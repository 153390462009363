import styled from "styled-components";

import { colors } from "@hotel-engine/styles";

export const OverlappingToast = styled.div`
  display: grid;
  grid-template-columns: 35px 1fr;
  background-color: ${colors.orange[50]};
  padding: 8px 16px 8px 8px;
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.lg};
  margin-top: 24px;

  svg {
    margin: 4px 0 0 6px;
  }
`;
