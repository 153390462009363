import type { Dispatch, ReactNode, SetStateAction } from "react";
import { createContext, useState } from "react";

export type Page = "form" | "confirmation" | "";

interface IValue {
  page: Page;
  setPage: Dispatch<SetStateAction<Page>>;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

interface IDrawerProviderProps {
  /** React children */
  children: ReactNode;
  /** Optional useState with page and setPage to pass into provider
   *
   * Example:
   *
   *     const [page, setPage] = useState<string>('form')
   *
   *     ...
   *
   *     return (<Provider pageHook={[page, setPage]} />)
   */
  pageHook?: [Page, Dispatch<SetStateAction<Page>>];
  /** Optional useState with visible and setVisible to pass into provider
   *
   * Example:
   *
   *     const [visible, setVisible] = useState<boolean>(false)
   *
   *     ...
   *
   *     return (<Provider visibleHook={[visible, setVisible]} />)
   */
  visibleHook?: [boolean, Dispatch<SetStateAction<boolean>>];
}

export const DrawerContext = createContext({} as IValue);

/**
 * This provider is created to allow visible state to be passed
 * down to nested components as an alternative to utilizing prop drilling
 */

const DrawerProvider = ({ children, pageHook, visibleHook }: IDrawerProviderProps) => {
  /**  Controls open/close state of drawer */
  const [visible, setVisible] = useState<boolean>(false);
  const [page, setPage] = useState<Page>("");

  let value: IValue = {
    page,
    setPage,
    visible,
    setVisible,
  };

  if (pageHook) {
    value = {
      ...value,
      ...{
        // [ page, setPage ] = pageHook
        page: pageHook[0],
        setPage: pageHook[1],
      },
    };
  }

  if (visibleHook) {
    value = {
      ...value,
      ...{
        // [ visible, setVisible ] = visibleHook
        visible: visibleHook[0],
        setVisible: visibleHook[1],
      },
    };
  }

  return <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>;
};

export default DrawerProvider;
