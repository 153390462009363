import { useMutation, useQueryClient } from "react-query";

import { endpoints } from "@hotel-engine/react-query/constants";
import { useApi } from "@hotel-engine/react-query/useApi";
import type { IErrorResponse } from "@hotel-engine/types/errors";
import type {
  IPaymentProfile,
  IPaymentProfileCreateParams,
} from "@hotel-engine/types/paymentProfile";
import { convertEmptyStrValsToNull } from "@hotel-engine/utilities";
import { Unsafe } from "@hotel-engine/data";

export const useCreatePaymentProfile = () => {
  const queryClient = useQueryClient();
  const post = useApi("post");

  return useMutation<IPaymentProfile, IErrorResponse, IPaymentProfileCreateParams>(
    (params) => post(endpoints.paymentProfiles, convertEmptyStrValsToNull(params)),
    {
      onSuccess: () => {
        queryClient
          .invalidateQueries(endpoints.paymentProfiles)
          .then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
      },
    }
  );
};
