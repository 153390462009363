import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";
import * as Styled from "./styles";

const PolicyTerm = () => {
  const { COMPANY_NAME, COMPANY_URL } = useCompanyName();

  return (
    <Styled.TermsText variant="body/xs">
      By continuing, you agree to {COMPANY_NAME}’s
      <Styled.SmallLink
        href={`https://www.${COMPANY_URL}/terms-of-service/`}
        target="_blank"
        rel="noreferrer noopener"
      >
        {" "}
        Terms and Conditions
      </Styled.SmallLink>{" "}
      and&nbsp;
      <Styled.SmallLink
        href={`https://www.${COMPANY_URL}/privacy-policy/`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </Styled.SmallLink>
      .
    </Styled.TermsText>
  );
};

export default PolicyTerm;
