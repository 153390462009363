import type { UseQueryOptions } from "react-query";

import type { IErrorResponse } from "@hotel-engine/types/errors";

import { endpoints } from "../constants";
import { useApi } from "../useApi";
import { useExtendedQuery } from "../useExtendedQuery";
import type { ISupportCaseUpdatesCount } from "../../types/supportCase";

export const useSupportCaseUpdatesCountQuery = (
  options?: UseQueryOptions<ISupportCaseUpdatesCount, IErrorResponse>
) => {
  const get = useApi("get");

  return useExtendedQuery(
    [endpoints.supportCaseUpdatesCount],
    () => get<ISupportCaseUpdatesCount>(`${endpoints.supportCaseUpdatesCount}`),
    options
  );
};
