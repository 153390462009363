import type { UseQueryOptions } from "react-query";
import type { IErrorResponse } from "@hotel-engine/types/errors";
import { endpoints } from "../constants";
import { useApi } from "../useApi";
import { useExtendedQuery } from "../useExtendedQuery";
import type { IFlexProSubscription } from "@hotel-engine/types/flexPro";

export const useFlexProSubscriptionQuery = (
  options?: UseQueryOptions<IFlexProSubscription, IErrorResponse, IFlexProSubscription>
) => {
  const get = useApi("get");

  return useExtendedQuery<IFlexProSubscription, IErrorResponse>(
    endpoints.flexProSubscriptions,
    () => get<IFlexProSubscription>(endpoints.flexProSubscriptions),
    { ...options }
  );
};
