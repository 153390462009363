import { queryClient } from "@hotel-engine/contexts";
import { endpoints } from "@hotel-engine/react-query/constants";
import type { IFavoriteProperty } from "@hotel-engine/types/favoriteProperty";
import config from "config";

import Api from "./Api";
import Resource from "./Resource";

/**
 * @deprecated - switch to the associated react-query hooks if possible
 */
export default class FavoriteProperty extends Resource {
  public static _basePath = `${config.apiHostV2}/favorite_properties`;

  // Duplicate of the constructor from ReadOnlyResource;
  // The context of "this" got broken in the upgrade to CRA,
  // so calling it explicitly here as a hack
  constructor(params?) {
    super(params);
    Object.assign(this, params);
  }

  public static async create(params: {
    propertyId: number;
  }): Promise<IFavoriteProperty> {
    const result = await Api.post(this._basePath, params);

    await queryClient.invalidateQueries(endpoints.favoriteProperties);

    return result;
  }

  public static async delete(favoritePropertyId: number) {
    const result = await Api.delete(`${this._basePath}/${favoritePropertyId}`, {});

    await queryClient.invalidateQueries(endpoints.favoriteProperties);

    return result;
  }
}
