/**
 * Truncates a string.
 * @param {number} maxSize The max size the string can have, taking the trailing characters into account.
 * @param {string} trailing Characters to be added at the end if the original string is larger than the maximum size.
 * @param {boolean} useWordBoundary When breaking the string down, prefer breaking in between words. The final string may have less characters than the max size.
 */
export const truncateString = (
  original: string,
  maxSize: number,
  trailing = "...",
  useWordBoundary = false
) => {
  if (original.length <= maxSize) return original;

  const maxSizeWithoutTrailing = maxSize - trailing.length;

  if (useWordBoundary) {
    // This RegExp will get as many words as possible so that the total number of characters is still within limits
    const regex = new RegExp(`^(.{0,${maxSizeWithoutTrailing}})\\s+.*$`, "gm");
    // The first match will be the whole string, and the second the group we are interested at. Or null if the first word is too big.
    const matchResult = regex.exec(original);
    if (!!matchResult) {
      return matchResult[1].trimEnd() + trailing;
    }
  }

  return original.slice(0, maxSizeWithoutTrailing).trimEnd() + trailing;
};
