/* eslint-disable
 @typescript-eslint/naming-convention,
 @typescript-eslint/no-empty-interface,
 */
import type {
  HasFn,
  HasPropAssertion,
  HasPropGuard,
  HasPropGuard as HasPropFn,
  HasPropParser,
  HasPathAssertion,
  HasPathGuard,
  HasPathGuard as HasPathFn,
  HasPathParser,
  HasPathsAssertion,
  HasPathsGuard,
  HasPathsGuard as HasPathsFn,
  HasPathsParser,
} from "./has"
import * as H from "./has"

export { Has }

interface HasPropModule {
  assert: HasPropAssertion,
  guard: HasPropGuard,
  parse: HasPropParser
}
const hasPropModule: HasPropModule = {
  assert: H.hasProp({ strategy: "Assert" }),
  guard: H.hasProp({ strategy: "Guard" }),
  parse: H.hasProp({ strategy: "Parse" })
}

interface HasPathModule {
  assert: HasPathAssertion,
  guard: HasPathGuard,
  parse: HasPathParser
}

const hasPathModule: HasPathModule = {
  assert: H.hasPath({ strategy: "Assert" }),
  guard: H.hasPath({ strategy: "Guard" }),
  parse: H.hasPath({ strategy: "Parse" })
}

interface HasPathsModule {
  assert: HasPathsAssertion,
  guard: HasPathsGuard,
  parse: HasPathsParser
}
const hasPathsModule: HasPathsModule = {
  assert: H.hasPaths({ strategy: "Assert" }),
  guard: H.hasPaths({ strategy: "Guard" }),
  parse: H.hasPaths({ strategy: "Parse" })
}

type HasModule =
  (
    & HasFn
    & {
      prop: (HasPropFn & HasPropModule)
      path: (HasPathFn & HasPathModule)
      paths: (HasPathsFn & HasPathsModule)
    }
  ) | never
  ;

const Has
  : HasModule
  = Object.assign(
    H.has,
    {
      prop: Object.assign(H.hasProp, hasPropModule),
      path: Object.assign(H.hasPath, hasPathModule),
      paths: Object.assign(H.hasPaths, hasPathsModule),
    }
  )
