import { useState } from "react";

import { useTwoFactorVerificationCodeAuthenticatedRequest } from "@hotel-engine/react-query/twoFactorAuth/useTwoFactorVerificationCodeRequest";
import { captureMessage } from "@hotel-engine/utilities";

import * as Styled from "../styles";

/**
 * This component handles requesting that 2FV verification code be resent to the user's phone
 */

interface IResendButton {
  phone: string;
  setResendError: (errorMessage: string | null) => void;
}

export const ResendButton = ({ phone, setResendError }: IResendButton) => {
  const [hasResent, setHasResent] = useState(false);

  const twoFactorAuth = useTwoFactorVerificationCodeAuthenticatedRequest();

  const resendCodeMutate = () => {
    setHasResent(true);

    twoFactorAuth.mutate(
      {
        phone: phone,
      },
      {
        onSuccess: () => {
          setResendError(null);
          setHasResent(true);
        },
        onError: (error) => {
          captureMessage("Two Factor Auth verification code resend", { error });
          setHasResent(false);

          return setResendError(
            "Sorry there was a problem resending your code. Please try again later."
          );
        },
      }
    );
  };

  return (
    <Styled.TextButton onClick={resendCodeMutate} disabled={hasResent}>
      Resend code
    </Styled.TextButton>
  );
};
