import { css } from "styled-components";
import type { ValueOf } from "type-fest";

import { colors, sizes, visuallyHidden } from "../index";
import { globalTheme } from "../themes/global.theme";

/* Function to create a flexbox layout
=============================================================== */

interface IFlexbox {
  display?: "flex" | "inline-flex";
  direction?: "row" | "row-reverse" | "column" | "column-reverse";
  wrap?: "nowrap" | "wrap" | "wrap-reverse";
  alignItems?:
    | "stretch"
    | "flex-start"
    | "flex-end"
    | "center"
    | "baseline"
    | "first baseline"
    | "last baseline"
    | "start"
    | "end"
    | "self-start"
    | "self-end";
  justifyItems?:
    | "auto"
    | "normal"
    | "start"
    | "end"
    | "flex-start"
    | "flex-end"
    | "self-end"
    | "center"
    | "left"
    | "right"
    | "baseline"
    | "first baseline"
    | "last baseline"
    | "stretch";
  alignContent?:
    | "normal"
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | "stretch"
    | "start"
    | "end"
    | "baseline"
    | "first baseline"
    | "last baseline";
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | "start"
    | "end"
    | "left"
    | "right"
    | "center";
  shrink?: number;
  grow?: number;
  basis?: string | number;
}

export const flexbox = ({
  display,
  direction,
  wrap,
  alignItems,
  justifyItems,
  alignContent,
  justifyContent,
  shrink,
  grow,
  basis,
}: IFlexbox = {}) => css`
  display: ${display || "flex"};
  flex-direction: ${direction};
  flex-wrap: ${wrap};
  align-items: ${alignItems};
  justify-items: ${justifyItems};
  align-content: ${alignContent};
  justify-content: ${justifyContent};
  flex-shrink: ${shrink};
  flex-grow: ${grow};
  flex-basis: ${basis};
`;

/* Function to create a typography component
=============================================================== */
export interface ITypography {
  color?:
    | Extract<ValueOf<typeof colors>, string>
    | ValueOf<typeof colors.red>
    | ValueOf<typeof colors.orange>
    | ValueOf<typeof colors.gold>
    | ValueOf<typeof colors.yellow>
    | ValueOf<typeof colors.green>
    | ValueOf<typeof colors.aqua>
    | ValueOf<typeof colors.blue>
    | ValueOf<typeof colors.purple>
    | ValueOf<typeof colors.slate>
    | ValueOf<typeof colors.grey>;
  font?: keyof typeof globalTheme.legacy.fontFamily;
  height?: keyof typeof globalTheme.legacy.lineHeight | "inherit" | "initial";
  size?: keyof typeof globalTheme.legacy.fontSize | "inherit" | "initial";
  spacing?: string | number;
  weight?: keyof typeof globalTheme.legacy.fontWeight | "inherit" | "initial";
}

/**
 * @deprecated If you're building out a type style, utilize the new `Typography` component found in the `@hotelengine/atlas-web` package.
 * @see {@link [Design Guide for more details](https://atlas-design-system.supernova-docs.io/latest/guides/migration-guides/typography-migration-guide-Amp7Gns2)}
 * @example
 * OLD: xxxlarge | NEW: heading/3xl
 * OLD: xxlarge | NEW: heading/2xl
 * OLD: xlarge | NEW: heading/xl
 * OLD: large | NEW: heading/lg
 * OLD: medium | NEW: heading/md
 * OLD: small | NEW: heading/sm
 * OLD: xsmall | NEW: heading/xs
 * OLD: body-large | NEW: body/md
 * OLD: body-medium | NEW: body/sm
 * OLD: body-small | NEW: body/xs
 *
 *
 * @see {@link Typography}
 * @see {@link typographyMixin}
 */
export const typography = ({
  color = colors.blackPanther,
  font = "primary",
  height,
  size = "sm",
  spacing,
  weight = "normal",
}: ITypography) => [
  color &&
    `
            color: ${color};
            `,
  font &&
    `
            font-family: ${globalTheme.legacy.fontFamily[font]};
            `,
  size &&
    `
            font-size: ${globalTheme.legacy.fontSize[size]};
            `,
  weight &&
    `
            font-weight: ${globalTheme.legacy.fontWeight[weight]};
            `,
  height &&
    `
            line-height: ${globalTheme.legacy.lineHeight[height]};
            `,
  spacing &&
    `
            letter-spacing: ${spacing};
            `,
];

/* Function creating a underline animation
=============================================================== */

interface IUnderline {
  color: string;
  transition?: string;
}

export const underlineAnimation = ({
  color,
  transition = "all 0.3s ease-in-out 0s",
}: IUnderline) => css`
  display: inline-block;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: ${color};
    visibility: hidden;
    transform: scaleX(0);
    transition: ${transition};
  }
  &:hover:before {
    visibility: visible;
    transform: scaleX(1);
  }
`;

/* Function to apply all of the properties needed for a background image
=============================================================== */

interface IBackgroundImage {
  image?: string;
  size?: "cover" | "contain" | "auto" | number;
  position?: string | number;
  attachment?: "scroll" | "fixed" | "local";
  repeat?: "no-repeat" | "repeat-x" | "repeat-y" | "repeat" | "space" | "round";
}

export const backgroundImage = ({
  image,
  size,
  position,
  attachment,
  repeat,
}: IBackgroundImage) => css`
  ${image && `background-image: url(${image})`};
  background-size: ${size};
  background-position: ${position};
  background-attachment: ${attachment};
  background-repeat: ${repeat};
`;

export const backgroundImageCover = () => css`
  ${backgroundImage({
    size: "cover",
    repeat: "no-repeat",
    position: "center",
  })};
`;

/* Function to position elements
=============================================================== */

interface IPositions {
  position?: "absolute" | "fixed" | "relative" | "static" | "sticky";
}

interface IPositionElement extends IPositions {
  top?: string | number;
  right?: string | number;
  bottom?: string | number;
  left?: string | number;
  x?: string | number;
  y?: string | number;
  z?: string | number;
}

export const positionElement = ({
  position = "absolute",
  top,
  right,
  bottom,
  left,
  x = 0,
  y = 0,
  z = 1,
}: IPositionElement) => css`
  content: "";
  position: ${position};
  top: ${top};
  right: ${right};
  bottom: ${bottom};
  left: ${left};
  transform: translate(${x}, ${y});
  z-index: ${z};
`;

export const centerElementX = ({ z, position }: IPositionElement) => css`
  ${positionElement({ position: position, left: "50%", x: "-50%", z: z })};
`;

export const centerElementY = ({ z, position }: IPositionElement) => css`
  ${positionElement({ position: position, top: "50%", y: "-50%", z: z })};
`;

export const centerElement = ({ z, position }: IPositionElement) => css`
  ${positionElement({
    position: position,
    top: "50%",
    left: "50%",
    x: "-50%",
    y: "-50%",
    z: z,
  })};
`;

export const fillParent = ({ z, position }: IPositionElement) => css`
  ${positionElement({
    position: position,
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    z: z,
  })};
`;

/* Function to create a fixed aspect ratio or symmetrical background elements
=============================================================== */

export const circleBackground = (color: string) => css`
  position: relative;
  z-index: ${({ theme }) => theme.legacy.zIndex.backdrop};
  &:after {
    ${centerElement({ position: "absolute", z: -1 })}
    background: ${color};
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.x100};
    height: 0;
    width: 100%;
    padding-bottom: 100%;
  }
`;

/* Function to help with interactive pseudo-classes
=============================================================== */

interface IInteractionStates {
  initialFg?: string;
  initialBg?: string;
  visitedFg?: string;
  visitedBg?: string;
  hoverFg?: string;
  hoverBg?: string;
  focusFg?: string;
  focusBg?: string;
  activeFg?: string;
  activeBg?: string;
}

export const interactiveStateColors = ({
  initialFg,
  initialBg,
  visitedFg,
  visitedBg,
  hoverFg,
  hoverBg,
  focusFg = hoverFg, // Sets the focus foreground to hover incase it's not explicitly set
  focusBg = hoverBg, // Sets the focus background to hover incase it's not explicitly set
  activeFg,
  activeBg,
}: IInteractionStates) => css`
  color: ${initialFg};
  background-color: ${initialBg};
  &:visited {
    color: ${visitedFg};
    background-color: ${visitedBg};
  }
  &:focus:not(:hover) {
    color: ${focusFg};
    background-color: ${focusBg};
  }
  &:hover {
    color: ${hoverFg};
    background-color: ${hoverBg};
  }
  &:active {
    color: ${activeFg};
    background-color: ${activeBg};
  }
`;

/* IE doesn't allow the gap attribute with flexbox in IE, so emulate it for now.
This function creates a gap between each flex item without a margin between the parent.
=============================================================== */
export const flexGap = (gap: string) => css`
  margin: calc(-1 * ${gap}) 0 0 calc(-1 * ${gap});
  width: calc(100% + ${gap});

  > * {
    margin: ${gap} 0 0 ${gap};
  }
`;

/* A function to help with responsive display of elements. By default this makes the element hidden yet accessible if there is no declared display property. This ultimately should be used to created formatting helpers for these scenarios and maybe global class names you can add to the DOM/JSX directly.
=============================================================== */
export const hideAndShow = (
  key: keyof typeof sizes.breakpoints,
  range: "min" | "max" = "min",
  display?:
    | "none"
    | "block"
    | "inline"
    | "inline-block"
    | "flex"
    | "inline-flex"
    | "grid"
    | "list-item"
    | "table"
    | "table-row"
    | "table-cell"
    | "initial"
) => {
  return () => `@media (${range}-width: ${sizes.breakpoints[key]}) {
        ${display ? `display: ${display}` : visuallyHidden}
    }`;
};

/* A overly simple function used to create a square or a height and width that are the same instead of having to declare them explicity over and over again when multiple occasions arise
=============================================================== */
export const equalDimensions = (size: number, unit: "em" | "%" | "px" = "px") => `
  height: ${size}${unit};
  width: ${size}${unit};
`;

/**
 *
 * @param hex #fff, #000, #757575
 * @param oppacity 0 ~ 1
 * @returns rgba(255, 255, 255, opacity)
 */
export const hexToRgba = (hex: string, opacity = 1) => {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    let c;
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + `,${opacity})`;
  }

  // default to black color instead of throwing an error
  return colors.black;
};

/**
 * Map a pixel value into it's equivalent `rem` value
 *
 * @param pxValue number of pixels being used
 * @returns equivalent `rem` value as string (eg: `1.25rem`)
 */
export const pxToRem = (pxValue: number): string => {
  return `${pxValue / 14}rem`;
};
