import styled, { css } from "styled-components";

import SkeletonLoader from "@hotel-engine/common/SkeletonLoader";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";

export const NotificationInbox = styled.article`
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  height: 100%;

  ${flexbox({
    direction: "column",
  })};

${({ theme }) => css`
  @media screen and (min-width: ${theme.breakpoints.xl}) {
  width: 400px;

      ${flexbox({
        justifyContent: "flex-start",
      })};
  }`}
`;

export const InboxHeader = styled.header`
  border-bottom: ${({ theme }) => `${theme.borderWidth.normal} solid ${theme.colors.borderMinimal}`};
  padding: ${({ theme }) => `${theme.spacing[24]} ${theme.spacing[16]} ${theme.spacing[16]}`};
  ${flexbox({
    alignItems: "center",
    direction: "row",
    justifyContent: "space-between",
  })};

  ${({ theme }) => css`
  @media screen and (min-width: ${theme.breakpoints.xl}) {
    padding-top: ${theme.spacing[8]};
    padding-bottom: ${theme.spacing[16]};
    padding-left: ${theme.spacing[0]};
  }
`}

`;

export const LoaderWrap = styled.div`
  padding: ${({ theme }) => `${theme.spacing[16]} ${theme.spacing[0]}`};
  text-align: center;
`;

export const StyledLoader = styled(SkeletonLoader)`
  border: 0;
  height: auto;
  margin: ${({ theme }) => `${theme.spacing[16]} ${theme.spacing[0]} ${theme.spacing[24]}`};
  padding: ${({ theme }) => `${theme.spacing[0]} ${theme.spacing[8]}`};

  &:first-of-type {
    margin-top: ${({ theme }) => theme.spacing[8]};
  }

  &:last-of-type {
    margin-bottom: ${({ theme }) => theme.spacing[0]};
  }

  &&&.ant-skeleton {
    .ant-skeleton-avatar {
      width: 48px;
      height: 48px;
    }
  }
`;

export const NotificationWrap = styled.ul`
  overflow-y: auto;
  padding-top: ${({ theme }) => theme.spacing[16]};

  ${({ theme }) => css`
  @media screen and (min-width: ${theme.breakpoints.xl}) {
    max-height: 600px;
  }`}

`;
