import type { CSSProperties, ReactElement, ReactNode } from "react";

import { Dropdown, Menu } from "antd";
import type { ClickParam } from "antd/lib/menu";

import Button from "@hotel-engine/common/Button";

import { Icon } from "../Icon";
import * as Styled from "./styles";

type DropDownOptions = {
  title: string;
  callback: (data: unknown) => void;
  img?: ReactElement;
  style?: CSSProperties;
  children?: ReactNode[];
};
interface IDropdown {
  /** Callback to fire when Edit is clicked from dropdown */
  handleEdit?: (data: ClickParam) => void;
  /** Callback to fire when Delete is clicked from dropdown */
  handleDelete?: (data: ClickParam) => void;
  /** other options */
  dropdownOptions?: DropDownOptions[];
}

export type TVariants = "no-margin";
export interface ICard {
  /** Image to appear on left of card */
  img?: ReactElement;
  /** Card title */
  title: string | ReactElement;
  /** Label for Card (renders antd Tag) */
  label?: string;
  /** Main description for card */
  description?: string;
  /** Renders next to description, separated by bullet */
  subDescription?: string | ReactElement;
  /** Renders next to description, not separated by bullet red. */
  status?: string;
  /** Used for multiple action items on the card to render in dropdown */
  dropdown?: IDropdown;
  /** Used for a single action to render inline */
  singleAction?: ReactElement;
  /** Error state */
  error?: boolean;
  /** Variants are ways to update styles where classNames are being used and the parent component isn't a styled-component yet. This in theory is just temporary until styles can be applied at the implementation level when SASS is gone */
  $variant?: TVariants;
  /** the className prop allows this styled component to have their styles be overwritten when reused */
  className?: string;
  /** removes the border from the card */
  noBorder?: boolean;
}

/**
 * The `Card` component is intended to encompass use cases for all horizontally oriented cards. It supports a single action rendered inline as well as a dropdown for multiple actions
 *
 * @see {@link https://www.figma.com/file/GVLYN60OBX188CID3YvWpSo6/Components---WEB?node-id=1523%3A12281 Design Specs}
 */
const GeneralCard = ({
  $variant,
  className,
  description,
  dropdown,
  error,
  img,
  label,
  singleAction,
  status,
  subDescription,
  title,
  noBorder,
}: ICard) => {
  return (
    <Styled.Card
      $variant={$variant}
      className={className}
      data-testid="card"
      error={error}
      noBorder={noBorder}
    >
      <div className="card-content-wrapper">
        <div className="card-body">
          {!!img && <div className="image">{img}</div>}
          <div className="copy-content">
            <span className="title-wrapper">
              <span className="card-title">{title}</span>
              {!!label && <Styled.CardTag className="label">{label}</Styled.CardTag>}
            </span>
            {!!description && (
              <div>
                <span>{description}</span>
                {!!subDescription && (
                  <>
                    <span className="bullet">&bull;</span>
                    {subDescription}
                  </>
                )}
                {!!status && <span className="status">{status}</span>}
              </div>
            )}
          </div>
        </div>
        <Styled.Actions>
          {!!dropdown && (
            <Dropdown
              placement="bottomRight"
              overlay={
                <Menu>
                  {!!dropdown.handleEdit && (
                    <Styled.MenuItem onClick={dropdown.handleEdit}>
                      <Icon icon={["far", "edit"]} />
                      <span>Edit</span>
                    </Styled.MenuItem>
                  )}
                  {!!dropdown.handleDelete && (
                    <Styled.MenuItem onClick={dropdown.handleDelete}>
                      <Icon icon={["far", "minus-circle"]} />
                      <span>Delete</span>
                    </Styled.MenuItem>
                  )}
                  {!!dropdown.dropdownOptions &&
                    dropdown.dropdownOptions.map((option, index) => (
                      <Styled.MenuItem
                        key={index}
                        onClick={option.callback}
                        style={option.style}
                        className="actions-dropdown-item"
                      >
                        {!!option.img && option.img}
                        <span>{option.title}</span>
                        {!!option.children && option.children}
                      </Styled.MenuItem>
                    ))}
                </Menu>
              }
              trigger={["click"]}
            >
              <Button type="default" data-testid="show-actions-dropdown">
                <Icon icon={["fas", "ellipsis-h"]} />
              </Button>
            </Dropdown>
          )}
          {!!singleAction && singleAction}
        </Styled.Actions>
      </div>
    </Styled.Card>
  );
};

export default GeneralCard;
