import type { AxiosRequestConfig } from "axios";

import { getClient } from "@hotel-engine/client";
import type { ICreateHiddenPropertyParams } from "@hotel-engine/types/hiddenProperty";
import type {
  ICreateSalesforceCaseParams,
  ISalesforceCaseQueryParams,
} from "@hotel-engine/types/salesforceCase";
import type { IBookingShortenRoomParams } from "@hotel-engine/types/booking";
import type { IDirectBillOnboardingParams } from "@hotel-engine/types/directBillOnboarding";

import type { IDebouncedGuestQueryParams } from "./guest/useGuestQuery";

type IVariables =
  | { [k: string]: unknown }
  | ICreateHiddenPropertyParams
  | ICreateSalesforceCaseParams
  | ISalesforceCaseQueryParams
  | IDirectBillOnboardingParams
  | IDebouncedGuestQueryParams
  | IBookingShortenRoomParams;

export const convertToSnake = (variables?: IVariables) => {
  const convertedVariables = {};

  function camelToUnderscore(key) {
    return key?.replace(/([A-Z])/g, "_$1").toLowerCase();
  }

  for (const camel in variables) {
    convertedVariables[camelToUnderscore(camel)] = variables[camel];

    // only sort param values should be snake case for now
    // we can build an array in the future to loop over if more param values need converting
    if (camel === "sort") {
      convertedVariables["sort"] = camelToUnderscore(variables[camel]);
    }
  }

  return convertedVariables;
};

/**
    This hook sets up all the axios functionality needed such as cancelling
    requests when components unmount or new requests are fired off before an existing
    one hasn't finished.
    It returns an object with the different REST methods offered from the
    axios library (delete, get, patch, post. put).
    The DataProvider must be a parent of this for it to properly work with axios and
    react-query libraries.
    See /docs/DataComponents/DataContext.md for more info
*/

/** Type of request to make ('delete' | 'get' | 'patch' | 'post' | 'put') */
export type IRequestType = "delete" | "get" | "patch" | "post" | "put";

export declare function listFilesOfDirectory(dirPath: string): string[];

export function useApi(
  requestType: IRequestType,
  signOutOnUnauthorized = true,
  apiVersion?: string
) {
  const dataClient = getClient(signOutOnUnauthorized, apiVersion);

  async function requestDataCB<T>(
    apiPath,
    payload = {},
    config: AxiosRequestConfig = {},
    sendVariablesAsBody = false,
    disableSnakeCase = false
  ): Promise<T> {
    const transformedPayload = disableSnakeCase ? payload : convertToSnake(payload);

    /*
            TypeScript didn't like trying to call the dataClient directly with a variable as a
            key (ex. dataClient[requestType]) due to the different signature types defined in the
            library; therefore, defined it for each specific key. Also, patch, post and put take
            an additional arg for data (variables) as part of the function. The delete and get request
            types take the variables as a param key in the client config.
        */
    const dataClientRequest = {
      delete: () =>
        dataClient.delete(apiPath, {
          ...config,
          params: sendVariablesAsBody ? null : transformedPayload,
          /**
           * some delete requests need to handle a request body
           */
          data: sendVariablesAsBody ? transformedPayload : null,
        }),
      get: () => dataClient.get(apiPath, { ...config, params: transformedPayload }),
      patch: () => dataClient.patch(apiPath, transformedPayload, config),
      post: () => dataClient.post(apiPath, transformedPayload, config),
      put: () => dataClient.put(apiPath, transformedPayload, config),
    };

    // Executes request
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: any = await dataClientRequest[requestType]();

    // If errors exist we need to throw this so useQuery api can pick them up, insert into "error", and
    // set status correctly
    if (response.errors) {
      throw new Error(response.errors);
    }

    if (requestType === "get") {
      return response.data;
    }

    return response.data.data ?? response.data;
  }

  return requestDataCB;
}
