import type { UseMutationOptions } from "react-query";
import { useMutation, useQueryClient } from "react-query";

import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { IMemberRecord } from "@hotel-engine/types/member";

import { cacheKeys, endpoints } from "../constants";
import { useApi } from "../useApi";

interface IMemberMutationConfig {
  options?: UseMutationOptions<
    IBulkMembersResponse,
    IErrorResponse & IBulkMembersResponse,
    Partial<IMemberRecord>[]
  >;
  method?: "post" | "delete";
}

type IMutationParams = Partial<IMemberRecord>[];

interface IBulkMembersResponse {
  successCount: number;
}

const useBulkMembersMutation = (config: IMemberMutationConfig = {}) => {
  const post = useApi("post");
  const del = useApi("delete");
  const queryClient = useQueryClient();

  // Function that executes when useMutation is fired
  const mutationFn = (variables: IMutationParams) => {
    const isDeleteRequest = config?.method === "delete";
    const endpoint = endpoints.bulkMembers;
    const body = isDeleteRequest ? { ids: variables } : { members: { batch: variables } };

    const options = {
      headers: {
        Accept: "application/json",
      },
    };

    switch (config?.method) {
      case "delete":
        return del<IBulkMembersResponse>(endpoint, body, options, true);
      case "post":
      default:
        return post<IBulkMembersResponse>(endpoint, body, options);
    }
  };

  // Add all queries that need to be invalidated here
  const refetchQueries = [cacheKeys.useMembersQuery, cacheKeys.useTravelPoliciesQuery];

  const options = {
    // Triggers a refetch on all queries affected by this mutation
    onSuccess: async () => {
      await Promise.all(refetchQueries.map((queryKey) => queryClient.invalidateQueries(queryKey)));
    },
    onError: async () => {
      await Promise.all(refetchQueries.map((queryKey) => queryClient.invalidateQueries(queryKey)));
    },
    ...(config.options || {}),
  };

  return useMutation<IBulkMembersResponse, IErrorResponse & IBulkMembersResponse, IMutationParams>(
    mutationFn,
    options
  );
};

export { useBulkMembersMutation };
