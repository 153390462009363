import { useEffect, useRef } from "react";
import config from "config";
import LogRocket from "logrocket";
import { useAppSelector } from "store/hooks";
import { useGetAppVersion } from "@hotel-engine/scripts/hooks/useGetAppVersion";

export interface ILogRocketRequest {
  reqId: string;
  url: string;
  headers: { [key: string]: string | undefined };
  body?: string;
  method: string;
  referrer?: string;
  mode?: string;
  credentials?: string;
}

export interface ILogrocketResponse {
  reqId: string;
  status?: number;
  headers: { [key: string]: string | undefined };
  body?: string;
  method: string;
}

const redactedFields = ["password", "api_key", "accesstoken", "authorization"];

const REDACTED_MSG = "**REDACTED**";

export function sanitizer<T extends ILogRocketRequest | ILogrocketResponse>(request: T): T {
  // scrub header value from request
  if (request.headers) {
    for (const header in request.headers) {
      if (redactedFields.includes(header.toLowerCase())) {
        request.headers[header] = REDACTED_MSG;
      }
    }
  }

  let redactingBody = false;
  redactedFields.forEach((field) => {
    if (
      !redactingBody &&
      !!request.body &&
      !!request.body.includes &&
      request.body.includes(field)
    ) {
      request.body = REDACTED_MSG;
      redactingBody = true;
    }
  });

  return request;
}

export const requestSanitizer = (request: ILogRocketRequest) => sanitizer(request);

export const responseSanitizer = (response: ILogrocketResponse) => sanitizer(response);

export const useLogrocketInit = () => {
  const hasIdentified = useRef<boolean>(false);
  const userId = useAppSelector((store) => store.Auth.user?.id);
  const userEmail = useAppSelector((store) => store.Auth.user?.email);
  const userRole = useAppSelector((store) => store.Auth.user?.role);

  const { fetchedValue } = useGetAppVersion();

  useEffect(() => {
    const release = fetchedValue?.substring(fetchedValue?.indexOf("@") + 1);
    const releaseFetched = !!release;

    if (releaseFetched) {
      let project;
      if (config.stage === "production") {
        project = "c8gpr6/members-client";
      } else if (!globalThis.Cypress) {
        project = "c8gpr6/integration-test";
      }

      if (project) {
        LogRocket.init(project, {
          network: { requestSanitizer, responseSanitizer },
          release,
          rootHostname: config.logRocketRootDomain,
        });
      }
    }
  }, [fetchedValue]);

  useEffect(() => {
    if (!!userId && hasIdentified.current === false) {
      LogRocket.identify(String(userId), {
        email: String(userEmail),
        role: String(userRole),
      });
      hasIdentified.current = true;
    }
  }, [userId, userEmail, userRole]);

  useEffect(() => {
    const LRHandleVisibilityChange = () => {
      if (LogRocket && LogRocket.info) {
        LogRocket.info("visibility-change", {
          visibilityState: document.visibilityState,
        });
      }
    };
    document.addEventListener("visibilitychange", LRHandleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", LRHandleVisibilityChange);
    };
  }, []);
};
