import { Modal } from "@hotel-engine/common/Modal";
import { Box, Button, Typography } from "@hotelengine/atlas-web";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import { Formik } from "formik";
import { ChangePasswordSchema } from "../../../../../Settings/components/MyProfile/helpers";
import { notification } from "@hotel-engine/common/Notifications";
import { Auth } from "@hotel-engine/services";
import ChangePasswordForm from "../ChangePasswordForm";

import { routes } from "@hotel-engine/constants";

interface IChangePasswordModal {
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
}

interface IFooterButtons {
  isSubmitting: boolean;
  onCancel: () => void;
  onSave: () => void;
}

const ChangePasswordModal = ({ openModal, setOpenModal }: IChangePasswordModal) => {
  const navigate = useNavigate();
  const handleLockOut = async () => {
    await Auth.signOut("Settings.handleLockOut");
    navigate(routes.loggedOut);
  };
  const _handleSubmit = (values, { setErrors, setSubmitting }) => {
    const currentPassword = values.currentPassword;
    const newPassword = values.newPassword;
    const newPasswordConfirmation = values.passwordConfirmation;

    if (newPassword !== newPasswordConfirmation) {
      setErrors({ general: "Passwords do not match" });
      setSubmitting(false);
    } else {
      setSubmitting(true);

      Auth.resetPassword(currentPassword, newPassword, true)
        .then(async () => {
          notification.success({
            message: "Your password has been updated",
          });
          await Auth.signOut("Settings.components.MyProfile.ChangePassword");
          setOpenModal(false);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          if (error.locked) {
            return handleLockOut();
          }
          const defaultError = [
            "There was a problem saving your information. Please contact support if the problem persists.",
          ];
          const submitError = error?.errors || defaultError;
          setErrors({ general: submitError });
          setSubmitting(false);
          return;
        });
    }
  };

  const FooterButtons = ({ onCancel, onSave, isSubmitting }: IFooterButtons) => (
    <Box display="flex" gap={16} justifyContent="flex-end">
      <Button variant="outlined" color="secondary" onClick={onCancel}>
        Cancel
      </Button>
      <Button
        variant="filled"
        onClick={onSave}
        isLoading={isSubmitting}
        data-testid="save-change-password-button"
      >
        Save
      </Button>
    </Box>
  );

  return (
    <Formik
      onSubmit={_handleSubmit}
      initialValues={{
        currentPassword: "",
        newPassword: "",
        passwordConfirmation: "",
      }}
      validationSchema={ChangePasswordSchema}
    >
      {({ values, isSubmitting, setErrors, setSubmitting }) => (
        <Modal
          visible={openModal}
          onCancel={() => setOpenModal(false)}
          okText="Save"
          footer={
            <FooterButtons
              onSave={() => _handleSubmit(values, { setErrors, setSubmitting })}
              onCancel={() => setOpenModal(false)}
              isSubmitting={isSubmitting}
            />
          }
        >
          <Box display="flex" flexDirection="column" gap={8}>
            <Typography variant="heading/lg" as="p">
              Change your password
            </Typography>

            <ChangePasswordForm />
          </Box>
        </Modal>
      )}
    </Formik>
  );
};

export default ChangePasswordModal;
