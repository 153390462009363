import { Icon } from "@hotel-engine/common/Icon";
import { colors } from "@hotel-engine/styles";

import * as Styled from "./styles";

interface IExpressBookPaymentErrorBannerProps {
  errors: string[];
}

export const ExpressBookPaymentErrorBanner = ({ errors }: IExpressBookPaymentErrorBannerProps) => {
  return (
    <Styled.ExpressBookPaymentErrorBannerWrapper>
      <Icon icon={["fas", "exclamation-circle"]} color={colors.red[400]} size="lg" />
      <Styled.ExpressBookPaymentErrorBannerColumn>
        <Styled.ExpressBookPaymentErrorBannerTitle>
          Payment Error
        </Styled.ExpressBookPaymentErrorBannerTitle>
        <ul>
          {errors.map((error, erroridx) => (
            <li key={erroridx}>{error}</li>
          ))}
        </ul>
      </Styled.ExpressBookPaymentErrorBannerColumn>
    </Styled.ExpressBookPaymentErrorBannerWrapper>
  );
};
