import type { UseQueryOptions } from "react-query";

import type { ICustomField } from "@hotel-engine/types/customField";
import type { IDepartment } from "@hotel-engine/types/department";
import type { IError } from "@hotel-engine/types/errors";

import { endpoints } from "../constants";
import { convertToSnake, useApi } from "../useApi";
import { useExtendedQuery } from "../useExtendedQuery";
import type { IUser } from "../../types/user";

export const useCustomFieldsQuery = (
  params?: {
    departmentId?: IDepartment["id"];
    role?: IUser["role"];
  },
  options?: UseQueryOptions<ICustomField[], IError, ICustomField[]>
) => {
  const get = useApi("get");

  return useExtendedQuery<ICustomField[], IError>(
    [endpoints.customFields, params],
    async () => {
      const data = await get<{ data: ICustomField[] }>(
        endpoints.customFields,
        convertToSnake(params)
      );
      data.data?.length > 1 && data.data.sort((a, b) => a.name.localeCompare(b.name));
      data.data?.forEach((field) => {
        field.options?.sort((a, b) => a.localeCompare(b));
      });
      return data.data;
    },
    options
  );
};
