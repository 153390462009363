import { useState } from "react";

import { Icon } from "@hotel-engine/common/Icon";
import { Switch } from "@hotel-engine/common/Switch";
import { Tooltip } from "@hotel-engine/common/Tooltip";
import storage from "@hotel-engine/storage";

import { CookieTypeDefinitions, Cookies } from "./Cookies";
import * as Styled from "./CookieSettings.styles";
import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";

const CookieInformation = (props: {
  type: keyof typeof CookieTypeDefinitions;
  enabled: [boolean, (type: keyof typeof CookieTypeDefinitions) => void];
}) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <Styled.CookieInformation key={props.type}>
      <h3>
        {props.type}
        {props.type === "Strictly Necessary" ? (
          <Tooltip title={CookieTypeDefinitions[props.type]}>
            <Icon icon={["far", "question-circle"]} />
          </Tooltip>
        ) : (
          <Switch
            onClick={() => props.enabled[1](props.type)}
            checked={props.enabled[0]}
            aria-label={`${props.enabled[0] ? "disable" : "enable"} ${props.type} cookies`}
          />
        )}
      </h3>
      <p>{CookieTypeDefinitions[props.type]}</p>
      <Styled.ViewCookiesButton type="link" onClick={() => setIsVisible((prev) => !prev)}>
        <Styled.PlusIcon icon={["fal", "plus"]} $isOpen={isVisible} />
        {isVisible ? "Hide cookies" : "View cookies"}
      </Styled.ViewCookiesButton>
      {!!isVisible && (
        <Styled.CookieDetailTable>
          <tbody>
            <tr>
              <th></th>
              <th>Description</th>
              <th>Type</th>
              <th>Duration</th>
            </tr>
            {Object.values(Cookies).map(
              (cookie) =>
                cookie.type === props.type && (
                  <tr key={cookie.name}>
                    <td>{cookie.name}</td>
                    <td>{cookie.description}</td>
                    <td>{cookie.firstParty ? "First Party" : "Third Party"}</td>
                    <td>
                      {cookie?.["expiresDisplay"] || cookie?.["options"]?.expires || "Session"}
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </Styled.CookieDetailTable>
      )}
    </Styled.CookieInformation>
  );
};

export const CookieSettings = (props: {
  onOk?: () => void;
  setAmplitudeCookieAccepted: () => void;
}) => {
  const { COMPANY_NAME, COMPANY_URL } = useCompanyName();
  const cookiePerms = storage.getSecureItem("cookiePerms");
  const [cookieSettings, setCookieSettings] = useState(
    !!cookiePerms
      ? JSON.parse(cookiePerms)
      : {
          Statistics: true,
          Marketing: true,
        }
  );

  const saveCookieSettingsHandler = () => {
    storage.setSecureItem("cookiePerms", JSON.stringify(cookieSettings));
    if (cookieSettings.Statistics) {
      props.setAmplitudeCookieAccepted();
    }
    props.onOk?.();
  };

  return (
    <Styled.SettingsContainer>
      <section>
        <h1>Cooking Settings</h1>
        <h3>{COMPANY_NAME} uses cookies to improve your experience and for marketing.</h3>
        <p>
          For more information, please refer to our{" "}
          <a
            href={`https://www.${COMPANY_URL}/privacy-policy/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </p>
      </section>
      {Object.keys(CookieTypeDefinitions).map((cookieType) =>
        CookieInformation({
          type: cookieType as keyof typeof CookieTypeDefinitions,
          enabled: [
            cookieSettings[cookieType],
            () =>
              setCookieSettings((prev) => ({
                ...prev,
                ...{ [cookieType]: !prev[cookieType] },
              })),
          ],
        })
      )}
      <Styled.Approve onClick={saveCookieSettingsHandler}>OK</Styled.Approve>
    </Styled.SettingsContainer>
  );
};
