import { createGlobalStyle } from "styled-components";
import { useIsFlightsEnabled } from "../../hooks/useIsFlightsEnabled";
import GlobalStyle from "./global.style";

const FlightGlobalStyles = createGlobalStyle`
    * {
      font-feature-settings: "cv11" on ,"ss01" on, "case" on, "tnum" on;
    }
`;

export const GlobalStyles = () => {
  const isFlightsOn = useIsFlightsEnabled();

  return (
    <>
      <GlobalStyle />
      {!!isFlightsOn && <FlightGlobalStyles />}
    </>
  );
};
