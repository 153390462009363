import type { ReactNode } from "react";
import { useEffect } from "react";
import type {
  GetTokenSilentlyOptions,
  LogoutOptions,
  RedirectLoginOptions,
} from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  addAuthEventListener,
  EVENT_TYPES,
  listenForAuthStatusChanges,
  removeAuthEventListener,
  removeAuthStatusChangeListener,
} from "@hotel-engine/utilities/auth/events";
import { Auth } from "@hotel-engine/services";

import { RouteLoadingUI } from "../../../RouteLoadingUI";
import { useGetAccessTokenSilently } from "@hotel-engine/utilities/auth";

interface IAuth0ContextConsumerProps {
  /** React children */
  children: ReactNode;
}

export const handleLoginEvent = () => {
  const isAuth0Callback = location.search.includes("code=");
  if (isAuth0Callback) return; // used to prevent double redirects
  // refresh page to get new user data
  const matches = Auth.getRedirectMatches();
  if (matches) {
    Auth.redirect();
  } else {
    globalThis.location.reload();
  }
};

export const handleLogoutEvent = async () => {
  await Auth.signOut("handleLogoutEvent", false);
};

export const Auth0ContextConsumer = ({ children }: IAuth0ContextConsumerProps) => {
  const { logout, isAuthenticated, isLoading } = useAuth0();
  const getToken = useGetAccessTokenSilently();
  useEffect(() => {
    // set global function to get access token
    globalThis.getAccessTokenSilently = (
      options?: GetTokenSilentlyOptions,
      redirectOptions?: RedirectLoginOptions
    ) => getToken(options, redirectOptions);
    globalThis.auth0Logout = async (opts?: LogoutOptions | undefined): Promise<void> => {
      if (isAuthenticated) {
        await logout(opts);
      }
    };
  }, [logout, isAuthenticated, isLoading, getToken]);
  useEffect(() => {
    addAuthEventListener(EVENT_TYPES.signIn, handleLoginEvent);
    addAuthEventListener(EVENT_TYPES.signOut, handleLogoutEvent);
    listenForAuthStatusChanges();

    return () => {
      removeAuthEventListener(EVENT_TYPES.signIn, handleLoginEvent);
      removeAuthEventListener(EVENT_TYPES.signOut, handleLogoutEvent);
      removeAuthStatusChangeListener();
    };
  }, []);
  return isLoading ? <RouteLoadingUI /> : <>{children}</>;
};

export default Auth0ContextConsumer;
