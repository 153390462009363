import styled from "styled-components";

export const ResendButton = styled.span`
  color: ${({ theme }) => theme.colors.linkPrimary};
  cursor: pointer;
`;

export const ResendResponse = styled.p<{ $status: "error" | "success" }>`
  text-align: center;
  margin: auto;
  max-width: 315px;
  margin-top: 8px;
  color: ${({ $status, theme }) =>
    $status === "error" ? theme.colors.accentRed : theme.colors.accentGreen};
`;
