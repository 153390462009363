import styled from "styled-components";

import type { TypographyProps } from "@hotelengine/atlas-web";
import { Typography } from "@hotelengine/atlas-web";

const TermsText = styled<React.FC<TypographyProps>>(Typography)`
  color: ${({ theme }) => theme.colors.foregroundTertiary};
  margin-top: ${({ theme }) => theme.spacing[1]};
  font-size: 11px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`;

const SmallLink = styled.a`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.linkPrimary};

  &:hover {
    color: ${({ theme }) => theme.colors.uiPrimaryActive};
  }
`;

export { TermsText, SmallLink };
