export const breakpoints = {
  xs: 320,
  sm: 480,
  md: 576,
  lg: 768,
  xl: 992,
  xxl: 1200,
  xxxl: 1400,
  xxxxl: 1660,
} as const;

/**
 * @deprecated The sizes object is being deprecated to make room for the upcoming Atlas theme. Please reach out to the Atlas team with any questions.
 */
export const sizes = {
  breakpoints: {
    xs: `${breakpoints.xs}px`,
    sm: `${breakpoints.sm}px`,
    md: `${breakpoints.md}px`,
    lg: `${breakpoints.lg}px`,
    xl: `${breakpoints.xl}px`,
    xxl: `${breakpoints.xxl}px`,
    xxxl: `${breakpoints.xxxl}px`,
    xxxxl: `${breakpoints.xxxxl}px`,
  },
} as const;
