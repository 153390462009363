import styled from "styled-components";

import { colors } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import type { TypographyProps } from "@hotelengine/atlas-web";
import { Typography } from "@hotelengine/atlas-web";
import type React from "react";

export const OtherOptionBox = styled.div`
  width: 100%;
  ${flexbox({ direction: "row", alignItems: "center" })}
  margin: 32px 0px;
`;

export const OtherOptionText = styled<React.FC<TypographyProps>>(Typography)`
  width: 56px;
  height: 20px;
  text-align: center;
`;

export const Doted = styled.div`
  flex: 1;
  height: 0px;
  border: 1px dashed ${colors.grey[200]};
`;

export const LoadingContainer = styled.div`
  margin: 32px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[16]};
`;
