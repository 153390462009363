import type { FormItemProps } from "antd/lib/form";
import type { FieldProps } from "formik";

import { FormItem } from "@hotel-engine/common/Form";
import { Input } from "@hotel-engine/common/Inputs";

import { getNestedProperty } from "../helpers";

export interface IInputFieldProps extends FieldProps, FormItemProps {
  /** antd's input size */
  size?: "small" | "default" | "large";
  /** if tracking submit attempts outside the same form, manually pass in that number */
  submitAttempts?: number;
  /** Formik match string */
  match?: string;
  /** if form is multistep then different validation rules will apply */
  isMultiStep?: boolean;
  /** any other props to pass to ant input (e.g. label, placeholder, etc.) */
  [x: string]: unknown;
  /** if `true` it will hide the error helper text */
  hideErrorHelperText?: boolean;
}

/**
 * The `InputField` component is a wrapper around antd's FormItem and Input components. It requires the use of formik and their
 * `<Field />` component.
 *
 * @remarks Props are spread onto the antd input, so we have full control over antd's input in this component
 * @example <Field component={InputField} label="Username" placeholder="username" name="username" />
 * @see {@link https://3x.ant.design/components/input/ AntD Input Documentation}
 * @see {@link https://formik.org/docs/api/field Formik Field Documentation}
 * @see {@link https://www.figma.com/file/GVLYN60OBX188CID3YvWpSo6/Components---WEB?node-id=870%3A4 Design Specs}
 */
const InputField = ({
  field,
  form: { errors, submitCount, touched, values },
  isMultiStep = false,
  matchError, // Destructure so it doesn't get passed to Input
  size = "large", // Destructure so we can set a default
  submitAttempts,
  extra,
  hideErrorHelperText,
  ...props
}: IInputFieldProps) => {
  const fieldTouched = getNestedProperty(touched, field.name, ".");
  const fieldError = getNestedProperty(errors, field.name, ".");
  const showValidation = isMultiStep ? fieldTouched : submitCount || submitAttempts || fieldTouched;

  let validateStatus: undefined | "error" = showValidation && fieldError && "error";

  let help = (showValidation && fieldError) || props.help;
  // Custom matching validation due to this Yup issue https://github.com/jquense/yup/issues/504
  if (props.match && showValidation) {
    if (values[props.match] !== values[field.name]) {
      validateStatus = "error";
      help = matchError;
    }
  }

  return (
    <FormItem
      className={props.className}
      colon={!!props.colon}
      help={hideErrorHelperText ? undefined : help}
      htmlFor={props.id || field.name}
      label={props.label}
      required={props.required}
      validateStatus={validateStatus || undefined}
      extra={extra}
    >
      <Input
        {...field}
        {...props}
        id={props.id || field.name}
        size={size}
        style={{ fontSize: "16px" }}
      />
    </FormItem>
  );
};

export default InputField;
