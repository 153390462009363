import { use } from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import type { HttpBackendOptions } from "i18next-http-backend";
import config from "config";

let i18n;

const LOCAL_NAMESPACES = ["common", "cars"];

export const initI18n = () => {
  if (i18n) {
    return i18n;
  }

  i18n = use(initReactI18next)
    .use(HttpApi)
    .init<HttpBackendOptions>({
      ns: [
        "common",
        "cars",
        "iata_aircraft",
        "iata_airline",
        "iata_airport",
        "iata_city",
        "iata_loyalty",
        "iata_airline_shortname",
        "iata_airline_website",
        "iata_airline_checkin_url",
        "iata_airline_support_url",
        "car_rental_vendor_url",
        "car_rental_vendor_company_name",
        "car_rental_vendor_loyalty_program",
      ],
      lng: "en",
      fallbackLng: "en",
      defaultNS: "common",
      backend: {
        loadPath: (_languages, namespaces) => {
          if (LOCAL_NAMESPACES.includes(namespaces[0])) {
            return "/assets/i18n/{{lng}}/{{ns}}.json";
          } else {
            return `${config.engineContentCdn}/engine-content-static-resources/locales/{{lng}}/{{ns}}.json`;
          }
        },
      },
      interpolation: {
        escapeValue: false, // react already safe from xss
      },
    });

  return i18n;
};
