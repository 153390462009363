import { pxToRem } from "@hotel-engine/styles/helpers/mixins";
import styled from "styled-components";

export const ExpressBookGuestSelectionContainer = styled.div`
  padding: ${pxToRem(16)} ${pxToRem(24)};
  border-top: 4px solid ${({ theme }) => theme.legacy.colors.grey[50]};
  max-width: 596px;

  > .ant-select-show-search {
    z-index: ${({ theme }) => theme.legacy.zIndex.backdrop};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: ${pxToRem(16)};
  }
`;
