import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import { Checkbox as AtlasCheckbox, BrandLogo, DialogContent } from "@hotelengine/atlas-web";
import styled from "styled-components";

export const FlexProSignUpModalContainer = styled.div`
  ${flexbox({})};
  width: 960px;
  max-width: 100%;
`;

export const FlexProModalSection = styled.div<{ $isSignUp: boolean }>`
  ${flexbox({
    direction: "column",
    justifyContent: "space-between",
  })};
  padding: 40px;
  gap: 32px;
  border-left: ${({ $isSignUp, theme }) =>
    $isSignUp ? `1px solid ${theme.colors.neutralN200}` : `none`};
  max-width: 480px;
`;

export const FlexProLogo = styled(BrandLogo)`
  width: 140px;
`;

export const FlexProModalItemContainer = styled.div`
  ${flexbox({
    alignItems: "flex-start",
  })};
  gap: 8px;
`;

export const FlexProColGapContainer = styled.div<{
  $gap: string;
  $onOverviewPage?: boolean;
}>`
  ${flexbox({
    direction: "column",
  })};
  gap: ${({ $gap }) => $gap};
  flex-grow: ${({ $onOverviewPage }) => ($onOverviewPage ? 1 : undefined)};
  justify-content: ${({ $onOverviewPage }) => ($onOverviewPage ? "center" : undefined)};
`;

export const FlexProModalItemIconContainer = styled.div`
  margin-top: 2px;
`;

export const MobileDrawerSection = styled.div`
  ${flexbox({
    direction: "column",
    justifyContent: "space-between",
    alignItems: "center",
  })};
  padding: 20px;
  gap: 24px;
`;

export const MobileBottomContainer = styled.div`
  ${flexbox({
    direction: "column",
  })};
  gap: 12px;
  width: 100%;
`;

export const Dialog = styled(DialogContent)`
  width: auto;
  min-width: 320px;
  max-width: 90vw;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const Checkbox = styled(AtlasCheckbox)`
  button {
    flex-shrink: 0;
  }
`;
