import { useCallback, useState } from "react";

type UseToggleResponse = [boolean, () => void];

export const useToggle = (initialToggle = false): UseToggleResponse => {
  const [on, setOn] = useState(() => initialToggle === true);

  const toggle = useCallback(() => {
    setOn((val) => !val);
  }, []);

  return [Boolean(on), toggle];
};
