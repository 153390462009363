/* eslint-disable
 @typescript-eslint/ban-types,
 @typescript-eslint/naming-convention,
 @typescript-eslint/no-explicit-any,
 @typescript-eslint/no-non-null-assertion,
 prefer-rest-params,
 */

export type { TypePredicate } from "../any/__internal"
export { absorb } from "./shared"
export { flow } from "./flow"
export { pipe } from "./pipe"

export {
  type Assert,
  type Binary,
  type Guard,
  type Guarded,
  type GuardSource,
  type Predicate,
  type Unary,
  all,
  any,
  apply,
  die,
  exhaustive,
  loop,
  guardAll,
  guardAny,
  identity,
  isUnusedParameter,
  isUnary,
  throw_ as throw,
  tuple,
  tupled,
  uncurry,
  untupled,
  UnusedParameter,
};

import type { TypePredicate } from "../any/__internal"
import type { Any } from "../any/exports"

/**
 * A {@link Predicate} is a more general version of a {@link Guard}:
 * it's a function that takes some input `T`, and, based on some
 * internal logic, returns a boolean.
 *
 * For example, {@link Array.prototype.filter} is a function that
 * takes a predicate as an argument and, based on whether the
 * predicate returned true, keeps the argument to which it was
 * applied in the array.
 */
interface Predicate<T> {
  (source: T): boolean
}

/**
 * A {@link TypeGuard} is a special case of a {@link Predicate}:
 * it's a function that takes some input, and, based on some internal
 * logic, returns true or false, and contextually narrows its input
 * from `unknown` to `T`.
 */
type Guard<T> = TypePredicate<[any, T]>

/**
 * Extracts the target type from a {@link Guard}
 */
type Guarded<fn extends Guard<any>> = fn extends Any.TypeGuard<any, infer target> ? target : never

/**
 * Extracts the source type from a {@link Guard}
 */
type GuardSource<fn extends Guard<any>> = fn extends Any.TypeGuard<infer source, any> ? source : never

type Assert<A extends readonly [source: any, target: any]> = never | ((input: A[0]) => asserts input is A[1])

/**
 * A placeholder for an unused argument. Allows you to be explicit, and resolves
 * any ambiguity about whether a user passed `undefined` in place of an
 * optional argument (which can be done accidentally, for example if the value
 * the user _thinks_ is defined, isn't).
 *
 * Meant to be paired with {@link isUnusedParameter}.
 */
type UnusedParameter = typeof UnusedParameter
const UnusedParameter: unique symbol
  = Symbol.for("Guard::UnusedParameter")

/**
 * A type-guard for {@link UnusedParameter}.
 */
const isUnusedParameter = (u: unknown): u is UnusedParameter => u === UnusedParameter


type Unary<Arg = never> = never | readonly [$1: Arg]
type Binary<A = never, B = never> = never | readonly [$1: A, $2: B]

const isUnary
  : <$1>(args: Unary<$1> | Any.Array) => args is Unary<$1>
  = (args): args is Unary => args.length === 1

const apply =
  <A>(a: A) =>
    <B>(f: (a: A) => B): B =>
      f(a)

const identity
  : <T>(t: T) => T
  = (t) => t

const tuple
  : <TS extends Any.Array>(...args: readonly [...TS]) => TS
  = (...args) => args

const untupled
  : <A extends Any.Array, B>(f: (args: A) => B) => (...args: A) => B
  = (f) => (...args) => f(args)

const tupled
  : <A extends Any.Array, B>(f: (...args: A) => B) => (args: A) => B
  = (f) => (args) => f(...args)

function uncurry<A, B, Z = any>(f: (a: A) => (b: B) => Z): {
  <T extends A>(t: T): <U extends B>(u: U) => Z
  <T extends A, U extends B>(t: T, u: U): Z
}
function uncurry<A, B, Z>(f: (a: A) => (b: B) => Z) {
  return <T extends A, U extends B>(t: T, u: U = UnusedParameter as never) =>
    isUnusedParameter(u)
      ? f(t)
      : f(t)(u)
}

const die = (msg: string): never => { throw Error(msg) }

const exhaustive
  : (fnName: string, _: never) => never
  = (fnName, _) =>
    die(`Exhaustive match failed in \`${fnName}\`, received: \n${JSON.stringify(_)}`)

type Eval<T> = ([T] extends [Any.Object] ? { [K in keyof T]: T[K] } : T) | never

type IntersectAll<T extends Any.Array, Acc = unknown>
  = T extends readonly [] ? Acc
  : T extends readonly [infer Hd, ...infer Tl] ? IntersectAll<Tl, Acc & Hd>
  : never
  ;

type ExtractPairs<T extends Any.Array<Guard<any>>>
  = { [ix in keyof T]: [source: GuardSource<T[ix]>, target: Guarded<T[ix]>] }

type Seconds<T extends Any.Array<readonly [any, any, ...any]>> = { [ix in keyof T]: T[ix][1] }

type GuardAll<T extends Any.Array<Guard<any>>>
  = ExtractPairs<T> extends
  | Any.ListOf<Any.Pair, infer L>
  ? TypePredicate<[from: L[number][0], to: Eval<IntersectAll<Seconds<L>>>]>
  : never
  ;

type GuardAny<T extends Any.Array<Guard<any>>>
  = ExtractPairs<T> extends
  | Any.ListOf<Any.Pair, infer L>
  ? TypePredicate<[from: L[number][0], to: L[number][1]]>
  : never
  ;

type CompositePredicateArgs =
  | readonly [Any.Array<Predicate<any>>]
  | Any.Array<Predicate<any>>
  ;

function all<T>(...predicates: Any.Array<Predicate<T>>): Predicate<T>
function all<T>(predicates: Any.Array<Predicate<T>>): Predicate<T>
function all(...predicates: CompositePredicateArgs): (u: unknown) => boolean {
  return (u: unknown) => {
    const flattened = predicates.flat()
    for (const f of flattened) if (!f(u)) return false
    return true
  }
}

function any<T extends Any.Array<Predicate<any>>>(predicates: readonly [...T]): (x: T[number]) => boolean
function any<T extends Any.Array<Predicate<any>>>(...predicates: readonly [...T]): (x: T[number]) => boolean
function any(...predicates: CompositePredicateArgs): unknown {
  return (u: unknown) => {
    const flattened = predicates.flat()
    for (const f of flattened) if (!f(u)) return false
    return true
  }
}

function guardAll<T extends Any.Array<Guard<any>>>(guards: readonly [...T]): GuardAll<T>
function guardAll<T extends Any.Array<Guard<any>>>(...guards: readonly [...T]): GuardAll<T>
function guardAll(...guards: CompositePredicateArgs): unknown {
  return (u: unknown) => {
    const flattened = guards.flat()
    for (const f of flattened) if (!f(u)) return false
    return true
  }
}

function guardAny<T extends Any.Array<Guard<any>>>(guards: readonly [...T]): GuardAny<T>
function guardAny<T extends Any.Array<Guard<any>>>(...guards: readonly [...T]): GuardAny<T>
function guardAny(...guards: CompositePredicateArgs): unknown {
  return (u: unknown) => {
    const flattened = guards.flat()
    for (const f of flattened) if (f(u)) return true
    return false
  }
}

/**
 * {@link loop `fn.loop`} puts a recursive function in tail-position.
 *
 * When in tail-position, a recursive function is able to re-use stack frames for successive calls, which means:
 *
 * - no stack overflows
 * - a more predictable performance profile (linear)
 *
 * See also: {@link https://en.wikipedia.org/wiki/Continuation-passing_style continuation passing style}
 *
 * @category optimization
 */
const loop
  : <A, B>(f: (a: A, next: (a: A) => B) => B) => (a: A) => B
  = (f) => (a) => {
    const next = (a_: typeof a) => f(a_, next)
    return f(a, next)
  }

const throw_ = (...args: any[]): never => {
  throw globalThis.Error(globalThis.JSON.stringify(args.length === 1 ? args[0] : args, null, 2))
}
