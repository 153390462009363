import moment from "moment";

import type { IContractRate } from "@hotel-engine/types/contractRate";

// determines if the reservation is eligible to be canceled
export const isContractRateCancellable = (contractRate?: IContractRate) => {
  if (!contractRate?.cancelBy) {
    return false;
  }

  if (moment(contractRate.cancelBy).diff(moment(), "hours") < 2) {
    return false;
  }

  return true;
};
