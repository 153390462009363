import { Typography } from "@hotelengine/atlas-web";

import * as Styled from "./styles";
import { useExpressBook } from "../../hooks/useExpressBook";

export const ExpressBookOutOfPolicyAlert = () => {
  const { redirectToSearch } = useExpressBook();

  return (
    <Styled.InPolicyAlertContainer data-testid="eb-out-of-policy">
      <Typography variant="body/sm">This room rate is out of your travel policy</Typography>
      <Styled.SearchInPolicyBtn onClick={redirectToSearch}>
        <Typography variant="body/sm" color="linkPrimary">
          Search in-policy rates
        </Typography>
      </Styled.SearchInPolicyBtn>
    </Styled.InPolicyAlertContainer>
  );
};
