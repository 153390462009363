import { forwardRef } from "react";

import { AutoComplete as AntAutoComplete } from "antd";
import type { AutoCompleteProps } from "antd/lib/auto-complete";

import * as Styled from "./styles";

export type {
  AutoCompleteProps,
  DataSourceItemObject,
  DataSourceItemType,
} from "antd/lib/auto-complete";

export type { AutoComplete as TAutoComplete } from "antd";
/**
 * The `AutoComplete` component wraps antd's `<AutoComplete />` component.
 *
 * @remarks Since we are leveraging antd for most components, our internal AutoComplete component does not have any of it's own props. Instead, we are directly referring to the interface definition antd is expecting. This allows us to easily be in alignment with their type definitions and prevents type errors that may arise from maintaining an override interface.
 *
 * @see {@link https://3x.ant.design/components/auto-complete/ AntD AutoComplete Documentation}
 */

/** The GlobalAutoCompleteStyles are so that you can target both the AutoComplete as well as the AutoComplete.Option styles with their default Ant classNames. AutoComplete.Option is appended to the DOM so you'll need to target them via global styles.
 *
 * To target specific AutoComplete components @see {@link https://github.com/HotelEngine/members/blob/main/src/pages/SearchResults/components/FilterPane/components/PropertyNames/styles.ts#L39-L64 PropertyNames/styles}
 */

export const AutoComplete = forwardRef<AntAutoComplete, AutoCompleteProps>(
  function AutoComplete(AutoCompleteArgs, ref) {
    return (
      <>
        <Styled.GlobalAutoCompleteStyles />
        <Styled.AntAutoComplete ref={ref} {...AutoCompleteArgs} />
      </>
    );
  }
);

// This isn't a styled component because you cannot seemingly target this DOM node to style directly. If you want to add a global style, use the GlobalAutoCompleteStyles
export const AutoCompleteOption = AntAutoComplete.Option;
