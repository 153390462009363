/* eslint-disable
 prefer-const,
 @typescript-eslint/naming-convention,
 @typescript-eslint/no-explicit-any,
 @typescript-eslint/no-namespace,
 */
import type { Fn } from "../function/exports";
import type { Any } from "../any/exports";
import { Struct } from "../struct/exports";

export {
  type None,
  type Option,
  type Some,
  type Infer as infer,
  flatMap,
  fromPredicate,
  isNone,
  isSome,
  map,
  match,
  none,
  some,
  struct,
}

/** @category model */
interface Some<T> { _tag: "Some"; value: T }

/** @category model */
interface None { _tag: "None" }

/** @category model */
type Option<T> = None | Some<T>

/** @category inference */
type Infer<F extends Option<unknown>> = [F] extends [Option<infer T>] ? T : never

/** @category constructors */
const some
  : <T>(t: T) => Option<T>
  = (t) => ({ _tag: "Some", value: t })

/** @category constructors */
const none
  : <T = never>() => Option<T>
  = () => ({ _tag: "None" })

/** @category predicates */
const isSome
  : <T>(option: Option<T>) => option is Some<T>
  = (option): option is Some<never> => option._tag === "Some"

/** @category predicates */
const isNone
  : <T>(option: Option<T>) => option is None
  = (option): option is None => option._tag === "None"

/** @category combinators */
const map
  : <T, U>(f: (t: T) => U) => (option: Option<T>) => Option<U>
  = (f) => (option) => isSome(option) ? some(f(option.value)) : option

/** @category combinators */
const flatMap
  : <T, U>(f: (t: T) => Option<U>) => (option: Option<T>) => Option<U>
  = (f) => (option) => isSome(option) ? f(option.value) : option

/** @category constructors */
function fromPredicate<T, U extends T>(predicate: (t: T) => t is U): (value: T) => Option<U>
function fromPredicate<T>(predicate: Fn.Predicate<T>): (value: T) => Option<T>
function fromPredicate<T>(predicate: Fn.Predicate<T>) {
  return (value: T) => predicate(value) ? some(value) : none()
}

/** @category destructors */
const match
  : <A, T, U>(onSome: (a: A) => T, onNone: () => U) => (option: Option<A>) => T | U
  = (onSome, onNone) => (option) => isSome(option) ? onSome(option.value) : onNone()

type Struct<T extends Any.Dict<Option<unknown>>> = never | { [K in keyof T]: Infer<T[K]> }
type SequenceS<T extends Any.Dict<Option<unknown>>> = never | Option<Struct<T>>

const struct
  : <T extends Any.Dict<Option<any>>>(s: T) => SequenceS<T>
  = (s) => {
    const out = Struct.emptyOf<Struct<typeof s>>()
    for (const key in s) {
      if (Object.prototype.hasOwnProperty.call(s, key)) {
        const option = s[key]
        if (isNone(option)) return none()
        out[key] = option.value
      }
    }
    return some(out)
  }
