import storage from "@hotel-engine/storage";

export const EVENT_TYPES = {
  signIn: "signIn",
  signOut: "signOut",
  switchAccount: "switchAccount",
};

const AUTHORIZED_STORAGE_KEY = "Authorized";

const buildAuthEventId = (eventType) => `hotelengine.${eventType}`;

const emitAuthEvent = (eventType: string, reason: string) => {
  const event = new CustomEvent(buildAuthEventId(eventType), {
    detail: { reason },
  });
  globalThis.dispatchEvent(event);
};

const emitAuthStatusChangeEvent = (isAuthorized: boolean) => {
  const eventType = EVENT_TYPES[isAuthorized ? "signIn" : "signOut"];
  const reason = isAuthorized ? "Authorized" : "Unauthorized";

  emitAuthEvent(eventType, reason);
};

export const setAuthStatus = (isAuthorized: boolean) => {
  if (isAuthorized && storage.getItem(AUTHORIZED_STORAGE_KEY) === String(isAuthorized)) {
    // we need to fire switch account since we are already signed in
    storage.setItem(AUTHORIZED_STORAGE_KEY, String(EVENT_TYPES.switchAccount));
  } else {
    storage.setItem(AUTHORIZED_STORAGE_KEY, String(isAuthorized));
  }

  // Storage events are only dispatched to other open windows and tabs.
  // We need to manually trigger the auth for the current tab.
  emitAuthStatusChangeEvent(isAuthorized);
};

export const getAuthStatus = (): boolean => {
  return storage.getItem(AUTHORIZED_STORAGE_KEY) === "true";
};

export const addAuthEventListener = (
  eventType: string,
  callback: EventListenerOrEventListenerObject
): void => {
  globalThis.addEventListener(buildAuthEventId(eventType), callback);
};

export const removeAuthEventListener = (
  eventType: string,
  callback: EventListenerOrEventListenerObject
): void => {
  globalThis.removeEventListener(buildAuthEventId(eventType), callback);
};

const handleAuthStatusChange = ({ key, newValue }: StorageEvent) => {
  if (key !== AUTHORIZED_STORAGE_KEY) return;

  emitAuthStatusChangeEvent(newValue === "true" || newValue === "switchAccount");
};

export const listenForAuthStatusChanges = () => {
  globalThis.addEventListener("storage", handleAuthStatusChange);
};

export const removeAuthStatusChangeListener = () => {
  globalThis.removeEventListener("storage", handleAuthStatusChange);
};
