import type { ReactNode } from "react";

import { QueryClient, QueryClientProvider as RQClientProvider } from "react-query";

// eslint-disable-next-line import/no-named-as-default
import DataClientProvider from "@hotel-engine/contexts/DataContext/DataClientProvider";

export interface IQueryClientProviderProps {
  /** A react node rendered within the component */
  readonly children: ReactNode;
}

export const queryClientOptions = {
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, retry: false, staleTime: 300000 },
  },
};

export const queryClient = new QueryClient(queryClientOptions);

const QueryClientProvider = ({ children }: IQueryClientProviderProps) => {
  return (
    <RQClientProvider client={queryClient}>
      <DataClientProvider>{children}</DataClientProvider>
    </RQClientProvider>
  );
};

export default QueryClientProvider;
