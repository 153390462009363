import styled from "styled-components";

import { typographyMixin } from "@hotel-engine/common/Typography/styles";

export interface IPrivacyPolicyDisclaimer {
  $top?: number | string;
  $left?: number | string;
  $bottom?: number | string;
  $right?: number | string;
}
export const PrivacyPolicyDisclaimer = styled.div<IPrivacyPolicyDisclaimer>`
  ${({ $top }) => $top && `margin-top: ${$top}`};
  ${({ $right }) => $right && `margin-right:${$right}`};
  ${({ $bottom }) => $bottom && `margin-bottom: ${$bottom}`};
  ${({ $left }) => $left && `margin-left: ${$left}`};
  p {
    ${typographyMixin("body-small")};
  }
  a {
    margin-left: 4px;
  }
`;
