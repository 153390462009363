import { Menu } from "antd";
import styled, { css } from "styled-components";

import Tag from "@hotel-engine/common/Tag";
import { colors, ellipsisText } from "@hotel-engine/styles";

import type { TVariants } from "./";

interface ICard {
  noBorder?: boolean;
  error?: boolean;
  $variant?: TVariants;
}

export const Card = styled.div<ICard>`
  border: 1px solid ${colors.grey[200]};
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xl};
  margin-bottom: 16px;
  background: ${colors.white};
  transition: all 0.3s;
  ${({ noBorder }) =>
    noBorder &&
    css`
      border: none;
    `}

  ${({ error }) =>
    error &&
    css`
      border-color: ${colors.khmerCurry};
      background-color: ${colors.voraciousWhite};
    `}

  ${({ $variant }) =>
    $variant === "no-margin" &&
    css`
      margin: 0;
    `}

  .card-content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 24px;
  }

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;

    img {
      width: 40px;
    }
  }

  .label {
    border-radius: 100px /* disable theming (do not replicate) */;
  }

  .card-body {
    display: flex;
    max-width: 80%;

    .copy-content {
      display: flex;
      flex-direction: column;
    }
  }

  .title-wrapper {
    display: flex;
  }

  .card-title {
    ${ellipsisText};
    font-size: ${({ theme }) => theme.legacy.fontSize.md};
    font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
    color: ${colors.grey[800]};
    display: block;
    margin-bottom: 0;
  }

  .status {
    color: ${colors.khmerCurry};
    margin-left: 8px;
  }

  .bullet {
    color: ${colors.piedWagtailGrey};
    margin: 0px 4px;
  }
`;

export const Actions = styled.div`
  button {
    padding: 6px 10px;
  }
`;

export const MenuItem = styled(Menu.Item)`
  > span {
    margin: 8px;
  }

  &:last-of-type {
    color: ${colors.khmerCurry};
  }
`;

export const CardTag = styled(Tag)`
  cursor: auto;
  margin-left: 8px;
`;
