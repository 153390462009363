import { Menu as AntdMenu } from "antd";

/**
 * The `Menu` component wraps antd's `<Menu />` component.
 *
 * @remarks Since we are leveraging antd for most components, our internal Menu component does not have any of it's own props. Instead, we are directly referring to the interface definition antd is expecting. This allows us to easily be in alignment with their type definitions and prevents type errors that may arise from maintaining an override interface.
 *
 * @see {@link https://3x.ant.design/components/menu/ AntD Menu Documentation}
 */
export const Menu = AntdMenu;
export const SubMenu = AntdMenu.SubMenu;
export const MenuItem = AntdMenu.Item;
export const MenuItemGroup = AntdMenu.ItemGroup;

export type { Menu as TMenu } from "antd";

export type { MenuProps, ClickParam } from "antd/lib/menu";
