import * as Styled from "./styles";

interface IProgramLengthWarning {
  visible: boolean;
}

export const ProgramLengthWarning = ({ visible }: IProgramLengthWarning) => (
  <>
    {visible ? (
      <Styled.ProgramLengthWarningContainer>
        <div>
          <Styled.WarningIcon icon={["fal", "info-circle"]} />
        </div>
        <Styled.WarningText data-testid="loyalty-program-length-warning">
          Check your account number and if it's correct, please continue.
        </Styled.WarningText>
      </Styled.ProgramLengthWarningContainer>
    ) : null}
  </>
);
