import momentTimezone from "moment-timezone";

/**
 * helper function to get the abbreviated timezone based on the name that we get from the database
 * @see {@link https://momentjs.com/timezone/docs/ Moment Timezone docs} for reference
 *
 * @param {string} date - date string that we want to base the timezone
 * @param {string} timezoneDatabaseName - string that we get from the database
 * @returns {string} - the abbreviated Timezone or uts offset or an empty string if timezoneDatabaseName is an invalid input
 */
export const getAbbreviatedTimezone = ({
  date = undefined,
  timezoneDatabaseName,
}: {
  date?: string | null;
  timezoneDatabaseName: string;
}): string => {
  const abbreviatedTimezone = momentTimezone(date).tz(timezoneDatabaseName)?.format("z");

  /**
   * there are some timezones that momentTimezone fails to abbreviate,
   * instead only returning the utc offset number
   * hence we return the UTC offset
   */
  const parsedTimezoneInt = parseInt(abbreviatedTimezone);

  // we return the abbreviated timezone if we don't get an offset number and defined
  if (abbreviatedTimezone && isNaN(parsedTimezoneInt)) {
    return abbreviatedTimezone;
  }

  if (parsedTimezoneInt > 0) {
    return `UTC+${parsedTimezoneInt}`;
  }
  if (parsedTimezoneInt === 0) {
    return "UTC";
  }
  if (parsedTimezoneInt < 0) {
    return `UTC${parsedTimezoneInt}`;
  }

  return "";
};
