import { Box, Button } from "@hotelengine/atlas-web";
import styled from "styled-components";

export const InPolicyAlertContainer = styled(Box).attrs(() => ({
  backgroundColor: "accentRedLightest",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: 4,
  paddingBottom: 4,
  paddingRight: 24,
  paddingLeft: 24,
}))`
  min-height: 36px;
`;

export const SearchInPolicyBtn = styled(Button).attrs(() => ({
  variant: "plain",
  trailingIcon: "chevron-right",
  size: "xs",
}))`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;
