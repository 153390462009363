import type { ReactNode } from "react";
import { useEffect, useState } from "react";

import { createPortal } from "react-dom";

import { zIndex } from "@hotel-engine/styles";

import * as Styled from "@hotel-engine/app/FullscreenModal/styles";
import { Typography } from "@hotelengine/atlas-web";

interface IFullScreenModalProps {
  /** Extract the open prop so we can hide the overflow of the body when the modal is open */
  visible: boolean;
  /** Padding */
  padding?: string;
  /** Title of the modal */
  title?: string;
  /** Controls if the modal contains a done button */
  hasDoneButton?: boolean;
  /** Controls if the close button is rendered to the left or the right */
  headerReverse?: boolean;
  /** When done or close is clicked, this callback will be called */
  onClose?: (closeFromDone?: boolean) => void;
  /** Content for the modal */
  children: ReactNode;
  /** ID for the portal */
  portalID?: string;
  /** z-index override */
  modifiedZIndex?: number;
  /** Determines if the modal will be a portal or not */
  isPortal?: boolean;
  /** Determines if the modal will animate from the bottom */
  animateFromBottom?: boolean;
  /** Custom header */
  customHeader?: ReactNode;
}

/**
 * The `FullscreenModal` component is used to create any needed full screen modal.
 *
 * @remarks The modal creates a portal in the root of the body, the id of which can be defined if desired. Scrolling of the body is disabled while the modal is visible
 * @see {@link https://www.figma.com/file/eHm0GKtrMxDQ74elnwjA4t/Dashboard-UX?node-id=82%3A0 Design Specs}
 */
const FullscreenModal = ({
  animateFromBottom,
  customHeader,
  visible,
  padding,
  title,
  hasDoneButton,
  onClose,
  headerReverse = false,
  isPortal = true,
  children = true,
  portalID = "root-portal",
  modifiedZIndex = zIndex.modal,
}: IFullScreenModalProps) => {
  // Create the portal container upon initialization
  const [container] = useState(() => {
    const portal = document.createElement("div");
    portal.id = portalID;
    return portal;
  });

  // Append the portal to the body
  useEffect(() => {
    isPortal && document.body.appendChild(container);

    // Remove the container on component dismount
    return () => {
      isPortal && document.body.removeChild(container);
    };
  }, [container, isPortal]);

  /**
   * Prevent scrolling of the body when the modal is visible
   */
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "initial";
    }
  }, [visible]);

  const closeButton = (
    <Styled.CloseAtRight
      icon="xmark"
      onClick={() => {
        if (onClose) onClose();
      }}
      aria-label="Close the modal"
    />
  );

  const titleSection = title && visible && (
    <Styled.TitleSection $headerReverse={headerReverse}>
      {!headerReverse && closeButton}
      <Typography
        as="h3"
        color="foregroundPrimary"
        style={{ margin: headerReverse ? 0 : "auto" }}
        variant="heading/lg"
      >
        {title}
      </Typography>
      {!!headerReverse && closeButton}
    </Styled.TitleSection>
  );

  const doneButton = hasDoneButton && visible && (
    <Styled.Done
      onClick={() => {
        if (onClose) onClose(true);
      }}
      color="primary"
      aria-label="Done with the modal"
    >
      Done
    </Styled.Done>
  );

  const wrapper = (innerContent) =>
    isPortal ? createPortal(innerContent, container) : innerContent;

  return wrapper(
    <Styled.FullscreenModal
      $isVisible={visible}
      $modifiedZIndex={modifiedZIndex}
      $animateFromBottom={animateFromBottom}
    >
      <Styled.DisableScrollOnBody disableBodyScroll={visible} />
      {customHeader ?? titleSection}
      <Styled.Content padding={padding} hasDoneButton={hasDoneButton}>
        {!!visible && children}
      </Styled.Content>
      {doneButton}
    </Styled.FullscreenModal>
  );
};

export default FullscreenModal;
