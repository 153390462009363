/*
  eslint-disable
  @typescript-eslint/no-shadow,
  @typescript-eslint/no-namespace,
  @typescript-eslint/naming-convention,
 */
import * as Core from "../shared/core"
import type { Prop } from "../shared/core"
import type { Any } from "@hotel-engine/data"
import { Fn } from "@hotel-engine/data"
import { Struct } from "../../data/src/struct/exports";

export {
  type ShowableTree,
  show,
}

export interface Dict<T> { [x: Any.Key]: T }

type ShowableTree =
  | Prop
  | ReadonlyArray<ShowableTree>
  | Dict<ShowableTree>
  ;

/**
 * As a first pass, we preprocess the tree to create an intermediate
 * representation.
 *
 * The job of this preprocessing step is to give each node a tag,
 * which simplifies (and separates) the task of identifying a node
 * from the task of interpreting a node.
 */
type PreprocessedTree =
  | StringNode
  | NumberNode
  | ArrayNode
  | ObjectNode
  ;

interface StringNode { type: "string", children: string }
interface NumberNode { type: "number", children: number }
interface ArrayNode { type: "array", children: ReadonlyArray<PreprocessedTree> }
interface ObjectNode { type: "object", children: Dict<PreprocessedTree> }

const tag
  : <Tag extends string, T>(tag: Tag, children: T) => { type: Tag, children: T }
  = (tag, children) => ({ type: tag, children })

/**
 * Moves the recursive call to tail position.
 * This technique is an example of
 * {@link https://en.wikipedia.org/wiki/Continuation-passing_style | continuation passing style}.
 */
const loop
  : <A, B>(f: (a: A, cont: (a: A) => B) => B) => (a: A) => B
  = (f) =>
    (a) => {
      const next = (_: typeof a) => f(_, next)
      return f(a, next)
    }

/**
 * This step is responsible for "tagging" our tree nodes.
 * By separating this step, we avoid having to continually
 * re-solve this problem later on.
 */
const preprocessTree
  : <Tree extends ShowableTree>(tree: Tree) => PreprocessedTree
  = (tree) => {
    const go = loop<ShowableTree, PreprocessedTree>(
      (a, cont) => {
        if (Core.is.number(a)) return tag("number", a)
        else if (Core.is.string(a)) return tag("string", a)
        else if (Core.is.array(a)) {
          const children = a.map(cont)
          return tag("array", children)
        }
        else if (Core.is.struct(a)) {
          const children = Struct.map(cont)(a)
          return tag("object", children)
        }
        else return Fn.exhaustive(`preprocessTree`, a)
      }
    )

    return go(tree)
  }

const show
  : <Tree extends ShowableTree>(tree: Tree) => string
  = <Tree extends ShowableTree>(tree: Tree): string => {
    const go = loop<PreprocessedTree, string>(
      (a, cont) => {
        if (a.type === "number") return `${a.children}`
        else if (a.type === "string") return `"${a.children}"`
        else if (a.type === "array") {
          const elem = a.children.map(cont)
          return `[${elem.join(", ")}]`
        }
        else if (a.type === "object") {
          const elem = Struct.map(cont)(a.children)
          const entries = Struct.entries(elem)
          return entries.length === 0
            ? "{}"
            : `{ ${Struct.entries(elem).map(([k, v]) => `${k}: ${v}`).join(", ")} }`
        }
        else return Fn.exhaustive(`show`, a)
      }
    )

    return go(preprocessTree(tree))
  }
