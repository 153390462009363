import styled, { createGlobalStyle, css } from "styled-components";

import { Icon } from "@hotel-engine/common/Icon";
import { colors, sizes } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";

interface IDateRangeProps {
  checkInError: boolean;
  checkOutError: boolean;
}

// This is used to target the full screen portal on mobile as well as the normal styles because it appends the DOM nodes outside of the scope of this component on mobile. There are some shared styles so to removed duplicated efforts some generalized styles are here as well */}
export const CalendarStyleOverrides = createGlobalStyle`
  /* Moves the portal in front of the modal when the date pickers needs to be available in conjunction with said "CheckoutVerificationModal" */
  .SingleDatePicker_picker__portal {
    z-index: 1001 /* disable theming (do not replicate) */;
  }
   /* Calendar day and interactive states  */
   .CalendarDay {
    border: none;
    &.CalendarDay__default {
      &:hover {
        background: ${colors.grey[100]};
      }
    }
    &.CalendarDay__selected,
    &.CalendarDay__selected:active {
      border: none;
      background: ${colors.retroBlue};
      color: ${colors.white};
      &:hover {
        background: ${colors.denim};
        border: none;
      }
    }
    &.CalendarDay__blocked_out_of_range {
      cursor: not-allowed;
    }
  }
  /* Calendar month title (i.e. January, March, October) */
  .CalendarMonth_caption {
    color: ${colors.black};
    font-size: ${({ theme }) => theme.legacy.fontSize.md};
    padding-top: 13px;
    strong {
      font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
    }
  }
`;

// Wraps wraps the label and the input elements together and lives inside of the InputGroupWrapper)
export const InputWrapper = styled.div`
  width: calc(50% - 10px);
  @media screen and (max-width: ${sizes.breakpoints.md}) {
    width: 100%;
    &:first-child {
      margin-bottom: 15px;
    }
  }
  label,
  .SingleDatePicker,
  .SingleDatePickerInput {
    display: block;
    width: 100%;
  }
`;

// Wraps both of the data picker elements as a whole (including label and input)
export const InputGroupWrapper = styled.div<IDateRangeProps>`
  ${flexbox({ justifyContent: "space-between" })};
  @media screen and (max-width: ${sizes.breakpoints.md}) {
    flex-direction: column;
  }
  /* <div> that wraps the <input /> and calendar icon but excludes the <label /> */
  .SingleDatePicker {
    position: relative;
    &Input_calendarIcon {
      position: absolute;
      top: 50%;
      left: 15px;
      z-index: ${({ theme }) => theme.legacy.zIndex.aboveBackdrop};
      transform: translateY(-50%);
      margin: 0;
      padding: 0;
    }
    /* Moves up the calendar to cover the input when the screen isn't tall enough */
    &_picker {
      @media screen and (max-height: 890px) {
        transform: translateY(-66px);
      }
    }
  }
  /* <div> node that surrounds the <input /> element */
  .DateInput {
    width: 100%;
    /* Arrow on top of the calendar hidden when the screen isn't tall enough */
    &_fang {
      transform: translateY(1px);
      &Stroke {
        stroke: ${colors.grey[100]};
        fill: ${colors.white};
      }
      @media screen and (max-height: 890px) {
        display: none;
      }
    }
    /* Actual <input /> element */
    .DateInput_input {
      color: ${colors.black};
      font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
      font-size: ${({ theme }) => theme.legacy.fontSize.md};
      border: 1px solid ${colors.grey[200]};
      border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
      padding: 10px 10px 10px 40px;
      cursor: pointer;
      &__disabled {
        background: ${colors.grey[50]};
        cursor: not-allowed;
        font-style: normal;
      }
    }
  }
  /* Display for days of the week */
  .DayPicker_weekHeader {
    height: 40px;
    border-top: 1px solid ${colors.grey[200]};
    @media screen and (min-width: ${sizes.breakpoints.xl}) {
      top: 55px;
      height: auto;
      border: none;
    }
  }
  /* Left and right arrows for month navigation */
  .DayPickerNavigation_button__default {
    border: none;
  }
  /* Box around the expanded calendar */
  .DayPicker__withBorder {
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xl};
    box-shadow: 0px 8px 24px 1px rgba(0, 0, 0, 0.12),
      0px 1px 4px rgba(0, 0, 0, 0.04);
  }
  .DateRangePickerInput_arrow {
    color: inherit;
  }

  /* NOTE: This may be deleted depending on how we deal with error handling once we hook up the BE */
  ${({ checkOutError }) =>
    checkOutError &&
    css`
      .DateInput_input[id="new-check-out-date"] {
        color: ${colors.tomato};

        &::placeholder {
          color: ${colors.tomato};
          opacity: 1;
        }
      }
    `}
`;

export const DateSelectorIcon = styled(Icon)`
  font-size: ${({ theme }) => theme.legacy.fontSize.md};
`;
