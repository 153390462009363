import { useEffect, useState } from "react";

import { useFormikContext } from "formik";

import OverlappingResMessage from "@hotel-engine/app/GuestSelection/OverlappingResMessage";
import type { IOverlappingContract } from "@hotel-engine/types/contractRate";

import { useAppSelector } from "store/hooks";
import type {
  IExpressBookCheckoutForm,
  IExpressBookContractRate,
} from "@hotel-engine/types/expressBook";
import { useIsLoyaltyEligibleExpressBook } from "pages/Checkout/LegacyLodging/hooks/useRoomIsLoyaltyEligible";
import { useOverlappingReservationExpressBook } from "pages/Checkout/LegacyLodging/hooks/useOverlappingReservation";
import { ExpressBookGuestRoom } from "./components/ExpressBookGuestRoom/ExpressBookGuestRoom";

import * as Styled from "./styles";
import type { IRoomRate } from "@hotel-engine/types/room";
import type { IPropertyLegacy } from "@hotel-engine/types/property";
import type { IPropertyLoyaltyRewards } from "@hotel-engine/types/booking";

export interface IExpressBookGuestSelectionParams {
  contractRate: IExpressBookContractRate;
  propertyData: IPropertyLegacy;
  roomRate: IRoomRate;
}

export const ExpressBookGuestSelection = ({
  contractRate,
  propertyData,
  roomRate,
}: IExpressBookGuestSelectionParams) => {
  const EXPRESS_BOOK_ROOM_COUNT = 1;

  const user = useAppSelector((state) => state.Auth.user);
  const form = useFormikContext<IExpressBookCheckoutForm>();

  const isLoyaltyEligible = useIsLoyaltyEligibleExpressBook(roomRate);

  const propertyLoyaltyRewards: IPropertyLoyaltyRewards = {
    id: propertyData?.loyaltyRewardId,
    name: propertyData?.loyaltyRewardName,
    programNumberLength: propertyData?.loyaltyRewardProgramNumberLength,
  };

  const { selectedTravelerHasOverlappingRes } = useOverlappingReservationExpressBook(contractRate);
  const [overlappingContractData, setOverlappingContractData] = useState<IOverlappingContract[]>(
    []
  );

  // This useEffect assigns starter hotel loyalty info, this data isn't ready on first render for formik's initialization so has to be done in a useEffect
  useEffect(() => {
    const matchingLoyaltyRewards =
      isLoyaltyEligible &&
      user?.userLoyaltyRewards?.find(
        (reward) => reward.loyaltyRewardName === propertyLoyaltyRewards.name
      );

    if (!form.values.guestList || !matchingLoyaltyRewards || user?.role !== "user") {
      return;
    }

    const newGuestList = [...form.values.guestList];
    newGuestList[0].loyaltyInfo = matchingLoyaltyRewards || null;
    form.setFieldValue("guestList", newGuestList);
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoyaltyEligible, propertyLoyaltyRewards.name, user?.userLoyaltyRewards]);

  const rooms: React.ReactElement[] = [];
  for (let index = 0; index < EXPRESS_BOOK_ROOM_COUNT; index++) {
    rooms.push(
      <Styled.ExpressBookGuestSelectionContainer key={index}>
        <ExpressBookGuestRoom
          index={index}
          propertyLoyaltyRewards={propertyLoyaltyRewards}
          isLoyaltyEligible={isLoyaltyEligible}
          setOverlappingContractData={setOverlappingContractData}
        />
        {!!(
          selectedTravelerHasOverlappingRes ||
          // TODO: clean this up
          Boolean((form.values.guestList || [])[index]?.overlappingContracts?.length)
        ) && (
          <OverlappingResMessage
            data={
              overlappingContractData.length
                ? overlappingContractData
                : contractRate?.overlappingContracts
            }
          />
        )}
      </Styled.ExpressBookGuestSelectionContainer>
    );
  }

  useEffect(() => {
    globalThis.sessionStorage.setItem("eb-guest-cache", JSON.stringify(form.values.guestList));
  }, [form.values.guestList]);

  return <>{rooms}</>;
};
