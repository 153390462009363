import type { ShelfOrdinalType } from "@hotelengine/core-booking-web";
import type {
  IFlightsSearchFilters,
  ShelfSummaryKey,
} from "store/Flights/FlightsFilters/flights.filters.types";

export const AllAirlinesFilter = "All";

export enum FlightsSortOptions {
  Cheapest = "Cheapest",
  Fastest = "Fastest",
  EarliestDeparture = "Earliest Departure",
  LatestDeparture = "Latest Departure",
  EarliestArrival = "Earliest Arrival",
  LatestArrival = "Latest Arrival",
}

export enum StopFilterOptions {
  any = "any",
  oneOrNonStop = "oneOrNonStop",
  nonStop = "nonStop",
}

export enum ShelfBlockKey {
  all = "all",
  basic = "basic",
  standard = "standard",
  enhanced = "enhanced",
  premium = "premium",
  luxury = "luxury",
}

export enum flightsStoreFilterKeys {
  stops = "stops",
  inPolicy = "inPolicy",
  priceRange = "priceRange",
  duration = "duration",
  takeoffTime = "takeoffTime",
  landingTime = "landingTime",
  airlines = "airlines",
  selectedSort = "selectedSort",
  shelf = "shelf",
}

export const shelfSummaryKeyMap: Record<ShelfOrdinalType, ShelfSummaryKey> = {
  1: "shelf1FareSummary",
  2: "shelf2FareSummary",
  3: "shelf3FareSummary",
  4: "shelf4FareSummary",
  5: "shelf5FareSummary",
};

export const shelfBlockKeyMap: Record<ShelfSummaryKey, ShelfBlockKey> = {
  shelf1FareSummary: ShelfBlockKey.basic,
  shelf2FareSummary: ShelfBlockKey.standard,
  shelf3FareSummary: ShelfBlockKey.enhanced,
  shelf4FareSummary: ShelfBlockKey.premium,
  shelf5FareSummary: ShelfBlockKey.luxury,
};

export const shelfBlockOrdinalMap: Partial<Record<ShelfBlockKey, ShelfOrdinalType | null>> = {
  basic: 1,
  standard: 2,
  enhanced: 3,
  premium: 4,
  luxury: 5,
};

const airlineFilterInitialState = {
  selected: false,
  count: 0,
};

const initialFlightDurationState = {
  ceil: 100000, // P1DT2H3M (1 day, 2 hours, and 3 minutes)
  floor: 0,
  iso8601Ceil: null,
  iso8601Floor: null,
  userMax: null,
  userMin: null,
};

export const FLIGHT_TIMES_CEIL = 1439; //24 hours * 60 min - 1 min
const initialFlightTimesState = {
  ceil: FLIGHT_TIMES_CEIL,
  floor: 0,
  userMax: null,
  userMin: null,
};

export const initialFlightsFilterState: IFlightsSearchFilters = {
  [flightsStoreFilterKeys.stops]: {
    selected: StopFilterOptions.any,
  },
  [flightsStoreFilterKeys.inPolicy]: {
    selected: true,
  },
  [flightsStoreFilterKeys.priceRange]: {
    ceil: 100000,
    floor: 0,
    userMax: null,
    userMin: null,
  },
  [flightsStoreFilterKeys.duration]: initialFlightDurationState,
  takeoffTime: initialFlightTimesState,
  landingTime: initialFlightTimesState,
  airlines: {
    All: airlineFilterInitialState,
    AA: airlineFilterInitialState, // Zero count are not shown
    DL: airlineFilterInitialState,
    WN: airlineFilterInitialState,
    NK: airlineFilterInitialState,
    UA: airlineFilterInitialState,
  },
  [flightsStoreFilterKeys.selectedSort]: FlightsSortOptions.Cheapest,
  [flightsStoreFilterKeys.shelf]: {
    all: {
      selected: true,
      value: ShelfBlockKey.all,
      ordinal: null,
      label: "All",
    },
    basic: {
      selected: false,
      value: ShelfBlockKey.basic,
      ordinal: 1,
      label: "Basic",
    },
    standard: {
      selected: false,
      value: ShelfBlockKey.standard,
      ordinal: 2,
      label: "Standard",
    },
    enhanced: {
      selected: false,
      value: ShelfBlockKey.enhanced,
      ordinal: 3,
      label: "Enhanced",
    },
    premium: {
      selected: false,
      value: ShelfBlockKey.premium,
      ordinal: 4,
      label: "Premium",
    },
    luxury: {
      selected: false,
      value: ShelfBlockKey.luxury,
      ordinal: 5,
      label: "Luxury",
    },
  },
};

export const durationRegex = /P(?:(\d+)D)?T(?:(\d+)H)?(?:(\d+)M)?/;

export const stopFilterOptions = [
  {
    label: "Any",
    value: StopFilterOptions.any,
  },
  {
    label: "1 or nonstop",
    value: StopFilterOptions.oneOrNonStop,
  },
  {
    label: "nonstop",
    value: StopFilterOptions.nonStop,
  },
];
