import type { ReactNode } from "react";
import config from "config";
import { Auth0Provider } from "@auth0/auth0-react";
import { Auth0ContextConsumer } from "./Auth0ContextConsumer";
import { useAppDispatch } from "store/hooks";
import { handleAuth0Callback } from "./handleAuth0Callback";
import { MFA_SCOPES } from "../../../pages/Settings/components/AccountSecurity";
import { DEFAULT_SCOPES } from "@hotel-engine/utilities/auth/helpers";

interface IAuth0ContextProviderProps {
  /** React children */
  children: ReactNode;
}

export const Auth0ContextProvider = ({ children }: IAuth0ContextProviderProps) => {
  const dispatch = useAppDispatch();
  return (
    <Auth0Provider
      domain={config.auth0Domain}
      clientId={config.auth0ClientId}
      authorizationParams={{
        audience: config.auth0Audience,
        scope: `${DEFAULT_SCOPES} ${MFA_SCOPES}`,
      }}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      onRedirectCallback={handleAuth0Callback(dispatch)}
    >
      <Auth0ContextConsumer>{children}</Auth0ContextConsumer>
    </Auth0Provider>
  );
};

export default Auth0ContextProvider;
