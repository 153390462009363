import styled from "styled-components";

import { AutoComplete } from "@hotel-engine/common/AutoComplete";
import { Input } from "@hotel-engine/common/Inputs";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { colors } from "@hotel-engine/styles";

export const SearchGuestInput = styled(Input)`
  input.ant-input.ant-input-lg {
    height: 64px;
    padding: 16px;
    padding-left: 44px;
  }
`;

export const SearchGuestAutoComplete = styled(AutoComplete)<{
  $error?: boolean;
}>`
  width: 100%;
  .ant-input {
    ${({ $error }) => $error && `border-color: ${colors.red[400]} !important;`}
  }
`;

export const GuestError = styled.p`
  ${typographyMixin("body-small")}
  color: ${colors.red[400]};
  margin: 0;
`;
