import { FlexProSignUpModal } from "@hotel-engine/app/FlexProBanner/components/FlexProSignUpModal";
import { routes } from "@hotel-engine/constants";
import { useFlexProPricingQuery } from "@hotel-engine/react-query/flexPro/useFlexProPricingQuery";
import { colors } from "@hotel-engine/styles";
import { formatCurrency } from "@hotel-engine/utilities";
import { ampli } from "ampli";
import { useNotificationVisibility } from "pages/CompanySettings/components/hooks/useNotificationVisibility";
import useIsMobile from "pages/Dashboard/hooks/useIsMobile";
import { useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import { useAppSelector } from "store/hooks";
import useAdminExperienceFlag from "../../EnhanceAdminExperience/useAdminExperienceFlag";
import * as Styled from "./styles";
import {
  Button,
  Dialog,
  DialogContent,
  Icon,
  IconButton,
  Typography,
} from "@hotelengine/atlas-web";

export const FlexProAnnouncementModal = () => {
  const isMobile = useIsMobile();
  const [visible, setVisible] = useState(true);
  const flexProPricing = useFlexProPricingQuery();
  const monthlyCost = flexProPricing?.data?.month.discountAmount || 200;
  const user = useAppSelector((state) => state.Auth.user);
  const { displayWelcomeModalCriteria } = useAdminExperienceFlag();
  const currencyCode = user?.business.currencyCode || "USD";

  const { status, updateVisibilityStatus } = useNotificationVisibility("FlexProAnnouncementModal");

  if (status !== "show") return null;

  const handleDismissModal = () => {
    setVisible(false);
    updateVisibilityStatus("dismissed", 0);
  };

  const handleJoinFlexPro = () => {
    setVisible(false);
  };

  // content
  const title = `Total flexibility for just ${formatCurrency(
    monthlyCost || 200,
    false,
    currencyCode
  )}/month.`;
  const textContext = [
    "Every booking is automatically covered, so you'll never pay for another unused night",
    "Hassle-free changes and cancellations, no questions asked",
    "Refunds are instant and automatic (no phone calls needed)",
  ];
  const content = (
    <>
      <Styled.FlexProImageBanner
        alt="FlexPro Announcement Modal"
        src="assets/flex-pro/FlexProAnnouncementModal.svg"
      />
      <Styled.TextContainer>
        <Typography variant="heading/lg" style={{ marginBottom: 16, color: colors.black }}>
          {title}
        </Typography>
        <Styled.BulletList>
          {textContext.map((text, key) => (
            <Styled.BulletContainer key={key}>
              <Icon name="check" style={{ flexShrink: 2, marginTop: 4 }} />
              <Typography as="li" variant="body/md" style={{ color: colors.black, flex: 1 }}>
                {text}
              </Typography>
            </Styled.BulletContainer>
          ))}
        </Styled.BulletList>
        <Styled.ButtonContainer $isMobile={isMobile}>
          <Button
            asChild
            variant="outlined"
            onClick={() => {
              handleDismissModal();
              ampli.viewFlexProOverviewPage({
                businessId: user?.business.id,
                userId: user?.id,
                previousPage: "Flex Pro Announcement Modal",
              });
            }}
          >
            <Typography
              variant="body/md"
              as="a"
              href={routes.flexPassFAQ + "?int=true"}
              rel="noreferrer"
              target="_blank"
            >
              Learn More
            </Typography>
          </Button>
          <FlexProSignUpModal>
            <Button onClick={handleJoinFlexPro}>Join FlexPro</Button>
          </FlexProSignUpModal>
        </Styled.ButtonContainer>
      </Styled.TextContainer>
    </>
  );

  const closeButton = (
    <IconButton
      onClick={handleDismissModal}
      icon="xmark"
      color="primary"
      variant="outlined"
      data-testid="close-button"
      style={{ marginRight: 16, marginTop: 16 }}
    />
  );

  if (isMobile) {
    return (
      <BottomSheet
        data-testid="flexPro-announcement-modal"
        open={visible}
        blocking={true}
        header={false}
      >
        <Styled.BottomSheetContentContainer>
          {closeButton}
          {content}
        </Styled.BottomSheetContentContainer>
      </BottomSheet>
    );
  }

  if (displayWelcomeModalCriteria) return null;

  return (
    <Dialog isOpen={visible} data-testid="flexPro-announcement-modal">
      <DialogContent>{content}</DialogContent>
    </Dialog>
  );
};
