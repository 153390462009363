import { useState, useCallback } from "react";

import { useSystemOutages } from "@hotel-engine/hooks/useSystemOutages";
import { Alert, Box, Typography } from "@hotelengine/atlas-web";

const onFailure = {
  title: "",
  body: "",
};

const SystemOutageBanner = () => {
  const { payload } = useSystemOutages(onFailure);
  const { title, body } = payload;
  const [isVisible, setIsVisible] = useState(true);

  const onDismiss = useCallback(() => setIsVisible(false), []);

  if (!isVisible || !Boolean(title && body)) {
    return null;
  }

  return (
    <Box position="fixed" zIndex="modal" style={{ width: "100%" }}>
      <Alert
        title={title}
        leadingIcon="triangle-exclamation--solid"
        sentiment="critical"
        onDismiss={onDismiss}
      >
        <Typography variant="body/md">{body}</Typography>
      </Alert>
    </Box>
  );
};

export { SystemOutageBanner };
