import type { UseQueryOptions } from "react-query";

import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { IOverlappingContract } from "@hotel-engine/types/contractRate";

import { useApi } from "../useApi";
import { useExtendedQuery } from "../useExtendedQuery";
import { endpoints } from "../constants";

interface IOverlappingContractParams {
  bookableId: string;
  bookableType: "guest" | "user";
  checkIn: string;
  checkOut: string;
}

const useOverlappingContractsQuery = (
  params: IOverlappingContractParams,
  options?: UseQueryOptions<IOverlappingContract[], IErrorResponse, IOverlappingContract[]>
) => {
  const get = useApi("get");
  const endpoint = endpoints.overlappingContracts;

  return useExtendedQuery(
    [endpoint, params],
    () => get<IOverlappingContract[]>(endpoint, params),
    options
  );
};

export { useOverlappingContractsQuery };
