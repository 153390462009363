import moment from "moment";
import { useAppSelector } from "store/hooks";
import { selectSearchId } from "store/Search/SearchResults/selectors";

import * as Styled from "./styles";
import { Formik } from "formik";
import { noop } from "lodash";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import { useRef } from "react";
import { ampli } from "ampli";

interface IExpressBookDatePickerProps {
  handleDatesUpdate: (
    dates: {
      checkIn: string;
      checkOut: string;
    },
    onSuccessCallback?: () => void,
    isDateChange?: boolean
  ) => void;
}

export const ExpressBookDatePicker = ({ handleDatesUpdate }: IExpressBookDatePickerProps) => {
  const initialCheckin = useAppSelector((state) => state.ExpressBook.checkIn);
  const initialCheckout = useAppSelector((state) => state.ExpressBook.checkOut);
  const changeDatesFFEnabled = useIsFeatureFlagOn("express_book_change_dates");
  const searchId = useAppSelector(selectSearchId) ?? undefined;
  const originalDatesRef = useRef({
    checkIn: initialCheckin,
    checkOut: initialCheckout,
  });

  if (changeDatesFFEnabled) {
    return (
      <Styled.ExpressBookInteractiveDatePickerContainer>
        <Formik
          initialValues={{
            checkin: initialCheckin ? moment(initialCheckin) : null,
            checkout: initialCheckout ? moment(initialCheckout) : null,
          }}
          onSubmit={(values) => {
            if (values.checkin && values.checkout) {
              const formattedCheckin = values.checkin.format("YYYY-MM-DD");
              const formattedCheckout = values.checkout.format("YYYY-MM-DD");

              handleDatesUpdate(
                {
                  checkIn: formattedCheckin,
                  checkOut: formattedCheckout,
                },
                noop,
                true
              );
            }
          }}
        >
          {/* this pattern will be refactored as part of migrating off formik in FEN-10147 */}
          {({ touched, handleSubmit, setTouched, values }) => (
            <>
              <Styled.ExpressBookDatePicker
                handleMobileSubmit={handleSubmit}
                originalDates={originalDatesRef.current}
              />
              <Styled.UpdateSearchBtn
                onClick={() => {
                  ampli.clickContinueWithNewDatesInExpressBook({
                    searchId: searchId,
                    originalCheckIn: originalDatesRef.current.checkIn || "",
                    originalCheckOut: originalDatesRef.current.checkOut || "",
                    newCheckIn: values.checkin ? values.checkin.format("YYYY-MM-DD") : "",
                    newCheckOut: values.checkout ? values.checkout.format("YYYY-MM-DD") : "",
                  });
                  handleSubmit();
                  setTouched({});
                }}
                disabled={!touched || Object.keys(touched).length === 0}
              >
                Update search
              </Styled.UpdateSearchBtn>
            </>
          )}
        </Formik>
      </Styled.ExpressBookInteractiveDatePickerContainer>
    );
  } else {
    const FORMAT_STR = "ddd, MMM D, YYYY";
    const formattedCheckin = moment(initialCheckin).format(FORMAT_STR);
    const formattedCheckout = moment(initialCheckout).format(FORMAT_STR);

    return (
      <Styled.ExpressBookDatePickerContainer>
        <span>
          <Styled.CalendarIcon />
          <span>{formattedCheckin}</span>
        </span>
        <span>
          <Styled.RightArrowIcon />
        </span>
        <span>{formattedCheckout}</span>
      </Styled.ExpressBookDatePickerContainer>
    );
  }
};
