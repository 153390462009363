import { useFormikContext } from "formik";

import type { ICurrency } from "@hotel-engine/constants";
import { formatCurrency } from "@hotel-engine/utilities";

import type { ICheckoutFormData } from "../../validation";
import { useContractRoomData } from "./useContractRoomData";
import { useAppSelector } from "store/hooks";

/**
 * Calculates and returns important booking costs and values
 *
 * @param {ICurrency} currencyCode - Currency code for current user/business
 * @param {boolean} [limitNegativeTotal=false] - (Optional) Prevents the totalCost from being less than $0.00, defaults to `false`
 */
export const usePricingCalculation = (currencyCode: ICurrency, limitNegativeTotal = false) => {
  const { values } = useFormikContext<ICheckoutFormData>();
  const { contractRate } = useContractRoomData();
  const user = useAppSelector((state) => state.Auth.user);
  const isFlexProEnrolled = user?.business.flexProEnrolled || false;
  if (!contractRate) return { formattedTotal: "", totalCost: 0, customerTotal: 0 };

  const totalCost = +(
    (values.isFlexEnabled ? contractRate.flexCancellationCost : 0) +
    (values.isFlexEnabled && isFlexProEnrolled ? -contractRate.flexCancellationCost : 0) +
    (values.includeIncidentalCharges ? contractRate.totalWithIncidentals : contractRate.total)
  ).toFixed(2);

  let totalDue = +(totalCost - values.rewardsPoints - values.travelCredits);

  if (limitNegativeTotal && totalDue < 0) {
    totalDue = 0.0;
  }

  // Rounding Fix for Float Edge Cases
  totalDue = Math.round(totalDue * 100) / 100;

  const formattedTotal = formatCurrency(totalDue.toFixed(2), true, currencyCode);

  return {
    formattedTotal,
    totalCost,
    customerTotal: totalDue,
  };
};
