import type { IUser } from "@hotel-engine/types/user";

export default function getUserDistanceUnits(user: IUser | null): "mi" | "km" {
  if (user?.distanceUnit) {
    return user.distanceUnit;
  } else if (user?.business.currencyCode && user?.business.currencyCode === "EUR") {
    return "km";
  }
  return "mi";
}
