import { endpoints } from "../constants";
import { getClient } from "../../client";
import { getAccessToken } from "../../utilities/auth/getToken";

export interface IUserActiveApiResponse {
  /**
   * The date and time when the user's token will expire.
   * The format is a timestamp, measured in seconds (not ms).
   * Example:
   * ```
   * // tokenExpiresAt = 1695221917 = Wed Sep 20 2023 10:58:37 GMT-0400
   * new Date(tokenExpiresAt * 1000)
   * moment(tokenExpiresAt * 1000)
   * ```
   */
  tokenExpiresAt: number | null;
}

export async function fetchSessionExpirationTimeQuery(): Promise<
  IUserActiveApiResponse["tokenExpiresAt"]
> {
  /**
   * We noticed some instance after an employee impersonates another
   * user, that there was some race condition where the token was
   * `null` in local storage, but redux still had a `userid`.
   * This is a slight sanity check / debug log to help us debug this case.
   */
  if (!(await getAccessToken())) {
    console.error("No token found in storage when fetching token expiration date.");
    return null;
  }

  const axiosInstance = getClient(false);
  const apiResponse = await axiosInstance.get<IUserActiveApiResponse>(
    endpoints.userActiveTokenExpiry
  );

  const tokenExpiresAtSeconds = apiResponse?.data?.tokenExpiresAt;

  return !!tokenExpiresAtSeconds ? tokenExpiresAtSeconds : null;
}
