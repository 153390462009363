import styled, { css } from "styled-components";

export const LoadingImg = styled.img`
  position: absolute;
  filter: blur(3px);
  top: 0;
  left: 0;
`;

export const Container = styled.div(({ $fill }: { $fill: boolean }) =>
  !$fill
    ? ``
    : css`
        position: relative;
        height: 100%;
        width: 100%;
        min-height: 100%;
      `
);
