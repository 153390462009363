import type { PayloadAction } from "@reduxjs/toolkit";

import type { IFlightsSearchResultsState } from "./flights.results.types";
import { initialFlightsResultsState, resultsAdapter } from "./flights.results.constants";
import type {
  ITravelerManifest,
  IUserTravelPolicy,
} from "@hotel-engine/types/flights/flights.request.types";

export const reset = () => initialFlightsResultsState;

export const resetResults = (state: IFlightsSearchResultsState) => {
  resultsAdapter.removeAll(state);
};

export const setError = (
  state: IFlightsSearchResultsState,
  action: PayloadAction<{ status?: number; code?: string; message: string }>
) => {
  state.error = {
    code: action.payload.code,
    status: action.payload.status,
    message: action.payload.message,
  };
};

export const setSearchId = (state: IFlightsSearchResultsState, action: PayloadAction<string>) => {
  state.searchId = action.payload;
};

export const setTravelerManifeset = (
  state: IFlightsSearchResultsState,
  action: PayloadAction<ITravelerManifest>
) => {
  state.travelerManifest = action.payload;
};

export const setUserTravelPolicy = (
  state: IFlightsSearchResultsState,
  action: PayloadAction<IUserTravelPolicy>
) => {
  state.userTravelPolicy = action.payload;
};
