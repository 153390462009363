import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import type { IDateMarker } from "@hotel-engine/app/RefundPolicy/types";
import type { IReservationBase } from "@hotel-engine/types/reservation";

import moment from "moment";
import { useCompanyName } from "./useCompanyName";

/**
 * Migration of some of the logic from src/@hotel-engine/utilities/helpers/flexHelpers.ts
 * to enable the defenition of constants and functions based on the user's feature flags.
 */

export const useFlex = (formatedFlexCutOffTime?: string) => {
  const isFlexPolicy24hEnabled = useIsFeatureFlagOn("flex-policy-24h");
  const { COMPANY_NAME } = useCompanyName();
  const flexShortenCutoffCopy = `${isFlexPolicy24hEnabled ? "24" : "48"} hours`;
  const flexShortenCutoffFullText = `${
    isFlexPolicy24hEnabled
      ? "24 hours of your actual checkout date"
      : "48 hours of your original checkout date"
  }`;

  const flexShortenCutoff = (checkoutDate: string) =>
    moment(checkoutDate)
      .endOf("day")
      .add(isFlexPolicy24hEnabled ? 1 : 2, "d");

  const isBeforeFlexExpiration = (checkOut: string, flexEnabled: boolean, timezone = "UTC") => {
    const now = moment().tz(timezone).startOf("day");
    return flexEnabled && now.isSameOrBefore(flexShortenCutoff(checkOut), "day");
  };

  const buildDateMarkers = (reservation: IReservationBase): IDateMarker | null => {
    const { cancelBy, checkIn, checkOut, flexEnabled, modificationActions, createdAt } =
      reservation;

    // Upcoming refundable and flex or upcoming non-refundable and flex
    if (modificationActions?.cancellable) {
      return {
        start: moment(createdAt, "YYYY-MM-DD"),
        today: moment().startOf("day"),
        end: flexShortenCutoff(reservation.checkOut),
        policyExpiration: cancelBy ? moment(cancelBy, "YYYY-MM-DD") : null,
      };
    }
    // Flex after check-in & Before flex shorten expiration
    if (isBeforeFlexExpiration(checkOut, flexEnabled) && modificationActions?.flexShortenable) {
      return {
        start: moment(checkIn, "YYYY-MM-DD"),
        today: moment().startOf("day"),
        end: flexShortenCutoff(reservation.checkOut),
      };
    }
    // If none of the if statements match a reservation has no refund pathways
    return null;
  };

  const flexTravelCreditCopy = `Adding Flex to your booking allows you to cancel your reservation by ${formatedFlexCutOffTime} local time on check-in
    day for a refund. Depending on your hotel’s cancellation policy, you’ll either be reimbursed with the
    original form of payment or with travel credit. This benefit works for any individually booked,
    multi-night reservation and for any room, refundable or non-refundable. If you need to leave early,
    check out at the front desk so they have a record of your departure. Then shorten your trip directly
    from your ${COMPANY_NAME} itinerary page within ${flexShortenCutoffFullText} to be refunded
    with travel credit for any unused nights. Travel credit expires one year to the day of cancellation or
    shortening.`;

  const flexOriginalPaymentCopy = `Adding Flex to your booking allows you to cancel your reservation up to ${formatedFlexCutOffTime} local time on
    check-in day for a refund to your original form of payment. This benefit works for any individually
    booked, multi-night reservation and for any room, refundable or non-refundable. If you need to
    leave early, check out at the front desk so they have a record of your departure. Then shorten your
    trip directly from your ${COMPANY_NAME} itinerary page within ${flexShortenCutoffFullText} to
    be refunded for any unused nights. `;

  return {
    buildDateMarkers,
    isBeforeFlexExpiration,
    flexShortenCutoff,
    flexShortenCutoffCopy,
    flexTravelCreditCopy,
    flexOriginalPaymentCopy,
  };
};
