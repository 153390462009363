import { faArrowRight, faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { faCircleCheck, faMoon } from "@fortawesome/pro-solid-svg-icons";
import { Icon } from "@hotel-engine/common/Icon";
import { pxToRem } from "@hotel-engine/styles/helpers/mixins";
import styled from "styled-components";

export const ExpressBookSuccessStateContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ExpressBookSuccessCopyPanel = styled.div`
  padding-top: 58px;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100px 1fr;
  gap: ${pxToRem(42)};
  justify-items: center;

  text-align: center;

  h3 {
    font-size: ${pxToRem(24)};
    line-height: ${pxToRem(32)};
    letter-spacing: ${pxToRem(-0.24)};
    font-weight: 700;
    margin: 0 0 ${pxToRem(8)} 0;
  }

  p {
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(20)};
    margin: 0;
    max-width: 320px;
  }
`;

export const ExpressBookSuccessCheckIcon = styled(Icon).attrs(() => ({
  icon: faCircleCheck,
}))`
  width: 100px;
  height: 100px;

  color: #2bda94;
`;

export const ExpressBookSuccessStayInfoPanel = styled.div`
  padding: ${pxToRem(16)} ${pxToRem(24)};

  > div.inner {
    border-radius: ${({ theme }) => theme.borderRadius.lg};
    border: 1px solid ${({ theme }) => theme.colors.borderSubtle};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: ${pxToRem(16)};
  }
`;

export const ExpressBookSuccessDatesNightsBlock = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.borderSubtle};
  margin: 0 ${pxToRem(16)} ${pxToRem(8)};
  padding-top: ${pxToRem(8)};

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ExpressBookSuccessDatesCalIcon = styled(Icon).attrs(() => ({
  icon: faCalendar,
}))`
  height: ${pxToRem(16)};
  width: ${pxToRem(16)};
`;

export const ExpressBookSuccessDateSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${pxToRem(4)};
`;

export const ExpressBookSuccessNightsChip = styled.div`
  background: #ecf1f6;
  border-radius: ${({ theme }) => theme.borderRadius.pill};

  height: ${pxToRem(32)};
  padding: 0 ${pxToRem(9.5)};

  font-size: ${pxToRem(12)};

  display: flex;
  gap: ${pxToRem(4)};
  align-items: center;
  justify-items: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    background: ${({ theme }) => theme.colors.backgroundPrimary};
    padding: 0;
    width: ${pxToRem(16)};

    > span {
      display: none;
    }
  }
`;

export const ExpressBookSuccessMoonIcon = styled(Icon).attrs(() => ({
  icon: faMoon,
}))`
  height: ${pxToRem(16)};
  width: ${pxToRem(10)};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;
export const ExpressBookSuccessArrowRightIcon = styled(Icon).attrs(() => ({
  icon: faArrowRight,
}))`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: inline-block;
    height: ${pxToRem(16)};
    width: ${pxToRem(16)};
  }
`;

export const ExpressBookSuccessFooter = styled.footer`
  margin-top: ${pxToRem(48)};
  display: flex;
  gap: ${pxToRem(10)};

  padding: 0 ${pxToRem(24)} ${pxToRem(24)};

  button {
    min-height: 40px;
    width: 100%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
    gap: ${pxToRem(20)};

    padding: 0 ${pxToRem(16)} ${pxToRem(16)};

    button {
      width: 100%;
    }

    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    background: ${({ theme }) => theme.colors.backgroundPrimary};
  }
`;
