import styled from "styled-components";

import { colors, ellipsisText, sizes, visuallyHidden } from "@hotel-engine/styles";
import { backgroundImageCover, equalDimensions } from "@hotel-engine/styles/helpers/mixins";

export const PropertyInfoWrapper = styled.section`
  padding-bottom: 24px;
  border-bottom: 1px solid ${colors.grey[100]};
  > div {
    display: inline-block;
    vertical-align: middle;
  }
  width: 100%;
  .property-image {
    ${backgroundImageCover};
    ${equalDimensions(80)};
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.lg};
    overflow: hidden;
    margin: 12px;
    @media screen and (min-width: ${sizes.breakpoints.md}) {
      margin: 0 15px 0 0;
    }
    img {
      ${visuallyHidden};
    }
  }
  .property-details {
    max-width: calc(100% - 120px);
    > * {
      margin-bottom: 4px;
      ${ellipsisText};
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
`;
