import type { IPropertyQueryParams } from "@hotel-engine/types/property";
import config from "config";

import Api from "./Api";
import Resource from "./Resource";

/**
 * @deprecated - switch to the associated react-query hooks if possible
 */
export default class Property extends Resource {
  public static _basePath = `${config.apiHostV2}/properties`;

  // Duplicate of the constructor from ReadOnlyResource;
  // The context of "this" got broken in the upgrade to CRA,
  // so calling it explicitly here as a hack
  constructor(params?) {
    super(params);
    Object.assign(this, params);
  }

  /**
   * @deprecated deprecated in favor of using the react query hook {@link usePropertyQuery} instead
   */
  public static get(id: number | string, params: IPropertyQueryParams = {}) {
    return Api.get(`${this._basePath}/${id}`, params);
  }

  public static getProperties(params: IPropertyQueryParams = {}) {
    return Api.get(this._basePath, params);
  }
}
