import type { IAvailableAmenities, IResult } from "@hotel-engine/types/search";
import type { IResults } from "store/Search/SearchResults/types";

import { AMENITIES_KEYS, AVAILABLE_AMENITIES } from "./constants";

export const initialAvailableAmenities: IAvailableAmenities = {
  [AMENITIES_KEYS.wifi]: false,
  [AMENITIES_KEYS.internet]: false,
  [AMENITIES_KEYS.parking]: false,
  [AMENITIES_KEYS.breakfast]: false,
  [AMENITIES_KEYS.petFriendly]: false,
  [AMENITIES_KEYS.swimmingPool]: false,
  [AMENITIES_KEYS.fitnessCenter]: false,
  [AMENITIES_KEYS.dining]: false,
  [AMENITIES_KEYS.truckParking]: false,
  [AMENITIES_KEYS.selfServiceLaundry]: false,
  [AMENITIES_KEYS.meetingSpace]: false,
  [AMENITIES_KEYS.kitchen]: false,
  [AMENITIES_KEYS.airConditioning]: false,
  [AMENITIES_KEYS.coffeeMaker]: false,
  [AMENITIES_KEYS.refrigerator]: false,
  [AMENITIES_KEYS.dryCleaning]: false,
  [AMENITIES_KEYS.valetParking]: false,
  [AMENITIES_KEYS.shuttle]: false,
  [AMENITIES_KEYS.spa]: false,
};

/**
 * Build and return availableAmenities for result.
 * @param result {IResult} - Result used to build the availableAmenities
 * @returns Built availableAmenities
 */
export const buildResultAvailableAmenities = (result: IResult) => {
  const availableAmenities = { ...initialAvailableAmenities };
  const resultAmenities = result.amenities;

  Object.keys(AMENITIES_KEYS).forEach((amenityKey) => {
    // Use Array#some to exit early if a match isn't found
    AVAILABLE_AMENITIES[amenityKey].some((amenity) => {
      const hasMatch = resultAmenities?.some((ras) => ras.toLowerCase() === amenity.toLowerCase());

      if (hasMatch) {
        availableAmenities[amenityKey] = true;
      }

      return hasMatch;
    });
  });

  return availableAmenities;
};

/**
 * Set availableAmenities for all results in the results object. Updates the
 * results object passed in.
 * @param results {IResults} - Mutable key/value pair result object
 * @returns void
 */
export const setAvailableAmenities = (results: IResults) => {
  Object.values(results).forEach((result) => {
    result.availableAmenities = buildResultAvailableAmenities(result);
  });
};
