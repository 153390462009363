import type { IBusiness, IBusinessUpdatePayload } from "@hotel-engine/types/business";
import { fileToBase64 } from "@hotel-engine/utilities";
import config from "config";

import Api from "./Api";
import Resource from "./Resource";

/**
 * @deprecated - switch to the associated react-query hooks if possible
 */
export default class Business extends Resource {
  // Duplicate of the constructor from ReadOnlyResource;
  // The context of "this" got broken in the upgrade to CRA,
  // so calling it explicitly here as a hack
  constructor(params?) {
    super(params);
    Object.assign(this, params);
  }

  public static async getTrendsCsv(
    type: string,
    businessId: number,
    userId: number,
    startDate: string,
    endDate: string,
    bccEmails?: string,
    dateRangeFilter?: string,
    customViewFilters?: string
  ) {
    const format = "json";

    await Api.download(
      `${config.apiHost}/businesses/${businessId}/spend_by_${type}`,
      {
        bcc_emails: bccEmails,
        start_date: startDate,
        end_date: endDate,
        user_id: userId,
        format,
        date_range_filter: dateRangeFilter,
        custom_view_filters: customViewFilters,
      },
      { responseType: "json" }
    );
  }

  public static async updateLogo(params: File | Blob) {
    return Api.put(`${config.apiHostV2}/business/logo`, {
      logo: await fileToBase64(params),
    });
  }

  public static deleteLogo() {
    return Api.delete(`${config.apiHostV2}/business/logo`);
  }

  public static async updateBackgroundBanner(params: File | Blob) {
    return Api.put(`${config.apiHostV2}/businesses/background_logo`, {
      background_logo: await fileToBase64(params),
    });
  }

  public static deleteBackgroundBanner() {
    return Api.delete(`${config.apiHostV2}/businesses/background_logo`);
  }

  // overrides the inhereted update method
  public static update(payload: IBusinessUpdatePayload): Promise<IBusiness> {
    return Api.put(`${config.apiHostV2}/business`, payload);
  }
}
