import { useEffect, useState } from "react";

import { breakpoints } from "@hotel-engine/styles/variables/sizes";

/**
 * This hook allows us to use media queries in React
 * Max queries will always subtract one so there is no overlap
 *
 * @param key - Query width breakpoint. Accepts any of the defined theme breakpoints
 * @param range - Query max/min, defaulting to min
 * @returns A boolean dictating whether or not the media query matches the current screen width
 */
export function useBreakpoint(
  key: keyof typeof breakpoints,
  range: "min" | "max" = "min"
): boolean {
  const [mediaQueryMatches, setMediaQueryMatches] = useState<boolean>(false);

  useEffect(() => {
    const mediaWatcher = globalThis.matchMedia(
      range === "min"
        ? `(min-width: ${breakpoints[key]}px)`
        : `(max-width: ${breakpoints[key] - 1}px)`
    );
    setMediaQueryMatches(mediaWatcher.matches);

    const updateMediaQuery = (e) => {
      setMediaQueryMatches(e.matches);
    };

    if (mediaWatcher.addEventListener) {
      mediaWatcher.addEventListener("change", updateMediaQuery);
    } else {
      mediaWatcher.addListener(updateMediaQuery);
    }

    return () => {
      if (mediaWatcher.removeEventListener) {
        mediaWatcher.removeEventListener("change", updateMediaQuery);
      } else if (mediaWatcher.removeListener) {
        mediaWatcher.removeListener(updateMediaQuery);
      }
    };
  }, [key, range]);

  return mediaQueryMatches;
}
