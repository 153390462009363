import styled from "styled-components";

import { Icon } from "@hotel-engine/common/Icon";
import { Select } from "@hotel-engine/common/Select";
import { colors } from "@hotel-engine/styles";

export const InputIcon = styled(Icon)`
  color: ${colors.disabledBlack};
  font-size: ${({ theme }) => theme.legacy.fontSize.sm};
  &.error-icon {
    color: ${colors.khmerCurry};
  }
`;

export const CustomSelect = styled(Select)`
  width: 100%;
`;
