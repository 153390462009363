import { useFormikContext } from "formik";

import Loader from "@hotel-engine/common/Loader";
import { SelectOption } from "@hotel-engine/common/Select";
import { useDebouncedGuestQuery } from "@hotel-engine/react-query/guest/useGuestQuery";
import { useLastThreeBookedQuery } from "@hotel-engine/react-query/users/useLastThreeBookedQuery";
import { useUsersQuery } from "@hotel-engine/react-query/users/useUsersQuery";
import type { IGuest } from "@hotel-engine/types/guest";
import type { IUser } from "@hotel-engine/types/user";
import { isUser } from "@hotel-engine/utilities";
import { useLoyaltyRewards } from "pages/Checkout/LegacyLodging/hooks/useLoyaltyRewards";
import { useRoomIsLoyaltyEligible } from "pages/Checkout/LegacyLodging/hooks/useRoomIsLoyaltyEligible";
import type { ICheckoutFormData } from "pages/Checkout/validation";
import { useAppSelector } from "store/hooks";

import { buildGuestOption } from "./GuestOption";
import * as Styled from "./styles";
import { buildUserOption } from "./UserOption";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";

import { useTheme } from "styled-components";

export const ADD_GUEST_KEY = "add-guest";

export const useGuestFormOptions = (
  index: number,
  searchUser: string,
  selectedUserId: number,
  setShowAddGuestForm: (boolean) => void
) => {
  const allowBookingForUnconfirmedUsers = useIsFeatureFlagOn("book-for-unconfirmed-users");
  const guests = useDebouncedGuestQuery({
    search: searchUser,
    limit: 30,
  });
  const users = useUsersQuery({
    search: searchUser,
    limit: 30,
    includeUserLoyaltyRewards: true,
    // Nexus does not typecast the value for isConfirmed so this param needs to be
    // explicitly omitted to allow unconfirmed users to be returned
    isConfirmed: allowBookingForUnconfirmedUsers ? null : true,
  });
  const { propertyLoyaltyRewards } = useLoyaltyRewards();
  const lastThreeBooked = useLastThreeBookedQuery({});
  const user = useAppSelector((state) => state.Auth.user);
  const form = useFormikContext<ICheckoutFormData>();
  const roomIsLoyaltyEligible = useRoomIsLoyaltyEligible();

  // Temporary suggestion occupant object
  let suggestedOccupantOption: JSX.Element | undefined = undefined;
  const suggestedOccupant = form.values.guestList[index].primary.user || user;

  if (suggestedOccupant) {
    if (suggestedOccupant?.businessId && user) {
      suggestedOccupantOption = buildUserOption({
        propertyLoyaltyRewards,
        roomIsLoyaltyEligible,
        suggestedOccupant,
        user,
      });
    } else {
      suggestedOccupantOption = buildGuestOption({
        propertyLoyaltyRewards,
        roomIsLoyaltyEligible,
        suggestedOccupant,
      });
    }
  }

  // array of suggested occupants for admin/coor based on last three booked for users, will be ignored when searching to prevent duplicates
  const lastThreeBookedOptions: JSX.Element[] = [];

  // if we get data back from api
  if (lastThreeBooked.data?.users && user && !searchUser) {
    [...lastThreeBooked.data.users].forEach((occupant: IUser) => {
      // filters out any dup entries from recently selected or self
      if (occupant.id !== suggestedOccupant?.id && occupant?.id !== selectedUserId) {
        lastThreeBookedOptions.push(
          buildUserOption({
            propertyLoyaltyRewards,
            roomIsLoyaltyEligible,
            suggestedOccupant: occupant,
            user,
          })
        );
      }
    });
  }

  const occupantOptions: JSX.Element[] = [];
  const searchedOccupants = !!searchUser
    ? [...(guests.data?.results ?? []), ...(users.data?.users ?? [])]
    : [];

  // Sorts occupants with no name to the bottom of the list
  searchedOccupants.sort((a, b) => {
    if (a.firstName === null || a.lastName === null) {
      return 1;
    } else if (b.firstName === null || b.lastName === null) {
      return -1;
    } else {
      return 0;
    }
  });

  searchedOccupants.forEach((occupant: IUser | IGuest) => {
    /** If no suggested occupant exists OR if the suggested occupant is NOT the currently suggested occupant
     * AND the currently suggested occupant is not a previously selected occupant
     * */
    if (
      (!suggestedOccupant || suggestedOccupant.id !== occupant.id) &&
      occupant?.id !== selectedUserId
    ) {
      // Push either a guest or user as an option to be selected
      const option =
        isUser(occupant) && user
          ? buildUserOption({
              propertyLoyaltyRewards,
              roomIsLoyaltyEligible,
              suggestedOccupant: occupant,
              user,
            })
          : buildGuestOption({
              propertyLoyaltyRewards,
              roomIsLoyaltyEligible,
              suggestedOccupant: occupant,
            });
      occupantOptions.push(option);
    }
  });

  const results: JSX.Element[] = [];
  const { colors } = useTheme();

  // If the user is typing/searching for someone, show loading spinner
  if (users.isLoading) {
    results.push(
      <SelectOption disabled key="loader">
        <Loader tip="Searching" />
      </SelectOption>
    );
  }

  // If the user is NOT typing/searching for someone and there are last three occupants, show the  last three (lastThreeBookedOptions will be an empty array when searchUser is true)
  if (!users.isLoading && lastThreeBookedOptions.length) {
    results.push(
      <SelectOption key="recent-guest-header" disabled style={{ color: colors.foregroundPrimary }}>
        <Styled.RecentGuestHeader>Recent Guests</Styled.RecentGuestHeader>
      </SelectOption>
    );
    results.push(...lastThreeBookedOptions);
  }
  // If the user is NOT typing/searching for someone and theres a suggested occupant, show the suggested occupant
  // If has not been selected as primary already
  if (
    !users.isLoading &&
    suggestedOccupant &&
    suggestedOccupantOption &&
    suggestedOccupant.id !== selectedUserId
  ) {
    results.push(suggestedOccupantOption);
  }

  // Show the user or guests found after searching
  results.push(...occupantOptions);

  // If the user is no longer typing/searching for someone and nobody matches what they're searching for, show the "No Matches Found" message
  if (!users.isLoading && users.data?.total === 0) {
    results.push(
      <SelectOption disabled key="no-match">
        <div>No member found</div>
      </SelectOption>
    );
  }

  results.push(
    <SelectOption key={ADD_GUEST_KEY}>
      <Styled.AddAGuestButton
        onClick={() => setShowAddGuestForm(true)}
        data-testid="add-guest-option"
      >
        + Add a guest
      </Styled.AddAGuestButton>
    </SelectOption>
  );

  return results;
};
