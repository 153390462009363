import type { UseQueryOptions } from "react-query";

import { endpoints } from "@hotel-engine/react-query/constants";
import { useApi } from "@hotel-engine/react-query/useApi";
import { useExtendedQuery } from "@hotel-engine/react-query/useExtendedQuery";
import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { ILinkedAuthStrategy } from "@hotel-engine/types/authStrategy";

export const useLinkedAuthStrategiesQuery = (
  accountType: "personal" | "business" | undefined,
  options?: UseQueryOptions<ILinkedAuthStrategy[], IErrorResponse>
) => {
  const get = useApi("get");

  const oppositeAccountType = accountType === "personal" ? "business" : "personal";

  const endpoint = `${endpoints.authStrategies}/linked?account_type=${oppositeAccountType}`;

  return useExtendedQuery(endpoint, () => get<ILinkedAuthStrategy[]>(endpoint), options);
};
