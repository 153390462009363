import { useEffect } from "react";

import { useDebounce } from "@react-hook/debounce";
import deepEqual from "fast-deep-equal";
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from "react-query";

import { useExtendedQuery } from "./useExtendedQuery";

/**
 * @param queryKey {@link https://react-query.tanstack.com/guides/query-keys} - please be aware if the queryKey is too deep, then runtime will suffer.
 * @param queryFn - query function (will be debounced in this hook)
 * @param options - {@link UseQueryOptions} && wait - debounce time in ms {@default 250 }
 * @returns a custom query hook where the queryFn is throttled.
 */
function useDebouncedQuery<
  TQueryFnData,
  TError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  wait?: number
): UseQueryResult<TData, TError> {
  const [debouncedQueryKey, setDebouncedQueryKey] = useDebounce(queryKey, wait || 500);
  useEffect(() => {
    if (deepEqual(queryKey, debouncedQueryKey)) {
      return;
    }
    setDebouncedQueryKey(queryKey);
  });

  return useExtendedQuery<TQueryFnData, TError, TData, TQueryKey>(
    debouncedQueryKey,
    queryFn,
    options
  );
}

export default useDebouncedQuery;
