import { Input } from "antd";
import type { InputProps, SearchProps, TextAreaProps } from "antd/lib/input";
import styled, { css } from "styled-components";

import { colors } from "@hotel-engine/styles";

export const placeholderContrastFix = css`
  &.ant-input,
  .ant-input {
    color: ${colors.grey[800]};
    font-size: ${({ theme }) => theme.legacy.fontSize.md};

    &::placeholder {
      color: ${colors.newGrey500};
    }
  }
`;

export const AntInput = styled(Input)<InputProps>`
  ${placeholderContrastFix};
`;

export const AntInputSearch = styled(Input.Search)<SearchProps>`
  ${placeholderContrastFix};
`;

export const AntInputTextArea = styled(Input.TextArea)<TextAreaProps>`
  ${placeholderContrastFix};
`;
