import { useState } from "react";

import { createConsumer } from "@rails/actioncable";
import type { UseMutationOptions } from "react-query";
import { useMutation } from "react-query";

import { endpoints } from "@hotel-engine/react-query/constants";
import { useApi } from "@hotel-engine/react-query/useApi";
import type { AxiosErrorResponse } from "@hotel-engine/types/errors";
import type {
  ICreateReservationError,
  IReservationCreatePayload,
  IReservationResponse,
} from "@hotel-engine/types/reservation";
import { convertEmptyStrValsToNull } from "@hotel-engine/utilities";
import { getAccessToken } from "@hotel-engine/utilities/auth";
import config from "config";

export const useCreateReservation = (
  options?: UseMutationOptions<IReservationResponse, AxiosErrorResponse, IReservationCreatePayload>
) => {
  const post = useApi("post");

  return useMutation<IReservationResponse, AxiosErrorResponse, IReservationCreatePayload>(
    (params) =>
      post<IReservationResponse>(`${endpoints.reservations}`, convertEmptyStrValsToNull(params)),
    options
  );
};

const setupActionCable = async () => {
  const token = (await getAccessToken()) || "";
  const consumerUrl = new URL(config.apiHost);
  consumerUrl.pathname = "/cable";
  consumerUrl.searchParams.set("user_token", token);
  return createConsumer(String(consumerUrl));
};

/** This hook manages the create reservation action cable setup, teardown, and the consumption of the websocket's data.
 * This returns the initial mutate callback as well as the status of the reservation */
export const useCreateReservationActionCable = ({
  onSuccess,
  onError,
}: {
  /** This callback is called upon a successful status response from the reservation action cable */
  onSuccess: (
    reservationResponse: IReservationResponse,
    reservationPayload: IReservationCreatePayload
  ) => void;
  /** This callback is called upon a failed status response from the reservation action cable */
  onError: (reservationResponse: IReservationResponse | ICreateReservationError) => void;
}) => {
  // This tracks the current status of the reservation
  const [reservationResponse, setReservationResponse] = useState<IReservationResponse>();
  // This create reservation hook is needed to initialize the creation
  // of the reservation, as well as to get the id of the reservation we are creating
  const { mutate, status } = useCreateReservation({
    onError: (error) => {
      return onError({
        errors: error.response?.data.errors,
        statusCode: error.code ? parseInt(error.code) : 500,
      });
    },
    async onSuccess(response, variables) {
      // Delayed reservation detection short-circuit, https://hotelengine.atlassian.net/browse/FEN-13510
      if (response?.success === "Contract Pending") {
        // @ts-expect-error We're short circuting this for delayed reservation workaround
        onSuccess("delayed");
      }

      if (!response?.id) {
        return;
      }

      const consumer = await setupActionCable();
      consumer.subscriptions.create(
        { channel: "ContractChannel", room_id: response.id },
        {
          received(data: IReservationResponse) {
            if (!data.status || data.status === "failed") {
              consumer.disconnect();
              const errorResponse = {
                ...data,
                errors: data?.status_message ? [data.status_message] : [],
                status: "failed",
              };
              setReservationResponse(errorResponse);
              onError(errorResponse);
            }

            const bookingFinalStatuses = ["booked", "cancelled", "visiting"];

            if (bookingFinalStatuses.includes(data.status)) {
              consumer.disconnect();
              onSuccess(data, variables);
            }
            // update the reservation progress
            setReservationResponse(data);
          },
        }
      );
    },
  });

  let response: IReservationResponse | undefined = reservationResponse;
  // If we have a status from the initial reservation creation and none from the action cable, return that status
  if (!response && status === "loading") {
    response = { status, id: "0" };
  }

  return { response, mutate };
};
