import type { UseQueryOptions } from "react-query";

import type { IErrorResponse } from "@hotel-engine/types/errors";

import { useApi } from "../useApi";
import { useExtendedQuery } from "../useExtendedQuery";
import { endpoints } from "../constants";
import type { ISupportCase } from "../../types/supportCase";

const useSingleSupportCaseQuery = (
  caseId: number,
  options?: UseQueryOptions<ISupportCase, IErrorResponse, ISupportCase>
) => {
  const get = useApi("get");

  const endpoint = `${endpoints.supportCenter}/${caseId}`;

  const queryKey = [caseId];

  const queryFn = () => get<ISupportCase>(endpoint);

  return useExtendedQuery<ISupportCase, IErrorResponse>(queryKey, queryFn, {
    ...options,
    cacheTime: 0,
    staleTime: 0,
  });
};

export { useSingleSupportCaseQuery };
