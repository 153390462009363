import moment from "moment";
import { useExtendedQuery } from "@hotel-engine/react-query/useExtendedQuery";
import { captureMessage } from "@hotel-engine/utilities/logger";
import { isMobileOrTablet } from "@hotel-engine/utilities/helpers/phoneHelpers";

const ignoreCache = isMobileOrTablet();
const defaultReactQueryCacheTime = 5 * 60 * 1000;
const path = "/version.txt";
const errorMessage = "[APP VERSION.TXT FETCH ERROR]: ";

async function getVersion() {
  // we append a timestamp rounded to the next hour to our query
  // this enables us to read from browser cache on each page transition, and every hour when that string changes
  // we bust the cache and read from s3 directly
  // this strategy is a more conservative approach than every user hitting s3 on every pathname update all the time
  const cacheString = moment().add(1, "h").startOf("h").toString();
  const res = await fetch(`${path}?${cacheString}`, {
    headers: {
      "Cache-Control": ignoreCache ? "no-cache" : "max-age=31536000",
    },
  });
  const version = await res.text();
  return version.replace("\n", "");
}

export const useGetAppVersion = () => {
  const {
    data: fetchedValue,
    refetch,
    isFetched,
  } = useExtendedQuery([path], getVersion, {
    cacheTime: ignoreCache ? 0 : defaultReactQueryCacheTime,
    onError: (error) => {
      console.error(errorMessage, error);
      captureMessage(errorMessage, { error });
    },
  });

  return { fetchedValue, refetch, isFetched };
};
