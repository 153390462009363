/* eslint-disable
 @typescript-eslint/no-namespace,
 @typescript-eslint/naming-convention,
 @typescript-eslint/no-empty-interface,
 @typescript-eslint/no-explicit-any,
 @typescript-eslint/no-shadow,
 */
export { Get }

import type { Any } from "@hotel-engine/data";
import { List, Fn, Option as O, Struct } from "@hotel-engine/data";
import { Config } from "../shared/config/exports"
import * as Core from "../shared/core";
import {
  type Entries,
  type EntriesDict,
  type Entry,
  type Path,
  isEntries,
  isEntriesDict,
  isEntry,
  isPath,
} from "../shared/util"

/** @external */
type Get<
  P extends Path,
  T extends Core.TreeFromPath<P, V>,
  V = unknown
> = Go<P, T>;

/** @internal */
type Get$<P extends Path, T, _ = unknown> = Go<P, T>

/** @internal */
type Go<
  P extends Path,
  Acc
>
  = P extends readonly [] ? Acc
  : P extends readonly [Any.KeyOf<Acc, infer H>, ...Any.ListOf<Any.Key, infer T>]
  ? Go<T, Acc[H]>
  : never
  ;

/** @internal */
namespace Merge {
  /** @internal */
  type Next<PD extends PathDescriptor> = { guard: PD["guard"], path: List.Tail<PD["path"]> }
  /** @internal */
  type Done<PD extends PathDescriptor> = Fn.Guarded<PD["guard"]>
  /** @internal */
  type Go<PD extends PathDescriptor>
    = PD["path"] extends readonly []
    ? Done<PD>
    : { [D in PD extends PD ? PD : never as D["path"][0]]
      : Go<D extends D ? Next<D> : never> }
    ;
  export type NamedEntries<PS>
    = Go<PS extends PathDescriptor ? PS : never>
    ;
  export type Paths<PS>
    = Go<PS[Extract<keyof PS, `${number}`>] extends
      | infer D
      ? D extends PathDescriptor
      ? D
      : never
      : never
    >
}

namespace Args {
  /** @external */
  export type Tag = typeof Tag[keyof typeof Tag]
  /** @external */
  export const Tag = {
    JustPath: "JustPath",
    PathWithGuard: "PathWithGuard",
    JustEntries: "JustEntries",
    JustEntriesDict: "JustEntriesDict",
    PathWithOpt: "PathWithOpt",
    PathWithGuardAndOpt: "PathWithGuardAndOpt",
    EntriesWithOpt: "EntriesWithOpt",
    EntriesDictWithOpt: "EntriesDictWithOpt",
  } as const

  /** @external */
  export type JustPath<P extends Path = Path> = readonly [path: P]
  /** @external */
  export type PathWithGuard<P extends Path = Path, G extends Fn.Guard<any> = Fn.Guard<any>> = readonly [path: P, guard: G]
  /** @external */
  export type JustEntries<PS extends Entries = Entries> = readonly [...PS]
  /** @external */
  export type JustEntriesDict<S extends EntriesDict = EntriesDict> = never | readonly [dict: S]
  /** @external */
  export type PathWithOpt<P extends Path = Path, Opt extends Options = Options> = readonly [path: P, options: Opt]
  /** @external */
  export type PathWithGuardAndOpt<P extends Path = Path, G extends Fn.Guard<any> = Fn.Guard<any>, Opt extends Options = Options> = readonly [path: P, guard: G, options: Opt]
  /** @external */
  export type EntriesWithOpt<ES extends Entries = Entries, Opt extends Options = Options> = readonly [...ES, Opt]
  /** @external */
  export type EntriesDictWithOpt<ES extends EntriesDict = EntriesDict, Opt extends Options = Options> = readonly [ES, Opt]


  /** @external */
  export type FullArgs = FullArgsSet[number]
  /** @external */
  export type FullArgsSet = readonly [
    JustPath,
    PathWithGuard,
    JustEntries,
    JustEntriesDict,
    PathWithOpt,
    PathWithGuardAndOpt,
    EntriesWithOpt,
    EntriesDictWithOpt,
  ]

  /** @external */
  export type TaggedArgs = TaggedArgsSet[number]
  /** @external */
  export type TaggedArgsSet = readonly [
    { type: typeof Tag.JustPath, args: Args.JustPath },
    { type: typeof Tag.PathWithGuard, args: Args.PathWithGuard },
    { type: typeof Tag.JustEntries, args: Args.JustEntries },
    { type: typeof Tag.JustEntriesDict, args: Args.JustEntriesDict },
    { type: typeof Tag.PathWithOpt, args: Args.PathWithOpt },
    { type: typeof Tag.PathWithGuardAndOpt, args: Args.PathWithGuardAndOpt },
    { type: typeof Tag.EntriesWithOpt, args: Args.EntriesWithOpt },
    { type: typeof Tag.EntriesDictWithOpt, args: Args.EntriesDictWithOpt },
  ]

  /** @internal */
  const isJustPath
    = (args: Args.FullArgs): args is Args.JustPath =>
      args.length === 1
      && isPath(args[0])
  /** @internal */
  const isPathWithGuard
    = (args: Args.FullArgs): args is Args.PathWithGuard =>
      args.length === 2
      && isPath(args[0])
      && Core.is.function(args[1])
  /** @internal */
  const isJustEntries
    : (args: Args.FullArgs) => args is Args.JustEntries
    = isEntries
  /** @internal */
  const isJustEntriesDict
    = (args: Args.FullArgs): args is Args.JustEntriesDict =>
      args.length === 1
      && isEntriesDict(args[0])
  /** @internal */
  const isPathWithOpt
    = (args: Args.FullArgs): args is Args.PathWithOpt =>
      args.length === 2
      && isPath(args[0])
      && Core.is.struct(args[1])
  /** @internal */
  const isPathWithGuardAndOpt
    = (args: Args.FullArgs): args is Args.PathWithGuardAndOpt =>
      args.length === 3
      && isPath(args[0])
      && Core.is.function(args[1])
      && Core.is.struct(args[2])
  /** @internal */
  const isEntriesWithOpt
    = (args: Args.FullArgs): args is Args.EntriesWithOpt =>
      isEntries(List.init(args))
      && Core.is.struct(List.last(args))
  /** @internal */
  const isEntriesDictWithOpt
    = (args: Args.FullArgs): args is Args.EntriesDictWithOpt =>
      args.length === 2
      && isEntriesDict(args[0])
      && Core.is.struct(args[1])
    ;

  /** @external */
  export const tagArgs
    : (args: Args.FullArgs) => Args.TaggedArgs
    = (args) => {
      if (isJustPath(args)) return { type: Tag.JustPath, args }
      else if (isPathWithGuard(args)) return { type: Tag.PathWithGuard, args }
      else if (isJustEntries(args)) return { type: Tag.JustEntries, args }
      else if (isJustEntriesDict(args)) return { type: Tag.JustEntriesDict, args }
      else if (isPathWithOpt(args)) return { type: Tag.PathWithOpt, args }
      else if (isPathWithGuardAndOpt(args)) return { type: Tag.PathWithGuardAndOpt, args }
      else if (isEntriesWithOpt(args)) return { type: Tag.EntriesWithOpt, args }
      else if (isEntriesDictWithOpt(args)) return { type: Tag.EntriesDictWithOpt, args }
      else return Fn.exhaustive(`get`, args)
    }
}

/** @internal - could be moved for reuse maybe */
type Values<S>
  = [S] extends [Any.Array] ? S[number]
  : [S] extends [Any.Object] ? S[keyof S]
  : S
  ;

/** @internal */
interface PathDescriptor {
  guard: Fn.Guard<any>
  path: Path
}

/** @internal */
interface EntryDescriptor extends PathDescriptor {
  key: string
}

/** @internal */
type EntryToPathDescriptor<T>
  = T extends Entry ? { path: Extract<List.Init<T>, Path>, guard: List.Last<T> }
  : T extends Path ? { path: T, guard: Fn.Guard<Any.NonNullable> }
  : never
  ;

/** @internal */
type Options = { strategy: Exclude<Config.Strategy, typeof Config.Strategy.Guard> }
/** @internal */
type DefaultOptions = typeof DefaultOptions
/** @internal */
const DefaultOptions = Struct.make({ strategy: "Assert" })

/** @internal */
type FromEntries<PS> = never
  | { [Ix in keyof PS]: EntryToPathDescriptor<PS[Ix]> }
  ;
/** @internal */
type FromNamedEntries<S> = never
  | EntryToPathDescriptor<
    Values<S> extends
    | infer V
    ? (never | { [Ix in keyof V]: V[Ix] })
    : never
  >

/** @internal */
type DescribeEntries<PS extends Entries>
  = { [ix in keyof PS]
    : PS[ix] extends Path ? ReturnType<typeof pathDescriptorFromPath<PS[ix]>>
    : PS[ix] extends Entry ? ReturnType<typeof pathDescriptorFromEntry<PS[ix]>>
    : never }
  ;

/** @internal */
type GetPathReturnType<
  P extends Path,
  V = unknown,
  Opt extends Options = DefaultOptions
> = (
  <const T extends Core.TreeFromPath<P, V>>(tree: T)
    => Config.Interpret<Config.GetStrategyMap[Opt["strategy"]], Get$<P, T>>) | never
  ;

/** @internal */
type GetEntriesReturnType<
  PS extends Entries,
  Opt extends Options
> = (
  <T extends Merge.Paths<FromEntries<PS>>>(tree: T) =>
    Config.Interpret<
      Config.GetStrategyMap[Opt["strategy"]],
      DescribeEntries<PS>[number] extends
      | infer PD
      ? [PD] extends [{ path: Path, key: string }]
      ? { [P in PD as P["key"]]: Get$<P["path"], T> }
      : never
      : never
    >
) | never
  ;

/** @internal */
type GetNamedPathsReturnType<
  PS extends EntriesDict,
  Opt extends Options
> = (
  <const T extends Merge.NamedEntries<FromNamedEntries<PS>>>(tree: T) =>
    Config.Interpret<
      Config.GetStrategyMap[Opt["strategy"]],
      { [K in keyof PS]
        : PS[K] extends Entry<infer P, infer G>
        ? Get$<P, T, Fn.Guarded<G>>
        : Get$<Extract<PS[K], Path>, T> }
    >
) | never
  ;

/** @internal */
const joinPath = List.join(".")

/** @internal */
const pathDescriptorFromEntry
  : <P extends Entry>(entry: readonly [...P]) => { path: List.Init<P>, guard: List.Last<P>, key: List.Join<List.Init<P>, "."> }
  = (entry) => ({
    path: Fn.absorb(List.init(entry)),
    guard: List.last(entry),
    key: joinPath(Fn.absorb(List.init(entry))),
  })

/** @internal */
const pathDescriptorFromPath
  : <const P extends Path>(path: P) => { path: P, guard: <U>(u: U) => u is U, key: List.Join<P, "."> }
  = (path) => ({
    path,
    guard: Core.succeed,
    key: joinPath(path),
  })

/** @internal */
function describeEntries(entries: Entries): readonly EntryDescriptor[] {
  return entries.map(
    entry => isEntry(entry)
      ? pathDescriptorFromEntry(entry)
      : isPath(entry)
        ? pathDescriptorFromPath(entry)
        : Fn.exhaustive(`describeEntries`, entry)
  )
}

/**
 * @internal
 *
 * TODO: Write a better error message
 */
const makeParseErrorMsg
  = () => `\`Get\` received a value that did not have the expected path`

/** @internal */
type MatchOptions<Opt extends Options, T> = {
  [Config.Strategy.Assert]: (input: O.Option<T>) => T
  [Config.Strategy.Parse]: (input: O.Option<T>) => O.Option<T>
}[Opt["strategy"]]

/** @internal */
function matchOptions<Opt extends Options>(opt: Opt): <T>(input: O.Option<T>) => MatchOptions<Opt, T>
function matchOptions(opt: Options): <T>(input: O.Option<T>) => T | O.Option<T> {
  return (input) => {
    switch (opt.strategy) {
      case Config.Strategy.Parse: return input
      case Config.Strategy.Assert:
        return Fn.pipe(
          input,
          O.match(
            Fn.identity,
            () => Fn.die(makeParseErrorMsg()),
          )
        )
      default:
        return Fn.exhaustive(`matchOptions`, opt.strategy)
    }
  }
}

/** @internal */
type StructFromDescriptors<DS extends Any.Array<EntryDescriptor>> = never |
  { [ix in Extract<keyof DS, `${number}`> as DS[ix]["key"]]
    : Get$<DS[ix]["path"], Core.TreeFromPath<DS[ix]["path"], Fn.Guarded<DS[ix]["guard"]>>> }
  ;

/** @internal */
const structFromDescriptors
  : <DS extends Any.Array<EntryDescriptor>>(descriptors: DS) => <T extends Any.Struct>(tree: T) => StructFromDescriptors<DS>
  = (descriptors) =>
    (tree) =>
      descriptors.reduce(
        (acc, { key, path, guard }) => ({ ...acc, [key]: get(path, guard)(tree) }),
        Struct.emptyOf<StructFromDescriptors<typeof descriptors>>()
      )
  ;

/** @internal */
type StructFromEntriesDict<S extends EntriesDict, T> = never |
  { [K in keyof S]
    : (
      S[K] extends Entry ? ReturnType<typeof pathDescriptorFromEntry<S[K]>>
      : S[K] extends Path ? ReturnType<typeof pathDescriptorFromPath<S[K]>>
      : never
    ) extends infer Descriptor
    ? Descriptor extends PathDescriptor
    ? O.Option<Get$<Descriptor["path"], T>>
    : never
    : never
  }

/** @internal */
const fromEntries = Fn.flow(
  describeEntries,
  structFromDescriptors,
)

/** @internal */
const fromEntriesDict
  : <S extends EntriesDict>(dict: S) => <T extends Any.Struct>(tree: T) => StructFromEntriesDict<S, T>
  = (dict) => (tree) => Fn.pipe(
    dict,
    Struct.map(
      Fn.flow(
        e => isEntry(e) ? pathDescriptorFromEntry(e) : pathDescriptorFromPath(e),
        ({ path, guard }) => get(path, guard)(tree),
      )
    ),
    Fn.absorb,
  )

/** @internal */
const get
  : (path: Core.Path, guard?: Fn.Guard<any>) => (tree: Any.Struct) => O.Option<unknown>
  = (path, guard = Core.succeed) =>
    (tree) =>
      Fn.pipe(
        path.reduce((acc, curr) => acc[curr], tree),
        O.fromPredicate(guard),
      )
  ;

/** @external */
function Get<const P extends Path>(path: P): GetPathReturnType<P>
function Get<const P extends Path, V>(path: P, guard: Fn.Guard<V>): GetPathReturnType<P, V, DefaultOptions>
function Get<const PS extends Entries>(...args: PS): GetEntriesReturnType<PS, DefaultOptions>;
function Get<const S extends EntriesDict>(named: S): GetNamedPathsReturnType<S, DefaultOptions>;
function Get<const P extends Path, Opt extends Options>(path: P, options: Opt): GetPathReturnType<P, Any.NonNullable, Opt>;
function Get<const P extends Path, V, Opt extends Options>(path: P, guard: Fn.Guard<V>, options: Opt): GetPathReturnType<P, V, Opt>;
function Get<const PS extends Entries, Opt extends Options>(...args: PS): GetEntriesReturnType<PS, Opt>;
function Get<const ES extends Entries>(...args: ES): GetEntriesReturnType<ES, DefaultOptions>;
function Get<const ES extends Entries, Opt extends Options>(...args: Args.EntriesWithOpt<ES, Opt>): GetEntriesReturnType<ES, Opt>;
function Get<const S extends EntriesDict, Opt extends Options>(...args: Args.EntriesDictWithOpt<S, Opt>): GetNamedPathsReturnType<S, Opt>;
function Get(
  ...fullArgs: Args.FullArgs
): unknown {
  const { type, args } = Args.tagArgs(fullArgs)
  switch (type) {
    // Overload #1
    case Args.Tag.JustPath:
      return Fn.flow(
        get(...args),
        matchOptions(DefaultOptions),
      )
    // Overload #2
    case Args.Tag.PathWithGuard:
      return Fn.flow(
        get(...args),
        matchOptions(DefaultOptions),
      )
    // Overload #3
    case Args.Tag.JustEntries:
      return Fn.flow(
        fromEntries(args),
        O.struct,
        matchOptions(DefaultOptions),
      )
    // Overload #4
    case Args.Tag.JustEntriesDict:
      return Fn.flow(
        fromEntriesDict(...args),
        O.struct,
        matchOptions(DefaultOptions),
      )
    // Overload #5
    case Args.Tag.PathWithOpt:
      return Fn.flow(
        get(...List.init(args)),
        matchOptions(List.last(args))
      )
    // Overload #6
    case Args.Tag.PathWithGuardAndOpt:
      return Fn.flow(
        get(...List.init(args)),
        matchOptions(List.last(args))
      )
    // Overload #7
    case Args.Tag.EntriesWithOpt:
      return Fn.flow(
        fromEntries(List.init(args)),
        O.struct,
        matchOptions(List.last(args)),
      )
    // Overload #8
    case Args.Tag.EntriesDictWithOpt:
      return Fn.flow(
        fromEntriesDict(...List.init(args)),
        O.struct,
        matchOptions(List.last(args)),
      )
    // Default: throw if none of the above matched
    default:
      return Fn.exhaustive(`get`, args)
  }
}
