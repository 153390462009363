/** For accessing nested object properties with path string (required for Formik validation) */
export const getNestedProperty = (object, path: string, separator: string) => {
  try {
    separator = separator || ".";

    return path
      .replace("[", separator)
      .replace("]", "")
      .split(separator)
      .reduce((obj, property) => obj[property], object);
  } catch (err) {
    return undefined;
  }
};
