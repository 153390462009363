import { TripType } from "@hotelengine/core-booking-web";
import {
  FareListAlertType,
  type IFareListAlert,
} from "pages/Flights/types/flights.components.types";

export const flightsBreakpoints = {
  desktop: 1042,
  largeDesktop: 1280,
};

export const fareListAlertMap: Record<FareListAlertType, IFareListAlert> = {
  [FareListAlertType.limited]: {
    title: "Fare choices limited",
    text: "This is the only available fare based on your outbound fare selection",
  },
  [FareListAlertType.sameFare]: {
    title: "Same fare class unavailable",
    text: "The fare class you selected for your outbound flight is not available for this flight",
  },
};

export const shelfDescriptions = {
  basic: {
    title: "Basic fares",
    description: "Generally the cheapest options with restrictive policies and fewer amenities.",
  },
  standard: {
    title: "Standard fares",
    description:
      "Commonly main cabin and economy fares with amenities like carry-on bags and seat selection.",
  },
  enhanced: {
    title: "Enhanced fares",
    description:
      "Generally includes extra legroom, priority boarding, complimentary snacks and drinks.",
  },
  premium: {
    title: "Premium fares",
    description:
      "Generally includes cradle or reclined seats, priority boarding, premium snacks and beverages.",
  },
  luxury: {
    title: "Luxury fares",
    description:
      "Generally includes luxury reclined seats, personalized services, and premium meals, along with priority boarding.",
  },
};

export const tripTypeOptions: Record<TripType, string> = {
  [TripType.oneWay]: "One-way",
  [TripType.roundTrip]: "Round-trip",
  [TripType.multiCity]: "Multi-city",
};

export const MULTI_CITY_FLIGHT_LIMIT = 5;
