import styled, { createGlobalStyle, css, keyframes } from "styled-components";

import { Button, IconButton } from "@hotelengine/atlas-web";

export const DisableScrollOnBody = createGlobalStyle<{
  disableBodyScroll?: boolean;
}>`
    ${({ disableBodyScroll }) =>
      disableBodyScroll &&
      css`
        body,
        html {
          overflow: hidden;
        }
      `}
`;

const growAndFadeInAnimation = keyframes`
    from {
        transform: scale(0);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
`;

export const modalSizes = {
  buttonBottom: 16,
  buttonHeight: 40,
  buttonMargin: 10,
  titleHeight: 56,
} as const;

export const FullscreenModal = styled.div<{
  $isVisible: boolean;
  $modifiedZIndex: number;
  $animateFromBottom?: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100vw;
  z-index: ${({ $modifiedZIndex }) => $modifiedZIndex};
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  overflow: hidden;
  visibility: ${({ $isVisible }) => ($isVisible ? "visible" : "hidden")};
  ${({ $isVisible, $animateFromBottom }) =>
    (!$animateFromBottom &&
      $isVisible &&
      css`
      animation: ${growAndFadeInAnimation} 0.2s;
    `) ||
    css`
    transition: 0.5s;
    transform: translateY(100%);
    ${($isVisible && "transform: translateY(0);") || ""}
    `}
`;

export const TitleSection = styled.div<{ $headerReverse: boolean }>`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderMinimal};
  position: relative;
  height: ${modalSizes.titleHeight}px;
  ${({ $headerReverse }) =>
    $headerReverse &&
    css`
      justify-content: space-between;
      align-items: center;
      padding-left: 16px;
      padding-right: 4px;
    `}
`;

export const CloseAtRight = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.backgroundTransparent};
`;

export const Done = styled(Button)`
  position: absolute;
  width: 90%;
  height: ${modalSizes.buttonHeight}px;
  bottom: ${modalSizes.buttonBottom}px;
  left: 5%;
`;

export const Content = styled.div<{
  padding?: string;
  hasDoneButton?: boolean;
}>`
  ${({ padding }) => padding && `padding: ${padding}`};
  height: calc(100% - 50px);
  ${({ hasDoneButton }) =>
    hasDoneButton &&
    `padding-bottom: ${
      modalSizes.buttonBottom + modalSizes.buttonHeight + modalSizes.buttonMargin
    }px`}
`;
