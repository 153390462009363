import styled from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";

export const EmptyInbox = styled.section`
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background: ${({ theme }) => theme.colors.backgroundSecondary};
  flex: 6;
  margin-top: ${({ theme }) => theme.spacing[16]};
  padding: ${({ theme }) => theme.spacing[24]};
  text-align: center;
  ${flexbox({
    alignItems: "center",
    direction: "column",
    justifyContent: "center",
  })};
`;
