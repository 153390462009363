import { useExpressBook } from "pages/Checkout/LegacyLodging/ExpressBookModal/hooks/useExpressBook";
import { ExpressBookModalStep } from "@hotel-engine/types/expressBook";
import { ManWalkingAnimation } from "pages/SearchResults/components/common/ManWalkingAnimation";

import * as Styled from "./styles";
import { noop } from "lodash";

export type TExpressBookFormErrorTypes = "bad_rate" | "generic_api" | "fetch_error";

interface IExpressBookCheckoutFormErrorProps {
  errorType: TExpressBookFormErrorTypes;
  refreshContract: (
    dates?: { checkIn: string; checkOut: string },
    onSuccessCallback?: () => void,
    isDateChange?: boolean
  ) => void;
  errorMsg?: string | null;
}

const formErrorRenderer = (
  errorType: TExpressBookFormErrorTypes,
  refreshEBContract: () => void
): JSX.Element | null => {
  switch (errorType) {
    case "fetch_error": {
      return (
        <>
          <ManWalkingAnimation />
          <Styled.ExpressBookCheckoutFormErrorCopy>
            Sorry!
            <br />
            We had trouble finding a room!
          </Styled.ExpressBookCheckoutFormErrorCopy>
          <Styled.ExpressBookCheckoutFormErrorButtonContainer>
            <Styled.ExpressBookCheckoutFormErrorButton type="primary" onClick={refreshEBContract}>
              Retry
            </Styled.ExpressBookCheckoutFormErrorButton>
          </Styled.ExpressBookCheckoutFormErrorButtonContainer>
        </>
      );
    }
    case "bad_rate": {
      return (
        <>
          <ManWalkingAnimation />
          <Styled.ExpressBookCheckoutFormErrorCopy>
            Sorry!
            <br />
            Your Express Book Rate is no longer valid!
          </Styled.ExpressBookCheckoutFormErrorCopy>
          <Styled.ExpressBookCheckoutFormErrorButtonContainer>
            <Styled.ExpressBookCheckoutFormErrorButton type="primary" onClick={refreshEBContract}>
              Refresh rate
            </Styled.ExpressBookCheckoutFormErrorButton>
          </Styled.ExpressBookCheckoutFormErrorButtonContainer>
        </>
      );
    }
    case "generic_api": {
      return (
        <>
          <ManWalkingAnimation />
          <Styled.ExpressBookCheckoutFormErrorCopy>
            Sorry!
            <br />
            There was an issue when trying to book your room.
          </Styled.ExpressBookCheckoutFormErrorCopy>
          <Styled.ExpressBookCheckoutFormErrorButtonContainer>
            <Styled.ExpressBookCheckoutFormErrorButton type="primary" onClick={refreshEBContract}>
              Refresh rate
            </Styled.ExpressBookCheckoutFormErrorButton>
          </Styled.ExpressBookCheckoutFormErrorButtonContainer>
        </>
      );
    }
  }
};

export const ExpressBookCheckoutFormError = ({
  errorType,
  refreshContract,
}: IExpressBookCheckoutFormErrorProps) => {
  const {
    setModalStep,
    expressCheckoutProperty,
    setHideExpressBookForStackingModals,
    expressBookCheckIn,
    expressBookCheckOut,
  } = useExpressBook();

  const refreshHandler = () => {
    if (expressCheckoutProperty) {
      setHideExpressBookForStackingModals(false);
      refreshContract(
        {
          checkIn: expressBookCheckIn ?? "",
          checkOut: expressBookCheckOut ?? "",
        },
        noop,
        true
      );
      setModalStep(ExpressBookModalStep.BASE_STATE);
    }
  };

  return (
    <Styled.ExpressBookCheckoutFormErrorContainer data-testid="express-book-form-error">
      {formErrorRenderer(errorType, refreshHandler)}
    </Styled.ExpressBookCheckoutFormErrorContainer>
  );
};
