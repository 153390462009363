import React, { useEffect, useState } from "react";

import { useLocation, Navigate } from "@hotel-engine/lib/react-router-dom";

import { routes } from "@hotel-engine/constants";
import { getRedirectLocation } from "@hotel-engine/utilities";
import type { IRole } from "@hotel-engine/types/role";

import type { AuthValidatorFn } from "../helpers";
import { validateCustomAuth, validatePersonalAccount, validateRole } from "../helpers";
import { useHydrateUser } from "../helpers/useHydrateUser";

interface IAuthRouteProps {
  /** Minimum role for logged in user to access route */
  minRole: IRole;
  /** Custom validator function to determine whether the route can be accessed */
  customAuth?: AuthValidatorFn[];
  children: React.ReactNode;
}

/**
 * When setting the `<Route element={} />` element property, when we want a route
 * to be accessible only to users who are signed in with a minimum role and/or custom auth,
 * wrap your component in this "Route Wrapper" component.
 *
 * Example:
 *
 * ```
 * <Route path="adminsonly" element={
 *  <RouteAuthWrapper minRole="admin">
 *    <div>Admins Only!</div>
 *  </RouteAuthWrapper>
 * } />
 * ```
 */
export const RouteAuthWrapper = ({ children, customAuth, minRole = "user" }: IAuthRouteProps) => {
  const { user, loading } = useHydrateUser();
  const isViewOnlyTraveler = user?.role === "view_only_traveler";
  const [isVerified, setIsVerified] = useState(user ? validatePersonalAccount(user) : false);

  const rrLocation = useLocation();

  useEffect(() => {
    setIsVerified(user ? validatePersonalAccount(user) : false);
  }, [user]);

  if (loading) {
    return null;
  }

  if (!user) {
    const redirectPath = getRedirectLocation();
    return redirectPath ? <Navigate to={redirectPath} replace /> : null;
  }

  // Is account setup complete and does the user have permissions to view this route
  if (validateRole(user, minRole) && validateCustomAuth(customAuth, user) && isVerified) {
    return <>{children}</>;
  }

  // If their account is set up correctly, but the user doesn't have the
  // correct role to access this page, they need to be redirected.
  if (isVerified) {
    return <Navigate to={isViewOnlyTraveler ? routes.trips.upcoming : "/"} replace />;
  }

  // Account setup incomplete - Needs to confirm
  const needsToConfirmPersonalTravel =
    !user.confirmedAt && rrLocation.pathname !== routes.personalTravelUnconfirmed;
  if (needsToConfirmPersonalTravel) {
    return <Navigate to={routes.personalTravelUnconfirmed} replace />;
  }

  // Account setup incomplete - Needs to verify phone
  const needsToVerifyPhone =
    !!user.confirmedAt && !user.phoneVerified && rrLocation.pathname !== routes.personalTravel2fv;
  if (needsToVerifyPhone) {
    return <Navigate to={routes.personalTravel2fv} replace />;
  }

  return <>{children}</>;
};
