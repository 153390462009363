/* eslint-disable @typescript-eslint/no-restricted-imports */
import * as RR from "react-router-dom";
import type { Location } from "react-router-dom";

/**
 * In react router version 5, you could specify a generic type
 * for useLocation, and it would be used literally. Meaning,
 * it hid the fact that state could be null, or it's properties
 * could be undefined.
 *
 * Version 5 Example:
 * ```
 * interface IMyState {
 *   name: string;
 * }
 *
 * const { state } = useLocation<IMyState>();
 * ```
 *
 * But in RR version 6, we can no longer specify the type.
 *
 * This hook overrides the type for useLocation to allow us to specify
 * a type for the state object. In addition, it adds `| undefined` to
 * each property on state, forcing the user to handle that possibility.
 *
 * Example:
 * ```
 * // state is now { name: string | undefined } | null
 * // state might be null ✅
 * // state?.name might be undefined ✅
 * const { state } = useLocation<IMyState>();
 * ```
 */
export function useLocation<
  StateMap extends object | Record<string, ValidStateTypes> = never,
>(): Location<
  { [key in keyof StateMap]?: StateMap[key] } | (StateMap extends never ? never : null)
> {
  return RR.useLocation();
}

/**
 * State values should be serializable
 */
type ValidStateTypes =
  | string
  | number
  | boolean
  | null
  | readonly ValidStateTypes[]
  | { [key: string]: ValidStateTypes };
