import type { PropsWithChildren } from "react";
import { createContext, useEffect } from "react";

import { useExperimentSdk, useInitAmplitude } from "@hotel-engine/contexts/Amplitude/utils";
import type { IAmplitudeContext } from "@hotel-engine/types/amplitude";
import { ampli } from "ampli";
import { detect } from "detect-browser";
import { useAllExperimentsQuery } from "@hotel-engine/react-query/amplitude/useExperimentQuery";
import { getWidthXHeightFormattedString } from "@hotel-engine/utilities/helpers/getWidthXHeightFormattedString";
import { ANONYMOUS_AMPLITUDE_USER_ID } from "@hotel-engine/constants";

/** The context for interfacing with Amplitude. */
export const AmplitudeContext = createContext({} as IAmplitudeContext);

/**
 * `AmplitudeContext` provider component. Not much should happen here except
 * basic initialization and establishing the necessary context for downstream
 * components and hooks to function with Amplitude services.
 *
 * See `FeatureFlag.tsx` for an example of a component/hook which interface
 * with the Amplitude react context definied/provided here.
 */
export const AmplitudeContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  /** Set the Amplitude browser sdk. */
  const amplitudeSdk = useInitAmplitude();

  /** Set the Amplitude experiment sdk. */
  const experimentSdk = useExperimentSdk();
  const { init: initExperiment, profile, client, userId } = experimentSdk;

  /**
   * After amplitude analytics has initialized, then we can
   * initialize amplitude experiment and set the initial
   * user within the experiment sdk
   */
  useEffect(() => {
    if (amplitudeSdk.initialized) {
      ampli.identify(String(userId), {
        viewportSize: getWidthXHeightFormattedString(),
        browser_name: String(detect()?.name ?? "Unable to detect browser"),
      });

      if (!client && initExperiment && userId) {
        // use actual unique user id from redux store rather than common id shared between business/personal accounts to ensure appropriate keys are retrieved
        initExperiment()?.setUser({
          ...profile,
          // Need to translate the anonymous amplitude user id to the empty string.
          user_id: userId === ANONYMOUS_AMPLITUDE_USER_ID ? "" : String(userId),
        });
      }
    }
  }, [amplitudeSdk.initialized, userId, client, initExperiment, profile]);

  useAllExperimentsQuery(experimentSdk);

  return (
    <AmplitudeContext.Provider
      value={{
        amplitudeSdk,
        experimentSdk,
      }}
    >
      {children}
    </AmplitudeContext.Provider>
  );
};
