import type { IPaymentProfile } from "@hotel-engine/types/paymentProfile";

import { SpreedlyCardInput } from "./SpreedlyCardInput";
import * as Styled from "./styles";

export interface ICardUpdateValues {
  /** boolean if expiration cvv is valid or not */
  cvvInvalid?: boolean;
  /** card expiration month, format: MM */
  expirationMonth?: string;
  /** card expiration year, format: YYYY */
  expirationYear?: string;
}

interface ICardInfoProps {
  isNewCheckout: boolean;
  handleCardUpdate: (updatedValues: ICardUpdateValues) => void;
  existingPayment: IPaymentProfile | null | undefined;
  submitted: boolean;
  isRenderedInModal?: boolean;
  isAutopayModal?: boolean;
}

const CardInfo = ({
  isNewCheckout,
  handleCardUpdate,
  existingPayment,
  submitted,
  isRenderedInModal,
  isAutopayModal,
}: ICardInfoProps) => {
  return (
    <>
      <Styled.FromSection addMargin={!isNewCheckout && !isAutopayModal}>
        <Styled.CardholderName
          name="name"
          placeholder="Full name on card"
          label="Cardholder name"
          colon={false}
          submitted={submitted}
        />
        <SpreedlyCardInput
          payment={existingPayment}
          onCardUpdate={handleCardUpdate}
          isRenderedInModal={isRenderedInModal}
          isAutopayModal={isAutopayModal}
        />
      </Styled.FromSection>
    </>
  );
};

export default CardInfo;
