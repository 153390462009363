import { faBed } from "@fortawesome/pro-regular-svg-icons";
import { Icon } from "@hotel-engine/common/Icon";
import { pxToRem } from "@hotel-engine/styles/helpers/mixins";
import styled from "styled-components";

export const ExpressBookRoomInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 44px;
  padding: 0 ${pxToRem(40)} ${pxToRem(16)};

  color: ${({ theme }) => theme.colors.neutralN1000};
  line-height: 144%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-left: ${pxToRem(32)};
    padding-right: ${pxToRem(32)};
  }
`;

export const ExpressRoomInfoRoomBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${pxToRem(8)};
`;

export const ExpressRoomInfoBedIcon = styled(Icon).attrs(() => ({
  icon: faBed,
}))``;

export const ExpressRoomInfoRateBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    span {
      display: none;
    }
  }
`;

export const ExpressBookRoomTitleBlock = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 265px;
`;
