import { useTranslation } from "react-i18next";

export const useAirlineInfo = () => {
  const { t } = useTranslation("iata_airline");

  return {
    getAirlineNameFromIataAirlineCode: (iataAirlineKey: string) =>
      t(`iata_airline.name.${iataAirlineKey}`, { defaultValue: "Unknown airline" }),
  };
};
