import { usePostCheckoutActions } from "@hotel-engine/contexts";

import * as Styled from "./styles";

const PropertyReviewLink = ({
  onClick,
  reviewID,
  contractNumber,
}: {
  onClick: () => void;
  reviewID: string | undefined;
  contractNumber: string | undefined;
}) => {
  // Checkout verification context
  const { dispatch: dispatchPostCheckoutActions } = usePostCheckoutActions();

  const reviewProperty = () => {
    dispatchPostCheckoutActions({
      type: "INIT_POST_CHECKOUT_ACTION",
      payload: [
        {
          propertyReviewRequestId: reviewID,
          contractNumber,
        },
      ],
    });
    onClick();
  };

  return (
    <Styled.NotificationLink onClick={reviewProperty} as="p">
      Leave a review
    </Styled.NotificationLink>
  );
};
export default PropertyReviewLink;
