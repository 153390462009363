import { useExperiment } from "@hotel-engine/react-query/amplitude/useExperimentQuery";
import type { IVariant } from "@hotel-engine/types/amplitude";
import type { StandardFlagKey } from "@hotel-engine/types/featureFlag";

/** Standard feature flags */
type StandardFlagVariant = IVariant<"on" | "control">;

/**
 * A hook for standard feature flags in Amplitude Experiments.
 * @param key The feature flag key.
 * @returns `on`,`control`
 */
export const useFeatureFlag = (key: StandardFlagKey) => {
  return useExperiment<StandardFlagVariant>(key, {
    value: "control",
  });
};

/**
 * A hook for directly checking if a feature flag is `on`.
 * @param key The feature flag key.
 * @returns `true`,`false`
 */
export const useIsFeatureFlagOn = (key: StandardFlagKey) => {
  return useFeatureFlag(key).data.value === "on";
};
