import styled from "styled-components";

export const ExpressBookRateChangeAlertWrapper = styled.div`
  display: grid;
  grid-template-columns: 35px 1fr;
  background-color: ${({ theme }) => theme.legacy.colors.orange[50]};
  padding: 16px;
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.lg};
  margin: 0px 24px 16px;

  svg {
    margin: 4px 0 0 6px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const ExpressBookRateChangeAlertColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ExpressBookRateChangeAlertTitle = styled.div`
  font-weight: bold;
`;
