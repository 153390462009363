import { createGlobalStyle } from "styled-components";

import { colors, ellipsisText } from "@hotel-engine/styles";
import { globalTheme } from "@hotel-engine/styles/themes/global.theme";

export const NotificationStyles = createGlobalStyle`
  .ant-notification {
    margin-left: 0;
    .ant-notification-notice {
      background: ${colors.black};
      color: ${colors.white};
      font-size: ${globalTheme.legacy.fontSize.sm};
      padding: 15px;

      .ant-notification-notice-content {
        .ant-notification-notice-message {
          color: ${colors.white};
          font-size: ${globalTheme.legacy.fontSize.sm};
          font-weight: ${globalTheme.legacy.fontWeight.bold};
        }
      }

      .ant-notification-notice-close {
        color: ${colors.white};
      }
    }

    .favorite-notification {
      .ant-notification-notice-message {
        ${ellipsisText}
        display: block;
        margin-left: 35px;
        width: 225px;
      }
      .ant-notification-notice-description {
        margin-left: 33px;
      }
      .ant-notification-notice-icon {
        color: ${colors.khmerCurry};
      }
    }
  }
`;
