import type { FormikHelpers } from "formik";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useAppSelector } from "store/hooks";
import { emailRegEx, passwordRegEx, captureMessage } from "@hotel-engine/utilities";
import { usePersonalAccountUserMutation } from "@hotel-engine/react-query/users/usePersonalAccountMutation";
import type { SeverityLevel } from "@sentry/react";
import { useState } from "react";
import { useSignUpNavigate } from "..";
import { Box, Button, FormControl, TextInput, Typography } from "@hotelengine/atlas-web";
import { ErrorModal } from "../../ErrorModal";

type IPersonalUserFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

const HTTP_CODE_INTERNAL_ERROR = 500;

const UserFormDataSchema = yup.object().shape({
  firstName: yup.string().required("First name required"),
  lastName: yup.string().required("Last name required"),
  email: yup
    .string()
    .matches(emailRegEx, {
      message: "Please enter a valid email address",
    })
    .required("Please enter a valid email address"),
  password: yup
    .string()
    .matches(passwordRegEx, {
      message: "Please enter a valid password",
    })
    .required("Please enter a valid password")
    .when(["email"], ([email], schema) => {
      return schema.test({
        test: (password) => {
          return email && password && !password.includes(email.split("@")[0]);
        },
        message: "Password must not contain email",
      });
    }),
});

const SignUpForm = () => {
  const { onNavigate, onSetEmail } = useSignUpNavigate();
  const { user } = useAppSelector((state) => state.Auth);
  const personalAccountMutation = usePersonalAccountUserMutation();

  const [loading, setLoading] = useState(false);

  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleSubmit = (
    values: IPersonalUserFormValues,
    { setFieldError }: FormikHelpers<IPersonalUserFormValues>
  ) => {
    setLoading(true);
    personalAccountMutation.mutate(
      {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        passwordConfirmation: values.password,
      },
      {
        onSuccess() {
          onSetEmail(values.email);
          onNavigate("confirm-email-message");
        },
        onError(error) {
          const response = error?.response;
          const isInternalError =
            (response?.status ?? HTTP_CODE_INTERNAL_ERROR) >= HTTP_CODE_INTERNAL_ERROR;
          const errorReason = response?.data?.invalidParams?.[0]?.reasons?.[0];
          const errorDetails = !!errorReason ? ` - ${errorReason}` : "";
          let errorSeverity = "critical" as SeverityLevel;

          if (!isInternalError && errorReason) {
            setFieldError("email", errorReason);

            errorSeverity = "info";
          } else {
            setShowErrorModal(true);
          }

          captureMessage(
            `Error sign up personal account user${errorDetails}`,
            { error },
            errorSeverity
          );
        },
        onSettled: () => setLoading(false),
      }
    );
  };

  return (
    <>
      <Formik
        initialValues={
          {
            email: "",
            password: "",
            firstName: user?.firstName,
            lastName: user?.lastName,
          } as IPersonalUserFormValues
        }
        onSubmit={handleSubmit}
        validationSchema={UserFormDataSchema}
      >
        {({ values, setFieldValue, errors, touched }) => {
          const formErrors = {
            firstName: !!(errors.firstName && touched.firstName),
            lastName: !!(errors.lastName && touched.lastName),
            email: !!(touched.email && errors.email),
            password: !!(errors.password && touched.password),
          };

          return (
            <Form data-testid="signup-form">
              <Box display="flex" flexDirection="column" gap={16}>
                <Box display="flex" gap={16}>
                  <FormControl
                    label="First name"
                    status={formErrors.firstName ? "error" : "default"}
                    errorText="First name is required"
                  >
                    <TextInput
                      id="firstName"
                      name="firstName"
                      data-testid="firstName"
                      value={values.firstName}
                      onChange={(e) => setFieldValue("firstName", e.target.value)}
                    />
                  </FormControl>
                  <FormControl
                    label="Last name"
                    status={formErrors.lastName ? "error" : "default"}
                    errorText="Last name is required"
                  >
                    <TextInput
                      id="lastName"
                      name="lastName"
                      data-testid="lastName"
                      value={values.lastName}
                      onChange={(e) => setFieldValue("lastName", e.target.value)}
                    />
                  </FormControl>
                </Box>
                <FormControl
                  label="Personal email address"
                  status={formErrors.email ? "error" : "default"}
                  errorText={touched.email ? errors.email : undefined}
                >
                  <TextInput
                    autoComplete="username"
                    id="email"
                    name="email"
                    data-testid="email"
                    value={values.email}
                    onChange={(e) => setFieldValue("email", e.target.value)}
                  />
                </FormControl>
                <FormControl
                  label="Password"
                  status={formErrors.password ? "error" : "default"}
                  errorText="Password is required"
                >
                  <TextInput
                    autoComplete="new-password"
                    id="password"
                    name="password"
                    data-testid="password"
                    type="password"
                    value={values.password}
                    onChange={(e) => setFieldValue("password", e.target.value)}
                  />
                </FormControl>
                <Typography variant="body/xs" color="foregroundTertiary">
                  Password must be at least 8 characters long containing one uppercase letter, one
                  lowercase letter, and one number.
                </Typography>
                <Button
                  type="submit"
                  variant="filled"
                  data-testid="submit-button"
                  isLoading={loading}
                >
                  Continue
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
      <ErrorModal isOpen={showErrorModal} />
    </>
  );
};

export default SignUpForm;
