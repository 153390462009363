export type {
  Entry,
  EntriesDict,
  Path,
  Prop,
  PropOrPath,
} from "./core"

export {
  type Entries,
  isEntries,
  isEntriesDict,
  isEntry,
  isEntryOrPath,
  isPath,
  isProp,
  isPropOrPath,
}

import { type Any, Fn, List, Struct } from "@hotel-engine/data"
import * as Core from "./core"
import type {
  Entry,
  EntriesDict,
  Path,
  Prop,
  PropOrPath,
} from "./core"

type Entries = Any.Array<Path | Entry>

/** @internal */
const isProp
  : (u: unknown) => u is Prop
  = Fn.guardAny(Core.is.string, Core.is.number)

/** @internal */
const isPath = (u: unknown): u is Path =>
  Core.is.array(u) && u.every(isProp)

/** @internal */
const isPropOrPath
  : (u: unknown) => u is PropOrPath
  = Fn.guardAny(isProp, isPath)

/** @internal */
const isEntry = (u: unknown): u is Entry =>
  Core.is.array(u) && Core.is.function(List.last(u)) && isPath(List.init(u))

/** @internal */
const isEntryOrPath
  : (u: unknown) => u is Entry | Path
  = Fn.guardAny(isEntry, isPath)

/** @internal */
const isEntries = (u: unknown): u is Entries =>
  Core.is.array(u) && u.every(isEntryOrPath)

/** @internal */
const isEntriesDict = (u: unknown): u is EntriesDict =>
  Core.is.struct(u) && Struct.every(isEntryOrPath)(u)
