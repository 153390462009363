import { useLocation } from "@hotel-engine/lib/react-router-dom";
import { Unsafe } from "@hotel-engine/data";

import { useEffect, useState, useRef } from "react";

import { useGetAppVersion } from "./useGetAppVersion";

const useVersionCheckOnLocationChange = ({
  onVersionMismatch,
}: {
  onVersionMismatch: () => void;
}) => {
  const { pathname } = useLocation();
  const [storedValue, setValue] = useState<string | undefined>();
  const initRender = useRef(true);

  const { fetchedValue, refetch, isFetched } = useGetAppVersion();

  useEffect(() => {
    // on every path change after initial load we will check against version.txt to see if version is outdated
    if (!initRender.current) {
      refetch().then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    }
  }, [pathname, refetch]);

  useEffect(() => {
    // after first fetch we track the version in state for comparison
    if (isFetched && initRender.current) {
      setValue(fetchedValue);
      initRender.current = false;
      return;
    }

    // if the fetchedValue changes on subsequent refetch calls and does not match our storedValue we will reload the window to force new version of app
    if (isFetched && !initRender.current && storedValue !== fetchedValue) {
      onVersionMismatch();
    }
  }, [isFetched, fetchedValue, storedValue, onVersionMismatch]);

  return { appVersion: storedValue };
};

export default useVersionCheckOnLocationChange;
