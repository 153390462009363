import type { IExpressBookContractRate } from "@hotel-engine/types/expressBook";
import { formatCurrency } from "@hotel-engine/utilities";

import { useAppSelector } from "store/hooks";

import * as Styled from "./styles";
import type { IRoom } from "@hotel-engine/types/room";
import { formatRoomDescription } from "@hotel-engine/utilities/formatters/formatPropertyInformation";

interface IExpressBookRoomInfoProps {
  contractRate: IExpressBookContractRate;
  room: IRoom;
}

export const ExpressBookRoomInfo = ({ contractRate, room }: IExpressBookRoomInfoProps) => {
  const user = useAppSelector((state) => state.Auth.user);
  const currencyCode = user?.business?.currencyCode || "USD";

  return (
    <Styled.ExpressBookRoomInfoContainer>
      <Styled.ExpressRoomInfoRoomBlock>
        <Styled.ExpressRoomInfoBedIcon />
        <Styled.ExpressBookRoomTitleBlock>
          {formatRoomDescription(room.title ?? "")}
        </Styled.ExpressBookRoomTitleBlock>
      </Styled.ExpressRoomInfoRoomBlock>
      <Styled.ExpressRoomInfoRateBlock>
        <span>Lowest nightly rate: </span>
        <strong>{formatCurrency(contractRate.rate, true, currencyCode)}</strong>
      </Styled.ExpressRoomInfoRateBlock>
    </Styled.ExpressBookRoomInfoContainer>
  );
};
