import { Box, IconButton } from "@hotelengine/atlas-web";

export interface IModalNavBar {
  onGoBack?: () => void;
  onClose?: () => void;
}

export const ModalNavBar = ({ onGoBack, onClose }: IModalNavBar) => {
  return (
    <Box position="relative" style={{ height: "48px" }}>
      {!!onGoBack && (
        <Box position="absolute" style={{ left: 0 }}>
          <IconButton icon="arrow-left" onClick={() => onGoBack()}>
            &nbsp; Go Back
          </IconButton>
        </Box>
      )}
      {!!onClose && (
        <Box
          position="absolute"
          style={{
            right: 0,
          }}
        >
          <IconButton
            icon="xmark"
            color="primary"
            onClick={() => onClose()}
            data-testid="close-modal"
            style={{
              position: "relative",
            }}
          />
        </Box>
      )}
    </Box>
  );
};
