import type { IUser } from "@hotel-engine/types/user";

import NotificationInbox from "./NotificationInbox";
import { Box, Popover, PopoverContent, PopoverTrigger } from "@hotelengine/atlas-web";
import { useNotificationsTray } from "./useNotificationsTray";

import * as Styled from "./styles";

export interface IHeaderNotificationsProps {
  hasUnreadNotifications: boolean;
  setShowUnreadNotifications: (setUnread: boolean) => void;
  accountType: IUser["accountType"];
}

const HeaderNotifications = ({
  hasUnreadNotifications,
  setShowUnreadNotifications,
  accountType,
}: IHeaderNotificationsProps) => {
  const { badgeText, rest, trackNotificationsOpen, unreadMessageCount } = useNotificationsTray({
    setShowUnreadNotifications,
    accountType,
  });
  const hasUnreadMessages = unreadMessageCount > 0;

  return (
    <Box position="relative" display="flex" alignItems="center">
      {!!hasUnreadNotifications && <Styled.Badge text={badgeText} />}
      <Popover onOpenChange={trackNotificationsOpen}>
        <PopoverTrigger aria-label="notifications-menu">
          <Styled.HeaderIconWrapRedesign24 variant="plain" icon="bell" />
        </PopoverTrigger>
        <PopoverContent align="end" style={{ maxWidth: "unset" }}>
          <NotificationInbox
            accountType={accountType}
            hasUnreadMessages={hasUnreadMessages}
            {...rest}
          />
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default HeaderNotifications;
