import { useEffect } from "react";

import { useFormikContext } from "formik";

export const ScrollIntoView = () => {
  const form = useFormikContext();

  useEffect(() => {
    if (form.errors) {
      const errors = document.querySelectorAll(
        "#guest-error, .ant-form-explain, #expired-payment-method"
      );
      errors?.[0]?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [form.errors, form.submitCount]);
  return null;
};
