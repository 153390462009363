export const formatDateDictionary = {
  // Month Day variants
  // Jan 4
  "MMM D": {
    dmy: "D MMM",
    mdy: "MMM D",
    ymd: "MMM D",
  },
  // Jan 04
  "MMM DD": {
    dmy: "DD MMM",
    mdy: "MMM DD",
    ymd: "MMM DD",
  },
  // January 4th
  "MMMM Do": {
    dmy: "Do MMMM",
    mdy: "MMMM Do",
    ymd: "MMMM Do",
  },
  // Jan 4 at 4pm
  "MMM D \\at ha": {
    dmy: "D MMM \\at ha",
    mdy: "MMM D \\at ha",
    ymd: "MMM D \\at ha",
  },
  // Jan 4 at 4:03pm
  "MMM D \\at h:mm A": {
    dmy: "D MMM \\at h:mm A",
    mdy: "MMM D \\at h:mm A",
    ymd: "MMM D \\at h:mm A",
  },
  // Jan 4, 4pm
  "MMM D, h:m a": {
    dmy: "D MMM, h:m a",
    mdy: "MMM D, h:m a",
    ymd: "MMM D, h:m a",
  },
  // Jan 4, 4:00 pm
  "MMM D, h:mm a": {
    dmy: "D MMM, h:mm a",
    mdy: "MMM D, h:mm a",
    ymd: "MMM D, h:mm a",
  },
  // January 26
  "MMMM D": {
    dmy: "D MMMM",
    mdy: "MMMM D",
    ymd: "MMMM D",
  },
  // 01/04
  "MM/DD": {
    dmy: "DD/MM",
    mdy: "MM/DD",
    ymd: "MM/DD",
  },
  // 1/04
  "M/DD": {
    dmy: "DD/M",
    mdy: "M/DD",
    ymd: "M/DD",
  },
  // 1/4
  "M/D": {
    dmy: "D/M",
    mdy: "M/D",
    ymd: "M/D",
  },
  // 1/4/23
  "M/D/YY": {
    dmy: "D/M/YY",
    mdy: "M/D/YY",
    ymd: "YY/M/D",
  },
  // Month Day Year variants
  // January 2022
  "MMMM YYYY": {
    dmy: "MMMM YYYY",
    mdy: "MMMM YYYY",
    ymd: "YYYY MMMM",
  },
  // Jan 2022
  "MMM YYYY": {
    dmy: "MMM YYYY",
    mdy: "MMM YYYY",
    ymd: "YYYY MMM",
  },
  // 01/04/2022
  "MM/DD/YYYY": {
    dmy: "DD/MM/YYYY",
    mdy: "MM/DD/YYYY",
    ymd: "YYYY/MM/DD",
  },
  // 1/04/2022
  "M/DD/YYYY": {
    dmy: "DD/M/YYYY",
    mdy: "M/DD/YYYY",
    ymd: "YYYY/M/DD",
  },
  // 01/04/22
  "MM/DD/YY": {
    dmy: "DD/MM/YY",
    mdy: "MM/DD/YY",
    ymd: "YY/MM/DD",
  },
  // 01-04-2022
  "MM-DD-YYYY": {
    dmy: "DD-MM-YYYY",
    mdy: "MM-DD-YYYY",
    ymd: "YYYY-MM-DD",
  },
  // Jan 04, 2022
  "MMM DD YYYY": {
    dmy: "DD MMM YYYY",
    mdy: "MMM DD, YYYY",
    ymd: "YYYY MMM DD",
  },
  // January 4, 2022
  "MMMM D YYYY": {
    dmy: "D MMMM YYYY",
    mdy: "MMMM D, YYYY",
    ymd: "YYYY MMMM D",
  },
  // Jan 4th, 2022
  "MMM Do, YYYY": {
    dmy: "Do MMM YYYY",
    mdy: "MMM Do, YYYY",
    ymd: "YYYY MMM Do",
  },
  // Jan 4, 2022
  "MMM D YYYY": {
    dmy: "D MMM YYYY",
    mdy: "MMM D, YYYY",
    ymd: "YYYY MMM D",
  },
  // Tue, Jan 4
  "ddd, MMM D": {
    dmy: "ddd, D MMM",
    mdy: "ddd, MMM D",
    ymd: "ddd, MMM D",
  },
  // Including Day of week variants
  // Tue, Jan 4th, 2022
  "ddd, MMM Do, YYYY": {
    dmy: "ddd, Do MMM YYYY",
    mdy: "ddd, MMM Do, YYYY",
    ymd: "ddd, YYYY MMM Do",
  },
  // Tue, Jan 4, 22
  "ddd, MMM D, YY": {
    dmy: "ddd, D MMM YY",
    mdy: "ddd, MMM D, YY",
    ymd: "ddd, YY MMM D",
  },
  // Tue, Jan 4, 2022
  "ddd, MMM D, YYYY": {
    dmy: "ddd, D MMM YYYY",
    mdy: "ddd, MMM D, YYYY",
    ymd: "ddd, YYYY MMM D",
  },
  dddd: {
    dmy: "dddd",
    mdy: "dddd",
    ymd: "dddd",
  },
  "YYYY/MM/DD": {
    dmy: "YYYY/MM/DD",
    mdy: "YYYY/MM/DD",
    ymd: "YYYY/MM/DD",
  },
  // 9:00 AM
  "hh:mm A": {
    dmy: "hh:mm A",
    mdy: "hh:mm A",
    ymd: "hh:mm A",
  },
  // Tuesday, January 4th, 2022
  "dddd, MMMM Do, YYYY": {
    dmy: "dddd, MMMM Do, YYYY",
    mdy: "dddd, MMMM Do, YYYY",
    ymd: "dddd, MMMM Do, YYYY",
  },
  // 4
  D: {
    dmy: "D",
    mdy: "D",
    ymd: "D",
  },
} as const;
