import styled from "styled-components";

import { Icon } from "@hotel-engine/common/Icon";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { colors, sizes } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";

export const Notification = styled.div`
  ${flexbox({ direction: "column", alignItems: "center" })};
  min-height: 100vh;
  width: 100vw;
  background: ${colors.grey[50]};
  padding: 64px 16px 40px;
  @media screen and (min-width: ${sizes.breakpoints.sm}) {
    padding: 64px 16px 75px;
  }
`;

export const NotificationWrapper = styled.div`
  text-align: center;
  padding: 40px 25px;
  h1 {
    ${typographyMixin("large")};
    color: ${colors.errieBlack};
  }
  p {
    ${typographyMixin("body-medium")};
    color: ${colors.errieBlack};
  }
  button {
    min-width: 190px;
  }
`;

export const NotificationIcon = styled(Icon)<{ $isSuccess?: boolean }>`
  font-size: 40px;
  color: ${({ $isSuccess }) => ($isSuccess ? colors.finePine : colors.errieBlack)};
  margin: 0 0 15px;
`;
