import { useEffect, useState } from "react";

type ScriptStatus = "loading" | "idle" | "ready" | "error";

// Source: https://usehooks.com/useScript/
export function useScript(src?: string, attributes?: Record<string, string> | undefined) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState<ScriptStatus>(src ? "loading" : "idle");
  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus("idle");
        return;
      }
      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      const script = addScript(src, attributes);
      // Grab existing script status from attribute and set to state.
      setStatus((script.getAttribute("data-status") as ScriptStatus) ?? "error");

      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event: Event) => {
        setStatus(event.type === "load" ? "ready" : "error");
      };
      // Add event listeners
      script.addEventListener("load", setStateFromEvent);
      script.addEventListener("error", setStateFromEvent);
      // Remove event listeners on cleanup
      return () => {
        script.removeEventListener("load", setStateFromEvent);
        script.removeEventListener("error", setStateFromEvent);
      };
    },
    [src, attributes] // Only re-run effect if script src changes
  );
  return status;
}
export function addScript(src: string, attributes?: Record<string, string> | undefined) {
  const script: HTMLScriptElement =
    document.querySelector(`script[src="${src}"]`) ?? document.createElement("script");
  if (!script.src) {
    // Create script
    script.async = true;
    script.setAttribute("data-status", "loading");
    if (attributes) {
      Object.entries(attributes).forEach(([key, value]) => {
        script.setAttribute(key, value);
      });
    }

    // Store status in attribute on script
    // This can be read by other instances of this hook
    const setAttributeFromEvent = (event: Event) => {
      script.setAttribute("data-status", event.type === "load" ? "ready" : "error");
    };
    script.addEventListener("load", setAttributeFromEvent);
    script.addEventListener("error", setAttributeFromEvent);

    script.src = src;
    // Add script to document body
    document.body.appendChild(script);
  }
  return script;
}
