import { Auth, User } from "@hotel-engine/services";
import { cleanAccessToken } from "@hotel-engine/utilities/auth";
import { setAuthStatus } from "@hotel-engine/utilities/auth/events";
import storage from "@hotel-engine/storage";
import { tokenKey } from "@hotel-engine/constants";
import type { AccessTokenStored } from "@hotel-engine/utilities/auth/helpers";
import type { AppDispatch } from "store";
import { AuthActions } from "store/Auth/AuthRedux";
import { queryClient } from "@hotel-engine/contexts";
import { captureException } from "@hotel-engine/utilities/logger";

export const handleAuth0Callback = (dispatch: AppDispatch) => {
  return async () => {
    try {
      const tokens = storage.getSecureItem(tokenKey);
      const parsedTokens = JSON.parse(tokens as string) as AccessTokenStored;
      const updatedParsedTokens = {
        ...parsedTokens,
        unknown: "getFromAuth0",
      };

      storage.setSecureItem(tokenKey, JSON.stringify(updatedParsedTokens));
      cleanAccessToken("unknown"); // clean token except for unknown

      const newUser = await User.getSelf();

      if (newUser && !newUser.error) {
        dispatch(AuthActions.setUser(newUser));
        queryClient.clear();
        setAuthStatus(true);
        Auth.redirect();
      }
    } catch (e) {
      captureException(e);
      await Auth.signOut("Auth0 Callback Error");
    }
  };
};
