import { useQueryClient } from "react-query";

import { endpoints } from "../constants";
import { Unsafe } from "@hotel-engine/data";

export const useRefetchPropertyReviews = () => {
  /**
   * Invalidate the react-query cache, which will trigger the refetch
   */
  const queryClient = useQueryClient();
  const refetchFavoritePropertiesList = () => {
    queryClient
      .invalidateQueries(endpoints.propertyReviews)
      .then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    queryClient
      .invalidateQueries(endpoints.propertyReviewsBusinessSummary)
      .then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    queryClient
      .invalidateQueries(endpoints.propertyReviewsByAllUsersOfCompany)
      .then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    queryClient
      .invalidateQueries(endpoints.propertyReviewsBusinessSummaryById)
      .then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
  };

  return refetchFavoritePropertiesList;
};
