import type { UseQueryOptions } from "react-query";

import { usePostCheckoutActions } from "@hotel-engine/contexts";
import { endpoints } from "@hotel-engine/react-query/constants";
import { useApi } from "@hotel-engine/react-query/useApi";
import { useExtendedQuery } from "@hotel-engine/react-query/useExtendedQuery";
import type { ICheckoutVerification } from "@hotel-engine/types/notifications";
import type { IError } from "@hotel-engine/types/errors";

export const useCheckoutVerificationQuery = (
  id: ICheckoutVerification["id"],
  options?: UseQueryOptions<ICheckoutVerification, IError>
) => {
  const get = useApi("get");
  const { dispatch: dispatchPostCheckoutActions } = usePostCheckoutActions();

  return useExtendedQuery(
    [endpoints.checkoutVerification, id],
    () => get<ICheckoutVerification>(`${endpoints.checkoutVerification}/${id}`),
    {
      onSuccess: () => {
        dispatchPostCheckoutActions({
          type: "UPDATE_STATUS",
          payload: "isCheckoutVerificationSuccess",
        });
      },
      ...options,
    }
  );
};
