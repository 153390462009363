import type { UseQueryOptions } from "react-query";

import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { IPaymentProfile } from "@hotel-engine/types/paymentProfile";
import type { OlderPaginatedResults } from "@hotel-engine/types/generic";

import { useApi } from "../useApi";
import { useExtendedQuery } from "../useExtendedQuery";
import { endpoints } from "../constants";

export interface IPaymentProfileParams {
  businessId?: number;
  limit?: number;
}

export const usePaymentProfileQuery = (
  params?: IPaymentProfileParams,
  options?: UseQueryOptions<
    OlderPaginatedResults<IPaymentProfile>,
    IErrorResponse,
    OlderPaginatedResults<IPaymentProfile>
  >
) => {
  const get = useApi("get");

  return useExtendedQuery<OlderPaginatedResults<IPaymentProfile>, IErrorResponse>(
    [endpoints.paymentProfiles, params?.businessId],
    () => get(endpoints.paymentProfiles, { ...params }),
    options
  );
};
