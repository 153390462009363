import styled, { css } from "styled-components";
import { Card, Divider, Icon } from "@hotelengine/atlas-web";

export * from "../../styles";

export { Loader, Container } from "../Preferences/styles";

export const MFAOptionIcon = styled(Icon).attrs(() => ({
  color: "foregroundPrimary",
  size: "lg",
}))`
  margin-right: ${({ theme }) => theme.spacing[16]};
`;

export const SectionDivider = styled(Divider)`
  margin: ${({ theme }) => theme.spacing[24]} 0;
`;

export const CardContainer = styled(Card)`
    ${({ theme }) => css`
      flex-wrap: wrap;
      margin-bottom: ${theme.spacing[16]};
      padding: ${theme.spacing[24]};

      @media screen and (min-width: ${theme.breakpoints.md}) {
        flex-wrap: nowrap;
        margin-bottom: ${theme.spacing[24]};
        align-items: center;
      }
  `}

`;

export const CardValueContainer = styled(Card)`
  display: flex;
  border: none;
  align-items: center;
  overflow: visible;
  justify-content: space-around;
`;
