import type { IFlightSlice, IFlightsShopState } from "./flights.shop.types";
import { entityAdapter } from "./flights.shop.constants";

interface IUpsertEntityArgs {
  state: IFlightsShopState;
  type: "flight" | "fare";
  newData: IFlightSlice;
}

export const upsertEntity = ({ state, type, newData }: IUpsertEntityArgs) => {
  const entity = entityAdapter.getSelectors().selectById(state, newData.sliceIndex);
  const isDifferent = entity?.[type]?.continuationToken !== newData[type]?.continuationToken;

  if (isDifferent) {
    const followingEntitiesToRemove = state.ids.filter((id) => Number(id) > newData.sliceIndex);

    if (followingEntitiesToRemove.length) {
      entityAdapter.removeMany(state, followingEntitiesToRemove);
    }
    entityAdapter.upsertOne(state, newData);
  }
};
