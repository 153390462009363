import styled from "styled-components";

import { Skeleton } from "@hotel-engine/common/Skeleton";

export const SkeletonWrapper = styled.div`
  padding: 24px;
`;
export const SkeletonHeader = styled(Skeleton)`
  .ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-square {
    height: 80px;
    width: 80px;
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.lg};
  }
  .ant-skeleton-content {
    .ant-skeleton-title {
      margin-top: 5px;
      max-width: 75%;
      height: 20px;
    }
    .ant-skeleton-paragraph li {
      height: 12px;
      max-width: 50%;
    }
    .ant-skeleton-paragraph > li + li {
      margin-top: 10px;
      max-width: 35%;
    }
  }
  .ant-skeleton-title,
  .ant-skeleton-paragraph li {
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
  }

  .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
    margin: 10px 0 0;
  }
`;

export const SkeletonBody = styled(Skeleton)`
  margin: 30px 0;
  .ant-skeleton-content {
    display: block;
  }
  .ant-skeleton-content {
    .ant-skeleton-title {
      margin-top: 5px;
      max-width: 100%;
      height: 20px;
    }
    .ant-skeleton-paragraph li {
      height: 12px;
      margin-top: 10px;
    }
    .ant-skeleton-paragraph > li {
      &:nth-child(1) {
        max-width: 95%;
      }
      &:nth-child(2) {
        max-width: 85%;
      }
      &:nth-child(3) {
        max-width: 70%;
      }
    }
  }
  .ant-skeleton-title,
  .ant-skeleton-paragraph li {
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
  }

  .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
    margin: 10px 0 0;
  }
`;
export const SkeletonInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SkeletonInput = styled(Skeleton)`
  width: 45%;
  .ant-skeleton-content {
    display: inline-block;
    .ant-skeleton-title {
      margin: 0;
      height: 35px;
    }
  }
`;

export const SkeletonButton = styled(Skeleton)`
  width: 100%;
  .ant-skeleton-content {
    display: inline-block;
    .ant-skeleton-title {
      margin: 48px 0 24px;
      height: 35px;
    }
  }
`;
