import type { UseMutationOptions } from "react-query";
import { useMutation, useQueryClient } from "react-query";

import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { TSkipNotificationParams } from "@hotel-engine/types/notifications";

import { endpoints } from "../constants";
import { useApi } from "../useApi";
import { Unsafe } from "@hotel-engine/data";

export const useSkipPostCheckoutActions = (
  options?: UseMutationOptions<null, IErrorResponse, TSkipNotificationParams>
) => {
  const post = useApi("post");
  const queryClient = useQueryClient();

  return useMutation(
    (params) =>
      post(endpoints.skipPostCheckoutActions, {
        postCheckoutActions: params,
      }),
    {
      onSuccess: () => {
        queryClient
          .invalidateQueries([endpoints.user, "notifications"])
          .then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
      },
      ...options,
    }
  );
};
