import { Field } from "formik";

import Stars from "./Stars";

export interface IStarsInput {
  setStarRating: (val: number) => void;
  setHoverOn: (val: number) => void;
}

const StarsInput = ({ setStarRating, setHoverOn, hoverOn }) => (
  <Field name="starRating" id="starRating" type="number">
    {({ field: { value }, form: { setFieldValue } }) => (
      <Stars
        count={value}
        handleClick={(number) => {
          setFieldValue("starRating", number);
          setStarRating(number);
        }}
        setHoverOn={setHoverOn}
        hoverOn={hoverOn}
      />
    )}
  </Field>
);

export default StarsInput;
