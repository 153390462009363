import type { ReactNode } from "react";
import React, { createContext, useContext, useState, useEffect } from "react";

type OutletContent = React.ReactNode | undefined;
interface IInternalHeaderOutletContextProps {
  OutletContent: OutletContent;
  setOutletContent: (content: React.ReactNode) => void;
}

const HeaderOutletContext = createContext<IInternalHeaderOutletContextProps | undefined>(undefined);

export const HeaderOutletProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [OutletContent, setOutletContent] = useState<React.ReactNode | undefined>(undefined);

  return (
    <HeaderOutletContext.Provider value={{ OutletContent, setOutletContent }}>
      {children}
    </HeaderOutletContext.Provider>
  );
};

export const useInternalHeaderOutlet = (): IInternalHeaderOutletContextProps => {
  const context = useContext(HeaderOutletContext);
  if (!context) {
    throw new Error("useHeader must be used within a HeaderProvider");
  }

  return context;
};

export const useHeaderContent = () => {
  const { setOutletContent } = useInternalHeaderOutlet();

  useEffect(() => {
    return () => {
      setOutletContent(undefined);
    };
  }, [setOutletContent]);

  return setOutletContent;
};
