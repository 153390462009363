import { useParams } from "@hotel-engine/lib/react-router-dom";

import { usePropertyQuery } from "@hotel-engine/react-query/property/usePropertyQuery";
import type { IPropertyLoyaltyRewards } from "@hotel-engine/types/booking";

export const useLoyaltyRewards = () => {
  const { propertyId } = useParams<{ propertyId: string }>();
  const { data: property } = usePropertyQuery(Number(propertyId));

  const propertyLoyaltyRewards: IPropertyLoyaltyRewards = {
    id: property?.loyaltyRewardId,
    name: property?.loyaltyRewardName,
    programNumberLength: property?.loyaltyRewardProgramNumberLength,
  };

  return { propertyLoyaltyRewards, property };
};
