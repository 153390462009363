import { Scrollbars } from "react-custom-scrollbars";
import styled from "styled-components";

import { colors } from "@hotel-engine/styles";

interface IScrollbar {
  height: string;
}
export const Scrollbar = styled(Scrollbars)<IScrollbar>`
  -webkit-overflow-scrolling: touch;
  direction: ltr;
  height: ${({ height }) => height || "100%"};
  overflow: hidden;
  position: relative;
  width: 100%;
  will-change: transform;

  @media screen and (min-width: 980px) {
    margin-top: 0;
  }
`;

export const Thumb = styled.div`
  background-color: ${colors.grey[600]};
  border-radius: 50px /* disable theming (do not replicate) */;
  opacity: 0.75;
`;
