import type { IInvoice } from "@hotel-engine/types/invoice";
import { generateDownload } from "@hotel-engine/utilities";
import config from "config";

import Api from "./Api";
import Resource from "./Resource";

/**
 * @deprecated - switch to the associated react-query hooks if possible
 */
export default class Invoice extends Resource {
  public static _basePath = `${config.apiHostV2}/invoices`;

  // Duplicate of the constructor from ReadOnlyResource;
  // The context of "this" got broken in the upgrade to CRA,
  // so calling it explicitly here as a hack
  constructor(params?) {
    super(params);
    Object.assign(this, params);
  }

  // If an invoice has > 50 contracts, we post to this endpoint, sending the user an email with a PDF of all contracts
  public async requestAllContracts(id: number, params?: { "invoice_item_ids[]": number[] }) {
    return await Api.post(
      `${Invoice._basePath}/${id}/contracts`,
      {},
      undefined,
      undefined,
      params || null
    );
  }

  // If an invoice has <= 50 contracts, we also post, but to a different endpoint which returns the PDF contents
  public static async downloadAllContractsPDF(
    url: string,
    filename: string,
    params?: { "invoice_item_ids[]": number[] }
  ) {
    const res = await Api.downloadPost(url, params || null, {
      responseType: "arraybuffer",
    });
    generateDownload(res, filename);
  }

  public static async query() {
    const response = await Api.get(`${Invoice._basePath}`, null);

    return {
      ...response,
      results: response.results.map((i: IInvoice) => this._makeInstance(i)),
    };
  }

  // Duplicate of the _makeInstance from ReadOnlyResource;
  // The context of "this" got broken in the upgrade to CRA,
  // so calling it explicitly here as a hack to make .query()
  // work as it used it.
  public static _makeInstance(params) {
    return new Invoice(params);
  }

  public static async downloadPDF(
    url: string,
    filename: string,
    params?: { "invoice_item_ids[]": number[] }
  ) {
    const res = await Api.download(url, params || null, {
      responseType: "arraybuffer",
    });
    generateDownload(res, filename);
  }

  public static async get(id: number | string) {
    return await Api.get(`${Invoice._basePath}/${id}`);
  }
}
