import { googleMaps } from "@hotel-engine/scripts/google";
import { captureMessage } from "@hotel-engine/utilities";
import { once } from "lodash/fp";

export interface ILatLngObj {
  latitude: number;
  longitude: number;
}

const cityLocationElements = ["locality", "administrative_area_level_1", "country"];

/** Return placeId error to Sentry */
const handleError = (placeId: string) => (error: Error) => {
  const getDetailsError = `Google geocoder was unable to return information for the selection`;

  captureMessage(
    getDetailsError,
    {
      error,
      placeId,
    },
    "info"
  );
};

/** Ingests google.maps.GeocoderResponse
 * check for results length if no results return
 * use the first object in results array
 * use lat / lng funcs to return numerical values
 * */
const returnLatLngObj = (res: google.maps.GeocoderResponse) => {
  const hasLength = res.results.length > 0;
  if (hasLength) {
    const resultItem = res.results[0];

    return {
      latitude: resultItem.geometry.location.lat(),
      longitude: resultItem.geometry.location.lng(),
    } as ILatLngObj;
  } else {
    return;
  }
};

const returnLocation = (res: google.maps.GeocoderResponse) => {
  const hasLength = res.results.length > 0;
  if (hasLength) {
    const resultItem = res.results[0];
    const address = resultItem.address_components;
    const { types } = resultItem;
    const isAirport = (types || []).includes("airport");

    const filteredAddress = address.filter((component) =>
      cityLocationElements.find((element) => component.types.includes(element))
    );
    const addressToReturn = filteredAddress
      .slice(0, 2)
      .map((item, i) => (i === 0 ? item.long_name : item.short_name))
      .join(", ");

    return {
      latitude: resultItem.geometry.location.lat(),
      longitude: resultItem.geometry.location.lng(),
      address: addressToReturn,
      isAirport,
    };
  } else {
    return;
  }
};

const getGeocoder = once((api: google.maps.GeocodingLibrary) => {
  return new api.Geocoder();
});

export const getLatLng = (placeId: string) => {
  return googleMaps
    .importLibrary("geocoding")
    .then(getGeocoder)
    .then((api) =>
      api.geocode({
        placeId,
      })
    )
    .then(returnLatLngObj)
    .catch(handleError(placeId));
};

export const getLocation = (placeId: string) => {
  return googleMaps
    .importLibrary("geocoding")
    .then(getGeocoder)
    .then((api) =>
      api.geocode({
        placeId,
      })
    )
    .then(returnLocation)
    .catch(handleError(placeId));
};

/** useGoogleGeocoder
 * Exposes getLatLng function that returns results from google's geocoder
 * based on placeId
 * */
export const useGoogleGeocoder = () => {
  return {
    getLatLng,
    getLocation,
  };
};
