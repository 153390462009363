import type * as React from "react";

import { message as antdMessage } from "antd";
import type { ArgsProps } from "antd/lib/message";

type MessageArgs = ArgsProps;
type IMessageProps = MessageArgs | React.ReactNode | string;
type MessageDuration = number | (() => void);

/**
 * The `message` helper leverages antD to provide a UI for messages at the top of the screen
 *
 * @remarks Since we are leveraging antd for most components, our internal message component does not have any of it's own props. Instead, we are directly referring to the interface definition antd is expecting. This allows us to easily be in alignment with their type definitions and prevents type errors that may arise from maintaining an override interface.
 *
 * @see {@link https://3x.ant.design/components/message/ AntD Message Documentation}
 * @see {@link https://www.figma.com/file/S9dcIwiGPXxXIT1Iw1n33D/1.-Basic-Components-(Web)?node-id=1%3A8539 Design Specs}
 */
antdMessage.destroy();
export const message = {
  open: (props: MessageArgs) => antdMessage.open(props),
  destroy: () => antdMessage.destroy(),
  success: (props: IMessageProps, duration?: MessageDuration, onClose?: () => void) =>
    antdMessage.success(props, duration, onClose),
  error: (props: IMessageProps, duration?: MessageDuration, onClose?: () => void) =>
    antdMessage.error(props, duration, onClose),
  info: (props: IMessageProps, duration?: MessageDuration, onClose?: () => void) =>
    antdMessage.info(props, duration, onClose),
  warn: (props: IMessageProps, duration?: MessageDuration, onClose?: () => void) =>
    antdMessage.warn(props, duration, onClose),
  warning: (props: IMessageProps, duration?: MessageDuration, onClose?: () => void) =>
    antdMessage.warning(props, duration, onClose),
  loading: (props: IMessageProps, duration?: MessageDuration, onClose?: () => void) =>
    antdMessage.loading(props, duration, onClose),
};

export type { MessageType } from "antd/lib/message";
