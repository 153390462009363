import { Fn } from "../function/exports";
import { Result } from "../result/exports";


export {
  DO_NOTHING,
  IGNORE_ERROR,
  unsafeAssertionFromGuard,
  unsafeParserFromGuard,
  unsafeParserFromResult,
};

/** @internal */
const unsafeThrow = <T>(input: T): never => { throw input }
// /** @internal */
// const unsafeThrowWithTrace = <T extends Any.Showable>(input: T): never => { throw Error(`${input}`) }

/** @internal */
const unsafeAssertionFromGuard
  : <Source, Target>(guard: Fn.TypePredicate<[Source, Target]>, msg?: string) => Fn.Assert<[Source, Target]>
  = (guard, msg) =>
    (input) =>
      !guard(input)
        ? unsafeThrow(msg ?? `Assertion passed to \`assertionFromGuard\` failed`)
        : void 0
  ;

const unsafeParserFromGuard
  : <Source, Target>(guard: Fn.TypePredicate<[Source, Target]>, msg?: string) => (input: Source) => Target
  = <Source, Target>(guard: Fn.TypePredicate<[Source, Target]>, msg?: string) =>
    (input: Source) => {
      const assert: Fn.Assert<[Source, Target]> = unsafeAssertionFromGuard(guard, msg);
      assert(input);
      return input;
    };

const unsafeParserFromResult
  : <T, E>(result: Result.Result<T, E>) => T
  = <T, E>(result: Result.Result<T, E>): T =>
    Result.isErr(result)
      ? unsafeThrow(result.err)
      : result.ok
  ;


/**
 * {@link Unsafe.DO_NOTHING} is a signal, a semantic cry for help.
 * It exists only to satisfy the base requirements of an eslint-rule
 * so that we could turn the rule on. Now that the rule is on, its presence
 * is an indication of a likely defect at the call site, to make that
 * fact explicit.
 *
 * In most cases, a better approach would be to actually _use_ the value that
 * the promise returns. You could, for example `await` the promise, or you
 * could chain a `.then` call at the end -- or, if you really need to fire
 * off a Promise and don't care whether it succeeds or fails, you could
 * make that intent clear by prepending the call with the `void` keyword.
 *
 * If you're here because you stumbled upon `Unsafe.DO_NOTHING` in the wild,
 * you should do exactly what it says: nothing. That's what you've been asked to do,
 * and nobody will fault you for doing your job. Fixing it is somebody else's
 * responsibility.
 *
 * But ownership is a state of mind. So if you feel like that somebody is
 * you, who can say otherwise?
 *
 * Up to you. But the official stance of the platform team is:
 *
 * > It'd be a lot cooler if you did.
 *
 * {@link https://hotelengine.atlassian.net/browse/ENS-1604 | Ticket} • {@link https://github.com/typescript-eslint/typescript-eslint/blob/main/packages/eslint-plugin/docs/rules/no-floating-promises.md | ESLint rule}
 */
const DO_NOTHING = Fn.absorb;

/**
 * {@link Unsafe.IGNORE_ERROR} is a signal, a semantic cry for help.
 * It exists only to satisfy the base requirements of an eslint-rule
 * so that we could turn the rule on. Now that the rule is on, its presence
 * is an indication of a likely defect at the call site, to make that
 * fact explicit.
 *
 * In most cases, a better approach would be to actually _handle_ the error
 * case, either by wrapping the call in a try/catch block, or by adding a
 * `.catch` branch at the end.
 *
 * If you're here because you stumbled upon `Unsafe.IGNORE_ERROR` in the wild,
 * you should do exactly what it says: ignore it. That's what you've been asked
 * to do, and nobody will fault you for doing your job. Handling that failure
 * is somebody else's responsibility.
 *
 * But ownership is a state of mind. So if you feel like that somebody is
 * you, who can say otherwise?
 *
 * Up to you. But the official stance of the platform team is:
 *
 * > It'd be a lot cooler if you did.
 *
 * {@link https://hotelengine.atlassian.net/browse/ENS-1604 | Ticket} • {@link https://github.com/typescript-eslint/typescript-eslint/blob/main/packages/eslint-plugin/docs/rules/no-floating-promises.md | ESLint rule}
 */
const IGNORE_ERROR = Fn.absorb;
