import type { IUser } from "@hotel-engine/types/user";

import type { AuthValidatorFn } from "../helpers";

/** Blocks route access if user account does not have rewards enabled */
export const hasRewardsEnabled: AuthValidatorFn = (user: IUser) => {
  const disableRewards = user.business.disableRewards;

  if (disableRewards) {
    return false;
  }

  return true;
};
