// This file contains business logic that is used across pages

// Used on Results, Property, and Booking pages
// Determines the property type string that displays under the hotel name
import type { Moment } from "moment";
import moment from "moment";

export const determinePropertyTypeCopy = (
  propertyType: string | undefined | null,
  starRating: number
): string | undefined | null => {
  const propertyTypeCopy = propertyType?.toLocaleLowerCase() || "hotel";

  if (starRating) {
    return `${starRating.toString()}-star ${propertyTypeCopy}`;
  } else {
    return propertyTypeCopy;
  }
};

export const areDatesSameDay = (dateA: Moment, dateB: Moment) => {
  return dateA.isSame(dateB, "day");
};

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const getTimeFromTimestamp = (timestamp: string | undefined | null): string => {
  if (!timestamp) {
    return "";
  }
  const formattedTimestamp = moment(timestamp).utc().format("h:mm A");
  return formattedTimestamp;
};

export const isInsideIFrame = (): boolean => globalThis.location !== globalThis.parent.location;
