import { useAppSelector } from "store/hooks";
import { useLinkedAuthStrategiesQuery } from "@hotel-engine/react-query/authStrategies/useLinkedAuthStrategiesQuery";
import { useAuthStrategiesQuery } from "@hotel-engine/react-query/authStrategies/useAuthStrategiesQuery";

import type { IEmailLoginDid403 } from "../EmailConfirmationWarningModal";
import { SignInModalWrapper } from "./components/SignInModalWrapper";
import { SignInWithEmailPlusSSO } from "./components/SignInWithEmailPlusSSO";

interface ISignInModalProps {
  /** Indicates the modal is visible */
  visible: boolean;
  /** Closes out the modal */
  onCancel: () => void;
  /** Renders the "Whoops" modal indicating that the associated account has been email-verified */
  setEmailLoginDid403: (data: IEmailLoginDid403) => void;
  /** Optional forwarding route used post-login */
  forwardingRoute?: string;
}

export const SignInModal = ({
  visible,
  onCancel,
  setEmailLoginDid403,
  forwardingRoute,
}: ISignInModalProps) => {
  const user = useAppSelector((state) => state.Auth.user);
  const { data: authStrategy, isFetched } = useLinkedAuthStrategiesQuery(user?.accountType, {
    enabled:
      user?.accountType === "personal" ||
      (user?.accountType === "business" && !!user?.personalTravelUserId),
  });

  const lookupEmail =
    user?.accountType === "business"
      ? user?.personalTravelUserEmail
      : user?.businessTravelUserEmail;

  const { meta, isFetched: lookupFetched } = useAuthStrategiesQuery(lookupEmail, {
    enabled:
      !!lookupEmail &&
      (user?.accountType === "personal" ||
        (user?.accountType === "business" && !!user?.personalTravelUserId)),
  });

  const closeModal = () => {
    onCancel();
  };

  return visible ? (
    <SignInModalWrapper visible={!!isFetched && !!lookupFetched} onCancel={closeModal}>
      <SignInWithEmailPlusSSO
        authStrategies={authStrategy || []}
        shouldUseAuth0={meta?.useAuth0}
        queryIsResolved={!!isFetched && !!lookupFetched}
        lookupEmail={lookupEmail}
        closeModal={closeModal}
        setEmailLoginDid403={setEmailLoginDid403}
        forwardingRoute={forwardingRoute}
      />
    </SignInModalWrapper>
  ) : null;
};
