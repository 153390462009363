import { Checkbox } from "@hotel-engine/common/Checkbox";
import { useExpressBook } from "pages/Checkout/LegacyLodging/ExpressBookModal/hooks/useExpressBook";
import { useIncidentalVisibilityExpressBook } from "@hotel-engine/hooks/useIncidentalVisibility";
import type {
  IExpressBookCheckoutForm,
  IExpressBookContractRate,
} from "@hotel-engine/types/expressBook";
import type { IPropertyLegacy } from "@hotel-engine/types/property";
import { formatCurrency } from "@hotel-engine/utilities";
import { showFlexCancel } from "@hotel-engine/utilities/helpers/flexHelpers";
import { ampli } from "ampli";
import config from "config";
import { useFormikContext } from "formik";
import { defaultFlexPercentage } from "pages/Checkout/LegacyLodging/constants";
import { useEffect } from "react";
import { useAppSelector } from "store/hooks";
import { isFlexProEligibleBooking } from "./helpers";

import * as Styled from "./styles";

interface IExpressBookAdditionalServicesProps {
  contractInfo: IExpressBookContractRate;
  detailedPropertyInfo: IPropertyLegacy;
}

export const ExpressBookAdditionalServices = ({
  contractInfo,
  detailedPropertyInfo,
}: IExpressBookAdditionalServicesProps) => {
  const userInfo = useAppSelector((state) => state.Auth.user);
  const { checkIn, checkOut } = useAppSelector((state) => state.ShareOptions.search);
  const { expressBookAnalytics } = useExpressBook();
  const { values: expressBookFormValues, setFieldValue } =
    useFormikContext<IExpressBookCheckoutForm>();

  const currencyCode = userInfo?.business?.currencyCode || "USD";

  // FLEX-RELATED COMPUTED VALUES
  const showFlexCancellation = showFlexCancel({
    cancelBy: contractInfo.cancelBy,
    checkIn,
    checkOut,
    propertyTimezone: detailedPropertyInfo.timezone,
    roomCount: 1, // Room Count is always 1 in express book
    user: userInfo,
    flexConfigurationNotBlocked: userInfo?.business.flexConfiguration !== "blocked",
  });
  const defaultFlexCost: number =
    Math.round(contractInfo.total * defaultFlexPercentage * 100) / 100;
  const flexProSubscribed =
    userInfo?.business.flexAllowedBusiness && userInfo?.business.flexProEnrolled;
  const companyFlexRequired = userInfo?.business.flexConfiguration === "required";
  const isPriorityCustomer = contractInfo.flexCancellationCost === 0;

  const flexProOverride =
    isFlexProEligibleBooking({
      checkIn,
      checkOut,
      property: detailedPropertyInfo,
      user: userInfo,
      contractCancelBy: contractInfo.cancelBy,
    }) && userInfo?.business.flexProEnrolled;
  const flexProPriorityOrCompanyOverride =
    (companyFlexRequired || isPriorityCustomer) && showFlexCancellation;

  useEffect(() => {
    if (flexProOverride || flexProPriorityOrCompanyOverride) {
      setFieldValue("isFlexEnabled", true);
    }
  }, [setFieldValue, flexProOverride, flexProPriorityOrCompanyOverride]);

  // INCIDENTAL-RELATED COMPUTED VALUES
  const incidentalVisibility = useIncidentalVisibilityExpressBook(
    detailedPropertyInfo,
    expressBookFormValues
  );
  const travelPolicyIncidentalsRequired = userInfo?.incidentalsConfiguration === "required";
  const incidentalsText =
    incidentalVisibility !== "disabled"
      ? "Include Incidental Charges"
      : "This property does not allow preemptive payments for incidental coverage.";
  useEffect(() => {
    if (incidentalVisibility === "visible" || travelPolicyIncidentalsRequired) {
      setFieldValue("includeIncidentalCharges", true);
    } else {
      setFieldValue("includeIncidentalCharges", false);
    }
  }, [incidentalVisibility, travelPolicyIncidentalsRequired, setFieldValue]);

  // FORM HANDLERS
  const handleFlexCheckboxSelect = (e) => {
    ampli.clickSelectFlexCancellationCoverageOnExpressBookModal({
      propertyId: expressBookAnalytics.propertyId,
      searchId: expressBookAnalytics.searchId,
      hasFlexSelected: e.target.checked,
    });
    setFieldValue("isFlexEnabled", e.target.checked);
  };
  const handleIncidentalCheckboxSelect = (e) => {
    ampli.clickSelectIncidentalCoverageOnExpressBookModal({
      propertyId: expressBookAnalytics.propertyId,
      searchId: expressBookAnalytics.searchId,
      hasIncidentalCoverageSelected: e.target.checked,
    });
    setFieldValue("includeIncidentalCharges", e.target.checked);
  };

  return (
    <Styled.AdditionalServicesContainer>
      {!!flexProOverride && (
        <Styled.FlexProBanner data-testid="flex-pro-banner">
          <img alt="Flex Pro" src={`${config.cdnHost}/assets/flex-pro/flex-pro-logo.svg`} />
          <span>Subscribed</span>
        </Styled.FlexProBanner>
      )}
      {!!showFlexCancellation && !flexProOverride && (
        <Styled.AddFlexBanner htmlFor="add-flex-checkbox" data-testid="add-flex-banner">
          <Styled.RequiredFieldTagContainer>
            {!!companyFlexRequired && (
              <Styled.RequiredFieldTag $tagType="flex">Company Requirement</Styled.RequiredFieldTag>
            )}
          </Styled.RequiredFieldTagContainer>
          <Checkbox
            defaultChecked={
              companyFlexRequired ? companyFlexRequired : expressBookFormValues.isFlexEnabled
            }
            disabled={companyFlexRequired}
            id="add-flex-checkbox"
            data-testid="add-flex-checkbox"
            onClick={handleFlexCheckboxSelect}
            checked={expressBookFormValues.isFlexEnabled}
          />
          <Styled.AddFlexBannerLabelText>
            <Styled.FlexIcon />{" "}
            {companyFlexRequired ? (
              <>Flex Cancellation Added </>
            ) : !!isPriorityCustomer ? (
              "Flex is free for priority customers!"
            ) : (
              "Add Flex Cancellation"
            )}
          </Styled.AddFlexBannerLabelText>
          <Styled.AddFlexBannerPricing>
            {(defaultFlexCost > contractInfo.flexCancellationCost || !!flexProSubscribed) && (
              <Styled.AddFlexBannerPreDiscountPricing>
                {formatCurrency(defaultFlexCost, true, currencyCode)}
              </Styled.AddFlexBannerPreDiscountPricing>
            )}
            {flexProSubscribed || isPriorityCustomer
              ? "Free"
              : formatCurrency(contractInfo.flexCancellationCost, true, currencyCode)}
          </Styled.AddFlexBannerPricing>
        </Styled.AddFlexBanner>
      )}
      {incidentalVisibility !== "hidden" && (
        <Styled.IncidentalsBanner
          htmlFor="include-incidentals-checkbox"
          data-testid="include-incidentals-banner"
        >
          <Styled.RequiredFieldTagContainer>
            {!!travelPolicyIncidentalsRequired && (
              <Styled.RequiredFieldTag $tagType="incidentals">
                Company Requirement
              </Styled.RequiredFieldTag>
            )}
          </Styled.RequiredFieldTagContainer>
          {incidentalVisibility === "disabled" ? (
            <Styled.IncidentalsDisabledIcon />
          ) : (
            <Checkbox
              id="include-incidentals-checkbox"
              onClick={handleIncidentalCheckboxSelect}
              checked={
                travelPolicyIncidentalsRequired || expressBookFormValues.includeIncidentalCharges
              }
              defaultChecked={travelPolicyIncidentalsRequired}
              disabled={travelPolicyIncidentalsRequired}
            />
          )}
          <Styled.IncidentalsBannerLabelText
            $useDisabledStyles={incidentalVisibility === "disabled"}
          >
            {incidentalsText}{" "}
          </Styled.IncidentalsBannerLabelText>
          {incidentalVisibility !== "disabled" && (
            <Styled.IncidentalsBannerPricing data-testid="incidentalAmount">
              {formatCurrency(contractInfo.incidentalsFee, true, currencyCode)}
            </Styled.IncidentalsBannerPricing>
          )}
        </Styled.IncidentalsBanner>
      )}
    </Styled.AdditionalServicesContainer>
  );
};
