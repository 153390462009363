import { createSlice } from "@reduxjs/toolkit";

import type { ISearchMetricsState } from "./types";

import {
  addWebsocketBatch,
  resetState,
  resetStateForNewSearch,
  setEntryPointTimestamp,
  setMapLoadingTimes,
  setResultVisibleInList,
  setResultVisibleInMap,
  setResultsInteractiveTimestamp,
  setSpecificSearchTimestamps,
  setWebsocketAbortedAt,
  setWebsocketBatchVisibleInList,
  setWebsocketBatchVisibleOnMap,
  setWebsocketTimestamps,
} from "./reducers";

export const initialState: ISearchMetricsState = {
  listInteractiveAt: null,
  listResultVisibility: {},
  mapFinishedLoadingAt: null,
  mapInteractiveAt: null,
  mapResultVisibility: {},
  mapStartedLoadingAt: null,
  specificSearchResolvedAt: null,
  specificSearchStartedAt: null,
  rateRefreshStartedAt: null,
  userClickedEditSearchFromSearchResultsPageAt: null,
  userClickedSearchOnDashboardPageAt: null,
  userTriggeredSearchByMovingTheMapAt: null,
  websocketAbortedAt: null,
  websocketBatches: [],
  websocketClosedAt: null,
  websocketOpenedAt: null,
  websocketStartedAt: null,
};

export const searchMetricsSlice = createSlice({
  name: "SearchMetrics",
  initialState,
  reducers: {
    addWebsocketBatch,
    resetState,
    resetStateForNewSearch,
    setEntryPointTimestamp,
    setMapLoadingTimes,
    setResultsInteractiveTimestamp,
    setResultVisibleInList,
    setResultVisibleInMap,
    setSpecificSearchTimestamps,
    setWebsocketAbortedAt,
    setWebsocketBatchVisibleInList,
    setWebsocketBatchVisibleOnMap,
    setWebsocketTimestamps,
  },
});

export const searchMetricsActions = searchMetricsSlice.actions;

export default searchMetricsSlice.reducer;
