import { Form } from "antd";
import type { FormItemProps, FormProps } from "antd/lib/form";
import styled from "styled-components";

export const AntForm = styled(Form)<FormProps>``;

export const AntFormItem = styled(Form.Item)<FormItemProps>`
  &.ant-form-item {
    margin-bottom: 16px;
  }
  .ant-input-lg,
  .ant-select-lg {
    font-size: ${({ theme }) => theme.legacy.fontSize.md};
  }
  .ant-form-item-label {
    line-height: ${({ theme }) => theme.legacy.lineHeight.sm};
    label {
      margin-bottom: 4px;
      display: inline-block;
    }
  }
`;
