import { faBolt } from "@fortawesome/pro-solid-svg-icons";
import { Icon } from "@hotel-engine/common/Icon";
import type { IExpressBookPropertyRef } from "@hotel-engine/types/expressBook";
import { ExpressBookModalStep } from "@hotel-engine/types/expressBook";
import { ExpressBookModalBaseState } from "./components/ExpressBookModalBaseState/ExpressBookModalBaseState";
import { useExpressBook } from "pages/Checkout/LegacyLodging/ExpressBookModal/hooks/useExpressBook";

import { ExpressBookSuccessState } from "./components/ExpressBookSuccessState/ExpressBookSuccessState";

import * as Styled from "./styles";
import { ampli } from "ampli";

const ExpressBookModalTitle = () => {
  const { redirectToProperty, expressBookAnalytics } = useExpressBook();

  return (
    <>
      <strong>
        <Icon icon={faBolt} size={"sm"} /> Express Book
      </strong>

      <Styled.EditButton
        type="link"
        data-testid="express-book-edit-btn"
        onClick={() => {
          ampli.clickExpressBookEditButton({
            propertyId: expressBookAnalytics.propertyId,
            searchId: expressBookAnalytics.searchId,
          });
          redirectToProperty(false);
        }}
      >
        Edit
      </Styled.EditButton>
    </>
  );
};

const expressBookStepRenderer = (
  step: ExpressBookModalStep,
  checkoutPropertyRef: IExpressBookPropertyRef,
  checkIn: string,
  checkOut: string
) => {
  switch (step) {
    case ExpressBookModalStep.BASE_STATE:
    case ExpressBookModalStep.OVERLAPPING_STATE:
    case ExpressBookModalStep.ERROR_STATE:
      return (
        <ExpressBookModalBaseState
          checkoutPropertyRef={checkoutPropertyRef}
          checkIn={checkIn}
          checkOut={checkOut}
        />
      );
    case ExpressBookModalStep.SUCCESS_STATE:
      return <ExpressBookSuccessState />;
  }
};

const ExpressBookModal = () => {
  const {
    expressCheckoutProperty,
    expressBookCheckOut,
    expressBookCheckIn,
    modalStep,
    expressBookContractData,
    closeExpressBookModal,
    hideExpressbookForStackingModals,
  } = useExpressBook();

  const hideBaseModal =
    (modalStep === ExpressBookModalStep.BASE_STATE && hideExpressbookForStackingModals) ||
    modalStep === ExpressBookModalStep.OVERLAPPING_STATE; // TODO: expand this with more conditions

  const isOutOfPolicy = Boolean(expressBookContractData?.roomRate?.outOfPolicy);

  return (
    <>
      <Styled.ExpressBookModal
        className="express-book-modal"
        $hidden={hideBaseModal}
        $isOutOfPolicy={isOutOfPolicy}
        visible={!!expressCheckoutProperty}
        width={596}
        maskClosable={false}
        title={modalStep === ExpressBookModalStep.BASE_STATE && <ExpressBookModalTitle />}
        footer={null}
        onCancel={(e) => {
          if (e.type === "click") {
            closeExpressBookModal("modal_x");
          }
        }}
      >
        {!!expressCheckoutProperty && !!expressBookCheckIn && !!expressBookCheckOut && (
          <div className="express-book-modal" data-testid="express-book-modal">
            {expressBookStepRenderer(
              modalStep,
              expressCheckoutProperty,
              expressBookCheckIn,
              expressBookCheckOut
            )}
          </div>
        )}
      </Styled.ExpressBookModal>
    </>
  );
};

export default ExpressBookModal;
