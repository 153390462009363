import { Form } from "formik";
import styled from "styled-components";

import { buttonReset, colors } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import type { TextAreaProps, IconProps, TypographyProps } from "@hotelengine/atlas-web";
import { Icon, TextArea, Typography } from "@hotelengine/atlas-web";
import type React from "react";

export const ModalForm = styled(Form)`
  width: 100%;
`;

export const RadioContainer = styled.div`
  ${flexbox({
    justifyContent: "center",
    alignItems: "flex-start",
    direction: "column",
  })};
  color: ${colors.black};
`;

export const MoodContainer = styled.div`
  ${flexbox({
    justifyContent: "space-between",
    alignItems: "start",
  })};
  width: 100%;
`;

export const MoodWrapper = styled.button`
  ${buttonReset};
  ${flexbox({
    justifyContent: "center",
    alignItems: "center",
    direction: "column",
  })};
  position: relative;
  width: 20%;
`;

export const MoodLabel = styled<React.FC<TypographyProps>>(Typography)<{ selected: boolean }>`
  text-align: center;
  color: ${(props) => (props.selected ? colors.black : "transparent")};
  margin: ${({ theme }) => `${theme.spacing[4]} 0`}
`;

export const MoodIcon = styled<React.FC<IconProps>>(Icon)<{
  selected: boolean;
  $activeColor: string;
}>`
  margin: 8px 0 0;
  width: ${({ theme }) => theme.spacing[32]};
  height: ${({ theme }) => theme.spacing[32]};
  color: ${(props) => (props.selected ? props.$activeColor : colors.slate[400])};
`;

export const CommentContainer = styled.div`
  margin-top: 16px;
`;

export const CommentText = styled<React.FC<TextAreaProps>>(TextArea)`
  margin-top: 4px;
`;

export const ButtonsContainer = styled.div`
  ${flexbox({
    justifyContent: "flex-end",
    alignItems: "center",
    direction: "row",
  })};

  gap: ${({ theme }) => theme.spacing[8]};
  margin-top: ${({ theme }) => theme.spacing[16]};
`;
