import config from "config";

import { createSlice } from "@reduxjs/toolkit";

import type { IEnvModsState } from "./types";

export const initialState: IEnvModsState = {};

export const envModsEnabled = () => {
  switch (config.stage) {
    case "production":
    case "staging":
      return false;
    default:
      return true;
  }
};

export const envModsSlice = createSlice({
  name: "EnvMods",
  initialState,
  reducers: {
    clearEnvMods: () => initialState,
    setEnvMods: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const EnvModsActions = envModsSlice.actions;

export default envModsSlice.reducer;
