/* eslint-disable
 @typescript-eslint/no-namespace,
 @typescript-eslint/naming-convention,
 */
export {
  type GetStrategyMap,
  type HasStrategyMap,
  type Options,
  type Interpret,
  type Kind,
  type AssertionInterpreter,
  type GuardInterpreter,
  type ParserInterpreter,
  DefaultOptions,
  mergeOptions,
  Strategy,
}

import type { Option as O, Result as R } from "@hotel-engine/data"

interface AssertionInterpreter extends Kind { output: (u: unknown) => this["input"] }
interface GuardInterpreter extends Kind { output: (u: unknown) => u is this["input"] }
/** TODO: Create a more granular error type */
interface ParserInterpreter extends Kind { output: (u: unknown) => Result<this["input"], string> }


type Result<T, E> = R.Result<T, E>

const Strategy = {
  Assert: "Assert",
  Guard: "Guard",
  Parse: "Parse",
} as const
type Strategy = keyof typeof Strategy
type HasStrategyMap = {
  [Strategy.Assert]: AssertionInterpreter
  [Strategy.Guard]: GuardInterpreter
  [Strategy.Parse]: ParserInterpreter
}

type GetStrategyMap = {
  [Strategy.Assert]: IdentityInterpreter
  [Strategy.Parse]: OptionInterpreter
}

interface Options {
  strategy: Strategy
}

type DefaultOptions = typeof DefaultOptions
const DefaultOptions = {
  strategy: Strategy.Guard,
} as const

const mergeOptions
  : <T extends Partial<Options>>(userDefined: T)
    => { [K in keyof DefaultOptions]: K extends keyof T ? Exclude<T[K], undefined> : DefaultOptions[K] }
  = (userDefined) => ({ ...DefaultOptions, ...userDefined } as never)

type Interpret<K extends Kind, T> = (K & { input: T })["output"]
interface Kind {
  input: unknown
  output: unknown
}

interface AssertionInterpreter extends Kind { output: (u: unknown) => this["input"] }
interface GuardInterpreter extends Kind { output: (u: unknown) => u is this["input"] }
/** TODO: Create a more granular error type */
interface ParserInterpreter extends Kind { output: (u: unknown) => Result<this["input"], string> }
interface OptionInterpreter extends Kind { output: O.Option<this["input"]> }
interface IdentityInterpreter extends Kind { output: this["input"] }
