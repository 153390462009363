import type { UseQueryOptions } from "react-query";

import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { IUserV2Response } from "@hotel-engine/types/user";

import { endpoints } from "../constants";
import { useApi } from "../useApi";
import useDebouncedQuery from "../useDebouncedQuery";

export const useUsersQuery = (
  params: {
    search: string;
    limit?: number;
    includeUserLoyaltyRewards: boolean;
    isConfirmed: boolean | null;
  },
  options?: UseQueryOptions<IUserV2Response, IErrorResponse>
) => {
  const get = useApi("get");

  return useDebouncedQuery(
    [endpoints.users, params],
    () => get<IUserV2Response>(endpoints.users, params),
    {
      enabled: params.search.length > 0,
      ...options,
    }
  );
};
