/**
 * File to Base64 helper function: Used to convert a File object into a Base64 string
 * @param {File | Blob} file The File (or Blob) object to be converted
 */

const fileToBase64 = (file: File | Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export default fileToBase64;
