import styled from "styled-components";

import { Box, type BoxProps } from "@hotelengine/atlas-web";
import Button from "@hotel-engine/common/Button";
import Typography from "@hotel-engine/common/Typography";
import { colors, ellipsisText, sizes } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";

export const RoomTypography = styled(Typography)<{
  $color?: string;
  $margin?: string;
}>`
  ${({ $color }) => $color && `color: ${$color};`}
  ${({ $margin }) => $margin && `margin: ${$margin};`}
  ${ellipsisText}
  span {
    font-size: 12px;
    @media screen and (min-width: ${sizes.breakpoints.md}) {
      margin-left: 8px;
    }
  }
`;

export const SelectedGuestContainer = styled.div`
  padding: 16px;
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xl};
  border: 1px solid ${colors.grey[200]};
`;

export const SelectedGuest = styled.div`
  display: flex;
  align-items: center;
`;

export const ChangeButton = styled(Button)`
  margin-left: auto;
`;

export const AddHotelLoyalty = styled.div<{ renderForm?: boolean }>`
  margin-top: 16px;
  border-top: 1px dotted ${colors.grey[500]};
  padding: ${({ renderForm }) => (renderForm ? `16px 8px 0 0` : `16px 8px 0 0`)};
  width: 100%;
  display: flex;
  align-items: center;
`;

export const GuestContentContainer = styled<React.FC<BoxProps>>(Box)`
  width: 100%;
  ${flexbox({
    justifyContent: "space-between",
  })}
`;

export const GuestContentWrapper = styled<React.FC<BoxProps>>(Box)<{
  $isMobile: boolean;
}>`
  max-width: ${({ $isMobile }) => ($isMobile ? "60%" : "75%")};
  ${flexbox({
    alignItems: "center",
  })}
`;

export const GuestRoomWrapper = styled<React.FC<BoxProps>>(Box)`
  max-width: 100%;
  ${flexbox()}
`;
