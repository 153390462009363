import { useMutation, useQueryClient } from "react-query";

import { endpoints } from "@hotel-engine/react-query/constants";
import { useApi } from "@hotel-engine/react-query/useApi";
import type { IErrorResponse } from "@hotel-engine/types/errors";
import type {
  INewPaymentProfile,
  IPaymentProfile,
  IPaymentProfileUpdateParams,
} from "@hotel-engine/types/paymentProfile";

import { convertEmptyStrValsToNull } from "../../utilities";
import { Unsafe } from "@hotel-engine/data";

export const useUpdatePaymentProfile = () => {
  const queryClient = useQueryClient();
  const put = useApi("put");

  return useMutation<
    IPaymentProfile & Partial<INewPaymentProfile>,
    IErrorResponse,
    IPaymentProfileUpdateParams
  >(
    (params) => put(`${endpoints.paymentProfiles}/${params.id}`, convertEmptyStrValsToNull(params)),
    {
      onSuccess: () => {
        queryClient
          .invalidateQueries(endpoints.paymentProfiles)
          .then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
      },
    }
  );
};
