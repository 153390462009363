import styled from "styled-components";

import Button from "@hotel-engine/common/Button";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { buttonReset, colors } from "@hotel-engine/styles";

export const TextButton = styled(Button)`
  ${buttonReset};
  ${typographyMixin("small")};
  font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
  background: none !important;
  color: ${colors.retroBlue};
  :disabled {
    color: ${colors.grey[300]};
    :hover {
      color: ${colors.grey[300]};
    }
  }
`;
