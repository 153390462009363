/* eslint-disable @typescript-eslint/naming-convention, prefer-const */

export type ParseInt<T> = T extends `${infer N extends number}` ? N : T
export type KeyOf<T> =
  & keyof T
  & (
    [T] extends [readonly unknown[]]
    ? [number] extends [T["length"]] ? number
    : ParseInt<Exclude<keyof T, unknown[]>>
    : unknown
  )

export function map<const T, V>
  (fn: (value: T[KeyOf<T>], key: KeyOf<T>, object: T) => V): (object: T) => { [K in keyof T]: V }

export function map<const T, V>
  (object: T, fn: (value: T[KeyOf<T>], key: KeyOf<T>, object: T) => V): { [K in keyof T]: V }

/// impl.
export function map<const T, V>(
  ...args:
    | [fn: (value: T[KeyOf<T>], key: KeyOf<T>, object: T) => V]
    | [object: T, fn: (value: T[KeyOf<T>], key: KeyOf<T>, object: T) => V]
) {
  if (args.length === 1) return (object: T) => map(object, args[0])
  else {
    const [object, fn] = args
    if (globalThis.Array.isArray(object)) return object.map(fn as never)
    else {
      let out: { [x: string]: unknown } = {}
      for (const k in object)
        out[k] = fn(object[k] as never, k as never, object)
      return out
    }
  }
}
