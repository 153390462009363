import { Button, Icon } from "@hotelengine/atlas-web";
import styled from "styled-components";

export const EditIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.spacing[8]};
`;

export const EditButton = styled(Button)`
  height: ${({ theme }) => theme.spacing[40]};
`;

export const ChangePasswordButton = styled(Button)`
  height: 40px;
  text-wrap: nowrap;
`;
