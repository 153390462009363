import * as Styled from "./styles";

interface INotificationSkeleton {
  isPropertyReviewLoading?: boolean;
}

const NotificationSkeleton = ({ isPropertyReviewLoading }: INotificationSkeleton) => {
  return (
    <Styled.SkeletonWrapper data-testid="checkout-verification-skeleton">
      <Styled.SkeletonHeader
        avatar={{
          shape: "square",
        }}
        title={{ width: "auto" }}
        paragraph={{ rows: 2 }}
        active
      />
      <Styled.SkeletonBody title={{ width: "auto" }} paragraph={{ rows: 3 }} active />
      {!isPropertyReviewLoading && (
        <>
          <Styled.SkeletonInputWrapper>
            <Styled.SkeletonInput title paragraph={false} active />
            <Styled.SkeletonInput title paragraph={false} active />
          </Styled.SkeletonInputWrapper>
          <Styled.SkeletonButton title paragraph={false} active />
        </>
      )}
    </Styled.SkeletonWrapper>
  );
};

export default NotificationSkeleton;
