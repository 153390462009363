import { default as moment } from "moment";
import type { Moment } from "moment";

import { formatDateDictionary } from "@hotel-engine/constants/formatDateDictionary";
import type { IDateFormat } from "@hotel-engine/types/business";

/**
 * @deprecated - Use only when a hook is not suitable. A helper function to format dates by the preferred date format set in company settings for the business. Refer to useFormatDate for Functional Components.
 * @param date date string to be formatted
 * @param displayFormat matched moment string from formatDateDictionary
 * @param preferredDateFormat from business object as "dmy" || "mdy" || "ymd"
 * @param utc defaults as false, if you wish to intereact with date as an UTC date use true
 * @returns a formatted moment string
 */

export const formatDate = (
  date: string | Moment | undefined,
  displayFormat: keyof typeof formatDateDictionary,
  preferredDateFormat: IDateFormat,
  utc = false
) => {
  if (date === undefined) return undefined;

  const preferredDisplayFormat = formatDateDictionary[displayFormat][preferredDateFormat];

  return utc
    ? moment.utc(date).format(preferredDisplayFormat)
    : moment(date).format(preferredDisplayFormat);
};

/**  Single digit month, numbered day of month, four digit year
 * @param {string} date `Moment` or `string`
 * @example 3/08/2022
 */
export const formatDateNumericalShortDay = (date: Moment | string) => {
  formatDate(date, "MM/DD/YYYY", "mdy");
};

/**  Abbreviated day name, abbreviated month name, numbered day of month with letter suffixes and four digit numbered year
 * @param {string} date `Moment` or `string`
 * @example Sun, Feb 3nd, 2022
 */
export const formatDateLongFormWithDay = (date: Moment | string) => {
  formatDate(date, "ddd, MMM Do, YYYY", "mdy");
};
