import { useMutation } from "react-query";

import type { IErrorResponse } from "@hotel-engine/types/errors";
import config from "config";

import { useApi } from "../useApi";
import { endpoints } from "../constants";

interface IEmailConfirmationParams {
  email: string;
}

export const useEmailConfirmationsMutation = () => {
  const post = useApi("post");

  return useMutation<null, IErrorResponse, IEmailConfirmationParams>(({ email }) =>
    post(`${config.apiHostV2}/${endpoints.emailConfirmations}`, {
      email,
    })
  );
};
