import { useMutation, useQueryClient } from "react-query";

import { endpoints } from "@hotel-engine/react-query/constants";
import { convertEmptyStrValsToNull } from "@hotel-engine/utilities";
import { useApi } from "@hotel-engine/react-query/useApi";
import type { IExpressBookContractDataResponse } from "@hotel-engine/types/expressBook";
import type { IErrorResponse } from "@hotel-engine/types/errors";

export type TCreateExpressBookContractParams =
  | {
      propertyId: number | string;
      searchId?: number | string;
    }
  | {
      propertyId: number | string;
      checkIn: string;
      checkOut: string;
      guestCount: number;
    };

export const useCreateExpressBookContractQuery = () => {
  const queryClient = useQueryClient();
  const post = useApi("post");

  return useMutation<
    IExpressBookContractDataResponse,
    IErrorResponse,
    TCreateExpressBookContractParams
  >(
    (params) => {
      return post(endpoints.expressBookContractRate, convertEmptyStrValsToNull(params));
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(endpoints.expressBookContractRate);
      },
    }
  );
};
