import { openSalesForceChat } from "../../scripts/hooks";
import * as Styled from "./styles";

interface ISupportChatLink {
  text: string;
}

const SupportChatLink = ({ text }: ISupportChatLink) => {
  return <Styled.ChatLink onClick={() => openSalesForceChat()}>{text}</Styled.ChatLink>;
};

export default SupportChatLink;
