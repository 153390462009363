import styled from "styled-components";

import { Icon } from "@hotel-engine/common/Icon";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { colors } from "@hotel-engine/styles";

export const LoyaltyHeader = styled.h2`
  ${typographyMixin("medium")}
`;

export const LoyaltyHeaderSpan = styled.span`
  ${typographyMixin("body-small")}
  color: ${colors.grey[600]};
  margin-left: 8px;
`;

export const LoyaltySubtext = styled.p`
  ${typographyMixin("body-medium")}
  color: ${colors.grey[600]};
  margin-bottom: 16px;
`;

export const ProgramLengthWarningContainer = styled.div`
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xs};
  padding: 4px 6px;
  display: flex;
  align-content: center;
`;

export const WarningIcon = styled(Icon)`
  color: ${colors.retroBlue};
  margin-right: 8px;
  align-self: center;
`;

export const WarningText = styled.p`
  ${typographyMixin("body-medium")}
  color: ${colors.retroBlue};
  display: flex;
  width: auto;
`;
