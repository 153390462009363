import { useEffect, useState } from "react";

import SkeletonLoader from "@hotel-engine/common/SkeletonLoader";
import { useCreateExpressBookContractQuery } from "@hotel-engine/react-query/expressBook/useCreateExpressBookContractQuery";
import { useCustomFieldsQuery } from "@hotel-engine/react-query/customFields/useCustomFieldsQuery";
import { useAppSelector } from "store/hooks";

import { ExpressBookDatePicker } from "../ExpressBookDatePicker/ExpressBookDatePicker";
import { ExpressBookHotelPreviewCard } from "../ExpressBookHotelPreviewCard/ExpressBookHotelPreviewCard";
import { ExpressBookTripInformation } from "../ExpressBookTripInformation/ExpressBookTripInformation";
import { ExpressBookAdditionalServices } from "../ExpressBookAddtionalServices/ExpressBookAdditionalServices";
import { ExpressBookPaymentMethods } from "../ExpressBookPaymentMethods/ExpressBookPaymentMethods";
import { ExpressBookBaseStateFooter } from "../ExpressBookBaseStateFooter/ExpressBookBaseStateFooter";
import { ExpressBookPricingDetails } from "../ExpressBookPricingDetails/ExpressBookPricingDetails";
import { ExpressBookGuestSelection } from "../ExpressBookGuestSelection/ExpressBookGuestSelection";
import { ExpressBookRoomInfo } from "../ExpressBookRoomInfo/ExpressBookRoomInfo";
import { ExpressBookCheckoutForm } from "../ExpressBookCheckoutForm/ExpressBookCheckoutForm";
import ExpressBookRateChangeAlert from "../ExpressBookRateChangeAlert/ExpressBookRateChangeAlert";
import { ExpressBookCancelCopy } from "../ExpressBookCancelCopy/ExpressBookCancelCopy";
import { ExpressBookPaymentErrorBanner } from "../ExpressBookPaymentError/ExpressBookPaymentErrorBanner";
import { ExpressBookCheckoutFormError } from "../ExpressBookCheckoutFormError/ExpressBookCheckoutFormError";
import { ScrollIntoView } from "pages/Checkout/LegacyLodging/ScrollIntoView";
import { ampli } from "ampli";

import * as Styled from "./styles";
import {
  ExpressBookModalStep,
  type IExpressBookContractDataResponse,
  type IExpressBookPropertyRef,
} from "@hotel-engine/types/expressBook";
import moment from "moment";
import { ExpressBookOutOfPolicyAlert } from "../ExpressBookOutOfPolicyAlert/ExpressBookOutOfPolicyAlert";
import { useExpressBook } from "../../hooks/useExpressBook";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments/FeatureFlag";

interface IExpressBookModalBaseStateProps {
  checkoutPropertyRef: IExpressBookPropertyRef;
  checkIn: string;
  checkOut: string;
}

export const ExpressBookModalBaseState = ({
  checkoutPropertyRef,
  checkIn,
  checkOut,
}: IExpressBookModalBaseStateProps) => {
  const {
    mutate: generateContractRate,
    data: expressBookData,
    status: expressBookQueryStatus,
  } = useCreateExpressBookContractQuery();
  const { setContractData, modalStep, updateExpressBookDates } = useExpressBook();
  const [paymentErrors, setPaymentErrors] = useState<string[]>([]);

  const user = useAppSelector((store) => store.Auth.user);
  const showExpressBookCustomFields = useIsFeatureFlagOn("express_book_custom_fields");

  const { data: customFields, status: customFieldsQueryStatus } = useCustomFieldsQuery(
    {
      departmentId: user?.department.id,
      role: user?.role,
    },
    { enabled: showExpressBookCustomFields }
  );

  const isDataLoaded =
    expressBookQueryStatus === "success" &&
    (!showExpressBookCustomFields || customFieldsQueryStatus === "success") &&
    expressBookData;

  const hasDataError = expressBookQueryStatus === "error" || customFieldsQueryStatus === "error";

  const showRateChangeAlert = expressBookData && expressBookData.priceChange !== "none";

  const isOutOfPolicy = Boolean(expressBookData?.roomRate.outOfPolicy);

  const fetchContract = (
    dates?: { checkIn: string; checkOut: string },
    onSuccessCallback?: () => void,
    isDateChange?: boolean
  ) => {
    const onSuccess = (data: IExpressBookContractDataResponse) => {
      setContractData(data);
      onSuccessCallback?.();
    };

    if (checkoutPropertyRef.searchId && !isDateChange) {
      generateContractRate(
        {
          propertyId: checkoutPropertyRef.propertyId,
          searchId: checkoutPropertyRef.searchId,
        },
        { onSuccess }
      );
    } else {
      const checkinDate = dates?.checkIn || new Date();
      const checkoutDate = dates?.checkOut || moment().add("days", 1);
      const formattedCheckin = moment(checkinDate).format("YYYY-MM-DD");
      const formattedCheckout = moment(checkoutDate).format("YYYY-MM-DD");
      generateContractRate(
        {
          propertyId: checkoutPropertyRef.propertyId,
          checkIn: formattedCheckin,
          checkOut: formattedCheckout,
          guestCount: 1,
        },
        { onSuccess }
      );

      if (isDateChange) {
        updateExpressBookDates(formattedCheckin, formattedCheckout);
      }
    }
  };

  useEffect(() => {
    const clickTime = Date.now();

    const onSuccessCallback = () => {
      const loadingTime = Date.now() - clickTime;

      ampli.viewExpressBookModal({
        propertyId: checkoutPropertyRef.propertyId,
        searchId: checkoutPropertyRef.searchId,
        loadingTime,
      });
    };

    if (checkoutPropertyRef.searchId) {
      fetchContract(undefined, onSuccessCallback);
    } else {
      fetchContract({ checkIn, checkOut }, onSuccessCallback);
    }
    // Only want this to run on mount atm, may update logic for change date ticket later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Styled.ExpressBookBaseStateContainer
      data-testid="eb-bs-container"
      $isLoaded={!!isDataLoaded && modalStep !== ExpressBookModalStep.ERROR_STATE}
      $isOutOfPolicy={!!isOutOfPolicy}
    >
      {!!isOutOfPolicy && <ExpressBookOutOfPolicyAlert />}
      <Styled.ExpressBookHotelPreviewCardContainer>
        <ExpressBookHotelPreviewCard
          resultPropertyRef={checkoutPropertyRef}
          expressBookContractData={expressBookData}
        />
      </Styled.ExpressBookHotelPreviewCardContainer>

      <ExpressBookDatePicker handleDatesUpdate={fetchContract} />
      {!isOutOfPolicy ? (
        <>
          {!!showRateChangeAlert && (
            <ExpressBookRateChangeAlert changeType={expressBookData?.priceChange} />
          )}
          {paymentErrors.length > 0 && <ExpressBookPaymentErrorBanner errors={paymentErrors} />}
          {!isDataLoaded && !hasDataError && (
            <Styled.ExpressBookLoadingBlock>
              <h4>
                <strong>Hold tight</strong>, we’re grabbing the best rate for you.
              </h4>
              <SkeletonLoader type="bar" flexible />
              <SkeletonLoader type="bar" flexible />
              <SkeletonLoader type="bar" flexible />
            </Styled.ExpressBookLoadingBlock>
          )}
          {!!hasDataError && (
            <ExpressBookCheckoutFormError
              refreshContract={fetchContract}
              errorType={"fetch_error"}
            />
          )}
          {!!isDataLoaded && (
            <ExpressBookCheckoutForm
              expressBookData={expressBookData}
              refreshContract={fetchContract}
              setPaymentErrors={setPaymentErrors}
            >
              <Styled.ExpressBookBaseStateFormContainer>
                <ExpressBookRoomInfo
                  contractRate={expressBookData.contractRate}
                  room={expressBookData.room}
                />

                <ExpressBookGuestSelection
                  propertyData={expressBookData.property}
                  roomRate={expressBookData.roomRate}
                  contractRate={expressBookData.contractRate}
                />
                {!!showExpressBookCustomFields && (
                  <ExpressBookTripInformation customFields={customFields ?? []} />
                )}
                <ExpressBookAdditionalServices
                  contractInfo={expressBookData.contractRate}
                  detailedPropertyInfo={expressBookData.property}
                />
                <ExpressBookPaymentMethods
                  contractRate={expressBookData.contractRate}
                  propertyId={checkoutPropertyRef.propertyId}
                  searchId={checkoutPropertyRef.searchId}
                  paymentProfiles={expressBookData.paymentProfiles}
                />
                <ExpressBookPricingDetails
                  contractRate={expressBookData.contractRate}
                  roomRate={expressBookData.roomRate}
                  expressBookCheckIn={checkIn}
                  expressBookCheckOut={checkOut}
                />
                <ExpressBookCancelCopy copyText={expressBookData.contractRate.cancellationPolicy} />
                <ExpressBookBaseStateFooter expressBookData={expressBookData} />
                <ScrollIntoView />
              </Styled.ExpressBookBaseStateFormContainer>
            </ExpressBookCheckoutForm>
          )}
        </>
      ) : (
        <Styled.ExpressBookBaseStateFormContainer>
          {!!expressBookData && (
            <ExpressBookRoomInfo
              contractRate={expressBookData.contractRate}
              room={expressBookData.room}
            />
          )}
        </Styled.ExpressBookBaseStateFormContainer>
      )}
    </Styled.ExpressBookBaseStateContainer>
  );
};
