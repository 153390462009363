import type { UseQueryOptions } from "react-query";

import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { IUser } from "@hotel-engine/types/user";

import { cacheKeys, endpoints } from "../constants";
import { useApi } from "../useApi";
import { useExtendedQuery } from "../useExtendedQuery";

export interface IUsersData {
  users?: IUser[];
  limit: string;
  offset: number;
  total: number;
}

export interface ILastThreeBookedQueryConfig {
  options?: UseQueryOptions<IUsersData, IErrorResponse, IUsersData>;
}

const useLastThreeBookedQuery = ({ options = {} }: ILastThreeBookedQueryConfig) => {
  const get = useApi("get");
  const endpoint = `${endpoints.users}`;

  const params = {
    primaryGuest: true,
    limit: 3,
    includeUserLoyaltyRewards: true,
  };

  const queryKey = [cacheKeys.useLastThreeBookedQuery];
  const queryFn = () => get<IUsersData>(endpoint, { ...params });
  return useExtendedQuery<IUsersData, IErrorResponse>(queryKey, queryFn, options);
};

export { useLastThreeBookedQuery };
