import Api from "./Api";
import ReadOnlyResource from "./ReadOnlyResource";

export default class Resource<T = unknown> extends ReadOnlyResource<T> {
  protected static async _create<T>(params): Promise<T> {
    const response = await Api.post(this._basePath, params);
    return this._makeInstance(response) as T;
  }

  public id?: number | string;

  public async update(): Promise<T> {
    const response = (await Api.put(this._getInstancePath(), this)) as T;
    return Object.assign(this, response) as T;
  }

  public async save(): Promise<T> {
    if (this.id) {
      return this.update();
    }

    return (this.constructor as typeof Resource)._create<T>(this);
  }

  public async delete() {
    await Api.delete(this._getInstancePath(), null);
  }

  private _getInstancePath(): string {
    return `${(this.constructor as typeof Resource)._basePath}/${this.id}`;
  }
}
