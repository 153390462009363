import { useEffect } from "react";

import storage from "@hotel-engine/storage";
import config from "config";

/**
 * Initializes Google Analytics scripts on mount.
 */
export function useInitGoogleAnalytics(forceEnable = false) {
  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `(function (w, d, s, l, i) {
          w[l] = w[l] || []; w[l].push({
              'gtm.start':
                  new Date().getTime(), event: 'gtm.js'
          }); var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                  'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', '${config.gtmId}');`;
    const noScript = document.createElement("noscript");
    noScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${config.gtmId}" height="0" width="0"
            style="display:none;visibility:hidden"></iframe>`;
    if (!globalThis.Cypress && (forceEnable || !!storage.getSecureItem("googleTagManager"))) {
      document.head.insertBefore(script, document.head.firstChild);
      globalThis.dataLayer = [];
      globalThis.dataLayer.push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      });
      globalThis.document.body.insertBefore(noScript, globalThis.document.body.firstChild);
    }
    return () => {
      globalThis.document.body.removeChild(noScript);
      globalThis.document.head.removeChild(script);
    };
  }, [forceEnable]);
}
