import type {
  ExpressBookModalStep,
  IExpressBookCheckoutForm,
  IExpressBookContractDataResponse,
} from "@hotel-engine/types/expressBook";
import { ExpressBookActions } from "store/ExpressBook/ExpressBookRedux";
import type { IExpressBookState } from "store/ExpressBook/types";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { formatUrl as formatQueryString } from "@hotel-engine/react-router";
import { useNavigate, useLocation } from "@hotel-engine/lib/react-router-dom";
import { parse, stringify } from "qs";
import { pick } from "ramda";
import moment from "moment";
import { queryClient } from "@hotel-engine/contexts";
import { endpoints } from "@hotel-engine/react-query/constants";
import type { IPropertyLegacy } from "@hotel-engine/types/property";
import { routes } from "@hotel-engine/constants";
import { ampli } from "ampli";
import type { ISearchQueryParams } from "@hotel-engine/types/search";
import { Unsafe } from "@hotel-engine/data";
import { EB_GUEST_CACHE_SESSION_STORAGE_KEY } from "../constants";

export const useExpressBook = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // REDUX VALUE REFS
  const modalStep = useAppSelector((store) => store.ExpressBook.modalStep);
  const expressCheckoutProperty = useAppSelector((store) => store.ExpressBook.checkoutProperty);
  const expressBookCheckIn = useAppSelector((store) => store.ExpressBook.checkIn);
  const expressBookCheckOut = useAppSelector((store) => store.ExpressBook.checkOut);
  const expressBookAnalytics = useAppSelector((store) => store.ExpressBook.analytics);
  const expressBookSuccessContext = useAppSelector((store) => store.ExpressBook.successContext);
  const hideExpressbookForStackingModals = useAppSelector(
    (store) => store.ExpressBook.hideForStackingModals
  );
  const expressBookContractData = useAppSelector((store) => store.ExpressBook.contractData);
  const expressBookContractRateRefreshedAt = useAppSelector(
    (store) => store.ExpressBook.contractRateRefreshedAt
  );

  // REDUX ACTION HANDLERS
  const appDispatch = useAppDispatch();
  const setModalStep = (newModalStep: ExpressBookModalStep): void => {
    appDispatch(ExpressBookActions.setModalStep(newModalStep));
  };
  const setHideExpressBookForStackingModals = (hideVal: boolean): void => {
    appDispatch(ExpressBookActions.setHideForStackingModals(hideVal));
  };

  type EBCloseType =
    | "cancel_btn"
    | "modal_x"
    | "back_to_search_success"
    | "back_to_search_error"
    | "redirect";
  const closeExpressBookModal = (closeType: EBCloseType): void => {
    if (closeType === "modal_x") {
      ampli.clickCloseExpressBookModal({
        propertyId: expressBookAnalytics?.propertyId,
        searchId: expressBookAnalytics?.searchId,
      });
    }

    if (closeType === "cancel_btn") {
      ampli.clickCancelExpressBook({
        propertyId: expressBookAnalytics?.propertyId,
        searchId: expressBookAnalytics?.searchId,
      });
    }

    if (closeType === "back_to_search_success") {
      ampli.clickGoBackToSearchFromExpressBookSuccessModal();
    }

    if (closeType === "back_to_search_error") {
      ampli.clickGoBackToSearchFromExpressBookErrorState({
        propertyId: expressBookAnalytics?.propertyId,
        searchId: expressBookAnalytics?.searchId,
      });
    }

    // Handle search update if on search page & dates have been changed
    // NOTE: Removing for now, preserving if we want this logic in the future
    // if (rrLocation.pathname === routes.search) {
    //   const currentQueryParams: ISearchQueryParams = parse(
    //     rrLocation.search.substring(1)
    //   ) as unknown as ISearchQueryParams;
    //
    //   const formattedCheckin = moment(expressBookCheckIn).format("MM-DD-YYYY");
    //   const formattedCheckout =
    //     moment(expressBookCheckOut).format("MM-DD-YYYY");
    //   if (
    //     currentQueryParams.checkIn !== formattedCheckin &&
    //     currentQueryParams.checkOut !== formattedCheckout
    //   ) {
    //     const updatedQueryParams: ISearchQueryParams = {
    //       ...currentQueryParams,
    //       checkIn: formattedCheckin,
    //       checkOut: formattedCheckout,
    //     };
    //     navigate({
    //       pathname: `${routes.search}`,
    //       search: `?${stringify(updatedQueryParams)}`,
    //     });
    //
    //     queryClient
    //       .invalidateQueries(endpoints.searches)
    //       .then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    //   }
    // }

    // Clear cache for guest from this modal session
    globalThis.sessionStorage.removeItem(EB_GUEST_CACHE_SESSION_STORAGE_KEY);
    appDispatch(ExpressBookActions.closeModal());
  };
  const updateAnalyticsRefs = (val: Partial<IExpressBookState["analytics"]>): void => {
    appDispatch(ExpressBookActions.updateAnalyticsValues(val));
  };
  const advanceToSuccessState = (successContext: IExpressBookState["successContext"]) => {
    if (!successContext.itineraryNumber) {
      console.error(
        "[useExpressBook.ts | advanceToSuccessState()] Error: No itinerary number present on success call"
      );
      return;
    }

    appDispatch(
      ExpressBookActions.advanceToSuccessState({
        rewardsInfo: successContext.rewardsInfo || null,
        isRefundable: successContext.isRefundable || false,
        itineraryNumber: successContext.itineraryNumber,
      })
    );
  };
  const updateExpressBookDates = (checkIn: string, checkOut: string): void => {
    appDispatch(
      ExpressBookActions.updateTripDates({
        checkIn,
        checkOut,
      })
    );
  };

  const setContractData = (data: IExpressBookContractDataResponse) => {
    appDispatch(ExpressBookActions.setContractData(data));
  };

  const redirectToProperty = (shouldFocus = false) => {
    setTimeout(() => {
      closeExpressBookModal("redirect");
    }, 300);

    navigate({
      pathname: `${routes.properties}/${expressCheckoutProperty?.propertyId}`,
      search: formatQueryString({
        ...parse(location.search, {
          ignoreQueryPrefix: true,
        }),
        ...(shouldFocus && { defaultFocus: "startDate" }),
        s: expressBookContractData.roomRate?.searchId || expressCheckoutProperty?.searchId,
        checkIn: moment(expressBookCheckIn).format("MM-DD-YYYY"),
        checkOut: moment(expressBookCheckOut).format("MM-DD-YYYY"),
      }),
    });
  };

  const redirectToItinerary = () => {
    if (expressBookSuccessContext.itineraryNumber) {
      setTimeout(() => {
        closeExpressBookModal("redirect");
      }, 300);

      ampli.clickViewItineraryFromExpressBookSuccessModal({
        contractNumber: expressBookSuccessContext.itineraryNumber,
      });

      navigate({
        pathname: routes.itinerary,
        search: `?contract_number=${expressBookSuccessContext.itineraryNumber}`,
      });
    }
  };

  const redirectToSearch = ({ guestCount = 1, roomCount = 1 }) => {
    const propertyDetails = expressBookContractData.property;
    const locationString =
      expressCheckoutProperty?.city && expressCheckoutProperty?.state
        ? `${expressCheckoutProperty?.city}, ${expressCheckoutProperty?.state}`
        : `Near ${expressCheckoutProperty?.name}`;

    if (propertyDetails) {
      const searchParams: ISearchQueryParams = {
        locationId: "",
        lat: propertyDetails.latitude.toString(),
        lon: propertyDetails.longitude.toString(),
        location: locationString,
        // The following are hardcoded as "mdy" since all query params need to maintain "mdy" format for HE-API
        checkIn: moment(expressBookCheckIn).format("MM-DD-YYYY"),
        checkOut: moment(expressBookCheckOut).format("MM-DD-YYYY"),
        roomCount: roomCount,
        guestCount: guestCount,
        retrySearchWithExpandedRadius: true,
      };

      navigate({
        pathname: `${routes.search}`,
        search: `?${stringify(searchParams)}`,
      });

      queryClient
        .invalidateQueries(endpoints.searches)
        .then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);

      closeExpressBookModal("back_to_search_error");
    }
  };

  const redirectToCheckout = (formValues: IExpressBookCheckoutForm) => {
    setTimeout(() => {
      closeExpressBookModal("redirect");
    }, 300);

    // Since we already have the property we can cache it so the checkout page loads faster.
    if (expressBookContractData.property?.id) {
      queryClient.setQueryData<IPropertyLegacy>(
        [endpoints.properties, expressBookContractData.property.id],
        expressBookContractData.property
      );
    }

    // Carry filled form fields to Checkout.
    const expressBookFormValues = pick(
      ["guestList", "includeIncidentalCharges", "isFlexEnabled", "selectedPaymentId"],
      formValues
    );

    // TODO: Make sure redirects are working well after Change Dates work.
    // Specially if we're passing the updated `searchId`.
    navigate(
      {
        pathname: `${routes.properties}/${expressBookContractData.property?.id}/book`,
        search: formatQueryString({
          ...parse(location.search, { ignoreQueryPrefix: true }),
          roomRateId: expressBookContractData.roomRate?.id,
          contractId: expressBookContractData.contractRate?.id,
          s: expressBookContractData.roomRate?.searchId || expressCheckoutProperty?.searchId,
          checkIn: moment(expressBookCheckIn).format("MM-DD-YYYY"),
          checkOut: moment(expressBookCheckOut).format("MM-DD-YYYY"),
        }),
      },
      {
        state: {
          property: expressBookContractData.property,
          room: {
            ...expressBookContractData.room,
            rates: [expressBookContractData.roomRate],
          },
          contractRate: expressBookContractData.contractRate,
          checkIn: moment(expressBookCheckIn).format("MM-DD-YYYY"),
          checkOut: moment(expressBookCheckOut).format("MM-DD-YYYY"),
          prefilledFormValues: expressBookFormValues,
          reserveData: {
            clickReserveTime: Date.now(),
            // Prevents Ampli time tracking used when redirecting from Property -> Checkout.
            clickTimeRecorded: true,
            // Informs Checkout of elapsed time since the last Contract Rate refresh.
            contractRateRefreshedAt: expressBookContractRateRefreshedAt,
          },
        },
      }
    );
  };

  return {
    expressCheckoutProperty,
    modalStep,
    expressBookCheckIn,
    expressBookCheckOut,
    expressBookAnalytics,
    expressBookSuccessContext,
    expressBookContractData,
    hideExpressbookForStackingModals,
    setModalStep,
    setHideExpressBookForStackingModals,
    closeExpressBookModal,
    updateAnalyticsRefs,
    advanceToSuccessState,
    updateExpressBookDates,
    setContractData,
    redirectToSearch,
    redirectToItinerary,
    redirectToProperty,
    redirectToCheckout,
  };
};
