import { useEffect, useRef } from "react";

import { useBreakpoint } from "@hotel-engine/hooks";
import type { IUser } from "@hotel-engine/types/user";
import type { ICourierMessage } from "@hotel-engine/types/courierNotifications";

interface IUseNotificationsInbox {
  accountType: IUser["accountType"];
  messages: ICourierMessage[];
  fetchMessages: () => void;
  hasUnreadMessages: boolean;
}

export const useNotificationsInbox = ({
  messages,
  accountType,
  fetchMessages,
  hasUnreadMessages,
}: IUseNotificationsInbox) => {
  const hasMessages = !!messages?.length;
  const accountTypeRef = useRef(accountType);
  const isEmpty = !messages?.length;
  const isMobile = useBreakpoint("xs", "max");

  useEffect(() => {
    if (!hasMessages) {
      fetchMessages();
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUnreadMessages]);

  useEffect(() => {
    if (accountType !== accountTypeRef.current) {
      fetchMessages();
      accountTypeRef.current = accountType;
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountType]);

  return { isMobile, isEmpty, hasMessages };
};
