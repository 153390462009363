import type { ISupportCase, SupportCaseEssentials } from "@hotel-engine/types/supportCase";
import { Chip } from "@hotelengine/atlas-web";

interface IStatusCell {
  supportCase: SupportCaseEssentials | ISupportCase;
}

const supportCaseChipStyling = {
  Active: { color: "blue", variant: "filled" },
  Closed: {
    Denied: { color: "gray", variant: "outlined" },
    Approved: { color: "green", variant: "outlined" },
    // default for no sub-status
    color: "gray",
    variant: "outlined",
  },
};

const getChipStyling = (supportCase: SupportCaseEssentials | ISupportCase) => {
  const { status, requestStatus } = supportCase;
  const styling = supportCaseChipStyling[status];

  if (requestStatus && styling[requestStatus]) {
    return styling[requestStatus];
  }

  return { color: styling?.color, variant: styling?.variant };
};

const StatusCell = ({ supportCase }: IStatusCell) => {
  const { status, subStatus, requestStatus } = supportCase;

  const isInQueue = status === "Active" && subStatus === "In Queue";
  const hasCheck = status === "Closed" && requestStatus === "Approved";

  const { color, variant } = getChipStyling(supportCase);

  const label = requestStatus ?? subStatus ?? status;

  return (
    <>
      {isInQueue ? (
        <Chip label="Queued" variant="outlined" color="blue" />
      ) : (
        <Chip
          leadingIcon={hasCheck ? "check" : undefined}
          label={label}
          variant={variant}
          color={color}
        />
      )}
    </>
  );
};

export default StatusCell;
