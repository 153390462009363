import type { IFlexProResubscribe } from "@hotel-engine/types/flexPro";
import { endpoints } from "../constants";
import { useApi } from "../useApi";
import { useMutation } from "react-query";
import type { IErrorResponse } from "@hotel-engine/types/errors";

export const useResubscribeFlexProSubscription = () => {
  const put = useApi("put");

  return useMutation<IFlexProResubscribe, IErrorResponse, IFlexProResubscribe>((payload) =>
    put(endpoints.flexProSubscriptionsResubscribe, payload)
  );
};
