import { useEffect, useState } from "react";

import { datadogRum } from "@datadog/browser-rum";
import { Modal } from "@hotel-engine/common/Modal";

import config from "config";
import { eventChallengePresent, eventChallengeSolved } from "./client";

let interval;
const waitForTabClose = (tab) => {
  return new Promise((resolve) => {
    clearTimeout(interval);
    interval = setInterval(() => {
      if (tab?.closed) {
        clearTimeout(interval);
        resolve("complete");
      }
    }, 100);
  });
};

const CloudFlareChallenge = () => {
  const [showChallenge, setShowChallenge] = useState(false);

  const onOk = async () => {
    // open new tab
    const tab = globalThis.open(config.heApi + "/cloudflare.html?t=" + new Date().valueOf());
    await waitForTabClose(tab).then(() => {
      console.log("CloudFlareChallenge: Challenge Solved");
      const event = new CustomEvent(eventChallengeSolved);
      globalThis.dispatchEvent(event);
      setShowChallenge(false);
    });
  };

  useEffect(() => {
    if (!showChallenge) {
      console.log("CloudFlareChallenge: Listening for challenge");
      const callback = () => {
        setShowChallenge(true);
      };
      globalThis.addEventListener(eventChallengePresent, callback);
      return () => globalThis.removeEventListener(eventChallengePresent, callback);
    } else {
      // Log this event to datadog
      datadogRum.addAction("cloudflareChallenge");
      return () => {
        // noop
      };
    }
  }, [showChallenge]);

  return showChallenge ? (
    <Modal
      title="Security Check"
      visible={showChallenge}
      onOk={onOk}
      cancelButtonProps={{ style: { display: "none" } }}
      closable={false}
    >
      <p>Safety first! Please complete the security check to continue using the app.</p>
    </Modal>
  ) : null;
};

export default CloudFlareChallenge;
