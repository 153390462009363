import { Select as antdSelect } from "antd";
import styled from "styled-components";

import { colors } from "@hotel-engine/styles";

export const Select: typeof antdSelect = styled(antdSelect)`
  color: ${colors.grey[800]};
  .ant-select-selection__placeholder,
  div.ant-select-selection__placeholder {
    color: ${colors.newGrey500};
  }
` as never;
