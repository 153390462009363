import type { Dispatch, ReactNode, SetStateAction } from "react";
import { createContext, useState } from "react";

import type { ICheckoutFormData } from "pages/Checkout/validation";

interface IValue {
  checkoutState: ICheckoutFormData;
  setCheckoutState: Dispatch<SetStateAction<ICheckoutFormData>>;
  resetCheckoutProvider: () => void;
}

interface ICheckoutProviderProps {
  /** React children */
  children: ReactNode;
}

const initialState = {
  applyToAll: false,
  isFlexEnabled: false,
  isFlexProEnrolled: false,
  customFields: [],
  directBillCode: "",
  directBillVerificationRequired: false,
  duplicateOverride: false,
  guestList: [],
  rewardsPoints: 0,
  travelCredits: 0,
  includeIncidentalCharges: false,
  selectedPaymentId: undefined,
  extensionConfirmed: false,
};

export const CheckoutContext = createContext({} as IValue);

const CheckoutProvider = ({ children }: ICheckoutProviderProps) => {
  const [checkoutState, setCheckoutState] = useState<ICheckoutFormData>(initialState);

  const resetCheckoutProvider = () => {
    setCheckoutState(initialState);
  };

  const value: IValue = {
    checkoutState,
    setCheckoutState,
    resetCheckoutProvider,
  };
  return <CheckoutContext.Provider value={value}>{children}</CheckoutContext.Provider>;
};

export default CheckoutProvider;
