import { useCallback, useState } from "react";

/**
 * Simple hook to control visibility of a piece of UI within
 * a functional component
 *
 * @param {boolean} defaultVisible - default visibility
 * @returns {object} visible, show, hide, toggle, setVisible
 */
export function useVisibility(defaultVisible = false) {
  const [visible, setVisible] = useState<boolean>(defaultVisible);

  const show = useCallback(() => {
    setVisible(true);
  }, []);

  const hide = useCallback(() => {
    setVisible(false);
  }, []);

  const toggle = useCallback(() => {
    setVisible((prevVisible) => !prevVisible);
  }, []);

  return {
    visible,
    show,
    hide,
    toggle,
    setVisible,
  };
}
