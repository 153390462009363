import type { IUserLoyaltyProgram } from "@hotel-engine/types/user";
import config from "config";

import Api from "./Api";

/**
 * @deprecated - switch to the associated react-query hooks if possible
 */
export default class UserLoyaltyProgram {
  public static async list(): Promise<IUserLoyaltyProgram[]> {
    const path = this.getUrl();
    return await Api.get<IUserLoyaltyProgram[]>(path, {});
  }

  public static async createOrUpdate(program: IUserLoyaltyProgram): Promise<IUserLoyaltyProgram> {
    const path = this.getUrl();
    if (program.id) {
      return await Api.put<IUserLoyaltyProgram>(`${path}/${program.id}`, program);
    }

    return await Api.post<IUserLoyaltyProgram>(path, program);
  }

  public static async delete(program: IUserLoyaltyProgram): Promise<IUserLoyaltyProgram> {
    const path = this.getUrl();
    // Does not actually return the deleted object - the type is a lie
    return await Api.delete<IUserLoyaltyProgram>(`${path}/${program.id}`, {});
  }

  private static getUrl(): string {
    return `${config.apiHostV2}/user_loyalty_rewards`;
  }
}
