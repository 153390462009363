import { useMutation, useQueryClient } from "react-query";

import { endpoints } from "@hotel-engine/react-query/constants";
import { useApi } from "@hotel-engine/react-query/useApi";
import type { IErrorResponse } from "@hotel-engine/types/errors";
import { Unsafe } from "@hotel-engine/data";

export const useDeletePaymentProfile = () => {
  const queryClient = useQueryClient();
  const del = useApi("delete");

  return useMutation<null, IErrorResponse, number>(
    (id) => del(`${endpoints.paymentProfiles}/${id}`),
    {
      onSuccess: () => {
        queryClient
          .invalidateQueries(endpoints.paymentProfiles)
          .then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
      },
    }
  );
};
