import * as Sentry from "@sentry/browser";
import moment from "moment";

import type { IBusiness } from "@hotel-engine/types/business";
import type { IDepartment } from "@hotel-engine/types/department";
import type { IUser } from "@hotel-engine/types/user";
import { cleanAccessToken, setToken } from "@hotel-engine/utilities/auth";
import { NOT_LINKED_MESSAGE } from "@hotel-engine/utilities/constants/ErrorMessages";
import config from "config";
import { captureMessage } from "@hotel-engine/utilities";

import Api from "./Api";
import Resource from "./Resource";
import { roleMap } from "@hotel-engine/types/role";
import type { IRole } from "@hotel-engine/types/role";

/**
 * @deprecated - switch to the associated react-query hooks if possible
 */
export default class User extends Resource<User> {
  public static _currentUser;

  // Duplicate of the constructor from ReadOnlyResource;
  // The context of "this" got broken in the upgrade to CRA,
  // so calling it explicitly here as a hack
  constructor(params?) {
    super(params);
    Object.assign(this, params);
  }

  public static current(user?: IUser): IUser {
    if (user) {
      this._currentUser = this._makeInstance(user);
      const userContext = {
        email: this._currentUser.email,
        id: this._currentUser.id,
        businessId: this._currentUser.businessId,
        role: this._currentUser.role,
      };

      Sentry.setUser(userContext);
    }

    return this._currentUser;
  }

  // Duplicate of the _makeInstance from ReadOnlyResource;
  // The context of "this" got broken in the upgrade to CRA,
  // so calling it explicitly here as a hack to make .query()
  // work as it used it.
  public static _makeInstance(params) {
    return new User(params);
  }

  public static async getSelf(switchUserAttempt?: boolean, currentUser?: IUser) {
    try {
      const user = await Api.get(`${config.apiHostV2}/user`, null, undefined, switchUserAttempt);
      // Need user id and account type in order to make the proper comparison
      // check if user i'm validly linked to user if not return no user
      let canTransition = !(switchUserAttempt && currentUser);
      if (switchUserAttempt && currentUser) {
        // i am linked to this new user.
        if (currentUser?.accountType !== user?.accountType) {
          if (
            // case: i am business travel user transitioning into personal travel
            (user?.accountType === "personal" && currentUser?.personalTravelUserId === user?.id) ||
            // case: i am personal travel user transitioning into business travel
            (user?.accountType === "business" && currentUser?.businessTravelUserId === user?.id)
          ) {
            canTransition = true;
          } else {
            cleanAccessToken(currentUser?.accountType);
            return { error: NOT_LINKED_MESSAGE };
          }
        }
      }

      if (canTransition) {
        this.current(user);
        setToken();
        return user;
      }
    } catch (error) {
      captureMessage("Error getting user in getUser", { error });
    }
    return null;
  }

  public static setUserRefresh() {
    const maxAge = config.userTTL ? config.userTTL : 1000 * 60 * 15;

    globalThis.setInterval(async () => {
      if (this._currentUser) {
        const user = await this.getSelf();
        this._currentUser = this._makeInstance(user);
      }
    }, maxAge);
  }

  public static async signOut() {
    this._currentUser = undefined;
  }

  public business!: IBusiness;
  public currentPassword?: string;
  public department!: IDepartment;
  public directBill!: boolean;
  public email!: string;
  public employeeId!: string | null;
  public firstName!: string;
  public lastName!: string;
  public lastSignInAt!: string | null;
  public newPassword?: string;
  public refundableOnly!: boolean;
  public rewardsMember!: boolean;
  public role!: IRole;

  public getTimeSinceLastSignIn() {
    if (!this.lastSignInAt) {
      return "never";
    }

    return moment(this.lastSignInAt).fromNow();
  }

  public hasRole(role: IRole) {
    return roleMap[this.role] >= roleMap[role];
  }

  public async joinRewards() {
    if (this.rewardsMember) {
      return new Error("User is already enrolled in the rewards program");
    }

    return Api.post(`${config.apiHostV2}/user/enroll_rewards`, {});
  }

  public async save() {
    const path = `${config.apiHostV2}/users/${this.id}`;

    const response: IUser = await Api.put(path, this);

    return Object.assign(this, response);
  }
}
