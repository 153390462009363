import type { ICurrency } from "@hotel-engine/constants/currencyDictionary";

export interface IRoomCreateParams {
  checkIn?: string;
  checkOut?: string;
  propertyId: number | string;
  salesChannel?: string;
  searchId?: number | string;
  supplier?: string;
  limit?: number;
}

/**
 * @deprecated use {@link IRoom}
 */
interface IBaseRoom {
  readonly cancelBy: string | null;
  readonly cancellationPolicy: string;
  readonly clientProgramName?: string;
  readonly description: string;
  readonly displayRate: string;
  readonly displaySaveAmount: string;
  readonly displayStrikeRate: string;
  readonly hotelCollect: boolean;
  readonly id: number;
  readonly nightlyRates: INightlyRate[];
  readonly rate: number;
  readonly ratePlan?: string;
  readonly roomCode: string;
  readonly saveAmount: number;
  readonly savePercent: number;
  readonly searchId: number;
  readonly strikeRate: number;
  readonly supplier: string;
  readonly unknownBed: boolean;
}

export interface IRoomCreateResult extends IBaseRoom {
  isCancellable?: boolean;
}

/**
 * @deprecated uses the v1/rooms endpoints. use {@link IRoom}
 */
export interface ILegacyRoom extends IBaseRoom {
  readonly isCancellable: () => boolean;
}

export enum AmenityAvailability {
  /**
   * We cannot determine if the amenity is available.
   */
  AMENITY_AVAILABILITY_UNKNOWN = 0,
  /**
   * This Amenity is not available with the selected rate.
   */
  AMENITY_UNAVAILABLE = 1,
  /**
   * The Amenity is available with the selected rate.
   */
  AMENITY_AVAILABLE = 2,
}

export interface IRateIncludedAmenities {
  breakfast: AmenityAvailability;
  parking: AmenityAvailability;
}

export interface IRoomRate {
  cancelBy?: string;
  cancellationPolicy?: string;
  clientProgramName?: string;
  currency: ICurrency;
  displayStrikeRate: string;
  displayRate: string;
  displaySaveAmount: string;
  doubleRewards?: boolean;
  groupingIndex: number; //can be used as a unique id for the room
  hasDeals?: boolean;
  hotelCollect: boolean;
  id: number;
  loyaltyEligible: boolean;
  mappingAttributes?: { smokingAllowed: boolean }[];
  nightlyRates: INightlyRate[];
  numRooms: number;
  numNights: number;
  outOfPolicy: boolean;
  rate: number;
  includedAmenities: IRateIncludedAmenities;
  ratePlan?: string;
  roomCode: string;
  saveAmount: number;
  savePercent?: number;
  searchId: number;
  strikeRate: number;
  supplier: string;
  totalCustomerBasePrice: number;
  totalCustomerPrice: number;
  totalTaxesAndFees: number;
}

export type RoomResponsePriceChange = "decrease" | "increase" | "none";

export interface IRoomsResponse {
  rooms: IRoom[];
  /**
   * if the price has increased/decreased from the last time the rooms was called with the provided searchId
   */
  priceChange: RoomResponsePriceChange;
  /**
   * if Vervotech is down (our mapping & grouping third party service), this will be true
   */
  failedToRoomGroup: boolean;
  /**
   * GSA rate information for the rooms
   */
  gsaRates: IRoomsGsaRate[];
}

export interface IRoomsGsaRate {
  city: string;
  rates: {
    month: number;
    rate?: number;
  }[];
}

export interface IRoomPhoto {
  url: string;
  caption?: string;
}

/**
 * The response we get from the POST v2/rooms endpoint
 */
export interface IRoom {
  title: string;
  description?: string;
  photos?: IRoomPhoto[];
  amenities?: string[];
  beddings?: { type?: string; count?: number }[];
  rates: IRoomRate[];
  /** Used to track if the room is missing photos. Doesn't come from API response. */
  missingPhotos?: boolean;
}

export interface INightlyRate {
  readonly date: string;
  readonly displayRate: string;
  readonly gsaRate: number | null;
  readonly pastGsaRate: boolean | null;
  readonly rate: number;
}

export type TRoomRateModalType = "unavailable" | "rateIncrease";
