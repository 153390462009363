import type * as sdk from "@amplitude/experiment-js-client";
import type { QueryOptions } from "react-query";
import { useQuery } from "react-query";

import { useAmplitudeContext } from "@hotel-engine/hooks";
import type { IExperimentSDK } from "@hotel-engine/types/amplitude";

export const useExperiment = <T>(
  flagKey: string,
  fallbackVariant: T
): { data: T; isFetched: boolean } => {
  const { experimentSdk } = useAmplitudeContext();
  const { data, isFetched } = useAllExperimentsQuery(experimentSdk);
  return {
    data: data === undefined ? fallbackVariant : (data[flagKey] as T) ?? fallbackVariant,
    isFetched,
  };
};

export const useAllExperimentsQuery = (
  experimentSdk: IExperimentSDK,
  options: QueryOptions<sdk.Variants, Error, sdk.Variants> = {}
) => {
  const { client, profile, userId } = experimentSdk;

  // Not using ?? to handle cases where it's empty string
  const userIdCacheKey = profile?.user_id ? userId : "logged-out";

  return useQuery(
    ["amplitude/experiments", userIdCacheKey],
    () => client?.fetch().then((next) => next.all()) ?? {},
    {
      ...options,
      notifyOnChangeProps: ["data"],
      enabled: Boolean(client),
    }
  );
};
