import { useEffect, useState } from "react";

import moment from "moment";

import { useFormatDate } from "@hotel-engine/hooks/useFormatDate";
import config from "config";

import { formatPropertyLocation } from "../../../../utilities";
import * as Styled from "./styles";

export const PropertyInfo = ({ propertyInfo }) => {
  const {
    contractCheckIn,
    contractCheckOut,
    propertyCity,
    propertyCountry,
    propertyName,
    propertyState,
    propertyMainImageLinkUrl,
  } = propertyInfo;

  const [hasImageError, setHasImageError] = useState(false);
  const [propertyThumbnail, setPropertyThumbnail] = useState(propertyMainImageLinkUrl);

  const onError = () => {
    setPropertyThumbnail(`${config.cdnHost}/assets/no-property-photo.png`);
  };

  useEffect(() => {
    if (hasImageError || propertyMainImageLinkUrl === null) {
      onError();
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasImageError]);

  const checkIn = useFormatDate(contractCheckIn, "MMM D");
  const checkOut = useFormatDate(contractCheckOut, "MMM D");

  return (
    <Styled.PropertyInfoWrapper>
      <div
        className="property-image"
        style={{
          backgroundImage: `url(${propertyThumbnail})`,
        }}
      >
        {!!propertyMainImageLinkUrl && (
          <img
            id="property-thumbnail"
            src={propertyThumbnail}
            onError={() => setHasImageError(true)}
            alt={`Thumbnail preview of the ${propertyName} property`}
          />
        )}
      </div>
      <div className="property-details">
        <h1>{propertyName}</h1>
        <address>
          {formatPropertyLocation({
            propertyCity,
            propertyCountry,
            propertyState,
          })}
        </address>
        <div>
          <time dateTime={`${moment(contractCheckIn).format()}`}>{checkIn}</time> -{" "}
          <time dateTime={`${moment(contractCheckOut).format()}`}>{checkOut}</time>
        </div>
      </div>
    </Styled.PropertyInfoWrapper>
  );
};
