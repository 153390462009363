import styled, { css } from "styled-components";

import { Icon } from "@hotelengine/atlas-web/dist/components/Icon";
import { tokens } from "@hotelengine/atlas-web/dist/theme/tokens";
import { MenuItem } from "@hotel-engine/common/Menu";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { colors } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import { globalTheme } from "@hotel-engine/styles/themes/global.theme";
import PageLayout from "@hotel-engine/app/PageLayout";
import { PageLayoutContent } from "@hotel-engine/app/PageLayout/styles";

export const Settings = styled.div`
  .no-results {
    ${flexbox()}
    background-color: ${colors.chefsHat};
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
    margin-top: 40px;

    p {
      color: ${colors.kiwiGrey};
      flex: 1;
      vertical-align: middle;
      text-align: center;
      margin: 8em 0;
    }
  }

  table {
    border-collapse: separate;
  }

  .add-row-button {
    border: none;
    background-color: ${colors.white};
    font-size: ${globalTheme.legacy.fontSize.sm};
    cursor: pointer;
    color: ${colors.retroBlue};
    margin: 20px 0;

    span {
      margin-left: 4px;
    }

    &:disabled {
      cursor: not-allowed;
      color: ${colors.fadedRetroBlue};
    }
  }
`;

export const SettingsMain = styled.div`
  ${flexbox({ direction: "column" })}
  height: 100%;
  padding-top: 32px;
  width: 100%;
  max-width: 1120px; 
  margin: auto;
  margin-top: 0;
`;

export const SettingsTitle = styled.h2`
  text-transform: uppercase;
  ${typographyMixin("xsmall")}
  margin: 0 16px 24px;
`;

export const RowActions = styled.div`
  text-align: right;

  .row-actions-button {
    padding: 6px 10px;
  }
`;

export const RowActionsMenuItem = styled(MenuItem)`
  > span {
    margin: 8px;
  }

  &:hover {
    background-color: ${colors.chefsHat};
  }

  &:last-of-type {
    color: ${colors.khmerCurry};
  }
`;

export const DeleteModalHeader = styled.div`
  vertical-align: middle;
`;

export const DeleteModalIcon = styled(Icon)`
  color: ${tokens.default.colors.accentRed};
  margin: 0 10px;
`;

export const SettingsPage = styled(PageLayout)<{ $tab: string }>`
  ${PageLayoutContent} {
    width: 100%;
    ${({ $tab }) =>
      $tab === "guest-information" &&
      css`
        background-color: ${({ theme }) => theme.colors.backgroundSecondary};
    `};
  }`;
