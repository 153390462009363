import { Box, BottomSheet, BottomSheetContent, BottomSheetTrigger } from "@hotelengine/atlas-web";

import { NotificationInboxHeader } from "../NotificationInbox/NotificationInboxHeader";
import { NotificationInboxContent } from "../NotificationInbox/NotificationInboxContent";
import { useNotificationsTray } from "../useNotificationsTray";
import { useNotificationsInbox } from "../useNotificationsInbox";

import * as Styled from "../styles";

interface IMobileNotificationsTray {
  hasUnreadNotifications: boolean;
  setShowUnreadNotifications: (setUnread: boolean) => void;
}

export const MobileNotificationsTray = ({
  hasUnreadNotifications,
  setShowUnreadNotifications,
}: IMobileNotificationsTray) => {
  const { badgeText, trackNotificationsOpen, unreadMessageCount, rest } = useNotificationsTray({
    setShowUnreadNotifications,
    accountType: "personal",
  });

  const { isEmpty } = useNotificationsInbox({
    accountType: rest.accountType,
    fetchMessages: rest.fetchMessages,
    hasUnreadMessages: rest.hasUnreadMessages,
    messages: rest.messages,
  });

  return (
    <BottomSheet
      onIsOpenChange={trackNotificationsOpen}
      header={<NotificationInboxHeader isMobile hasMessages={unreadMessageCount > 0} {...rest} />}
    >
      <BottomSheetTrigger asChild>
        <Box position="relative">
          {!!hasUnreadNotifications && <Styled.Badge text={badgeText} />}
          <Styled.HeaderIconWrap variant="plain" icon="bell" />
        </Box>
      </BottomSheetTrigger>
      <BottomSheetContent>
        <NotificationInboxContent isEmpty={isEmpty} {...rest} />
      </BottomSheetContent>
    </BottomSheet>
  );
};
