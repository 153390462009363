import SkeletonLoader from "@hotel-engine/common/SkeletonLoader";
import { pxToRem } from "@hotel-engine/styles/helpers/mixins";

import styled from "styled-components";

export const ExpressBookHotelPreview = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: 1rem;
  align-items: center;
  padding: 16px;

  .express-hotel-preview-img {
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xl};
    overflow: hidden;
    width: 80px;
    height: 80px;
  }
`;

export const ExpressBookHotelTitle = styled.strong`
  font-weight: 600;
`;

export const ExpressBookHotelPreviewAddress = styled.span`
  color: ${({ theme }) => theme.colors.foregroundSecondary};

  font-size: ${pxToRem(12)};
  line-height: 150%;

  display: flex;
  flex-direction: column;
`;

export const ExpressBookHotelPreviewLoader = styled(SkeletonLoader).attrs(() => ({
  type: "bar",
  flexible: true,
}))`
  height: 20px;

  .ant-skeleton-paragraph > li {
    width: 80% !important;
    height: 15px !important;
    margin-top: 5px !important;
  }
`;
