import { Fn } from "@hotel-engine/data";
import type { IUser } from "@hotel-engine/types/user";
import { useAppSelector } from "store/hooks";

/**
 * ### {@link useUser `useUser`}
 *
 * #### Go directly to the current user. Do not handle NULL, do not collect $200.
 *
 * This hook is a drop-in replacement for:
 *
 * ```typescript
 * const user = useAppSelector((state) => state.Auth.user)
 * //    ^? const user: IUser | null
 * ```
 *
 * The difference when using {@link useUser `useUser`} is that you just get an
 * {@link IUser `IUser`} -- no more `IUser | null`:
 *
 * ```typescript
 * const user = useUser()
 * //    ^? const user: IUser
 * ```
 *
 * If you need to select a property of the `user` object, you can optionally
 * pass a selector function:
 *
 * ```typescript
 * const business = useUser((user) => user.business)
 * //    ^? const business: IBusiness
 * ```
 *
 * **Note:** This is safe in most contexts, since the `null` case has already been
 * handled by the router. {@link useUser `useUser`} should _not_ be used in the routing
 * layer, where the `null` case is a real possibility.
 */
export function useUser(): IUser;
export function useUser<T>(selector: (user: IUser) => T): T;
/// impl.
export function useUser<T>(selector: (user: IUser) => T = Fn.identity as never) {
  return useAppSelector((state) => {
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
    const user = state.Auth.user!;
    return user === null
      ? // log warning if user is null and in a dev environment
        (import.meta.env.DEV && void console.warn(USE_USER_WARNING)) || user
      : // otherwise call the selector with the currently logged in user:
        selector(user);
  });
}

/** @internal */
const USE_USER_WARNING = `
============
✋ WARNING:
============

    Unhandled case:
    \`user === null\`

Calling \`useUser\` near the root of the component tree is not recommended.
Move the call to \`useUser\` closer to the leaves of your component.

This message only appears when \`import.meta.env.DEV === true\`.
`.trim();
