import { Typography } from "@hotelengine/atlas-web";
import type { AuthenticatorKind, IAuthenticatorData } from "./authenticator";
import { AuthenticatorEditButton } from "./AuthenticatorEditButton";
import { isEmail, isPhone } from "./util";

// Auth0 returns phone numbers as XXXXXX1234 - this converts those Xs to dots
// and applies the correct <Typography> element to center them properly.
const formatAuthenticator = ({
  kind,
  value,
}: {
  kind: AuthenticatorKind;
  value: string;
}) => {
  if (isPhone(kind)) {
    const x = "X";
    const dot = "•";
    const number = value.replaceAll(x, dot);
    const splitIndex = number.lastIndexOf(dot) + 1;
    const dots = number.substring(0, splitIndex);
    const numbers = number.substring(splitIndex);
    return (
      <>
        <Typography variant="body/md" as="code" marginRight={2} marginBottom={2}>
          {dots}
        </Typography>
        <Typography variant="body/md" marginRight={12}>
          {numbers}
        </Typography>
      </>
    );
  } else {
    return <Typography variant="body/md">{value}</Typography>;
  }
};

const AuthenticatorInfo = ({
  authenticator,
  mfaEnabled,
  setHasErrors,
}: {
  authenticator: IAuthenticatorData;
  mfaEnabled: boolean;
  setHasErrors: (boolean) => void;
}) => {
  return (
    <>
      {authenticator.value ? (
        <>
          {formatAuthenticator({
            kind: authenticator.kind,
            value: authenticator.value,
          })}
        </>
      ) : (
        <Typography
          as="p"
          color="foregroundDisabled"
          marginBottom={0}
          variant="body/md"
          display="inline-block"
          marginRight={isEmail(authenticator.kind) ? 0 : 12}
        >
          Not configured
        </Typography>
      )}

      {mfaEnabled ? (
        <AuthenticatorEditButton
          kind={authenticator.kind}
          id={authenticator.id}
          hasValue={!!authenticator.value}
          setHasErrors={setHasErrors}
        />
      ) : null}
    </>
  );
};

export { AuthenticatorInfo };
