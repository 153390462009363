import type { UseQueryOptions } from "react-query";

import type { IError } from "@hotel-engine/types/errors";
import type { IPropertyLegacy } from "@hotel-engine/types/property";
import { endpoints } from "../constants";
import { useApi } from "../useApi";
import { useExtendedQuery } from "../useExtendedQuery";

/**
 * Query to fetch the property information
 *
 * @params 'id' - the id of the property
 *
 * @returns {@link IPropertyLegacy Property}
 */
export const usePropertyQuery = (
  id: number,
  options?: UseQueryOptions<IPropertyLegacy, IError>
) => {
  const get = useApi("get");

  return useExtendedQuery<IPropertyLegacy, IError>(
    [endpoints.properties, id],
    () => get<IPropertyLegacy>(`${endpoints.properties}/${id}`),
    options
  );
};
