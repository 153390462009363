import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from "react-query";
import { useQuery as useBaseQuery } from "react-query";

type UseQueryResultExtended<TData, TError> = UseQueryResult<TData, TError> & {
  meta?: {
    active?: number;
    availableCredits?: string;
    bookingConfirmed?: number;
    cancelled?: number;
    clientSelection?: number;
    completed: number;
    contracting?: number;
    draft: number;
    expiringSoon?: string;
    limit: number;
    maxInvitedReferralsMet?: boolean;
    offset: number;
    open: number;
    past?: number;
    qualification?: number;
    sourcing?: number;
    status?: string;
    total: number;
    upcoming?: number;
    totalActive?: number;
    totalClosed?: number;
    totalAll?: number;
    useAuth0?: boolean;
  };
};

/**
 * Customizes the basic useQuery hook exposed by react-query.
 *
 * Modifications:
 * - 1.) If response payload has a "meta" key, it attaches that object to
 *   the useQuery result object under the same key name
 *
 * - 2.) If response payload has a "data" key, it flattens it so we don't
 *   have to access the response as data.data
 * */
function useExtendedQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
): UseQueryResultExtended<TData, TError> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const query: any = useBaseQuery<TQueryFnData, TError, TData, TQueryKey>(
    queryKey,
    queryFn,
    options
  );

  if (query.status === "success") {
    // 1.) Inject meta key from response payload to query object
    if (query.data?.meta) {
      query.meta = query.data.meta;
    }

    // 2.) Flatten data key
    if (query.data?.data) {
      query.data = query.data.data;
    }
  }

  return query;
}

export { useExtendedQuery };
