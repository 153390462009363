/*
 eslint-disable
 @typescript-eslint/naming-convention,
 @typescript-eslint/no-empty-interface,
 @typescript-eslint/no-explicit-any,
 @typescript-eslint/no-namespace,
 @typescript-eslint/no-shadow,
 */

import { Fn } from "../function/exports"
import { Option } from "../option/exports"

export {
  type Err,
  type Ok,
  type Result,
  type InferErr as inferErr,
  type InferOk as inferOk,
  getOrElse,
  err,
  fromOption,
  fromPredicate,
  isErr,
  isOk,
  isResult,
  map,
  match,
  ok,
  unsafeExpect,
  unsafeUnwrap,
}

/** @category model */
type Result<T, E> = Ok<T> | Err<E>

/** @category model */
interface Ok<T> extends Local.Ok<T> { }

/** @category model */
interface Err<E> extends Local.Err<E> { }

/** @category inference */
type InferOk<F extends Result<any, any>> = [F] extends [Result<infer T, any>] ? T : never

/** @category inference */
type InferErr<F extends Result<any, any>> = [F] extends [Result<any, infer E>] ? E : never

namespace Local {
  /** @internal */
  export type Ok<T> = ReturnType<typeof ok<T>>
  /** @internal */
  export type Err<E> = ReturnType<typeof err<E>>
  /** @internal */
  export type FromPredicateCurriedArgs<T, E> = readonly [f: Fn.Predicate<T>, onErr: (t: T) => E]
  /** @internal */
  export type FromPredicateUncurriedArgs<T, E> = readonly [value: T, f: Fn.Predicate<T>, onErr: (t: T) => E]
  /** @internal */
  export type FromPredicateArgs<T, E> =
    | FromPredicateCurriedArgs<T, E>
    | FromPredicateUncurriedArgs<T, E>
    ;
  /** @internal */
  export const isUncurried
    : <T, E>(args: Local.FromPredicateArgs<T, E>) => args is FromPredicateUncurriedArgs<T, E>
    = (args): args is never => args.length === 3
  /** @internal */
  export const isCurried
    : <T, E>(args: Local.FromPredicateArgs<T, E>) => args is FromPredicateCurriedArgs<T, E>
    = (args): args is never => args.length === 2
    ;
  /** @internal */
  export const Tag = {
    Ok: "Data::Result::Ok",
    Err: "Data::Result::Err",
  } as const
  /** @internal */
  export const ok = <T>(ok: T) => ({ tag: Local.Tag.Ok, ok } as const)
  /** @internal */
  export const err = <E>(err: E) => ({ tag: Local.Tag.Err, err } as const)
  export const die
    : (x: unknown) => never
    = (x) => { throw x }
}

/** @external */
const ok
  : <T, E = never>(t: T) => Result<T, E>
  = Local.ok
/** @external */
const err
  : <E, T = unknown>(e: E) => Result<T, E>
  = Local.err

/** @external */
const isOk = <T>(u: unknown): u is Ok<T> => (u as { tag: string }).tag === Local.Tag.Ok
/** @external */
const isErr = <E>(u: unknown): u is Err<E> => (u as { tag: string }).tag === Local.Tag.Err
/** @external */
const isResult = <T, E>(u: unknown): u is Result<T, E> => isOk(u) || isErr(u)

/** @external */
const map
  : <T, U>(f: (t: T) => U) => <E>(result: Result<T, E>) => Result<U, E>
  = (fn) => (result) => isOk(result) ? ok(fn(result.ok)) : result

const fromOption
  : <E>(onNone: () => E) => <T>(option: Option.Option<T>) => Result<T, E>
  = (onNone) => (option) => Option.isNone(option) ? err(onNone()) : ok(option.value)

/** @external */
function fromPredicate<T, U, E>(g: Fn.TypePredicate<[T, U]>, onErr: (t: T) => E): (value: T) => Result<U, E>
function fromPredicate<T, U, E>(value: T, f: Fn.TypePredicate<[T, U]>, onErr: (t: T) => E): Result<U, E>
function fromPredicate<T, E>(f: Fn.Predicate<T>, onErr: (t: T) => E): (value: T) => Result<T, E>
function fromPredicate<T, E>(value: T, f: Fn.Predicate<T>, onErr: (t: T) => E): Result<T, E>
function fromPredicate<T, E>(...args: Local.FromPredicateArgs<T, E>): unknown {
  if (Local.isUncurried(args)) {
    const [value, f, onErr] = args
    return f(value) ? ok(value) : err(onErr(value))
  }
  else if (Local.isCurried(args)) {
    const [f, onErr] = args
    return (value: T) => f(value) ? ok(value) : err(onErr(value))
  }
  else return Fn.exhaustive(`fromPredicate`, args)
}

/** @external */
const unsafeUnwrap
  : <T, E>(result: Result<T, E>) => T
  = (result) => isOk(result) ? result.ok : Local.die(result.err)

/** @external */
const unsafeExpect
  : <E>(onErr: string | ((e: E) => string)) => <T>(result: Result<T, E>) => T
  = (onErr) =>
    (result) =>
      isOk(result)
        ? result.ok
        : Local.die(typeof onErr === "function" ? onErr(result.err) : onErr)
  ;

const match
  : <T, E, O1, O2>(onOk: (t: T) => O1, onErr: (e: E) => O2) => (result: Result<T, E>) => O1 | O2
  = (onOk, onErr) => (result) => isOk(result) ? onOk(result.ok) : onErr(result.err)

const getOrElse
  : <E, O>(onErr: (e: E) => O) => <T>(result: Result<T, E>) => T | O
  = (onErr) => match(Fn.identity, onErr)
