import { useAircraftInfo } from "./useAircraftInfo";
import { useAirlineInfo } from "./useAirlineInfo";
import { useAirportInfo } from "./useAirportInfo";
import { useCityInfo } from "./useCityInfo";

export function useResourceInfo() {
  const { getAirlineNameFromIataAirlineCode } = useAirlineInfo();
  const { getAircraftNameByIataAircraftKey } = useAircraftInfo();
  const {
    getAirportNameFromIataAirportCode,
    getIataCityCodeByIataAirportCode,
    getIataCountryCodeByIataAirportCode,
  } = useAirportInfo();
  const { getCityNameByIataCityCode, getIataCountryCodeByIataCityCode } = useCityInfo();

  function getCityNameByIataAirportCode(iataAirportCode: string) {
    const iataCityCode = getIataCityCodeByIataAirportCode(iataAirportCode);
    return getCityNameByIataCityCode(iataCityCode);
  }

  return {
    getAirlineNameFromIataAirlineCode,
    getAircraftNameByIataAircraftKey,
    getAirportNameFromIataAirportCode,
    getIataCityCodeByIataAirportCode,
    getIataCountryCodeByIataAirportCode,
    getCityNameByIataCityCode,
    getIataCountryCodeByIataCityCode,
    getCityNameByIataAirportCode,
  };
}
