import { useMutation } from "react-query";

import { useAppDispatch } from "store/hooks";
import { endpoints } from "@hotel-engine/react-query/constants";
import { convertToSnake, useApi } from "@hotel-engine/react-query/useApi";
import { User } from "@hotel-engine/services";
import type { IDirectBillOnboardingParams } from "@hotel-engine/types/directBillOnboarding";
import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { IUser } from "@hotel-engine/types/user";
import { AuthActions } from "store/Auth/AuthRedux";

export const useUpdateDirectBillOnboardingMethod = () => {
  const put = useApi("put");
  const dispatch = useAppDispatch();

  return useMutation<null, IErrorResponse, IDirectBillOnboardingParams>(
    (params) => put(`${endpoints.directBillOnboardings}`, convertToSnake(params)),
    {
      onSuccess: async () => {
        const user: IUser = await User.getSelf();
        dispatch(AuthActions.setUser(user));
      },
    }
  );
};
