import { Popover as antdPopover } from "antd";
import styled from "styled-components";

/**
 * The `Popover` component wraps antd's `<Popover />` component.
 *
 * @remarks Since we are leveraging antd for most components, our internal Popover component does not have any of its own props. Instead, we are directly referring to the interface definition antd is expecting. This allows us to easily be in alignment with their type definitions and prevents type errors that may arise from maintaining an override interface.
 *
 * @see {@link https://3x.ant.design/components/popover/ AntD Popover Documentation}
 * @see {@link https://www.figma.com/file/GVLYN60OBX188CID3YvWpSo6/Components---WEB?node-id=4387%3A34332 Design Specs}
 */
export const Popover = styled(antdPopover)``;
