/* eslint-disable
 @typescript-eslint/no-explicit-any,
 @typescript-eslint/naming-convention,
 */
import type { Any } from "../any/exports"
import { Fn } from "../function/exports"

export {
  type Behead,
  type Beheaded,
  type Dequeue,
  type Head,
  type Init,
  type Join,
  type Last,
  type Tail,
  behead,
  dequeue,
  head,
  init,
  join,
  last,
  map,
  tail,
}

function map<A, B>(fn: (a: A) => B): (xs: Any.Array<A>) => Any.Array<B>
function map<A, B>(xs: Any.Array<A>, fn: (a: A) => B): Any.Array<B>
function map<A, B>(
  ...[$1, $2 = Fn.UnusedParameter as never]:
    | [fn: (a: A) => B]
    | [xs: Any.Array<A>, fn: (a: A) => B]
): unknown {
  if (Fn.isUnusedParameter($2))
    return (xs: Any.Array<A>) => map(xs, $1 as never)
  else if (Array.isArray($1) && typeof $2 === "function") return $1.map($2)
  else return Fn.exhaustive(`List.map`, [$1, $2] as never)
}

type Beheaded<H, T extends Any.Array> = readonly [head: H, tail: T]

/**
 * Get only the first element of a list.
 *
 * - Complements {@link Tail}
 * - Dual of {@link Last}
 * - Type-level equivalent of {@link head}
 */
type Head<TS extends Any.Array>
  = [TS] extends [readonly [infer H, ...any]] ? (H | never) : never

/**
 * Get only the first element of a list.
 *
 * - Complements {@link tail}
 * - Dual of {@link last}
 * - Term-level equivalent of {@link Head}
 */
const head
  : <List extends Any.Array>(xs: List) => Head<List>
  = (xs) => Fn.absorb(xs[0])

/**
 * Get all but the first element of a list.
 *
 * - Complements {@link Head}
 * - Dual of {@link Init}
 * - Type-level equivalent of {@link tail}
 */
type Tail<TS extends Any.Array>
  = [TS] extends [readonly [any, ...infer T]] ? (T | never) : readonly []

/**
 * Get all but the first element of a list.
 *
 * - Complements {@link head}
 * - Dual of {@link init}
 * - Term-level equivalent of {@link Tail}
 */
const tail
  : <List extends Any.Array>(xs: List) => Tail<List>
  = (xs) => Fn.absorb(xs.slice(1))

/**
 * Get all but the first element of a list.
 *
 * - Complements {@link Last}
 * - Dual of {@link Tail}
 * - Type-level equivalent of {@link init}
 */
type Init<List extends Any.Array>
  = List extends readonly [...infer I, any] ? I : readonly []

/**
 * Get all but the lasts element of a list.
 *
 * - Complements {@link last}
 * - Dual of {@link tail}
 * - Term-level equivalent of {@link Init}
 */
const init
  : <List extends Any.Array>(xs: List) => Init<List>
  = (xs) => Fn.absorb(xs.slice(0, -1))

/**
 * Get only the last element of a list.
 *
 * - Complements {@link Init}
 * - Dual of {@link Head}
 * - Type-level equivalent of {@link last}
 */
type Last<List extends Any.Array>
  = List extends readonly [...any, infer L] ? (L | never) : never

/**
 * Get only the last element of a list.
 *
 * - Complements {@link init}
 * - Dual of {@link head}
 * - Term-level equivalent of {@link Last}
 */
const last
  : <List extends Any.Array>(xs: List) => Last<List>
  = (xs) => Fn.absorb(xs[xs["length"] - 1])

/**
 * Separate the first element from the rest of a list.
 *
 * - Performs the functionality of {@link Head} and {@link Tail} in a single step
 * - Dual of {@link Dequeue}
 * - Type-level equivalent of {@link behead}
 */
type Behead<List extends Any.Array> = readonly [head: Head<List>, tail: Tail<List>]

/**
 * Separate the first element from the rest of a list.
 *
 * - Performs the functionality of {@link head} and {@link tail} in a single step
 * - Dual of {@link dequeue}
 * - Term-level equivalent of {@link Behead}
 */
const behead
  : <List extends Any.Array>(xs: List) => Behead<List>
  = (xs) => [head(xs), tail(xs)]

/**
 * Separate the last element from the rest of a list.
 *
 * - Performs the functionality of {@link Init} and {@link Last} in a single step
 * - Dual of {@link Behead}
 * - Type-level equivalent of {@link dequeue}
 */
type Dequeue<List extends Any.Array>
  = List extends readonly [...infer I, infer L] ? ([init: I, last: L] | never) : never

/**
 * Separate the last element from the rest of a list.
 *
 * - Performs the functionality of {@link init} and {@link last} in a single step
 * - Dual of {@link behead}
 * - Term-level equivalent of {@link Dequeue}
 */
const dequeue
  : <List extends Any.Array>(xs: List) => Dequeue<List>
  = (xs) => Fn.absorb([init(xs), last(xs)])

type Join<Segments extends Any.Array<Any.Showable>, Delimiter extends Any.Showable>
  = Join_<"", Segments, Delimiter>

type Join_<
  Acc extends string,
  Segments extends Any.Array<Any.Showable>,
  Delimiter extends Any.Showable
  >
  = Segments extends readonly [] ? Acc
  : Segments extends
  | readonly [Any.Showable<infer Hd>, ...Any.ListOf<Any.Showable, infer Tl>]
  ? Join_<`${Acc}${Acc extends `` ? `` : Delimiter}${Hd}`, Tl, Delimiter>
  : never
  ;

const join
  : <Delimiter extends Any.Showable>(delimiter: Delimiter) => <Segments extends Any.Array<Any.Showable>>(segments: readonly [...Segments]) => Join<Segments, Delimiter>
  = (delimiter) => (segments) => Fn.absorb(segments.map(String).join(`${delimiter}`))
  ;
