import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import type { ISupportCaseDrawer } from "@hotel-engine/types/supportCase";

export const initialState: ISupportCaseDrawer = {
  caseId: undefined,
  showDrawer: false,
};

export const supportCaseDrawerSlice = createSlice({
  name: "SupportCaseDrawer",
  initialState,
  reducers: {
    setSupportCaseDrawerState: (state, action: PayloadAction<ISupportCaseDrawer>) => ({
      ...state,
      caseId: action.payload.caseId,
      showDrawer: action.payload.showDrawer,
    }),
  },
});

export const SupportCaseDrawerActions = supportCaseDrawerSlice.actions;

export default supportCaseDrawerSlice.reducer;
