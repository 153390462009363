import { createSlice } from "@reduxjs/toolkit";
import type { IRoomOffersState } from "./types";
import { ROOM_RATE_TIMEOUT } from "pages/Property/components/ChooseARoom/helpers";

export const initialState: IRoomOffersState = {};

export const roomOffersSlice = createSlice({
  name: "RoomOffers",
  initialState,
  reducers: {
    setRoomOffersContinuationToken: (
      state,
      { payload: { propertyId, sessionId, roomOffersContinuationToken } }
    ) => {
      // cleanup expired sesssions
      Object.keys(state).forEach((existingPropertyId) => {
        // check each session id to see if it has expired
        const sessions = Object.keys(state[existingPropertyId]);
        sessions.forEach((existingSessionId) => {
          if (state[existingPropertyId][existingSessionId].expiresAt < Date.now()) {
            // if expired, remove it from the state
            delete state[existingPropertyId][existingSessionId];
            console.log(
              `Session ${existingSessionId} for property ${existingPropertyId} has expired and has been removed from state`
            );
          }
        });

        // if property contains no sessions, remove it from the state
        if (!Object.keys(state[existingPropertyId]).length) {
          delete state[existingPropertyId];
          console.log(
            `Property ${existingPropertyId} has no active sessions and has been removed from state`
          );
        }
      });

      state[propertyId] = {
        ...state[propertyId],
        [sessionId]: {
          expiresAt: Date.now() + ROOM_RATE_TIMEOUT,
          roomOffersContinuationToken,
        },
      };
    },
    resetState: () => initialState,
  },
});

export const roomOffersActions = roomOffersSlice.actions;

export default roomOffersSlice.reducer;
