import { colors, zIndex } from "../index";
import { tokens } from "@hotelengine/atlas-web/dist/theme/tokens";

/**
 * @deprecated Do not access the globalTheme directly. Utilize the `useGlobalTheme` hook or if you're using styled components
 * access the current theme off the props
 *
 * @example
 * const { tokens } = useGlobalTheme();
 *
 * @example
 * opacity: ${({ theme }) => theme.opacity["40"]};
 *
 */
const globalTheme = {
  /**
   * @deprecated The legacy theme object is deprecated in favor of the new atlas tokens (at the root of this object)
   */
  legacy: {
    elevation: {
      0: "none", // Use for: Flat Object (to reset or override elevation/box shadow need be)
      1: `0px 1px 2px 1px ${colors.lightShadow}, 0px 1px 1px ${colors.darkShadow}`, // Use for: Panels, Cards Subtle
      2: `0px 2px 6px 1px ${colors.lightShadow}, 0px 1px 1px ${colors.darkShadow}`, // Use for: Panels, Navigation Bars, Cards Bold
      3: `0px 6px 12px 1px ${colors.lightShadow}, 0px 1px 2px ${colors.darkShadow}`, // Use for: Menus, Date Picker
      4: `0px 8px 24px 1px ${colors.lightShadow}, 0px 1px 4px ${colors.darkShadow}`, // Use for: Modals
    },
    fontFamily: {
      primary:
        "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    },
    fontSize: {
      xs: "12px",
      sm: "14px",
      md: "16px",
      lg: "20px",
      xl: "24px",
      xxl: "32px",
      xxxl: "40px",
    },
    fontWeight: {
      thin: "100", // Thin (Hairline)
      extraLight: "200", // Extra Light (Ultra Light)
      light: "300", // Light
      normal: "400", // Normal (Regular)
      medium: "500", // Medium
      semiBold: "600", // Semi Bold (Demi Bold)
      bold: "700", // Bold
      extraBold: "800", // Extra Bold (Ultra Bold)
      black: "900", // Black (Heavy)
      extraBlack: "950", // Extra Black (Ultra Black)
    },
    lineHeight: {
      xs: "16px",
      sm: "20px",
      md: "24px",
      lg: "28px",
      xl: "32px",
      xxl: "40px",
      xxxl: "48px",
    },
    colors,
    shape: {
      borderRadius: {
        xs: "2px",
        sm: "4px",
        md: "6px",
        lg: "8px", // Use for: Button
        xl: "10px", // Use for: Containers, Advanced Button
        xxl: "16px",
        x100: "100%", // Use for circles
      },
    },
    zIndex: {
      belowBackdrop: zIndex.backdrop + zIndex.below,
      backdrop: zIndex.backdrop,
      aboveBackdrop: zIndex.backdrop + zIndex.above,
      sticky: zIndex.sticky,
      belowModal: zIndex.modal + zIndex.below,
      modal: zIndex.modal,
      aboveModal: zIndex.modal + zIndex.above,
    },
    space: (n = 1) => `${n * 8}px`,
  },
  ...tokens.default,
} as const;

export { globalTheme };

type AppTheme = typeof globalTheme;

declare module "styled-components" {
  // eslint-disable-next-line
  export interface DefaultTheme extends AppTheme {}
}
