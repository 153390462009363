import { useDebounce, usePrevious } from "@hotel-engine/hooks";
import { useEffect, useMemo, useState } from "react";

import { GoogleAutoCompleteService } from "@hotel-engine/services";
type AutocompletePrediction = google.maps.places.AutocompletionRequest;

const MIN_CHARACTERS_TO_SEARCH = 3;
export const useGoogleAutoCompleteResults = (
  searchInput: string,
  config?: Partial<AutocompletePrediction>
) => {
  const autoCompleteService = useMemo(() => GoogleAutoCompleteService(), []);

  const [{ predictions }, setAutocompleteState] = useState<google.maps.places.AutocompleteResponse>(
    {
      predictions: [],
    }
  );

  useEffect(() => {
    const subscription = autoCompleteService.register(mergeState);
    return () => {
      subscription.unregister();
    };
  }, [autoCompleteService]);

  const mergeState = (incoming: google.maps.places.AutocompleteResponse) => {
    setAutocompleteState((prev) => {
      return {
        ...prev,
        ...incoming,
      };
    });
  };

  /** debouncedSearchInput
   * Update search input on 500 ms delay
   * Minimize number of calls made to Google's api
   */
  const debouncedSearchInput = useDebounce(searchInput, 500);

  /** previousDebouncedSearchInput
   * Keep previous version of input for comparisons
   */
  const previousDebouncedSearchInput = usePrevious(debouncedSearchInput);

  const isSearchEnabled = searchInput.length >= MIN_CHARACTERS_TO_SEARCH;
  const isSearchEmpty = searchInput.length === 0;
  /** setIsAutocompleteLoading
   * Add autocomplete loading state setting method here and pass it to service
   * since google does not provide us a loading status
   */
  const [isAutocompleteLoading, setIsAutocompleteLoading] = useState(false);

  /** Compare input text for updates and make request to google with new term */
  useEffect(() => {
    if (previousDebouncedSearchInput !== debouncedSearchInput) {
      autoCompleteService.request(debouncedSearchInput, setIsAutocompleteLoading, config);
    }
  }, [debouncedSearchInput, previousDebouncedSearchInput, config, autoCompleteService]);
  /** autoCompleteDataState
   * if the input is empty or search is enabled do not return idle
   * if search is enabled check array length to determine display
   * if the search is not empty but not yet enabled return idle
   */
  const autoCompleteDataState = (() => {
    const isDirty = !isSearchEmpty && !isSearchEnabled;
    const hasSearchResults = !!predictions?.length;
    if (isSearchEmpty || isSearchEnabled || isDirty || !isAutocompleteLoading) {
      if (isSearchEnabled || (isDirty && hasSearchResults)) {
        return hasSearchResults ? "show-locations" : "no-results";
      }
    }
    if (isSearchEmpty) {
      return "show-cold-state";
    }
    return "idle";
  })();

  return {
    predictions,
    isAutocompleteLoading,
    autoCompleteDataState,
  };
};
