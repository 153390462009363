/* eslint-disable
 @typescript-eslint/naming-convention,
 @typescript-eslint/no-empty-interface,
 */
import type { ICurrency } from "@hotel-engine/constants/currencyDictionary";
import { supportNumberDictionary } from "@hotel-engine/constants/currencyDictionary";
import { Fn } from "@hotel-engine/data";
import { select } from "@hotel-engine/utilities/config";

const apiOrigin = import.meta.env.VITE_HE_API_HOST_V2?.replace("/api/v2", "");

const withoutHosts: <Config extends WithoutHosts>(config: Config) => Config = Fn.identity;

const withHosts: <Config extends IConfig>(config: Config) => Config = Fn.identity;

const config = withoutHosts({
  amplitudeApiKey: import.meta.env.VITE_AMPLITUDE_API_KEY,
  ampliExperimentKey: import.meta.env.VITE_AMPLI_EXPERIMENT_API_KEY ?? "",
  ampliExperimentUrl: import.meta.env.VITE_AMP_EXPERIMENT_URL ?? "",
  ampliAnalyticsUrl: import.meta.env.VITE_AMP_ANALYTICS_URL ?? "",
  cdnHost: import.meta.env.VITE_CDN_HOST || "",
  courierClientKey: import.meta.env.VITE_COURIER_CLIENT_KEY,
  datadogClientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  defaultErrorMessage:
    "We're sorry, there was an error submitting your request. Please try again later.",
  enableChallenge: import.meta.env.VITE_ENABLE_CHALLENGE === "true",
  retryOnChallenge: import.meta.env.VITE_RETRY_ON_CHALLENGE === "true",
  fssHost: import.meta.env.VITE_FLIGHTS_HOST,
  cssHost: import.meta.env.VITE_CSS_HOST,
  googleAutocompleteApiKey: import.meta.env.VITE_GOOGLE_AUTOCOMPLETE_API_KEY,
  googleMapId: import.meta.env.VITE_GOOGLE_MAP_STYLE_ID,
  gtmId: import.meta.env.VITE_GTM_ID,
  heApi: apiOrigin,
  hotelCount: "700,000",
  logRocketRootDomain: import.meta.env.VITE_LOGROCKET_ROOT_DOMAIN,
  loyaltyEligibleEnabled: import.meta.env.VITE_LOYALTY_ELIGIBLE_ENABLED,
  lssHost: import.meta.env.VITE_LODGING_SEARCH_SERVICE_HOST,
  lssWebsocketHost: import.meta.env.VITE_LODGING_SEARCH_SERVICE_WS_HOST,
  marketingHost: "https://www.hotelengine.com",
  maxNights: 90,
  maxSearchRange: 500,
  minGroupBookingRooms: 9,
  minPointsRequiredToApply: 12500,
  pointValue: 0.002,
  release: import.meta.env.VITE_RELEASE,
  salesNumber: "720-409-3979",
  searchWebsocketHost: import.meta.env.VITE_SEARCH_WEBSOCKET_HOST,
  sentryClientDsn: import.meta.env.VITE_SENTRY_DSN,
  spreedlyKey: import.meta.env.VITE_SPREEDLY_KEY,
  stage: import.meta.env.VITE_STAGE,
  supplyRepoHost: import.meta.env.VITE_SUPPLY_REPO_HOST,
  supportNumber: (currencyCode: ICurrency) => supportNumberDictionary[currencyCode],
  useLegacyScriptLoader: false,
  useOneTrust: import.meta.env.VITE_USE_ONETRUST,
  auth0Domain: import.meta.env.VITE_AUTH0_DOMAIN,
  auth0ClientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  auth0Audience: import.meta.env.VITE_AUTH0_AUDIENCE,
  auth0Tenant: import.meta.env.VITE_AUTH0_TENANT,
  engineContentCdn: import.meta.env.VITE_ENGINE_CONTENT_CDN,
  pendoClientKey: "e76cce8f-d1b0-4123-6fd1-50ae23a864a5",
  turnstileSitekey: globalThis.Cypress
    ? import.meta.env.VITE_CLOUDFLARE_TURNSTILE_DUMMY_SITEKEY
    : import.meta.env.VITE_CLOUDFLARE_TURNSTILE_SITEKEY,
});

const addHosts = (current: WithoutHosts): ReadonlyConfig => {
  const { heApi, useOneTrust, fssHost, cssHost } = current;

  return withHosts({
    ...current,
    apiHost: heApi + "/api/v1",
    apiHostV2: heApi + "/api/v2",
    ssoHost: heApi,
    fssHost: fssHost + "/api/v1",
    cssHost: cssHost + "/api/v1",
    useLegacyScriptLoader: useOneTrust !== "true",
  });
};

export default addHosts(select(config));

/**
 * Used to determine original unmodified values.  You should not use these values in runtime, instead use the default export above.
 */
export const baseConfig = addHosts(config);

interface ReadonlyConfig extends Readonly<IConfig> {}

export interface IConfig extends WithoutHosts {
  apiHost: string;
  apiHostV2: string;
  ssoHost: string;
}

export interface WithoutHosts {
  amplitudeApiKey: string;
  ampliExperimentKey: string;
  ampliExperimentUrl: string;
  ampliAnalyticsUrl: string;
  cdnHost: string;
  courierClientKey: string;
  datadogClientToken: string;
  defaultErrorMessage: string;
  enableChallenge: boolean;
  retryOnChallenge: boolean;
  fssHost: string;
  cssHost: string;
  googleAutocompleteApiKey: string;
  googleMapId: string;
  gtmId: string;
  heApi: string;
  hotelCount: string;
  logRocketRootDomain: string | undefined;
  loyaltyEligibleEnabled?: string;
  lssHost: string;
  lssWebsocketHost: string;
  marketingHost: string;
  maxNights: number;
  maxSearchRange: number;
  minGroupBookingRooms: number;
  minPointsRequiredToApply: number;
  pointValue: number;
  release: string;
  salesNumber: string;
  searchWebsocketHost: string;
  sentryClientDsn: string;
  spreedlyKey: string;
  stage: "dev" | "preview" | "staging" | "production" | "test";
  supplyRepoHost: string;
  supportNumber: (currencyCode: ICurrency) => string;
  userTTL?: number;
  useLegacyScriptLoader?: boolean;
  useOneTrust: string;
  auth0Domain: string;
  auth0ClientId: string;
  auth0Audience: string;
  auth0Tenant: string;
  engineContentCdn: string;
  pendoClientKey: string;
  turnstileSitekey: string;
}
