import type { UseQueryOptions } from "react-query";

import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { ISurvey } from "@hotel-engine/types/survey";

import { endpoints } from "../constants";
import { useApi } from "../useApi";
import { useExtendedQuery } from "../useExtendedQuery";

export const useSurveyQuery = (
  params: {
    name: string;
  },
  options?: UseQueryOptions<ISurvey, IErrorResponse>
) => {
  const get = useApi("get");

  return useExtendedQuery(
    [endpoints.surveys, params.name],
    () => get<ISurvey>(`${endpoints.surveys}/${params.name}`),
    options
  );
};
