import styled from "styled-components";

export const CopyWrapper = styled.div`
  padding-left: 8px;
  white-space: nowrap;
  display: flex;
  flex-direction: column;

  span:first-child {
    margin-bottom: -4px;
  }
`;
