import { useParams } from "@hotel-engine/lib/react-router-dom";

import { useSearchParams } from "@hotel-engine/hooks/useSearchParams";

interface IParams {
  [key: string]: string | number | undefined;
}

export interface ISearchAndRoomRateID {
  s: string;
  roomRateId: string;
}

export interface ISearchAndPropertyID {
  s: string;
  propertyId: string;
}

/**
 * The `useURLParams` hook combines react router's useParams with hotel engine's custom useSearchParams hook. This allows for more information to be utilized with a single import. This is especially helpful for sharing similar bits of data across the app for our analytics platform Amplitude.
 * The hook requires a specific type for the useSearchParams hook while useParams returns an object with various value types.
 */

export const useURLParams = <T>() => {
  const searchParams: T = useSearchParams();
  const params: IParams = useParams();
  return {
    search: { ...searchParams },
    params: { ...params },
  };
};
