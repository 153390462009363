import styled, { createGlobalStyle, css } from "styled-components";

import { List } from "@hotel-engine/common/Lists";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { colors, ellipsisText } from "@hotel-engine/styles";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import { Icon } from "@hotelengine/atlas-web";

const bullet = css`
  &:after {
    content: "•";
    display: inline-block;
    padding: 0 10px;
    color: #c4c4c4;
  }
`;

export const OrangeBull = styled.div`
  color: ${colors.carrotCurl};
  font-weight: bold;
  margin-right: 8px;
`;

export const Left = styled.div`
  margin-right: 16px;
`;

export const Right = styled.div`
  overflow: hidden;
`;

export const PropertyName = styled.div`
  ${ellipsisText};
  margin-right: 6px;
  ${typographyMixin("small")};
  font-weight: 500;
  margin-bottom: 0;

  ${OrangeBull} {
    display: inline;
  }
`;

export const Location = styled.div`
  ${ellipsisText};
  ${mediaQuery("lg")(`
      display: inline;
      ${bullet};
    `)}
`;

export const Dates = styled.div`
  ${bullet};
  min-width: fit-content;
`;

export const Total = styled.div`
  display: none;

  ${mediaQuery("lg")(`
      display: inline;
    `)}
`;

export const Info = styled.div`
  display: block;

  ${mediaQuery("lg")(`
      display: inline;
    `)}
`;

export const CheckoutInfo = styled.span`
  ${flexbox()}
  ${mediaQuery("lg")(`
    display: inline-flex;
    `)}
`;

export const CheckoutVerification = styled.div<{ $isSuccess?: boolean }>`
  ${ellipsisText};

  ${typographyMixin("body-medium")};

  margin-right: 6px;
  color: ${({ $isSuccess }) => $isSuccess && colors.green[600]};
  ${mediaQuery("lg")(`
    ${bullet};
    `)}
`;

export const GlobalListItemStyles = createGlobalStyle`
  .ant-skeleton-header {
    padding-left: 8px;
    padding-top: 8px;
  }

  .ant-skeleton-content {
    padding-right: 8px;
  }
`;

export const Chevron = styled(Icon)`
  margin-left: auto;
  margin-right: 6px;
  min-width: 6px;
`;

export const TripsListItem = styled(List.Item)<{
  selected: boolean;
  $viewIsMap: boolean;
  $pendingMod: boolean;
}>`
  ${flexbox({
    wrap: "nowrap",
    alignItems: "center",
    justifyContent: "flex-start",
  })}
  background-color: ${({ selected }) => selected && colors.blue[50]};
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
  margin: 10px 0;
  padding: 0;
  overflow: hidden;
  cursor: pointer;

  &.ant-list-item {
    border: 1px solid
      ${({ selected }) => (selected ? colors.retroBlue : colors.grey[200])};

    ${({ $pendingMod }) =>
      $pendingMod &&
      css`
        background-color: ${colors.whatsLeft};
        border: 1px solid ${colors.carrotCurl};
      `};

    &:last-child,
    .ant-list-split & {
      border-bottom: 1px solid
        ${({ selected, $pendingMod }) =>
          $pendingMod ? colors.carrotCurl : selected ? colors.retroBlue : colors.grey[200]};
    }
  }

  ${({ $viewIsMap }) =>
    $viewIsMap &&
    css`
      &:first-child {
        ${mediaQuery("lg")(`
          margin-top: 0
        `)}
      }

      ${Location} {
        ${mediaQuery("lg")(`
          display: block;
          &:after {
            display: none;
          }
        `)}
      }

      ${CheckoutVerification} {
        &:after {
          display: none;
        }
      }
    `};
`;
