import moment from "moment-timezone";

import type { Reservation } from "@hotel-engine/services";
import type { IReservationBase } from "@hotel-engine/types/reservation";

const isTripCancellable = (trip: IReservationBase | Reservation | null) => {
  if (!trip) return false;
  // trip is not in a finalized status, cannot cancel
  if (trip.status === "completed" || trip.status === "cancelled") return false;

  const isFlex = trip.flexEnabled || (trip.flexType && trip.flexType !== "unassigned");
  const checkInAtNoon = moment(
    moment(trip.checkIn)
      .utc()
      .tz(trip.propertyTimezone || "UTC", true)
      .hour(12)
      .format()
  );

  // reservation has flex cancel and todays time (at property timezone) is before or same (12pm of checkIn day) can cancel
  if (isFlex && moment().isSameOrBefore(checkInAtNoon)) return true;
  // reservation has a cancelBy and today's time (at property timezone) is before or same can cancel
  if (trip.cancelBy && moment().isSameOrBefore(trip.cancelBy)) return true;
  return false;
};

export default isTripCancellable;
