import moment from "moment";

import Button from "@hotel-engine/common/Button";
import { useExpressBook } from "pages/Checkout/LegacyLodging/ExpressBookModal/hooks/useExpressBook";

import { ExpressBookHotelPreviewCard } from "../ExpressBookHotelPreviewCard/ExpressBookHotelPreviewCard";

import * as Styled from "./styles";
import type { IExpressBookContractDataResponse } from "@hotel-engine/types/expressBook";
import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";

const successDateFormatter = (dateStr: string): string => {
  return moment(dateStr).format("ddd, MMM D, YYYY");
};

export const ExpressBookSuccessState = () => {
  const { COMPANY_NAME } = useCompanyName();
  const {
    expressCheckoutProperty,
    expressBookSuccessContext,
    expressBookCheckIn,
    expressBookCheckOut,
    redirectToItinerary,
    expressBookContractData,
  } = useExpressBook();

  const nightsAmt = moment(expressBookCheckOut).diff(moment(expressBookCheckIn), "days");

  const hasSuccessData = !!expressCheckoutProperty && expressBookCheckIn && expressBookCheckOut;

  return (
    <Styled.ExpressBookSuccessStateContainer data-testid="eb-success-state">
      <Styled.ExpressBookSuccessCopyPanel>
        <Styled.ExpressBookSuccessCheckIcon />
        <div>
          <h3>Booking complete!</h3>
          {/* TODO: Bind this data when we have contract flow built */}
          <p>
            {COMPANY_NAME} Contract #{expressBookSuccessContext.itineraryNumber}
          </p>
        </div>
      </Styled.ExpressBookSuccessCopyPanel>
      {!!hasSuccessData && (
        <Styled.ExpressBookSuccessStayInfoPanel>
          <div className="inner">
            <ExpressBookHotelPreviewCard
              resultPropertyRef={expressCheckoutProperty}
              expressBookContractData={expressBookContractData as IExpressBookContractDataResponse}
            />
            <Styled.ExpressBookSuccessDatesNightsBlock>
              <Styled.ExpressBookSuccessDateSpan>
                <Styled.ExpressBookSuccessDatesCalIcon />
                <span>{successDateFormatter(expressBookCheckIn)}</span>
              </Styled.ExpressBookSuccessDateSpan>
              <Styled.ExpressBookSuccessNightsChip>
                <Styled.ExpressBookSuccessMoonIcon />
                <span>
                  {nightsAmt} Night{nightsAmt > 1 ? "s" : ""}
                </span>
                <Styled.ExpressBookSuccessArrowRightIcon />
              </Styled.ExpressBookSuccessNightsChip>
              <Styled.ExpressBookSuccessDateSpan>
                {successDateFormatter(expressBookCheckOut)}
              </Styled.ExpressBookSuccessDateSpan>
            </Styled.ExpressBookSuccessDatesNightsBlock>
          </div>
        </Styled.ExpressBookSuccessStayInfoPanel>
      )}
      <Styled.ExpressBookSuccessFooter>
        <Button type="default" onClick={redirectToItinerary}>
          View itinerary
        </Button>
      </Styled.ExpressBookSuccessFooter>
    </Styled.ExpressBookSuccessStateContainer>
  );
};
