import * as Styled from "./styles";
import Loader from "@hotel-engine/common/Loader";

const SupportCaseSpinner = () => {
  return (
    <Styled.SpinnerContainer>
      <Loader size="large" type="inline" />
    </Styled.SpinnerContainer>
  );
};

export default SupportCaseSpinner;
