import { ACCEPT, GROUPS, JOIN, SIGN_UP } from "@hotel-engine/constants";
import { useSearchParams } from "@hotel-engine/hooks/useSearchParams";
import { useLocation } from "@hotel-engine/lib/react-router-dom";
import type { ISignUpSearchParams } from "pages/SignUp/types";

export const useExtractInvitationParams = (): ISignUpSearchParams | undefined => {
  const params = useSearchParams<ISignUpSearchParams>();
  const location = useLocation();
  const isInvitationPage =
    location.pathname === `/${SIGN_UP}` ||
    location.pathname === `/${ACCEPT}` ||
    location.pathname === `/${JOIN}/${GROUPS}`;

  if (isInvitationPage) {
    return {
      id: params.id,
      invitation_token: params.invitation_token,
    };
  }
  return undefined;
};
