import styled from "styled-components";

import { TopNavMenu } from "@hotelengine/atlas-web";

export const StyledTopNavMenu = styled(TopNavMenu)`
  cursor: default;

  * {
    cursor: default;
  }
`;
