import { Drawer as AntDrawer } from "antd";
import styled, { css } from "styled-components";

import type { IDrawer } from "./";

export const Drawer = styled(AntDrawer)<IDrawer>`
  .ant-drawer-body {
    width: 100%;
    ${({ fullscreen }) =>
      !!fullscreen &&
      css`
        position: absolute;
        top: 0;
        bottom: 0;
      `}
    ${({ title, hasFooter }) =>
      (!!title &&
        !!hasFooter &&
        css`
          padding: 112px 40px 112px; // 72px is height of header (top) and footer (bottom) + 40px content padding
        `) ||
      (!!title &&
        css`
          padding: 112px 40px 40px; // 72px is height of header + 40px content padding
        `) ||
      (!!hasFooter &&
        css`
          padding: 40px 40px 112px; // 72px is height of footer + 40px content padding
        `) ||
      css`
        padding: 40px;
      `}
  }
  .ant-drawer-close {
    top: 7px;
    right: 7px;
  }

  .ant-drawer-header {
    position: absolute;
    padding: 24px;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100 /* disable theming (do not replicate) */;
  }

  .ant-drawer-title {
    font-size: ${({ theme }) => theme.legacy.fontSize.lg};
    font-weight: ${({ theme }) => theme.legacy.fontWeight.bold};
  }
`;
