import type {
  IResultVisibility,
  ISearchMetricsState,
  ISetWebsocketBatchVisiblePayload,
} from "./types";

export enum VisibilityType {
  List = "list",
  Map = "map",
}

const VISIBILITY_TYPE_KEY_MAP = {
  [VisibilityType.List]: {
    batchVisibilityKey: "visibleInListAt",
    resultVisibilityKey: "listResultVisibility",
  },
  [VisibilityType.Map]: {
    batchVisibilityKey: "visibleOnMapAt",
    resultVisibilityKey: "mapResultVisibility",
  },
} as const;

export const setBatchVisible = (
  state: ISearchMetricsState,
  payload: ISetWebsocketBatchVisiblePayload,
  sourceType: VisibilityType
) => {
  const { timestamp, propertyIds, batchIndex } = payload;
  const { batchVisibilityKey, resultVisibilityKey } = VISIBILITY_TYPE_KEY_MAP[sourceType];

  for (let i = 0; i <= batchIndex; i++) {
    const batch = state.websocketBatches[i];

    if (!batch[batchVisibilityKey]) {
      batch[batchVisibilityKey] = timestamp;
    }
  }

  propertyIds.forEach((propertyId) => {
    if (!state[resultVisibilityKey][propertyId]) {
      state[resultVisibilityKey][propertyId] = timestamp;
    }
  });
};

export const setResultVisible = (
  state: ISearchMetricsState,
  payload: IResultVisibility,
  sourceType: VisibilityType
) => {
  const propertyIds = Object.keys(payload);
  const { resultVisibilityKey } = VISIBILITY_TYPE_KEY_MAP[sourceType];

  propertyIds.forEach((propertyId) => {
    if (!state[resultVisibilityKey][propertyId])
      state[resultVisibilityKey][propertyId] = payload[propertyId];
  });
};
