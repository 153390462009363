import * as Yup from "yup";

import type { IPaymentProfile } from "@hotel-engine/types/paymentProfile";

/**
 * Creates a yup schema for a PaymentProfile which handles international postal code validation messages
 */
export const buildPaymentProfileSchema = (currencyCode: string) => {
  const requiredPostalCodeMessage =
    currencyCode === "USD" ? "Enter billing zip code" : "Enter billing postal code";

  const invalidPostalCodeCopy =
    currencyCode === "USD" ? "Zip code is invalid" : "Postal code is invalid";

  return Yup.object().shape({
    authorized: Yup.bool().default(false),
    billingPostalCode: Yup.string().when("billingCountry", {
      is: (val) => {
        return val === "US";
      },
      then: (stringSchema) => {
        return stringSchema
          .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, invalidPostalCodeCopy)
          .required(requiredPostalCodeMessage);
      },
      otherwise: (stringSchema) => {
        return stringSchema.required(requiredPostalCodeMessage);
      },
    }),
    default: Yup.bool().default(false),
    name: Yup.string()
      .required("Enter full name on card")
      .matches(/\w*[a-zA-Z]\w*/, "Name on card must contain at least one letter"),
    nickname: Yup.string().nullable(),
  });
};

export const checkForDuplicates = (paymentProfiles, payment) => {
  if (!paymentProfiles?.length || payment.id) {
    return false;
  }

  return paymentProfiles.find((p) => {
    return p.spreedlyFingerprint === payment.spreedlyFingerprint;
  });
};

/**
 * Format expiration date as MM/YY using a payment profile
 */
export const formatExpirationDisplay = (payment: IPaymentProfile) => {
  const monthString = payment.expirationMonth.toString();
  const expirationMonth = monthString.length === 1 ? `0${monthString}` : monthString;
  const expirationYear = payment.expirationYear.toString();
  const expirationDisplay = `${expirationMonth}/${expirationYear.slice(2, 4)}`;
  return expirationDisplay;
};

/**
 * International copy for zip/postal codes
 */
export const billingCodeCopy = (currencyCode: string) =>
  currencyCode === "USD" ? "Zip code" : "Postal code";
