import styled from "styled-components";
import { colors } from "../../styles";

export const ChatLink = styled.span`
  color: ${colors.retroBlue};
  text-decoration: underline;
  &:focus,
  &:hover {
    cursor: pointer;
    transition: 0.3s;
  }
`;
