import * as Styled from "./styles";

/**
 * The `Checkbox` component wraps antd's `<Checkbox />` component.
 *
 * @remarks Since we are leveraging antd for most components, our internal Checkbox component does not have any of it's own props. Instead, we are directly referring to the interface definition antd is expecting. This allows us to easily be in alignment with their type definitions and prevents type errors that may arise from maintaining an override interface.
 *
 * @see {@link https://3x.ant.design/components/radio/ AntD Radio Documentation}
 */

export const Checkbox = Styled.AntCheckbox;

export type { CheckboxProps, CheckboxChangeEvent } from "antd/lib/checkbox";
