import styled from "styled-components";

import Button from "@hotel-engine/common/Button";
import { colors } from "@hotel-engine/styles";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";

export const LoyaltyBadge = styled.div`
  margin: 0;
  display: inline-flex;
  color: ${colors.dragonlordPurple};
  align-items: center;
  flex: 1 1 auto;
`;

export const ProgramName = styled.span`
  margin: 0 4px;
`;

export const ProgramNum = styled.span`
  margin-left: 4px;
  margin-right: 10px;
`;

export const EditProgramNum = styled(Button)`
  margin: 1px 0px 0px 16px;
`;

export const OuterForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const InnerForm = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;

export const LoyaltyInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  ${mediaQuery(
    "xl",
    "max"
  )(`
        flex-direction: column;
        justify-content: unset;
  `)}
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  &:last-of-type {
    margin-left: 16px;
  }

  .ant-form-item {
    flex-grow: 1;
    margin-bottom: 0;
  }

  .ant-form-item-label {
    line-height: ${({ theme }) => theme.legacy.lineHeight.sm};
  }

  ${mediaQuery(
    "xl",
    "max"
  )(`
        &:last-of-type {
            margin-top: 16px;
            margin-left: unset;
          }
  `)}
`;

export const LoyaltyPassThruMsg = styled.div`
  color: ${colors.kiwiGrey};
`;

export const Buttons = styled.div`
  margin: 24px 0 0 0;
  button {
    &:last-of-type {
      margin-left: 16px;
    }
  }

  ${mediaQuery(
    "xl",
    "max"
  )(`
    margin: 16px 0;
  `)}
`;
