export const AMENITIES_KEYS = {
  wifi: "wifi",
  internet: "internet",
  parking: "parking",
  breakfast: "breakfast",
  petFriendly: "petFriendly",
  swimmingPool: "swimmingPool",
  fitnessCenter: "fitnessCenter",
  dining: "dining",
  truckParking: "truckParking",
  selfServiceLaundry: "selfServiceLaundry",
  meetingSpace: "meetingSpace",
  kitchen: "kitchen",
  airConditioning: "airConditioning",
  coffeeMaker: "coffeeMaker",
  refrigerator: "refrigerator",
  dryCleaning: "dryCleaning",
  valetParking: "valetParking",
  shuttle: "shuttle",
  spa: "spa",
} as const;

const INTERNET_AMENITIES = [
  "internet",
  "wifi",
  "free wifi",
  "free wired internet",
  "internet access public access for fee",
  "internet access free public access",
];

export const AVAILABLE_AMENITIES = {
  [AMENITIES_KEYS.wifi]: INTERNET_AMENITIES,
  [AMENITIES_KEYS.internet]: INTERNET_AMENITIES,
  [AMENITIES_KEYS.parking]: ["free parking"],
  [AMENITIES_KEYS.breakfast]: ["free breakfast"],
  [AMENITIES_KEYS.petFriendly]: ["pet friendly", "pets allowed"],
  [AMENITIES_KEYS.swimmingPool]: ["pool", "swimming pool"],
  [AMENITIES_KEYS.fitnessCenter]: ["fitness center", "gym"],
  [AMENITIES_KEYS.dining]: ["dining", "bar", "lounge", "bar/lounge", "restaurant"],
  [AMENITIES_KEYS.truckParking]: ["truck/rv parking", "truck parking"],
  [AMENITIES_KEYS.selfServiceLaundry]: ["self service laundry"],
  [AMENITIES_KEYS.meetingSpace]: [
    "meeting rooms",
    "conference center",
    "convention center",
    "convention services",
    "banquet facilities",
    "business center",
  ],
  [AMENITIES_KEYS.kitchen]: ["kitchenette", "kitchen"],
  [AMENITIES_KEYS.airConditioning]: ["air conditioning", "ac", "a/c"],
  [AMENITIES_KEYS.coffeeMaker]: ["coffee", "coffee maker", "coffee available", "free coffee"],
  [AMENITIES_KEYS.refrigerator]: ["refrigerator"],
  [AMENITIES_KEYS.dryCleaning]: ["valet laundry", "dry cleaning services"],
  [AMENITIES_KEYS.valetParking]: ["valet", "valet parking"],
  [AMENITIES_KEYS.shuttle]: ["free shuttle"],
  [AMENITIES_KEYS.spa]: ["spa"],
};
