import { useState, useCallback } from "react";

import { Chip, TopNavRightAccessory, Box } from "@hotelengine/atlas-web";

import SelectedProperties from "pages/ShareOptions/components/SelectedProperties/SelectedProperties";
import { SwitchUserAccountType, type UserAccountType } from "@hotel-engine/types/user";
import {
  MobileComponents,
  DesktopComponents,
  type IDesktopComponents,
} from "../MobileAndDesktopAccessories";
import { useUser } from "@hotel-engine/hooks";
import { useAppSelector } from "store/hooks";

interface IRightAccessories
  extends Pick<IDesktopComponents, "mode" | "onInviteTraveler" | "onPersonalAccountCreation"> {
  isMobile: boolean;
  handleSwitchAccounts: (mode: UserAccountType) => void;
}

export const RightAccessories = ({
  isMobile,
  mode,
  handleSwitchAccounts,
  onInviteTraveler,
  onPersonalAccountCreation,
}: IRightAccessories) => {
  const user = useUser();
  const options = useAppSelector((state) => state.ShareOptions.options);
  const [showUnreadNotifications, setShowUnreadNotifications] = useState(false);
  const onToggleBizPersonalAccount = useCallback(() => {
    const newMode = SwitchUserAccountType[mode ?? "personal"];
    handleSwitchAccounts(newMode);
  }, [mode, handleSwitchAccounts]);

  return (
    <TopNavRightAccessory style={{ height: isMobile ? "76px" : undefined }}>
      {isMobile ? (
        <MobileComponents
          mode={mode}
          onInviteTraveler={onInviteTraveler}
          onPersonalAccountCreation={onPersonalAccountCreation}
          setShowUnreadNotifications={setShowUnreadNotifications}
          showUnreadNotifications={showUnreadNotifications}
          onToggleBizPersonalAccount={onToggleBizPersonalAccount}
        />
      ) : (
        <>
          <Box display="flex" alignItems="center" gap={8}>
            {Boolean(user.isDemo) && <Chip label="Demo" color="gray" variant="outlined" />}
            <SelectedProperties options={options} showSelectedProperties={options?.length > 0} />
            <DesktopComponents
              mode={mode}
              onInviteTraveler={onInviteTraveler}
              onPersonalAccountCreation={onPersonalAccountCreation}
              setShowUnreadNotifications={setShowUnreadNotifications}
              showUnreadNotifications={showUnreadNotifications}
              onToggleBizPersonalAccount={onToggleBizPersonalAccount}
            />
          </Box>
        </>
      )}
    </TopNavRightAccessory>
  );
};
