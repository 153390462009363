import styled, { css } from "styled-components";

import { colors } from "@hotel-engine/styles";

import type { SkeletonLoaderSizes, SkeletonLoaderType } from ".";

interface ISkeletonLoaderProps {
  type: SkeletonLoaderType;
  /** The width/min-width of the skeleton */
  width?: SkeletonLoaderSizes;
  /** The height/min-height of the skeleton */
  height?: SkeletonLoaderSizes;
  /** If flexible, use min-width instead of a set width */
  flexible?: boolean;
}

const uniqueDefaultCardStyling = css`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  .ant-skeleton-avatar {
    height: 148px;
    width: 90px;
    border-radius: 5px /* disable theming (do not replicate) */;
  }
`;

const uniqueBarStyling = css`
  border: none;
`;

const uniqueTallCardStyling = css`
  .ant-skeleton-title {
    border-radius: 5px /* disable theming (do not replicate) */;
    height: 150px;
    margin: 0;
  }
`;

const uniqueLongCardStyling = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const skeletonWidth = {
  xs: "200px",
  sm: "230px",
  md: "285px",
  lg: "300px",
  xl: "350px",
  fluid: "100%",
};

const skeletonHeight = {
  xs: "40px",
  sm: "180px",
  md: "200px",
  lg: "240px",
  xl: "400px",
  fluid: "100%",
};

// Our config for common properties in each skeleton type
const SkeletonLoaderConfig = {
  default: {
    width: skeletonWidth.fluid,
    height: skeletonHeight.sm,
    padding: "16px",
    styling: uniqueDefaultCardStyling,
  },
  bar: {
    width: skeletonWidth.sm,
    height: skeletonHeight.xs,
    padding: "0",
    styling: uniqueBarStyling,
  },
  long: {
    width: skeletonWidth.md,
    height: skeletonHeight.md,
    padding: "30px 16px",
    styling: uniqueLongCardStyling,
  },
  tall: {
    width: skeletonWidth.lg,
    height: skeletonHeight.xl,
    padding: "32px",
    styling: uniqueTallCardStyling,
  },
};

export const SkeletonLoader = styled.div<ISkeletonLoaderProps>`
  /* Common styles */
  border: 1px solid ${colors.grey[200]};
  border-radius: 5px /* disable theming (do not replicate) */;
  /* Use the default height for each card unless a custom height is passed */
  height: ${({ type, height }) =>
    height ? skeletonHeight[height] : SkeletonLoaderConfig[type].height};
  padding: ${({ type }) => SkeletonLoaderConfig[type].padding};
  /* If flexible, use min-width instead of width. If no width is passed in, use card's default. */
  ${({ flexible, width, type }) => {
    const setWidth = width ? skeletonWidth[width] : SkeletonLoaderConfig[type].width;
    return flexible
      ? css`
          min-width: ${setWidth};
        `
      : css`
          width: ${setWidth};
        `;
  }}

  /* Override antd styling */
    .ant-skeleton-paragraph {
    margin-bottom: 0;
  }
  .ant-skeleton-content .ant-skeleton-paragraph > li {
    margin-bottom: 8px;
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
    ${({ type, height }) =>
      type === "bar" &&
      css`
        height: ${height ? skeletonHeight[height] : `40px`};
        margin: 0;
      `};
    ${({ type }) =>
      type === "long" &&
      css`
        &:nth-child(2) {
          margin-top: 8px;
          height: 8px;
        }
      `};
  }

  .ant-skeleton-content .ant-skeleton-paragraph > li + li {
    margin-top: 0;
  }

  ${({ type, height }) =>
    type === "bar" &&
    height === "fluid" &&
    css`
      .ant-skeleton,
      ul {
        height: 100%;
      }
    `}

  /* Grab any other unique styling for each skeleton type */
    ${({ type }) => SkeletonLoaderConfig[type].styling}
`;
