import type { IResult } from "@hotel-engine/types/search";
import type { IResults, ISearchResultsState } from "store/Search/SearchResults/types";
import { type IBuildFilterResultsFunction, buildFilterResultFunction } from "./helpers";

export interface IBuildFilteredResults extends IBuildFilterResultsFunction {
  results: IResults;
}

export type BuildFilteredResultsReturn = Pick<
  ISearchResultsState,
  "visibleResultIds" | "visibleHiddenResultIds"
>;

export const buildFilteredResults = ({
  results,
  selectedFilters,
  options,
}: IBuildFilteredResults): BuildFilteredResultsReturn => {
  const visibleResultIds: number[] = [];
  const visibleHiddenResultIds: number[] = [];

  const doesResultMeetsFilterCriteria = buildFilterResultFunction({
    selectedFilters,
    options,
  });

  (Object.values(results) as IResult[]).filter(doesResultMeetsFilterCriteria).forEach((result) => {
    const targetBucket = result.hidden ? visibleHiddenResultIds : visibleResultIds;

    targetBucket.push(result.propertyId);
  });

  return {
    visibleResultIds,
    visibleHiddenResultIds,
  };
};
