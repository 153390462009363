import styled from "styled-components";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import Image from "@hotel-engine/common/Image";

export const BottomSheetContentContainer = styled.div`
  position: relative;
  margin-bottom: 16px;
`;

export const FlexProImageBanner = styled(Image)`
  border-top-right-radius: ${({ theme }) => theme.legacy.shape.borderRadius.md};
  border-top-left-radius: ${({ theme }) => theme.legacy.shape.borderRadius.md};
  width: 100%;
`;

export const TextContainer = styled.section`
  ${flexbox({
    justifyContent: "center",
    alignItems: "flex-start",
    direction: "column",
  })};
  padding: 40px;
`;

export const BulletList = styled.ul`
  margin: 0;
  width: 90%;
  list-style-type: none;
`;

export const ButtonContainer = styled.div<{ $isMobile?: boolean }>`
  ${flexbox({
    direction: "row",
    justifyContent: "flex-end",
  })};
  margin: 24px 0 0;
  gap: 8px;
  width: 100%;
  `;

export const BulletContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 4px;
`;
