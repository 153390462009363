import styled, { css } from "styled-components";

import { colors } from "@hotel-engine/styles";
import { equalDimensions } from "@hotel-engine/styles/helpers/mixins";

import type { IAvatar } from "./";

export const Avatar = styled.div<IAvatar>`
  display: flex;
  position: relative;
  z-index: ${({ theme }) => theme.legacy.zIndex.backdrop};
  .avatar,
  .number {
    align-items: center;
    border-radius: 60px /* disable theming (do not replicate) */;
    display: flex;
    justify-content: center;
    overflow: hidden;
    line-height: 1;
    ${({ size }) =>
      (size === "xsmall" &&
        css`
          ${equalDimensions(24)};
          font-size: ${({ theme }) => theme.legacy.fontSize.sm};
        `) ||
      (size === "small" &&
        css`
          ${equalDimensions(32)};
          font-size: ${({ theme }) => theme.legacy.fontSize.sm};
        `) ||
      (size === "med" &&
        css`
          ${equalDimensions(40)};
          font-size: ${({ theme }) => theme.legacy.fontSize.md};
        `) ||
      (size === "large" &&
        css`
          ${equalDimensions(60)};
          font-size: ${({ theme }) => theme.legacy.fontSize.xl};
        `) ||
      (size === "xlarge" &&
        css`
          ${equalDimensions(120)};
          font-size: ${({ theme }) => theme.legacy.fontSize.xxxl};
        `)}
    ${({ multiple }) =>
      multiple &&
      css`
        ${equalDimensions(32)};
        font-size: ${({ theme }) => theme.legacy.fontSize.sm};
        box-sizing: content-box;
      `}
  }
  .avatar {
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: ${({ fontColor }) => fontColor || `${colors.white}`};
    ${({ multiple, border }) =>
      (multiple || border) &&
      css`
        border: ${border || `2px solid ${colors.white}`};
      `}
    img {
      max-height: 100%;
    }
  }
  .number {
    background-color: ${colors.grey[50]};
    color: ${colors.blackPanther};
    border: 2px solid ${colors.white};
    margin-left: -6px;
    z-index: ${({ theme }) => theme.legacy.zIndex.belowBackdrop};
  }
`;
