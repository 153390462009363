import type { IContractRate } from "@hotel-engine/types/contractRate";
import type {
  IExpressBookCheckoutForm,
  IExpressBookContractRate,
} from "@hotel-engine/types/expressBook";
import type { IPaymentTypes } from "@hotel-engine/types/reservation";
import { isIUser, type IUser } from "@hotel-engine/types/user";

import type { ICheckoutFormData } from "../validation";

export const returnGuestList = (
  roomCount: string,
  user: IUser | null,
  contractRate: IContractRate
) => {
  const guestList: ICheckoutFormData["guestList"] = new Array(parseInt(roomCount)).fill({
    primary: { mode: "search" },
    secondary: { mode: "hidden" },
  });

  if (user?.role === "user" && !!!contractRate?.overlappingContracts?.length) {
    guestList[0].primary = { user, mode: "selected" };
  }

  return guestList;
};

export const formatPaymentTypes = (
  data: ICheckoutFormData | IExpressBookCheckoutForm,
  rate: IContractRate | IExpressBookContractRate
) => {
  const paymentTypes: IPaymentTypes[] = [];

  if (data.travelCredits) {
    paymentTypes.push({
      profileId: null,
      type: "travel_credits",
      amount: data.travelCredits,
    });
  }

  if (data.rewardsPoints) {
    paymentTypes.push({
      profileId: null,
      type: "points",
      amount: data.rewardsPoints,
    });
  }

  const totalCost = data.includeIncidentalCharges ? rate?.totalWithIncidentals : rate?.total;

  const customerTotal = +(
    totalCost -
    data.rewardsPoints +
    -data.travelCredits +
    (data.isFlexEnabled && !data.isFlexProEnrolled ? rate?.flexCancellationCost : 0)
  ).toFixed(2);

  if (data.selectedPaymentId === 0) {
    paymentTypes.push({
      profileId: null,
      type: "direct_bill",
      amount: customerTotal,
    });
  } else if (data.selectedPaymentId) {
    paymentTypes.push({
      profileId: data.selectedPaymentId,
      type: "card",
      amount: customerTotal,
    });
  }

  return paymentTypes;
};

export const findUnconfirmedUserIds = (guestList?: ICheckoutFormData["guestList"]) => {
  if (!guestList) return null;

  const unconfirmedUserIds = guestList.map((room) => {
    const unconfirmedIds: string[] = [];

    if (
      room?.primary?.user &&
      isIUser(room?.primary?.user) &&
      room?.primary?.user?.confirmedAt === null
    ) {
      unconfirmedIds.push(room.primary.user.id.toString());
    }

    if (
      room?.secondary?.user &&
      isIUser(room?.secondary?.user) &&
      room?.secondary?.user?.confirmedAt === null
    ) {
      unconfirmedIds.push(room.secondary.user.id.toString());
    }

    return unconfirmedIds;
  });

  return !!unconfirmedUserIds?.flat().length ? unconfirmedUserIds.flat() : null;
};
