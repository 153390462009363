import { AutoComplete } from "antd";
import type { AutoCompleteProps } from "antd/lib/auto-complete";
import styled, { createGlobalStyle } from "styled-components";

import { colors } from "@hotel-engine/styles";

export const AntAutoComplete = styled(AutoComplete)<AutoCompleteProps>`
  .ant-input {
    color: ${colors.grey[800]};
  }
  .ant-select-selection__placeholder {
    color: ${colors.newGrey500};
  }
`;

export const GlobalAutoCompleteStyles = createGlobalStyle``;
