import { useTranslation } from "react-i18next";

export const useCityInfo = () => {
  const { t } = useTranslation("iata_city");

  return {
    getCityNameByIataCityCode: (iataAirportCode: string) =>
      t(`iata_city.name.${iataAirportCode}`, { defaultValue: iataAirportCode }),
    getIataCountryCodeByIataCityCode: (iataAirportCode: string) =>
      t(`iata_city.iata_country_code.${iataAirportCode}`, { defaultValue: iataAirportCode }),
  };
};
