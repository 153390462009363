/* eslint-disable @typescript-eslint/no-namespace, @typescript-eslint/naming-convention */
export declare namespace Rewards {
  type NextTier = typeof Rewards.NextTier;

  interface DisabledProfile {
    _tag: Rewards.Tier.Disabled;
  }

  interface EnabledProfile extends IRewardsProfile {
    _tag: Rewards.Tier;
  }

  /**
   * ### {@link Profile `Rewards.Profile`}
   *
   * A decoded {@link IRewardsProfile `IRewardsProfile`}.
   */
  type Profile = DisabledProfile | EnabledProfile;
}

/**
 * ### {@link deriveEnum `deriveEnum`}
 *
 * Derive an enum variant-lookup from a list of identifiers. Preserves type information.
 *
 * Useful when the variants have an "order" that's important, to avoid from having to
 * define the enum twice (first as an array, to give the variants an order, then as an
 * object, so you can access the variant by its name instead of its index).
 */
function deriveEnum<T extends string | number>(variants: readonly T[]): { [K in T]: K };
function deriveEnum<T extends string | number>(variants: readonly T[]) {
  const out: Record<string | number, string | number> = {};
  for (let ix = 0, len = variants.length; ix < len; ix++) out[variants[ix]] = variants[ix];
  return out;
}

export namespace Rewards {
  export const Tiers = ["Disabled", "Member", "Silver", "Gold", "Platinum"] as const;

  /**
   * ### {@link Enum `Rewards.Enum`}
   *
   * Enum describing the user's current rewards tier.
   */
  export const Enum = deriveEnum(Tiers);
  export type Enum = typeof Enum;

  export type Tier = (typeof Tiers)[number];
  /**
   * ### {@link Tier `Rewards.Tier`}
   *
   * As a type, refers to the union of all possible rewards tier names.
   *
   * As a namespace, contains a set of types and enums that relate to
   * a user's current and available reward tiers.
   */
  export namespace Tier {
    /**
     * #### {@link Tier.Disabled `Rewards.Tier.Disabled`}
     *
     * Handles 2 cases:
     * 1. `user === null`
     * 2. `user.rewardsProfile === null`
     */
    export const Disabled = Enum.Disabled;
    export type Disabled = typeof Disabled;
    /**
     * #### {@link Tier.Member `Rewards.Tier.Member`}
     * Maps to `user.rewardsProfile.currentTier === "Member"`
     */
    export const Member = Enum.Member;
    export type Member = typeof Member;
    /**
     * #### {@link Tier.Silver `Rewards.Tier.Silver`}
     * Maps to `user.rewardsProfile.currentTier === "Silver"`
     */
    export const Silver = Enum.Silver;
    export type Silver = typeof Silver;
    /**
     * #### {@link Tier.Gold `Rewards.Tier.Gold`}
     * Maps to `user.rewardsProfile.currentTier === "Gold"`
     */
    export const Gold = Enum.Gold;
    export type Gold = typeof Gold;
    /**
     * #### {@link Tier.Platinum `Rewards.Tier.Platinum`}
     * Maps to `user.rewardsProfile.currentTier === "Platinum"`
     */
    export const Platinum = Enum.Platinum;
    export type Platinum = typeof Platinum;
  }

  /**
   * ### {@link NextTier `Rewards.NextTier`}
   *
   * Enum mapping a user's current rewards tier to its next state.
   * Since the two correspond 1:1, we can stuff this logic inside a data structure.
   *
   * _Tradeoff_:
   *
   * - **Con:** slightly more work upfront, since you have to explicitly
   *   enumerate the possible states ahead of time. Which is annoying, but
   *   [probably](https://joelonsoftware.com/2000/10/02/painless-functional-specifications-part-1-why-bother/)
   *   worth doing
   *
   * - **Pro:** we've replaced the need for business logic / if statements
   *   with a simple table lookup. This is often possible when working
   *   with enums (or any
   *   [discriminated union](https://www.typescriptlang.org/docs/handbook/typescript-in-5-minutes-func.html#discriminated-unions)).
   */
  export const NextTier = {
    Disabled: Tier.Disabled,
    Member: Tier.Silver,
    Silver: Tier.Gold,
    Gold: Tier.Platinum,
    Platinum: null,
  } as const satisfies Record<Tier, Tier | null>;
}

/**
 * ### {@link IRewardsProfile `IRewardsProfile`}
 *
 * This interface describes the user's rewards profile that we get
 * from Nexus, before it has been decoded into a
 * {@link RewardsProfile `RewardsProfile`}.
 */
export interface IRewardsProfile {
  /** Number of bookings completed during current term */
  bookings: number | string;
  /** Details about member's current tier */
  currentTier: IRewardsProfileCurrentTier;
  /** Date member joined rewards program */
  joinDate: string;
  /** Member's ID */
  memberId: number | string;
  /** Details about member's next tier */
  nextTier: IRewardsProfileNextTier | null;
  /** Points member has available for redemption */
  points: number;
  /** Ammount spent by member during current term */
  spend: number | string;
}

export interface IRewardsProfileCurrentTier {
  /** Beginning date of current term */
  startDate: string;
  /** Term of current tier */
  name: string;
  /** Position of term in tier sequence */
  tierNumber: number;
  /** Points earned per dollar spent */
  pointsPerDollar: number;
  /** Number of bookings required to unlock tier */
  bookingCountUnlock: number;
  /** Number of dollars required to unlock tier */
  spendUnlock: number;
}

export interface IRewardsProfileNextTier {
  /** Beginning date of next term */
  startDate: string | null;
  /** Term of next tier */
  name: string;
  /** Position of term in tier sequence */
  tierNumber: number;
  /** Points earned per dollar spent */
  pointsPerDollar: number;
  /** Number of bookings required to unlock tier */
  bookingCountUnlock: number;
  /** Number of dollars required to unlock tier */
  spendUnlock: number | string;
}
