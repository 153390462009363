import { useEffect } from "react";

/**
 * Add event listener to any component, cleanup handled in useEffect return
 *
 * @param event - name of event for functional component to listen to
 * @param callback - callback function to fire for event
 */
export const useEventListener = (event: string, callback: (e?: Event) => void) => {
  useEffect(() => {
    globalThis.addEventListener(event, callback);

    return () => globalThis.removeEventListener(event, callback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
