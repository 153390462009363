import styled from "styled-components";

import Button from "@hotel-engine/common/Button";
import { FormItem as AntFormItem } from "@hotel-engine/common/Form";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { colors } from "@hotel-engine/styles";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";

export const FormItem = styled(AntFormItem)`
  .ant-select {
    width: 100%;
    border: 1px solid ${colors.grey[300]};
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.lg};
  }
  .ant-select-arrow {
    color: ${colors.grey[800]};
  }
  .ant-select-selection-selected-value {
    color: ${colors.grey[800]};
  }
  .ant-select-selection__placeholder {
    color: ${colors.kiwiGrey};
  }
  .ant-input {
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.lg};
  }
`;

export const SubmitButton = styled(Button)`
  margin-top: 8px;
  min-width: 100%;
  font-size: 17px;
  line-height: 22px;
  ${({ loading }) =>
    loading &&
    `
      padding-left: 15px !important;
    `};
  &.ant-btn-primary {
    &:disabled {
      font-weight: 500;
      color: ${colors.kiwiGrey};
      background-color: ${colors.grey[200]};
      border-color: ${colors.grey[200]};
    }
  }
`;

export const ForgotPasswordButton = styled(Button)`
  width: fit-content;
  margin-bottom: "0";
`;

export const FormTitle = styled.h1`
  ${typographyMixin("xlarge")}
  color: ${colors.grey[800]};
  margin-bottom: "0";

  margin-top: "0";

  ${mediaQuery(
    "md",
    "max"
  )(`
    margin: 0 auto;
  `)}
`;

export const FormSubtitle = styled.h3`
  ${typographyMixin("body-medium")}
  color: ${colors.grey[500]};
  margin: "8px auto auto";
`;
