import { BOOK, PAYMENT, routes } from "@hotel-engine/constants";
import {
  RedirectToExternalUrl,
  RouteWithFeatureFlag,
  hasDirectBillEnabled,
  hasReportingAccess,
  hasRewardsEnabled,
  hasTravelCreditsAllowedEnabled,
  mustCompletePersonal2fv,
} from "@hotel-engine/react-router";
import config from "config";

import type { RouteObject } from "@hotel-engine/lib/react-router-dom";
import { Navigate, Outlet, createBrowserRouter } from "@hotel-engine/lib/react-router-dom";
import { RouteAuthWrapper } from "@hotel-engine/react-router/AuthRoute";
import { hasRequiredSearchQueryParams } from "@hotel-engine/react-router/customAuth/hasRequiredSearchQueryParams";
import { RedirectWithRouteParams } from "@hotel-engine/react-router/RedirectWithParams";
import { FlightSearchParamsProvider } from "pages/Flights/contexts/FlightSearchParamsContext";
import { ProvidersWithinRouter } from "./Providers";
import * as RouteComponents from "./RouteComponents";

const redirectRoutes: RouteObject[] = [
  { path: "/teams", element: <Navigate to={routes.members.all} replace /> },
  {
    path: "/trips/booked",
    element: <Navigate to={routes.trips.upcoming} replace />,
  },
  {
    path: "/trips/visiting",
    element: <Navigate to={routes.trips.active} replace />,
  },
  {
    path: "/trips/completed",
    element: <Navigate to={routes.trips.past} replace />,
  },
  {
    path: "/company-settings/controls",
    element: <Navigate to={routes.companySettings.userPermissions} replace />,
  },
  {
    path: "/company-settings/worksites",
    element: <Navigate to={routes.companySettings.savedLocations} replace />,
  },
  {
    path: routes.favoritePlaces,
    element: <Navigate to={routes.myProperties} replace />,
  },
];

const externalRedirectRoutes: RouteObject[] = [
  {
    path: "/register",
    element: <RedirectToExternalUrl externalUrl={`${config.marketingHost}/sign-up`} />,
  },
];

const publicRoutes: RouteObject[] = [
  { path: routes.lockedOut, element: <RouteComponents.LockedOut /> },
  { path: routes.login, element: <RouteComponents.Login /> },
  { path: routes.changePassword, element: <RouteComponents.ForgotPassword /> },
  { path: routes.accountUnlock, element: <RouteComponents.AccountUnlock /> },
  { path: routes.propertyReviews, element: <RouteComponents.PropertyReview /> },
  {
    path: routes.flightsSeatmapPortal,
    element: <RouteComponents.FlightsSeatmapPortal />,
  },
  {
    path: routes.personalAccountConfirm,
    element: <RouteComponents.PersonalTravelConfirm />,
  },
  {
    element: <RouteComponents.JoinPageLayout />,
    children: [
      {
        path: `${routes.join.base}/:businessCode/*`,
        element: <RouteComponents.JoinFlow />,
      },
      {
        path: `${routes.join.referFriends}/:token/*`,
        element: <RouteComponents.JoinReferFriendsFlow />,
      },
      {
        path: `${routes.join.accept}/*`,
        element: <RouteComponents.JoinFlow />,
      },
      { path: `${routes.confirm}/*`, element: <RouteComponents.JoinConfirm /> },
      {
        path: `${routes.join.business}/:businessCode/*`,
        element: <RouteComponents.JoinFlow />,
      },
      {
        path: `${routes.join.partners}/:orgCode/*`,
        element: <RouteComponents.JoinPartnersFlow />,
      },
      {
        path: `${routes.join.legacyReferFriends}/:token/*`,
        element: <RedirectWithRouteParams to={`${routes.join.referFriends}/:token`} />,
      },
      {
        path: `${routes.join.base}/finish`,
        element: <RouteComponents.JoinFinish />,
      },
    ],
  },
  {
    path: routes.checkoutVerifications,
    element: <RouteComponents.CheckoutVerification />,
  },
  {
    path: routes.personalTravelUnconfirmed,
    element: <RouteComponents.PersonalTravelUnconfirmed />,
  },
  { path: routes.signUp, element: <RouteComponents.SignUp /> },
  { path: routes.joinGroups, element: <RouteComponents.GroupsJoin /> },
];

const authRoutes: RouteObject[] = [
  {
    path: "/",
    element: (
      <RouteAuthWrapper minRole="user">
        <RouteComponents.Dashboard />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.balance}/*`,
    element: (
      <RouteAuthWrapper minRole="admin">
        <RouteComponents.Balance />
      </RouteAuthWrapper>
    ),
  },
  {
    path: routes.creditLineIncrease,
    element: (
      <RouteAuthWrapper minRole="admin" customAuth={[hasDirectBillEnabled]}>
        <RouteComponents.CreditLineIncrease />
      </RouteAuthWrapper>
    ),
  },
  {
    path: routes.billing,
    element: (
      <RouteAuthWrapper minRole="admin" customAuth={[hasDirectBillEnabled]}>
        <RouteComponents.Billing />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.billing}/:id`,
    element: (
      <RouteAuthWrapper minRole="admin">
        <RouteComponents.Statement />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.billing}/:id/${PAYMENT}/*`,
    element: (
      <RouteAuthWrapper minRole="admin">
        <RouteComponents.PaymentReview />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.companySettings.ssoVerification}`,
    element: (
      <RouteAuthWrapper minRole="admin">
        <RouteComponents.NewSSOConfigConfirm />
      </RouteAuthWrapper>
    ),
    children: [
      // :ssoId was an optional dynamic route param in rrv5 (:ssoId?)
      { path: ":ssoId/*", element: <Outlet /> },
      { path: "*", element: <Outlet /> },
    ],
  },
  {
    path: `${routes.companySettings.base}`,
    element: (
      <RouteAuthWrapper minRole="admin">
        <RouteComponents.CompanySettings />
      </RouteAuthWrapper>
    ),
    children: [
      // :tab was an optional dynamic route param in rrv5 (:tab?)
      { path: ":tab/*", element: <Outlet /> },
      { path: "*", element: <Outlet /> },
    ],
  },
  {
    path: `${routes.companySettings.travelPoliciesLanding}/*`,
    element: (
      <RouteAuthWrapper minRole="admin">
        <RouteComponents.TravelPoliciesLandingPage />
      </RouteAuthWrapper>
    ),
  },
  {
    path: routes.creditCardTransactions,
    element: (
      <RouteAuthWrapper minRole="admin">
        <RouteComponents.CreditCardTransactions />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.myProperties}/*`,
    element: (
      <RouteAuthWrapper minRole="user">
        <RouteComponents.MyProperties />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.referFriends.programDetails}/*`,
    element: (
      <RouteAuthWrapper minRole="user">
        <RouteComponents.ReferFriendsProgramDetails />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.referFriends.base}/*`,
    element: (
      <RouteAuthWrapper minRole="user">
        <RouteComponents.ReferFriends />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.itinerary}/*`,
    element: (
      <RouteAuthWrapper minRole="view_only_traveler">
        <RouteComponents.Itinerary />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.members.details}/:userId`,
    element: (
      <RouteAuthWrapper minRole="admin">
        <RouteComponents.MemberDetails />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.companySettings.travelPolicies}/:travelPolicyId`,
    element: (
      <RouteAuthWrapper minRole="admin">
        <RouteComponents.TravelPolicyFormPage />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.members.base}/:departmentId/*`,
    element: (
      <RouteAuthWrapper minRole="admin">
        <RouteComponents.Members />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.modifyReservation}/:reservationId/*`,
    element: (
      <RouteAuthWrapper minRole="user">
        <RouteComponents.ModifyReservation />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.billingIssue}/:reservationId`,
    element: (
      <RouteAuthWrapper minRole="user">
        <RouteComponents.BillingIssue />
      </RouteAuthWrapper>
    ),
  },
  {
    path: routes.paymentHistory,
    element: (
      <RouteAuthWrapper minRole="admin" customAuth={[hasDirectBillEnabled]}>
        <RouteComponents.PaymentHistory />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.paymentDetails}/:id`,
    element: (
      <RouteAuthWrapper minRole="admin" customAuth={[hasDirectBillEnabled]}>
        <RouteComponents.PaymentDetails />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.paymentMethods}/*`,
    element: (
      <RouteAuthWrapper minRole="admin">
        <RouteComponents.PaymentMethods />
      </RouteAuthWrapper>
    ),
  },
  {
    path: routes.rewards,
    element: (
      <RouteAuthWrapper minRole="user" customAuth={[hasRewardsEnabled]}>
        <RouteComponents.Rewards />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.properties}/:propertyId`,
    element: (
      <RouteAuthWrapper minRole="user">
        <RouteComponents.Property />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.properties}/:propertyId/${BOOK}/*`,
    element: (
      <RouteAuthWrapper minRole="user">
        <RouteComponents.Checkout />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.rewardsEnrollment}/*`,
    element: (
      <RouteAuthWrapper minRole="user" customAuth={[hasRewardsEnabled]}>
        <RouteComponents.RewardsEnrollment />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.rewardsFaq}/*`,
    element: (
      <RouteAuthWrapper minRole="user" customAuth={[hasRewardsEnabled]}>
        <RouteComponents.RewardsFaq />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.rewardsToc}/*`,
    element: (
      <RouteAuthWrapper minRole="user" customAuth={[hasRewardsEnabled]}>
        <RouteComponents.RewardsTerms />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.settings.base}`,
    element: (
      <RouteAuthWrapper minRole="view_only_traveler">
        <RouteComponents.Settings />
      </RouteAuthWrapper>
    ),
    children: [
      // :tab was an optional dynamic route param in rrv5 (:tab?)
      { path: ":tab/*", element: <Outlet /> },
      { path: "*", element: <Outlet /> },
    ],
  },

  {
    path: `${routes.settings.guestInformation}/:userId`,
    element: (
      <RouteAuthWrapper minRole="user">
        <RouteComponents.MemberDetails isGuest />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.flightsSearch}/*`,
    element: (
      <RouteWithFeatureFlag featureFlag="hilo_shop_and_checkout">
        {(isLoggedInAndFFEnabled) =>
          isLoggedInAndFFEnabled ? (
            <RouteAuthWrapper minRole="user">
              <RouteComponents.FlightsSearch />
            </RouteAuthWrapper>
          ) : (
            <Navigate replace to="/" />
          )
        }
      </RouteWithFeatureFlag>
    ),
  },
  {
    path: `${routes.flightsBooking}/*`,
    element: (
      <RouteWithFeatureFlag featureFlag="hilo_shop_and_checkout">
        {(isLoggedInAndFFEnabled) =>
          isLoggedInAndFFEnabled ? (
            <RouteAuthWrapper minRole="user">
              <FlightSearchParamsProvider>
                <RouteComponents.FlightsCheckout />
              </FlightSearchParamsProvider>
            </RouteAuthWrapper>
          ) : (
            <Navigate replace to="/" />
          )
        }
      </RouteWithFeatureFlag>
    ),
  },
  {
    path: "/flights/test-page",
    element: (
      <RouteWithFeatureFlag featureFlag="hilo_shop_and_checkout">
        {(isLoggedInAndFFEnabled) =>
          isLoggedInAndFFEnabled ? (
            <RouteAuthWrapper minRole="user">
              <RouteComponents.TestPage />
            </RouteAuthWrapper>
          ) : (
            <Navigate replace to="/" />
          )
        }
      </RouteWithFeatureFlag>
    ),
  },
  {
    path: `${routes.cars}/search`,
    lazy: () => import("./pages/Cars/Search"),
  },
  {
    path: `${routes.cars}/book/`,
    element: (
      <RouteAuthWrapper minRole="user">
        <RouteComponents.CarsCheckout />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.shareOptions}/*`,
    element: (
      <RouteAuthWrapper minRole="user">
        <RouteComponents.ShareOptions />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.supportCenter}/*`,
    element: (
      <RouteAuthWrapper minRole="view_only_traveler">
        <RouteComponents.SupportCenter />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.travelCredits}/*`,
    element: (
      <RouteAuthWrapper minRole="user" customAuth={[hasTravelCreditsAllowedEnabled]}>
        <RouteComponents.TravelCredits />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.trends.base}`,
    element: (
      <RouteWithFeatureFlag featureFlag="ace-trends-3-in-1">
        {(isLoggedInAndFFEnabled) =>
          isLoggedInAndFFEnabled ? (
            <RouteAuthWrapper minRole="user" customAuth={[hasReportingAccess]}>
              <RouteComponents.Trends />
            </RouteAuthWrapper>
          ) : (
            <RouteAuthWrapper minRole="user" customAuth={[hasReportingAccess]}>
              <RouteComponents.TrendsLegacy />
            </RouteAuthWrapper>
          )
        }
      </RouteWithFeatureFlag>
    ),
    children: [
      // :tab was an optional dynamic route param in rrv5 (:tab?)
      { path: ":tab/*", element: <Outlet /> },
      { path: "*", element: <Outlet /> },
    ],
  },
  {
    path: `${routes.trips.base}`,
    element: (
      <RouteAuthWrapper minRole="view_only_traveler">
        <RouteComponents.Trips />
      </RouteAuthWrapper>
    ),
    children: [
      // :status was an optional dynamic route param in rrv5 (:status?)
      { path: ":status/*", element: <Outlet /> },
      { path: "*", element: <Outlet /> },
    ],
  },
  {
    path: `${routes.flexFAQ}/*`,
    element: (
      <RouteAuthWrapper minRole="user">
        <RouteComponents.FlexFAQ />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.personalTravel2fv}/*`,
    element: (
      <RouteAuthWrapper minRole="user" customAuth={[mustCompletePersonal2fv]}>
        <RouteComponents.PersonalTravel2FV />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.autopayFAQ}/*`,
    element: (
      <RouteAuthWrapper minRole="user">
        <RouteComponents.AutopayFAQ />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.autopaySetup}/*`,
    element: (
      <RouteAuthWrapper minRole="user">
        <RouteComponents.AutopaySetup />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.autopayCancel}/*`,
    element: (
      <RouteWithFeatureFlag featureFlag="autopay-cancel-redesign">
        {(isLoggedInAndFFENabled) =>
          isLoggedInAndFFENabled ? (
            <RouteAuthWrapper minRole="user">
              <RouteComponents.AutopayCancel />
            </RouteAuthWrapper>
          ) : (
            <RouteAuthWrapper minRole="user">
              <RouteComponents.AutopayCancelLegacy />
            </RouteAuthWrapper>
          )
        }
      </RouteWithFeatureFlag>
    ),
  },
  {
    path: `${routes.groups.base}/*`,
    element: (
      <RouteAuthWrapper minRole="user">
        <RouteComponents.Groups />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.search}/*`,
    element: (
      <RouteAuthWrapper minRole="user" customAuth={[hasRequiredSearchQueryParams]}>
        <RouteComponents.SearchResults />
      </RouteAuthWrapper>
    ),
  },
  /**
   * AmpliAuthRouteFeatureFlags Below
   */
  {
    path: `${routes.ratePartnering.base}/*`,
    element: (
      <RouteWithFeatureFlag featureFlag="rate_partnering">
        {(isLoggedInAndFFEnabled) =>
          isLoggedInAndFFEnabled ? (
            <RouteAuthWrapper minRole="coordinator">
              <RouteComponents.RatePartnering />
            </RouteAuthWrapper>
          ) : (
            <Navigate replace to="/" />
          )
        }
      </RouteWithFeatureFlag>
    ),
  },
  {
    path: `${routes.directBillFAQ}/*`,
    element: (
      <RouteWithFeatureFlag featureFlag="direct-bill-overview-redesign">
        {(isLoggedInAndFFENabled) =>
          isLoggedInAndFFENabled ? (
            <RouteAuthWrapper minRole="user">
              <RouteComponents.DirectBillFAQ />
            </RouteAuthWrapper>
          ) : (
            <RouteAuthWrapper minRole="user">
              <RouteComponents.DirectBillFAQLegacy />
            </RouteAuthWrapper>
          )
        }
      </RouteWithFeatureFlag>
    ),
  },
  {
    path: `${routes.flexPassFAQ}/*`,
    element: (
      <RouteWithFeatureFlag featureFlag="flexpass">
        {(isLoggedInAndFFEnabled) =>
          isLoggedInAndFFEnabled ? (
            <RouteAuthWrapper minRole="user">
              <RouteComponents.FlexPassFAQ />
            </RouteAuthWrapper>
          ) : (
            <Navigate replace to="/" />
          )
        }
      </RouteWithFeatureFlag>
    ),
  },
  {
    path: `${routes.incidentalsFAQ}/*`,
    element: (
      <RouteAuthWrapper minRole="user">
        <RouteComponents.IncidentalsFAQ />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.orders}/*`,
    element: (
      <RouteAuthWrapper minRole="user">
        <RouteComponents.Orders />
      </RouteAuthWrapper>
    ),
  },
  {
    path: `${routes.extendTrips}/:reservationId/*`,
    element: (
      <RouteWithFeatureFlag featureFlag="sste_multi_date">
        {(isLoggedInAndFFEnabled) =>
          isLoggedInAndFFEnabled ? (
            <RouteAuthWrapper minRole="user">
              <RouteComponents.ExtendTrips />
            </RouteAuthWrapper>
          ) : (
            <Navigate replace to="/" />
          )
        }
      </RouteWithFeatureFlag>
    ),
  },
  {
    path: `${routes.personalTravelLanding}/*`,
    element: (
      <RouteAuthWrapper minRole="user">
        <RouteComponents.PersonalAccountLanding />
      </RouteAuthWrapper>
    ),
  },
];

const default404Route: RouteObject = {
  path: "*",
  element: <Navigate to="/" />,
};

// All the normal routes, wrapped in our Providers as a Layout
const allRoutesWithProviders: RouteObject[] = [
  {
    element: <ProvidersWithinRouter />,
    children: [
      ...redirectRoutes,
      ...externalRedirectRoutes,
      ...publicRoutes,
      ...authRoutes,
      default404Route,
    ],
  },
  {
    element: <></>,
    children: [
      {
        path: "/mobile/auth0-callback",
        element: <></>,
      },
    ],
  },
];

export const router = createBrowserRouter(allRoutesWithProviders);
