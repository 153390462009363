import type { AxiosResponse } from "axios";
import type { UseMutationOptions } from "react-query";
import { useMutation } from "react-query";

import { getClient } from "@hotel-engine/client";
import type { AxiosErrorResponse } from "@hotel-engine/types/errors";
import type {
  ITwoFactorAuthenticationParams,
  ITwoFactorVerificationParams,
} from "@hotel-engine/types/twoFactorAuthentication";
import config from "config";

import { endpoints } from "../constants";
import { useApi } from "../useApi";

/**
 * This is used for requests in the personal account flow for logged-in users with only the phone in the body
 */

export const useTwoFactorVerificationCodeAuthenticatedRequest = (
  params?: { token: string },
  options?: UseMutationOptions<
    AxiosResponse<null>,
    AxiosErrorResponse,
    ITwoFactorVerificationParams
  >
) => {
  const post = useApi("post");

  return useMutation<AxiosResponse<null>, AxiosErrorResponse, ITwoFactorVerificationParams>(
    (data) =>
      post<AxiosResponse<null>>(
        `${config.apiHostV2}/${endpoints.twoFactorVerificationCodeRequest}`,
        data,
        {
          ...(params?.token && {
            headers: {
              Authorization: `Bearer ${params.token}`,
            },
          }),
        }
      ),
    options
  );
};

/**
 * This is used for requests in the join flow that will include the phone, referral token and business code in the body and will target the v1 endpoint - note that it hits v1 and /two_factor_auth/verifications, see ARC-2253
 */

export const useTwoFactorVerificationCodeUnauthenticatedRequest = (
  options?: UseMutationOptions<
    AxiosResponse<null>,
    AxiosErrorResponse,
    ITwoFactorAuthenticationParams
  >
) => {
  return useMutation<AxiosResponse<null>, AxiosErrorResponse, ITwoFactorAuthenticationParams>(
    (data) =>
      getClient(false).post(
        `${config.apiHost}/${endpoints.twoFactorVerificationUnauthenticatedRequest}`,
        data
      ),
    options
  );
};
