import { useState } from "react";

import type {
  ICardInputs,
  ISpreedlyCardInputProps,
} from "@hotel-engine/app/EditPaymentProfileForm/components/SpreedlyCardInput";
import { SpreedlyCardInput } from "@hotel-engine/app/EditPaymentProfileForm/components/SpreedlyCardInput";
import { useURLParams } from "@hotel-engine/hooks";
import { AmplitudeFormObserver } from "@hotel-engine/scripts/hooks";
import { Has, is } from "@hotel-engine/guards";

const amplitudeEventList = {
  expirationDisplay: { title: "editExpirationDate" },
};

export const AmplitudePaymentMethodWrapper = ({
  payment,
  onCardUpdate,
}: ISpreedlyCardInputProps) => {
  const [ampliValues, setAmpliValues] = useState<ICardInputs>({
    cardType: null,
  });

  const { params, search } = useURLParams();
  const sid = Has("s", is.key)(search) ? search.s : undefined;
  const rid = Has("roomRateId", is.key)(search) ? search.roomRateId : undefined;
  const pid = Has("propertyId", is.key)(params) ? params.propertyId : undefined;

  const amplitudeSubmissionObj = {
    propertyId: Number(pid),
    searchId: Number(sid),
    roomRateId: Number(rid),
  };

  const initialAmplitudeValues = {
    current: {
      cardType: null,
      expirationDateInvalid: true,
      expirationDisplay: "",
    },
  };

  return (
    <>
      <AmplitudeFormObserver
        eventList={amplitudeEventList}
        initialValues={initialAmplitudeValues}
        submissionObj={amplitudeSubmissionObj}
        values={ampliValues as ICardInputs}
      />
      <SpreedlyCardInput
        payment={payment}
        onCardUpdate={onCardUpdate}
        setAmpliValues={setAmpliValues}
        amplitudeSubmissionObj={amplitudeSubmissionObj}
      />
    </>
  );
};
