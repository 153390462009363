import type { IUser } from "@hotel-engine/types/user";

const COORDINATOR_ROLE = "coordinator";
const USER_ROLE = "user";

export const shouldCheckOverlappingContracts = (user: IUser | null) => {
  if (!user) return false;

  const {
    role,
    business: { preventCoordinatorOverlappingContracts, preventUserOverlappingContracts },
  } = user;

  return (
    (role === USER_ROLE && preventUserOverlappingContracts) ||
    (role === COORDINATOR_ROLE && preventCoordinatorOverlappingContracts)
  );
};
