import LoyaltyBadge from "@hotel-engine/assets/LoyaltyBadge";
import Avatar from "@hotel-engine/common/Avatar";
import { SelectOption } from "@hotel-engine/common/Select";
import { colors } from "@hotel-engine/styles";
import type { IOccupant, IPropertyLoyaltyRewards } from "@hotel-engine/types/booking";
import type { IUser } from "@hotel-engine/types/user";
import { getNameOrEmail } from "@hotel-engine/utilities";

import * as Styled from "../styles";
import { Icon } from "@hotel-engine/common/Icon";

export const buildUserOption = ({
  propertyLoyaltyRewards,
  roomIsLoyaltyEligible,
  suggestedOccupant,
  user,
}: {
  propertyLoyaltyRewards: IPropertyLoyaltyRewards;
  roomIsLoyaltyEligible: boolean;
  suggestedOccupant: IOccupant | IUser;
  user: IUser;
}) => {
  const { id, firstName, lastName, email, userLoyaltyRewards } = suggestedOccupant;

  const matchingLoyaltyReward =
    roomIsLoyaltyEligible &&
    userLoyaltyRewards?.find((reward) => reward.loyaltyRewardId === propertyLoyaltyRewards.id);

  const renderUserOption = () => (
    <Styled.GuestInfo adjacentElementSize="small">
      <Styled.GuestInfoRow>
        <Styled.OptionTypography
          variant="small"
          as="p"
          $margin="0 4px 0 8px"
          data-testid="guest-select-option-name"
        >
          {firstName} {lastName}
          {user?.id === id && " (you)"}
        </Styled.OptionTypography>
        {!!matchingLoyaltyReward && (
          <span data-testid="member-dropdown-loyalty-badge">
            <LoyaltyBadge
              fill={colors.black}
              style={{
                margin: "0 4px",
                verticalAlign: "middle",
                width: "12px",
                height: "12px",
              }}
            />
          </span>
        )}
      </Styled.GuestInfoRow>
      <Styled.GuestInfoRow $spaceBetween>
        <Styled.OptionTypography
          variant="body-small"
          as="p"
          $margin="0 4px 0 8px"
          $color={firstName && lastName ? colors.kiwiGrey : colors.disabledBlack}
          data-private
        >
          {email}
        </Styled.OptionTypography>
        {(!firstName || !lastName) && (
          <Styled.OptionTypography
            variant="body-small"
            as="p"
            $margin="0 4px 0 8px"
            $color={colors.kiwiGrey}
            data-private
          >
            {<Icon style={{ marginRight: "6px" }} icon={["fal", "exclamation-triangle"]} />}
            First and last name are required for all guests
          </Styled.OptionTypography>
        )}
      </Styled.GuestInfoRow>
    </Styled.GuestInfo>
  );

  return (
    <SelectOption
      key={`user-${id}`}
      value={JSON.stringify(suggestedOccupant)}
      data-testid="guest-select-option"
      disabled={!firstName || !lastName}
    >
      <Styled.SearchGuestOption>
        <Avatar
          size="small"
          name={getNameOrEmail(suggestedOccupant)}
          backgroundColor={colors.black}
        />
        {renderUserOption()}
      </Styled.SearchGuestOption>
    </SelectOption>
  );
};
