import { routes } from "@hotel-engine/constants";

import type { AuthValidatorFn } from "./validateCustomAuth";

/**
 * Returns true for all business accounts, or personal travel accounts that are associated with a business account.
 *
 * If the user is logged in on a personal account, determine if it has completed email confirmation & 2FV, and whether or not it's on the 2FV page
 */

export const validatePersonalAccount: AuthValidatorFn = (user) => {
  if (user.accountType !== "personal") {
    return true;
  } else if (user.accountType === "personal" && Boolean(user.businessTravelUserId)) return true;

  const personalRoutes = [routes.personalTravel2fv, routes.personalTravelUnconfirmed] as string[];

  if (personalRoutes.includes(globalThis.location.pathname)) {
    return true;
  }

  return !!user.confirmedAt && !!user.phoneVerified;
};
