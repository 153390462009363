import { useTranslation } from "react-i18next";

export const useAirportInfo = () => {
  const { t } = useTranslation("iata_airport");

  return {
    getAirportNameFromIataAirportCode: (iataAirportKey: string) =>
      t(`iata_airport.name.${iataAirportKey}`, { defaultValue: iataAirportKey }),
    getIataCityCodeByIataAirportCode: (iataAirportKey: string) =>
      t(`iata_airport.iata_city_code.${iataAirportKey}`, { defaultValue: iataAirportKey }),
    getIataCountryCodeByIataAirportCode: (iataAirportKey: string) =>
      t(`iata_airport.iata_country_code.${iataAirportKey}`, { defaultValue: iataAirportKey }),
  };
};
