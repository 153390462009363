import { Modal as antdModal } from "antd";
import type { ModalFuncProps as antdModalFuncProps } from "antd/lib/modal";

import * as Styled from "./styles";
import { GlobalModalStyles } from "./styles";

export type ModalFuncProps = antdModalFuncProps;

const returnModalStyles = (obj: ModalFuncProps) => {
  return {
    ...obj,
    title: (
      <>
        {obj.title}
        <GlobalModalStyles />
      </>
    ),
  };
};

const functionalModals = {
  confirm: (obj: ModalFuncProps) => antdModal.confirm(returnModalStyles(obj)),
  destroyAll: () => antdModal.destroyAll(),
  error: (obj: ModalFuncProps) => antdModal.error(returnModalStyles(obj)),
  info: (obj: ModalFuncProps) => antdModal.info(returnModalStyles(obj)),
  success: (obj: ModalFuncProps) => antdModal.success(returnModalStyles(obj)),
  warn: (obj: ModalFuncProps) => antdModal.warn(returnModalStyles(obj)),
  warning: (obj: ModalFuncProps) => antdModal.warning(returnModalStyles(obj)),
};

const ModalComponent = Styled.Modal;

export const Modal = Object.assign(ModalComponent, functionalModals);
