import type { UseMutationOptions } from "react-query";
import { useMutation } from "react-query";

import { usePostCheckoutActions } from "@hotel-engine/contexts";
import { endpoints } from "@hotel-engine/react-query/constants";
import { useApi } from "@hotel-engine/react-query/useApi";
import type {
  ICheckoutVerification,
  ICheckoutVerificationCreateParams,
} from "@hotel-engine/types/notifications";
import type { IErrorResponse } from "@hotel-engine/types/errors";

export const useCreateCheckoutVerificationMutation = (
  options?: UseMutationOptions<
    ICheckoutVerification,
    IErrorResponse,
    ICheckoutVerificationCreateParams
  >
) => {
  const put = useApi("put");

  // Post checkout actions context
  const { dispatch: dispatchPostCheckoutActions } = usePostCheckoutActions();

  return useMutation(
    (params) => {
      return put(`${endpoints.checkoutVerification}/${params.id}?check_out_date=${params.date}`);
    },
    {
      onSuccess: () => {
        dispatchPostCheckoutActions({
          type: "UPDATE_STATUS",
          payload: "isCheckoutVerificationSuccess",
        });
      },
      ...options,
    }
  );
};
