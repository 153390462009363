import { createSlice } from "@reduxjs/toolkit";
import { buildSetter } from "./helpers";
import type { ISearchPageState } from "./types";
import { ViewType } from "./constants";

export const initialState: ISearchPageState = {
  showEditSearchForm: false,
  showHeader: true,
  listHighlightedResultId: null,
  selectedResultId: null,
  markerClicked: false,
  mapExpanded: false,
  specificSearchDismissed: false,
  helpersMenuVisible: true,
  showingCurrentMapArea: false,
  viewType: ViewType.Combined,
  showLoadingBar: true,
};

export const searchPageSlice = createSlice({
  name: "SearchPage",
  initialState,
  reducers: {
    resetState: () => initialState,
    setHelpersMenuVisible: buildSetter<boolean>("helpersMenuVisible"),
    setListHighlightedResultId: buildSetter<number | null>("listHighlightedResultId"),
    setMapExpanded: buildSetter<boolean>("mapExpanded"),
    setMarkerClicked: buildSetter<boolean>("markerClicked"),
    setSelectedResultId: buildSetter<number | null>("selectedResultId"),
    setShowEditSearchForm: buildSetter<boolean>("showEditSearchForm"),
    setShowHeader: buildSetter<boolean>("showHeader"),
    setShowingCurrentMapArea: buildSetter<boolean>("showingCurrentMapArea"),
    setSpecificSearchDismissed: buildSetter<boolean>("specificSearchDismissed"),
    setViewType: buildSetter<ViewType>("viewType"),
    setShowLoadingBar: buildSetter<boolean>("showLoadingBar"),
  },
});

export const searchPageActions = searchPageSlice.actions;

export default searchPageSlice.reducer;
