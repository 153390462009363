import { faCircleInfo, faShieldCheck } from "@fortawesome/pro-solid-svg-icons";
import { Icon } from "@hotel-engine/common/Icon";
import Tag from "@hotel-engine/common/Tag";
import { pxToRem } from "@hotel-engine/styles/helpers/mixins";
import styled from "styled-components";

export const AdditionalServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(16)};

  padding: ${pxToRem(16)} ${pxToRem(24)};
  border-top: 4px solid ${({ theme }) => theme.legacy.colors.grey[50]};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: ${pxToRem(16)};
  }
`;

export const RequiredFieldTagContainer = styled.div`
  grid-area: company;
  grid-column: span 3;
`;

export const RequiredFieldTag = styled(Tag)<{
  $tagType: "flex" | "incidentals";
}>`
  border-radius: 100px /* disable theming (do not replicate) */;
  background-color: ${({ $tagType, theme }) => {
    const baseColor = $tagType === "flex" ? "green" : "grey";
    return theme.legacy.colors[baseColor][50];
  }};
  color: ${({ $tagType, theme }) => {
    return $tagType === "flex" ? theme.colors.accentGreenStrong : theme.colors.accentGrayStronger;
  }};
  border-color: ${({ $tagType, theme }) => {
    return $tagType === "flex" ? theme.colors.accentGreenLighter : theme.colors.accentGrayLighter;
  }};
  font-size: ${pxToRem(14)};
  font-weight: 400;
  padding: 4px 8px;

  margin-left: 0;
  margin-bottom: 8px;
`;

export const FlexProBanner = styled.label`
  min-height: 44px;
  width: 100%;
  padding: ${pxToRem(14)} ${pxToRem(16)};
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xl};
  background-color: ${({ theme }) => theme.legacy.colors.green[50]};
  color: ${({ theme }) => theme.legacy.colors.grey[800]};

  font-size: ${pxToRem(14)};
  line-height: 125%;
  letter-spacing: ${pxToRem(0.25)};
  font-weight: 400;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  img {
    height: 18px;
  }
`;

export const AddFlexBanner = styled.label`
  min-height: 44px;
  width: 100%;
  padding: ${pxToRem(14)} ${pxToRem(16)};
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xl};
  background-color: ${({ theme }) => theme.legacy.colors.green[50]};
  color: ${({ theme }) => theme.legacy.colors.grey[800]};

  font-size: ${pxToRem(14)};
  line-height: 125%;
  letter-spacing: ${pxToRem(0.25)};
  font-weight: 600;

  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    "company company company"
    "check text price";
`;

export const AddFlexBannerLabelText = styled.p`
  grid-area: text;

  margin: 0;
  margin-left: ${pxToRem(16)};

  color: ${({ theme }) => theme.legacy.colors.green[500]};

  display: flex;
  gap: ${pxToRem(8)};
  align-items: center;

  flex: 3;
`;

export const FlexIcon = styled(Icon).attrs(() => ({
  icon: faShieldCheck,
}))`
  position: relative;
  font-size: ${pxToRem(16)};
  color: ${({ theme }) => theme.legacy.colors.green[500]};
`;

export const AddFlexBannerPricing = styled.p`
  font-weight: 600;
  text-align: right;

  grid-area: price;
`;

export const AddFlexBannerPreDiscountPricing = styled.span`
  color: ${({ theme }) => theme.legacy.colors.grey[500]};
  font-weight: 400;
  line-height: 144%;
  text-decoration: line-through;

  margin-right: ${pxToRem(8)};
`;

export const IncidentalsBanner = styled.label`
  min-height: 44px;
  width: 100%;
  padding: ${pxToRem(14)} ${pxToRem(16)};
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xl};
  background-color: ${({ theme }) => theme.legacy.colors.grey[50]};
  color: ${({ theme }) => theme.legacy.colors.grey[800]};

  font-size: ${pxToRem(14)};
  line-height: 125%;
  letter-spacing: ${pxToRem(0.25)};
  font-weight: 600;

  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    "company company company"
    "check text price";
`;

export const IncidentalsDisabledIcon = styled(Icon).attrs(() => ({
  icon: faCircleInfo,
}))`
  position: relative;
  top: -7px;
  font-size: ${pxToRem(16)};
  color: ${({ theme }) => theme.legacy.colors.grey[800]};

  grid-area: check;
`;

export const IncidentalsBannerLabelText = styled.p<{
  $useDisabledStyles?: boolean;
}>`
  margin: 0;
  margin-left: ${pxToRem(16)};

  display: flex;
  gap: ${pxToRem(8)};
  align-items: center;

  grid-area: text;

  font-weight: ${({ $useDisabledStyles }) => ($useDisabledStyles ? 400 : 600)};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    gap: 0;
  }
`;

export const IncidentalsBannerPricing = styled.p`
  font-weight: 600;
  text-align: right;

  grid-area: price;
`;
