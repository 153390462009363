import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import type { IOption, ISearch, IShareOptions } from "@hotel-engine/types/search";

export const initialState: IShareOptions = {
  options: [],
  search: {
    checkIn: null,
    checkOut: null,
    displayCheckIn: null,
    displayCheckOut: null,
    guestCount: null,
    latitude: null,
    loadingTime: null,
    location: null,
    locationId: null,
    longitude: null,
    roomCount: null,
    searchId: null,
    types: null,
  },
};

export const shareSlice = createSlice({
  name: "ShareOptions",
  initialState,
  reducers: {
    setSearchDetails: (state, action: PayloadAction<ISearch>) => {
      Object.assign(state, { options: [], search: action.payload });
    },
    addOption: (state, action: PayloadAction<IOption>) => {
      if (state.options.findIndex((o) => o.searchRateId === action.payload.searchRateId) !== -1) {
        return state;
      }

      return {
        ...state,
        options: [...state.options, action.payload],
      };
    },
    updateOption: (state, action: PayloadAction<{ index: number; option: IOption }>) => {
      const updatedOptions = state.options.map((o, i) => {
        if (i !== action.payload.index) {
          return o;
        }

        return {
          ...o,
          ...action.payload.option,
        };
      });

      return {
        ...state,
        options: updatedOptions,
      };
    },
    removeOption: (state, action: PayloadAction<IOption["searchRateId"]>) => {
      const index = state.options.findIndex((o) => o.searchRateId === action.payload);

      if (index === -1) {
        return state;
      }

      return {
        ...state,
        options: [...state.options.slice(0, index), ...state.options.slice(index + 1)],
      };
    },
    clearOptions: (state) => ({
      ...state,
      options: [],
    }),
    resetShareOption: () => initialState,
  },
});

export const ShareOptionsActions = shareSlice.actions;

export default shareSlice.reducer;
