/* formats a query string to be utilized in conjunction with the search property of useNavigate
 useNavigate's search property handles the encoding
 this method will only add keys that have a value
*/

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatUrl(objToFormat: Record<string, any>) {
  // string that will contain all query params that exist
  let queryString = "?";
  // loop through object
  Object.keys(objToFormat).forEach((key) => {
    // check if key has a value
    if (objToFormat[key]) {
      // variable to determine if we should precede query variable with &sign
      const shouldAddAnd = queryString.length > 2;
      // conditionally add & sign (otherwise the first one with a value might have an & sign)
      queryString += shouldAddAnd ? "&" : "";
      // add the query param to the key... e.g. `location=${location}`
      queryString += `${key}=${objToFormat[key]}`;
    }
  });

  // return final string
  return encodeURI(queryString);
}
