import type { IOverlappingContract } from "@hotel-engine/types/contractRate";
import type { IPropertyLegacy } from "./property";
import type { IRoom, IRoomRate } from "./room";
import type { ICheckoutGuest, ICustomFieldFormData } from "pages/Checkout/validation";
import type { IUserLoyaltyProgram } from "./user";
import type { Moment } from "moment";

export interface IExpressBookPropertyRef {
  propertyId: number;
  name: string | null;
  thumbnailUrl?: string | null;
  street: string;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  searchId?: number;
}

export interface IExpressBookContractDataResponse {
  priceChange: "increase" | "decrease" | "none";
  contractRate: IExpressBookContractRate;
  paymentProfiles: IExpressBookPaymentProfile[];
  property: IPropertyLegacy;
  roomRate: IRoomRate;
  room: IRoom;
}

// TODO: Re-sync this with `IContractRate`, they're probably the same (some props are listed in hydra but not members)
export interface IExpressBookContractRate {
  cancelBy?: string;
  cancellationPolicy: string;
  checkInInstructions: string | null;
  convenienceFee: number;
  currency: string;
  doubleRewards: boolean;
  description: string;
  flexCancellationCost: number;
  recentFlexEnabledBookingsCount: number;
  recentFlexEnabledBookingsPeriodInDays: number;
  hotelCollect: boolean;
  id: string;
  incidentalsFee: number;
  loyaltyEligible: boolean;
  mandatoryFeeAmount: number;
  mandatoryFeeDescription: string | null;
  overlappingContracts: IOverlappingContract[];
  propertyId: number;
  publicFee: number;
  rate: number;
  roomRateId: number;
  subtotal: number;
  supplier: string;
  tax: number;
  total: number;
  memberSavingsTotal: number;
  totalWithIncidentals: number;
  prepaidFees: number;
  postpaidFees: number;
  postpaidFeesDescription: string | null;
}

export interface IExpressBookPaymentProfile {
  id: number;
  cardNumber: string;
  default: boolean;
  last4: string;
  isExpired: boolean;
  name: string;
  nickname: string | null;
  billingStreet: string | null;
  billingStreet2: string | null;
  billingCity: string | null;
  billingRegion: string | null;
  billingPostalCode: string;
  billingCountry: string | null;
  businessId: string | null;
  spreedlyFingerprint: string;
  expirationMonth: string;
  expirationYear: string;
  type: spreedly.SpreedlyCardType;
}

export enum ExpressBookModalStep {
  BASE_STATE,
  SUCCESS_STATE,
  OVERLAPPING_STATE,
  ERROR_STATE, // TODO: This may need to be split out into several other error states if needed
}

// TODO: Continue adding to this over the course of FEN-5336
export interface IExpressBookCheckoutForm {
  isFlexEnabled: boolean;
  isFlexProEnrolled: boolean;
  includeIncidentalCharges: boolean;
  selectedPaymentId?: number;
  travelCredits: 0;
  rewardsPoints: 0;
  duplicateOverride?: boolean;
  customFields: ICustomFieldFormData[];
  isBillable?: boolean;
  directBillCode: string;
  guestList: {
    primary: ICheckoutGuest;
    secondary: ICheckoutGuest;
    loyaltyInfo: IUserLoyaltyProgram | null;
    overlappingContracts?: IOverlappingContract[];
  }[];
}

export interface IExpressBookDatesForm {
  checkin: Moment | null;
  checkout: Moment | null;
  mobileIntermediateCheckin: Moment | null;
  mobileIntermediateCheckout: Moment | null;
}
