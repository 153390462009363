import type { UseQueryOptions } from "react-query";
import type { IErrorResponse } from "@hotel-engine/types/errors";
import { endpoints } from "../constants";
import { useApi } from "../useApi";
import { useExtendedQuery } from "../useExtendedQuery";
import type { IFlexProPricing } from "@hotel-engine/types/flexPro";

export const useFlexProPricingQuery = (
  options?: UseQueryOptions<IFlexProPricing, IErrorResponse>
) => {
  const get = useApi("get");
  return useExtendedQuery(
    endpoints.flexProPricing,
    () => get<IFlexProPricing>(endpoints.flexProPricing),
    { ...options }
  );
};
