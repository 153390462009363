import type { UseMutationOptions } from "react-query";
import { useMutation, useQueryClient } from "react-query";

import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { IUserLoyaltyProgram } from "@hotel-engine/types/user";

import { useApi } from "../useApi";
import { endpoints } from "../constants";
import { Unsafe } from "@hotel-engine/data";

export const useUserLoyaltyRewardsCreate = (
  options?: UseMutationOptions<IUserLoyaltyProgram, IErrorResponse, IUserLoyaltyProgram>
) => {
  const queryClient = useQueryClient();
  const post = useApi("post");

  return useMutation<IUserLoyaltyProgram, IErrorResponse, IUserLoyaltyProgram>(
    (data) => post(endpoints.userLoyaltyRewards, data),
    {
      ...options,
      onSuccess: () => {
        queryClient
          .invalidateQueries(endpoints.userLoyaltyRewards)
          .then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
      },
    }
  );
};
