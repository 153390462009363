import type { UseQueryOptions } from "react-query";

import type { IAchPaymentProfile } from "@hotel-engine/types/achPaymentProfile";
import type { IErrorResponse } from "@hotel-engine/types/errors";

import { useApi } from "../useApi";
import { useExtendedQuery } from "../useExtendedQuery";
import { endpoints } from "../constants";

/**
 *
 * @param businessId the businessId being queried.
 * @param options UseQueryOptions
 * @returns a useExtendedQueryHook
 */
export const useAchPaymentProfileQuery = (
  businessId: number,
  options?: UseQueryOptions<IAchPaymentProfile[], IErrorResponse, IAchPaymentProfile[]>
) => {
  const get = useApi("get");

  return useExtendedQuery<IAchPaymentProfile[], IErrorResponse>(
    [endpoints.achPaymentProfiles, businessId],
    () => get<IAchPaymentProfile[]>(endpoints.achPaymentProfiles, { businessId }),
    options
  );
};
