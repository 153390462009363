import config from "config";
import { useAppSelector } from "store/hooks";

import { useContractRoomData } from "./useContractRoomData";
import type { IRoomRate } from "@hotel-engine/types/room";

export const useRoomIsLoyaltyEligible = () => {
  const { room } = useContractRoomData();

  const user = useAppSelector((state) => state.Auth.user);

  return (
    config.loyaltyEligibleEnabled === "true" &&
    !!user?.business.loyaltyRewardsEnabled &&
    !!room?.loyaltyEligible
  );
};

export const useIsLoyaltyEligibleExpressBook = (room: IRoomRate) => {
  const user = useAppSelector((state) => state.Auth.user);

  return (
    config.loyaltyEligibleEnabled === "true" &&
    !!user?.business.loyaltyRewardsEnabled &&
    room.loyaltyEligible
  );
};
