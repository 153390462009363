import type { IBaseHEIconProps } from "./index";

const MapMarker = ({ height = "15", width = "11" }: IBaseHEIconProps) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 11 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="1.-LANDING" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="HE_Component_SearchResults_@2x" transform="translate(-482.000000, -93.000000)">
          <g id="Group-4" transform="translate(480.000000, 87.000000)">
            <g id="Pin" transform="translate(0.000000, 6.000000)">
              <rect id="Rectangle" x="0" y="0" width="15" height="15" />
              <path
                fill="currentColor"
                d="M11.389893,1.53870738 C10.3510157,0.54650547 8.96967021,0 7.50055654,0 C6.03115605,0 4.65009742,0.54650547 3.61122009,1.53870738 C1.68863733,3.37463703 1.44971275,6.8289899 3.09378919,8.921873 L7.50055654,15 L11.9007269,8.93036507 C13.5514003,6.8289899 13.3124758,3.37463703 11.389893,1.53870738 Z M7.55132443,7.12237705 C6.44446975,7.12237705 5.54355487,6.26193911 5.54355487,5.204814 C5.54355487,4.14768888 6.44446975,3.28725095 7.55132443,3.28725095 C8.6581791,3.28725095 9.55909398,4.14768888 9.55909398,5.204814 C9.55909398,6.26193911 8.6581791,7.12237705 7.55132443,7.12237705 Z"
                id="Result_Icon"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MapMarker;
