/**
 * These keys are standardized throughout the stack to help associate logs
 * in different applications with one another.
 * Request ID: Identifier for a request between two applications, such as Members and FSS
 * Session ID: Identifier used for grouping multiple logs and requests together.
 *             This is synonymous with the search ID.
 * Trace ID: Identifier for a request that is passed for all downstream logs and requests.
 */
export enum DataDogTracingAttributes {
  BusinessId = "io-htleng-business-id",
  RequestId = "io-htleng-request-id",
  SessionId = "io-htleng-session-id",
  TraceId = "io-htleng-trace-id",
  UserId = "io-htleng-user-id",
}

export enum FssRequestHeaders {
  RequestId = "x-hotelengine-fss-request-id",
  SearchId = "x-hotelengine-fss-search-id",
  TraceId = "x-hotelengine-fss-trace-id",
}
