import { sizes } from "../index";

/**
 * @deprecated Use a regular ol' media query instead of this function. The template string for this function doesnt not support
 * props and prettier does not format the code inside the template string
 * @example
 * ```
 * @media screen and (min-width: ${sizes.breakpoints.xl}) { ... }
 * ```
 * @param key breakpoint size
 * @param range type of media query (min or max)
 */
export const mediaQuery = (key: keyof typeof sizes.breakpoints, range: "min" | "max" = "min") => {
  return (style: TemplateStringsArray | string) =>
    `@media (${range}-width: ${sizes.breakpoints[key]}) { ${style} }`;
};
