import styled, { css } from "styled-components";

import { colors } from "@hotel-engine/styles";

import type { ILoader } from "./";

const baseIconSize = 16;
const baseCopySize = 14;

export const Loader = styled.span<ILoader>`
  display: inline-block;

  ${({ color = "grey" }) =>
    (color === "grey" &&
      css`
        color: ${colors.errieBlack};
      `) ||
    (color === "white" &&
      css`
        color: ${colors.white};
      `)}

  ${({ size = "default" }) =>
    (size === "small" &&
      css`
        font-size: ${baseIconSize}px;
      `) ||
    (size === "default" &&
      css`
        font-size: ${baseIconSize * 1.5}px; // 24px
      `) ||
    (size === "large" &&
      css`
        font-size: ${baseIconSize * 2.5}px; // 40px
      `)}

  ${({ type = "inline" }) =>
    (type === "pill" &&
      css`
        border-radius: 100px /* disable theming (do not replicate) */;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
        padding: ${`${baseIconSize / 2}px`};
        > div {
          @include flexbox($alignItems: center);
        }
        i {
          margin: -0.115em 0 0;
          align-self: baseline;
        }
      `) ||
    (type === "stacked" &&
      css`
        i {
          display: block; // Removes extra space taken up by the animation origins
          margin: 0 auto 0.15em; // Centers and compensates for default the vertical-align attribute
        }
      `)}

  .ant-spin,
  .anticon-loading {
    color: inherit;
    font-size: inherit;
    line-height: inherit;

    &.ant-spin-show-text {
      .ant-spin-text {
        display: inline-block;
      }
    }

    .ant-spin-text {
      font-size: ${baseCopySize}px;
      line-height: 1.15;
      margin-top: ${baseIconSize / 2}px;
    }

    .loader-stacked & {
      .ant-spin {
        &.ant-spin-show-text {
          .ant-spin-text {
            display: block;
            text-align: center;
            margin: ${baseIconSize}px 0 0 0;
          }
        }
      }
    }
  }
`;
