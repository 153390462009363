import styled from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";

export const BottomSheetContainer = styled.div`
  padding: 16px;
  height: 80vh;
`;

export const ModalContent = styled.main`
  height: 100%;
  margin-top: 16px;
  ${flexbox({ direction: "column", alignItems: "flex-start" })};
  ${mediaQuery(
    "md",
    "max"
  )(`
    .fixed-submit-btn {
      flex-grow: 1;

      form {
        flex-grow: 1;

        button[type="submit"] {
          margin: auto 0 80px;
        }
      }
    }
  `)}
  ${mediaQuery("lg")(`
    flex: 1 1 auto;
    height: auto;
  `)}
  .form-container {
    ${flexbox({ direction: "column", alignItems: "flex-start" })};
    width: 100%;
    height: 100%;
    overflow-y: auto;
    ${mediaQuery("lg")(`
      padding: 0;
    `)}

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  .form-title {
    width: 100%;
    ${flexbox({ direction: "column", alignItems: "flex-start" })};
    margin-bottom: 24px;
    text-align: left;
  }
`;
