import type { UseQueryOptions } from "react-query";

import { endpoints } from "@hotel-engine/react-query/constants";
import { useApi } from "@hotel-engine/react-query/useApi";
import { useExtendedQuery } from "@hotel-engine/react-query/useExtendedQuery";
import type { IAuthStrategy } from "@hotel-engine/types/authStrategy";
import type { IErrorResponse } from "@hotel-engine/types/errors";

export const useAuthStrategiesQuery = (
  email: string | null | undefined,
  options?: UseQueryOptions<IAuthStrategy[], IErrorResponse>
) => {
  const get = useApi("get");
  const encodedEmail = encodeURIComponent(email || "");
  const endpoint = `${endpoints.authStrategies}/lookup?email=${encodedEmail}`;

  return useExtendedQuery(endpoint, () => get<IAuthStrategy[]>(endpoint), options);
};
