import type { Moment } from "moment";
import {
  clearTokenExpirationInStorage,
  getTokenExpirationFromStorageOrNull,
  setTokenExpirationInStorage,
} from "./sessionTokenExpirationStorage";
import { fetchSessionExpirationTimeQuery } from "@hotel-engine/react-query/userActive/useUserIsActiveQuery";
import moment from "moment";
import { captureMessage } from "@hotel-engine/utilities/logger";

/**
 * Given some date & time (Moment), find the most recent occurrence of 3AM
 * in local time.
 *
 * Examples:
 *  - 5:00am -> 3:00am same day
 *  - 2:55am -> 3:00am previous day
 *  - 9:00pm -> 3:00am same day
 */
export function setMomentToMostRecent3AMLocalTime(date: Moment): Moment {
  // By default, moment() parses and displays in local time
  const threeAM = date.clone().startOf("day").add(3, "hours");

  return date.isBefore(threeAM) ? threeAM.subtract(1, "day") : threeAM;
}

/**
 * Checks if the user's MODIFIED token expiration time has passed.
 * Will fetch the expiration time from the api if `forceRefetch` is
 * passed, or if a previous value is not found in local storage.
 * If it has to fetch it from the api, then it will modify the time
 * before storing in local storage.
 *
 * @param forceRefetch Pass `true` to ignore the value cached in
 * local storage and definitely refetch the token's expiration
 * time from the API.
 *
 * Returns `true` if the user's JWT token has already expired.
 * Returns `false` if not, or if an error occurs.
 */
export async function isUsersJWTExpired(forceRefetch: boolean): Promise<boolean> {
  try {
    // Start by checking if there's already an expiration time in local storage
    let expirationTimeMoment: Moment | null = getTokenExpirationFromStorageOrNull();

    // If there was no expiration time in local storage, then fetch
    // a new one from the API and store it in local storage
    if (forceRefetch || !expirationTimeMoment) {
      // Before fetching the expiration time from the API, we want
      // to clear the old one from local storage. This is in case
      // this upcoming API request fails.
      clearTokenExpirationInStorage();

      const expiryTimeInSeconds: number | null = await fetchSessionExpirationTimeQuery();

      if (typeof expiryTimeInSeconds === "number") {
        const expiryTimeMomentMaybeInvalid = moment(expiryTimeInSeconds * 1000);

        if (expiryTimeMomentMaybeInvalid.isValid()) {
          // Modify the token's expiration time to be sooner
          expirationTimeMoment = setMomentToMostRecent3AMLocalTime(expiryTimeMomentMaybeInvalid);
          setTokenExpirationInStorage(expirationTimeMoment.toISOString());
        }
      }
    }

    // If the token's modified expiration time has passed
    if (expirationTimeMoment && expirationTimeMoment.isBefore(moment())) {
      return true;
    }
  } catch (error) {
    // If something were to cause an error, then let's start from
    // a clean slate the next time the interval runs.
    clearTokenExpirationInStorage();
    captureMessage("Exception when trying to fetch and process token expiration date.", { error });
  }

  return false;
}
