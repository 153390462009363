import { CourierProvider } from "@trycourier/react-provider";

import storage from "@hotel-engine/storage";
import config from "config";
import { useAppSelector } from "store/hooks";

const NotificationsProvider = ({ children }) => {
  const user = useAppSelector((state) => state.Auth.user);

  if (!user?.id || !user?.courierHmac) return children;

  return (
    <CourierProvider
      clientKey={config.courierClientKey}
      userId={String(user.id)}
      userSignature={user.courierHmac}
      localStorage={storage as Storage}
    >
      {children}
    </CourierProvider>
  );
};

export { NotificationsProvider };
