import React from "react";

export const isReactNode = (node: unknown): node is React.ReactNode => {
  return (
    React.isValidElement(node) ||
    typeof node === "string" ||
    typeof node === "number" ||
    Array.isArray(node)
  );
};
