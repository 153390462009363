import type { IOption } from "@hotel-engine/types/search";
import { Typography, Tooltip } from "@hotelengine/atlas-web";
import { Link } from "@hotel-engine/lib/react-router-dom";

import * as Styled from "./styles";

const SelectedProperties = ({ options, showSelectedProperties }: ISelectedPropertiesProps) => {
  if (!showSelectedProperties) return null;

  return (
    <Tooltip content="Review your selected hotels here">
      <Link to="/share-options" data-testid="selected-properties">
        <Styled.Button color="accent" size="sm">
          <Styled.IconAdornment name="share" size="sm" />
          <Styled.Pill className="count">
            <Typography variant="body/2xs" color="foregroundPrimary">
              {options.length}
            </Typography>
          </Styled.Pill>
        </Styled.Button>
      </Link>
    </Tooltip>
  );
};

export default SelectedProperties;

//
// ─── INTERFACES ─────────────────────────────────────────────────────────────────
//

export interface ISelectedPropertiesProps {
  options: IOption[];
  showSelectedProperties: boolean;
}
