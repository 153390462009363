import { useCallback, Fragment } from "react";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import { Icon, Box, Typography } from "@hotelengine/atlas-web";

import { User } from "@hotel-engine/services";
import { useAppDispatch } from "store/hooks";
import { useUser } from "@hotel-engine/hooks";
import { AuthActions } from "store/Auth/AuthRedux";
import { UserPrefsActions } from "store/UserPrefs/UserPrefsRedux";
import { handleSignOut } from "../../../helpers";
import { InternalUserSettings } from "../InternalUserSettings";
import { getUserNavLinks, hePerkBusinessId } from "../../../constants";

import type { KeyboardEvent } from "react";
import type { IHeaderFooterProps } from "../types";
import type { ITokenMode } from "@hotel-engine/react-query/users/useAccountMode";

import {
  DropdownFooter,
  StyledDropdownItem,
  HeaderCTAWrapper,
  Ul,
  Li,
  HeaderCTAItem,
  SignOutButton,
  AccountSwitchButton,
} from "./styles";

interface IAccountSwitcher {
  onPersonalAccountCreation: () => void;
  onToggleBizPersonalAccount: () => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  canCreatePersonalAccount: boolean;
  showSwitch: boolean;
  isMobile?: boolean;
  userTokenMode: ITokenMode;
}

const AccountSwitcher = ({
  onPersonalAccountCreation,
  onToggleBizPersonalAccount,
  canCreatePersonalAccount,
  showSwitch,
  userTokenMode,
  onKeyDown,
  isMobile,
}: IAccountSwitcher) => {
  const copy = `Switch to ${userTokenMode === "personal" ? "business" : "personal"}`;
  const onClick = canCreatePersonalAccount ? onPersonalAccountCreation : onToggleBizPersonalAccount;
  if (canCreatePersonalAccount || showSwitch) {
    if (isMobile) {
      return (
        <div>
          <AccountSwitchButton onClick={onClick} color="secondary" variant="filled">
            {copy}
          </AccountSwitchButton>
        </div>
      );
    }

    return (
      <HeaderCTAItem onKeyDown={onKeyDown}>
        <AccountSwitchButton onClick={onClick} color="secondary" variant="filled">
          {copy}
        </AccountSwitchButton>
      </HeaderCTAItem>
    );
  }

  return null;
};

export const HeaderFooter = ({
  onToggleBizPersonalAccount,
  userTokenMode,
  shouldShowMyProperties,
  shouldShowReferAFriend,
  shouldShowTravelCredits,
  showSwitch,
  onInviteTraveler,
  shouldShowRewards,
  onPersonalAccountCreation,
  isMobile,
  canCreatePersonalAccount,
}: IHeaderFooterProps) => {
  const dispatch = useAppDispatch();
  const user = useUser();
  const navigate = useNavigate();
  const currentUser = new User(user);
  const hasCoordinatorAccess = currentUser.hasRole("coordinator");

  const signOut = useCallback(() => dispatch(AuthActions.signOut()), [dispatch]);

  const onToggleBizPersonalAccountKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === "Enter") {
        if (canCreatePersonalAccount) {
          onPersonalAccountCreation();
        } else {
          onToggleBizPersonalAccount();
        }
      }
    },
    [onToggleBizPersonalAccount, canCreatePersonalAccount, onPersonalAccountCreation]
  );

  const onSignOutClick = useCallback(async () => {
    await handleSignOut(navigate, signOut);
    dispatch(UserPrefsActions.clearUserPrefs());
  }, [dispatch, navigate, signOut]);

  const onSignOutKeyDown = useCallback(
    async (e: KeyboardEvent) => {
      if (e.code === "Enter") {
        await onSignOutClick();
      }
    },
    [onSignOutClick]
  );

  const { business } = user;
  const isInviteTravelerEnabled =
    hasCoordinatorAccess && (business.id === hePerkBusinessId || !business.isInternal);

  const userNavLinks = getUserNavLinks({
    navigate,
    shouldShowReferAFriend,
    shouldShowMyProperties,
    shouldShowRewards,
    shouldShowTravelCredits,
    onInviteTraveler,
    isInviteTravelerEnabled,
  });

  if (!isMobile) {
    return (
      <DropdownFooter>
        {userNavLinks.map(({ label, onClick, icon, shouldShow }) => (
          <Fragment key={label}>
            {!!shouldShow && (
              <>
                <StyledDropdownItem onClick={() => onClick(user)} trailingIcon={icon}>
                  <Typography variant="body/md">{label}</Typography>
                </StyledDropdownItem>
              </>
            )}
          </Fragment>
        ))}
        <HeaderCTAWrapper>
          <AccountSwitcher
            onPersonalAccountCreation={onPersonalAccountCreation}
            onToggleBizPersonalAccount={onToggleBizPersonalAccount}
            onKeyDown={onToggleBizPersonalAccountKeyDown}
            canCreatePersonalAccount={canCreatePersonalAccount}
            userTokenMode={userTokenMode}
            showSwitch={showSwitch}
          />
          <HeaderCTAItem
            onKeyDown={onSignOutKeyDown}
            style={{ flexBasis: !showSwitch ? "100%" : undefined }}
          >
            <SignOutButton onClick={onSignOutClick} variant="plain" color="everlight">
              Sign out
            </SignOutButton>
          </HeaderCTAItem>
        </HeaderCTAWrapper>
        <InternalUserSettings isInternalUser={user.business.isInternal} />
      </DropdownFooter>
    );
  }

  return (
    <Box>
      <Ul>
        {userNavLinks.map(({ label, onClick, icon, shouldShow }) => (
          <Fragment key={label}>
            {!!shouldShow && (
              <Li>
                <Box onClick={() => onClick(user)} display="flex" justifyContent="space-between">
                  <Box flexDirection="row" display="flex" alignItems="center" gap={16}>
                    <Typography variant="body/md" color="foregroundInverse">
                      {label}
                    </Typography>
                  </Box>
                  <Icon name={icon} />
                </Box>
              </Li>
            )}
          </Fragment>
        ))}
      </Ul>
      <HeaderCTAWrapper>
        <AccountSwitcher
          onPersonalAccountCreation={onPersonalAccountCreation}
          onToggleBizPersonalAccount={onToggleBizPersonalAccount}
          canCreatePersonalAccount={canCreatePersonalAccount}
          userTokenMode={userTokenMode}
          showSwitch={showSwitch}
          isMobile
        />
        <div style={{ flexBasis: !showSwitch ? "100%" : undefined }}>
          <SignOutButton onClick={onSignOutClick} variant="plain" color="everlight">
            Sign out
          </SignOutButton>
        </div>
      </HeaderCTAWrapper>
      <InternalUserSettings isMobile={isMobile} isInternalUser={user.business.isInternal} />
    </Box>
  );
};
