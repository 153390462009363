import { useEffect, useState } from "react";

import { useEmailConfirmationsMutation } from "@hotel-engine/react-query/users/useEmailConfirmations";
import { captureMessage } from "@hotel-engine/utilities";

import * as Styled from "./styles";
import { Typography } from "@hotelengine/atlas-web";
import { useGlobalTheme } from "../../contexts/GlobalThemeContext";

type IResendStatus = "idle" | "error" | "success" | "sending";

export interface IEmailConfirmationWarning {
  /** Email that did not validate */
  email: string;
  /** Indicates that the modal is visible to the user */
  visible?: boolean;
}

export const UnconfirmedEmail = ({ email, visible }: IEmailConfirmationWarning) => {
  const { tokens } = useGlobalTheme();
  const [resendStatus, setResendStatus] = useState<IResendStatus>("idle");

  useEffect(() => {
    // As closing the modal doesn't de-render it, we need to reset status whenever the email address or visibility changes //
    setResendStatus("idle");
  }, [email, visible]);

  const mutation = useEmailConfirmationsMutation();
  const handleResendEmail = () => {
    setResendStatus("sending");

    mutation.mutate(
      { email },
      {
        onSuccess: () => {
          setResendStatus("success");
        },
        onError: (error) => {
          setResendStatus("error");
          captureMessage("Email confirmation request failure", {
            error,
          });
        },
      }
    );
  };

  return (
    <Styled.ConfirmEmailMessageWrapper>
      <Styled.ConfirmIcon
        icon={["fal", "paper-plane"]}
        size="3x"
        color={tokens.colors.accentBlue}
      />
      <Typography
        variant="heading/2xl"
        color="foregroundPrimary"
        style={{ marginBottom: tokens.spacing[16], textAlign: "center" }}
      >
        Whoops! This account was never verified.
      </Typography>
      <Typography
        variant="body/sm"
        color="foregroundPrimary"
        style={{ textAlign: "center", marginBottom: 0 }}
        data-testid="unverified-email"
      >
        We sent a verification email to <strong>{email}</strong>. Please click the link in the email
        to finish setting up your account registration.
      </Typography>
      <Typography
        variant="body/sm"
        color="foregroundPrimary"
        style={{ textAlign: "center", marginBottom: 0, marginTop: 8 }}
      >
        Didn't get an email from us?{" "}
      </Typography>
      <Styled.ResendButton onClick={handleResendEmail} disabled={resendStatus !== "idle"}>
        Resend verification email.
      </Styled.ResendButton>
      {resendStatus === "success" && (
        <Styled.ResendResponse $status="success">
          A new email verification has been sent to you.
        </Styled.ResendResponse>
      )}
      {resendStatus === "error" && (
        <Styled.ResendResponse $status="error">
          We are sorry, there was an error submitting your request. Please try again later.
        </Styled.ResendResponse>
      )}
    </Styled.ConfirmEmailMessageWrapper>
  );
};
