import { ScreenReaderOnly } from "@hotel-engine/app/ScreenReaderOnly/styles";
import LoyaltyBadge from "@hotel-engine/assets/LoyaltyBadge";
import { Icon } from "@hotel-engine/common/Icon";
import { colors } from "@hotel-engine/styles";

import * as Styled from "./styles";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import { useAppSelector } from "store/hooks";
import config from "config";
import { useIsSingleDaySameDayBooking } from "@hotel-engine/hooks/useIsSingleDaySameDayBooking";

interface IExpressBookRewardsRefundable {
  /**
   * name of loyalty program for customer
   */
  loyaltyRewardName?: string | null;
  /**
   * Hotel is refundable on cancellation
   */
  isCancellable: boolean;
}

export const ExpressBookRewardsRefundable = ({
  loyaltyRewardName,
  isCancellable,
}: IExpressBookRewardsRefundable) => {
  const user = useAppSelector((state) => state.Auth.user);

  /**
   * note: this flag was initially called flexpass before the rebrand to flexpro
   *  todo remove flexProEnabled once flexpass is fully rolled out
   */
  const flexProEnabled = useIsFeatureFlagOn("flexpass");
  // TODO: Per Boone's notes on FEN-5335 PR, this may break when we get to changing dates.  Verify on FEN-5367
  const isSingleDaySameDay = useIsSingleDaySameDayBooking();

  const showFlexPro =
    flexProEnabled &&
    user?.business.flexAllowedBusiness &&
    user?.business.flexProEnrolled &&
    !isSingleDaySameDay;

  if (!loyaltyRewardName && !isCancellable && !showFlexPro) {
    return null;
  }

  return (
    <Styled.Container data-testid="eb-rewards-refundable">
      {!!loyaltyRewardName && (
        <Styled.IconTextBlock type="loyalty">
          <LoyaltyBadge ariaHidden={true} fill={colors.dragonlordPurple} />
          <Styled.LoyaltyText aria-hidden={true}>{loyaltyRewardName}</Styled.LoyaltyText>
          <ScreenReaderOnly>{`${loyaltyRewardName} eligible`}</ScreenReaderOnly>
        </Styled.IconTextBlock>
      )}
      {showFlexPro ? (
        <Styled.FlexProLogoContainer>
          <Styled.FlexProLogo
            alt="Flex Pro Logo"
            src={`${config.cdnHost}/assets/flex-pro/flex-pro-logo.svg`}
            fallbackSrc={`${config.cdnHost}/assets/flex-pro/flex-pro-logo.svg`}
            setContainerSize={true}
            style={{ objectFit: "contain" }}
          />
        </Styled.FlexProLogoContainer>
      ) : (
        !!isCancellable && (
          <Styled.IconTextBlock type="refundable">
            <Icon icon={["far", "check"]} />
            <Styled.RefundableText>Refundable Rooms</Styled.RefundableText>
          </Styled.IconTextBlock>
        )
      )}
    </Styled.Container>
  );
};
