import type { IBaseHEIconProps } from ".";

const LoyaltyBadge = ({
  height = "16",
  width = "16",
  fill = "white",
  style = {},
  ariaHidden = false,
}: IBaseHEIconProps) => (
  <svg
    aria-hidden={ariaHidden}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    style={style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8351 7.63794L14.5464 6.52351C14.3975 6.39512 14.341 6.1897 14.4078 6.00481L14.9674 4.39737C15.0649 4.12004 14.8904 3.82731 14.608 3.77082L12.9291 3.44728C12.7392 3.41133 12.5851 3.2624 12.5492 3.06724L12.2309 1.39303C12.1744 1.10544 11.8818 0.935965 11.6045 1.03354L9.99751 1.59332C9.81268 1.65495 9.60731 1.60359 9.47896 1.45466L8.35971 0.165623C8.16975 -0.0552078 7.82576 -0.0552078 7.63579 0.165623L6.52168 1.45466C6.39332 1.60359 6.18796 1.66009 6.00313 1.59332L4.39613 1.03354C4.11889 0.935965 3.82624 1.11058 3.76976 1.39303L3.44631 3.06724C3.41037 3.25726 3.26148 3.41133 3.06638 3.44728L1.39264 3.77082C1.10513 3.82731 0.935702 4.12004 1.03325 4.39737L1.59288 6.00481C1.65449 6.1897 1.60314 6.39512 1.45425 6.52351L0.165577 7.63794C-0.0551923 7.82796 -0.0551923 8.17204 0.165577 8.36206L1.45425 9.47649C1.60314 9.60488 1.65962 9.8103 1.59288 9.99518L1.03325 11.6026C0.935702 11.88 1.11026 12.1727 1.39264 12.2292L3.06638 12.5527C3.25635 12.5887 3.41037 12.7376 3.44631 12.9328L3.76976 14.607C3.82624 14.8946 4.11889 15.064 4.39613 14.9665L6.00313 14.4067C6.18796 14.345 6.39332 14.3964 6.52168 14.5453L7.63579 15.8344C7.82576 16.0552 8.16975 16.0552 8.35971 15.8344L9.47383 14.5453C9.60218 14.3964 9.80755 14.3399 9.99238 14.4067L11.5994 14.9665C11.8766 15.064 12.1693 14.8894 12.2257 14.607L12.5492 12.9328C12.5851 12.7427 12.734 12.5887 12.9291 12.5527L14.6029 12.2292C14.8904 12.1727 15.0598 11.88 14.9623 11.6026L14.4026 9.99518C14.341 9.8103 14.3924 9.60488 14.5412 9.47649L15.8299 8.36206C16.0558 8.17204 16.0558 7.82796 15.8351 7.63794ZM6.5944 6.39619L7.57084 4.41638C7.74729 4.059 8.25421 4.06349 8.42916 4.41638L9.40561 6.39619L11.5903 6.7147C11.982 6.77152 12.1391 7.25451 11.8549 7.53114L10.2744 9.07133L10.6482 11.247C10.7155 11.6403 10.3013 11.9334 9.95439 11.7509L8 10.7237L6.04561 11.7509C5.6987 11.9349 5.28449 11.6403 5.35178 11.247L5.72561 9.07133L4.14505 7.53114C3.86094 7.25451 4.01795 6.77152 4.40973 6.7147L6.5944 6.39619Z"
      fill={fill}
    />
  </svg>
);

export default LoyaltyBadge;
