import { useEffect, useRef } from "react";

export function usePrevious<T>(value: T | undefined) {
  if (value === null) {
    throw new Error("Use undefined instead of null");
  }

  const ref = useRef<T | undefined>(undefined);

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}
