import type { FunctionComponent } from "react";

import { FormItem } from "@hotel-engine/common/Form";
import { Input } from "@hotel-engine/common/Inputs";

export interface IDirectBillVerificationProps {
  readonly directBillCode?: string;
  readonly onChange: (directBillCode: string) => void;
  readonly submitAttempts?: number;
  readonly labelText?: string;
  readonly placeholderText?: string;
}

export const DirectBillVerification: FunctionComponent<IDirectBillVerificationProps> = ({
  directBillCode,
  onChange,
  submitAttempts,
  labelText = "Direct Bill Code",
  placeholderText = "Enter Direct Bill Code",
}) => {
  const validateStatus = !!submitAttempts && !directBillCode ? "error" : undefined;
  const help = !!submitAttempts && !directBillCode ? "Enter direct bill code" : undefined;

  return (
    <FormItem
      colon={false}
      label={labelText}
      help={help}
      validateStatus={validateStatus}
      id={validateStatus ? "trip-info-error" : ""}
      required
    >
      <Input
        defaultValue={directBillCode}
        placeholder={placeholderText}
        onChange={(e) => onChange(e.target.value)}
        size="large"
      />
    </FormItem>
  );
};
