import type { PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "./";

import type {
  IAddWebsocketBatchPayload,
  IResultVisibility,
  ISearchMetricsState,
  ISetEntryPointPayload,
  ISetMapLoadingTimesPayload,
  ISetResultsInteractivePayload,
  ISetSpecificSearchTimestampsPayload,
  ISetWebsocketBatchVisiblePayload,
  ISetWebsockTimestampsPayload,
} from "./types";
import { VisibilityType, setBatchVisible, setResultVisible } from "./helpers";

export const setWebsocketTimestamps = (
  state: ISearchMetricsState,
  action: PayloadAction<ISetWebsockTimestampsPayload>
): ISearchMetricsState => {
  const { startedAt, openedAt, closedAt } = action.payload;

  return {
    ...state,
    websocketStartedAt: startedAt,
    websocketOpenedAt: openedAt,
    websocketClosedAt: closedAt,
  };
};

export const setWebsocketAbortedAt = (
  state: ISearchMetricsState,
  action: PayloadAction<number>
) => {
  state.websocketAbortedAt = action.payload;
};

export const setSpecificSearchTimestamps = (
  state: ISearchMetricsState,
  action: PayloadAction<ISetSpecificSearchTimestampsPayload>
): ISearchMetricsState => {
  const { startedAt, resolvedAt } = action.payload;

  return {
    ...state,
    specificSearchStartedAt: startedAt,
    specificSearchResolvedAt: resolvedAt,
  };
};

export const setResultVisibleInList = (
  state: ISearchMetricsState,
  action: PayloadAction<IResultVisibility>
) => setResultVisible(state, action.payload, VisibilityType.List);

export const setResultVisibleInMap = (
  state: ISearchMetricsState,
  action: PayloadAction<IResultVisibility>
) => setResultVisible(state, action.payload, VisibilityType.Map);

export const addWebsocketBatch = (
  state: ISearchMetricsState,
  action: PayloadAction<IAddWebsocketBatchPayload>
) => {
  state.websocketBatches.push(action.payload);
};

export const setWebsocketBatchVisibleInList = (
  state: ISearchMetricsState,
  action: PayloadAction<ISetWebsocketBatchVisiblePayload>
) => setBatchVisible(state, action.payload, VisibilityType.List);

export const setWebsocketBatchVisibleOnMap = (
  state: ISearchMetricsState,
  action: PayloadAction<ISetWebsocketBatchVisiblePayload>
) => setBatchVisible(state, action.payload, VisibilityType.Map);

export const setMapLoadingTimes = (
  state: ISearchMetricsState,
  action: PayloadAction<ISetMapLoadingTimesPayload>
) => {
  const { finishedAt, startedAt } = action.payload;

  state.mapFinishedLoadingAt = finishedAt;
  state.mapStartedLoadingAt = startedAt;
};

// Resets state and sets the timestamp for a specific search entry point.
export const setEntryPointTimestamp = (
  state: ISearchMetricsState,
  action: PayloadAction<ISetEntryPointPayload>
) => {
  // Values to keep that aren't specific to a search
  const { mapFinishedLoadingAt, mapStartedLoadingAt } = state;

  return {
    ...initialState,
    mapFinishedLoadingAt,
    mapStartedLoadingAt,
    [action.payload.entryPoint]: action.payload.timestamp,
  };
};

export const setResultsInteractiveTimestamp = (
  state: ISearchMetricsState,
  action: PayloadAction<ISetResultsInteractivePayload>
) => {
  state[action.payload.key] = action.payload.timestamp;
};

export const resetState = () => {
  return initialState;
};

export const resetStateForNewSearch = (state: ISearchMetricsState) => {
  // Values to keep that aren't specific to a search
  const { mapFinishedLoadingAt, mapStartedLoadingAt } = state;

  return {
    ...initialState,
    mapFinishedLoadingAt,
    mapStartedLoadingAt,
  };
};
