import { Button } from "@hotelengine/atlas-web";
import styled from "styled-components";
import { screenReaderOnly, zIndex } from "@hotel-engine/styles";

export const SkipToLink = styled(Button)`
  position: fixed;
  transition: none;
  z-index: ${zIndex.modal + zIndex.below};
  margin: 3px;

  &:not(:focus) {
    ${screenReaderOnly};
  }
`;
