import * as Styled from "./styles";

interface ISkipLinkProps {
  to?: string;
}

/**
 * When adding a new main content section, ensure that a) the element is a main tag b) has tabIndex={-1} c) has an aria-label of main-content
 * @param to the id of the main content to skip to
 * @constructor
 */
export const SkipLink = ({ to = "site-content" }: ISkipLinkProps) => {
  const onClick = (e) => {
    //the checkout page uses location state which doesn't work well with hash routing, so manually focusing on the main content
    e.preventDefault();
    document.getElementById(to)?.focus();
  };
  return (
    <Styled.SkipToLink size="lg" onClick={onClick}>
      Skip to Content
    </Styled.SkipToLink>
  );
};
