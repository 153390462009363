import { Field } from "formik";

import * as Styled from "./styles";

export interface IReviewText {
  setReviewText: (val: string) => void;
  value: string;
  showCount?: boolean;
  maxLength?: number;
  maxRows?: number;
}

const ReviewText = ({ setReviewText, value, showCount, maxLength, maxRows = 13 }: IReviewText) => (
  <Styled.Wrapper>
    <Field
      component={Styled.ReviewTextArea}
      label={"Tell us about your stay"}
      name={"reviewText"}
      placeholder={"How did it go?"}
      onChange={(ev) => setReviewText(ev.target.value)}
      maxLength={maxLength}
      value={value}
      autoSize={{ minRows: 4, maxRows }}
    />
    {!!showCount && (
      <Styled.CharacterCount>
        {value.length}
        <Styled.Divide>/</Styled.Divide>
        {maxLength} Characters
      </Styled.CharacterCount>
    )}
  </Styled.Wrapper>
);

export default ReviewText;
