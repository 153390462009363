import type { IExpressBookContractDataResponse } from "@hotel-engine/types/expressBook";
import type { IReservationCreatePayload } from "@hotel-engine/types/reservation";

export const getRewardsInfoIfPresent = (
  expressBookData: IExpressBookContractDataResponse,
  occupants: IReservationCreatePayload["occupants"]
) => {
  return guestAddedLoyaltyRewards(occupants) ? expressBookData.property.loyaltyRewardName : null;
};

export const guestAddedLoyaltyRewards = (occupants: IReservationCreatePayload["occupants"]) =>
  occupants.some((occupantsList) =>
    occupantsList.some((occupant) => !!occupant.loyaltyRewardProgramNumber)
  );
