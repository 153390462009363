import { useLocation } from "@hotel-engine/lib/react-router-dom";
import { useSearchParams } from "@hotel-engine/hooks";
import type { ICheckoutQueryParams } from "@hotel-engine/types/booking";
import type { IContractRate } from "@hotel-engine/types/contractRate";
import type { ILegacyRoom, IRoom, IRoomRate } from "@hotel-engine/types/room";

export interface ILocationState {
  contractRate: IContractRate;
  room: IRoom | ILegacyRoom;
}

const isLegacyRoom = (room: IRoom | ILegacyRoom, roomRateId: string): room is ILegacyRoom => {
  return "id" in room && !roomRateId;
};

export const useContractRoomData = () => {
  const { state: locationState } = useLocation<ILocationState>();
  const { roomRateId } = useSearchParams<ICheckoutQueryParams>();

  if (!locationState || !locationState.contractRate || !locationState.room)
    return { room: undefined, contractRate: undefined };

  let room: IRoomRate | undefined;
  let description: string;
  let bedTypes: string[];
  if (!isLegacyRoom(locationState.room, roomRateId)) {
    room = locationState.room.rates.find((r) => r.id === Number.parseInt(roomRateId));
    description = locationState.room.title;
    bedTypes =
      (locationState.room.beddings
        ?.map((bedding) => bedding.type)
        .filter((type) => type) as string[]) || [];
  } else {
    room = {
      ...locationState.room,
      groupingIndex: 0,
      includedAmenities: { breakfast: 0, parking: 0 },
      outOfPolicy: false,
      loyaltyEligible: locationState.contractRate.loyaltyEligible,
      cancelBy: locationState.contractRate.cancelBy ?? undefined,
      currency: "USD",
      numRooms: 1,
      numNights: 1,
      totalCustomerBasePrice: 0,
      totalCustomerPrice: 0,
      totalTaxesAndFees: 0,
    };
    description = locationState.room.description;
    bedTypes = [];
  }

  return {
    room,
    contractRate: locationState.contractRate,
    description,
    bedTypes,
  };
};
