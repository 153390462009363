/* eslint-disable */
export {
  type Comparator,
  type Entries,
  type EntriesDict,
  type Entry,
  type Ordering,
  type Path,
  type Prop,
  type PropOrPath,
  type TreeFromPath,
  absorb,
  fail,
  is,
  isLiteral,
  isLiterally,
  isNonNullable,
  isNullable,
  isObjectLike,
  isShowable,
  not,
  succeed,
}

import { type Any, type Fn, type Guard, empty } from "@hotel-engine/data";

/**
 * @example
 *  type Abc123 = TreeFromPath<["a", "b", "c", 1, 2, 3], "leaf">
 *  //   ^? type Abc123 = { a: { b: { c: { 1: { 2: { 3: "leaf" } } } } } }
 */
type TreeFromPath<P extends Path, V = {}>
  = P extends readonly [] ? V
  : P extends readonly [
    ...Any.ListOf<Prop, infer T>,
    Any.Key<infer H>,
  ]
  ? TreeFromPath<T, { [K in H]: V }>
  : never
  ;

type Prop = Any.Key;
type Path = Any.Array<Prop>;

type EntriesDict = Any.Dict<Path | Entry>

/**
 * @example
 *  // Example of `Entry` being used as a constraint:
 *  declare const myEntry: [1, 2, 3, (u: unknown) => u is string]
 *  declare const notEntry: [[1, 2, 3], (u: unknown) => u is string]
 *
 *  type Test1 = typeof myEntry extends Entry ? true : false
 *  //   ^? type Test1 = true
 *  type Test2 = typeof notEntry extends Entry ? true : false
 *  //   ^? type Test2 = false
 *
 *  // Example of `Entry` being used as a pattern matcher:
 *  type EntryToObj<T>
 *    = T extends Entry<infer P, infer G>
 *    ? { path: P, guard: G }
 *    : never
 *
 *  type Test3 = EntryToObj<[1, 2, 3, (u: unknown) => u is string]>
 *  //   ^? type Test3 = { path: [1, 2, 3], guard: (u: unknown) => u is string }
 */
type Entry<
  P extends Path = Path,
  G extends Any.Guard = Any.Guard,
> = readonly [...P, G]

type Entries = Any.Array<Entry>
type PropOrPath = Prop | Path


interface Comparator<T> {
  (left: T, right: T): Ordering
}
type Ordering = -1 | 0 | 1

const absorb
  : <t>(t: t) => never
  = (t) => t as never

const isObjectLike
  : (u: unknown) => u is {}
  = absorb((u: unknown) => typeof u === "object" && u !== null)

const not
  : <T>(predicate: Fn.Predicate<T>) => Fn.Predicate<T>
  = <T>(predicate: Fn.Predicate<T>): Fn.Predicate<T> => (source) => !predicate(source)

const succeed
  : <U>(u: U) => u is U
  = (u): u is typeof u => true

const fail
  : (_: unknown) => _ is never
  = (_): _ is never => false

const typeOf = (u: unknown) => typeof u

const TypeMap = {
  number: `number`,
  string: `string`,
  boolean: `boolean`,
  object: `object`,
  bigint: `bigint`,
  symbol: `symbol`,
  undefined: `undefined`,
  function: `function`,
} as const

type TypeMap = {
  [TypeMap.number]: number
  [TypeMap.string]: string
  [TypeMap.boolean]: boolean
  [TypeMap.object]: object
  [TypeMap.bigint]: bigint,
  [TypeMap.symbol]: symbol,
  [TypeMap.undefined]: undefined,
  [TypeMap.function]: Function
}

const typeIs
  : <target extends ReturnType<typeof typeOf>>(target: target) => Fn.Guard<TypeMap[target]>
  = (target) => absorb((u: unknown) => typeof u === target)


const isLiterally
  : <V extends Any.Literal>(v: V) => (u: unknown) => u is V
  = (v) => absorb((u: unknown) => u === v)

const includes = (elements: Any.Array, itemToFind: unknown): boolean => elements.includes(itemToFind)

const isKey
  : (u: unknown) => u is Any.Key
  = absorb((u: unknown) => includes([TypeMap.number, TypeMap.string], typeOf(u)))

const isIndex
  : (u: unknown) => u is Any.Index
  = absorb((u: unknown) => includes([TypeMap.number, TypeMap.string, TypeMap.symbol], typeOf(u)))

const isLiteral
  : (u: unknown) => u is Any.Literal
  = absorb((u: unknown) => includes([TypeMap.boolean, TypeMap.number, TypeMap.string], typeOf(u)))

const isShowable
  : (u: unknown) => u is Any.Showable
  = absorb((u: unknown) => includes([TypeMap.bigint, TypeMap.boolean, TypeMap.number, TypeMap.string], typeOf(u)) || isNullable(u))

const isPrimitive
  : (u: unknown) => u is Any.Primitive
  = absorb((u: unknown) => isShowable(u) || typeof u === "symbol")

const isNullable
  : Guard<null | undefined>
  = (u: unknown): u is null | undefined => u == null;

const isNonNullable
  : Fn.Guard<Any.NonNullable>
  = absorb(not(isNullable));

const isStruct
  : (u: unknown) => u is Any.Struct
  = absorb((u: unknown) => typeof u === "object" && u !== null && !Array.isArray(u))

const isArray = <type = unknown>(u: unknown): u is Any.Array<type> => Array.isArray(u)

const isEmptyString
  : <type>(u: type) => u is empty.string<type>
  = (u): u is never => u === empty.string

const Empty = {
  string: isEmptyString
} as const

const is = {
  empty: Empty,
  string: typeIs(TypeMap.string),
  number: typeIs(TypeMap.number),
  boolean: typeIs(TypeMap.boolean),
  bigint: typeIs(TypeMap.bigint),
  symbol: typeIs(TypeMap.symbol),
  function: typeIs(TypeMap.function),
  undefined: typeIs(TypeMap.undefined),
  struct: isStruct,
  array: isArray,
  nullable: isNullable,
  nonNullable: isNonNullable,
  key: isKey,
  index: isIndex,
  literal: isLiteral,
  showable: isShowable,
  primitive: isPrimitive,
  literally: isLiterally,
} as const
