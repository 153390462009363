import { Typography } from "@hotelengine/atlas-web";
import type { ILodgingBooking, ISupportNameChange } from "@hotel-engine/types/supportCase";
import * as Styled from "../../styles";
import { capitalize } from "@hotel-engine/utilities";

export interface ISupportNameChangeProps {
  details: ISupportNameChange;
  booking: ILodgingBooking;
}

function NameChange({ details, booking }: ISupportNameChangeProps) {
  const NameChangeKeyValuePair = Object.entries(details);
  const { rooms } = booking as ILodgingBooking;
  const isMultiRoom = rooms.length > 1;

  return (
    <>
      {NameChangeKeyValuePair.map(([key, value], idxKey) => (
        <div key={`${key}-${idxKey}`}>
          {!!isMultiRoom && (
            <Styled.Row>
              <Typography variant="body/sm">{capitalize(key)}</Typography>
              <Typography variant="body/md">{rooms[idxKey].contractNumber}</Typography>
            </Styled.Row>
          )}

          {value.map(({ from, to }, idxValue) => {
            if (!to) {
              return (
                <Styled.Row key={`${key}-${idxKey}-${idxValue}`}>
                  <Typography variant="body/md">Previous Guest</Typography>
                  <Typography variant="body/md">
                    <Styled.StrikethroughText
                      data-testid={`strikethrough-text-${key}-${idxValue}`}
                      variant="body/md"
                    >
                      {from}
                    </Styled.StrikethroughText>
                    {to}
                  </Typography>
                </Styled.Row>
              );
            }
            return (
              <Styled.Row key={`${key}-${idxKey}-${idxValue}`}>
                <Typography variant="body/md">New Guest</Typography>
                <Typography variant="body/md">
                  <Styled.StrikethroughText marginRight={4} variant="body/md">
                    {from}
                  </Styled.StrikethroughText>
                  {to}
                </Typography>
              </Styled.Row>
            );
          })}
        </div>
      ))}
    </>
  );
}

export default NameChange;
