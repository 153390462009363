import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import type { IOrders } from "./types";

export const initialState: IOrders = {
  orderId: undefined,
};

export const ordersSlice = createSlice({
  name: "Orders",
  initialState,
  reducers: {
    setSelectedOrder: (state, action: PayloadAction<number>) => ({
      ...state,
      orderId: action.payload,
    }),
    clearSelectedOrder: (state) => ({
      ...state,
      orderId: initialState.orderId,
    }),
  },
});

export const OrdersActions = ordersSlice.actions;

export default ordersSlice.reducer;
