/**
 * !!IMPORTANT!!
 * This file is deprecated in support of the colors from the atlas-web package which is maintained by the Atlas team.
 *
 * See https://atlas-design-system.supernova-docs.io/latest/guides/developer-guide-xhDMKQKx for more details
 */

const colorPalettes = {
  red: {
    50: "#FBEBED",
    100: "#F0B0B9",
    200: "#E88996",
    300: "#E16173",
    400: "#D93A50",
    500: "#AE2E40",
    600: "#822330",
    700: "#571720",
  },
  orange: {
    50: "#FEF3EA",
    100: "#FCD0AC",
    200: "#FBB882",
    300: "#F9A159",
    400: "#F8892F", // Rewards - VIP - Orange
    500: "#C66E26",
    600: "#95521C",
    700: "#633713",
  },
  gold: {
    50: "#FEF9EF",
    100: "#FBE7BE",
    200: "#F9DA9D",
    300: "#F7CE7D",
    400: "#F5C25C", // Rewards - Gold - Yellow
    500: "#C2B052",
    600: "#937437",
    700: "#624E25",
  },
  yellow: {
    50: "#FEFBF0",
    100: "#FEFBF0",
    200: "#FAF1C2",
    300: "#F7EAA4",
    400: "#F2DC67",
    500: "#C2B052",
    600: "#91843E",
    700: "#615829",
  },
  green: {
    50: "#ECFBF1",
    100: "#B3F1C8",
    200: "#8CE9AC",
    300: "#66E291",
    400: "#40DB75",
    500: "#33AF5E",
    600: "#268346", // Dashboard Trends Green
    700: "#1A582F",
  },
  aqua: {
    50: "#EAFCFC",
    100: "#AAF1F1",
    200: "#80EBEB",
    300: "#55E4E4",
    400: "#2BDDDD",
    500: "#22B1B1",
    600: "#1A8585",
    700: "#115858",
  },
  blue: {
    50: "#E9F5FE",
    100: "#A7D8FC",
    200: "#7BC5FB",
    300: "#4FB1F9",
    400: "#239EF8",
    500: "#2F58EB",
    600: "#155F95",
    700: "#0E3F63",
  },
  purple: {
    50: "#EDE5FF",
    100: "#B799FF",
    200: "#9366FF",
    300: "#6F33FF",
    400: "#4B00FF",
    500: "#3C00CC",
    600: "#2D0099",
    700: "#1E0066",
  },
  slate: {
    50: "#ECF1F6",
    100: "#D1DBE7",
    200: "#A7BACC",
    300: "#8FA1B4",
    400: "#6E7E8E", // Rewards - Platinum - Grey
    500: "#586572",
    600: "#424C55",
    700: "#2C3239",
  },
  grey: {
    50: "#F3F4F7", // Background - Grey
    100: "#E7E8EC", // Dividers - Grey
    200: "#DDDEE0", // Borders - Grey
    300: "#CBCBCB",
    400: "#AFAFAF",
    450: "#858585",
    500: "#757575",
    600: "#424C55",
    700: "#2E2F31",
    800: "#1A1A1A", // Primary Content
  },
};

/**
 * @deprecated Do not access the colors directly. Utilize the `useGlobalTheme` hook or if you're using styled components
 * access the current theme off the props
 *
 * @example
 * const { tokens } = useGlobalTheme();
 *
 * @example
 * background-color: ${({ theme }) => theme.colors.backgroundPrimary};
 *
 * Please reach out to the Atlas team with any questions.
 */
export const colors = {
  white: "#FFFFFF", // Core Brand - White
  black: "#000000", // Core Brand - Black
  fadedBlack: "rgba(0, 0, 0, 0.5)", // Map marker cluster border
  softBlue: "#6d8af1", // Core Brand - Blue Faded
  tomato: "#FF3535", // Error - Red
  frilledShark: "#95A0AA", // Rewards - Silver - Grey
  fadedRetroBlue: "rgba(47, 88, 235, 0.7)", // Interactive - Primary Disabled - Blue
  retroBlueRgb: "47, 88, 235", // retroblue in rgb, used for map icon colors with various opacity
  drWhite: "#FAFAFA", // Interactive - Secondary Hover - White
  whiteSmoke: "#F5F5F5", // Interactive - Secondary Active/Clicked - White
  fadedRed: "#D54A4A", // Interactive - Delete Hover - Red
  rubiate: "#BE4242", // Interactive - Delete Active/Clicked - Red
  orangeOchre: "#D9753B", // Avatar - Orange1
  dullOrange: "#D9873B", // Avatar - Orange2
  excitingOrange: "#F2B279", // Avatar - Orange3
  loveYouPink: "#D97E8E", // Avatar - Pink
  moonlightPurple: "#5856B2", // Avatar - Purple
  blueNights: "#333A49", // Avatar - Blue1
  aerostaticsBlue: "#305473", // Avatar - Blue2
  irisBlue: "#3E64AD", // Avatar - Blue3
  iceBlue: "#739BD8", // Avatar - Blue4
  frillsBlue: "#8FA4BF", // Avatar - Blue5
  magicBlue: "#3D88A9", // Avatar - Teal1
  dragonflyBlue: "#44ADC5", // Avatar - Teal2
  stampPadGreen: "#30A690", // Avatar - Teal3
  technoGreen: "#6AA95A", // Avatar - Green1
  cabbagePatch: "#92CB59", // Avatar - Green2
  indianMaize: "#E7C54D", // Avatar - Yellow
  bruschettaRed: "#A65A49", // Avatar - Red1
  chestnutRose: "#CF5151", // Avatar - Red2
  fantasiaGrey: "#747A8C", // Avatar - Grey1
  assentGrey: "#8C8987", // Avatar - Grey2
  lightShadow: "rgba(0, 0, 0, 0.12)", // Button Shadow - Top
  darkShadow: "rgba(0, 0, 0, 0.04)", // Button Shadow - Bottom
  lavendarWisp: "#EAEBEE", // Dashboard Billing Progress Background
  royalHunterBlue: "#430CF5", // Dashboard Billing Icon
  placeboPurple: "#f1e6ff", // Dashboard Billing Icon Background
  brilliantLicorice: "#545454",
  weatheredStone: "#c4c4c4", // Dashboard Additional Feature Inactive Button
  sonicSilver: "#757575", // Modify Res & Checkout Guest Email Gray
  fadedTrendsGreen: "rgba(51, 175, 94, 0.16)",
  fadedTrendsBlue: "rgba(47, 91, 235, 0.12)",
  boysenberryShadow: "#F3F4F8", // Dashboard Additional Feature card background color

  /** NOTE: Sass variable $grey-7 and current colors.chefsHat should be changed to colors.grey[50] */
  chefsHat: "#F3F4F7", // Background - Grey
  /** NOTE: Sass variable $grey-6 and current colors.purpleCrystal should be changed to colors.grey[100] */
  purpleCrystal: "#E7E8EC", // Dividers - Grey
  /** NOTE: Sass variable $grey-5 and current colors.azureHint should be changed to colors.grey[200] */
  azureHint: "#DDDEE0", // Borders - Grey
  /** NOTE: Sass variable $grey-1 and current colors.errieBlack should be changed to colors.grey[800] */
  errieBlack: "#1A1A1A", // Primary Content
  /** NOTE: Sass variable $rewards-gold and current colors.butterfield should be changed to colors.gold[400] */
  butterfield: "#F5C25C", // Rewards - Gold - Yellow
  /** NOTE: Sass variable $rewards-gold and current colors.chainGangGrey should be changed to colors.slate[400] */
  chainGangGrey: "#6E7E8E", // Rewards - Platinum - Grey

  // The values below are not in the next iteration of the color system scale and use the named colors instead of the array index/number syntax]
  // The values below are not in the next iteration of the color system scale and use the named colors instead of the array index/number syntax]
  /** NOTE: Sass variable $blue should be changed to colors.retroBlue */
  retroBlue: colorPalettes.blue[500], // Core Brand - Blue
  widowMaker: "#9FB2F6", // Core Brand - Blue 46% opacity
  /** NOTE: Sass variable $grey-2 should be changed to colors.blackPanther */
  blackPanther: "#414243", // Secondary Content - Grey
  /** NOTE: Sass variable $grey-3 should be changed to colors.kiwiGrey */
  kiwiGrey: "#929395", // Tertiary Content - Grey
  /** NOTE: Sass variable $grey-4 should be changed to colors.piedWagtailGrey */
  piedWagtailGrey: "#BDBEC1", // Icon/Action - Grey
  /** NOTE: Sass variable $green should be changed to colors.finePine */
  finePine: "#008805", // Success - Green
  /** NOTE: Sass variable $light-green should be changed to colors.peacefulRain */
  peacefulRain: "#F2F9F2", // Success - Background - Light Green
  /** NOTE: Sass variable $light-red should be changed to colors.voraciousWhite */
  voraciousWhite: "#FEEEEE", // Error - Background - Light Red
  /** NOTE: Sass variable $orange should be changed to colors.carrotCurl */
  carrotCurl: "#FF9118", // Caution - Orange
  /** NOTE: Sass variable $light-orange should be changed to colors.whatsLeft */
  whatsLeft: "#FFF4E8", // Caution - Background - Light Orange
  /** NOTE: Sass variable $light-blue should be changed to colors.placeboBlue */
  placeboBlue: "#EDF3FC", // Highlight - Background - Blue
  /** NOTE: Sass variable $loyalty-primary should be changed to colors.dragonlordPurple */
  dragonlordPurple: "#6243BC", // Loyalty - Primary - Purple
  /** NOTE: Sass variable $loyalty-background-purple should be changed to colors.brightDusk */
  brightDusk: "#EFECF8", // Loyalty - Background - Light Purple
  /** NOTE: Sass variable $$blue-hover should be changed to colors.denim */
  denim: "#2646BC", // Interactive - Primary - Blue
  /** NOTE: Sass variable $blue-clicked should be changed to colors.bavarianBlue */
  bavarianBlue: "#1C348D", // Interactive - Primary Active/Clicked - Blue
  /** NOTE: Sass variable $red should be changed to colors.khmerCurry */
  khmerCurry: "#ED5252", // Interactive - Delete Disabled
  fadedKhmerCurry: "rgba(237, 82, 82, 0.5)", // Interactive - Delete Disabled Transparency/Favorite map marker
  /** NOTE: Sass variable $grey-9 should be changed to colors.disabledBlack */
  disabledBlack: "rgba(0, 0, 0, 0.25)", // antd disabled
  /** Color comes from iOS Smart Banner (for App Download from browser) background */
  errigalWhite: "#f2f1f6",
  /** Color is Download link color on iOS Smart Banner */
  lightishBlue: "#367af6",
  /** Color for loading badges in Web Components */
  clearChill: "#1890FF",
  /** Color for dividers in Configurations page */
  beluga: "#f1f1f1",
  /** Color for email pill in Share modal */
  antdBlue: colorPalettes.blue[500],
  /** Color that antd uses for focus/hover states for some input components */
  antdFocusBlue: "#5981f7",
  antdTableSortBackground: "#f2f2f2",
  antdTableSortIcon: "#BFBFBF",
  /**Border color for ant design Table */
  antdTableBorderColor: "#E7E8E8",
  /**Border color for ant inputs */
  antdInputBorderColor: "#D5D6D9",
  /** Outline color for email pill in Share modal */
  antdBoxShadow: "rgba(47, 88, 235, .20)",
  /** Box shadow for invalid email pill in Share modal */
  antdErrorBoxShadow: "rgba(255, 53, 53, .20)",
  /** Background color of the Refer a Friend CTA card on the dashboard */
  abyssalBlue: "#01055A",
  /** Background color of the 2X rewards badge in search results */
  newBlue: "#0000FF",
  /** Background color for error error or delete buttons */
  teaberry: "#D93A50",
  /** Background color for error disabled error or delete buttons */
  meat: "#f28080",
  /** Background color of personal account header banner */
  personalTravelBlueSky: "#EAF9FC",
  /** Background color of antd menu item active state */
  aliceBlue: "#f0f6ff",
  /** the new grey500 color to be used for secondary/placeholder text https://www.figma.com/file/TVGanw1R2NRE43JV7wJtLF/Component-Auditing?node-id=304%3A380&t=SyUOKOnOroKQW43Y-0 */
  newGrey500: "#76767a",
  /** Background color of the Morgan Stanley banner button on the dashboard */
  northAtlanticBreeze: "#3A78B5",
  ...colorPalettes,
} as const;
