import type { FunctionComponent, ReactElement, SyntheticEvent } from "react";

import { useFormikContext } from "formik";

import { Dropdown } from "@hotel-engine/common/Dropdown";
import { Icon } from "@hotel-engine/common/Icon";
import { Modal } from "@hotel-engine/common/Modal";
import { notification } from "@hotel-engine/common/Notifications";
import { useDeletePaymentProfile } from "@hotel-engine/react-query/paymentProfile/useDeletePaymentProfile";
import type { IPaymentProfile } from "@hotel-engine/types/paymentProfile";
import { captureMessage } from "@hotel-engine/utilities";
import config from "config";
import type { ICheckoutFormData } from "pages/Checkout/validation";

import * as Styled from "./styles";

export interface IPaymentItemProps {
  onPaymentSelect: (id: number) => void;
  onEditPayment: () => void;
  payment: IPaymentProfile;
}

export const PaymentItem: FunctionComponent<IPaymentItemProps> = ({
  onPaymentSelect,
  onEditPayment,
  payment,
}: IPaymentItemProps) => {
  const { values, setFieldValue } = useFormikContext<ICheckoutFormData>();
  const deletePayment = useDeletePaymentProfile();

  const handleImgError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    const defaultImgUrl = `${config.cdnHost}/assets/creditcards/default.png`;

    if (e.currentTarget.src === defaultImgUrl) {
      return e;
    }

    return (e.currentTarget.src = defaultImgUrl);
  };

  // delete payment method logic
  const handleDeletePayment = () => {
    deletePayment.mutate(payment.id, {
      onSuccess: () => {
        if (values.selectedPaymentId === payment.id) {
          setFieldValue("selectedPaymentId", undefined);
        }
        notification.success({
          message: "Card Deleted",
        });
      },
      onError: (error) => {
        captureMessage("Payment profile DELETE error", {
          error,
          payment,
        });
      },
    });
  };

  // render modal for deleting payment
  const handleDeletePaymentModal = () => {
    if (!payment) return;

    const displayName = payment.nickname || `card ending in ${payment.last4}`;
    Modal.confirm({
      cancelText: "Cancel",
      content: `Are you sure you want to delete ${displayName}?`,
      icon: "",
      okText: "Delete",
      okType: "danger",
      autoFocusButton: null,
      onOk: () => handleDeletePayment(),
      title: (
        <Styled.DeleteHeader>
          <Styled.DeleteIcon icon={["fas", "times-circle"]} />
          Delete Card
        </Styled.DeleteHeader>
      ),
    });
  };

  const displayName = payment.nickname || payment.name;
  const summary = `ending in ${payment.last4}`;
  const isSelected = values.selectedPaymentId === payment.id;

  let actionCallout: ReactElement | null = null;
  if (payment.isExpired) {
    actionCallout = <Styled.ActionNeeded>(expired)</Styled.ActionNeeded>;
  } else if (!payment.billingPostalCode) {
    actionCallout = (
      <Styled.ActionNeeded type="button" onClick={onEditPayment}>
        <Styled.ActionIcon icon={["fal", "exclamation-circle"]} />
        <span>Update address</span>
      </Styled.ActionNeeded>
    );
  }

  return (
    <Styled.PaymentItemRadio
      checked={isSelected}
      disabled={payment.isExpired}
      onClick={() => onPaymentSelect(payment.id)}
      id={displayName}
    >
      <Styled.PaymentItemContainer
        data-testid={`cc-${payment.isExpired ? "expired" : "active"}`}
        $disabled={payment.isExpired}
        key={payment.id}
      >
        <Styled.LeftColumn>
          <Styled.PaymentImage>
            <img
              src={`${config.cdnHost}/assets/creditcards/${payment.type}.png`}
              alt="Payment Item"
              onError={handleImgError}
            />
          </Styled.PaymentImage>
          <Styled.Info>
            <div>
              <Styled.Name htmlFor={displayName}>{displayName}</Styled.Name>
              {!!payment.default && <Styled.CardTag>Default</Styled.CardTag>}
            </div>
            <Styled.Summary>{summary}</Styled.Summary>
            {actionCallout}
          </Styled.Info>
        </Styled.LeftColumn>
        <Dropdown
          overlay={
            <Styled.ActionMenu>
              <Styled.ActionItem onClick={onEditPayment}>
                <Icon fixedWidth icon={["far", "edit"]} />
                <span>Edit Card</span>
              </Styled.ActionItem>
              <Styled.ActionItem $isDeleteLink onClick={() => handleDeletePaymentModal()}>
                <Icon fixedWidth icon={["far", "minus-circle"]} />
                <span>Delete Card</span>
              </Styled.ActionItem>
            </Styled.ActionMenu>
          }
          trigger={["click"]}
        >
          <Styled.DropdownTrigger data-testid="show-actions-menu">
            <Icon icon={["fas", "ellipsis-h"]} />
          </Styled.DropdownTrigger>
        </Dropdown>
      </Styled.PaymentItemContainer>
    </Styled.PaymentItemRadio>
  );
};
