import { useEffect, useState } from "react";

import { useAppSelector } from "store/hooks";
import { triggerAmplitudeEvent } from "@hotel-engine/scripts/hooks";

import * as Styled from "./styles";
import { Box, Icon, Typography } from "@hotelengine/atlas-web";
import { captureMessage } from "@hotel-engine/utilities";
import { useEmailConfirmationsMutation } from "@hotel-engine/react-query/users/useEmailConfirmations";

type IResendStatus = "idle" | "error" | "success" | "sending";

export interface IConfirmEmailMessageStep {
  email: string;
}

export const ConfirmEmailMessageStep = ({ email }: IConfirmEmailMessageStep) => {
  const [resendStatus, setResendStatus] = useState<IResendStatus>("idle");

  const user = useAppSelector((state) => state.Auth.user);

  useEffect(() => {
    triggerAmplitudeEvent("viewVerifyPersonalAccount", {
      userId: user?.id,
    });
  }, [user?.id]);

  useEffect(() => {
    setResendStatus("idle");
  }, [email]);

  const mutation = useEmailConfirmationsMutation();
  const handleResendEmail = () => {
    if (resendStatus !== "idle") {
      return;
    }

    setResendStatus("sending");

    mutation.mutate(
      { email },
      {
        onSuccess: () => {
          setResendStatus("success");
        },
        onError: (error) => {
          setResendStatus("error");
          captureMessage("Email confirmation request failure", {
            error,
          });
        },
      }
    );
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={16}>
      <Icon name="envelope" color="accentBlue" size="lg" />
      <Typography variant="heading/2xl" color="foregroundPrimary" style={{ textAlign: "center" }}>
        Almost there!
      </Typography>
      <Typography
        variant="body/sm"
        color="foregroundPrimary"
        style={{ textAlign: "center" }}
        data-testid="verification-message"
      >
        We sent a verification email to {email}. Please click the link in the email to finish
        setting up your account registration.
      </Typography>
      <Typography
        variant="body/sm"
        color="foregroundPrimary"
        style={{
          marginBottom: 0,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        Didn't get an email from us?
        <Styled.ResendButton onClick={handleResendEmail}>
          Resend verification email.
        </Styled.ResendButton>
        {resendStatus === "success" && (
          <Styled.ResendResponse $status="success">
            A new email verification has been sent to you.
          </Styled.ResendResponse>
        )}
        {resendStatus === "error" && (
          <Styled.ResendResponse $status="error">
            We are sorry, there was an error submitting your request. Please try again later.
          </Styled.ResendResponse>
        )}
      </Typography>
    </Box>
  );
};
