import type { ImgHTMLAttributes } from "react";
import { Img } from "react-image";
import config from "config";

import * as Styled from "./styles";

export interface IImage extends Omit<ImgHTMLAttributes<HTMLImageElement>, "width" | "height"> {
  /**
   * Sets an explicit width
   * @default "100%"
   */
  width?: string | number;
  /**
   * Sets an explicit height
   * @default "100%"
   */
  height?: string | number;
  /**
   * A fallback image src if src throws an error
   * @default "/assets/no-property-photo.png"
   */
  fallbackSrc?: string;
  /**
   * Indicates whether or not to set specific styles for the wrapping div
   * That div has the following styles:
   * ```
   * position: relative; /// used for absolutely positioned images
   * width: 100%;
   * height: 100%;
   * ```
   * The default for this prop is `true`, however if you would rather
   * treat this wrapper as nonexistent use `false` here
   */
  setContainerSize?: boolean;
}

/**
 * The `Image` component is meant to handle common scenarios for `<img />`'s in our app including
 * loading/error states and fallback images. It exposes multiple configurations
 * for handling a variety of use cases.
 */

function Image({
  src = "",
  alt,
  className,
  width = "100%",
  height = "100%",
  fallbackSrc = `${config.cdnHost}/assets/no-property-photo.png`,
  setContainerSize = true,
  ...imgProps
}: IImage) {
  return (
    <Styled.Container $fill={setContainerSize}>
      <Img
        src={src}
        alt={alt}
        className={className}
        height={height}
        width={width}
        style={{ objectFit: "cover" }}
        loader={
          <Styled.LoadingImg
            alt={alt}
            src={src || fallbackSrc}
            width={width}
            height={height}
            style={{ objectFit: "cover" }}
          />
        }
        unloader={
          <Img
            src={fallbackSrc}
            alt={alt}
            className={className}
            height={height}
            width={width}
            style={{ objectFit: "contain" }}
            {...imgProps}
          />
        }
        {...imgProps}
      />
    </Styled.Container>
  );
}

export default Image;
