import { FILTER_FUNCTIONS } from "@hotel-engine/helpers/search/results/buildFilteredResults/constants";
import type { IResult } from "@hotel-engine/types/search";
import type {
  CountableFilter,
  IBasicFilter,
  ISearchFiltersState,
  SelectableFilterKey,
} from "./types";
import { COUNTABLE_FILTER_TYPES, FILTER_TYPES_THAT_KEEP_COUNTS } from "./constants";
import { SEARCH_FILTERS } from "@hotel-engine/constants";

/**
 * Resets the filter counts UNLESS the last selected or deselected filter type is a filter that keeps its previous count.
 * @param state filter state
 */
export const resetFilterCounts = (state: ISearchFiltersState) => {
  const lastSelectedFilterType =
    state.orderedSelectedFilters.length > 0 &&
    state.orderedSelectedFilters[state.orderedSelectedFilters.length - 1];

  const lastDeselectedFilterIsWithinSameFilterType =
    state.lastDeselectedFilter && state.lastDeselectedFilter === lastSelectedFilterType;
  const lastSelectedFilterTypeShouldKeepCounts =
    lastSelectedFilterType && FILTER_TYPES_THAT_KEEP_COUNTS.includes(lastSelectedFilterType);
  const lastDeselectedFilterTypeShouldKeepCounts =
    state.lastDeselectedFilter &&
    FILTER_TYPES_THAT_KEEP_COUNTS.includes(state.lastDeselectedFilter);
  /** Don't reset the count for a filter type if it is a filter that keeps its previous count */
  const countableFilterTypesToReset =
    (lastDeselectedFilterIsWithinSameFilterType || !state.lastDeselectedFilter) &&
    (lastSelectedFilterTypeShouldKeepCounts || lastDeselectedFilterTypeShouldKeepCounts)
      ? COUNTABLE_FILTER_TYPES.filter((t) => t !== lastSelectedFilterType)
      : COUNTABLE_FILTER_TYPES;

  countableFilterTypesToReset.forEach((filterType) => {
    // Reset count to 0 for each filter in the type
    Object.keys(state[filterType]).forEach((filterKey) => {
      state[filterType][filterKey].count = 0;
    });
  });
};

/**
 * Adds or removes a filterGroup from the orderedSelectedFilters array.
 * @param selected whether the filterGroup was selected or deselected
 * @param filterGroup the filterGroup that is being updated
 * @param state filter state
 */
export const updateOrderedSelectedFilters = (
  selected: boolean,
  filterGroup: CountableFilter,
  state: ISearchFiltersState
) => {
  if (!selected) {
    const lastIndex = state.orderedSelectedFilters.lastIndexOf(filterGroup);

    state.orderedSelectedFilters = state.orderedSelectedFilters.filter(
      (_, index) => lastIndex !== index
    );
    state.lastDeselectedFilter = filterGroup;
  } else {
    state.orderedSelectedFilters.push(filterGroup);
    state.lastDeselectedFilter = undefined;
  }
};

export const toggleAllLoyaltyOptions = (
  loyaltyPrograms: ISearchFiltersState["loyaltyPrograms"],
  selected: boolean
) => {
  Object.keys(loyaltyPrograms).forEach((loyaltyProgram) => {
    const filter = loyaltyPrograms[loyaltyProgram];

    // Deselect if no results are available
    if (filter.count <= 0) {
      filter.selected = false;
      return;
    }

    filter.selected = selected;
  });
};

export const toggleAllStayTypes = (
  stayTypes: ISearchFiltersState["stayTypes"],
  selected: boolean
) => {
  Object.keys(stayTypes).forEach((stayType) => {
    const filter = stayTypes[stayType];

    // Deselect if no results are available
    if (filter.count <= 0) {
      filter.selected = false;
      return;
    }

    filter.selected = selected;
  });
};

export const checkIsResultRemovedByPriceRange = (state: ISearchFiltersState, result: IResult) => {
  const { userMin, userMax } = state.priceRange;
  const isPriceRangeSelected = Number.isFinite(userMin) || Number.isFinite(userMax);
  const filterFunction = FILTER_FUNCTIONS.priceRange;

  return isPriceRangeSelected && !filterFunction([userMin, userMax], result);
};

export const checkIsResultRemovedByStarRating = (state: ISearchFiltersState, result: IResult) => {
  const { starRating } = state;
  const selectedStars = Object.keys(starRating)
    .filter((key) => starRating[key].selected)
    .map((key) => Number(key));
  const filterFunction = FILTER_FUNCTIONS.starRating;

  return !!selectedStars.length && !filterFunction(selectedStars, result);
};

export const checkIsResultRemovedByPropertyName = (
  selectedPropertyNames: string[],
  result: IResult
) => {
  const arePropertyNamesSelected = !!selectedPropertyNames.length;
  const filterFunction = FILTER_FUNCTIONS.propertyNames;

  return arePropertyNamesSelected && !filterFunction(selectedPropertyNames, result);
};

export const buildSelectedPropertyNames = (state: ISearchFiltersState) => {
  const { propertyNames } = state;

  return Object.keys(propertyNames).reduce<string[]>((acc, propertyName) => {
    if (propertyNames[propertyName].selected) {
      acc.push(propertyName);
    }

    return acc;
  }, []);
};

export const buildIsResultVisibleChecker = ({
  visibleResultIds,
  visibleHiddenResultIds,
}: {
  visibleResultIds: number[];
  visibleHiddenResultIds: number[];
}) => {
  const visibleResultIdsSet = new Set(visibleResultIds);
  const visibleHiddenResultIdsSet = new Set(visibleHiddenResultIds);

  return (resultId: number) =>
    visibleResultIdsSet.has(resultId) || visibleHiddenResultIdsSet.has(resultId);
};

export const incrementFilterCounts = (
  state: ISearchFiltersState,
  result: IResult,
  isResultVisible: boolean
) => {
  COUNTABLE_FILTER_TYPES.forEach((filterType) => {
    Object.keys(state[filterType]).forEach((filterName) => {
      let isVisibleIfFilterApplied = false;
      if (FILTER_TYPES_THAT_KEEP_COUNTS.includes(filterType)) {
        const { values } = SEARCH_FILTERS[filterType]?.[filterName] || {};
        const filterValues = values || [filterName];
        const filterFunction = FILTER_FUNCTIONS[filterType];

        isVisibleIfFilterApplied = filterFunction(filterValues, result);
      } else {
        const filterFunction = FILTER_FUNCTIONS[`${filterType}.${filterName}`];

        isVisibleIfFilterApplied = filterFunction(true, result);
      }

      /**
       * There are a list of filter types (FILTER_TYPES_THAT_KEEP_COUNTS) that will keep their counts when selecting
       * or deselecting filters within the filter group. These filters allow users to select multiple options within
       * the filter group and the counts will not change. However, counts should update in OTHER filter types.
       */
      const lastSelectedFilterType =
        state.orderedSelectedFilters.length > 0 &&
        state.orderedSelectedFilters[state.orderedSelectedFilters.length - 1];
      const lastDeselectedFilterIsWithinSameFilterType =
        state.lastDeselectedFilter && state.lastDeselectedFilter === lastSelectedFilterType;
      const lastSelectedFilterTypeShouldKeepCounts =
        lastSelectedFilterType &&
        filterType === lastSelectedFilterType &&
        FILTER_TYPES_THAT_KEEP_COUNTS.includes(lastSelectedFilterType);
      const lastDeselectedFilterTypeShouldKeepCounts =
        filterType === state.lastDeselectedFilter &&
        FILTER_TYPES_THAT_KEEP_COUNTS.includes(state.lastDeselectedFilter) &&
        state.orderedSelectedFilters.includes(filterType);
      const filterShouldKeepPreviousCount =
        (lastDeselectedFilterIsWithinSameFilterType || !state.lastDeselectedFilter) &&
        (lastSelectedFilterTypeShouldKeepCounts || lastDeselectedFilterTypeShouldKeepCounts);

      if (filterShouldKeepPreviousCount || !isVisibleIfFilterApplied || !isResultVisible) {
        return;
      }

      state[filterType][filterName].count += 1;
    });
  });
};

export const getSelectableFilter = <T>(filtersState: T, filterKey: SelectableFilterKey) => {
  const keys = filterKey?.split(".");

  return keys.reduce(
    (acc, key) => acc[key],

    filtersState
  ) as unknown as IBasicFilter;
};
