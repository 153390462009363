import styled from "styled-components";

import { buttonReset, colors, ellipsisText, sizes } from "@hotel-engine/styles";

export const CopyLabel = styled.p`
  padding: 0;
  margin: 0 0 4px;
`;

export const CopyLink = styled.button<{ isLinkCopied: boolean }>`
  ${buttonReset}
  padding: 12px 16px;
  background-color: ${colors.chefsHat};
  border-radius: 5px /* disable theming (do not replicate) */;
  width: 100%;
  text-align: start;
  line-height: 16px;
  font-weight: ${({ theme }) => theme.legacy.fontWeight.bold};
  ${ellipsisText}

  &:hover {
    cursor: ${({ isLinkCopied }) => (!isLinkCopied ? "pointer" : "not-allowed")};
  }

  span {
    font-weight: 400;
    color: ${colors.retroBlue};
    margin-left: auto;
    float: right;
    opacity: ${({ isLinkCopied }) => (!isLinkCopied ? "1" : ".5")};

    @media screen and (max-width: ${sizes.breakpoints.md}) {
      display: none;
    }
  }
`;

export const OrDivider = styled.div`
  margin-top: 32px;
  border-top: 1px solid ${colors.grey[100]};
  display: flex;
  justify-content: center;
`;

export const OrText = styled.span`
  font-weight: ${({ theme }) => theme.legacy.fontWeight.bold};
  display: inline-block;
  background: ${colors.white};
  margin: 0;
  transform: translateY(-55%);
  padding: 8px;
`;

export const FooterButtons = styled.div`
  padding: 0 16px;
  display: flex;
  justify-content: end;
  gap: ${({ theme }) => theme.spacing[8]};
  margin-top: ${({ theme }) => theme.spacing[24]};
`;
