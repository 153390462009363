// Use this utility to generate a download link for a user, given a PDF response from the server
export default (response, filename) => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  let mimeType = "";
  if (filename.endsWith(".pdf")) {
    mimeType = "application/pdf";
  } else if (filename.endsWith(".csv")) {
    mimeType = "text/csv";
  }
  const newBlob = new Blob([response], { type: mimeType });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (globalThis.navigator && globalThis.navigator.msSaveOrOpenBlob) {
    globalThis.navigator.msSaveOrOpenBlob(newBlob, filename);
    return;
  }

  const data = globalThis.URL.createObjectURL(newBlob);
  const link = document.createElement("a");
  link.href = data;
  link.download = filename;
  link.click();
  setTimeout(function () {
    // For Firefox its necessary to delay revoking the ObjectURL
    globalThis.URL.revokeObjectURL(data);
  }, 100);
};
