import styled from "styled-components";
import { Typography } from "@hotelengine/atlas-web";
import type { TypographyProps } from "@hotelengine/atlas-web";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";

const ClaimPersonalAccountCreation = styled.div`
  max-width: 560px;
  width: auto;
  padding: 10px 15px;
  ${mediaQuery("lg", "max")(`margin: ${({ theme }) => theme.legacy.space(2)}`)};

  .ant-modal-content {
    padding: ${({ theme }) => theme.spacing[2]};

    .ant-modal-body {
      padding: 0;
    }
  }

  .ant-modal-header {
    padding: 0;
    margin-bottom: ${({ theme }) => theme.spacing[2]};
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalTitle = styled<React.FC<TypographyProps>>(Typography)`
  color: ${({ theme }) => theme.colors.foregroundPrimary};
  text-align: center;
`;

const Divider = styled.div`
  display: flex;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.dividerPrimary};
  align-items: center;
  justify-content: center;
  margin: 40px 0 40px 0;
`;

const DividerText = styled.span`
  display: flex;
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  color: ${({ theme }) => theme.colors.foregroundTertiary};
  align-items: center;
  justify-content: center;
`;

const Footer = styled.div`
  margin-top: 8px;
  margin-bottom: 20px;
`;

export { ClaimPersonalAccountCreation, Header, ModalTitle, Divider, DividerText, Footer };
