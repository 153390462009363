/**
 * When you need to redirect away from members to an external domain.
 *
 * Example:
 * ```
 * {
 *   path: "/send-them-away",
 *   element: (
 *     <RedirectToExternalUrl externalUrl="https://www.google.com" />
 *   ),
 * }
 * ```
 */
export function RedirectToExternalUrl({
  externalUrl,
}: {
  externalUrl: string;
}) {
  globalThis.location.replace(externalUrl);

  return null;
}
