import { useEffect } from "react";

import { NotificationsContainer } from "@hotel-engine/app/Notifications";
import { Icon } from "@hotel-engine/common/Icon";
import { PROPERTY_REVIEWS } from "@hotel-engine/constants";
import { usePostCheckoutActions } from "@hotel-engine/contexts";
import { useSkipPostCheckoutActions } from "@hotel-engine/react-query/notifications/useSkipNotifications";
import { ampli } from "ampli";
import { useAppSelector } from "store/hooks";

import type { IPostCheckoutAction } from "@hotel-engine/types/notifications";
import * as Styled from "./styles";

export const NotificationsModal = () => {
  const user = useAppSelector((state) => state.Auth.user);

  // Post checkout context
  const { dispatch: dispatchPostCheckoutActions, state: postCheckoutActionsState } =
    usePostCheckoutActions();

  const dismissActions = useSkipPostCheckoutActions();

  const postCheckoutActions = postCheckoutActionsState.postCheckoutActions?.length
    ? postCheckoutActionsState.postCheckoutActions
    : user?.postCheckoutActions;

  const isVisible = postCheckoutActionsState.isModalVisible;

  const dismissModal = (actions: IPostCheckoutAction[]) => {
    dispatchPostCheckoutActions({
      type: "CLOSE_MODAL",
    });
    if (actions.length) {
      dismissActions.mutate(actions, {
        onSuccess: () => {
          dispatchPostCheckoutActions({
            type: "RESET_POST_CHECKOUT_ACTIONS",
          });
        },
      });
    } else {
      dispatchPostCheckoutActions({
        type: "RESET_POST_CHECKOUT_ACTIONS",
      });
    }
  };

  const dismissActiveAction = () => {
    return dismissModal(
      postCheckoutActions
        ? [postCheckoutActions[0]]
        : user?.postCheckoutActions[0]
          ? [user?.postCheckoutActions[0]]
          : []
    );
  };

  const dismissAll = () => {
    if (user?.postCheckoutActions) {
      ampli.clickCloseThePrompt({
        bookingId: user?.postCheckoutActions[0]?.contractNumber,
      });

      return dismissModal(user?.postCheckoutActions);
    } else {
      dispatchPostCheckoutActions({
        type: "CLOSE_MODAL",
      });
    }
  };

  useEffect(() => {
    /* Show the modal if:
      1. We have post-checkout actions for the user &&
      2. The user isn't on the property-reviews page
    This prevents a bug where the user tries to review a property twice and gets an error
    Preventing opening from here still allows other post-checkout actions to trigger on other pages
    */
    if (
      user?.postCheckoutActions?.length &&
      !globalThis.location.pathname.includes(PROPERTY_REVIEWS)
    ) {
      dispatchPostCheckoutActions({
        type: "OPEN_MODAL",
      });
    }
  }, [dispatchPostCheckoutActions, user?.postCheckoutActions?.length]);

  return (
    <>
      {!!user && !!postCheckoutActions?.length && (
        <Styled.PostCheckoutActionsModal
          closeIcon={<Icon data-testid={"closeicon"} icon={["fal", "times"]} />}
          footer={null}
          onCancel={dismissAll}
          title={null}
          visible={isVisible}
        >
          <NotificationsContainer
            postCheckoutActions={postCheckoutActions}
            dismissModal={dismissActiveAction}
            referrer="modal"
          />
        </Styled.PostCheckoutActionsModal>
      )}
    </>
  );
};
