import {
  Icon,
  Dialog,
  Box,
  Typography,
  DialogContent,
  DialogTitle,
  DialogDescription,
} from "@hotelengine/atlas-web";

export const ErrorModal = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <Dialog isOpen={isOpen}>
      <DialogContent>
        <DialogTitle>
          <Box gap={8} display="flex" justifyContent="center" marginBottom={16} alignItems="center">
            <Icon name="circle-exclamation" />
            <Typography color="foregroundPrimary" marginBottom={0} variant="heading/xl">
              It's not you, it's us.
            </Typography>
          </Box>
        </DialogTitle>
        <DialogDescription>
          <Typography color="foregroundPrimary" marginBottom={24} variant="body/sm">
            Something happened on our platform while you were trying to connect. Please contact us
            at <a href="mailto:support@hotelengine.com">support@hotelengine.com</a> to resolve.
          </Typography>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
};
