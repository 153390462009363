import storage from "@hotel-engine/storage";

const userPrefs = () => {
  let prefsString: string | null;
  try {
    prefsString = storage.getItem("he_user_prefs");
  } catch (e) {
    prefsString = "{}";
  }

  // define initial values on a per he_user_prefs property basis and destructure into prefs
  const initialValues = {
    map_preference: "basic",
    sort_preference: 0,
    userSpecific: {},
  };

  const prefs = {
    ...initialValues,
    ...(prefsString ? JSON.parse(prefsString) : {}),
  };

  return class UserPrefs {
    public static get(key, forUserId?: number) {
      if (forUserId) {
        const { userSpecific } = prefs;
        // if user doesn't exist in client preference then default on `prefs` root
        if (userSpecific && userSpecific[forUserId] && userSpecific[forUserId][key]) {
          return userSpecific[forUserId][key];
        }
      }

      return prefs[key];
    }

    public static set(key, value, forUserId?: number) {
      if (forUserId) {
        const { userSpecific } = prefs;
        // if user doesn't exist in client preference then resume original behavior
        if (userSpecific) {
          if (userSpecific[forUserId]) {
            // update
            userSpecific[forUserId][key] = value;
          } else {
            // create
            userSpecific[forUserId] = { [key]: value };
          }
          this._save();
          return;
        }
      }
      prefs[key] = value;
      this._save();
    }

    public static _save() {
      try {
        storage.setItem("he_user_prefs", JSON.stringify(prefs));
      } catch (e) {
        // Do nothing
      }
    }
  };
};

export default userPrefs();
