import * as Styled from "./styles";

/**
 * The `Form` component wraps antd's `<Form />` component.
 *
 * @remarks Since we are leveraging antd for most components, our internal Form component does not have any of it's own props. Instead, we are directly referring to the interface definition antd is expecting. This allows us to easily be in alignment with their type definitions and prevents type errors that may arise from maintaining an override interface.
 *
 * @see {@link https://3x.ant.design/components/form/ AntD Form Documentation}
 */
export const Form = Styled.AntForm;

export const FormItem = Styled.AntFormItem;

export type { FormItemProps } from "antd/lib/form";
