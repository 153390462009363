import { useMemo } from "react";

import { parse } from "qs";
import { useLocation } from "@hotel-engine/lib/react-router-dom";

export function useSearchParams<T>(): T {
  const location = useLocation();
  return useMemo(
    () => parse(location.search, { ignoreQueryPrefix: true }) as unknown as T,
    [location.search]
  );
}
