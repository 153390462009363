import config from "config";

import Api from "./Api";
import Resource from "./Resource";

/**
 * @deprecated - switch to the associated react-query hooks if possible
 */
export default class ShareOptions extends Resource {
  // Duplicate of the constructor from ReadOnlyResource;
  // The context of "this" got broken in the upgrade to CRA,
  // so calling it explicitly here as a hack
  constructor(params?) {
    super(params);
    Object.assign(this, params);
  }

  public static async sendOptions(params) {
    try {
      return await Api.post(`${config.apiHostV2}/users/share_hotel_options`, params);
    } catch (error) {
      return error;
    }
  }
}
