import { Field } from "formik";

import CheckboxField from "@hotel-engine/common/FormikFields/CheckboxField";

import InputField from "./InputField";
import * as Styled from "./styles";
import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";

interface ICardSetupProps {
  /** boolean if form to authorize company to charge card */
  requireAuthorization: boolean;
  /** boolean if the form should add a margin to input elements */
  addMargin: boolean;
  /** boolean if form has been submitted or not */
  submitted: boolean;
  /** Whether the form is rendering in a modal or not. Used in FlexPro enrollment and management */
  isRenderedInModal?: boolean;
  /** whether the form is used for adding autopay */
  isAutopayModal?: boolean;
}

const CardSetup = ({
  requireAuthorization,
  addMargin,
  submitted,
  isRenderedInModal,
  isAutopayModal,
}: ICardSetupProps) => {
  const { COMPANY_NAME } = useCompanyName();
  return (
    <>
      <Styled.FromSection addMargin={addMargin}>
        {(!!isAutopayModal || !isRenderedInModal) && (
          <InputField
            name="nickname"
            placeholder="Ex: Personal Travel"
            label="Card nickname (optional)"
            colon={false}
            submitted={submitted}
          />
        )}
        {!isRenderedInModal && (
          <Field name="default" component={CheckboxField} label="Make default card" />
        )}
        {!!requireAuthorization && (
          <Field
            name="authorized"
            component={CheckboxField}
            label={`I authorize ${COMPANY_NAME} to charge my credit card once I click 'Submit Payment' below.`}
          />
        )}
      </Styled.FromSection>
    </>
  );
};

export default CardSetup;
