/* eslint-disable
  @typescript-eslint/no-namespace,
  @typescript-eslint/ban-types,
  prefer-rest-params,
  @typescript-eslint/no-non-null-assertion,
 */
import { type Any, Fn, Result } from "@hotel-engine/data"
import { is } from "../core"

export {
  Safe,
  Unsafe,
}

namespace Local {
  /** @internal */
  export const die = (msg: string): never => { throw Error(msg) }
}

namespace Safe {
  export const parse
    : <A, B> (guard: Fn.TypePredicate<[A, B]>, msg?: string) => (u: A) => Result.Result<B, string>
    = (guard, msg) =>
      Result.fromPredicate(
        guard,
        (u) => msg ?? `Value passed to type guard failed to parse. Received: ${String(u)}`
      )


  export const handleIllegalState
    : (fnName: string, ...args: Any.Array) => string
    = (fnName, ...args) =>
      `Encountered illegal state in \`${fnName}\`, received: ${args
        .map(String)
        .join(`, `)}`
    ;

  export const parseError
    : (p: Any.Index | Any.Array<Any.Index>) => string
    = (p) =>
      is.index(p)
        ? typeof p === `symbol`
          ? `Did not find \`symbol\` property in object passed to \`Has\``
          : `Received a value that failed to pass guard: \`Has("${p}")\``
        : `Received a value that failed to pass guard: \`Has(${p
          .map((_) => (typeof _ === "symbol" ? `"symbol"` : `"${_}"`))
          .join(`, `)})\``
    ;

  export const pathCollisionDetected
    : (collisions: Any.Array<readonly [string, Any.Array<string>]>) => string
    = (collisions) =>
      collisions
        .map(([hd, tl]) => [hd, ...tl])
        .reduce(
          (acc, curr) => acc.concat(curr.join(", ")).concat("\n"),
          collisions.length > 1 ? `Path collisions detected: ` : `Path collision detected: `
        )
    ;
}

namespace Unsafe {
  export const die = Local.die

  export const assert
    : <T>(guard: Fn.Guard<T>, msg?: string) => (u: unknown) => T
    = (guard, msg) => (u: unknown) =>
      Fn.pipe(
        u,
        Safe.parse(guard, msg),
        Result.unsafeUnwrap,
      )

  export const handleIllegalState
    : (fnName: string, ...args: Any.Array) => never
    = Fn.flow(Safe.handleIllegalState, Local.die)

  export const parseError
    : (p: Any.Index | Any.Array<Any.Index>) => never
    = Fn.flow(Safe.parseError, Local.die)

  export const pathCollisionDetected
    : (collisions: Any.Array<readonly [string, Any.Array<string>]>) => never
    = Fn.flow(Safe.pathCollisionDetected, Local.die)
}
