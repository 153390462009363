import { useState } from "react";

import { Field, Formik, useFormikContext } from "formik";
import * as Yup from "yup";

import LoyaltyBadge from "@hotel-engine/assets/LoyaltyBadge";
import { ProgramLengthWarning } from "@hotel-engine/app/HotelLoyalty/ProgramLengthWarning";
import Button from "@hotel-engine/common/Button";
import InputField from "@hotel-engine/common/FormikFields/InputField";
import { notification } from "@hotel-engine/common/Notifications";
import type { ISearchAndRoomRateID } from "@hotel-engine/hooks";
import { useToggle, useURLParams } from "@hotel-engine/hooks";
import { useUpdateGuest } from "@hotel-engine/react-query/guest/useUpdateGuest";
import {
  loyaltyRewardUpdateReq,
  useUpdateUserLoyaltyRewards,
} from "@hotel-engine/react-query/loyaltyRewards/useUpdateUserLoyaltyRewards";
import { useUserLoyaltyRewardsCreate } from "@hotel-engine/react-query/userLoyaltyRewards/useUserLoyaltyRewardsMutation";
import { colors } from "@hotel-engine/styles";
import type { IOccupant, IPropertyLoyaltyRewards } from "@hotel-engine/types/booking";
import type { IUser, IUserLoyaltyProgram } from "@hotel-engine/types/user";
import { captureException } from "@hotel-engine/utilities";
import { ampli } from "ampli";
import { useAppSelector } from "store/hooks";

import type { ICheckoutFormData } from "../../../../validation";
import { useLoyaltyRewards } from "../../../hooks/useLoyaltyRewards";
import * as Styled from "./styles";
import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";

export const LoyaltyFormSchema = Yup.object().shape({
  loyaltyRewardProgramNumber: Yup.string().required("Required"),
});

export interface ILoyaltyForm {
  /** the selected guest */
  selectedGuest: IOccupant;
  /** handle closing of loyalty form */
  handleCancel: () => void;
  /** The index of the room corresponding to this guest */
  index: number;
  passedPropertyLoyaltyRewards?: IPropertyLoyaltyRewards;
}

export default function LoyaltyForm({
  selectedGuest,
  handleCancel,
  index,
  passedPropertyLoyaltyRewards,
}: ILoyaltyForm) {
  const { COMPANY_NAME } = useCompanyName();
  const [isEditingWithLoyalty, setIsEditingWithLoyalty] = useState<boolean>(false);

  const user = useAppSelector((state) => state.Auth.user);
  const createUserLoyaltyRewards = useUserLoyaltyRewardsCreate();
  const updateGuest = useUpdateGuest();
  const { propertyLoyaltyRewards: propertyLoyaltyRewardsFromHook } = useLoyaltyRewards();
  const propertyLoyaltyRewards = passedPropertyLoyaltyRewards || propertyLoyaltyRewardsFromHook;
  // This Formik Context comes from the parent Formik context for GuestSelection //
  const { values, setFieldValue } = useFormikContext<ICheckoutFormData>();
  const updateUserLoyaltyRewards = useUpdateUserLoyaltyRewards();
  const [isWarningActive, toggleWarning] = useToggle();

  // Determine whether saving HLPs is allowed
  const allowSavingHotelLoyaltyRewardsForGuests = Boolean(
    user?.business?.loyaltyRewardsEnabled && !selectedGuest.businessId
  );
  const allowSavingHotelLoyaltyRewardsForMembers = Boolean(
    user?.business?.loyaltyRewardsEnabled &&
      selectedGuest.businessId === user?.businessId &&
      (user?.role === "admin" || user?.role === "coordinator")
  );

  // Grabs necessary info for usage tracking //
  const {
    params: { propertyId },
    search: { roomRateId, s },
  } = useURLParams<ISearchAndRoomRateID>();

  const { loyaltyInfo } = values.guestList[index];
  const userIsSelectedGuest = user?.id === selectedGuest.id;
  const isGuestSaved = Boolean(selectedGuest.id);

  // Sets successful submission info into parent Formik state //
  const setLoyaltyInfo = (newLoyaltyInfo: IUserLoyaltyProgram) => {
    setFieldValue(`guestList.${index}`, {
      ...values.guestList[index],
      loyaltyInfo: newLoyaltyInfo,
    });
  };

  const handleSubmit = async (submittedValues: {
    loyaltyRewardProgramNumber: string;
  }) => {
    ampli.clickSaveLoyaltyNumber({
      propertyId: Number(propertyId),
      searchId: Number(s),
      roomRateId: Number(roomRateId),
    });

    if (propertyLoyaltyRewards.id === undefined) {
      throw new Error(
        "Unable to process loyalty rewards submit - property rewards data is undefined."
      );
    }

    // Check if loyalty program number length is valid
    if (
      submittedValues.loyaltyRewardProgramNumber.length !==
        propertyLoyaltyRewards.programNumberLength &&
      !isWarningActive
    ) {
      toggleWarning();
      // create an updated guest based on info from the selected guest,
      // and the newly added program number
    } else if (userIsSelectedGuest) {
      createUserLoyaltyRewards.mutate(
        {
          loyaltyRewardId: propertyLoyaltyRewards.id,
          programNumber: submittedValues.loyaltyRewardProgramNumber,
          userId: (user as IUser).id,
        } as IUserLoyaltyProgram,
        {
          onSuccess: (response) => {
            notification.success({
              message: "Your loyalty number has been saved to your Settings",
            });

            // add to loyalty info if successful to display purple badge
            setLoyaltyInfo(response);
            setIsEditingWithLoyalty(false);
            if (isWarningActive) {
              toggleWarning();
            }
          },
          onError: (error) => {
            notification.error({
              message: "Something went wrong, please try again.",
            });
            captureException(error);
          },
        }
      );
      // Update members with new loyalty information if permitted
    } else if (isGuestSaved && allowSavingHotelLoyaltyRewardsForMembers) {
      updateUserLoyaltyRewards.mutate(
        loyaltyRewardUpdateReq(
          selectedGuest.id as number,
          propertyLoyaltyRewards.id,
          submittedValues.loyaltyRewardProgramNumber
        ),
        {
          onSuccess: ([updatedRecord]) => {
            notification.success({
              message: "Your loyalty number has been saved to your Settings",
            });

            // add to loyalty info if successful to display purple badge
            setLoyaltyInfo(updatedRecord);
            setIsEditingWithLoyalty(false);
            if (isWarningActive) {
              toggleWarning();
            }
          },
          onError: (error) => {
            notification.error({
              message: "Something went wrong, please try again.",
            });
            captureException(error);
          },
        }
      );
      // Update the guest with new loyalty information if guest has previously been saved
    } else if (isGuestSaved && allowSavingHotelLoyaltyRewardsForGuests) {
      updateGuest.mutate(
        {
          guest: {
            id: selectedGuest.id as number,
            firstName: selectedGuest.firstName,
            lastName: selectedGuest.lastName,
            email: selectedGuest.email as string,
            phone: selectedGuest.phone as string,
            guestLoyaltyRewards: [
              {
                loyaltyRewardId: propertyLoyaltyRewards.id as number,
                loyaltyRewardName: propertyLoyaltyRewards.name as string,
                programNumber: submittedValues.loyaltyRewardProgramNumber,
              },
            ],
          },
        },
        {
          onSuccess: () => {
            setLoyaltyInfo({
              loyaltyRewardId: propertyLoyaltyRewards.id,
              loyaltyRewardName: propertyLoyaltyRewards.name,
              programNumber: submittedValues.loyaltyRewardProgramNumber,
            } as IUserLoyaltyProgram);
            setIsEditingWithLoyalty(false);
            if (isWarningActive) {
              toggleWarning();
            }
          },
          onError: (error) => {
            notification.error({
              message: "Something went wrong, please try again.",
            });
            captureException("Error saving guest loyalty program info in LoyaltyForm", {
              error,
            });
          },
        }
      );
      // Update loyalty information for this booking without saving information to the guest
    } else {
      setLoyaltyInfo({
        loyaltyRewardId: propertyLoyaltyRewards.id,
        loyaltyRewardName: propertyLoyaltyRewards.name,
        programNumber: submittedValues.loyaltyRewardProgramNumber,
      } as IUserLoyaltyProgram);
      setIsEditingWithLoyalty(false);
      if (isWarningActive) {
        toggleWarning();
      }
    }
  };

  const handleEditLoyalty = (programNumber) => {
    setIsEditingWithLoyalty(true);
    ampli.editLoyaltyNumber({
      propertyId: Number(propertyId),
      searchId: Number(s),
      roomRateId: Number(roomRateId),
      typedText: programNumber,
    });
  };

  const handleCancelLoyaltyForm = (fieldSetter: (field: string, value: string) => void) => {
    if (isEditingWithLoyalty) {
      // show loyalty as previously entered
      setIsEditingWithLoyalty(false);
      if (isWarningActive) {
        toggleWarning();
      }
    } else {
      // close loyalty form and clear values
      fieldSetter("loyaltyRewardProgramNumber", "");
      fieldSetter("loyaltyRewardName", "");
      handleCancel();
    }
  };

  // if loyalty is saved and not being edited
  if (loyaltyInfo && Object.keys(loyaltyInfo).length && !isEditingWithLoyalty) {
    const { programNumber } = loyaltyInfo;
    // if programNumber is longer than 4 only use last 4 digits, otherwise display the whole programNumber
    const displayNum =
      programNumber?.length > 4 ? programNumber?.slice(programNumber.length - 4) : programNumber;

    return (
      <Styled.LoyaltyBadge data-testid="hotel-loyalty-section">
        <LoyaltyBadge fill={colors.dragonlordPurple} />
        <Styled.ProgramName>{propertyLoyaltyRewards.name}</Styled.ProgramName>
        &bull;
        <Styled.ProgramNum>{displayNum}</Styled.ProgramNum>
        <Styled.EditProgramNum type="link" onClick={() => handleEditLoyalty(programNumber)}>
          Edit Loyalty
        </Styled.EditProgramNum>
      </Styled.LoyaltyBadge>
    );
  }

  return (
    <>
      <Formik
        initialValues={{
          loyaltyRewardName: propertyLoyaltyRewards.name || "",
          loyaltyRewardProgramNumber: loyaltyInfo?.programNumber || "",
        }}
        onSubmit={handleSubmit}
        isInitialValid={false}
        validationSchema={LoyaltyFormSchema}
      >
        {({ handleSubmit: submitForm, setFieldValue: setValue }) => (
          <Styled.OuterForm onSubmit={submitForm}>
            <>
              <Styled.InnerForm>
                <Styled.LoyaltyInfoContainer>
                  <Styled.InputContainer>
                    <Field
                      name="loyaltyRewardName"
                      disabled
                      component={InputField}
                      placeholder="Program Name"
                      label="Hotel loyalty program"
                    />
                  </Styled.InputContainer>
                  <Styled.InputContainer>
                    <Field
                      name="loyaltyRewardProgramNumber"
                      component={InputField}
                      placeholder="Rewards Number"
                      label="Loyalty Number"
                    />
                  </Styled.InputContainer>
                </Styled.LoyaltyInfoContainer>
              </Styled.InnerForm>
              <ProgramLengthWarning visible={Boolean(isWarningActive)} />
              {userIsSelectedGuest ? (
                <Styled.LoyaltyPassThruMsg>
                  <span>
                    {COMPANY_NAME} will pass your loyalty number to the property along with your
                    reservation.
                  </span>
                </Styled.LoyaltyPassThruMsg>
              ) : (
                <Styled.LoyaltyPassThruMsg>
                  <span>
                    You can manage hotel loyalty program accounts on the Members page or in
                    Settings.
                  </span>
                </Styled.LoyaltyPassThruMsg>
              )}
              <Styled.Buttons>
                <Button type="primary" size="default" htmlType="submit">
                  {userIsSelectedGuest ? "Save" : "Add"}
                </Button>
                <Button
                  type="default"
                  size="default"
                  onClick={() => handleCancelLoyaltyForm(setValue)}
                >
                  Cancel
                </Button>
              </Styled.Buttons>
            </>
          </Styled.OuterForm>
        )}
      </Formik>
    </>
  );
}
