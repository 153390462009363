import type { Moment } from "moment";
import { default as moment } from "moment";

import { useAppSelector } from "store/hooks";
import { formatDateDictionary } from "@hotel-engine/constants/formatDateDictionary";

/**
 * A helper function to format dates by the preferred date format set in company settings for the business
 * @param date date string to be formatted
 * @param displayFormat matched moment string from formatDateDictionary
 * @param utc defaults as false, if you wish to intereact with date as an UTC date use true
 * @returns a formatted moment string
 */

export const useFormatDate = (
  date: string | Moment | undefined | null,
  displayFormat: keyof typeof formatDateDictionary,
  utc = false
) => {
  const { business } = useAppSelector((state) => state.Auth.user) || {
    business: { preferredDateFormat: "mdy" },
  };
  const { preferredDateFormat } = business;

  if (!date) return null;

  const preferredDisplayFormat = formatDateDictionary[displayFormat][preferredDateFormat];

  return utc
    ? moment.utc(date).format(preferredDisplayFormat)
    : moment(date).format(preferredDisplayFormat);
};
