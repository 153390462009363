import { tokenKey } from "@hotel-engine/constants";
import storage from "@hotel-engine/storage";
import type { GetTokenSilentlyOptions, RedirectLoginOptions } from "@auth0/auth0-react";
import { useAuth0, MissingRefreshTokenError } from "@auth0/auth0-react";

import { hasAuth0AccessToken, isAccessTokenInJson } from "./helpers";

/**
 * Get the access token from Auth0 with the useAuth0 hook.
 * If there is a missing refresh token, it will redirect to the login page.
 * This can happen if the refresh token is invalid or missing due to updated scopes.
 * We only want to redirect if there is an existing access token in the cache.
 * @param options Options for getting the token.
 * @param redirectOptions Options for redirecting to the login page.
 * @returns A function that returns the access token.
 */
export const useGetAccessTokenSilently = () => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  return async (options?: GetTokenSilentlyOptions, redirectOptions?: RedirectLoginOptions) =>
    getTokenSilently(getAccessTokenSilently, loginWithRedirect, options, redirectOptions);
};

const getTokenSilently = async (
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>,
  loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>,
  options?: GetTokenSilentlyOptions,
  redirectOptions?: RedirectLoginOptions
): Promise<string | undefined> => {
  try {
    return await getAccessTokenSilently(options);
  } catch (e: unknown) {
    if (
      e instanceof MissingRefreshTokenError &&
      (e.error === "missing_refresh_token" || e.error === "invalid_grant") &&
      hasAuth0AccessToken()
    ) {
      void loginWithRedirect(redirectOptions);
      throw e;
    }
    return;
  }
};

const convertGetFromAuth0 = async (token: string | null) => {
  if (token !== "getFromAuth0") return token || "";

  if (typeof globalThis?.getAccessTokenSilently === "function") {
    // This will call the /oauth/token endpoint since we use refresh tokens
    const redirectPath = `${globalThis.location.origin}/${
      globalThis.location.pathname == "/"
        ? ""
        : `?return=${encodeURIComponent(globalThis.location.href)}`
    }`;
    const auth0Token = await globalThis?.getAccessTokenSilently(undefined, {
      authorizationParams: {
        redirect_uri: redirectPath,
      },
    });
    if (auth0Token) {
      return auth0Token;
    }
  }
  return token;
};

export const getAccessToken = async () => {
  const token = storage.getSecureItem(tokenKey);
  if (token && isAccessTokenInJson(token)) {
    const parsedTokens = JSON.parse(token);
    return await convertGetFromAuth0(parsedTokens[parsedTokens.latestAccountType]);
  }
  return await convertGetFromAuth0(token);
};

export const getPersonalToken = () => {
  const token = storage.getSecureItem(tokenKey);
  if (token && isAccessTokenInJson(token)) {
    const parsedTokens = JSON.parse(token);
    return parsedTokens.personal;
  }
  return null;
};

export const getBusinessToken = () => {
  const token = storage.getSecureItem(tokenKey);
  if (token && isAccessTokenInJson(token)) {
    const parsedTokens = JSON.parse(token);
    return parsedTokens.business;
  }
  return null;
};
