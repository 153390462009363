/**
 * @deprecated The zIndex object is being deprecated to make room for the upcoming Atlas theme. Please reach out to the Atlas team with any questions.
 *
 * Here are the beginnings of a z-index variable to give us some consistency throughout the application.
 *  As you find you need a z-index for something, if it has the potential for reuse we will add it to this object.
 *  You can use above/below to calculate the implementation of a z-index in relation to another. E.g. below + modal
 */

export const zIndex = {
  backdrop: 0,
  modal: 999,
  sticky: 300,
  above: 1,
  below: -1,
} as const;
