import styled, { css } from "styled-components";

import { colors } from "@hotel-engine/styles";

import type { IBadgeProps } from "./";

const statusColors = {
  success: colors.green[600],
  warning: colors.carrotCurl,
  error: colors.khmerCurry,
  loading: colors.clearChill,
  default: colors.black,
  idle: colors.piedWagtailGrey,
};

const sizes = {
  xs: 12,
  sm: 14,
  md: 16,
  lg: 20,
  xl: 24,
  xxl: 32,
};

const defaults = {
  size: "sm",
  status: "default",
  variant: "filled",
};

export const BadgeWrapper = styled.span<IBadgeProps>`
  display: inline-block;

  ${({ variant = defaults.variant, status = defaults.status }) =>
    variant === "filled"
      ? css`
          color: ${colors.white};
          background: ${statusColors[status]};
        `
      : css`
          color: ${statusColors[status]};
          background: ${colors.white};
          border: solid 1px ${statusColors[status]};
        `}

  ${({ size = defaults.size, variant = defaults.variant }) => css`
    font-size: ${sizes[size]}px;
    border-radius: ${sizes[size]}px;
    padding: ${(sizes[size] - 4) / 2}px
      ${(sizes[size] / 4) * 3 - (variant === "outlined" ? 1 : 0)}px;
    line-height: ${sizes[size] + (variant === "outlined" ? 2 : 4)}px;
    height: ${sizes[size] * 2}px;
  `}
`;
