import type { IUser } from "@hotel-engine/types/user";

/** Custom validator should return true if the route should be
 *  accessible based on custom logic in the function
 * */
export type AuthValidatorFn = (user: IUser) => boolean;

/** Returns true if all custom validator functions are satisfied (return true) */
export const validateCustomAuth = (customAuth: AuthValidatorFn[] = [], user: IUser) => {
  return Boolean(user) && customAuth.every((authValidator: AuthValidatorFn) => authValidator(user));
};
