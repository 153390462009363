import moment from "moment";

// determines if the reservation is being made before midnight the day of checkIn
// checkIn string must be in format "MM/DD/YYYY"
export const isBeforeFlexCutoff = (checkIn: string, timezone = "UTC") => {
  const currentTime = moment().tz(timezone);
  const latestFlexCancel = moment(`${checkIn}T00:00:00`, "MM/DD/YYYY HH:mm").tz(timezone, true);

  return currentTime.isBefore(latestFlexCancel);
};

export const showFlexCancel = ({
  cancelBy,
  checkIn,
  checkOut,
  propertyTimezone,
  roomCount: _,
  user,
  flexConfigurationNotBlocked = true,
}) => {
  const expectedDateFormats = ["MM-DD-YYYY", "YYYY-MM-DD"];
  const checkInAsMoment = moment(checkIn, expectedDateFormats);
  const checkOutAsMoment = moment(checkOut, expectedDateFormats);
  const numberOfNights = checkOutAsMoment.diff(checkInAsMoment, "days");

  const isCancelByAfterNoonAndSingleNightBooking = Boolean(
    cancelBy &&
      moment(cancelBy)
        .tz(propertyTimezone || "UTC", true)
        .isSameOrAfter(
          checkInAsMoment
            .utc()
            .tz(propertyTimezone || "UTC", true)
            .hour(12)
        ) &&
      numberOfNights === 1
  );

  const isSameDayAndSingleNightBooking =
    checkInAsMoment.isSame(moment(), "day") && numberOfNights === 1;

  return Boolean(
    !isSameDayAndSingleNightBooking &&
      !isCancelByAfterNoonAndSingleNightBooking &&
      user?.business.flexAllowedBusiness &&
      user?.business.flexAllowedUser &&
      flexConfigurationNotBlocked
  );
};

export const isAfterCancelBy = (cancelBy: string | null) => {
  if (!cancelBy) return true;
  return moment().isAfter(cancelBy, "hour");
};
