import type { IRatingDescription } from "@hotel-engine/types/property";

/**
 *
 * @param rating property rating
 * @param hasReviewsCount boolean value
 * @returns ratingToDisplay: the string to be shown on the front
 * @returns ratingDescription: descriptoin of the rating in levels
 */
export const getRatingConversion = (
  rating: string | number | undefined | null,
  hasReviewsCount = true
): {
  ratingToDisplay: string;
  ratingDescription: IRatingDescription;
} => {
  if (rating === null || rating === undefined || !hasReviewsCount) {
    return {
      ratingToDisplay: "N/A",
      ratingDescription: "None",
    };
  }

  let numRating = Number(rating);

  const ratingDescription =
    numRating < 3
      ? "Terrible" // 1, 2
      : numRating < 5
        ? "Poor" // 3, 4
        : numRating < 7
          ? "Good" // 5, 6
          : numRating < 9
            ? "Great" // 7, 8
            : "Excellent"; // 9, 10

  numRating /= 2;

  // floor round to 1-digit number
  numRating = Math.floor(numRating * 10) / 10;

  const ratingToDisplay = numRating.toFixed(1).replace(".0", "");

  return {
    ratingToDisplay,
    ratingDescription,
  };
};
