import { createContext, useContext } from "react";
import PolicyTerm from "./PolicyTerm";
import SignUpForm from "./SignUpForm";
import SocialSignUp from "./SocialSignUp";

import * as Styled from "./styles";
import type { IPersonalAccountCreationScreen } from "../PersonalAccountCreationModal";
import { Box } from "@hotelengine/atlas-web";

export interface ISignUpProps {
  onNavigate(step: IPersonalAccountCreationScreen): void;
  onClose(): void;
  onSetEmail(value: string): void;
}

interface IProviderProps {
  value: ISignUpProps;
}

const Context = createContext({} as ISignUpProps);

const SignUpProvider: React.FC<IProviderProps> = (props) => <Context.Provider {...props} />;

const useSignUpNavigate = () => {
  const context = useContext(Context);

  return context;
};

const SignUp: React.FC<ISignUpProps> = (props) => {
  return (
    <SignUpProvider value={props}>
      <Styled.ClaimPersonalAccountCreation>
        <Styled.Header>
          <Styled.ModalTitle variant="heading/2xl" data-testid="claim-account-title">
            Claim your personal account
          </Styled.ModalTitle>
        </Styled.Header>

        <SocialSignUp />

        <Styled.Divider>
          <Styled.DividerText>or</Styled.DividerText>
        </Styled.Divider>

        <Box>
          <SignUpForm />
        </Box>

        <Styled.Footer data-testid="policy-term">
          <PolicyTerm />
        </Styled.Footer>
      </Styled.ClaimPersonalAccountCreation>
    </SignUpProvider>
  );
};

export { useSignUpNavigate, SignUpProvider };
export default SignUp;
