import type { Types } from "@amplitude/analytics-browser";

export const createEnrichmentPageUrlPlugin = (): Types.EnrichmentPlugin => {
  return {
    name: "enrichPageUrlPlugin",
    type: "enrichment",
    execute: async (event: Types.Event) => {
      event.event_properties = {
        ...event.event_properties,
        viewUrl: globalThis.location?.href,
      };

      return event;
    },

    setup: async () => {
      return;
    },
  };
};
