import { Box, Typography } from "@hotelengine/atlas-web";
import * as Styled from "./styles";

import { type IAuthenticatorCardProps, type AuthenticatorKind } from "./authenticator";
import { type IconName } from "@hotelengine/atlas-core";
import { isEmail } from "./util";
import { AuthenticatorInfo } from "./AuthenticatorInfo";

const getIcon = (kind: AuthenticatorKind): IconName => {
  switch (kind) {
    case "email":
      return "envelope";
    case "sms":
      return "message-lines";
    case "voice":
      return "phone";
  }
};

const getDescriptor = (kind: AuthenticatorKind): string => {
  switch (kind) {
    case "email":
      return "Email";
    case "sms":
      return "SMS";
    case "voice":
      return "Voice";
  }
};

const AuthenticatorCard = ({
  authenticator,
  mfaEnabled,
  setHasErrors,
}: IAuthenticatorCardProps) => {
  return (
    <Styled.CardContainer data-testid={`account-security-${authenticator.kind}-card`}>
      <Box>
        <Styled.MFAOptionIcon name={getIcon(authenticator.kind)} />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography
          as="h3"
          color="foregroundPrimary"
          marginBottom={isEmail(authenticator.kind) ? 4 : 0}
          variant="heading/lg"
        >
          {getDescriptor(authenticator.kind)}
        </Typography>
        {isEmail(authenticator.kind) ? (
          <Typography as="p" color="foregroundSecondary" marginBottom={0} variant="body/xs">
            Also used for password recovery
          </Typography>
        ) : null}
      </Box>
      <Box style={{ marginLeft: "auto" }}>
        <Styled.CardValueContainer>
          <AuthenticatorInfo
            authenticator={authenticator}
            mfaEnabled={mfaEnabled}
            setHasErrors={setHasErrors}
          />
        </Styled.CardValueContainer>
      </Box>
    </Styled.CardContainer>
  );
};

export { AuthenticatorCard, isEmail };
