import styled from "styled-components";

import { colors } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";

export const Dot = styled.div<{ isActive: boolean }>`
  background: ${({ isActive }) => (isActive ? colors.grey[200] : colors.grey[800])};
  width: 5px;
  height: 5px;
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.x100};
  margin-right: 5px;
`;

export const DotWrapper = styled.div`
  ${flexbox({ justifyContent: "center", alignItems: "center" })}
`;

export const PlaceHolder = styled.div`
  width: 63px;
`;

export const Hero = styled.div<{ background?: string }>`
  ${flexbox({ justifyContent: "center", alignItems: "center" })}
  height: 200px;
  width: 100%;
  ${({ background }) => background && `background: url(${background})`};
`;

export const HeroImage = styled.img`
  max-width: 400px;
`;

export const HeroVideo = styled.div`
  padding: 56.25% 0 0 0;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const ContentsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing[24]};
`;
