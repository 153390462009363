import styled from "styled-components";

import { colors } from "@hotel-engine/styles";

export const InternationalPhoneInputWrapper = styled.div`
  & > .react-tel-input > input.form-control {
    border-color: 1px solid ${colors.grey[200]};
    font-family: ${({ theme }) => theme.legacy.fontFamily.primary};
    line-height: 1;
    height: 40px;
    padding: 6px 11px 6px 48px;
    width: 100%;
  }

  .selected-flag {
    background-color: ${colors.grey[100]};
    transition: 0.2s ease all;

    &:hover {
      background-color: ${colors.grey[200]};
    }
  }
`;

export const Label = styled.label`
  display: flex;
  margin-bottom: 4px;
  color: ${colors.grey[800]};
  line-height: 20px;
`;

export const ErrorText = styled.p`
  color: ${colors.khmerCurry};
`;

export const ValidationContent = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
