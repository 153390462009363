import moment from "moment";
import { useEffect, useMemo, useRef, useCallback } from "react";
import * as Sentry from "@sentry/browser";
import { pick } from "lodash";
import config from "config";
import { fieldPaths } from "@hotel-engine/types/pendoMetaData";
import type { PickedMetaFields } from "@hotel-engine/types/pendoMetaData";
import { useAppSelector } from "store/hooks";
import { useAchPaymentProfileQuery } from "@hotel-engine/react-query/achPaymentProfile/useAchPaymentProfileQuery";
import { useTravelPoliciesQuery } from "@hotel-engine/react-query/travelPolicies/useTravelPoliciesQuery";

function testRunHelper(obj: Record<string, unknown>) {
  Object.keys(obj).forEach((key) => {
    obj[key] = `test_${typeof obj[key] === "string" ? obj[key] : JSON.stringify(obj[key])}`;
  });
}

function generatePendoMetaPayload(picked: PickedMetaFields, productionSession: boolean) {
  const {
    id,
    personalTravelUserId,
    businessTravelUserId,
    businessId,
    accountType,
    activePropertyReviewRequests,
    business,
    businessTravelUserBusinessId,
    canBookWithIncidentals,
    completedBookingCount,
    confirmedAt,
    countryCode,
    directBillConfiguration,
    directBillOnboarding,
    directBill,
    email,
    employeeId,
    firstName,
    firstTimeSignIn,
    hasAchProfile,
    hasTravelPolicyWithDB,
    hasReportingAccess,
    hasGroupsSpecialization,
    incidentalsConfiguration,
    lastSignInAt,
    occupantType,
    promptForPropertyReviews,
    rewardsMember,
    rewardsProfile,
    role,
    signInCount,
    shouldPromptForPropertyReviews,
  } = picked;

  const mappedFields = {
    visitor: {
      id: String(id),
      personalTravelUserId: personalTravelUserId ?? null,
      activePropertyReviewRequestIds: activePropertyReviewRequests?.map((req) => req.id) || [],
      canBookWithIncidentals: canBookWithIncidentals ?? null,
      completedBookingCount: completedBookingCount ?? null,
      confirmedAt: confirmedAt ?? null,
      countryCode: countryCode ?? null,
      directBillConfiguration: directBillConfiguration ?? null,
      directBillOnboardingModal: directBillOnboarding?.onboardingModal ?? null,
      directBillCheckOutPage: directBillOnboarding?.checkOutPage ?? null,
      directBillCompanySettings: directBillOnboarding?.companySettings ?? null,
      directBillPaymentMethods: directBillOnboarding?.paymentMethods ?? null,
      directBill: directBill ?? null,
      email: email ?? null,
      employeeId: employeeId ?? null,
      firstName: firstName ?? null,
      firstTimeSignIn: firstTimeSignIn ?? null,
      firstTimeSignInAfterDBSignup:
        role === "admin" && moment(business?.directBillEnabledAt) > moment(lastSignInAt),
      hasReportingAccess: hasReportingAccess ?? null,
      hasGroupsSpecialization: hasGroupsSpecialization ?? null,
      incidentalsConfiguration: incidentalsConfiguration ?? null,
      lastSignInAt: lastSignInAt ?? null,
      occupantType: occupantType ?? null,
      promptForPropertyReviews: promptForPropertyReviews ?? null,
      rewardsMember: rewardsMember ?? null,
      role: role ?? null,
      signInCount: signInCount ?? null,
      shouldPromptForPropertyReviews: shouldPromptForPropertyReviews ?? null,
      rewardsProfileCurrentTierStartDate: rewardsProfile?.currentTier?.startDate ?? null,
      rewardsProfileCurrentTierName: rewardsProfile?.currentTier?.name ?? null,
      rewardsProfileCurrentTierNumber: rewardsProfile?.currentTier?.tierNumber ?? null,
      rewardsProfileCurrentTierPointsPerDollar:
        rewardsProfile?.currentTier?.pointsPerDollar ?? null,
      rewardsProfileCurrentTierBookingCountUnlock:
        rewardsProfile?.currentTier?.bookingCountUnlock ?? null,
      rewardsProfileCurrentTierSpendUnlock: rewardsProfile?.currentTier?.spendUnlock ?? null,
    },
    account: {
      id: String(businessId),
      businessTravelUserId: businessTravelUserId ?? null,
      businessTravelUserBusinessId: businessTravelUserBusinessId ?? null,
      accountType: accountType ?? null,
      achProfile: hasAchProfile ?? null,
      adminOnboardingActive: business?.adminOnboardingActive ?? null,
      allowIncidentals: business?.allowIncidentals ?? null,
      autopayEnabled: business?.autopayEnabled ?? null,
      directBillAchEnabled: business?.directBillAchEnabled ?? null,
      directBillCreditCardEnabled: business?.directBillCreditCardEnabled ?? null,
      directBillEnabled: business?.directBillEnabled ?? null,
      directBillEnabledAt: business?.directBillEnabledAt ?? null,
      directBillPromotionAllowed: business?.directBillPromotionAllowed ?? null,
      directBillUserDefault: business?.directBillUserDefault ?? null,
      directBillVerificationRequired: business?.directBillVerificationRequired ?? null,
      disableRewards: business?.disableRewards ?? null,
      expensifyEnabled: business?.expensifyEnabled ?? null,
      flexAllowedBusiness: business?.flexAllowedBusiness ?? null,
      enablePartialPayments: business?.enablePartialPayments ?? null,
      guestBookingRestricted: business?.guestBookingRestricted ?? null,
      flexProEnrolled: business?.flexProEnrolled ?? null,
      hasPooledRewardPoints: business?.hasPooledRewardPoints ?? null,
      hasPooledTravelCredits: business?.hasPooledTravelCredits ?? null,
      hotelCollect: business?.hotelCollect ?? null,
      incidentalsPromotionAllowed: business?.incidentalsPromotionAllowed ?? null,
      isInternal: business?.isInternal ?? null,
      isPerkAccount: business?.isPerkAccount ?? null,
      loyaltyRewardsEnabled: business?.loyaltyRewardsEnabled ?? null,
      businessName: business?.name ?? null,
      pendingDirectBillRequest: business?.pendingDirectBillRequest ?? null,
      personalTravelEnabled: business?.personalTravelEnabled ?? null,
      businessPostalCode: business?.postalCode ?? null,
      promptForPropertyReviews: business?.promptForPropertyReviews ?? null,
      referFriendsEnabled: business?.referFriendsEnabled ?? null,
      rewardPoints: business?.rewardPoints ?? null,
      showCoordinatorDashboard: business?.showCoordinatorDashboard ?? null,
      showCreditRoles: business?.showCreditRoles ?? [],
      travelCreditsAllowed: business?.travelCreditsAllowed ?? null,
      travelPolicyWithDB: hasTravelPolicyWithDB ?? null,
      directBillPromotionEnabled: business?.directBillPromotionEnabled ?? null,
      currencyCode: business?.currencyCode ?? null,
    },
  };
  if (!productionSession) {
    testRunHelper(mappedFields.account);
    testRunHelper(mappedFields.visitor);
  }
  return mappedFields;
}

export function usePendo() {
  const user = useAppSelector((state) => state.Auth.user);
  const business = user?.business;
  const businessId = business?.id as number;
  const initializedUserId = useRef<number>();
  const isAdmin = !!user && user?.role === "admin";
  const achPaymentProfileEnabled = !!isAdmin && !!businessId;
  const travelPoliciesEnabled = !!isAdmin;

  const { data: achProfileData, isFetched: achProfileIsFetched } = useAchPaymentProfileQuery(
    businessId,
    {
      enabled: achPaymentProfileEnabled,
    }
  );
  const { data: travelPolicyData, isFetched: travelPolicyDataIsFetched } = useTravelPoliciesQuery({
    options: {
      enabled: travelPoliciesEnabled,
    },
  });
  const activeAchProfiles = achProfileData?.filter((item) => !item.updateRequired) || [];
  const travelPolicyWithDB = travelPolicyData?.filter((item) => item.directBillAllowed) || [];
  const hasAchProfile = activeAchProfiles?.length > 0;
  const hasTravelPolicyWithDB = travelPolicyWithDB?.length > 0;

  const picked: PickedMetaFields = useMemo(
    () => ({
      ...pick(user, fieldPaths),
      hasAchProfile,
      hasTravelPolicyWithDB,
    }),
    [user, hasAchProfile, hasTravelPolicyWithDB]
  );

  const initCallback = useCallback(
    (initialized = false) => {
      const metaFields = generatePendoMetaPayload(picked, config.stage === "production");

      // typically we will only "initialize pendo"
      // however if a user switches accounts we need to identify them with a different set of meta data
      if (initialized) {
        pendo.identify(metaFields);
      } else {
        pendo.initialize({ ...metaFields, apiKey: config.pendoClientKey });
      }
      initializedUserId.current = picked.id;
    },
    [picked]
  );

  // https://tanstack.com/query/latest/docs/framework/react/guides/disabling-queries#:~:text=When%20enabled%20is%20false%3A,not%20automatically%20fetch%20on%20mount.
  // If the query is disabled we are ready since isFetched will never be true
  const initPendoReady =
    !!pendo &&
    !!picked.id &&
    !picked.impersonatorId &&
    !globalThis.Cypress &&
    (achProfileIsFetched || !achPaymentProfileEnabled) &&
    (travelPolicyDataIsFetched || !travelPoliciesEnabled);

  useEffect(() => {
    // We need to wait to initialize Pendo session until a user id is available
    // account id is a nice to have but not a must have for tracking session
    // We do not want to track impersonation sessions
    // We do not want to track automated cypress sessions
    // We want to wait till it has fetched ACH Profile
    // We want to wait till it has fetched Travel Policy
    try {
      if (initPendoReady && !initializedUserId.current) {
        initCallback();
      }
    } catch (e) {
      Sentry.captureMessage(`Error initilizing pendo.io with user: ${picked.id}`);
    }
  }, [initPendoReady, picked, initCallback]);

  // This is to re-identify user if the user switches accounts
  useEffect(() => {
    try {
      if (!!initializedUserId.current && initializedUserId.current !== picked.id) {
        initCallback(true);
      }
    } catch (e) {
      Sentry.captureMessage(`Error identifying pendo.io user: ${picked.id}`);
    }
  }, [picked.id, initCallback]);
}
