import styled from "styled-components";

import { LegacyAlertBanner as AlertBanner } from "@hotel-engine/common/AlertBanner";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { colors } from "@hotel-engine/styles";
import { Icon } from "@hotel-engine/common/Icon";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { Typography } from "@hotelengine/atlas-web";
import { Collapse, CollapsePanel } from "@hotel-engine/common/Collapse";
import { pxToRem } from "@hotel-engine/styles/helpers/mixins";
import config from "config";

interface IFlexTextProps {
  showFlexPro?: boolean;
}

export const ExpressBookPricingDetailsContainer = styled.div`
  .ant-collapse {
    padding: ${pxToRem(16)} ${pxToRem(24)};
    padding-bottom: 0;
    background-color: transparent !important;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding: ${pxToRem(16)};
    }
  }

  .ant-collapse-item {
    border-bottom-style: dashed;
    border-color: ${({ theme }) => theme.legacy.colors.grey[200]};
  }

  .ant-collapse-content {
    border-top: 1px dashed ${({ theme }) => theme.legacy.colors.grey[200]} !important;
  }

  .ant-collapse-content-box {
    padding-top: ${pxToRem(10)} !important;
  }

  .ant-divider-horizontal {
    margin: ${pxToRem(12)} 0 !important;
    background: transparent;
    border-top: 1px dashed ${({ theme }) => theme.legacy.colors.grey[200]};
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const SummaryTitle = styled.h2`
  ${typographyMixin("medium")}
  color: ${colors.grey[800]};
  margin-bottom: 0;
`;

export const TotalDue = styled.span`
  ${typographyMixin("medium")}
  color: ${colors.grey[800]};
  margin-bottom: 0;
`;

export const MiniTable = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.blackPanther};

  .pricing-summary-row {
    justify-content: space-between;
    margin-bottom: ${pxToRem(12)};
    color: ${({ theme }) => theme.legacy.colors.black};
  }

  .bordered-row {
    border-top: 1px solid ${colors.grey[200]};
    border-bottom: 1px solid ${colors.grey[200]};
    margin-bottom: 10px;
    margin-top: 10px;
  }
`;

export const TripExtensionSavingsBanner = styled(AlertBanner)`
  background-color: ${colors.green[50]};
  font-size: ${({ theme }) => theme.legacy.fontSize.sm};
  font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
  margin: 8px 0 16px;

  .ant-alert-icon {
    color: ${colors.green[500]};
  }
`;

export const NightlyRateBreakdownTitle = styled.div`
  margin: 0 0 12px 0;
`;

export const NightlyRateDivider = styled.div`
  border-bottom: 1px solid ${colors.grey[200]};
  margin: 0 -16px;
`;

export const NightlyBreakdownBody = styled.div`
  padding: 10px 16px 10px 0;
  max-height: 208px;
  overflow-y: auto;
  margin-right: -16px;
`;

export const NightlyBreakdownNight = styled.div`
  margin-bottom: 8px;
`;

export const NightlyBreakdownRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ExceedsGSA = styled.p`
  ${typographyMixin("body-small")}
  color: ${colors.red[400]};
  text-align: right;
  margin-top: 4px;
`;

export const NightlyBreakdownFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`;

export const FlexCost = styled.span`
  color: ${colors.green[600]};
`;

export const FlexText = styled.span<IFlexTextProps>`
  text-decoration: ${({ showFlexPro }) => (showFlexPro ? "line-through" : "none")};
`;

export const FlexTextContainer = styled.div`
  text-align: right;
  display: flex;
`;

export const FlexProFreeContainer = styled.div`
  display: flex;
  margin-left: 8px;
  & > div {
    width: auto;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FlexProLogo = styled.img`
  width: 83px;
  height: 16px;
  margin-left: -4px;
`;

export const FlexProFreeText = styled.span`
  font-size: ${({ theme }) => theme.legacy.fontSize.sm};
`;

export const ZeroText = styled.div`
  margin-left: 4px;
`;

// Net New Starts Here FEN-5494
export const CollapseIcon = styled(Icon).attrs(() => ({
  icon: faChevronDown,
}))<{ $isOpen?: boolean }>`
  font-size: 16px !important;
  transform: ${({ $isOpen }) => ($isOpen ? "rotate(180deg)" : "rotate(0deg)")} !important;
  transition: transform 0.1s ease-in-out;
  top: calc(50% - 8px) !important;
  right: 0 !important;
`;

export const CollapseHeader = styled(Typography).attrs(() => ({
  as: "h4",
}))`
  margin: 0;
  font-weight: 600;
  line-height: 125%;
  font-size: 16px;
`;

export const PricingDetailsCollapse = styled(Collapse)`
  div.ant-collapse-header {
    padding: 0 !important;
    height: 40px;
    display: flex;
    align-items: center;
  }
`;

export const PricingDetailsCollapsePanel = styled(CollapsePanel)`
  .ant-collapse-content-box {
    padding: 0;
  }
`;

export const PricingDetailsTotalContainer = styled.div`
  padding: ${pxToRem(12)} ${pxToRem(24)} ${pxToRem(16)};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-left: ${pxToRem(16)};
    padding-right: ${pxToRem(16)};
  }
`;

export const RewardsBanner = styled.div`
  height: 40px;
  width: 100%;

  display: flex;
  align-items: center;
  position: relative;

  background: ${({ theme }) => theme.legacy.colors.slate[50]};
  background-image: url(${`${config.cdnHost}/assets/express-book/eb-rewards-bg.png`});
  background-size: contain;
  background-repeat: no-repeat;
  padding-left: 80px;

  border-radius: ${({ theme }) => theme.borderRadius[4]};
  i.rewards-logo {
    position: absolute;
    left: 16px;

    width: 24px;
    height: 24px;
    background-size: cover;
    background-image: url(${`${config.cdnHost}/assets/express-book/eb-reward-badge.svg`});
  }
  strong {
    color: #2d7475;
  }
`;
