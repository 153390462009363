export const countryList = [
  { name: "Afghanistan", code: "AF", alphaThree: "AFG" },
  { name: "Åland Islands", code: "AX", alphaThree: "ALA" },
  { name: "Albania", code: "AL", alphaThree: "ALB" },
  { name: "Algeria", code: "DZ", alphaThree: "DZA" },
  { name: "American Samoa", code: "AS", alphaThree: "ASM" },
  { name: "AndorrA", code: "AD", alphaThree: "AND" },
  { name: "Angola", code: "AO", alphaThree: "AGO" },
  { name: "Anguilla", code: "AI", alphaThree: "AIA" },
  { name: "Antarctica", code: "AQ", alphaThree: "ATA" },
  { name: "Antigua and Barbuda", code: "AG", alphaThree: "ATG" },
  { name: "Argentina", code: "AR", alphaThree: "ARG" },
  { name: "Armenia", code: "AM", alphaThree: "ARM" },
  { name: "Aruba", code: "AW", alphaThree: "ABW" },
  { name: "Australia", code: "AU", alphaThree: "AUS" },
  { name: "Austria", code: "AT", alphaThree: "AUT" },
  { name: "Azerbaijan", code: "AZ", alphaThree: "AZE" },
  { name: "Bahamas", code: "BS", alphaThree: "BHS" },
  { name: "Bahrain", code: "BH", alphaThree: "BHR" },
  { name: "Bangladesh", code: "BD", alphaThree: "BGD" },
  { name: "Barbados", code: "BB", alphaThree: "BRB" },
  { name: "Belarus", code: "BY", alphaThree: "BLR" },
  { name: "Belgium", code: "BE", alphaThree: "BEL" },
  { name: "Belize", code: "BZ", alphaThree: "BLZ" },
  { name: "Benin", code: "BJ", alphaThree: "BEN" },
  { name: "Bermuda", code: "BM", alphaThree: "BMU" },
  { name: "Bhutan", code: "BT", alphaThree: "BTN" },
  { name: "Bolivia", code: "BO", alphaThree: "BOL" },
  { name: "Bosnia and Herzegovina", code: "BA", alphaThree: "BIH" },
  { name: "Botswana", code: "BW", alphaThree: "BWA" },
  { name: "Bouvet Island", code: "BV", alphaThree: "BVT" },
  { name: "Brazil", code: "BR", alphaThree: "BRA" },
  { name: "British Indian Ocean Territory", code: "IO", alphaThree: "IOT" },
  { name: "Brunei Darussalam", code: "BN", alphaThree: "BRN" },
  { name: "Bulgaria", code: "BG", alphaThree: "BGR" },
  { name: "Burkina Faso", code: "BF", alphaThree: "BFA" },
  { name: "Burundi", code: "BI", alphaThree: "BDI" },
  { name: "Cambodia", code: "KH", alphaThree: "KHM" },
  { name: "Cameroon", code: "CM", alphaThree: "CMR" },
  { name: "Canada", code: "CA", alphaThree: "CAN" },
  { name: "Cape Verde", code: "CV", alphaThree: "CPV" },
  { name: "Cayman Islands", code: "KY", alphaThree: "CYM" },
  { name: "Central African Republic", code: "CF", alphaThree: "CAF" },
  { name: "Chad", code: "TD", alphaThree: "TCD" },
  { name: "Chile", code: "CL", alphaThree: "CHL" },
  { name: "China", code: "CN", alphaThree: "CHN" },
  { name: "Christmas Island", code: "CX", alphaThree: "CXR" },
  { name: "Cocos (Keeling) Islands", code: "CC", alphaThree: "CCK" },
  { name: "Colombia", code: "CO", alphaThree: "COL" },
  { name: "Comoros", code: "KM", alphaThree: "COM" },
  { name: "Congo", code: "CG", alphaThree: "COG" },
  {
    name: "Congo, The Democratic Republic of the",
    code: "CD",
    alphaThree: "COD",
  },
  { name: "Cook Islands", code: "CK", alphaThree: "COK" },
  { name: "Costa Rica", code: "CR", alphaThree: "CRI" },
  { name: "Cote D'Ivoire", code: "CI", alphaThree: "CIV" },
  { name: "Croatia", code: "HR", alphaThree: "HRV" },
  { name: "Cuba", code: "CU", alphaThree: "CUB" },
  { name: "Cyprus", code: "CY", alphaThree: "CYP" },
  { name: "Czech Republic", code: "CZ", alphaThree: "CZE" },
  { name: "Denmark", code: "DK", alphaThree: "DNK" },
  { name: "Djibouti", code: "DJ", alphaThree: "DJI" },
  { name: "Dominica", code: "DM", alphaThree: "DMA" },
  { name: "Dominican Republic", code: "DO", alphaThree: "DOM" },
  { name: "Ecuador", code: "EC", alphaThree: "ECU" },
  { name: "Egypt", code: "EG", alphaThree: "EGY" },
  { name: "El Salvador", code: "SV", alphaThree: "SLV" },
  { name: "Equatorial Guinea", code: "GQ", alphaThree: "GNQ" },
  { name: "Eritrea", code: "ER", alphaThree: "ERI" },
  { name: "Estonia", code: "EE", alphaThree: "EST" },
  { name: "Ethiopia", code: "ET", alphaThree: "ETH" },
  { name: "Falkland Islands (Malvinas)", code: "FK", alphaThree: "FLK" },
  { name: "Faroe Islands", code: "FO", alphaThree: "FRO" },
  { name: "Fiji", code: "FJ", alphaThree: "FJI" },
  { name: "Finland", code: "FI", alphaThree: "FIN" },
  { name: "France", code: "FR", alphaThree: "FRA" },
  { name: "French Guiana", code: "GF", alphaThree: "GUF" },
  { name: "French Polynesia", code: "PF", alphaThree: "PYF" },
  { name: "French Southern Territories", code: "TF", alphaThree: "ATF" },
  { name: "Gabon", code: "GA", alphaThree: "GAB" },
  { name: "Gambia", code: "GM", alphaThree: "GMB" },
  { name: "Georgia", code: "GE", alphaThree: "GEO" },
  { name: "Germany", code: "DE", alphaThree: "DEU" },
  { name: "Ghana", code: "GH", alphaThree: "GHA" },
  { name: "Gibraltar", code: "GI", alphaThree: "GIB" },
  { name: "Greece", code: "GR", alphaThree: "GRC" },
  { name: "Greenland", code: "GL", alphaThree: "GRL" },
  { name: "Grenada", code: "GD", alphaThree: "GRD" },
  { name: "Guadeloupe", code: "GP", alphaThree: "GLP" },
  { name: "Guam", code: "GU", alphaThree: "GUM" },
  { name: "Guatemala", code: "GT", alphaThree: "GTM" },
  { name: "Guernsey", code: "GG", alphaThree: "GGY" },
  { name: "Guinea", code: "GN", alphaThree: "GIN" },
  { name: "Guinea-Bissau", code: "GW", alphaThree: "GNP" },
  { name: "Guyana", code: "GY", alphaThree: "GUY" },
  { name: "Haiti", code: "HT", alphaThree: "HTI" },
  { name: "Heard Island and Mcdonald Islands", code: "HM", alphaThree: "HMD" },
  { name: "Holy See (Vatican City State)", code: "VA", alphaThree: "VAT" },
  { name: "Honduras", code: "HN", alphaThree: "HND" },
  { name: "Hong Kong", code: "HK", alphaThree: "HKG" },
  { name: "Hungary", code: "HU", alphaThree: "HUN" },
  { name: "Iceland", code: "IS", alphaThree: "ISL" },
  { name: "India", code: "IN", alphaThree: "IND" },
  { name: "Indonesia", code: "ID", alphaThree: "IDN" },
  { name: "Iran, Islamic Republic Of", code: "IR", alphaThree: "IRN" },
  { name: "Iraq", code: "IQ", alphaThree: "IRQ" },
  { name: "Ireland", code: "IE", alphaThree: "IRL" },
  { name: "Isle of Man", code: "IM", alphaThree: "IMN" },
  { name: "Israel", code: "IL", alphaThree: "ISR" },
  { name: "Italy", code: "IT", alphaThree: "ITA" },
  { name: "Jamaica", code: "JM", alphaThree: "JAM" },
  { name: "Japan", code: "JP", alphaThree: "JPN" },
  { name: "Jersey", code: "JE", alphaThree: "JEY" },
  { name: "Jordan", code: "JO", alphaThree: "JOR" },
  { name: "Kazakhstan", code: "KZ", alphaThree: "KAZ" },
  { name: "Kenya", code: "KE", alphaThree: "KEN" },
  { name: "Kiribati", code: "KI", alphaThree: "KIR" },
  {
    name: "Korea, Democratic People'S Republic of",
    code: "KP",
    alphaThree: "PRK",
  },
  { name: "Korea, Republic of", code: "KR", alphaThree: "KOR" },
  { name: "Kuwait", code: "KW", alphaThree: "KWT" },
  { name: "Kyrgyzstan", code: "KG", alphaThree: "KGZ" },
  { name: "Lao People'S Democratic Republic", code: "LA", alphaThree: "LAO" },
  { name: "Latvia", code: "LV", alphaThree: "LVA" },
  { name: "Lebanon", code: "LB", alphaThree: "LBN" },
  { name: "Lesotho", code: "LS", alphaThree: "LSO" },
  { name: "Liberia", code: "LR", alphaThree: "LBR" },
  { name: "Libyan Arab Jamahiriya", code: "LY", alphaThree: "LBY" },
  { name: "Liechtenstein", code: "LI", alphaThree: "LIE" },
  { name: "Lithuania", code: "LT", alphaThree: "LTU" },
  { name: "Luxembourg", code: "LU", alphaThree: "LUX" },
  { name: "Macao", code: "MO", alphaThree: "MAC" },
  {
    name: "Macedonia, The Former Yugoslav Republic of",
    code: "MK",
    alphaThree: "MKD",
  },
  { name: "Madagascar", code: "MG", alphaThree: "MDG" },
  { name: "Malawi", code: "MW", alphaThree: "MWI" },
  { name: "Malaysia", code: "MY", alphaThree: "MYS" },
  { name: "Maldives", code: "MV", alphaThree: "MDV" },
  { name: "Mali", code: "ML", alphaThree: "MLI" },
  { name: "Malta", code: "MT", alphaThree: "MLT" },
  { name: "Marshall Islands", code: "MH", alphaThree: "MHL" },
  { name: "Martinique", code: "MQ", alphaThree: "MTQ" },
  { name: "Mauritania", code: "MR", alphaThree: "MRT" },
  { name: "Mauritius", code: "MU", alphaThree: "MUS" },
  { name: "Mayotte", code: "YT", alphaThree: "MYT" },
  { name: "Mexico", code: "MX", alphaThree: "MEX" },
  { name: "Micronesia, Federated States of", code: "FM", alphaThree: "FSM" },
  { name: "Moldova, Republic of", code: "MD", alphaThree: "MDA" },
  { name: "Monaco", code: "MC", alphaThree: "MCO" },
  { name: "Mongolia", code: "MN", alphaThree: "MNG" },
  { name: "Montenegro", code: "ME", alphaThree: "MNE" },
  { name: "Montserrat", code: "MS", alphaThree: "MSR" },
  { name: "Morocco", code: "MA", alphaThree: "MAR" },
  { name: "Mozambique", code: "MZ", alphaThree: "MOZ" },
  { name: "Myanmar", code: "MM", alphaThree: "MMR" },
  { name: "Namibia", code: "NA", alphaThree: "NAM" },
  { name: "Nauru", code: "NR", alphaThree: "NRU" },
  { name: "Nepal", code: "NP", alphaThree: "NPL" },
  { name: "Netherlands", code: "NL", alphaThree: "NLD" },
  { name: "Netherlands Antilles", code: "AN", alphaThree: "ANT" },
  { name: "New Caledonia", code: "NC", alphaThree: "NCL" },
  { name: "New Zealand", code: "NZ", alphaThree: "NZL" },
  { name: "Nicaragua", code: "NI", alphaThree: "NIC" },
  { name: "Niger", code: "NE", alphaThree: "NER" },
  { name: "Nigeria", code: "NG", alphaThree: "NGA" },
  { name: "Niue", code: "NU", alphaThree: "NIU" },
  { name: "Norfolk Island", code: "NF", alphaThree: "NFK" },
  { name: "Northern Mariana Islands", code: "MP", alphaThree: "MNP" },
  { name: "Norway", code: "NO", alphaThree: "NOR" },
  { name: "Oman", code: "OM", alphaThree: "OMN" },
  { name: "Pakistan", code: "PK", alphaThree: "PAK" },
  { name: "Palau", code: "PW", alphaThree: "PLW" },
  { name: "Palestinian Territory, Occupied", code: "PS", alphaThree: "PSE" },
  { name: "Panama", code: "PA", alphaThree: "PAN" },
  { name: "Papua New Guinea", code: "PG", alphaThree: "PNG" },
  { name: "Paraguay", code: "PY", alphaThree: "PRY" },
  { name: "Peru", code: "PE", alphaThree: "PER" },
  { name: "Philippines", code: "PH", alphaThree: "PHL" },
  { name: "Pitcairn", code: "PN", alphaThree: "PCN" },
  { name: "Poland", code: "PL", alphaThree: "POL" },
  { name: "Portugal", code: "PT", alphaThree: "PRT" },
  { name: "Puerto Rico", code: "PR", alphaThree: "PRI" },
  { name: "Qatar", code: "QA", alphaThree: "QAT" },
  { name: "Reunion", code: "RE", alphaThree: "REU" },
  { name: "Romania", code: "RO", alphaThree: "ROU" },
  { name: "Russian Federation", code: "RU", alphaThree: "RUS" },
  { name: "RWANDA", code: "RW", alphaThree: "RWA" },
  { name: "Saint Helena", code: "SH", alphaThree: "SHN" },
  { name: "Saint Kitts and Nevis", code: "KN", alphaThree: "KNA" },
  { name: "Saint Lucia", code: "LC", alphaThree: "LCA" },
  { name: "Saint Pierre and Miquelon", code: "PM", alphaThree: "SPM" },
  { name: "Saint Vincent and the Grenadines", code: "VC", alphaThree: "VCT" },
  { name: "Samoa", code: "WS", alphaThree: "WSM" },
  { name: "San Marino", code: "SM", alphaThree: "SMR" },
  { name: "Sao Tome and Principe", code: "ST", alphaThree: "STP" },
  { name: "Saudi Arabia", code: "SA", alphaThree: "SAU" },
  { name: "Senegal", code: "SN", alphaThree: "SEN" },
  { name: "Serbia", code: "RS", alphaThree: "SRB" },
  { name: "Seychelles", code: "SC", alphaThree: "SYC" },
  { name: "Sierra Leone", code: "SL", alphaThree: "SLE" },
  { name: "Singapore", code: "SG", alphaThree: "SGP" },
  { name: "Slovakia", code: "SK", alphaThree: "SVK" },
  { name: "Slovenia", code: "SI", alphaThree: "SVN" },
  { name: "Solomon Islands", code: "SB", alphaThree: "SLB" },
  { name: "Somalia", code: "SO", alphaThree: "SOM" },
  { name: "South Africa", code: "ZA", alphaThree: "ZAF" },
  {
    name: "South Georgia and the South Sandwich Islands",
    code: "GS",
    alphaThree: "SGS",
  },
  { name: "Spain", code: "ES", alphaThree: "ESP" },
  { name: "Sri Lanka", code: "LK", alphaThree: "LKA" },
  { name: "Sudan", code: "SD", alphaThree: "SDN" },
  { name: "Suriname", code: "SR", alphaThree: "SUR" },
  { name: "Svalbard and Jan Mayen", code: "SJ", alphaThree: "SJM" },
  { name: "Swaziland", code: "SZ", alphaThree: "SWZ" },
  { name: "Sweden", code: "SE", alphaThree: "SWE" },
  { name: "Switzerland", code: "CH", alphaThree: "CHE" },
  { name: "Syrian Arab Republic", code: "SY", alphaThree: "SYR" },
  { name: "Taiwan, Province of China", code: "TW", alphaThree: "TWN" },
  { name: "Tajikistan", code: "TJ", alphaThree: "TJK" },
  { name: "Tanzania, United Republic of", code: "TZ", alphaThree: "TZA" },
  { name: "Thailand", code: "TH", alphaThree: "THA" },
  { name: "Timor-Leste", code: "TL", alphaThree: "TLS" },
  { name: "Togo", code: "TG", alphaThree: "TGO" },
  { name: "Tokelau", code: "TK", alphaThree: "TKL" },
  { name: "Tonga", code: "TO", alphaThree: "TON" },
  { name: "Trinidad and Tobago", code: "TT", alphaThree: "TTO" },
  { name: "Tunisia", code: "TN", alphaThree: "TUN" },
  { name: "Turkey", code: "TR", alphaThree: "TUR" },
  { name: "Turkmenistan", code: "TM", alphaThree: "TKM" },
  { name: "Turks and Caicos Islands", code: "TC", alphaThree: "TCA" },
  { name: "Tuvalu", code: "TV", alphaThree: "TUV" },
  { name: "Uganda", code: "UG", alphaThree: "UGA" },
  { name: "Ukraine", code: "UA", alphaThree: "UKR" },
  { name: "United Arab Emirates", code: "AE", alphaThree: "ARE" },
  { name: "United Kingdom", code: "GB", alphaThree: "GBR" },
  { name: "United States", code: "US", alphaThree: "USA" },
  {
    name: "United States Minor Outlying Islands",
    code: "UM",
    alphaThree: "UMI",
  },
  { name: "Uruguay", code: "UY", alphaThree: "URY" },
  { name: "Uzbekistan", code: "UZ", alphaThree: "UZB" },
  { name: "Vanuatu", code: "VU", alphaThree: "VUT" },
  { name: "Venezuela", code: "VE", alphaThree: "VEN" },
  { name: "Viet Nam", code: "VN", alphaThree: "VNM" },
  { name: "Virgin Islands, British", code: "VG", alphaThree: "VGB" },
  { name: "Virgin Islands, U.S.", code: "VI", alphaThree: "VIR" },
  { name: "Wallis and Futuna", code: "WF", alphaThree: "WLF" },
  { name: "Western Sahara", code: "EH", alphaThree: "ESH" },
  { name: "Yemen", code: "YE", alphaThree: "YEM" },
  { name: "Zambia", code: "ZM", alphaThree: "ZMB" },
  { name: "Zimbabwe", code: "ZW", alphaThree: "ZWE" },
] as const;

export const countriesByAlpha = [...countryList].sort((a, b) =>
  String(a.alphaThree).localeCompare(String(b.alphaThree))
);

export const statesProvinces = {
  USA: [
    { name: "Alabama", code: "AL" },
    { name: "Alaska", code: "AK" },
    { name: "American Samoa", code: "AS" },
    { name: "Arizona", code: "AZ" },
    { name: "Arkansas", code: "AR" },
    { name: "California", code: "CA" },
    { name: "Colorado", code: "CO" },
    { name: "Connecticut", code: "CT" },
    { name: "Delaware", code: "DE" },
    { name: "District Of Columbia", code: "DC" },
    { name: "Federated States Of Micronesia", code: "FM" },
    { name: "Florida", code: "FL" },
    { name: "Georgia", code: "GA" },
    { name: "Guam", code: "GU" },
    { name: "Hawaii", code: "HI" },
    { name: "Idaho", code: "ID" },
    { name: "Illinois", code: "IL" },
    { name: "Indiana", code: "IN" },
    { name: "Iowa", code: "IA" },
    { name: "Kansas", code: "KS" },
    { name: "Kentucky", code: "KY" },
    { name: "Louisiana", code: "LA" },
    { name: "Maine", code: "ME" },
    { name: "Marshall Islands", code: "MH" },
    { name: "Maryland", code: "MD" },
    { name: "Massachusetts", code: "MA" },
    { name: "Michigan", code: "MI" },
    { name: "Minnesota", code: "MN" },
    { name: "Mississippi", code: "MS" },
    { name: "Missouri", code: "MO" },
    { name: "Montana", code: "MT" },
    { name: "Nebraska", code: "NE" },
    { name: "Nevada", code: "NV" },
    { name: "New Hampshire", code: "NH" },
    { name: "New Jersey", code: "NJ" },
    { name: "New Mexico", code: "NM" },
    { name: "New York", code: "NY" },
    { name: "North Carolina", code: "NC" },
    { name: "North Dakota", code: "ND" },
    { name: "Northern Mariana Islands", code: "MP" },
    { name: "Ohio", code: "OH" },
    { name: "Oklahoma", code: "OK" },
    { name: "Oregon", code: "OR" },
    { name: "Palau", code: "PW" },
    { name: "Pennsylvania", code: "PA" },
    { name: "Puerto Rico", code: "PR" },
    { name: "Rhode Island", code: "RI" },
    { name: "South Carolina", code: "SC" },
    { name: "South Dakota", code: "SD" },
    { name: "Tennessee", code: "TN" },
    { name: "Texas", code: "TX" },
    { name: "Utah", code: "UT" },
    { name: "Vermont", code: "VT" },
    { name: "Virgin Islands", code: "VI" },
    { name: "Virginia", code: "VA" },
    { name: "Washington", code: "WA" },
    { name: "West Virginia", code: "WV" },
    { name: "Wisconsin", code: "WI" },
    { name: "Wyoming", code: "WY" },
  ],
  CAN: [
    { name: "Alberta", code: "AB" },
    { name: "British Columbia", code: "BC" },
    { name: "Manitoba", code: "MB" },
    { name: "New Brunswick", code: "NB" },
    { name: "Newfoundland and Labrador", code: "NL" },
    { name: "Northwest Territories", code: "NT" },
    { name: "Nova Scotia", code: "NS" },
    { name: "Nunavut", code: "NU" },
    { name: "Ontario", code: "ON" },
    { name: "Prince Edward Island", code: "PE" },
    { name: "Quebec", code: "QC" },
    { name: "Saskatchewan", code: "SK" },
    { name: "Yukon", code: "YT" },
  ],
} as const;
