import type { Moment } from "moment";
import * as Yup from "yup";
import type { TripType, PassengerTypes } from "@hotelengine/core-booking-web";
import type { FormikErrors } from "formik";

export type PassengerType = keyof typeof PassengerTypes;

export type PassengerChild = {
  count: number;
  childrenAges: (number | null)[];
};

export type Passengers = {
  [key in PassengerType]: key extends "child" ? PassengerChild : number;
};

export const flightLocationSchema = Yup.object().shape({
  name: Yup.string().required(),
  type: Yup.string().oneOf(["airport", "metropolitan-area"]).required(),
  city: Yup.string().required(),
  countryCode: Yup.string().required(),
  iataCode: Yup.string().required(),
});

export type FlightLocation = Yup.InferType<typeof flightLocationSchema>;

export type FlightSliceCriteria = {
  origin: FlightLocation | null;
  destination: FlightLocation | null;
  departureDate: Moment;
};

export type FormikErrorsFlightSliceCriteria = FormikErrors<
  Omit<FlightSliceCriteria, "departureDate"> & { departureDate: string }
>;

export interface IFlightSearchFormValues {
  tripType: TripType;
  slicesCriteria: FlightSliceCriteria[];
  passengers: Passengers;
}
