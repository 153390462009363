import { useContext } from "react";

import { AmplitudeContext } from "@hotel-engine/contexts/Amplitude";

/**
 * A hook function to access the Amplitude context in React.
 */
export const useAmplitudeContext = () => {
  return useContext(AmplitudeContext);
};
