import type { UseQueryOptions } from "react-query";

import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { ITimeZone } from "@hotel-engine/types/place";

import { useApi } from "../useApi";
import { useExtendedQuery } from "../useExtendedQuery";
import { endpoints } from "../constants";

export const useTimeZoneQuery = (
  params?: { latitude?: number | string; longitude?: number | string },
  options?: UseQueryOptions<ITimeZone, IErrorResponse, ITimeZone>
) => {
  const get = useApi("get");

  return useExtendedQuery<ITimeZone, IErrorResponse>(
    [endpoints.timezone, params?.latitude, params?.longitude],
    () => get<ITimeZone>(endpoints.timezone, params),
    options
  );
};
