/* eslint-disable @typescript-eslint/no-namespace, @typescript-eslint/naming-convention */
import type { DirectBillAndIncidentalsConfigType, MaxDailyRates } from "./travelPolicies";
import type { IActivePropertyReview, IPostCheckoutAction } from "./notifications";
import type { IBusiness } from "./business";
import type { IDepartment } from "./department";
import type { IHotelLoyaltyUserRecord } from "./hotelLoyalty";
import { type IRole, Role } from "./role";
import type { IOccupant } from "./booking";
import type { IRewardsProfile } from "./rewards";
import { z } from "zod";

export * from "./rewards";

export type UserProvider = "email" | "google_oauth2";
export type UserDistanceUnit = "mi" | "km";

export {
  /**
   * ### {@link AccountType `UserAccountType`}
   * Alias for {@link User.AccountType `User.AccountType`}
   */
  AccountType as UserAccountType,
};

/** ### {@link AccountType `User.AccountType`} */
export type AccountType = (typeof AccountType)[keyof typeof AccountType];
export const AccountType = {
  personal: "personal",
  business: "business",
} as const;

export const SwitchUserAccountType = {
  business: "personal",
  personal: "business",
} satisfies Record<AccountType, AccountType>;

/** ## {@link IUser `IUser`} */
export interface IUser {
  activePropertyReviewRequests?: IActivePropertyReview[];
  business: IBusiness;
  businessId: number;
  businessTravelUserBusinessId: null | number;
  businessTravelUserEmail: null | string;
  businessTravelUserId: null | number;
  canBookCars: boolean;
  canBookFlights: boolean;
  canBookWithIncidentals: boolean;
  completedBookingCount?: number;
  confirmedAt: string | null;
  confirmationSentAt: string;
  countryCode?: string;
  courierHmac: string;
  department: IDepartment;
  departmentId: IDepartment["id"];
  departmentName: string;
  directBill: boolean;
  directBillOnboarding: {
    onboardingModal: boolean;
    checkOutPage: boolean;
    companySettings: boolean;
    paymentMethods: boolean;
  } | null;
  directBillConfiguration?: DirectBillAndIncidentalsConfigType;
  distanceUnit: UserDistanceUnit | null;
  email: string;
  employeeId?: string;
  expressBookEligibility: "allowed" | "blocked" | "payment_profile_required";
  firstName: string;
  firstTimeSignIn: boolean;
  hasReportingAccess: boolean;
  hasGroupsSpecialization: boolean;
  id: number;
  incidentalsConfiguration?: DirectBillAndIncidentalsConfigType;
  impersonatorId?: number;
  isDemo: boolean;
  lastName: string;
  lastSignInAt: string;
  maxDailyRates?: MaxDailyRates;
  mfaMode: "required" | "email-only" | "bypass";
  occupantType?: "user" | "guest";
  phone?: string;
  promptForPropertyReviews: boolean;
  postCheckoutActions: IPostCheckoutAction[];
  refundableOnly: boolean;
  rewardsMember: boolean;
  rewardsProfile: IRewardsProfile | null;
  shouldPromptForPropertyReviews: boolean;
  role: IRole;
  userNotice: string | null;
  userLoyaltyRewards?: IUserLoyaltyProgram[];
  accountType: AccountType;
  provider: UserProvider;
  personalTravelUserId: number | null;
  personalTravelUserEmail: string | null;
  personalTravelUserConfirmedAt?: string | null;
  phoneVerified?: boolean;
  supportCaseUpdatesCount?: number;
}

export function isIUser(input: IUser | IOccupant): input is User {
  return "confirmedAt" in input;
}

export { User };

/**
 * ## {@link User `User`}
 *
 * As a type, represents an authenticated Engine user.
 *
 * As a namespace, contains a number of user-related helpers and user sub-types.
 */
type User<T extends IUser = IUser> = T;

declare namespace User {
  export { AccountType };
}

namespace User {
  /* eslint-disable @typescript-eslint/naming-convention */
  /** @internal */
  const viewOnlyTraveler = {
    role: z.literal(Role.view_only_traveler),
  } as const;
  /**
   * ### {@link ViewOnlyTraveler `User.ViewOnlyTraveler`}
   */
  export const ViewOnlyTraveler = z.object(viewOnlyTraveler);
  export interface ViewOnlyTraveler
    extends Omit<IUser, keyof typeof viewOnlyTraveler>,
      z.infer<typeof ViewOnlyTraveler> {}
  /**
   * ### {@link isViewOnlyTraveler `User.isViewOnlyTraveler`}
   */
  export const isViewOnlyTraveler = (user: IUser): user is User.ViewOnlyTraveler =>
    ViewOnlyTraveler.safeParse(user).success;
  /** @internal */
  const traveler = {
    role: z.literal(Role.user),
  } as const;
  /**
   * ### {@link Traveler `User.Traveler`}
   */
  export const Traveler = z.object(traveler);
  export interface Traveler extends Omit<IUser, keyof typeof traveler>, z.infer<typeof Traveler> {}
  /**
   * ### {@link isTraveler `User.isTraveler`}
   */
  export const isTraveler = (user: IUser): user is User.Traveler =>
    Traveler.safeParse(user).success;
  /** @internal */
  const coordinator = {
    role: z.literal(Role.coordinator),
  } as const;
  /**
   * ### {@link Coordinator `User.Coordinator`}
   */
  export const Coordinator = z.object(coordinator);
  export interface Coordinator
    extends Omit<IUser, keyof typeof coordinator>,
      z.infer<typeof Coordinator> {}
  /**
   * ### {@link isCoordinator `User.isCoordinator`}
   */
  export const isCoordinator = (user: IUser): user is User.Coordinator =>
    Coordinator.safeParse(user).success;
  /** @internal */
  const admin = {
    role: z.literal(Role.admin),
  } as const;
  /**
   * ### {@link Admin `User.Admin`}
   */
  export const Admin = z.object(admin);
  export interface Admin extends Omit<IUser, keyof typeof admin>, z.infer<typeof Admin> {}
  /**
   * ### {@link isAdmin `User.isAdmin`}
   */
  export const isAdmin = (user: IUser): user is User.Admin => user.role === Role.admin;
  /** @internal */
  const personalTravel = {
    ...traveler,
    accountType: z.literal(AccountType.personal),
    businessTravelUserId: z.number().int(),
    /**
     * {@link personalTravel.personalTravelUserId `user.personalTravelUserId`}
     * is always null for personal travel accounts
     *
     * _[last updated: 2024-09]_
     */
    personalTravelUserId: z.null(),
  } as const;
  /**
   * ### {@link PersonalTravel `User.PersonalTravel`}
   */
  export const PersonalTravel = z.object(personalTravel);
  export interface PersonalTravel
    extends Omit<IUser, keyof typeof personalTravel>,
      z.infer<typeof PersonalTravel> {}
  /**
   * ### {@link isPersonalTravel `User.isPersonalTravel`}
   */
  export const isPersonalTravel = (user: IUser): user is User.PersonalTravel =>
    PersonalTravel.safeParse(user).success;
  /** @internal */
  const businessTravel = {
    ...traveler,
    accountType: z.literal(AccountType.business),
    personalTravelUserId: z.number().int(),
    /**
     * {@link businessTravel.businessTravelUserId `user.businessTravelUserId`}
     * is always null for personal travel accounts
     *
     * _[last updated: 2024-09]_
     */
    businessTravelUserId: z.null(),
  } as const;
  /**
   * ### {@link BusinessTravel `User.BusinessTravel`}
   */
  export const BusinessTravel = z.object(businessTravel);
  export interface BusinessTravel
    extends Omit<IUser, keyof typeof businessTravel>,
      z.infer<typeof BusinessTravel> {}
  /**
   * ### {@link isBusinessTravel `User.isBusinessTravel`}
   */
  export const isBusinessTravel = (user: IUser): user is User.BusinessTravel =>
    BusinessTravel.safeParse(user).success;
  /* eslint-enable @typescript-eslint/naming-convention */
}
User.AccountType = AccountType;

export interface IUserV2Response {
  limit: number;
  offset: number;
  total: number;
  users: IUser[];
}

export interface IUserLoyaltyProgram extends IHotelLoyaltyUserRecord {
  userId?: number;
}

export interface IUserPrefsState {
  dashboardCalloutDismissed: boolean;
  dashboardTabPreference: "favorites" | "trips";
  favoriteCalloutDismissed: boolean;
  guestCount: number;
  lastPaymentId: number | null;
  roomCount: number;
  singleRoomGuestCount: number;
  showEngines: boolean;
  showRatelessProperties: boolean;
  showSearchRadiusCircle: boolean;
}
