import moment from "moment";

/**
 * Determines whether a rate is refundable based on the cancellation deadline.
 * CancelBy date should not be in the past or in the range of 2 hours from now
 * @param cancelBy - The cancellation deadline for the rate, represented as a string in ISO 8601 format.
 * @returns A boolean indicating whether the rate is refundable.
 */
export const isRateRefundable = (cancelBy: string | undefined) => {
  if (!cancelBy) {
    return false;
  }
  return moment(cancelBy).diff(moment(), "hours") > 2;
};
