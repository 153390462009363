export default (values, initialValues) => {
  const changedFields = {};
  Object.keys(values).forEach((key) => {
    const fieldChanged = values[key] !== initialValues[key];

    if (fieldChanged) {
      changedFields[key] = values[key];
    }
  });

  return changedFields as Partial<typeof values>;
};
