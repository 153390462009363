import type { ReactNode } from "react";
import { createContext, useContext, useReducer } from "react";

import type { IPostCheckoutAction } from "@hotel-engine/types/notifications";

type TStatus = "isIdle" | "isCheckoutVerificationSuccess" | "isPropertyReviewSuccess";

type DispatchActions =
  | {
      type: "CLOSE_MODAL";
    }
  | {
      type: "INIT_POST_CHECKOUT_ACTION";
      payload?: IPostCheckoutAction[];
    }
  | {
      type: "OPEN_MODAL";
    }
  | {
      type: "VERIFY_POST_CHECKOUT_ACTION_SUCCESS";
      payload?: string;
    }
  | {
      type: "RESET_POST_CHECKOUT_ACTIONS";
    }
  | {
      type: "UPDATE_STEP";
      payload: number;
    }
  | {
      type: "UPDATE_STATUS";
      payload?: TStatus;
    };

type Dispatch = (action: DispatchActions) => void;

interface IInitialState {
  isModalVisible: boolean;
  postCheckoutActions?: IPostCheckoutAction[];
  completedPostCheckoutActions?: string[];
  step: number;
  status?: TStatus;
}

const initialState = {
  isModalVisible: false,
  postCheckoutActions: [],
  completedPostCheckoutActions: [""],
  step: 0,
};

type ProviderProps = { children: ReactNode };

export interface IPostCheckoutActionsContextValue {
  state: IInitialState;
  dispatch: Dispatch;
}

const PostCheckoutActionsContext = createContext({} as IPostCheckoutActionsContextValue);

const postCheckoutActionsReducer = (
  state: IInitialState,
  action: DispatchActions
): IInitialState => {
  switch (action.type) {
    case "CLOSE_MODAL": {
      return {
        ...state,
        isModalVisible: false,
      };
    }
    case "OPEN_MODAL": {
      return {
        ...state,
        isModalVisible: true,
      };
    }
    case "INIT_POST_CHECKOUT_ACTION": {
      return {
        ...state,
        isModalVisible: true,
        postCheckoutActions: action.payload,
      };
    }
    case "VERIFY_POST_CHECKOUT_ACTION_SUCCESS": {
      return {
        ...state,
        completedPostCheckoutActions: [
          ...(state.completedPostCheckoutActions || []),
          ...[action.payload || ""],
        ],
      };
    }
    case "RESET_POST_CHECKOUT_ACTIONS": {
      return {
        ...state,
        postCheckoutActions: [],
        step: 0,
        status: "isIdle",
      };
    }
    case "UPDATE_STEP": {
      return {
        ...state,
        step: action.payload,
      };
    }
    case "UPDATE_STATUS": {
      return {
        ...state,
        status: action.payload,
      };
    }

    default: {
      return initialState;
    }
  }
};

const PostCheckoutActionsProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(postCheckoutActionsReducer, initialState);

  const value = { state, dispatch };

  return (
    <PostCheckoutActionsContext.Provider value={value}>
      {children}
    </PostCheckoutActionsContext.Provider>
  );
};

function usePostCheckoutActions() {
  const context = useContext(PostCheckoutActionsContext);
  if (context === undefined) {
    throw new Error("usePostCheckoutActions must be used within a PostCheckoutActionsProvider");
  }
  return context;
}

export { PostCheckoutActionsProvider, usePostCheckoutActions };
