import type { IBaseHEIconProps } from "./index";

const Hotel = ({ height = "15", width = "15" }: IBaseHEIconProps) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 15 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="1.-LANDING" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          fill="currentColor"
          id="HE_Component_SearchResults_@2x"
          transform="translate(-480.000000, -168.000000)"
        >
          <g id="Group-4" transform="translate(480.000000, 87.000000)">
            <g id="Hotel" transform="translate(0.000000, 81.000000)">
              <g id="Hotel_Icon">
                <path
                  d="M15,9.87244032 L14.076063,6.8433088 L14.076063,0 L0.95641986,0 L0.95641986,6.84326429 L0.0324828645,9.87244032 L15,9.87244032 Z M12.5606227,1.40625 L12.5606227,5.93794726 C12.5606227,5.93794726 2.58804394,5.93794726 2.47186018,5.93794726 L2.47186018,1.40625 L12.5606227,1.40625 Z"
                  id="Shape"
                />
                <polygon
                  id="Shape"
                  points="0 10.3842658 0 12.2978798 0.824416314 12.2978798 0.824416314 14.9330357 2.33667957 14.9330357 2.33667957 12.2978798 12.6632772 12.2978798 12.6632772 14.9330357 14.1755405 14.9330357 14.1755405 12.2978798 15 12.2978798 15 10.3842658"
                />
                <path
                  d="M6.93018064,5.44978526 L6.93018064,3.97718293 C6.93018064,3.45915816 6.12067913,3.03907098 5.12212188,3.03907098 C4.12356462,3.03907098 3.31410921,3.45915816 3.31410921,3.97718293 L3.31410921,5.44978526 L6.93018064,5.44978526 Z"
                  id="Shape"
                />
                <path
                  d="M11.3578354,5.44978526 L11.3578354,3.97718293 C11.3578354,3.45915816 10.5483442,3.03907098 9.54979965,3.03907098 C8.55125513,3.03907098 7.74176394,3.45915816 7.74176394,3.97718293 L7.74176394,5.44978526 L11.3578354,5.44978526 Z"
                  id="Shape"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Hotel;
