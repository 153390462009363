import type { Moment } from "moment";

import type { ILocationRecord } from "./locations";
import type { IHEImageElement } from "./property";

export const reasonsForStay = [
  "Construction/Crew",
  "Corporate/Business",
  "Convention/Conference",
  "Sports",
  "Events",
  "Other",
].map((value) => ({
  label: value,
  value,
}));

export type Location = Required<
  Pick<ILocationRecord, "city" | "country" | "latitude" | "longitude">
> & { state: string; street: string };

export interface IGetSpecialBookingResponse {
  meta: {
    completed: number;
    draft: number;
    limit: number;
    offset: number;
    open: number;
    total: number;
  };
  data: {
    id: number;
    name: string;
    location: Location;
    checkIn: Moment | string;
    checkOut: Moment | string;
    status: string;
    acceptedPropertyName: string;
    reviewableHotelOptions: number;
    userName: string;
    userId: number;
  }[];
}

export interface ICreateSpecialBooking {
  groupRateQuoteId: number | null;
  checkIn?: Moment;
  checkOut?: Moment;
  location: Location;
  name: string;
}

export interface ICreateDuplicateSpecialBooking extends ICreateSpecialBooking {
  sbrId: number;
}

export interface ICreateSpecialBookingResponse {
  id: number;
  checkIn: string;
  checkOut: string;
  street: string;
  city: string;
  state: string;
  country: string;
}

export interface IUpdateSpecialBookingParams {
  tripReason?: string;
  standardRooms?: number;
  doubleRooms?: number;
  suites?: number;
  amenities?: IAmenities;
  maxNightlyRate?: number;
  maxDistance?: number;
  preferredHotelBrand?: string;
  minimumStarRating?: number;
  topPriority?: string;
  preferencesNotes?: string;
  preferredPaymentType?: string;
  requestedPropertyIds?: number[];
  phone?: string;
  email?: string;
  id: number;
}

export interface IMeetingRoomDetails {
  budget?: number;
  meetingAttendees?: number;
  timeAndDates?: string;
  avRequired?: boolean;
  sustenanceRequired?: boolean;
  other?: string;
}

export interface IAmenities {
  parking: string;
  kitchen: string;
  breakfast: string;
  laundryServices: string;
  meetingRoom: string;
  meetingRoomDetails: IMeetingRoomDetails;
  otherNotes: string;
}
export interface ICustomNights {
  date?: Moment | string;
  standardRooms?: number | null;
  doubleRooms?: number | null;
  suites?: number | null;
}

export interface IRequestedProperty {
  id: number;
  thumbnailUrl: IHEImageElement;
  rating: number;
  reviewCount: number;
  name: string;
}

export interface ITripDetailsForm {
  checkIn?: Moment;
  checkOut?: Moment;
  tripReason: string;
  standardRooms?: number;
  doubleRooms?: number;
  suites?: number;
  amenities: IAmenities;
  maxNightlyRate?: string;
  maxDistance?: string;
  preferredHotelBrand: string;
  requestedProperties: IRequestedProperty[];
  minimumStarRating: string;
  topPriority: string;
  preferencesNotes: string;
  preferredPaymentType: string;
  email: string;
  phone: string;
  location: Location;
  customNights: ICustomNights[];
  name: string;
}

export interface ISpecialBookingResponse {
  name: string;
  location: Location;
  id: number;
  checkIn: string;
  checkOut: string;
  tripReason: string;
  standardRooms: number;
  doubleRooms: number;
  hotelOptionsData: IHotelOptions;
  hotelOrderName?: string;
  status: string;
  suites: number;
  amenities: Omit<IAmenities, "meetingRoomDetails">;
  maxNightlyRate?: string;
  maxDistance?: string;
  meetingRoomDetails: IMeetingRoomDetails;
  preferredHotelBrand: string;
  requestedProperties: IRequestedProperty[];
  minimumStarRating: number;
  topPriority: string;
  preferencesNotes: string;
  preferredPaymentType: string;
  customNights: ICustomNights[];
  phone: string;
  userName: string;
  userId: number;
}

interface IHotelOptions {
  count: number;
  propertyName: string;
  latitude: string;
  longitude: string;
  hotelOptionId: number;
}

export interface ISpecialBookingParams {
  id: number;
}

export enum RequestsTab {
  OPEN = "open",
  CONFIRMED = "confirmed",
  DRAFTS = "drafts",
}

export enum TripSummaryBarTab {
  DETAILS = "details",
  PROPOSALS = "proposals",
  SELECTION = "selection",
}

export enum Rankings {
  DONT_NEED = "Don't Need",
  NICE_TO_HAVE = "Nice to Have",
  MUST_HAVE = "Must Have",
}

export interface ICustomRoom {
  date: string;
  standardRooms?: number | string | null;
  doubleRooms?: number | string | null;
  suites?: number | string | null;
}

export interface IGroupTripSummary {
  name: string;
  id: number;
  checkIn: string;
  checkOut: string;
  status: string;
  acceptedPropertyName: string;
  reviewableHotelOptions: number;
  hotelOrderName?: string;
  location: Location;
  hotelOptionsData: {
    count: number;
    hotelOptionId: number;
    propertyName: string;
    latitude: string;
    longitude: string;
  };
  userName: string;
  userId: number;
  roomCount: number;
}

export interface ICancelSpecialBookingParams {
  id: number;
  cancelReasons: string[];
  notes: string;
}

export interface IHotelOptionsParams {
  id: number;
  limit?: number;
  total?: number;
  offset?: number;
  status: string;
}

export interface IGetHotelOptionsResponse {
  id: number;
  propertyName: string;
  totalCost: number;
}

export interface IHotelOptionParams {
  id: number;
  hotelOptionId: number;
}

export interface ICheckList {
  label: string;
  meets: boolean;
}

export interface IAmenitiesInfo {
  name: string;
  cost?: number;
  costType: string;
  frequency?: string;
}

interface ITieredGroupCancellation {
  days: number;
  percentageDue: number;
}

export interface IAdditionalDetails {
  amenities: string[];
  amenitiesInfo: IAmenitiesInfo[];
  attritionPercent?: number;
  attritionPolicy: string;
  breakfastIncludedInRate: boolean;
  breakfastRate: string;
  cutOffDate: string;
  earlyDepartureAllowed: boolean;
  earlyDeparturePolicy: string;
  earlyCheckoutPenalty: string;
  flatFeeType: string;
  flatFeeNightlyRate: string;
  groupCancellationPolicy: string;
  isCancelable: boolean;
  individualCancellationPolicy: string;
  meetingSpace: {
    additionalNotes: string;
    availability: string;
    avCapabilities: boolean;
    blackout: string;
    foodBevAvailable: boolean;
    maxAttendees: string;
    price: number;
    squareFootage: string;
  };
  notes: string;
  parkingIncludedInRate: boolean;
  parkingRate: string;
  rateExpirationDate: string;
  roomTypeOne: {
    rate: string;
    description: string;
    numberOfRooms: number;
  };
  roomTypeTwo: {
    rate: string;
    description: string;
    numberOfRooms: number;
  };
  roomTypeThree: {
    rate: string;
    description: string;
    numberOfRooms: number;
  };
  taxesAndFees: string;
  tieredGroupCancellation?: ITieredGroupCancellation[];
}
export interface IGetHotelOptionResponse {
  propertyId: number;
  totalCost: number;
  avgNightlyRate: number;
  avgStrikePrice: number;
  distanceFromDestination: string;
  accountManagerNote: string;
  checklist: ICheckList[];
  property: {
    name: string;
    starRating: number;
    rating: number;
    latitude: number;
    longitude: number;
    address: string;
    reviewCount: number;
    thumbnailUrl: string | IHEImageElement;
    propertyType: string;
  };
  additionalDetails: IAdditionalDetails;
}

export interface IDeclineHotelOptionParams {
  id: number;
  hotelOptionId: number;
  declineReasons: string[];
  notes: string;
}

export interface IAcceptHotelOptionParams {
  id: number;
  hotelOptionId: number;
}

export interface IReconsiderHotelOptionParams {
  id: number;
  hotelOptionId: number;
}

export type ISpecialBookingRequestParams = {
  limit?: number;
  total?: number;
  offset?: number;
  search?: string;
  bucket: RequestsTab;
  searching_for_hotels?: boolean;
  review_hotels?: boolean;
  only_my_requests?: boolean;
};

export interface ISearchPropertiesParams {
  order?: string;
  sort: "distance" | "star_rating";
  query?: string;
  maxDistance?: number;
  preferredHotelBrand?: string;
  minStarRating?: number;
  amenities: string[];
  sbrId: string;
  limit?: number;
  offset?: number;
}

export interface ISearchProperty {
  id: number;
  distance: number;
  thumbnailUrl: IHEImageElement;
  rating: number;
  starRating: number;
  reviewCount: number;
  name: string;
  latitude: number;
  longitude: number;
  images: (string | IHEImageElement)[];
}
export type ISearchPropertiesResponse = ISearchProperty[];

export enum EAmenityOption {
  DONT_NEED = "Don't Need",
  NICE_TO_HAVE = "Nice to Have",
  MUST_HAVE = "Must Have",
}

export const GROUP_DASHBOARD_SET_PAGE_NUMBER = "SET_PAGE_NUMBER" as const;
export const GROUP_DASHBOARD_SET_PAGE_SIZE = "SET_PAGE_SIZE" as const;
export const GROUP_DASHBOARD_SET_PAGINATION = "SET_PAGINATION" as const;

export const DIRECT_SELECT_SET_PAGE_NUMBER = "SET_PAGE_NUMBER" as const;
export const DIRECT_SELECT_SET_PAGE_SIZE = "SET_PAGE_SIZE" as const;
export const DIRECT_SELECT_SET_PAGINATION = "SET_PAGINATION" as const;
export const DIRECT_SELECT_SET_ACTIVE_PROPERTY = "SET_ACTIVE_PROPERTY" as const;
export const DIRECT_SELECT_RESET_ACTIVE_PROPERTY = "RESET_ACTIVE_PROPERTY" as const;
export const DIRECT_SELECT_SET_MOBILE_MAP_VISIBLE = "SET_MOBILE_MAP_VISIBLE" as const;
