import styled from "styled-components";
import { Timeline } from "@hotel-engine/common/Timeline";

export const ActivityTimeLine = styled(Timeline)`
  margin-top: 16px;
`;

export const TimeLineIconFill = ({ color }: { color: string }) => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="4" cy="4" r="3" fill={color} stroke={color} strokeWidth="2" />
  </svg>
);

export const TimeLineIconNoFill = ({ color }: { color: string }) => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="4" cy="4" r="3" fill="white" stroke={color} strokeWidth="2" />
  </svg>
);
