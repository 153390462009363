import animationData from "@hotel-engine/assets/animations/new-no-properties-found-animation.json";

import * as Styled from "./styles";

export const ManWalkingAnimation = () => (
  <Styled.ImagePlaceholder
    animationData={animationData}
    autoplay={true}
    data-testid={`manWalkingGlassAnimation`}
    loop={true}
  />
);
