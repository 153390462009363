import * as cookies from "browser-cookies";

/*
  Usage: One interface to manage all forms of storage that we use (cookies and localStorage).
         This interface has logic to handle when cookies is disabled, we fallback to localStorage,
         and when localStorage is disabled, we fallback to an in-memory storage. The usage of this
         interface should care about how the items are being stored outside of if they need to be
         "secure" or not.

  Importance: We need to not reference window/docuemtn storage objects directly to prevent errors
              or unexpected issues when the client has cookies/storage disabled.

  import storage from "@hotel-engine/storage";              Import
  storage.setItem(key, value);                              Set item in localStorage
  storage.getItem(key);                                     Get item from localStorage
  storage.removeItem(key);                                  Remove item from localStorage
  storage.setSecureItem(key, value);                        Set item in cookies
  storage.getSecureItem(key);                               Get item from cookies
  storage.removeSecureItem(key);                            Remove item from cookies
  globalThis.addEventListener("storage", (storageEvent) => {}); Listen for storage changes (cookies and localStorage)
*/

interface IStorageInterface {
  setItem(key: string, value: string): void;
  setSecureItem(key: string, value: string, options?: cookies.CookieOptions): void;
  removeItem(key: string): void;
  removeSecureItem(key: string, options?: cookies.CookieOptions): void;
  getItem(key: string): string | null;
  getSecureItem(key: string): string | null;
  clear(): void;
  key(index: number): string | null;
  length: number;
  asyncStorage: {
    setItem(key: string, value: string): Promise<void>;
    removeItem(key: string): Promise<void>;
    getItem(key: string): Promise<string | null>;
  };
  enabledPersistOptions: {
    localStorage: boolean;
    cookies: boolean;
  };
  canPersist: boolean;
}

interface IStorage {
  [key: string]: string;
}

const checkIsLocalStorageEnabled = () => {
  try {
    const testKey = "_isWindowStorageAllowed";

    globalThis.localStorage.setItem(testKey, testKey);
    globalThis.localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
};

export const checkIsCookiesEnabled = () => navigator.cookieEnabled;

const createStorageInterface = (): IStorageInterface => {
  let storage: IStorage = {}; // This is the basic object that is used as the in-memory storage.
  let length = 0;
  const enabledPersistOptions = {
    localStorage: checkIsLocalStorageEnabled(),
    cookies: checkIsCookiesEnabled(),
  };

  const notify = (key: string, oldValue: string | null, newValue: string | null) =>
    globalThis.dispatchEvent(new StorageEvent("storage", { key, newValue, oldValue }));

  const updateLength = (newValue?: number | undefined) => {
    length = (Number.isInteger(newValue) ? newValue : Object.keys(storage).length) as number;
  };

  const setItem = (key: string, value: string) => {
    if (enabledPersistOptions.localStorage) {
      globalThis.localStorage.setItem(key, value);
      updateLength(globalThis.localStorage.length);
    } else {
      const oldValue = storage[key];
      storage = { ...storage, [key]: value };

      notify(key, oldValue, value);
      updateLength();
    }
  };

  const setSecureItem = (key: string, value: string, options?: cookies.CookieOptions) => {
    if (enabledPersistOptions.cookies) {
      const oldValue = getSecureItem(key);

      cookies.set(key, value, options);
      notify(key, oldValue, value);
    } else {
      setItem(key, value);
      updateLength();
    }
  };

  const getItem = (key: string) =>
    (enabledPersistOptions.localStorage ? globalThis.localStorage.getItem(key) : storage[key]) ||
    null;

  const getSecureItem = (key: string) =>
    enabledPersistOptions.cookies ? cookies.get(key) : getItem(key);

  const removeItem = (key: string) => {
    if (enabledPersistOptions.localStorage) {
      globalThis.localStorage.removeItem(key);
      updateLength(globalThis.localStorage.length);
    } else {
      const oldValue = storage[key];

      delete storage[key];
      notify(key, oldValue, null);
      updateLength();
    }
  };

  const removeSecureItem = (key: string, options?: cookies.CookieOptions) => {
    if (enabledPersistOptions.cookies) {
      const oldValue = getSecureItem(key);

      cookies.erase(key, options);
      notify(key, oldValue, null);
    } else {
      removeItem(key);
    }
  };

  const clear = () => {
    if (enabledPersistOptions.localStorage) {
      globalThis.localStorage.clear();
      updateLength(globalThis.localStorage.length);
    } else {
      storage = {};
      updateLength();
    }
  };

  const getKey = (index: number) => {
    if (enabledPersistOptions.localStorage) {
      return globalThis.localStorage.key(index);
    } else {
      return Object.keys(storage)[index] || null;
    }
  };

  const asyncStorage = {
    getItem: async (key: string) => getItem(key),
    setItem: async (key: string, value: string) => setItem(key, value),
    removeItem: async (key: string) => removeItem(key),
  };

  return {
    setItem,
    setSecureItem,
    removeItem,
    removeSecureItem,
    getItem,
    getSecureItem,
    clear,
    key: getKey,
    asyncStorage,
    enabledPersistOptions,
    get canPersist() {
      return enabledPersistOptions.localStorage;
    },
    get length() {
      return length;
    },
  };
};

export default createStorageInterface();
