import type { RumEvent, RumInitConfiguration } from "@datadog/browser-rum";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs, type LogsInitConfiguration } from "@datadog/browser-logs";

import * as Sentry from "@hotel-engine/utilities";

import { useEffect, useState } from "react";

import config from "config";

import { useAppSelector } from "store/hooks";
import LogRocket from "logrocket";

/**
 * Generates an array of origins based on config.  Used for connecting end-to-end traces
 * in DataDog RUM service.  Filters duplicates and bad url's
 */
const getTracingOrigins = () => {
  const extractOrigin = (url: string) => {
    try {
      return new URL(url).origin;
    } catch {
      return "";
    }
  };

  // filtering out dupes, nulls and grabbing just the origin. There's a better way to do this, but we've been burned here.
  // Do not use set until we live in that sweet sweet post-ie11 eutopia.
  const origins = [config.apiHost, config.apiHostV2, config.fssHost, config.cssHost]
    .map(extractOrigin)
    .filter((u) => !!u)
    .filter((value, index, array) => array.indexOf(value) === index);

  return origins;
};

/**
 *  This is a callback that will be called before sending the event to the DD.
 *  You can modify the event or return false to stop it from being sent.
 *  https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#before-send
 */
const beforeSend = (event: RumEvent) => {
  if (
    event.type === "error" &&
    event.error.message.includes("Object Not Found Matching Id:12, MethodName:update, ParamCount:4")
  ) {
    return false;
  }
  return true;
};

const datadogSharedConfiguration = {
  clientToken: config.datadogClientToken,
  env: config.stage,
  service: "members",
  /**
   * To track 100% of sessions as Browser RUM (with no Session Replays),
   * we want sessionSampleRate: 100 & sessionReplaySampleRate: 0.
   * To stop session recordings, set sessionReplaySampleRate to 0.
   */
  sessionSampleRate: 100,
  site: "datadoghq.com",
  version: config.release,
};

const datadogLogsConfiguration: LogsInitConfiguration = {
  ...datadogSharedConfiguration,
};

const dataDogRumConfiguration: RumInitConfiguration = {
  ...datadogSharedConfiguration,
  applicationId: "71436375-999d-4fe0-834d-ca230fb8e35c",
  defaultPrivacyLevel: "mask-user-input",
  /**
   * Removing marketing trackers from Browser RUM's detection of when
   * pages are finished loading, which is 100ms after the last request.
   * Since these are very chatty on purpose, it can skew metrics for page
   * load times
   */
  excludedActivityUrls: ["https://r.lr-ingest.com/i", "http://lrkt-in.com"],
  sessionReplaySampleRate: 0,
  // enables the automatic collection of user clicks in your application.
  trackUserInteractions: true,
  // A resource event is generated for images, XHR, Fetch, CSS, or JS
  // libraries loaded on a webpage. It includes detailed loading
  // timing information.
  trackResources: true,
  // A long task event is generated for any task in the browser
  // that blocks the main thread for more than 50ms.
  trackLongTasks: true,
  // A list of request URLs used to inject tracing headers.
  allowedTracingUrls: getTracingOrigins(),
  // Starting with v5.0.0 of the RUM Browser SDK, the Session Replay starts
  // recording automatically when calling init(). To conditionally start
  // the recording, set startSessionReplayRecordingManually: true,
  // and call startSessionReplayRecording().
  startSessionReplayRecordingManually: true,
  beforeSend,
};

const ALLOWED_ENVS = ["production", "staging", "development"];

/**
 * initializes datadog and updates the user information as a side effect of the user object being updated.
 **/
export function useDatadog() {
  const user = useAppSelector((state) => state.Auth.user);
  const [isDatadogInitialized, setIsDatadogInitialized] = useState(false);

  const enabled =
    config.datadogClientToken && ALLOWED_ENVS.includes(config.stage) && !globalThis.Cypress;

  useEffect(() => {
    if (enabled) {
      /**
       * https://docs.datadoghq.com/real_user_monitoring/browser/
       */
      datadogRum.init(dataDogRumConfiguration);

      /**
       * https://docs.datadoghq.com/logs/log_collection
       */
      datadogLogs.init(datadogLogsConfiguration);

      setIsDatadogInitialized(true);
    }
  }, [enabled]);

  // set the user as a side effect of the user being loaded.
  useEffect(() => {
    if (user && isDatadogInitialized) {
      try {
        const { id, businessId, firstName, lastName, email, role } = user;
        datadogRum.setUser({
          id: String(id),
          businessId,
          name: `${firstName} ${lastName}`,
          email,
          role,
        });
      } catch (e) {
        Sentry.captureMessage(`Exception when setting user within DataDog`, {
          error: e,
          userId: user?.id,
        });
      }
    } else if (!user && isDatadogInitialized) {
      datadogRum.clearUser();
    }
  }, [user, isDatadogInitialized]);

  useEffect(() => {
    if (isDatadogInitialized) {
      LogRocket?.track("DataDog Initialized", {
        dataDogSessionId: datadogRum.getInternalContext()?.session_id || "No Session Id",
      });
    }
  }, [isDatadogInitialized]);

  useEffect(() => {
    if (isDatadogInitialized) {
      LogRocket?.getSessionURL((sessionURL) => {
        // The function name is misleading, it actually sets a property for the user session
        // https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#addoverride-user-session-property
        datadogRum.setUserProperty("LogRocket Session Url", sessionURL);
      });
    }
  }, [isDatadogInitialized]);

  useEffect(() => {
    const ddHandleVisibilityChange = () => {
      if (datadogRum && datadogRum.addAction) {
        datadogRum.addAction("visibility-change", {
          visibilityState: document.visibilityState,
        });
      }
    };
    document.addEventListener("visibilitychange", ddHandleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", ddHandleVisibilityChange);
    };
  }, []);

  return isDatadogInitialized;
}
