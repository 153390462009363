import { useEffect, useState } from "react";

import { User } from "@hotel-engine/services";
import type { IUser } from "@hotel-engine/types/user";
import { store } from "store";
import { AuthActions } from "store/Auth/AuthRedux";
import { useAppSelector } from "store/hooks";

export const useHydrateUser = (isLoading = false) => {
  const user = useAppSelector((state) => state.Auth.user);
  const [loading, setLoading] = useState<boolean>(true);
  const [hydratedUserId, setHydratedUserId] = useState<number>();

  useEffect(() => {
    if ((hydratedUserId !== user?.id || !user) && !isLoading) {
      hydrateUser()
        .then((hydratedUser: IUser) => hydratedUser && setHydratedUserId(hydratedUser.id))
        // noop since we redirect to login below if user doesn't get hydrated correctly
        .catch(() => undefined)
        .finally(() => setLoading(false));
    }
  }, [user, hydratedUserId, isLoading]);

  return { user, loading };
};

const hydrateUser = async () => {
  // Check if we already have a user saved in memory
  let user = User.current();

  if (!user) {
    // Refresh user
    user = await User.getSelf();

    if (user) {
      User.setUserRefresh();
      store.dispatch(AuthActions.setUser(user));
    } else {
      throw new Error("Error instantiating user");
    }
  }

  return user;
};
