import type { UseMutationOptions } from "react-query";
import { useMutation } from "react-query";

import { usePostCheckoutActions } from "@hotel-engine/contexts";
import { useRefetchPropertyReviews } from "@hotel-engine/react-query/propertyReviews/useRefetchPropertyReviews";
import type { IError } from "@hotel-engine/types/errors";

import { endpoints } from "../constants";
import { useApi } from "../useApi";

export interface IPropertyReviewsMutationParams {
  propertyReviewRequestId: string; //uuid
  rating: number;
  reviewText: string;
}

export const useCreatePropertyReview = (
  options?: UseMutationOptions<null, IError, IPropertyReviewsMutationParams>
) => {
  const post = useApi("post");
  const refetchPropertyReviews = useRefetchPropertyReviews();

  // Post checkout actions context
  const { dispatch: dispatchPostCheckoutActions } = usePostCheckoutActions();

  return useMutation(
    (data) => {
      return post(endpoints.propertyReviews, data);
    },
    {
      onSuccess: () => {
        refetchPropertyReviews();
        dispatchPostCheckoutActions({
          type: "UPDATE_STATUS",
          payload: "isPropertyReviewSuccess",
        });
      },
      ...options,
    }
  );
};
