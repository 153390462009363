import type { UseMutationOptions } from "react-query";
import { useMutation } from "react-query";

import type {
  IContractRate,
  IContractRateCreateParams,
  IContractRateErrorResponse,
} from "@hotel-engine/types/contractRate";

import { endpoints } from "../constants";
import { useApi } from "../useApi";
import { isContractRateCancellable } from "./helpers";

export const useCreateContractRate = (
  options?: UseMutationOptions<IContractRate, IContractRateErrorResponse, IContractRateCreateParams>
) => {
  const post = useApi("post");

  return useMutation(async (payload) => {
    const data = await post<IContractRate>(endpoints.contractRates, payload);

    return {
      ...data,
      isCancellable: isContractRateCancellable(data),
    };
  }, options);
};
