import type { Moment } from "moment";
import moment from "moment";
import storage from "@hotel-engine/storage";

/**
 * @internal
 * Don't export this key. Only exports functions that use it, so that it's
 * easy to see where it's being used and how (especially if we want to change
 * the kind of value that's stored.
 *
 * The value stored for this key should be an ISO 8601 string representing
 * the date and time when this app should consider the user's token to have
 * expired. It's likely that we've modified the official expiration time to be
 * sooner, in this string.
 */
const SESSION_EXPIRATION_SECONDS_STORAGE_KEY = "token_expiration_datestring_utc";

export function getTokenExpirationFromStorageOrNull(): Moment | null {
  const tokenExpirationFromStorage: string | null = storage.getItem(
    SESSION_EXPIRATION_SECONDS_STORAGE_KEY
  );

  // Passing `null` to `moment()` will return a `Moment` object
  // where `.isValid()` returns false. No exceptions are thrown.
  const possiblyInvalidMoment = moment(tokenExpirationFromStorage);

  return possiblyInvalidMoment.isValid() ? possiblyInvalidMoment : null;
}

/**
 * @param expirationDateStringUtc An ISO 8601 string representing the date
 * and time when the user's token will expire.
 */
export function setTokenExpirationInStorage(expirationDateStringUtc: string) {
  const possiblyInvalidMoment = moment(expirationDateStringUtc);

  if (possiblyInvalidMoment.isValid()) {
    storage.setItem(SESSION_EXPIRATION_SECONDS_STORAGE_KEY, expirationDateStringUtc);
  }
}

export function clearTokenExpirationInStorage() {
  storage.removeItem(SESSION_EXPIRATION_SECONDS_STORAGE_KEY);
}
