import type { IReservationBase } from "@hotel-engine/types/reservation";

/** If the id begins with G- && the none of the room contracts have a matching contractNumber then it is a NFSC */
export const isGroupContract = (res: IReservationBase) => {
  const hasGroupNaming = !!res.id && res.id.startsWith("G-");
  const groupIdInRooms = !!res.id && res.rooms.some((r) => r.contractNumber === res.id);

  return hasGroupNaming && !groupIdInRooms;
};

export const isSingleRoomGroupContract = (res: IReservationBase) => {
  return isGroupContract(res) && res.roomCount === 1;
};

export const isMultiRoomGroupContract = (res: IReservationBase) => {
  return isGroupContract(res) && res.roomCount > 1;
};
