import { createSlice } from "@reduxjs/toolkit";

import { initialFlightsResultsState, resultsAdapter } from "./flights.results.constants";
import {
  reset,
  resetResults,
  setError,
  setSearchId,
  setTravelerManifeset,
  setUserTravelPolicy,
} from "./flights.results.reducers";

export const flightsResultsSlice = createSlice({
  name: "flightsResults",
  initialState: initialFlightsResultsState,
  reducers: {
    updateResults: resultsAdapter.setAll,
    reset,
    resetResults,
    setError,
    setSearchId,
    setTravelerManifeset,
    setUserTravelPolicy,
  },
});

export const flightsSearchResultsActions = flightsResultsSlice.actions;

export default flightsResultsSlice.reducer;
