import { useEffect, useRef } from "react";

import { useDebounce } from "@hotel-engine/hooks/useDebounce";
import { ampli } from "ampli";

interface IAmpliEventList {
  [key: string]: { title: string; additionalValue?: string };
}

/**
 * This can be used to wrap a form that has multiple Amplitude events triggered by changes to it.
 * @param eventList Each key in this object corresponds with the `name` of the input being tracked. The value for each event in the list will be an object with a `title` corresponding to the ampli method name and an optional `additionalValue` corresponding to the parameter for the ampli event for the value of the input (ie, typedText<string> or selectedFilter<bool>) `{ email: { title: "editEmailOfNewGuest", additionalValue: "typedText" }}`
 * @param initialValues These will be an object of the initial values of the form being wrapped, this will be typed using `as` within the component with the observer based on the type of the form being used.
 * @param submissionObj The object that will be passed in as the second argument in the ampli method, this should only contain values that are applicable for every event being triggered by the observer.
 * @param values The current values of the form at the time of the ampli method being triggered, this will be typed using `as` within the component with the observer based on the type of the form being used.
 */

export const AmplitudeFormObserver = ({
  eventList,
  initialValues,
  submissionObj,
  values,
}: {
  eventList: IAmpliEventList;
  initialValues: object;
  submissionObj: object;
  values: object;
}) => {
  const debouncedValues = useDebounce(values, 2000);
  const previousValues = useRef(initialValues);

  useEffect(() => {
    Object.keys(eventList).map((event) => {
      if (previousValues.current[event] !== debouncedValues[event]) {
        if (eventList[event].additionalValue) {
          triggerAmplitudeEvent(eventList[event].title, {
            ...submissionObj,
            [eventList[event].additionalValue as string]: values[event],
          });
        } else {
          triggerAmplitudeEvent(eventList[event].title, submissionObj);
        }
      }
    });
    previousValues.current = debouncedValues;
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValues]);

  return null;
};

export const triggerAmplitudeEvent = (eventName, obj) => ampli[eventName](obj);
