import type { ICourierNotificationActions } from "@hotel-engine/types/courierNotifications";

import * as SharedStyled from "../styles";

const NotificationActions = ({
  handleMessageStatus,
  handleArchiveMessage,
  read,
}: ICourierNotificationActions) => {
  return (
    <SharedStyled.NotificationActions>
      <SharedStyled.NotificationAction onClick={handleArchiveMessage} leadingIcon="trash">
        Delete this notification
      </SharedStyled.NotificationAction>

      <SharedStyled.NotificationAction onClick={handleMessageStatus} leadingIcon="check--solid">
        {read ? "Mark as unread" : "Mark as read"}
      </SharedStyled.NotificationAction>
    </SharedStyled.NotificationActions>
  );
};

export default NotificationActions;
