import type { IExpressBookContractRate } from "@hotel-engine/types/expressBook";
import moment from "moment";

// determines if the reservation is eligible to be canceled
export const isRoomRefundable = (cancelByDate?: IExpressBookContractRate["cancelBy"]) => {
  if (!cancelByDate || moment(cancelByDate).diff(moment(), "hours") < 2) {
    return false;
  }

  return true;
};
