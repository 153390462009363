import { createGlobalStyle, css } from "styled-components";

import { colors } from "../variables/colors";

/* Helper to reset button styles
=============================================================== */

/** Used to reset any/all button styles */
export const buttonReset = css`
  appearance: none;
  border: none;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  outline: none;
  margin: 0;
  padding: 0;
`;

/* Helper that adds ellipsis to text if it extends past its parent container
=============================================================== */

/** When text is too large for its parent it will cut it off and add a ellipsis */
export const ellipsisText = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

/* Helpers for assistive technologies such as screen readers
=============================================================== */

/** When you want to hide an element by default use this and then add styles afterwards for what it should look like on the screen when it is "visible" to the user via navigating to the element via the users keyboard */
export const visuallyHidden = css`
  &:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;

/* Helpers for integrating reused component specific styles at a global scope so that when components get appended to the DOM the styles can still reach the appropriate classNames until we have unique components build for them
=============================================================== */

export const ModalStyles = createGlobalStyle`
  .ant-btn-primary {
    background-color: ${colors.retroBlue};
    border-color: ${colors.retroBlue};
    color: ${colors.white};
    &:hover:enabled,
    &:focus,
    &:focus:disabled {
      background-color: ${colors.denim};
      border-color: ${colors.denim};
      color: ${colors.white};
    }
    &:active:enabled {
      background-color: ${colors.bavarianBlue};
      border-color: ${colors.bavarianBlue};
      color: ${colors.white};
    }
    &:disabled {
      background-color: ${colors.softBlue};
      border-color: ${colors.softBlue};
    }
  }
  .ant-btn-danger {
    background-color: ${colors.teaberry};
    border-color: ${colors.teaberry};
    color: ${colors.white};
    &:hover:enabled,
    &:focus,
    &:focus:disabled {
      background-color: ${colors.meat};
      border-color: ${colors.meat};
      color: ${colors.white};
    }
    &:disabled {
      background-color: ${colors.meat};
      border-color: ${colors.meat};
    }
  }
  .ant-modal-wrap {
    .ant-modal-content {
      .ant-modal-header {
        border-bottom: 0;
      }

      .ant-modal-body {
        padding: 30px;
      }
    }

    .ant-modal-confirm-body-wrapper {
      .ant-modal-confirm-title {
        font-weight: ${({ theme }) => theme.legacy.fontWeight.semiBold};
      }

      .ant-modal-confirm-body {
        .anticon {
          &.success {
            color: ${colors.finePine};
          }
        }
      }

      .ant-modal-confirm-content {
        line-height: ${({ theme }) => theme.legacy.lineHeight.md};
        margin-top: 12px;
      }

      .ant-modal-confirm-btns {
        margin-top: 15px;
      }
    }

    .late-checkin-confirm-modal {
      .ant-modal-confirm-btns {
        width: 100%;
      }
    }
  }
 `;
