import type { AuthenticatorKind } from "./authenticator";

const isEmail = (kind: AuthenticatorKind): boolean => {
  return kind === "email";
};

const isPhone = (kind: AuthenticatorKind): boolean => {
  return kind === "sms" || kind === "voice";
};

export { isEmail, isPhone };
