import type { ReactNode } from "react";
import { createContext } from "react";

import type { AxiosInstance } from "axios";
import { connect } from "react-redux";

import { getClient } from "@hotel-engine/client";

/**  See /docs/DataComponents/DataClientContext.md for more info */

export type IDataClientProviderProps = {
  /** React node of the children to be rendered */
  readonly children: ReactNode;
};

interface IDataClientProviderContext {
  /** This is the axios object. The object keys defined here are just a few that are currently used */
  dataClient: AxiosInstance;
  /** This is a function needed to get the token from axios to cancel a request */
}

const DEFAULT_CONTEXT: IDataClientProviderContext = {
  dataClient: getClient(),
};

const DataClientContext = createContext(DEFAULT_CONTEXT);

export const DataClientProvider = ({ children }: IDataClientProviderProps) => {
  const dataClient = getClient();
  const context = { dataClient };
  return <DataClientContext.Provider value={context}>{children}</DataClientContext.Provider>;
};

export default connect<null, IDataClientProviderProps>(null)(DataClientProvider);
