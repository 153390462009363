import { UnconfirmedEmail } from "@hotel-engine/app/UnconfirmedEmail";

import { SignInModalWrapper } from "../SignInModal/components/SignInModalWrapper";

export interface IEmailLoginDid403 {
  /** Email that did not validate */
  email: string;
  /** Indicates that the the login attempt was on an account that did not validate its email */
  did403: boolean;
}

export interface IEmailConfirmationWarning {
  /** Email that did not validate */
  email: string;
  /** Closes the modal and resets that warning so that the user may reattempt account switching */
  onClose: () => void;
  /** Indicates that the modal is visible to the user */
  visible: boolean;
}

export const EmailConfirmationWarningModal = ({
  onClose,
  email,
  visible,
}: IEmailConfirmationWarning) => {
  return (
    <SignInModalWrapper visible={visible} onCancel={onClose}>
      <UnconfirmedEmail email={email} visible={visible} />
    </SignInModalWrapper>
  );
};
