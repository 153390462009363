import type { IBusiness } from "./business";
import type { IUser } from "./user";

export const fieldPaths = [
  "id",
  "businessId",
  "personalTravelUserId",
  "businessTravelUserId",
  "impersonatorId",
  "accountType",
  "activePropertyReviewRequests",
  "business.adminOnboardingActive",
  "business.allowIncidentals",
  "business.autopayEnabled",
  "business.directBillAchEnabled",
  "business.directBillCreditCardEnabled",
  "business.directBillEnabled",
  "business.directBillEnabledAt",
  "business.directBillPromotionAllowed",
  "business.directBillUserDefault",
  "business.directBillVerificationRequired",
  "business.disableRewards",
  "business.expensifyEnabled",
  "business.flexAllowedBusiness",
  "business.enablePartialPayments",
  "business.guestBookingRestricted",
  "business.flexProEnrolled",
  "business.hasPooledRewardPoints",
  "business.hasPooledTravelCredits",
  "business.hotelCollect",
  "business.incidentalsPromotionAllowed",
  "business.isInternal",
  "business.isPerkAccount",
  "business.loyaltyRewardsEnabled",
  "business.name",
  "business.pendingDirectBillRequest",
  "business.personalTravelEnabled",
  "business.postalCode",
  "business.promptForPropertyReviews",
  "business.referFriendsEnabled",
  "business.rewardPoints",
  "business.showCoordinatorDashboard",
  "business.showCreditRoles",
  "business.travelCreditsAllowed",
  "business.directBillPromotionEnabled",
  "business.currencyCode",
  "businessTravelUserBusinessId",
  "canBookWithIncidentals",
  "completedBookingCount",
  "confirmedAt",
  "countryCode",
  "directBillConfiguration",
  "directBillOnboarding.onboardingModal",
  "directBillOnboarding.checkOutPage",
  "directBillOnboarding.companySettings",
  "directBillOnboarding.paymentMethods",
  "directBill",
  "email",
  "employeeId",
  "firstName",
  "firstTimeSignIn",
  "hasReportingAccess",
  "hasGroupsSpecialization",
  "incidentalsConfiguration",
  "lastSignInAt",
  "occupantType",
  "promptForPropertyReviews",
  "rewardsMember",
  "rewardsProfile.currentTier",
  "role",
  "signInCount",
  "shouldPromptForPropertyReviews",
];

export type PickedMetaFields = Partial<IUser> & {
  signInCount?: number;
  hasAchProfile: boolean;
  hasTravelPolicyWithDB: boolean;
  business?: Partial<
    IBusiness & {
      directBillPromotionAllowed: boolean;
      enablePartialPayments: boolean;
      rewardPoints: number;
    }
  >;
};
