import type { IErrorResponse } from "@hotel-engine/types/errors";
import { endpoints } from "../constants";
import { useApi } from "../useApi";
import type { IFlexProCreateSubscription } from "@hotel-engine/types/flexPro";
import { useMutation, useQueryClient } from "react-query";

export const useCreateFlexProSubscription = () => {
  const post = useApi("post");
  const queryClient = useQueryClient();

  return useMutation<IFlexProCreateSubscription, IErrorResponse, IFlexProCreateSubscription>(
    (payload) => post(endpoints.flexProSubscriptions, payload),
    {
      onSuccess: async () => {
        void queryClient.invalidateQueries(endpoints.flexProSubscriptions);
      },
    }
  );
};
