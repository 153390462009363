import { DropdownTargetInfo } from "@hotel-engine/app/Header/components/redesignComponents/Rewards/DropdownTargetInfo";
import { DropdownMenu, Box, Typography, Avatar } from "@hotelengine/atlas-web";

import { formatCurrency, isUserEligibleToViewAndUseRewards } from "@hotel-engine/utilities";
import { HeaderFooter } from "./HeaderFooter";
import {
  getAdditionalInfoCopy,
  normalizeRewardsProfile,
  formatPoints,
  type NormalizedRewards,
} from "@hotel-engine/app/Header/helpers";
import { useUser } from "@hotel-engine/hooks";

import { Rewards } from "@hotel-engine/types/rewards";
import type { IUserDropdownProps } from "@hotel-engine/app/Header/components/redesignComponents/types";
import * as Styled from "./styles";

export const UserDropdown = (props: IUserDropdownProps) => {
  const user = useUser();
  const rewardsProfile = normalizeRewardsProfile(user.rewardsProfile);

  const shouldShowRewards = isUserEligibleToViewAndUseRewards(user);
  const additionalInfoCopy = getAdditionalInfoCopy(
    shouldShowRewards,
    rewardsProfile,
    user.confirmedAt ?? user.confirmationSentAt
  );

  return (
    <DropdownMenu>
      <Styled.DropdownTrigger asChild>
        <Styled.DropdownTriggerButton $isRewards={shouldShowRewards}>
          {shouldShowRewards && rewardsProfile.tier !== "Disabled" ? (
            <DropdownTargetInfo rewardsProfile={rewardsProfile} />
          ) : (
            <Styled.StyledIcon name="bars" color="backgroundInverse" />
          )}
          <Avatar name={`${user.firstName} ${user.lastName}`} size={32} />
        </Styled.DropdownTriggerButton>
      </Styled.DropdownTrigger>
      <Styled.DropdownContent
        $extraSpace={props.showSwitch || props.canCreatePersonalAccount}
        $isInternalUser={user.business.isInternal}
        $extraRightClearance={rewardsProfile.tier === "Disabled"}
        data-testid="user-dropdown-menu"
      >
        <Box padding={16}>
          <Styled.DropdownHeader>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Styled.UserHeader variant="body/lg">{`${user.firstName} ${user.lastName}`}</Styled.UserHeader>
              <Styled.SecondaryInfo variant="body/sm" $rewardsTier={rewardsProfile.tier}>
                {additionalInfoCopy}
              </Styled.SecondaryInfo>
            </Box>
            {rewardsProfile.tier === "Disabled" ? null : <Points rewardsProfile={rewardsProfile} />}
          </Styled.DropdownHeader>
          <UserRewards rewardsProfile={rewardsProfile} />
        </Box>
        <HeaderFooter {...props} shouldShowRewards={shouldShowRewards} />
      </Styled.DropdownContent>
    </DropdownMenu>
  );
};

export const Points = ({ rewardsProfile }: { rewardsProfile: NormalizedRewards.Enabled }) => (
  <Box display="flex" flexDirection="column" alignItems="flex-end" color="neutralN200">
    <>
      <Typography as="div" variant="body/lg-light">
        {formatPoints(rewardsProfile.points)}
      </Typography>
      <Typography as="div" variant="body/sm">
        Points
      </Typography>
    </>
  </Box>
);

type NextTierProgress = {
  rewardsProfile: Exclude<NormalizedRewards.Enabled, NormalizedRewards.Platinum>;
};
const NextTierProgress = ({ rewardsProfile }: NextTierProgress) => {
  const amountSpent = formatCurrency(rewardsProfile.spendUnlock[0], false);
  const spendTarget = formatCurrency(rewardsProfile.spendUnlock[1], false);
  const spendProgress = Math.round(
    (rewardsProfile.spendUnlock[0] / (rewardsProfile.spendUnlock[1] ?? 1)) * 100
  );
  const bookingsProgress = Math.round(
    (rewardsProfile.bookingsUnlock[0] / (rewardsProfile.bookingsUnlock[1] ?? 1)) * 100
  );

  return (
    <Box flexDirection="column" display="flex" position="relative">
      <Typography variant="heading/lg" paddingBottom={12}>
        Unlock{" "}
        <Styled.HighlightNextTierColor $rewardsTier={rewardsProfile.nextTier}>
          {rewardsProfile.nextTier}
        </Styled.HighlightNextTierColor>
      </Typography>
      <Box>
        <Styled.Progress>
          <Styled.ProgressBar
            $percent={spendProgress}
            $rewardsTier={rewardsProfile.nextTier}
            role="progressbar"
            aria-valuemin={0}
            aria-valuemax={rewardsProfile.spendUnlock[1]}
            aria-valuenow={spendProgress}
          />
        </Styled.Progress>
        <Box display="flex" justifyContent="space-between" paddingTop={2} paddingBottom={12}>
          <Typography variant="body/xs" color="neutralN200">
            Amount spent
          </Typography>
          <Typography variant="body/xs" color="neutralN200">
            {amountSpent} /{" "}
            <Styled.HighlightNextTierColor $rewardsTier={rewardsProfile.nextTier}>
              {spendTarget}
            </Styled.HighlightNextTierColor>
          </Typography>
        </Box>
        <Styled.Progress>
          <Styled.ProgressBar
            $percent={bookingsProgress}
            $rewardsTier={rewardsProfile.nextTier}
            role="progressbar"
            aria-valuemin={0}
            aria-valuemax={rewardsProfile.bookingsUnlock[1]}
            aria-valuenow={bookingsProgress}
          />
        </Styled.Progress>
        <Box display="flex" justifyContent="space-between" paddingTop={2}>
          <Typography variant="body/xs" color="neutralN200">
            Completed bookings
          </Typography>
          <Typography variant="body/xs" color="neutralN200">
            {rewardsProfile.bookingsUnlock[0]} /{" "}
            <Styled.HighlightNextTierColor $rewardsTier={rewardsProfile.nextTier}>
              {rewardsProfile.bookingsUnlock[1]}
            </Styled.HighlightNextTierColor>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export type UserRewards = { rewardsProfile: NormalizedRewards.Any };
export const UserRewards = ({ rewardsProfile }: UserRewards) => {
  if (rewardsProfile.tier === Rewards.Tier.Disabled) return null;

  if (rewardsProfile.tier === Rewards.Tier.Platinum) {
    return (
      <Typography variant="body/md-light" color="neutralN100">
        You're at peak reward, enjoy maximum perks and benefits like VIP support.
      </Typography>
    );
  }

  return <NextTierProgress rewardsProfile={rewardsProfile} />;
};
