import type { IMemberRecord } from "@hotel-engine/types/member";

const getNameOrEmail = (member: Partial<IMemberRecord>) => {
  const { firstName, lastName, email } = member;
  const fullName = `${firstName} ${lastName}`;

  if (firstName && lastName) return fullName;
  return email;
};

export default getNameOrEmail;
