/* eslint-disable */
export {
  type HasFn,
  type HasPathAssertion,
  type HasPathGuard,
  type HasPathParser,
  type HasPathsAssertion,
  type HasPathsGuard,
  type HasPathsParser,
  type HasPropAssertion,
  type HasPropFn,
  type HasPropGuard,
  type HasPropParser,
  has,
  hasPath,
  hasPaths,
  hasProp,
}

import {
  type Any,
  Fn,
  List,
  Result as R,
  Struct,
  Unsafe
} from "@hotel-engine/data"
import * as Core from "../shared/core"
import { Config } from "../shared/config/exports"
import {
  type Entries,
  type Entry,
  type Path,
  type Prop,
  type PropOrPath,
  isEntries,
  isPath,
  isProp,
  isPropOrPath,
} from "../shared/util"

import { Show } from "../show/exports"
import { Err } from "../shared/err/exports"

/**
 * TODO: Exported for testing helpers like `removeSubpaths` -- move the exported stuff elsewhere
 */
export namespace Impl {
  /** @internal */
  type HasPredicateCurriedParams = readonly [pathExpr: PropOrPath, guard?: Fn.Guard<Any.NonNullable>]
  /** @internal */
  type HasPredicateUncurriedParams = readonly [subject: unknown, pathExpr: PropOrPath, guard?: Fn.Guard<Any.NonNullable>]
  /** @internal */
  type HasPredicateParams =
    | HasPredicateCurriedParams
    | HasPredicateUncurriedParams

  /** @internal */
  const isCurried = (u: unknown): u is HasPredicateCurriedParams =>
    Array.isArray(u) && Object.prototype.hasOwnProperty.call(u, 0) && isPropOrPath(u[0])

  /** @internal */
  const isUncurried = (u: unknown): u is HasPredicateUncurriedParams =>
    Array.isArray(u) && Object.prototype.hasOwnProperty.call(u, 0) && Object.prototype.hasOwnProperty.call(u, 1) && isPropOrPath(u[1])

  /** @internal */
  const _hasOrExtendsProp = (key: Prop) => (u: unknown) =>
    Core.isObjectLike(u) && Core.isNonNullable((u as any)[key])

  /**
   * @external
   *
   * Type-guard that checks to see if a property exists on an object,
   * returning true even if the property is inherited (in this way it
   * differs from {@link hasProp}).
   *
   * See also {@link hasOrExtendsProp}.
   */
  export function hasOrExtendsProp<P extends Prop>(prop: P): (u: unknown) => u is { [K in P]: unknown }
  export function hasOrExtendsProp<P extends Prop, V extends Any.NonNullable>(prop: P, guard: Fn.Guard<V>): (u: unknown) => u is { [K in P]: V }
  export function hasOrExtendsProp(prop: Prop, guard?: Fn.Guard<Any.NonNullable>): unknown {
    return (u: unknown) =>
      !guard
        ? _hasProp(prop)(u)
        : _hasProp(prop)(u) && guard((u as Any.IndexedBy<typeof prop>)[prop])
  }

  /** @internal */
  const _hasProp = (key: Prop) => (u: unknown) =>
    _hasOrExtendsProp(key)(u) && Object.prototype.hasOwnProperty.call(u, key)

  /**
   * @external
   *
   * Type-guard that checks to see if a property exists on an object,
   * returning true only if the object "owns" the property. Uses
   * {@link Object.prototype.hasOwnProperty} under the hook.
   *
   * See also {@link hasOrExtendsProp}.
   */
  export function hasProp<P extends Prop>(prop: P): (u: unknown) => u is { [K in P]: unknown }
  export function hasProp<P extends Prop, V extends Any.NonNullable>(prop: P, guard: Fn.Guard<V>): (u: unknown) => u is { [K in P]: V }
  export function hasProp(prop: Prop, guard?: Fn.Guard<Any.NonNullable>): unknown {
    return (u: unknown) =>
      !guard
        ? _hasOrExtendsProp(prop)(u)
        : _hasOrExtendsProp(prop)(u) && guard((u as Any.IndexedBy<typeof prop>)[prop])
  }

  /** @external */
  export const hasPathOf
    : <P extends Path, V extends Any.NonNullable>(path: P, guard: Fn.Guard<V>) => (u: unknown) => u is Core.TreeFromPath<P, V>
    = (path, guard) => (u: unknown): u is never => hasPath(path, guard)(u)

  /** @external */
  export const hasPath
    : <P extends Path, V>(path: P, guard?: Fn.Guard<V>) => Fn.Predicate<unknown>
    = (path, guard = Core.isNonNullable as never) =>
      (u: unknown) => {
        if (!Core.isObjectLike(u)) return false

        const go
          : (acc: unknown, path: Path) => boolean
          = (acc, [hd, ...tl]) => {
            if (Core.isNullable(hd)) return guard(acc)               // CASE: base case
            else if (Core.isNullable((acc as any)[hd])) return false // CASE: fail fast
            else return go((acc as any)[hd], tl)                     // CASE: recursive
          }

        return go(u, path)
      }

  /** @internal */
  const fromEntries
    : (entries: Any.Array<Path | Entry>) => Any.Dict<Any.Guard>
    = (entries) =>
      entries
        .map((entry) => Fn.tuple(List.last(entry), List.init(entry).join("∷")))
        .reduce((acc, [guard, path]) => ({
          ...acc,
          [path]: guard
        }), Struct.emptyOf<Any.Dict<Any.Guard>>())
    ;

  /** @internal */
  const withoutSubpaths
    : (entries: readonly [string, Any.Guard][]) => Any.Dict<Any.Guard>
    = (entries) => {
      const paths = entries.map(entry => entry[0])
      return entries
        .filter(([path]) => {
          for (const p of paths) if (p !== path && p.includes(path)) return false
          return true
        })
        .reduce(
          (acc, [path, guard]) => ({
            ...acc,
            [path]: guard
          }),
          Struct.emptyOf<Any.Dict<Any.Guard>>()
        )
    }

  /** @internal */
  const pathDescriptor
    : <T extends Path | Entry>(t: T) => EntryToPathDescriptor<T>
    = (t) =>
      Fn.absorb(
        typeof List.last(t) === "function"
          ? ({ path: List.init(t), guard: List.last(t) })
          : ({ path: List.init(t), guard: Core.is.nonNullable })
      )

  /** @external */
  export const removeSubpaths
    : (pathToGuardMap: Any.Dict<Any.Guard>) => Any.Array<PathDescriptor>
    = (pathToGuardMap) => {
      const entries = Object.entries(pathToGuardMap)
      return Object.entries(withoutSubpaths(entries))
        .map(([path, guard]) => pathDescriptor([...path.split("∷"), guard] as const))
    }

  /** @internal */
  const predicateFromDescriptor
    : (descriptor: PathDescriptor) => Fn.Predicate<unknown>
    = ({ path, guard }) => (u) => _hasPredicate(u, path, guard)

  /** @external */
  export const hasPaths
    : <T extends Any.Array<Path | Entry>>(...entries: T) => Fn.Predicate<unknown>
    = Fn.flow(
      Fn.untupled(fromEntries),
      removeSubpaths,
      List.map(predicateFromDescriptor),
      Fn.all,
    )

  /** @internal */
  function _hasPredicate(
    ...fullArgs: HasPredicateParams
  ): boolean {
    if (isCurried(fullArgs))
      return Fn.absorb(
        (u: unknown) =>
          isProp(fullArgs[0]) ? _hasPredicate(u, fullArgs[0], fullArgs[1])
            : isPath(fullArgs[0]) ? _hasPredicate(u, fullArgs[0], fullArgs[1])
              : Fn.exhaustive(`has`, fullArgs[0])
      )
    else if (isUncurried(fullArgs)) {
      const [u, p, guard] = fullArgs
      if (isProp(p)) {
        // if (!hasOrExtendsProp(p, u))
        if (!hasProp(p)(u))
          return false
        else if (Core.isNonNullable(guard))
          return guard((u)[p])
        else
          return Core.isNonNullable(u[p])
      }
      else if (isPath(p)) {
        return (hasPath(p, guard)(u))
      }
      else
        // THROW: `p` is neither valid prop nor valid path
        return Err.Unsafe.handleIllegalState(`has`, fullArgs)
    }
    else {
      // THROW: checks that the implementation handles all possible cases
      return Fn.exhaustive(`_hasPredicate`, fullArgs)
    }
  }
}

/** @internal */
namespace Args {
  /** @external */
  export type Tag = typeof Tag[keyof typeof Tag]
  /** @external */
  export const Tag = {
    JustProp: "JustProp",
    PropWithGuard: "PropWithGuard",
    PropWithOpt: "PropWithOpt",
    PropWithGuardAndOpt: "PropWithGuardAndOpt",
    JustPath: "JustPath",
    PathWithGuard: "PathWithGuard",
    PathWithOpt: "PathWithOpt",
    PathWithGuardAndOpt: "PathWithGuardAndOpt",
    JustEntries: "JustEntries",
    EntriesWithOpt: "EntriesWithOpt",
  } as const

  /** @external */
  export type JustProp = readonly [prop: Prop]
  /** @external */
  export type PropWithGuard = readonly [prop: Prop, guard: Fn.Guard<any>]
  /** @external */
  export type PropWithOpt = readonly [prop: Prop, options: Config.Options]
  /** @external */
  export type PropWithGuardAndOpt = readonly [prop: Prop, guard: Fn.Guard<any>, options: Config.Options]
  /** @external */
  export type JustPath = readonly [path: Path]
  /** @external */
  export type PathWithGuard = readonly [path: Path, guard: Fn.Guard<any>]
  /** @external */
  export type PathWithOpt = readonly [path: Path, options: Config.Options]
  /** @external */
  export type PathWithGuardAndOpt = readonly [path: Path, guard: Fn.Guard<any>, options: Config.Options]
  /** @external */
  export type JustEntries = Entries
  /** @external */
  export type EntriesWithOpt<
    PS extends
    | Any.Array<Path | Entry>
    = Any.Array<Path | Entry>,
    Opt extends
    | Config.Options
    = Config.Options
  > = readonly [...PS, Opt]

  /** @external */
  export type FullArgs = FullArgsSet[number]
  /** @external */
  export type FullArgsSet = readonly [
    JustProp,
    PropWithGuard,
    PropWithOpt,
    PropWithGuardAndOpt,
    JustPath,
    PathWithGuard,
    PathWithOpt,
    PathWithGuardAndOpt,
    JustEntries,
    EntriesWithOpt,
  ]

  /** @external */
  export type TaggedArgs = TaggedArgsSet[number]
  /** @external */
  export type TaggedArgsSet = readonly [
    { type: typeof Tag.JustProp, args: Args.JustProp },
    { type: typeof Tag.PropWithGuard, args: Args.PropWithGuard },
    { type: typeof Tag.PropWithOpt, args: Args.PropWithOpt },
    { type: typeof Tag.PropWithGuardAndOpt, args: Args.PropWithGuardAndOpt },
    { type: typeof Tag.JustPath, args: Args.JustPath },
    { type: typeof Tag.PathWithGuard, args: Args.PathWithGuard },
    { type: typeof Tag.PathWithOpt, args: Args.PathWithOpt },
    { type: typeof Tag.PathWithGuardAndOpt, args: Args.PathWithGuardAndOpt },
    { type: typeof Tag.JustEntries, args: Args.JustEntries },
    { type: typeof Tag.EntriesWithOpt, args: Args.EntriesWithOpt },
  ]

  /** @internal */
  const isJustProp = (args: Args.FullArgs): args is Args.JustProp =>
    args.length === 1 && isProp(args[0])
  /** @internal */
  const isPropWithGuard = (args: Args.FullArgs): args is Args.PropWithGuard =>
    args.length === 2 && isProp(args[0]) && Core.is.function(args[1])
  /** @internal */
  const isPropWithOpt = (args: Args.FullArgs): args is Args.PropWithOpt =>
    args.length === 2 && isProp(args[0]) && Core.is.struct(args[1])
  /** @internal */
  const isPropWithGuardAndOpt = (args: Args.FullArgs): args is Args.PropWithGuardAndOpt =>
    args.length === 3 && isProp(args[0]) && Core.is.function(args[1]) && Core.is.struct(args[2])
  /** @internal */
  const isJustPath = (args: Args.FullArgs): args is Args.JustPath =>
    args.length === 1 && isPath(args[0])
  /** @internal */
  const isPathWithGuard = (args: Args.FullArgs): args is Args.PathWithGuard =>
    args.length === 2 && isPath(args[0]) && Core.is.function(args[1])
  /** @internal */
  const isPathWithOpt = (args: Args.FullArgs): args is Args.PathWithOpt =>
    args.length === 2 && isPath(args[0]) && Core.is.function(args[1])
  /** @internal */
  const isPathWithGuardAndOpt = (args: Args.FullArgs): args is Args.PathWithGuardAndOpt =>
    args.length === 3 && isPath(args[0]) && Core.is.function(args[1]) && Core.is.struct(args[2])

  /** @internal */
  const isJustEntries
    : (args: Args.FullArgs) => args is Args.JustEntries
    = isEntries
  /** @internal */
  const isEntriesWithOpt = (args: Args.FullArgs): args is Args.EntriesWithOpt =>
    isEntries(List.init(args)) && Core.is.struct(List.last(args))

  /** @external */
  export const tagArgs
    : (args: Args.FullArgs) => Args.TaggedArgs
    = (args) => {
      if (isJustProp(args)) return { type: Tag.JustProp, args }
      else if (isPropWithGuard(args)) return { type: Tag.PropWithGuard, args }
      else if (isPropWithOpt(args)) return { type: Tag.PropWithOpt, args }
      else if (isPropWithGuardAndOpt(args)) return { type: Tag.PropWithGuardAndOpt, args }
      else if (isJustPath(args)) return { type: Tag.JustPath, args }
      else if (isPathWithGuard(args)) return { type: Tag.PathWithGuard, args }
      else if (isPathWithOpt(args)) return { type: Tag.PathWithOpt, args }
      else if (isPathWithGuardAndOpt(args)) return { type: Tag.PathWithGuardAndOpt, args }
      else if (isJustEntries(args)) return { type: Tag.JustEntries, args }
      else if (isEntriesWithOpt(args)) return { type: Tag.EntriesWithOpt, args }
      else return Fn.exhaustive(`has`, args)
    }
}

/** @internal */
type EntryToPathDescriptor<T>
  = T extends Entry ? { path: Extract<List.Init<T>, Path>, guard: List.Last<T> }
  : T extends Path ? { path: T, guard: Fn.Guard<Any.NonNullable> }
  : never
  ;

/**
 * @internal
 * TODO: Might be able to remove this now
 */
type ApplyMissingGuards<PS>
  = Extract<
    { [ix in keyof PS]
      : PS[ix] extends readonly [...any, Fn.Guard<any>]
      ? PS[ix]
      : readonly [...Extract<PS[ix], Any.Array>, Fn.Guard<Any.NonNullable>] },
    Any.Array<Entry | Path>
  >

/** @internal */
type FromEntries<PS>
  = { [Ix in keyof PS]: EntryToPathDescriptor<PS[Ix]> }
  ;

/** @internal */
type PathDescriptor = {
  guard: Any.Guard
  path: Any.Array<Prop>
}

/** @internal */
type MergePaths<PS>
  = Go<PS[Extract<keyof PS, `${number}`>] extends
    | infer D
    ? D extends PathDescriptor
    ? D
    : never
    : never
  >

/** @internal */
type Next<PD extends PathDescriptor> = { guard: PD["guard"], path: List.Tail<PD["path"]> }
/** @internal */
type Done<PD extends PathDescriptor> = Fn.Guarded<PD["guard"]>
/** @internal */
type Go<PD extends PathDescriptor>
  = PD["path"] extends readonly []
  ? Done<PD>
  : { [P in PD extends PD ? PD : never as P["path"][0]]
    : Go<P extends P ? Next<P> : never> }
  ;

/** @internal */
type HasPropReturnType<P extends Prop, V extends Any.NonNullable, Options extends Config.Options>
  = Config.Interpret<Config.HasStrategyMap[Options["strategy"]], MergePaths<[EntryToPathDescriptor<[P, Fn.Guard<V>]>]>>
  ;
/** @internal */
type HasPathReturnType<P extends Path, V extends Any.NonNullable, Options extends Config.Options>
  = Config.Interpret<Config.HasStrategyMap[Options["strategy"]], MergePaths<[{ path: P, guard: Fn.Guard<V> }]>>
  ;
/** @internal */
type HasPathsReturnType<PS, Options extends Config.Options>
  = Config.Interpret<Config.HasStrategyMap[Options["strategy"]], MergePaths<FromEntries<ApplyMissingGuards<PS>>>>
  ;

/** @external */
interface HasPropGuard extends HasPropFn<{ strategy: "Guard" }> { }
/** @external */
interface HasPropAssertion extends HasPropFn<{ strategy: "Assert" }> { }
/** @external */
interface HasPropParser extends HasPropFn<{ strategy: "Parse" }> { }

/** @external */
interface HasPathAssertion extends HasPathFn<{ strategy: "Assert" }> { }
/** @external */
interface HasPathGuard extends HasPathFn<{ strategy: "Guard" }> { }
/** @external */
interface HasPathParser extends HasPathFn<{ strategy: "Parse" }> { }

/** @external */
interface HasPathsAssertion extends HasPathsFn<{ strategy: "Assert" }> { }
/** @external */
interface HasPathsGuard extends HasPathsFn<{ strategy: "Guard" }> { }
/** @external */
interface HasPathsParser extends HasPathsFn<{ strategy: "Parse" }> { }

/** @external */
interface HasPropFn<Options extends Config.Options> {
  <P extends Prop>(prop: P): HasPropReturnType<P, Any.NonNullable, Options>
  <P extends Prop, V extends Any.NonNullable>(prop: P, guard: Fn.Guard<V>): HasPropReturnType<P, V, Options>;
}

/** @external */
interface HasPathFn<Options extends Config.Options> {
  <const P extends Path>(path: P): HasPathReturnType<P, Any.NonNullable, Options>;
  <const P extends Path, V extends Any.NonNullable>(path: P, guard: Fn.Guard<V>): HasPathReturnType<P, V, Options>
}

/** @external */
type HasPathsFn<Opt extends Config.Options> = <const PS extends Any.Array<Entry>>(...entries: PS) => HasPathsReturnType<PS, Opt>

/** @internal */
const makeParseErrorMsg
  = (path: Show.ShowableTree) =>
    () =>
      `Received a value that failed to satisfy guard: \`Has(${Show.show(path)})\``

/** @internal */
type MatchOptions<Opt extends Config.Options, Target, Source> = {
  [Config.Strategy.Assert]: Fn.Assert<[from: Source, to: Target]>
  [Config.Strategy.Guard]: Fn.TypePredicate<[from: Source, to: Target]>
  [Config.Strategy.Parse]: (input: Source) => R.Result<Target, string>
}[Opt["strategy"]]

/** @internal */
const matchOptions
  : <Opt extends Config.Options>(opt: Opt)
    => <Target, Source>(predicate: Fn.Predicate<Source> | Fn.TypePredicate<[Source, Target]>, path: Show.ShowableTree)
      => MatchOptions<Opt, Target, Source>
  = (opt) =>
    (predicate, path) => {
      switch (opt.strategy) {
        case Config.Strategy.Assert:
          return Fn.absorb(
            Unsafe.unsafeParserFromGuard(
              predicate as never,
              makeParseErrorMsg(path)(),
            )
          )
        case Config.Strategy.Guard:
          return Fn.absorb(predicate)
        case Config.Strategy.Parse:
          return Fn.absorb(
            R.fromPredicate(
              predicate,
              makeParseErrorMsg(path),
            )
          )
        default:
          return Fn.exhaustive(`matchOptions`, opt.strategy)
      }
    }

/** @internal */
const isConfig
  : (u: unknown) => u is Config.Options
  = Fn.absorb(Core.is.struct)

/** @external */
function hasProp<P extends Prop>(prop: P): HasPropReturnType<P, Any.NonNullable, Config.DefaultOptions>;
function hasProp<P extends Prop, V extends Any.NonNullable>(prop: P, guard: Fn.Guard<V>): HasPropReturnType<P, V, Config.DefaultOptions>;
function hasProp<Opt extends Config.Options>(options: Opt): {
  <P extends Prop>(prop: P): HasPropReturnType<P, Any.NonNullable, Opt>
  <P extends Prop, V extends Any.NonNullable>(prop: P, guard: Fn.Guard<V>): HasPropReturnType<P, V, Opt>
};
function hasProp(
  ...[$1, $2 = Core.is.nonNullable]:
    | readonly [Config.Options]
    | readonly [prop: Prop, guard: Fn.Guard<Any.NonNullable>]
): unknown {
  return (
    isConfig($1)
      ? (prop: Prop, guard: Fn.Guard<Any.NonNullable> = Core.is.nonNullable) =>
        matchOptions($1)(hasProp(prop, guard), prop)
      : Impl.hasProp($1, $2)
  )
}

/** @external */
function hasPath<const P extends Path>(path: P): HasPathReturnType<P, Any.NonNullable, Config.DefaultOptions>;
function hasPath<const P extends Path, V extends Any.NonNullable>(path: P, guard: Fn.Guard<V>): HasPathReturnType<P, V, Config.DefaultOptions>;
function hasPath<Opt extends Config.Options>(options: Opt): {
  <const P extends Path>(path: P): HasPathReturnType<P, Any.NonNullable, Opt>;
  <const P extends Path, V extends Any.NonNullable>(path: P, guard: Fn.Guard<V>): HasPathReturnType<P, V, Opt>;
};
function hasPath(
  ...[$1, $2 = Core.is.nonNullable]:
    | readonly [path: Path]
    | readonly [path: Path, guard: Fn.Guard<Any.NonNullable>]
    | readonly [Config.Options]
): unknown {
  return (
    !isConfig($1)
      ? Impl.hasPath($1, $2)
      : (path: Path, guard: Fn.Guard<Any.NonNullable> = Core.is.nonNullable) =>
        matchOptions($1)(hasPath(path, guard), path)
  )
}

/** @external */
function hasPaths<const PS extends Any.Array<Path | Entry>>(...entries: PS): HasPathsReturnType<PS, Config.DefaultOptions>;
function hasPaths<Opt extends Config.Options>(options: Opt): <const PS extends Any.Array<Path | Entry>>(...entries: PS) => HasPathsReturnType<PS, Opt>;
function hasPaths(
  ...fullArgs:
    | readonly [Config.Options, undefined]
    | Any.Array<Entry>
): unknown {
  const [$1] = fullArgs
  return isEntries(fullArgs)
    ? Impl.hasPaths(...fullArgs)
    : isConfig($1)
      ? (...entries: Any.Array<Entry>) => matchOptions($1)(Impl.hasPaths(...entries), entries.map(List.init))
      : Fn.exhaustive(`hasPaths`, fullArgs as never)
    ;
}

/** @internal */
type hasFn = typeof has
/** @external */
interface HasFn extends hasFn { }

/** @external */
function has<P extends Prop>(prop: P): HasPropReturnType<P, Any.NonNullable, Config.DefaultOptions>;
function has<const P extends Path>(path: P): HasPathReturnType<P, Any.NonNullable, Config.DefaultOptions>;
function has<const P extends Entries>(...entries: P): HasPathsReturnType<P, Config.DefaultOptions>
function has<P extends Prop, V extends Any.NonNullable>(prop: P, guard: Fn.Guard<V>): HasPropReturnType<P, V, Config.DefaultOptions>;
function has<P extends Prop, Opt extends Config.Options>(prop: P, options: Opt): HasPropReturnType<P, Any.NonNullable, Opt>;
function has<const P extends Path, V extends Any.NonNullable>(path: P, guard: Fn.Guard<V>,): HasPathReturnType<P, V, Config.DefaultOptions>;
function has<const P extends Path, Opt extends Config.Options>(path: P, options: Opt): HasPathReturnType<P, Any.NonNullable, Opt>;
function has<const P extends Entries, Opt extends Config.Options>(...args: Args.EntriesWithOpt<P, Opt>): HasPathsReturnType<P, Opt>
function has<P extends Prop, V extends Any.NonNullable, Opt extends Config.Options>(prop: P, guard: Fn.Guard<V>, options: Opt): HasPropReturnType<P, V, Opt>;
function has<const P extends Path, V extends Any.NonNullable, Opt extends Config.Options>(path: P, guard: Fn.Guard<V>, options: Opt): HasPathReturnType<P, V, Opt>;
function has(
  ...fullArgs: Args.FullArgs
): unknown {
  const { type, args } = Args.tagArgs(fullArgs)
  switch (type) {
    case Args.Tag.JustProp: return Impl.hasProp(...args)
    case Args.Tag.JustPath: return Impl.hasPath(...args)
    case Args.Tag.JustEntries: return Impl.hasPaths(...args)
    case Args.Tag.PropWithGuard: {
      const [prop, guard] = args
      return matchOptions(Config.DefaultOptions)(Impl.hasProp(prop, guard), [prop])
    }
    case Args.Tag.PathWithGuard: {
      const [path, guard] = args
      return matchOptions(Config.DefaultOptions)(Impl.hasPath(path, guard), path)
    }
    case Args.Tag.PropWithOpt: {
      const [prop, opt] = args
      return matchOptions(opt)(Impl.hasProp(prop), [prop])
    }
    case Args.Tag.PathWithOpt: {
      const [path, opt] = args
      return matchOptions(opt)(Impl.hasPath(path), path)
    }
    case Args.Tag.PropWithGuardAndOpt: {
      const [prop, guard, opt] = args
      return matchOptions(opt)(Impl.hasProp(prop, guard), [prop])
    }
    case Args.Tag.PathWithGuardAndOpt: {
      const [path, guard, opt] = args
      return matchOptions(opt)(Impl.hasPath(path, guard), path)
    }
    case Args.Tag.EntriesWithOpt: {
      const [entries, opt] = List.dequeue(args)
      return matchOptions(opt)(Impl.hasPaths(...entries), entries.map(List.init))
    }
    default: {
      return Fn.exhaustive(`has`, args)
    }
  }
}
