import type { DefaultTheme, StyledProps } from "styled-components";
import { css } from "styled-components";

import { sizes } from "../../styles";
import { typography } from "@hotel-engine/styles/helpers/mixins";
import type { ITypography } from "../../styles/helpers/mixins";

/**
 * All the different variants used throughout our site. We should exclusively be using these instead of creating custom one-off styles
 *
 * @remarks*
 * {@link https://www.figma.com/file/te9lrwlyjuSXOEqn6C6Yki/Foundation-Update?node-id=2522%3A2156 | figma spec}
 *
 * - `xxxlarge`: Marketing page headings
 * - `xxlarge`: Marketing page headings
 * - `xlarge`: Page heading desktop
 * - `large`: Page heading mobile
 * - `medium`: Section headings
 * - `small`: Standard cards/panels, list items, menu items
 * - `xsmall`: Standard cards, panels, compact list items, compact menu items
 * - `body-large`: Body copy for touch devices and more spacious Marketing pages
 * - `body-medium`: Standard Body style for web and general product pages
 * - `body-small`: For more condensed product designs.
 */
export type TypographyVariant =
  | "xxxlarge"
  | "xxlarge"
  | "xlarge"
  | "large"
  | "medium"
  | "small"
  | "xsmall"
  | "body-large"
  | "body-medium"
  | "body-small";

const variantTypeStyles: { [key in TypographyVariant]: ITypography } = {
  xxxlarge: {
    font: "primary",
    size: "xxxl",
    height: "xxxl",
    weight: "bold",
  },
  xxlarge: {
    font: "primary",
    size: "xxl",
    height: "xxl",
    weight: "bold",
  },
  xlarge: {
    font: "primary",
    size: "xl",
    height: "xl",
    weight: "bold",
  },
  large: {
    font: "primary",
    size: "lg",
    height: "lg",
    weight: "bold",
  },
  medium: {
    font: "primary",
    size: "md",
    height: "md",
    weight: "bold",
  },
  small: {
    font: "primary",
    size: "sm",
    height: "sm",
    weight: "bold",
  },
  xsmall: {
    font: "primary",
    size: "xs",
    height: "xs",
    weight: "bold",
  },
  "body-large": {
    font: "primary",
    size: "md",
    height: "md",
    weight: "normal",
  },
  "body-medium": {
    font: "primary",
    size: "sm",
    height: "sm",
    weight: "normal",
  },
  "body-small": {
    font: "primary",
    size: "xs",
    height: "xs",
    weight: "normal",
  },
};

// desktopSize: mobileSize
const desktopToMobileVariant: Partial<{
  [key in TypographyVariant]: TypographyVariant;
}> = {
  xxxlarge: "xxlarge",
  xxlarge: "xlarge",
  xlarge: "large",
  large: "medium",
};

const getMobileStyles = (variant: TypographyVariant) => {
  const mobileVariant = desktopToMobileVariant[variant];
  const typeStyles = mobileVariant && variantTypeStyles[mobileVariant];

  return (
    typeStyles &&
    css`
      @media screen and (max-width: ${sizes.breakpoints.lg}) {
        ${typography(typeStyles)}
      }
    `
  );
};

export const getTypeStylesForVariant = ({
  variant,
  isResponsive = true,
}: StyledProps<{ variant: TypographyVariant; isResponsive?: boolean }>) => {
  const typeStyles = variantTypeStyles[variant];

  return (
    typeStyles &&
    css`
      ${isResponsive && getMobileStyles(variant)}
      ${typography(typeStyles)}
    `
  );
};

/**
 * @deprecated If you're building out a type style, utilize the new `Typography` component found in the `@hotelengine/atlas-web` package.
 * @see {@link [Design Guide for more details](https://atlas-design-system.supernova-docs.io/latest/guides/migration-guides/typography-migration-guide-Amp7Gns2)}
 * @example
 * OLD: xxxlarge | NEW: heading/3xl
 * OLD: xxlarge | NEW: heading/2xl
 * OLD: xlarge | NEW: heading/xl
 * OLD: large | NEW: heading/lg
 * OLD: medium | NEW: heading/md
 * OLD: small | NEW: heading/sm
 * OLD: xsmall | NEW: heading/xs
 * OLD: body-large | NEW: body/md
 * OLD: body-medium | NEW: body/sm
 * OLD: body-small | NEW: body/xs
 *
 
 *
 * @param variant - the type styles that will be applied inside of the component
 * @param isResponsive - if true the type will have mobile & desktop styles if applicable. defaults to true
 * @returns a function that accepts a theme that returns the variant's styles.
 *
 * @example
 * export const Title = styled.h1`
 *   ${typographyMixin('display01')}
 * `
 */
export function typographyMixin(variant: TypographyVariant, isResponsive = true) {
  return function ({ theme }: { theme: DefaultTheme }) {
    return getTypeStylesForVariant({ variant, isResponsive, theme });
  };
}
