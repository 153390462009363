import type { UseMutationOptions } from "react-query";
import { useMutation, useQueryClient } from "react-query";

import { cacheKeys, endpoints } from "@hotel-engine/react-query/constants";
import { useApi } from "@hotel-engine/react-query/useApi";
import type { IErrorResponse } from "@hotel-engine/types/errors";
import type {
  IHotelLoyaltyUserRecord,
  IUpdateUserLoyaltyRewards,
} from "@hotel-engine/types/hotelLoyalty";
import { convertEmptyStrValsToNull } from "@hotel-engine/utilities";

export const useUpdateUserLoyaltyRewards = (
  options: UseMutationOptions<
    IHotelLoyaltyUserRecord[],
    IErrorResponse,
    IUpdateUserLoyaltyRewards
  > = {}
) => {
  const post = useApi("post");
  const queryClient = useQueryClient();

  return useMutation(
    (params) => post(`${endpoints.userLoyaltyRewards}/manage`, convertEmptyStrValsToNull(params)),
    {
      onSuccess: () =>
        Promise.all([
          queryClient.invalidateQueries(cacheKeys.useMembersQuery),
          queryClient.invalidateQueries(`${endpoints.userLoyaltyRewards}/manage`),
        ]),
      ...options,
    }
  );
};

/**
 * Constructs the request body to update or delete a single hotel loyalty record on a user account.
 *
 * @param userId The id of the user.
 * @param loyaltyRewardId The id of the loyalty reward program.
 * @param programNumber The program number or `null` to delete the record.
 */
export const loyaltyRewardUpdateReq = (
  userId: number,
  loyaltyRewardId: number,
  programNumber: string | null
): IUpdateUserLoyaltyRewards => ({
  userId,
  userLoyaltyRewards: [
    {
      loyaltyRewardId,
      /** Send an empty string as the program number property to delete the record. */
      programNumber: programNumber ?? "",
    },
  ],
});
