import styled from "styled-components";

export const ExpressBookPaymentErrorBannerWrapper = styled.div`
  display: grid;
  grid-template-columns: 35px 1fr;
  background-color: ${({ theme }) => theme.legacy.colors.red[50]};
  padding: 16px;
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.lg};
  margin: 0px 16px 16px 16px;

  svg {
    margin: 4px 0 0 6px;
  }
`;

export const ExpressBookPaymentErrorBannerColumn = styled.div`
  display: flex;
  flex-direction: column;

  ul {
    padding-left: 16px;
    margin-bottom: 0;
  }
`;

export const ExpressBookPaymentErrorBannerTitle = styled.div`
  font-weight: bold;
`;
