import type { SkeletonAvatarProps } from "antd/lib/skeleton/Avatar";

const paragraphWidthArray = ["100%", "40%", "20%"];

// Paragraph props are needed to set the number of rows and length of each row for the antd Skeleton
export const buildParagraphProps = (rows: number) => ({
  rows: rows,
  width: paragraphWidthArray.slice(0, rows),
});

// Build our default antd props for each type
export const cardPropsConfig = {
  bar: {
    paragraph: buildParagraphProps(1),
    title: false,
  },
  long: {
    paragraph: buildParagraphProps(2),
    title: false,
    avatar: true,
  },
  tall: {
    paragraph: buildParagraphProps(3),
    title: { width: "100%" },
  },
  default: {
    paragraph: buildParagraphProps(3),
    title: false,
    avatar: { shape: "square" } as SkeletonAvatarProps,
  },
};
