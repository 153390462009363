import { useState } from "react";

// import { datadogRum } from "@datadog/browser-rum";
import type { FormikProps } from "formik";
import { Formik } from "formik";
import * as yup from "yup";

import { message } from "@hotel-engine/common/Message";
import { usePostCheckoutActions } from "@hotel-engine/contexts";
import { useCreatePropertyReview } from "@hotel-engine/react-query/propertyReviews/useCreatePropertyReview";
import { AmplitudeFormObserver } from "@hotel-engine/scripts/hooks";
// import type { INotificationReferrer } from "@hotel-engine/types/notifications";
import type { IPropertyReviewInfo } from "@hotel-engine/types/property";
import { captureMessage } from "@hotel-engine/utilities";
import { ampli } from "ampli";

import { PropertyInfo } from "../PropertyInfo";
import { ReviewStars, ReviewText } from "./components";
import * as Styled from "./styles";
import { ReviewLabel } from "./styles";
import { Unsafe } from "@hotel-engine/data";

const AMPLI_EVENT_LIST = {
  reviewText: {
    title: "editTellUsAboutYourStay",
    additionalValue: "typedText",
  },
};

export const REVIEW_TEXT_MAX_LENGTH = 800;
const STAR_MINIMUM = 0;

const starSelectionCopy = [
  "Select a rating.",
  "Terrible! There were many issues. ",
  "Poor. I was disappointed with my stay.",
  "Good. There were no issues. ",
  "Great! I was happy with my stay.",
  "Excellent, above and beyond",
];

const ReviewSchema = yup.object().shape({
  starRating: yup
    .number()
    .moreThan(STAR_MINIMUM, `Must select greater than ${STAR_MINIMUM} star rating`),

  // although not be seen by user, will retain this validation to be consistent with yup
  reviewText: yup
    .string()
    .max(
      REVIEW_TEXT_MAX_LENGTH,
      `Responses are limited to ${REVIEW_TEXT_MAX_LENGTH} characters or fewer`
    ),
});

// using formik to disable/enable submit based on yup validations
const isFormInvalid: (val: FormikProps<IValidationValues>) => boolean = ({ errors }) => {
  return Object.keys(errors).length !== 0;
};

export interface IValidationValues {
  starRating: number;
  reviewText: string;
}

interface IAddPropertyReview {
  contractNumber?: string | undefined;
  propertyInfo: IPropertyReviewInfo;
  // referrer: INotificationReferrer;
  setStep: () => void;
  skipStep: () => void;
}

const AddPropertyReview = ({
  contractNumber,
  propertyInfo,
  setStep,
  skipStep,
}: // TODO re-enable if datadog rum turned back on
// referrer,
IAddPropertyReview) => {
  const [hoveringOver, setHoveringOver] = useState(-1);
  const [starRating, setStarRating] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const createReview = useCreatePropertyReview();
  // Post checkout context
  const { dispatch: dispatchPostCheckoutActions } = usePostCheckoutActions();

  const ampliSubmissionObj = {
    bookingId: contractNumber,
  };

  const submit = async () => {
    try {
      await createReview.mutateAsync(
        {
          propertyReviewRequestId: propertyInfo.id,
          rating: starRating * 2,
          reviewText,
        },
        {
          onSuccess: () => {
            dispatchPostCheckoutActions({
              type: "VERIFY_POST_CHECKOUT_ACTION_SUCCESS",
              payload: propertyInfo.id,
            });
            setStep();
          },
        }
      );
      // datadogRum.addAction("propertyReview", {
      //   businessId: propertyInfo?.businessId,
      //   email: propertyInfo?.email,
      //   propertyId: propertyInfo?.propertyId,
      //   propertyName: propertyInfo?.propertyName,
      //   propertyReviewId: propertyInfo?.id,
      //   referrer,
      //   reviewText,
      //   starRating,
      // });

      ampli.clickSubmit(ampliSubmissionObj);
    } catch (error) {
      captureMessage("Submit property review error", { error });
      message
        .error(<Styled.ErrorMessage>An error occurred, please try again</Styled.ErrorMessage>, 0)
        .then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    }
  };

  const handleStarRating = (val: number) => {
    setStarRating(val);
    ampli.clickStarRating({
      minValue: val,
      ...ampliSubmissionObj,
    });
  };

  const initialValues = { reviewText: "", starRating: 0 };
  const values = { reviewText: reviewText, starRating: 0 };

  return (
    <Styled.ReviewContentsWrapper>
      <PropertyInfo propertyInfo={propertyInfo} />
      <Styled.ReviewForm>
        <Formik
          onSubmit={submit}
          initialValues={initialValues}
          validationSchema={ReviewSchema}
          validateOnChange
          validateOnMount
        >
          {(formikErrors) => {
            const isSubmitDisabled = isFormInvalid(formikErrors) || createReview.isLoading;

            return (
              <Styled.FormikForm>
                <Styled.ReviewFormWrapper data-testid="reviewForm">
                  <AmplitudeFormObserver
                    eventList={AMPLI_EVENT_LIST}
                    initialValues={initialValues as IValidationValues}
                    submissionObj={ampliSubmissionObj}
                    values={values as IValidationValues}
                  />
                  <ReviewLabel>How was your stay?</ReviewLabel>
                  <ReviewStars
                    setStarRating={handleStarRating}
                    setHoverOn={(value) => setHoveringOver(value)}
                    hoverOn={hoveringOver}
                  />
                  <Styled.StarCopy>
                    {starSelectionCopy[hoveringOver !== -1 ? hoveringOver : starRating]}
                  </Styled.StarCopy>
                  <ReviewText
                    setReviewText={setReviewText}
                    value={reviewText}
                    maxLength={REVIEW_TEXT_MAX_LENGTH}
                    showCount
                  />
                  <Styled.ReviewFooter>
                    <Styled.ReviewButton
                      onClick={skipStep}
                      size="large"
                      data-testid="reviewFormSkip"
                    >
                      Skip
                    </Styled.ReviewButton>
                    <Styled.SubmitButton
                      type="primary"
                      size="large"
                      disabled={isSubmitDisabled}
                      htmlType="submit"
                      loading={createReview.isLoading}
                    >
                      Submit
                    </Styled.SubmitButton>
                  </Styled.ReviewFooter>
                </Styled.ReviewFormWrapper>
              </Styled.FormikForm>
            );
          }}
        </Formik>
      </Styled.ReviewForm>
    </Styled.ReviewContentsWrapper>
  );
};

export default AddPropertyReview;
