export const getInitials = (value: string, useSingleInitial?: boolean) => {
  let initials;
  initials = value
    .split(/\s/)
    .map((part) => part.substring(0, 1).toUpperCase())
    .filter((v) => !!v);
  if (initials.length > 2) {
    initials = [initials.shift(), initials.pop()];
  }

  if (useSingleInitial) {
    initials = [initials[0]];
  }

  return initials.join("");
};
