import { Icon, Typography } from "@hotelengine/atlas-web";
import * as Styled from "./styles";

export interface IEmptyInbox {
  isError: boolean;
}

const EMPTY_COPY = {
  title: "You're all caught up!",
  body: "We will alert you when new notifications arrive.",
};

const ERROR_COPY = {
  title: "It's not you it's us!",
  body: "Something techie is wrong on our end, but we are on it! Please check back later.",
};

const EmptyInbox = ({ isError }: IEmptyInbox) => {
  const copyObj = isError ? ERROR_COPY : EMPTY_COPY;
  const { body, title } = copyObj;
  return (
    <Styled.EmptyInbox data-testid="empty-inbox">
      <Icon name="bell--solid" />
      <Typography as="h4" variant="heading/md" marginTop={12}>
        {title}
      </Typography>
      <Typography as="p" variant="body/sm">
        {body}
      </Typography>
    </Styled.EmptyInbox>
  );
};

export default EmptyInbox;
