/* eslint-disable
 @typescript-eslint/ban-types,
 @typescript-eslint/naming-convention,
 @typescript-eslint/no-explicit-any,
 @typescript-eslint/no-non-null-assertion,
 prefer-rest-params,
*/
export {
  pipe,
}

import { absorb, type Functions } from "./shared"

interface Module {
  pipe: {
    <A, B>(a: A, ab: (a: A) => B): B
    <A, B, C>(a: A, ab: (a: A) => B, bc: (b: B) => C): C
    <A, B, C, D>(a: A, ab: (a: A) => B, bc: (b: B) => C, cd: (c: C) => D): D
    <A, B, C, D, E>(a: A, ab: (a: A) => B, bc: (b: B) => C, cd: (c: C) => D, de: (d: D) => E): E
    <A, B, C, D, E, F>(a: A, ab: (a: A) => B, bc: (b: B) => C, cd: (c: C) => D, de: (d: D) => E, ef: (e: E) => F): F
    <A, B, C, D, E, F, G>(a: A, ab: (a: A) => B, bc: (b: B) => C, cd: (c: C) => D, de: (d: D) => E, ef: (e: E) => F, fg: (f: F) => G): G
  }
}

const pipe
  : Module["pipe"]
  = absorb(
    function pipe<A>(...[a, ab, bc, cd, de, ef, fg]: [A, ...Functions]): unknown {
      switch (arguments.length) {
        case 1:
          return a
        case 2:
          return ab!(a)
        case 3:
          return bc!(ab!(a))
        case 4:
          return cd!(bc!(ab!(a)))
        case 5:
          return de!(cd!(bc!(ab!(a))))
        case 6:
          return ef!(de!(cd!(bc!(ab!(a)))))
        case 7:
          return fg!(ef!(de!(cd!(bc!(ab!(a))))))
        default: {
          let out = arguments[0]
          for (let i = 1; i < arguments.length; i++) {
            out = arguments[i](out)
          }
          return out
        }
      }
    }
  )
