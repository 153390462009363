import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import Auth from "@hotel-engine/services/Auth";
import { usePreloadAllChunks } from "@hotel-engine/hooks/usePreloadAllChunks";
import useVersionCheckOnLocationChange from "@hotel-engine/scripts/hooks/useVersionCheckOnLocationChange";

/**
 * Side effects that we want to run as early as possible during app start up,
 * but require being within a router provider, should go here. Let's place this
 * component directly beneath the router provider.
 */
export function OnRouterInit({ children }: { children: React.ReactNode }) {
  usePreloadAllChunks();
  useVersionCheckOnLocationChange({
    onVersionMismatch: () => {
      globalThis.location.reload();
    },
  });

  return <WithInitAuth>{children}</WithInitAuth>;
}

/**
 * We currently have an old class called {@link Auth} that we plan to
 * delete soon when we redo our Auth flow. Currently, {@link Auth} uses a bunch
 * of static methods. Those static methods need to be able to perform
 * page navigation (like `navigate()` / `navigate()`), but they can't
 * use those hooks since it's not a component or hook.
 *
 * So when the App inits, we are going to set a static member on the
 * the Auth class called `Auth.reactRouterNavigateFn`.
 *
 * We chose this approach, rather than refactoring a bunch of the app,
 * since we're moving off of `Auth` soon anyways.
 */
export function WithInitAuth({ children }: { children: React.ReactNode }) {
  const rrNavigate = useNavigate();

  if (!Auth.hasNavigateFn) {
    Auth.setNavigateFn((relativePath: string) => {
      rrNavigate(relativePath);
    });
  }

  return <>{children}</>;
}
