import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import { Modal } from "@hotel-engine/common/Modal";
import { useExpressBook } from "pages/Checkout/LegacyLodging/ExpressBookModal/hooks/useExpressBook";
import { useCreateContractRate } from "@hotel-engine/react-query/contractRate/useCreateContractRate";
import { useCreateReservationActionCable } from "@hotel-engine/react-query/reservation/useCreateReservation";
import storage from "@hotel-engine/storage";
import type { GuestSelectionGuests, IOccupant } from "@hotel-engine/types/booking";
import type { IContractRate } from "@hotel-engine/types/contractRate";
import type {
  IExpressBookCheckoutForm,
  IExpressBookContractDataResponse,
  IExpressBookContractRate,
} from "@hotel-engine/types/expressBook";
import { ExpressBookModalStep } from "@hotel-engine/types/expressBook";
import type { IPropertyLegacy } from "@hotel-engine/types/property";
import type {
  ICreateReservationError,
  IReservationCreatePayload,
  IReservationResponse,
} from "@hotel-engine/types/reservation";
import { isCreateReservationError, isIReservationError } from "@hotel-engine/types/reservation";
import type { IUser } from "@hotel-engine/types/user";
import { captureMessage } from "@hotel-engine/utilities";
import type { FormikProps } from "formik";
import { Formik } from "formik";
import { pick } from "lodash";
import type { TBookingModalState } from "pages/Checkout/LegacyLodging/BookingModal";
import BookingModal from "pages/Checkout/LegacyLodging/BookingModal";
import { refreshContractRateTimeout } from "pages/Checkout/LegacyLodging/constants";
import { formatPaymentTypes, returnGuestList } from "pages/Checkout/LegacyLodging/helpers";
import { directBill } from "pages/Checkout/LegacyLodging/PaymentMethods/DirectBillItem";
import { checkoutValidate } from "pages/Checkout/validation";
import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "store/hooks";
import { isRoomRefundable } from "../../helpers";
import type { TExpressBookFormErrorTypes } from "../ExpressBookCheckoutFormError/ExpressBookCheckoutFormError";
import { ExpressBookCheckoutFormError } from "../ExpressBookCheckoutFormError/ExpressBookCheckoutFormError";
import { isDirectBill } from "../ExpressBookPaymentMethods/ExpressBookPaymentMethods";
import OverlappingContractModal from "pages/Checkout/LegacyLodging/OverlappingContractModal";
import { ampli } from "ampli";
import { getRewardsInfoIfPresent } from "./helpers";
import { EB_DATE_PICKER_TOGGLE_BTN_ID, EB_GUEST_CACHE_SESSION_STORAGE_KEY } from "../../constants";
import { useBreakpoint } from "@hotel-engine/hooks";

const generateExpressBookFormInitialValues = (
  contractRate: IExpressBookContractRate,
  property: IPropertyLegacy,
  user: IUser | null
) => {
  const guestCache = globalThis.sessionStorage.getItem(EB_GUEST_CACHE_SESSION_STORAGE_KEY);
  const parsedGuestCache = guestCache ? JSON.parse(guestCache) : undefined;
  return {
    isFlexEnabled: false,
    includeIncidentalCharges:
      user?.incidentalsConfiguration === "required" && property.allowIncidentals,
    guestList:
      parsedGuestCache || returnGuestList("1", user, contractRate as unknown as IContractRate),
    travelCredits: 0,
    rewardsPoints: 0,
    isFlexProEnrolled: user?.business.flexProEnrolled || false,
  } as IExpressBookCheckoutForm;
};

interface IExpressBookCheckoutFormProps {
  expressBookData: IExpressBookContractDataResponse;
  refreshContract: () => void;
  setPaymentErrors: (errors: string[]) => void;
}

export const ExpressBookCheckoutForm: React.FC<IExpressBookCheckoutFormProps> = ({
  children,
  expressBookData,
  refreshContract,
  setPaymentErrors,
}) => {
  // REDUX BINDINGS FOR FORM DATA
  const user = useAppSelector((state) => state.Auth.user);

  // HOOKS SETUP
  const isUsingMobileDatepicker = useBreakpoint("md", "max");
  const isExpressBookDateChangeEnabled = useIsFeatureFlagOn("express_book_change_dates");
  const formRef = useRef<FormikProps<IExpressBookCheckoutForm>>(null);
  // If open over five minutes and a booking is submitted, show the refreshed contract rate modal
  const [openOverFiveMinutes, setOpenOverFiveMinutes] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [expressBookCheckoutStatus, setExpressBookCheckoutStatus] = useState<
    TBookingModalState | undefined
  >(undefined);
  const [checkoutError, setCheckoutError] = useState<TExpressBookFormErrorTypes | null>(null);
  const {
    advanceToSuccessState,
    setModalStep,
    modalStep,
    setHideExpressBookForStackingModals,
    redirectToProperty,
    expressBookAnalytics,
  } = useExpressBook();
  const allowPreFilledGuests = useIsFeatureFlagOn("pre_fill_guests");
  const allowPreFillAllGuests = useIsFeatureFlagOn("pre_fill_all_guests");

  // REACT QUERY SETUP
  const handleBookRoomSuccess = (
    successResponse: IReservationResponse,
    reservationPayload: IReservationCreatePayload
  ) => {
    setHideExpressBookForStackingModals(false);
    ampli.viewExpressBookBookingSuccessfulModal({
      propertyId: expressBookAnalytics.propertyId,
      searchId: expressBookAnalytics.searchId,
      contractNumber: successResponse.id,
    });

    advanceToSuccessState({
      rewardsInfo: getRewardsInfoIfPresent(expressBookData, reservationPayload.occupants),
      isRefundable: isRoomRefundable(expressBookData.contractRate.cancelBy),
      itineraryNumber: successResponse.id,
    });
  };

  const handleBookRoomError = (
    reservationError: IReservationResponse | ICreateReservationError
  ) => {
    setExpressBookCheckoutStatus(undefined);

    /** Evaluate for Creation Error */
    const isCreateReservationErr = isCreateReservationError(reservationError);

    /** Evaluate for room or contract failure */
    const roomFailure =
      !isCreateReservationErr &&
      reservationError.allowRetryRooms !== undefined &&
      !reservationError.allowRetryRooms;

    const contractFailure =
      !isCreateReservationErr &&
      reservationError.allowRetryBooking !== undefined &&
      !reservationError.allowRetryBooking;

    if (!isIReservationError(reservationError)) {
      // TODO: Create specific error state handler for non-reservationError here
      setCheckoutError("generic_api");
      setModalStep(ExpressBookModalStep.ERROR_STATE);

      // datadogRum.addAction("checkout", {
      //   ...dataDogObj,
      //   reservationError,
      //   errorType: "booking",
      // });
      return;
    }

    /** Evaluate for duplicate reservation */
    if (
      (isCreateReservationErr && reservationError.statusCode === 409) ||
      (Array.isArray(reservationError.errors) &&
        reservationError.errors?.find((e) => e.includes("duplicate")))
    ) {
      setIsDuplicate(true);
      setHideExpressBookForStackingModals(true);

      ampli.viewDuplicateBookingModalOnExpressBook({
        propertyId: expressBookAnalytics.propertyId,
        searchId: expressBookAnalytics.searchId,
        roomRateId: expressBookAnalytics.roomRateId || expressBookData.contractRate?.roomRateId,
      });
      return;
    }

    /** Evaluate for network or server errors */
    if (isCreateReservationErr && reservationError.statusCode === 500) {
      // TODO: Create specific error state handler for network/server errors here
      setCheckoutError("generic_api");
      setModalStep(ExpressBookModalStep.ERROR_STATE);
      return;
    }

    // TODO: Re-add this when we build out analytics/metrics
    /** Send booking failure metric for Datadog analytics
     *  Include total seconds spent from page load to booking failure */
    // let errorType = "booking";

    const creditCardError =
      reservationError.errors && Array.isArray(reservationError.errors)
        ? reservationError?.errors?.some((err) => err.includes("payment"))
        : false;

    if (creditCardError) {
      // errorType = "payment";
      setPaymentErrors(
        (
          (reservationError.errors as string[]) || [
            "There was an error processing your payment card",
          ]
        ).filter((err) => err.toLowerCase().includes("payment"))
      );
      setCheckoutError(null);
      setHideExpressBookForStackingModals(false);
      setModalStep(ExpressBookModalStep.BASE_STATE);
      setExpressBookCheckoutStatus(undefined);
      // ampli.creditCardDeclined(AMPLITUDE_DATA);
    } else {
      setCheckoutError("generic_api");
      setModalStep(ExpressBookModalStep.ERROR_STATE);

      if (roomFailure || contractFailure) {
        // errorType = "room-availability";
      }
    }

    // datadogRum.addAction("checkout", { ...dataDogObj, errorType });

    captureMessage("Booking error (express_book_complete_booking)", {
      error: reservationError,
      contractRate: expressBookData.contractRate,
      // selectedPayment, // TODO: Bind this data correctly
    });
  };

  const { mutate: createContractRate } = useCreateContractRate();
  const { mutate: processReservationActionCable, response: reservationActionCableResponse } =
    useCreateReservationActionCable({
      onSuccess: (reservationResponse, reservationPayload) =>
        handleBookRoomSuccess(reservationResponse, reservationPayload),
      onError: handleBookRoomError,
    });

  // FORM SUBMISSION & CONTRACT CREATION HANDLERS
  const createBooking = (
    data: IExpressBookCheckoutForm,
    rate: IExpressBookContractRate = expressBookData.contractRate
  ) => {
    const bookForUserId = data.guestList[0].primary.user?.businessId
      ? data.guestList[0].primary.user?.id
      : undefined;
    const attributionCookie = storage.getSecureItem("attributionId");
    const affiliateTag = attributionCookie
      ? Buffer.from(attributionCookie, "base64").toString()
      : null;

    const customFields = (data.customFields || [])
      .filter((field) => field.value)
      .map((field) => ({
        customFieldId: field.id,
        value: field.value as string,
      }));

    const occupants: IReservationCreatePayload["occupants"] = data.guestList.map((userRoom) => {
      const primary = userRoom.primary.user as IUser | IOccupant;
      const secondary = userRoom.secondary.user;
      const picked = [
        "id",
        "businessId",
        "departmentName",
        "email",
        "firstName",
        "lastName",
        "phone",
      ];

      const primaryGuestPicks = !!primary.employeeId ? [...picked, "employeeId"] : picked;

      const shouldAddIdAndType = allowPreFilledGuests || allowPreFillAllGuests;
      const users: GuestSelectionGuests = [
        {
          ...pick(primary, primaryGuestPicks),
          ...(expressBookData.roomRate.loyaltyEligible && {
            loyaltyInfo: userRoom.loyaltyInfo,
            loyaltyRewardId: userRoom.loyaltyInfo?.loyaltyRewardId,
            loyaltyRewardProgramNumber: userRoom.loyaltyInfo?.programNumber,
          }),
          ...(shouldAddIdAndType && {
            occupantId: primary.id || null,
          }),
          ...(shouldAddIdAndType && {
            occupantType: primary.occupantType || "guest",
          }),
        } as IOccupant,
      ];

      if (secondary) {
        const secondaryGuestPicks = !!secondary.employeeId ? [...picked, "employeeId"] : picked;
        users.push({
          ...pick(secondary, secondaryGuestPicks),
          ...(shouldAddIdAndType && {
            occupantId: secondary.id || null,
          }),
          ...(shouldAddIdAndType && {
            occupantType: secondary.occupantType || "guest",
          }),
        } as IOccupant);
      }
      return users;
    });

    processReservationActionCable({
      bookForUserId: bookForUserId,
      allowIncidentals: data.includeIncidentalCharges,
      affiliateTag: affiliateTag,
      contractRateUuid: expressBookData.contractRate?.id,
      contractCustomFieldsAttributes: customFields,
      directBillCode: data.directBillCode || undefined,
      duplicateOverride: Boolean(data.duplicateOverride),
      isBillable: Boolean(data.isBillable),
      occupants,
      paymentTypes: formatPaymentTypes(data, rate),
      userAgent: navigator.userAgent,
      flexType: data.isFlexEnabled ? "cancellation" : "unassigned",
      flexEnabled: data.isFlexEnabled,
    });
  };

  const submitBookingHandler = (data: IExpressBookCheckoutForm) => {
    if (!expressBookData.paymentProfiles) {
      return;
    }

    const selectedPaymentMethod = [...expressBookData.paymentProfiles, directBill].find(
      (payment) => payment.id === data.selectedPaymentId
    );

    if (!isDirectBill(selectedPaymentMethod) && selectedPaymentMethod?.isExpired) {
      return;
    }

    setHideExpressBookForStackingModals(true);
    setPaymentErrors([]); // Reset any current payment errors

    if (!openOverFiveMinutes) {
      setExpressBookCheckoutStatus("loading");
      setCheckoutError(null);
      createBooking(data);
      return;
    }

    setExpressBookCheckoutStatus("loading");
    setCheckoutError(null);
    createContractRate(
      {
        roomRateId: expressBookData.contractRate?.roomRateId,
      },
      {
        onSuccess(refreshedRate) {
          createBooking(data, refreshedRate as unknown as IExpressBookContractRate);
        },
        onError(error) {
          // @ts-expect-error error response type doesn't consider response.data error structure
          switch (error.response.status) {
            case 409: {
              // RATE INCREASE ERROR
              setCheckoutError("bad_rate");
              break;
            }
            default: {
              // UNHANDLED RATE ERROR
              setCheckoutError("generic_api");
              break;
            }
          }
          setExpressBookCheckoutStatus(undefined);
          setModalStep(ExpressBookModalStep.ERROR_STATE);
        },
      }
    );
  };

  useEffect(() => {
    if (expressBookData.contractRate.overlappingContracts.length) {
      setModalStep(ExpressBookModalStep.OVERLAPPING_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Manages booking loader status specifically for the booking request. Prevents a display gap between the booking request and rooms request
    if (
      !!expressBookCheckoutStatus &&
      modalStep !== ExpressBookModalStep.ERROR_STATE &&
      (reservationActionCableResponse?.status === "failed" || !reservationActionCableResponse)
    ) {
      // Do Nothing
    } else {
      setExpressBookCheckoutStatus(reservationActionCableResponse?.status as TBookingModalState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationActionCableResponse]);

  // a timer that's utilized in checkout to determine if user has been on page for over 5 minutes
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenOverFiveMinutes(true);
    }, refreshContractRateTimeout);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Formik
        initialValues={generateExpressBookFormInitialValues(
          expressBookData.contractRate,
          expressBookData.property,
          user
        )}
        validationSchema={() => checkoutValidate(1)}
        validateOnChange={false}
        onSubmit={submitBookingHandler}
        innerRef={formRef}
      >
        {modalStep === ExpressBookModalStep.ERROR_STATE && checkoutError !== null ? (
          <ExpressBookCheckoutFormError
            refreshContract={refreshContract}
            errorType={checkoutError}
          />
        ) : (
          children
        )}
      </Formik>
      {modalStep === ExpressBookModalStep.OVERLAPPING_STATE && (
        <OverlappingContractModal
          contracts={expressBookData.contractRate.overlappingContracts}
          onChangeDates={() => {
            if (isExpressBookDateChangeEnabled) {
              setModalStep(ExpressBookModalStep.BASE_STATE);
              setTimeout(() => {
                if (isUsingMobileDatepicker) {
                  document.querySelector<HTMLElement>(`#${EB_DATE_PICKER_TOGGLE_BTN_ID}`)?.click();
                } else {
                  document
                    .querySelector<HTMLElement>(`#${EB_DATE_PICKER_TOGGLE_BTN_ID}`)
                    ?.parentElement?.click();
                }
              }, 10);
            } else {
              redirectToProperty(true);
            }
          }}
          onChangeGuest={() => {
            setModalStep(ExpressBookModalStep.BASE_STATE);
          }}
        />
      )}
      {!!isDuplicate && (
        <Modal
          cancelText="No, Cancel"
          closable={false}
          maskClosable={false}
          okText="Yes"
          onCancel={() => {
            setExpressBookCheckoutStatus(undefined);
            setHideExpressBookForStackingModals(false);
            setIsDuplicate(false);
          }}
          onOk={() => {
            setHideExpressBookForStackingModals(false);
            setIsDuplicate(false);
            formRef.current?.setFieldValue("duplicateOverride", true);
            formRef.current?.handleSubmit();
          }}
          title="Potential Duplicate Booking"
          visible={isDuplicate}
        >
          <p data-testid="duplicate-booking-message">
            We have detected that this may be a duplicate booking attempt. Are you sure you want to
            continue making this reservation?
          </p>
        </Modal>
      )}
      {!!expressBookCheckoutStatus && expressBookCheckoutStatus !== "failed" && !isDuplicate && (
        <BookingModal
          loadingStatus={expressBookCheckoutStatus}
          propertyName={expressBookData.property.name}
        />
      )}
    </>
  );
};
