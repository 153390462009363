import Api from "./Api";

export default class ReadOnlyResource<T> {
  public static _basePath: string;

  public static async query(params: { [k: string]: unknown } | null = null) {
    const response = await Api.get(this._basePath, params);

    return {
      ...response,
      results: response.results.map((i) => this._makeInstance(i)),
    };
  }

  public static async get(id: number | string) {
    const url = `${this._basePath}/${id}`;
    const response = await Api.get(url);
    return this._makeInstance(response);
  }

  public static _makeInstance(params) {
    return new this(params);
  }

  constructor(params?: T) {
    Object.assign(this, params);
  }
}
