import * as Styled from "./styles";

/**
 * The `Input`, `Search`, and `TextArea` components wraps antd's `<Input />`, `<Input.Search />`, and `<Input.TextArea />` component.
 *
 * @remarks Since we are leveraging antd for most components, our internal Input component does not have any of it's own props. Instead, we are directly referring to the interface definition antd is expecting. This allows us to easily be in alignment with their type definitions and prevents type errors that may arise from maintaining an override interface.
 *
 * @see {@link https://3x.ant.design/components/input/ AntD Input Documentation}
 * @see {@link https://3x.ant.design/components/input/#Input.Search AntD Search Input Documentation}
 * @see {@link https://3x.ant.design/components/input/#Input.TextArea AntD Text Area Input Documentation}
 *
 */
export const Input = Styled.AntInput;

export const Search = Styled.AntInputSearch;

export const TextArea = Styled.AntInputTextArea;

export type { Input as TInput } from "antd";

export type { InputProps } from "antd/lib/input";
