import { Switch } from "antd";
import type { SwitchProps } from "antd/lib/switch";
import styled, { css } from "styled-components";

import { colors } from "@hotel-engine/styles";

export const AntSwitch = styled(Switch)<SwitchProps>`
  ${({ checked }) =>
    checked &&
    css`
      background-color: ${colors.retroBlue};
    `}
`;
