import styled from "styled-components";
import { Icon as HEIcon } from "@hotel-engine/common/Icon";

export const DismissIcon = styled.div`
  min-width: 60px;
  min-height: 60px;
  background-color: #CECDD5;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Prompt = styled.div`
  align-items: center;
  display: flex;
  height: 60px;
  position: fixed;
  // matching iOS banner...
  background-color: #E3E1EA;
  width: 100%;
  z-index: 301 /* disable theming (do not replicate) */;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
  border-radius: 15px /* disable theming (do not replicate) */;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  height: 100%;
  max-height: 100%;
`;

export const Icon = styled(HEIcon)`
  height: 24px;
  width: 26px;
  margin: 0 10px;
`;

export const HEImage = styled.img`
  border-radius: 10px /* disable theming (do not replicate) */;
  height: 40px;
`;

export const StoreText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

export const InfoRight = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin-left: 15px;
`;

export const ImageContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;
