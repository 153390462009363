import { google, outlook } from "calendar-link";
import moment from "moment";
import type { ICurrency } from "@hotel-engine/constants";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import { captureMessage } from "@hotel-engine/utilities/logger";
import { formatPropertyLocation } from "@hotel-engine/utilities/formatters/formatPropertyInformation";
import { isMobileOrTablet } from "@hotel-engine/utilities/helpers/phoneHelpers";
import config from "config";

const CHECK_IN_TIME = "16:00";
const CHECK_OUT_TIME = "11:00";

type CalendarPlatform = "google" | "outlook";

const isValidUrl = (href: string) => {
  return new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ).test(href);
};

//these are some of the commons parameter we will find in a generated link for a google/outlook calendar event
const googleLinkParams = ["action", "dates", "details", "location", "text"];
const outlookLinkParams = ["body", "startdt", "enddt", "subject", "path"];

/** Check all the values in the params array are present in the url search params */
const urlHasParams = (url: URL, params: string[]) =>
  params.every((param) => url.searchParams.has(param));

/**
 * Validate the link returned by calendar-link.
 * Checks is a valid url.
 * Checks it contains the expected parameters.
 * @param url
 * @param platform
 * @returns
 */
const isValidCalendarEventLink = (url: string, platform: CalendarPlatform) => {
  try {
    const urlObject = new URL(url);
    const hasTheParams =
      platform === "google"
        ? urlHasParams(urlObject, googleLinkParams)
        : urlHasParams(urlObject, outlookLinkParams);
    return isValidUrl(url) && hasTheParams;
  } catch (error) {
    return false;
  }
};

/**
 *
 * Returns a formatted address depending on the location being in or outside the US.
 * If outside US will return city, country instead of the typical city, state
 *
 * @param reservation the reservation to get the location from
 * @returns a string formatted as {street}, {city, state} | {city, country}, {zip code}
 */
const getLocation = (reservation: IReservationBase) => {
  const { propertyStreet, propertyCity, propertyState, propertyCountry } = reservation;
  const propertyLocation = formatPropertyLocation({
    propertyCity,
    propertyCountry,
    propertyState,
  });
  return `${propertyStreet}, ${propertyLocation}, ${reservation.propertyPostalCode}`;
};

/**
 * Build a string to be used in the calendar event as description.
 * Throws an exception if globalThis.location.href is not a valid url.
 * @param reservation
 * @param address
 * @param currencyCode
 * @param platform
 * @returns
 */
const getReservationDescription = (
  reservation: IReservationBase,
  address: string,
  currencyCode: ICurrency,
  platform: CalendarPlatform,
  companyName: "Hotel Engine" | "Engine"
) => {
  const appUrl = globalThis.location.href;
  /**
   * in local https mode, especially on safari
   * <a> with the generated google/calendar link is not working
   * so better rule out {@link https://localhost}
   */
  const isLocalhost =
    appUrl.startsWith("http://localhost:3000/") ||
    appUrl.startsWith("http://localhost/") ||
    appUrl.startsWith("http://127.0.0.3/") ||
    appUrl.startsWith("https://local-member.he-staging.com:8443");
  const validURL = isValidUrl(appUrl);

  /** check also for localhost to be able to use it locally and to test it without mock the window.location */
  if (!validURL && !isLocalhost) {
    const error = new Error(
      `Value of window.location.href is invalid. window.location.href = ${appUrl}`
    );
    captureMessage("There was an error generating a calendar event link", {
      error,
    });
    throw error;
  }

  const confirmation = reservation.propertyConfirmationNumber
    ? `Property Confirmation Number: ${reservation.propertyConfirmationNumber}`
    : `${companyName} Number ${reservation.id}`;

  const description =
    platform === "google"
      ? `${confirmation}
Hotel Address: ${address}
${companyName} Support: ${config.supportNumber(currencyCode)}

View Trip in App: <a target="_blank" href="${appUrl}">${appUrl}</a>`
      : `${confirmation}</br>Hotel Address: ${address}</br>${companyName} Support: ${config.supportNumber(
          currencyCode
        )}</br>View Trip in App: ${appUrl}`;

  return description;
};

/**
 * Generate a datetime from a date and a datetime
 * Will throw an error if datetime is not valid.
 * @param date
 * @param time
 * @returns a datetime as string
 */
const getDateTime = (date: string, time: string | null) => {
  const formattedTime = !!time ? moment(time).utc().format("h:mm A") : "";
  const dateAsMoment = moment(date + " " + formattedTime, "YYYY-MM-DD h:mm A");
  if (!dateAsMoment.isValid()) {
    const error = new Error("Error formatting date to create a calendar event.");
    captureMessage(`There was an error generating a calendar event link.`, {
      error,
    });
    throw error;
  }
  return dateAsMoment.format();
};

/**
 *
 * @param reservation the reservation to set in the calendar
 * @param currencyCode used to retrieve the support number
 * @param platform will generate the link for this given calendar (google or outlook)
 * @param allDayParam indicates if the event should be marked as All Day
 * @returns the generated link to save the event in the calendar
 *  or an empty string if an exception was thrown in the process
 */
export const getCalendarEventLink = (
  reservation: IReservationBase,
  currencyCode: ICurrency,
  platform: CalendarPlatform,
  companyName: "Hotel Engine" | "Engine",
  allDayParam?: boolean
): string | null => {
  try {
    const location = getLocation(reservation);

    const description = getReservationDescription(
      reservation,
      location,
      currencyCode,
      platform,
      companyName
    );

    /**
     * flag the event as allDay if
     * - checkInTime or checkOutTime are missing OR
     * - outlook on mobile/table, because of a bug from outlook mobile which
     *   doesn't get the correct time of the event
     */
    const allDay =
      allDayParam ||
      !reservation.checkInTime ||
      !reservation.checkOutTime ||
      (platform === "outlook" && isMobileOrTablet());

    const startDateTime = getDateTime(reservation.checkIn, reservation.checkInTime);

    /** if allDay is true then add 1 day to checkout */
    const checkoutDate = allDay
      ? moment(reservation.checkOut).add(1, "d").format("YYYY-MM-DD")
      : reservation.checkOut;

    const endDateTime = getDateTime(checkoutDate, reservation.checkOutTime);

    const event = {
      title: `Reservation at ${reservation.propertyName}`,
      location,
      start: startDateTime,
      end: endDateTime,
      description,
      allDay,
    };

    const link = platform === "google" ? google(event) : outlook(event);

    if (!isValidCalendarEventLink(link, platform)) {
      throw new Error(`Link: ${link} is not valid.`);
    }
    return link;
  } catch (error) {
    captureMessage("There was an error generating a calendar event link. Link", { error });
    console.error("There was an error generating a calendar event link. Link", {
      error,
    });
    return null;
  }
};

const formatCalendarEventDate = (
  date: string,
  time: typeof CHECK_IN_TIME | typeof CHECK_OUT_TIME
) => moment(date + " " + time).format();

export const getIscCalendarEvent = (
  reservation: IReservationBase,
  currencyCode: ICurrency,
  companyName: "Hotel Engine" | "Engine"
) => {
  const address = `${reservation.propertyStreet}\\, ${reservation.propertyCity}\\, ${reservation.propertyState}\\, ${reservation.propertyPostalCode}`;
  const confirmation = reservation.propertyConfirmationNumber
    ? `Property Confirmation Number: ${reservation.propertyConfirmationNumber}`
    : `${companyName} Number ${reservation.id}`;

  return {
    title: `${companyName} Reservation at ${reservation.propertyName}`,
    description: `${confirmation}\\nHotel Address: ${address}\\n${companyName} Support: ${config.supportNumber(
      currencyCode
    )}\\n\\nView Trip in App: ${globalThis.location.href}`,
    startTime: formatCalendarEventDate(reservation.checkIn, CHECK_IN_TIME),
    endTime: formatCalendarEventDate(reservation.checkOut, CHECK_OUT_TIME),
    location: address,
  };
};
