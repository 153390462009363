import type { ReactNode } from "react";

import { colors } from "../../styles";
import { getInitials } from "./helpers";
import * as Styled from "./styles";

export interface IAvatar {
  /** border applied to css border property */
  border?: string;
  /** styling */
  className?: string;
  /** color applied to background-color css property */
  backgroundColor?: string;
  /** color applied to css color property */
  fontColor?: string;
  /** icon to display instead of a name */
  icon?: ReactNode;
  /** User's full name (default: 'N/A') */
  name?: string;
  /** overrides name, allowing user to input whatever text they want to display */
  nameOverride?: string;
  /** <img> src link for profile picture  */
  src?: string;
  /** 'xlarge' | 'large' | 'med' | 'small' (default: 'med') */
  size?: "xlarge" | "large" | "med" | "small" | "xsmall";
  /** Number specifying more avatars. When multiple values exist the styles default to the "small" size */
  multiple?: number | string;
  /** Boolean to only include the first initial */
  useSingleInitial?: boolean;
}

/**
 * The `Avatar` component is provided to display a user avatar. It is either a profile image or a colored circle with the user's initials.
 * Colors are pseudo-randomly generated by user's name and will stay the same each time the component is rendered and color is generated.
 *
 * @see {@link https://www.figma.com/file/GVLYN60OBX188CID3YvWpSo6/Components---WEB?node-id=3280%3A574 Design Specs}
 */
const Avatar = ({
  border = "",
  backgroundColor,
  fontColor = "",
  icon,
  name = "N/A",
  nameOverride,
  src,
  size = "med",
  useSingleInitial = false,
  multiple,
  className,
  ...rootProps
}: IAvatar) => {
  const renderInnerContent = () => {
    if (!!icon) {
      return icon;
    } else if (src) {
      return <img src={src} alt={getInitials(name, useSingleInitial)} />;
    } else if (nameOverride) {
      return nameOverride;
    } else {
      return getInitials(name, useSingleInitial);
    }
  };

  return (
    <Styled.Avatar
      border={border}
      fontColor={fontColor}
      backgroundColor={backgroundColor || colors.black}
      multiple={multiple}
      size={size}
      className={className}
      {...rootProps}
    >
      <div className="avatar">
        <span>{renderInnerContent()}</span>
      </div>
      {!!multiple && <div className="number">{`+${multiple}`}</div>}
    </Styled.Avatar>
  );
};

export default Avatar;
