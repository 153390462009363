import { useFormikContext } from "formik";
import { useAppSelector } from "store/hooks";

import Button from "@hotel-engine/common/Button";
import { useExpressBook } from "pages/Checkout/LegacyLodging/ExpressBookModal/hooks/useExpressBook";
import type {
  IExpressBookCheckoutForm,
  IExpressBookContractDataResponse,
} from "@hotel-engine/types/expressBook";

import { generatePricingCalculation } from "../ExpressBookPricingDetails/helpers";

import * as Styled from "./styles";
import { ampli } from "ampli";
import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";

interface IExpressBookBaseStateFooterProps {
  expressBookData: IExpressBookContractDataResponse;
}
export const ExpressBookBaseStateFooter = ({
  expressBookData,
}: IExpressBookBaseStateFooterProps) => {
  const { COMPANY_URL } = useCompanyName();

  const { closeExpressBookModal, expressBookAnalytics } = useExpressBook();
  const user = useAppSelector((state) => state.Auth.user);
  const currencyCode = user?.business?.currencyCode || "USD";
  const form = useFormikContext<IExpressBookCheckoutForm>();
  const formattedTotal = generatePricingCalculation(
    currencyCode,
    form.values,
    expressBookData.contractRate,
    user
  );

  const travelerHasOverlappingContracts = (form.values.guestList || []).some(
    (room) => room.overlappingContracts?.length
  );

  return (
    <Styled.ExpressBookBaseStateFooter>
      <Styled.ExpressBookFooterBaseStateButtonContainer>
        <Button
          type="default"
          data-testid="express-book-cancel-btn"
          onClick={() => closeExpressBookModal("cancel_btn")}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          data-testid="book-now-btn"
          onClick={() => {
            ampli.clickBookNowOnExpressBookModal({
              ...expressBookAnalytics,
            });
            form.handleSubmit();
          }}
          disabled={travelerHasOverlappingContracts}
        >
          Book now for <strong> {formattedTotal}</strong>
        </Button>
      </Styled.ExpressBookFooterBaseStateButtonContainer>
      <p>
        By completing this booking I acknowledge I have read and accept the{" "}
        <a
          onClick={() => {
            ampli.clickTermsConditionsOnExpressBookModal();
          }}
          href={`https://www.${COMPANY_URL}/terms-of-service/`}
          target="_blank"
          rel="noreferrer noopener"
        >
          terms &amp; conditions
        </a>
        ,{" "}
        <a
          onClick={() => {
            ampli.clickPrivacyPolicyOnExpressBookModal();
          }}
          href={`https://www.${COMPANY_URL}/privacy-policy/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          privacy policy
        </a>
        , and property cancellation policy.
      </p>
    </Styled.ExpressBookBaseStateFooter>
  );
};
