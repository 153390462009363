import { Dropdown as AntdDropdown } from "antd";

/**
 * The `Dropdown` component wraps antd's `<Dropdown />` component.
 *
 * @remarks Since we are leveraging antd for most components, our internal Dropdown component does not have any of it's own props. Instead, we are directly referring to the interface definition antd is expecting. This allows us to easily be in alignment with their type definitions and prevents type errors that may arise from maintaining an override interface.
 *
 * @see {@link https://3x.ant.design/components/dropdown/ AntD Dropdown Documentation}
 */
export const Dropdown = AntdDropdown;

export type { Dropdown as TDropdown } from "antd";

export type { DropDownProps } from "antd/lib/dropdown";
