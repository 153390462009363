import type { UseQueryOptions } from "react-query";

import { endpoints } from "@hotel-engine/react-query/constants";
import { convertToSnake, useApi } from "@hotel-engine/react-query/useApi";
import { useExtendedQuery } from "@hotel-engine/react-query/useExtendedQuery";
import type { IError } from "@hotel-engine/types/errors";
import type { IGuestQueryParams, IGuestQueryResponse } from "@hotel-engine/types/guest";

import useDebouncedQuery from "../useDebouncedQuery";

export const useGuestQuery = (
  params: IGuestQueryParams = {},
  options?: UseQueryOptions<IGuestQueryResponse, IError>
) => {
  const get = useApi("get");

  return useExtendedQuery(
    [endpoints.guests, params],
    () => get<IGuestQueryResponse>(endpoints.guests, convertToSnake(params)),
    options
  );
};

export interface IDebouncedGuestQueryParams extends Omit<IGuestQueryParams, "search"> {
  search: string;
}

export const useDebouncedGuestQuery = (
  params: IDebouncedGuestQueryParams,
  options?: UseQueryOptions<IGuestQueryResponse, IError>
) => {
  const get = useApi("get");

  return useDebouncedQuery(
    [endpoints.guests, params],
    () => get<IGuestQueryResponse>(endpoints.guests, convertToSnake(params)),
    {
      enabled: params.search.length > 0,
      ...options,
    }
  );
};
