import type { ReactNode, UIEvent } from "react";
import { useCallback } from "react";

import * as Styled from "./styles";
import type { ListOnScrollProps } from "react-window";

export interface ICustomScrollbarProps {
  children: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  forwardedRef?: any;
  /** Fired when the component scrolls. This function is not throttled or debounced */
  onScroll?: (e: UIEvent<HTMLDivElement> | ListOnScrollProps) => void;
  /** Subtracts from the height (100%) of the component */
  offsetHeight?: string;
  style?: React.CSSProperties;
}

/**
 * The `CustomScrollbar` component is a scrollbar container wrapping react-custom-scrollbars.
 * Use it to override default browser/OS scrollbar UI.
 *
 * @see {@link https://github.com/malte-wessel/react-custom-scrollbars react-custom-scrollbars Documentation}
 */
const CustomScrollbar = ({
  children,
  forwardedRef,
  onScroll,
  offsetHeight,
  style,
}: ICustomScrollbarProps) => {
  const refSetter = useCallback((scrollbarsRef) => {
    if (forwardedRef && scrollbarsRef) {
      forwardedRef(scrollbarsRef.view);
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Styled.Scrollbar
      autoHide
      ref={refSetter || undefined}
      onScroll={onScroll}
      renderThumbVertical={(props) => <Styled.Thumb {...props} />}
      // this is overriding the style on the scrollbar, styled component class
      // can't be used because it gets overwritten
      style={{
        height: offsetHeight ? `calc(100% - ${offsetHeight})` : undefined,
        ...style,
      }}
    >
      {children}
    </Styled.Scrollbar>
  );
};

export default CustomScrollbar;
