import styled from "styled-components";

import TextAreaField from "@hotel-engine/common/FormikFields/TextAreaField";
import { colors } from "@hotel-engine/styles";

export const ReviewTextArea = styled(TextAreaField)`
  width: 100%;
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xl};

  &.ant-input {
    height: 108px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  & .ant-form-item-label {
    padding: 0px;
  }
`;

export const CharacterCount = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px;
  color: ${colors.grey[400]};
  font-size: 12px;
`;

export const Divide = styled.span`
  margin-left: 0px;
  margin-right: 1px;
`;
