import { useNavigate } from "@hotel-engine/lib/react-router-dom";

import { routes } from "@hotel-engine/constants";

import * as SharedStyled from "../styles";

interface INotificationAdminActions {
  hasMessages: boolean;
  markAllAsRead: () => void;
}

const NotificationAdminActions = ({ hasMessages, markAllAsRead }: INotificationAdminActions) => {
  const navigate = useNavigate();

  const viewNotifications = () => {
    navigate(routes.userNotifications);
  };

  const handleMarkAllAsRead = () => {
    markAllAsRead();
  };

  return (
    <SharedStyled.NotificationActions>
      {!!hasMessages && (
        <SharedStyled.NotificationAction onClick={handleMarkAllAsRead} leadingIcon="check--solid">
          Mark all as read
        </SharedStyled.NotificationAction>
      )}

      <SharedStyled.NotificationAction onClick={viewNotifications} leadingIcon="gear">
        View notification settings
      </SharedStyled.NotificationAction>
    </SharedStyled.NotificationActions>
  );
};

export default NotificationAdminActions;
