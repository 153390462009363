import { useCallback, useEffect, useReducer } from "react";
import { z } from "zod";

const BeforeInstallPromptEvent = z.instanceof(globalThis.Event).and(
  z.object({
    platforms: z.array(z.string()),
    userChoice: z.promise(
      z.object({
        outcome: z.enum(["accepted", "dismissed"]),
        platform: z.string(),
      })
    ),
    prompt: z.function(z.tuple([]), z.promise(z.void())),
  })
);

type BeforeInstallPromptEvent = z.infer<typeof BeforeInstallPromptEvent>;

const isBeforeInstallPromptEvent = (u: unknown): u is BeforeInstallPromptEvent =>
  BeforeInstallPromptEvent.safeParse(u).success;

interface IHookReturn {
  prompt: globalThis.Event | null;
  promptToInstall: () => void;
  nullifyPrompt: () => void;
}

const isAndroid = (window_: typeof globalThis = globalThis) =>
  /(android)/i.test(window_.navigator.userAgent);

export function usePromptToInstall(): IHookReturn {
  const [prompt, setPrompt] = useReducer(
    (_prev: globalThis.Event | null, next: globalThis.Event | null) => next,
    null
  );

  const promptToInstall = () => {
    if (isBeforeInstallPromptEvent(prompt)) {
      return prompt.prompt();
    }
    return Promise.reject(
      new Error('Tried installing before browser sent "beforeinstallprompt" event')
    );
  };

  const nullifyPrompt = useCallback(() => {
    setPrompt(null);
  }, [setPrompt]);

  useEffect(() => {
    const ready = (e: globalThis.Event) => {
      e.preventDefault();
      setPrompt(e);
    };

    // chrome will fire off the beforeinstallprompt on desktop due to i _think_ the ability
    // to download "Chrome Apps"-- only happening in localhost
    if (isAndroid()) {
      globalThis.addEventListener("beforeinstallprompt", ready);
    }

    return () => {
      if (isAndroid()) {
        globalThis.removeEventListener("beforeinstallprompt", ready);
      }
    };
  }, []);

  return { prompt, promptToInstall, nullifyPrompt };
}
