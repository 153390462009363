import { getBookingType } from ".";
import { routes } from "@hotel-engine/constants";
import type { NavigateFunction } from "@hotel-engine/lib/react-router-dom";

export const navigateToItineraryPage = (bookingId: string, navigate: NavigateFunction) => {
  const bookingType = getBookingType(bookingId);
  const params: Record<string, string> = {
    contract_number: bookingId,
  };

  // Include `type` only if the bookingType is "flight" or "car"
  if (["flight", "car"].includes(bookingType)) {
    params.type = bookingType;
  }

  const queryParams = new URLSearchParams(params);

  navigate({
    pathname: routes.itinerary,
    search: queryParams.toString(),
  });
};
