import { Typography } from "@hotelengine/atlas-web";

import { CopyWrapper } from "./styles";
import {
  CurrentTierColor,
  formatPoints,
  type NormalizedRewards,
} from "@hotel-engine/app/Header/helpers";

interface IDropdownTargetInfoProps {
  rewardsProfile: NormalizedRewards.Enabled;
}

export const DropdownTargetInfo = ({ rewardsProfile }: IDropdownTargetInfoProps) => (
  <CopyWrapper>
    <Typography variant="heading/xs" color={CurrentTierColor[rewardsProfile.tier]}>
      {rewardsProfile.currentTier.name}
    </Typography>
    <Typography variant="body/xs" color="foregroundTertiary">
      {formatPoints(rewardsProfile.points)} pts
    </Typography>
  </CopyWrapper>
);
