import type { UseMutationOptions } from "react-query";
import { useMutation } from "react-query";

import type { IAxiosBatchError } from "@hotel-engine/types/errors";
import type { IUser } from "@hotel-engine/types/user";

import { endpoints } from "../constants";
import { useApi } from "../useApi";

export interface IPersonalAccountUserMutationParams {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

export const usePersonalAccountUserMutation = (
  options?: UseMutationOptions<IUser, IAxiosBatchError, IPersonalAccountUserMutationParams>
) => {
  const post = useApi("post");
  return useMutation<IUser, IAxiosBatchError, IPersonalAccountUserMutationParams>(
    (data) =>
      post(`${endpoints.personalUsers}`, {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        password: data.password,
        passwordConfirmation: data.passwordConfirmation,
      }),
    options
  );
};
