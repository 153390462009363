import { useState } from "react";
import { useExperiment } from "@hotel-engine/react-query/amplitude/useExperimentQuery";

import { useAmplitudeContext } from "@hotel-engine/hooks";
import storage from "@hotel-engine/storage";

import { CookieBanner } from "./Cookies";
import {
  useDatadog,
  useInitGoogleAnalytics,
  useInitSalesforceChat,
  useInitSentry,
  useScript,
  useInitMIAWSalesforceChat,
} from "./hooks";

import { useLogrocketInit } from "./hooks/useLogrocketInit";

/**
 * This includes all of the hooks that handle third party scripts.
 */
function ThirdPartyScripts() {
  const MIAWRollout = useExperiment("sf-miaw-rollout", { value: "off" });
  const sfMIAWFeatureFlagEnabled = MIAWRollout.data.value === "on";
  useInitSalesforceChat(MIAWRollout.isFetched && !sfMIAWFeatureFlagEnabled);
  useInitMIAWSalesforceChat(sfMIAWFeatureFlagEnabled);

  useDatadog();
  useInitGoogleAnalytics();
  useInitSentry();
  useLogrocketInit();
  /**
   * Load any scripts that do not require initialization
   */
  return null;
}

/**
 * This sets providers/context/cookies - all of which need to be done before scripts get loaded.
 */
export default function LegacyScriptLoader() {
  const { initialized, toggleTracking } = useAmplitudeContext().amplitudeSdk;

  const [isBannerVisible, setIsBannerVisible] = useState(
    () => !storage.getSecureItem("cookiePerms")
  );

  // fonts
  useScript("https://kit.fontawesome.com/8fbb5c99da.js");
  // spreedly
  useScript("https://core.spreedly.com/iframe/iframe-v1.min.js?hecachebuster=12345");

  if (!initialized) {
    return null;
  }

  return (
    <>
      {isBannerVisible ? (
        <CookieBanner
          setAmplitudeCookieAccepted={toggleTracking}
          onClose={() => setIsBannerVisible(false)}
        />
      ) : (
        <ThirdPartyScripts />
      )}
    </>
  );
}
