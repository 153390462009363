import type { AuthValidatorFn } from "../helpers";

/** Blocks route access if required search query params are missing */
export const hasRequiredSearchQueryParams: AuthValidatorFn = () => {
  const urlParams = new URLSearchParams(globalThis.location.search);
  const queryParams = Object.fromEntries(new URLSearchParams(urlParams));

  // Recent searches don't have locationId, so we can omit this from being required
  const isMissingRequiredSearchParams =
    !queryParams.checkIn ||
    !queryParams.checkOut ||
    !queryParams.guestCount ||
    !queryParams.lat ||
    !queryParams.location ||
    !queryParams.lon ||
    !queryParams.retrySearchWithExpandedRadius ||
    !queryParams.roomCount;

  if (isMissingRequiredSearchParams) {
    return false;
  }

  return true;
};
