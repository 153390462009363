import { formatPropertyLocation } from "@hotel-engine/utilities";
import { ExpressBookRewardsRefundable } from "../ExpressBookRewardsRefundable/ExpressBookRewardsRefundable";
import * as Styled from "./styles";
import type {
  IExpressBookContractDataResponse,
  IExpressBookPropertyRef,
} from "@hotel-engine/types/expressBook";
import { isRoomRefundable } from "../../helpers";

interface IExpressBookHotelPreviewCard {
  resultPropertyRef: IExpressBookPropertyRef;
  expressBookContractData?: IExpressBookContractDataResponse;
}

export const ExpressBookHotelPreviewCard = ({
  resultPropertyRef,
  expressBookContractData,
}: IExpressBookHotelPreviewCard) => {
  const propertyInfo = {
    propertyName: resultPropertyRef?.name || expressBookContractData?.property.name,
    propertyStreet: resultPropertyRef?.street || expressBookContractData?.property.address,
    propertyCity: resultPropertyRef?.city || expressBookContractData?.property.city,
    propertyState: resultPropertyRef?.state || expressBookContractData?.property.state,
    propertyCountry: resultPropertyRef?.country || expressBookContractData?.property.country,
    propertyRewards: expressBookContractData?.contractRate.loyaltyEligible
      ? expressBookContractData.property.loyaltyRewardName
      : null,
    isRefundable: isRoomRefundable(expressBookContractData?.contractRate.cancelBy),
  };

  return (
    <Styled.ExpressBookHotelPreview>
      <div
        aria-hidden
        className="express-hotel-preview-img"
        style={{
          backgroundImage: `url('${resultPropertyRef.thumbnailUrl}')`,
          backgroundSize: "cover",
        }}
      />

      <div>
        <p>
          <Styled.ExpressBookHotelTitle>{propertyInfo.propertyName}</Styled.ExpressBookHotelTitle>
          <br />
          <Styled.ExpressBookHotelPreviewAddress>
            {propertyInfo.propertyStreet ? (
              <span>{propertyInfo.propertyStreet}</span>
            ) : (
              <Styled.ExpressBookHotelPreviewLoader />
            )}
            <span>
              {formatPropertyLocation({
                propertyCity: propertyInfo.propertyCity,
                propertyState: propertyInfo.propertyState,
                propertyCountry: propertyInfo.propertyCountry,
              })}
            </span>
          </Styled.ExpressBookHotelPreviewAddress>
        </p>
        {!!expressBookContractData ? (
          <ExpressBookRewardsRefundable
            loyaltyRewardName={propertyInfo.propertyRewards}
            isCancellable={!!propertyInfo.isRefundable}
          />
        ) : (
          <Styled.ExpressBookHotelPreviewLoader />
        )}
      </div>
    </Styled.ExpressBookHotelPreview>
  );
};
