import { useState, useCallback, useEffect } from "react";

import { Box, BottomSheet, Avatar } from "@hotelengine/atlas-web";
import { DropdownTargetInfo } from "@hotel-engine/app/Header/components/redesignComponents/Rewards/DropdownTargetInfo";
import { useUser } from "@hotel-engine/hooks";
import { isUserEligibleToViewAndUseRewards } from "@hotel-engine/utilities";
import { Points, UserRewards } from "./";
import { HeaderFooter } from "./HeaderFooter";
import { normalizeRewardsProfile, getAdditionalInfoCopy } from "../../../helpers";

import type { IUserDropdownProps } from "../types";

type MobileUserBottomSheetProps = IUserDropdownProps;

import {
  BottomSheetTrigger,
  BottomSheetHeader,
  BottomSheetContent,
  StyledIcon,
  UserHeader,
  SecondaryInfo,
  BottomSheetClose,
} from "./styles";

export const MobileUserBottomSheet = ({
  onToggleBizPersonalAccount,
  onPersonalAccountCreation,
  ...rest
}: MobileUserBottomSheetProps) => {
  const [userBottomSheetOpen, setUserBottomSheetOpen] = useState(false);
  const user = useUser();
  const rewardsProfile = normalizeRewardsProfile(user.rewardsProfile);
  const shouldShowRewards = isUserEligibleToViewAndUseRewards(user);
  const additionalInfoCopy = getAdditionalInfoCopy(
    shouldShowRewards,
    rewardsProfile,
    user.confirmedAt
  );

  const handleMobileAccountToggle = useCallback(() => {
    setUserBottomSheetOpen(false);
    onToggleBizPersonalAccount();
  }, [onToggleBizPersonalAccount]);

  const handleMobilePersonalAccountCreation = useCallback(() => {
    setUserBottomSheetOpen(false);
    onPersonalAccountCreation();
  }, [onPersonalAccountCreation]);

  const openBottomSheet = useCallback(() => {
    setUserBottomSheetOpen(true);
  }, []);

  const closeBottomSheet = useCallback(() => {
    setUserBottomSheetOpen(false);
  }, []);

  useEffect(() => {
    // to prevent the Salesforece chat button from sitting on top of the mobile user nav we need to set its zindex in mobile
    const sfChatButton = document.getElementById("embedded-messaging");
    sfChatButton?.setAttribute("style", "position: absolute; z-index: 900");

    return () => {
      sfChatButton?.setAttribute("style", "null");
    };
  }, []);

  return (
    <BottomSheet
      isOpen={userBottomSheetOpen}
      header={
        <Box backgroundColor="neutralN700">
          <BottomSheetHeader>
            <Box
              style={{ width: "100%" }}
              paddingBottom={12}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <UserHeader variant="body/lg">{`${user.firstName} ${user.lastName}`}</UserHeader>
                <SecondaryInfo variant="body/sm" $rewardsTier={rewardsProfile.tier}>
                  {additionalInfoCopy}
                </SecondaryInfo>
              </Box>
              <Box gap={8} display="flex" justifyContent="center" alignItems="center">
                {rewardsProfile.tier === "Disabled" ? null : (
                  <Points rewardsProfile={rewardsProfile} />
                )}
                <BottomSheetClose onClick={closeBottomSheet} />
              </Box>
            </Box>
            <Box style={{ width: "100%" }} paddingTop={12}>
              <UserRewards rewardsProfile={rewardsProfile} />
            </Box>
          </BottomSheetHeader>
        </Box>
      }
    >
      <BottomSheetTrigger onClick={openBottomSheet}>
        {shouldShowRewards && rewardsProfile.tier !== "Disabled" ? (
          <DropdownTargetInfo rewardsProfile={rewardsProfile} />
        ) : (
          <StyledIcon name="bars" color="backgroundInverse" />
        )}
        <Avatar name={`${user.firstName} ${user.lastName}`} size={32} />
      </BottomSheetTrigger>
      <BottomSheetContent>
        <HeaderFooter
          isMobile
          onToggleBizPersonalAccount={handleMobileAccountToggle}
          onPersonalAccountCreation={handleMobilePersonalAccountCreation}
          shouldShowRewards={shouldShowRewards}
          {...rest}
        />
      </BottomSheetContent>
    </BottomSheet>
  );
};
