import type { UseQueryOptions } from "react-query";

import { useApi } from "@hotel-engine/react-query/useApi";
import { useExtendedQuery } from "@hotel-engine/react-query/useExtendedQuery";
import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { ITravelPolicyRecord } from "@hotel-engine/types/travelPolicies";
import type { PaginatedData, PaginatedParams } from "@hotel-engine/types/generic";

import { cacheKeys, endpoints } from "../constants";

export type ISortTravelPolicy =
  | "allowIncidentals"
  | "directBillAllowed"
  | "lastModified"
  | "maxDailyRates"
  | "name"
  | "onlyRefundableRates"
  | "userCount";

type ITravelPoliciesData = ITravelPolicyRecord[] | [];

interface ITravelPoliciesResponse {
  data: ITravelPoliciesData;
  meta: PaginatedData<unknown>["meta"];
}

interface IParams {
  limit?: number | string;
  offset?: number;
  sort?: ISortTravelPolicy;
  order?: PaginatedParams["direction"];
}

export interface ITravelPolicyQueryConfig {
  options?: UseQueryOptions<ITravelPoliciesResponse, IErrorResponse, ITravelPoliciesData>;
  params?: IParams;
}

const useTravelPoliciesQuery = ({ options = {}, params = {} }: ITravelPolicyQueryConfig = {}) => {
  const get = useApi("get");
  // set default limit to 5000 to support select dropdown
  // this is for mvp and should be updated to a new UX which doesn't require this
  const { offset, limit = 5000 } = params;

  const queryKey = [cacheKeys.useTravelPoliciesQuery, offset, limit];
  const queryFn = () =>
    get<ITravelPoliciesResponse>(endpoints.travelPolicies, {
      limit,
      ...params,
    });

  return useExtendedQuery<ITravelPoliciesResponse, IErrorResponse, ITravelPoliciesData>(
    queryKey,
    queryFn,
    options
  );
};

export { useTravelPoliciesQuery };
