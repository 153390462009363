import { Icon } from "@hotel-engine/common/Icon";
import { colors } from "@hotel-engine/styles";

import * as Styled from "./styles";
import type { IExpressBookContractDataResponse } from "@hotel-engine/types/expressBook";

const COPY_OPTIONS = {
  increase:
    "Heads up, the rate for this room just increased. The new rate is the lowest available for this hotel.",
  decrease:
    "You're in luck! The rate for this room just decreased. It is still the best rate for this hotel.",
};

const ExpressBookRateChangeAlert = ({
  changeType,
}: {
  changeType: IExpressBookContractDataResponse["priceChange"];
}) => {
  const alertCopy = COPY_OPTIONS[changeType];

  if (changeType === "none") return null;
  return (
    <Styled.ExpressBookRateChangeAlertWrapper>
      <Icon icon={["fas", "exclamation-circle"]} color={colors.orange[400]} size="lg" />
      <Styled.ExpressBookRateChangeAlertColumn>
        <Styled.ExpressBookRateChangeAlertTitle>Rate change</Styled.ExpressBookRateChangeAlertTitle>
        <p>{alertCopy}</p>
      </Styled.ExpressBookRateChangeAlertColumn>
    </Styled.ExpressBookRateChangeAlertWrapper>
  );
};

export default ExpressBookRateChangeAlert;
