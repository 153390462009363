import { debounce } from "lodash";
import { flightsBreakpoints } from "pages/Flights/constants";
import { useEffect, useState } from "react";

export const useIsMobile = (size?: keyof typeof flightsBreakpoints) => {
  const [width, setWidth] = useState(globalThis.innerWidth);

  useEffect(() => {
    const handleResize = debounce(() => {
      setWidth(globalThis.innerWidth);
    }, 50);

    globalThis.addEventListener("resize", handleResize);

    return () => globalThis.removeEventListener("resize", handleResize);
  }, []);

  return width < flightsBreakpoints[size || "desktop"];
};
