import { Checkbox } from "antd";
import type { CheckboxProps } from "antd/lib/checkbox";
import styled from "styled-components";

import { colors } from "@hotel-engine/styles";

export const AntCheckbox = styled(Checkbox)<CheckboxProps>`
  color: ${colors.grey[800]};

  &.ant-checkbox-wrapper-disabled {
    .ant-checkbox-disabled + span {
      color: ${colors.newGrey500};
    }
  }
`;
