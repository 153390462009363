/**
 * Sanitizes the given string by:
 * - Removing all non-breaking space characters
 * - Trimming any trailing space
 * @param value string to sanitize
 * @returns sanitized string
 */
export function sanitizeString(value: string) {
  // intentional weak comparison to catch undefined as well
  if (value == null) {
    return value;
  }
  return value.replace(/(\u00A0|&#xA0;|&nbsp;)/g, "").trim();
}
