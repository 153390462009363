import { useEffect, useState } from "react";
import storage from "@hotel-engine/storage";
import type { Moment } from "moment";
import moment from "moment";
import { useAppSelector } from "store/hooks";

export type VisibilityStatus = "show" | "dismissed" | "expired" | "dismissedWithExpiry" | "none";
export interface IVisibilityOptions {
  status: VisibilityStatus;
  expiryDate: Moment;
}

export interface INotificationVisibility {
  status: VisibilityStatus;
  updateVisibilityStatus: (status: VisibilityStatus, delay?: number, expiryDate?: Moment) => void;
}

export const useNotificationVisibility = (key: string): INotificationVisibility => {
  const user = useAppSelector((state) => state.Auth.user);
  const [visibilityOptions, setVisibilityOptions] = useState<IVisibilityOptions>();

  useEffect(() => {
    const options = JSON.parse(
      storage.getItem(`${key}-${user?.email}`) || `{}`
    ) as IVisibilityOptions;

    const { expiryDate, status } = options;
    if (!status) {
      options.status = "show";
      options.expiryDate = moment().add(28, "days");
      storage.setItem(`${key}-${user?.email}`, JSON.stringify(options));
    } else if (["show", "dismissedWithExpiry"].includes(status)) {
      const isExpired = moment().diff(expiryDate) > 0;
      if (isExpired) {
        options.status = "expired";
        storage.setItem(`${key}-${user?.email}`, JSON.stringify(options));
      }
    }

    setVisibilityOptions(options);
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateVisibilityStatus = (status: VisibilityStatus, delay = 4500, expiryDate?: Moment) => {
    if (visibilityOptions) {
      const newValue: IVisibilityOptions = {
        status,
        expiryDate: expiryDate || visibilityOptions.expiryDate,
      };
      setTimeout(() => {
        setVisibilityOptions(newValue);
      }, delay);

      storage.setItem(`${key}-${user?.email}`, JSON.stringify(newValue));
    }
  };

  return {
    status: visibilityOptions?.status ?? "none",
    updateVisibilityStatus,
  };
};
