import styled, { css } from "styled-components";

import Typography from "@hotel-engine/common/Typography";
import { colors } from "@hotel-engine/styles";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";

export const Title = styled(Typography)`
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${colors.azureHint};
  width: 100%;
`;

export const UserMessage = styled.div`
  align-items: center;
  display: flex;
  font-size: 16px;
  margin-bottom: 16px;

  svg {
    color: ${colors.khmerCurry};
  }

  span {
    margin-left: 8px;
  }
`;

export const ErrorContainer = styled.div`
  background: ${colors.voraciousWhite};
  border: 1px solid ${colors.khmerCurry};
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
  color: ${colors.khmerCurry};
  margin-bottom: 15px;
  margin-left: 32px;
  margin-right: 24px;
  padding: 12px;

  > div {
    margin-bottom: 8px;
    margin-left: 2px;
  }

  span {
    margin-left: 5px;
  }

  ul {
    list-style: circle;
    margin: 0;
    padding-left: 20px;
  }
`;

export const ButtonWrapper = styled.div<{
  addMargin: boolean;
  isRenderedInModal: boolean;
}>(({ addMargin, isRenderedInModal }) => [
  isRenderedInModal
    ? css`
        display: flex;
        flex-direction: column;
        width: 100%;

        button {
          height: 40px;
          border-radius: ${({ theme }) => theme.legacy.shape.borderRadius["md"]};
          ${typographyMixin("body-large")};
        }

        button:nth-of-type(1) {
          margin-bottom: 16px;
        }
      `
    : css`
        display: flex;
        margin: 0;

        button {
          &:last-of-type {
            margin-left: 0;
          }

          &:first-of-type {
            margin-right: 16px;
          }
        }
      `,
  addMargin &&
    css`
      margin-bottom: 25px;
      margin-left: 35px;
      margin-right: 24px;
    `,
]);
