import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import type { IPropertyLegacy } from "@hotel-engine/types/property";

const initialState: IPropertyState = {
  property: undefined,
  loading: false,
};

export interface IPropertyState {
  readonly property?: IPropertyLegacy;
  readonly loading: boolean;
}

export const propertySlice = createSlice({
  name: "Property",
  initialState,
  reducers: {
    setHiddenPropertyId: (state, action: PayloadAction<number | null>) => {
      if (state.property) {
        return {
          ...state,
          property: {
            ...state.property,
            hiddenPropertyId: action.payload,
          },
        };
      }
      return state;
    },
    setPropertyFavoriteId: (state, action: PayloadAction<number | null>) => {
      if (state.property) {
        return {
          ...state,
          property: {
            ...state.property,
            favoritePropertyId: action.payload,
          },
        };
      }
      return state;
    },
    getPropertySuccess: (state, action: PayloadAction<IPropertyLegacy>) => ({
      ...state,
      property: action.payload,
      loading: false,
    }),
    getPropertyFailure: () => initialState,
  },
});

export const PropertyActions = propertySlice.actions;

export default propertySlice.reducer;
