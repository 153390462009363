import { useMutation } from "react-query";
import type { UseMutationOptions } from "react-query";

import type { IErrorResponse } from "@hotel-engine/types/errors";
import config from "config";

import { useApi } from "../useApi";
import { endpoints } from "../constants";

export interface IUseTwoFactorVerification {
  code: string;
  phone: string;
  accountType?: "personal";
}

export type ITwoFactorVerificationCodeConfig = UseMutationOptions<
  IUseTwoFactorVerification,
  IErrorResponse,
  IUseTwoFactorVerification
>;

/**
 * Submit a received verification code
 */
export const useTwoFactorVerificationCodeSubmit = (
  params?: { token: string },
  options?: ITwoFactorVerificationCodeConfig
) => {
  const endpoint = `${config.apiHostV2}/${endpoints.twoFactorVerificationCodeSubmit}`;
  const post = useApi("post");

  const mutationFn = (data: IUseTwoFactorVerification) =>
    post<IUseTwoFactorVerification>(endpoint, data, {
      ...(params?.token && {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      }),
    });

  return useMutation<IUseTwoFactorVerification, IErrorResponse, IUseTwoFactorVerification>(
    mutationFn,
    options
  );
};
