/* eslint-disable
 @typescript-eslint/ban-types,
 @typescript-eslint/naming-convention,
 @typescript-eslint/no-explicit-any,
 @typescript-eslint/no-non-null-assertion,
 prefer-rest-params,
*/
export {
  flow
}

import { absorb, type Functions } from "./shared"

interface Module {
  flow: {
    <A extends ReadonlyArray<unknown>, B>(ab: (...a: A) => B): (...a: A) => B
    <A extends ReadonlyArray<unknown>, B, C>(ab: (...a: A) => B, bc: (b: B) => C): (...a: A) => C
    <A extends ReadonlyArray<unknown>, B, C, D>(ab: (...a: A) => B, bc: (b: B) => C, cd: (c: C) => D): (...a: A) => D
    <A extends ReadonlyArray<unknown>, B, C, D, E>(ab: (...a: A) => B, bc: (b: B) => C, cd: (c: C) => D, de: (d: D) => E): (...a: A) => E
    <A extends ReadonlyArray<unknown>, B, C, D, E, F>(ab: (...a: A) => B, bc: (b: B) => C, cd: (c: C) => D, de: (d: D) => E, ef: (e: E) => F): (...a: A) => F
    <A extends ReadonlyArray<unknown>, B, C, D, E, F, G>(ab: (...a: A) => B, bc: (b: B) => C, cd: (c: C) => D, de: (d: D) => E, ef: (e: E) => F, fg: (f: F) => G): (...a: A) => G
  }
}

const flow
  : Module["flow"]
  = absorb(
    function flow(...[ab, bc, cd, de, ef, fg]: Functions): unknown {
      switch (arguments.length) {
        case 1: return ab
        case 2:
          return function (this: unknown) {
            return bc!(ab.apply(this, arguments))
          }
        case 3:
          return function (this: unknown) {
            return cd!(bc!(ab.apply(this, arguments)))
          }
        case 4:
          return function (this: unknown) {
            return de!(cd!(bc!(ab.apply(this, arguments))))
          }
        case 5:
          return function (this: unknown) {
            return ef!(de!(cd!(bc!(ab.apply(this, arguments)))))
          }
        case 6:
          return function (this: unknown) {
            return fg!(ef!(de!(cd!(bc!(ab.apply(this, arguments))))))
          }
      }
      return
    }
  )
