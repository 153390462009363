import moment from "moment-timezone";

/**
 * Determines whether a late check in can be requested for a reservation.
 * Returns true when it's before 6AM on the day after checkin.
 *
 * @param status The reservation status.
 * @param checkIn The reservation checkin date.
 * @param timezone The property timezone.
 */
const isTripLateCheckinAvailable = (status: string, checkIn: string, timezone?: string) => {
  const isTripInactive = status === "completed" || status === "cancelled";

  if (isTripInactive) {
    return false;
  }

  const isBefore11amDayAfter = moment().isBefore(
    moment(checkIn)
      .utc()
      .tz(timezone || "UTC", true)
      .add(1, "d")
      .hour(6)
  );

  return isBefore11amDayAfter;
};

export default isTripLateCheckinAvailable;
