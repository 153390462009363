import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./constants";
import {
  clearSelectedFilters,
  initFilters,
  setPriceRange,
  setSelectedSort,
  setStarRating,
  setFilterSelected,
  toggleLoyaltyProgramFilter,
  togglePropertyNameFilter,
  updateFilterCounts,
  toggleStayTypeFilter,
} from "./reducers";

export { initialState } from "./constants";

export const searchFiltersSlice = createSlice({
  name: "SearchFilters",
  initialState,
  reducers: {
    clearSelectedFilters,
    initFilters,
    resetState: () => initialState,
    setPriceRange,
    setSelectedSort,
    setStarRating,
    setFilterSelected,
    toggleLoyaltyProgramFilter,
    togglePropertyNameFilter,
    toggleStayTypeFilter,
    updateFilterCounts,
  },
});

export const searchFiltersActions = searchFiltersSlice.actions;

export default searchFiltersSlice.reducer;
