import { useFormikContext } from "formik";

import type { ICheckoutFormData } from "../../validation";
import { useContractRoomData } from "./useContractRoomData";
import { useAppSelector } from "store/hooks";
import type {
  IExpressBookCheckoutForm,
  IExpressBookContractRate,
} from "@hotel-engine/types/expressBook";

export const useOverlappingReservation = () => {
  const { contractRate } = useContractRoomData();
  const user = useAppSelector((state) => state.Auth.user);
  const form = useFormikContext<ICheckoutFormData>();

  const initialUserHasOverlappingRes =
    user?.role === "user" && !!contractRate?.overlappingContracts?.length;
  const selectedTravelerHasOverlappingRes =
    !!user?.id &&
    initialUserHasOverlappingRes &&
    form.values.guestList.some(
      (room) => room.primary.user?.id === user.id || room.secondary.user?.id === user.id
    );
  const initialOverlappingResData = contractRate?.overlappingContracts;

  return {
    initialUserHasOverlappingRes,
    selectedTravelerHasOverlappingRes,
    initialOverlappingResData,
  };
};

export const useOverlappingReservationExpressBook = (contractRateEB: IExpressBookContractRate) => {
  const user = useAppSelector((state) => state.Auth.user);
  const form = useFormikContext<IExpressBookCheckoutForm>();

  const initialUserHasOverlappingRes =
    user?.role === "user" && !!contractRateEB?.overlappingContracts?.length;
  const selectedTravelerHasOverlappingRes =
    !!user?.id &&
    initialUserHasOverlappingRes &&
    form.values.guestList.some(
      (room) => room.primary.user?.id === user.id || room.secondary.user?.id === user.id
    );
  const initialOverlappingResData = contractRateEB?.overlappingContracts;

  return {
    initialUserHasOverlappingRes,
    selectedTravelerHasOverlappingRes,
    initialOverlappingResData,
  };
};
