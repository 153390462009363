import type { IResult } from "@hotel-engine/types/search";
import type { SelectedFilter } from "store/Search/SearchFilters/types";
import {
  FILTER_FUNCTIONS,
  OR_FILTER_KEYS,
} from "@hotel-engine/helpers/search/results/buildFilteredResults/constants";

export interface IFilterOptions {
  showRatelessProperties?: boolean;
}

export interface IBuildFilterResultsFunction {
  selectedFilters: SelectedFilter[];
  options?: IFilterOptions;
}

export const buildFilterResultFunction = ({
  selectedFilters,
  options,
}: IBuildFilterResultsFunction): ((result: IResult) => boolean) => {
  const nonAggregatedFilters = selectedFilters.filter(
    (selectedFilter) => !OR_FILTER_KEYS.includes(selectedFilter.filterKey)
  );
  const aggregatedFilters = OR_FILTER_KEYS.flatMap((filterKey) =>
    selectedFilters.some((selectedFilter) => selectedFilter.filterKey === filterKey)
      ? {
          filterKey,
          value: selectedFilters
            .filter((selectedFilter) => selectedFilter.filterKey === filterKey)
            .map((selectedFilter) => selectedFilter.value),
        }
      : []
  );
  const allFilters = [...aggregatedFilters, ...nonAggregatedFilters];

  const doesResultMeetsFilterCriteria = (result: IResult) => {
    // Using some will break after finding any filter that returns false with a given result, so it will not be visible
    if (!options?.showRatelessProperties && !result.customerRate) return false;

    const failedFilterCriteria = allFilters.some(
      (filter) => !FILTER_FUNCTIONS[filter.filterKey](filter.value, result)
    );

    return !failedFilterCriteria;
  };

  return doesResultMeetsFilterCriteria;
};
