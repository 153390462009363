import { useEffect, useState } from "react";

import type { CSSProperties } from "styled-components";

import { ampli } from "ampli";

import * as Styled from "./styles";
import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";
import { Box, Button, Dialog, DialogContent, Typography } from "@hotelengine/atlas-web";

export interface IContents {
  /** The title that is displayed under the hero image */
  title?: string;
  /** The body of the post that displays under the title */
  description?: JSX.Element;
  /** The src for the hero image section background */
  heroBackground?: string;
  /** The src for any elements to be displayed in hero section */
  heroSrc?: string;
  /** The alt text for the provided media */
  heroAlt?: string;
  /** The src for a video asset for the hero section */
  videoSrc?: string;
}

export interface IOnboardingModal {
  /** Contents of each slide in the modal */
  contents: IContents[];
  /**Personal travel onboarding has some minor differences like heigth of the close btn */
  customPrimaryBtnStyles?: CSSProperties;
  /** Optional callback function to trigger when modal closes */
  onClose?: () => void;
}

const OnboardingModal = ({ contents, onClose, customPrimaryBtnStyles }: IOnboardingModal) => {
  const { COMPANY_NAME } = useCompanyName();
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const lastPage = contents.length - 1;

  // Log amplitude event when video is played
  useEffect(() => {
    if (contents[pageNumber].videoSrc) {
      ampli.viewPersonalAccountOnboardingModal();
    }
  }, [contents, pageNumber]);

  if (contents.length === 0) return null;

  const hideModal = () => {
    setIsModalVisible(false);
    ampli.clickClosePersonalAccountModal();
    onClose?.();
  };

  const advancePage = () => {
    pageNumber === lastPage ? hideModal() : setPageNumber(pageNumber + 1);
  };

  // shows "Close" for a single page modal, otherwise "Next" to advance or "End" on the last slide
  const advancePageButtonContents = (() => {
    if (contents.length === 1) return "Close";
    if (pageNumber < lastPage) return "Next";
    return "End";
  })();

  if (!contents[pageNumber]) return null;
  const currentSlide = contents[pageNumber];

  return (
    <>
      <Dialog isOpen={isModalVisible} data-testid="onboarding-modal">
        <DialogContent>
          {!!currentSlide.heroSrc && (
            <Styled.Hero background={currentSlide.heroBackground} data-testid="hero-background">
              {!!currentSlide.heroSrc && (
                <Styled.HeroImage src={currentSlide.heroSrc} alt={currentSlide.heroAlt} />
              )}
            </Styled.Hero>
          )}
          {!!currentSlide.videoSrc && (
            <Styled.HeroVideo>
              <iframe
                src={currentSlide.videoSrc}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title={COMPANY_NAME}
              ></iframe>
            </Styled.HeroVideo>
          )}
          <Styled.ContentsWrapper>
            {!!currentSlide.title && (
              <Typography variant="heading/lg" as="h2">
                {currentSlide.title}
              </Typography>
            )}
            {!!currentSlide.description && (
              <Typography variant="body/md" as="p">
                {currentSlide.description}
              </Typography>
            )}
          </Styled.ContentsWrapper>
          <Box
            as="footer"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            marginTop={16}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              style={{ width: "100%" }}
            >
              {pageNumber > 0 ? (
                <Button key="back" variant="outlined" onClick={() => setPageNumber(pageNumber - 1)}>
                  Back
                </Button>
              ) : (
                <Styled.PlaceHolder key="placeholder" />
              )}
              <Styled.DotWrapper key="dots" data-testid="dot-wrapper">
                {contents.length > 1 &&
                  contents.map((page, index) => {
                    return <Styled.Dot key={index} isActive={pageNumber === index} />;
                  })}
              </Styled.DotWrapper>
              <Button key="next" onClick={advancePage} style={customPrimaryBtnStyles}>
                {advancePageButtonContents}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OnboardingModal;
