import type { TNotificationCategory } from "@hotel-engine/types/courierNotifications";
import { Icon } from "@hotelengine/atlas-web";

export const returnIcon = (type?: TNotificationCategory) => {
  switch (type) {
    case "property_reviews":
      return <Icon name="thumbs-up" color="accentBlueStrong" />;
    case "new_features":
    case "product_updates":
      return <Icon name="sparkles" color="accentPurpleStrong" />;
    case "policy_updates":
    case "account_updates":
      return <Icon name="megaphone" color="accentRedStrong" />;
    case "rewards":
      return <Icon name="he-rewards--solid" color="accentTealStrong" />;
    case "promotions_and_special_offers":
      return <Icon name="gift" color="accentTealStrong" />;
    case "industry_news_and_updates":
      return <Icon name="hotel" color="accentYellowStrong" />;
    default:
      return <Icon name="suitcase-rolling--solid" color="accentGreenStrong" />;
  }
};
