import Button from "@hotel-engine/common/Button";
import { pxToRem } from "@hotel-engine/styles/helpers/mixins";

import styled from "styled-components";

export const ExpressBookCheckoutFormErrorContainer = styled.div`
  padding: ${pxToRem(16)};
  margin-bottom: ${pxToRem(24)};

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${pxToRem(16)};
`;

export const ExpressBookCheckoutFormErrorCopy = styled.h4`
  font-size: ${pxToRem(16)};
  font-style: normal;
  font-weight: 700;
  line-height: ${pxToRem(24)};
  text-align: center;

  max-width: 343px;
`;

export const ExpressBookCheckoutFormErrorButtonContainer = styled.div`
  display: flex;
  gap: ${pxToRem(8)};
  justify-content: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

export const ExpressBookCheckoutFormErrorButton = styled(Button)`
  height: ${pxToRem(40)};
`;
