import { Box, Typography } from "@hotelengine/atlas-web";

export const personalTravelerOnboardingContent = (COMPANY_NAME: string, COMPANY_ABBR: string) => [
  {
    title: "Welcome to Your Personal Account",
    description: (
      <Box margin={20}>
        <Typography variant="body/sm" color="foregroundPrimary">
          Congratulations on your new personal account! Watch our video to learn more about these
          key features:
        </Typography>
        <Box margin={20} as="ul" color="foregroundPrimary">
          <Typography variant="body/sm" as="li">
            Enjoy the same {COMPANY_NAME} discounts.
          </Typography>
          <Typography variant="body/sm" as="li">
            Keep personal travel private with no travel restrictions.
          </Typography>
          <Typography variant="body/sm" as="li">
            Carry over hotel loyalty numbers from your business account (and add more).
          </Typography>
          <Typography variant="body/sm" as="li">
            Earn and hold on to {COMPANY_ABBR} Reward points no matter what.
          </Typography>
        </Box>
        <Typography variant="body/sm" style={{ marginTop: 20 }} color="foregroundPrimary">
          Remember to take advantage of your $25 travel credit! It will expire 30 days after you
          create your personal account.
        </Typography>
      </Box>
    ),
    videoSrc:
      "https://player.vimeo.com/video/758888676?h=9641d2f2de&badge=0&autopause=0&player_id=0&app_id=58479",
  },
];
