import type { ISkeleton } from "@hotel-engine/common/Skeleton";
import { Skeleton } from "@hotel-engine/common/Skeleton";

import { cardPropsConfig } from "./helpers";
import * as Styled from "./styles";

export type SkeletonLoaderType = "tall" | "long" | "bar" | "default";
export type SkeletonLoaderSizes = "sm" | "md" | "lg" | "xl" | "fluid";
export interface ISkeletonLoader extends ISkeleton {
  /** Type of card - by default we will return the default card */
  type?: SkeletonLoaderType;
  /** Width of the card - default is medium */
  width?: SkeletonLoaderSizes;
  /** Height of the card - default is set on a per-card basis */
  height?: SkeletonLoaderSizes;
  /** Is the width of the card flexible. If set to true then width will be set as a minimum - default is false */
  flexible?: boolean;
}

/**
 * The `SkeletonLoader` component implements antd's Skeleton component and allows overriding of any default antd props.
 * Our internal `SkeletonLoader` provides four skeleton cards which should be used throughout the application for uniformity.
 * If a new skeleton card is requested from design that is completely unique, we should consider adding it to our collection of skeleton cards.
 *
 * @remarks If a one-off implementation of a card is slightly different than the current four types, we can leverage the existing skeleton card and either override the `antd` props and if necessary create a styled component wrapper that overrides any needed styling.
 * @see {@link https://3x.ant.design/components/skeleton/ AntD Skeleton Documentation}
 * @see {@link https://www.figma.com/file/eHm0GKtrMxDQ74elnwjA4t/Dashboard-UX?node-id=82%3A0 Design Specs}
 */
const SkeletonLoader = ({
  type = "default",
  width,
  height,
  flexible,
  ...props
}: ISkeletonLoader) => {
  // Grab default props from our config
  const defaultProps = cardPropsConfig[type];
  return (
    <Styled.SkeletonLoader
      type={type}
      width={width}
      height={height}
      flexible={flexible}
      className={props.className}
    >
      <Skeleton active {...defaultProps} {...props} />
      {
        // Long cards contain two antd Skeletons
        type === "long" && <Skeleton {...defaultProps} {...props} />
      }
    </Styled.SkeletonLoader>
  );
};

export default SkeletonLoader;
