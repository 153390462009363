import styled, { keyframes } from "styled-components";

import Button from "@hotel-engine/common/Button";
import { Icon } from "@hotel-engine/common/Icon";
import { colors } from "@hotel-engine/styles";
import { flexbox, interactiveStateColors } from "@hotel-engine/styles/helpers/mixins";

export const SettingsContainer = styled.div`
  ${flexbox({ direction: "column" })}
  padding: 12px;
  overflow: auto;
  a {
    ${interactiveStateColors({
      initialFg: colors.retroBlue,
      hoverFg: colors.denim,
    })}
  }
  p {
    margin-bottom: 0;
  }
`;

export const CookieInformation = styled.section`
  border-top: 1px solid ${colors.grey[50]};
  margin: 5px;
  h3 {
    ${flexbox({ justifyContent: "space-between", alignItems: "center" })};
    margin-top: 5px;
  }
`;

export const CookieDetails = styled.div`
  width: 100%;
`;

export const CookieDetailTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  tbody {
    width: 100%;
  }

  th,
  td {
    border: 1px solid ${colors.grey[200]};
    padding: 5px;
    text-align: left;
  }

  tr:hover {
    background-color: ${colors.grey[50]};
  }

  th {
    background-color: ${colors.retroBlue};
    color: ${colors.white};
  }
`;

export const Approve = styled(Button)`
  background-color: ${colors.retroBlue};
  color: ${colors.white};
  margin-left: auto;
`;

const spinRight = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(90deg);
    }
`;

const spinLeft = keyframes`
    from {
        transform: rotate(90deg);
    }

    to {
        transform: rotate(0deg);
    }
`;

export const ViewCookiesButton = styled(Button)`
  &.ant-btn {
    ${interactiveStateColors({
      initialFg: colors.retroBlue,
      hoverFg: colors.denim,
    })}
  }
`;

export const PlusIcon = styled(Icon)<{ $isOpen: boolean }>`
  animation: ${({ $isOpen }) => ($isOpen ? spinRight : spinLeft)} 0.15s linear;
  margin-right: 10px;
`;
