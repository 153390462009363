import { captureMessage } from "@hotel-engine/utilities";
import { googleMaps } from "@hotel-engine/scripts/google";
import { throttle, attempt, isError, once } from "lodash/fp";
import type { Dispatch, SetStateAction } from "react";

type Autocomplete = google.maps.places.AutocompleteService;
type Response = google.maps.places.AutocompleteResponse;
type AutocompletePrediction = google.maps.places.AutocompletionRequest;

export type Handle = (predictions: Response) => void;

/** googleAutoCompleteService
 * Service to allow users to leverage google maps
 * request - Accepts an input string and returns request if enabled
 * register - Accepts a handler function for AutocompleteResponse
 * unregister - deletes passed handler func
 * */
export default function GoogleAutoCompleteService() {
  const apiThrottle = throttle(50);

  let handlers: Set<Handle>;

  /** If no handlers initiate the set and the AutocompleteService */
  function init() {
    if (handlers === undefined) {
      handlers = new Set();
    }
  }
  /** Add passed handler to Set */
  function registerHandle(incomingHandle: Handle) {
    init();
    handlers.add(incomingHandle);
  }
  /** Search is only enabled if term is longer than 3 char */
  function enabled(inputLength: number) {
    return inputLength >= 3;
  }

  const getService = once((api: google.maps.PlacesLibrary) => {
    return new api.AutocompleteService();
  });

  function postInput(input: string, config?: Partial<AutocompletePrediction>) {
    return (service: Autocomplete) =>
      service.getPlacePredictions({
        input,
        componentRestrictions: config?.componentRestrictions,
        types: config?.types,
      });
  }

  function callback(input: string, res: Response) {
    return (handler: Handle) => {
      const response = attempt(() => {
        handler(res);
      });

      if (isError(response)) {
        captureMessage("Error loading handler", {
          error: response,
          extra: handler,
          input,
        });
      }
    };
  }

  const errorMessage = "Google autocomplete was unable to return results for the input text";

  /** Throttled request to getPlacePredictions
   * forEach disperses response to all registered handlers */
  const request = apiThrottle(
    (
      input: string,
      setIsLoading: Dispatch<SetStateAction<boolean>>,
      config?: Partial<AutocompletePrediction>
    ) => {
      if (!enabled(input.length)) {
        return;
      }

      setIsLoading(true);

      googleMaps
        .importLibrary("places")
        .then(getService)
        .then(postInput(input, config))
        .then((res) => {
          setIsLoading(false);
          handlers.forEach(callback(input, res));
        })
        .catch((error) => {
          setIsLoading(false);
          captureMessage(errorMessage, { error, input }, "info");
        });
    }
  );

  return {
    request: (
      input: string,
      setIsLoading: Dispatch<SetStateAction<boolean>>,
      config?: Partial<AutocompletePrediction>
    ) => request(input, setIsLoading, config),
    register: (setpredictions: Handle) => {
      registerHandle(setpredictions);
      return {
        unregister: () => handlers.delete(setpredictions),
      };
    },
  };
}
