import styled from "styled-components";

import Button from "@hotel-engine/common/Button";

export const CloseReviewButton = styled(Button)<{ $modal?: boolean }>`
  font-size: ${({ theme }) => theme.legacy.fontSize.md};
  height: 40px;
  min-width: 175px;
  width: ${({ $modal }) => $modal && "220px"};
`;
