import type { InvoiceType } from "@hotel-engine/types/invoiceItem";

type BookingType = "car" | "flight" | "hotel";
type IconType = "car-side" | "plane" | "hotel" | "he-flex" | "file-invoice-dollar";

export function getBookingType(bookingId: string): BookingType {
  if (/^F-/.test(bookingId)) {
    return "flight";
  }
  if (/^C-/.test(bookingId)) {
    return "car";
  }

  return "hotel";
}

export function getBookingIconFromBookingId(bookingId: string): IconType {
  if (/^F-/.test(bookingId)) {
    return "plane";
  }
  if (/^C-/.test(bookingId)) {
    return "car-side";
  }

  return "hotel";
}

export function getBookingIconFromInvoiceType(invoiceType: InvoiceType): IconType {
  if (invoiceType === "Flight") {
    return "plane";
  }
  if (invoiceType === "Car") {
    return "car-side";
  }
  if (invoiceType === "FlexPro") {
    return "he-flex";
  }
  if (invoiceType === "Incidental") {
    return "file-invoice-dollar";
  }

  return "hotel";
}
