export default (() => {
  const dimensions: IDimensions = {
    desktop: false,
    height: globalThis.innerHeight,
    isMobileDevice: false,
    mobile: false,
    mobileSmall: false,
    retina: false,
    tablet: false,
    width: globalThis.innerWidth,
  };

  setSizes(dimensions);

  const listener = () => {
    dimensions.width = globalThis.innerWidth;
    dimensions.height = globalThis.innerHeight;
    setSizes(dimensions);
  };
  globalThis.addEventListener("resize", listener);
  globalThis.addEventListener("orientationchange", listener);

  return dimensions;

  function setSizes({ width }) {
    // Device specific sizes
    dimensions.mobileSmall = width < 480;
    dimensions.mobile = width < 768;
    dimensions.tablet = width >= 768 && width < 980;
    dimensions.isMobileDevice = dimensions.mobile || dimensions.tablet;
    dimensions.desktop = width >= 980;
    dimensions.retina = width >= 1250;
  }
})();

export interface IDimensions {
  desktop: boolean;
  height: number;
  isMobileDevice: boolean;
  mobile: boolean;
  mobileSmall: boolean;
  retina: boolean;
  tablet: boolean;
  width: number;
}
