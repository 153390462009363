import { Alert } from "antd";
import styled, { css } from "styled-components";

import { colors } from "@hotel-engine/styles";

import type { IAlertBanner } from "./";

const sizes = {
  sm: "8px",
  md: "16px",
  lg: "32px",
};

export const AlertBanner = styled(Alert).attrs<IAlertBanner>(() => ({
  role: "alert",
}))<IAlertBanner>`
  border: none;
  min-height: 32px;
  padding: 6px 16px 6px 40px;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? sizes[marginBottom] : 0)};
  margin-top: ${({ marginTop }) => (marginTop ? sizes[marginTop] : 0)};
  width: 100%;
  ${({ type }) =>
    (type === "error" &&
      css`
        background-color: ${colors.voraciousWhite};
        .ant-alert-icon {
          color: ${colors.khmerCurry};
        }
      `) ||
    (type === "info" &&
      css`
        background-color: ${colors.placeboBlue};
        .ant-alert-icon {
          color: ${colors.retroBlue};
        }
      `) ||
    (type === "warning" &&
      css`
        background-color: ${colors.whatsLeft};
        .ant-alert-icon {
          color: ${colors.carrotCurl};
        }
      `) ||
    (type === "success" &&
      css`
        background-color: ${colors.peacefulRain};
        .ant-alert-icon {
          color: ${colors.green[600]};
        }
      `)}

  .ant-alert-icon {
    position: absolute;
    top: 9px;
    left: 16px;
  }

  &.ant-alert-with-description {
    .ant-alert-message {
      font-size: ${({ theme }) => theme.legacy.fontSize.sm};
      font-weight: ${({ theme }) => theme.legacy.fontWeight.medium};
      color: ${colors.grey[800]};
      margin: 8px 0 0 16px;
    }
    .ant-alert-description {
      margin: 0 0 8px 16px;
    }
    .ant-alert-icon {
      font-size: ${({ theme }) => theme.legacy.fontSize.xl};
      left: 16px;
      top: 16px;
    }
    .ant-alert-close-icon {
      top: 10px;
    }
    ${({ variant }) =>
      variant === "system-outage" &&
      css`
        .ant-alert-message {
          margin-top: 0px;
        }
        .fa-exclamation-triangle,
        .ant-alert-close-icon {
          top: 50%;
          transform: translateY(-50%);
        }
        .fa-exclamation-triangle {
          font-size: ${({ theme }) => theme.legacy.fontSize.xl};
          color: ${colors.red[400]};
        }
        .ant-alert-close-icon {
          svg {
            font-size: ${({ theme }) => theme.legacy.fontSize.sm};
            color: black;
          }
        }
        .title {
          font-size: ${({ theme }) => theme.legacy.fontSize.sm};
          font-weight: ${({ theme }) => theme.legacy.fontWeight.bold};
          line-height: ${({ theme }) => theme.legacy.lineHeight.sm};
          margin-bottom: 0;
        }
        .ant-alert-description {
          max-width: 80%;
          margin: 8px 0 8px 16px;
          .body-text {
            font-size: ${({ theme }) => theme.legacy.fontSize.sm};
            font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
            line-height: ${({ theme }) => theme.legacy.lineHeight.sm};
            margin: 0;
          }
        }
      `}
  }
`;
