import type { PayloadAction } from "@reduxjs/toolkit";
import type { IFlightsShopState, ISetFarePayload, ISetFlightPayload } from "./flights.shop.types";
import { entityAdapter, initialState } from "./flights.shop.constants";
import { upsertEntity } from "store/Flights/FlightsShop/flights.shop.helpers";

export const reset = () => initialState;

export const setFlight = (state: IFlightsShopState, action: PayloadAction<ISetFlightPayload>) => {
  const { sliceIndex, flight } = action.payload;

  upsertEntity({
    state,
    type: "flight",
    newData: { sliceIndex, flight, fare: null },
  });
};

export const setFare = (state: IFlightsShopState, action: PayloadAction<ISetFarePayload>) => {
  const { sliceIndex, fare } = action.payload;
  const entity = entityAdapter.getSelectors().selectById(state, sliceIndex);

  upsertEntity({
    state,
    type: "fare",
    newData: { sliceIndex, fare, flight: entity?.flight ?? null },
  });
};

export const setIsLoadingFlights = (state: IFlightsShopState, action: PayloadAction<boolean>) => {
  state.isLoadingFlights = action.payload;
};

export const setStartSearchTime = (
  state: IFlightsShopState,
  action: PayloadAction<number | null>
) => {
  state.startSearchTime = action.payload;
  state.endSearchTime = null;
  state.firstResultRenderedTime = null;
};

export const setEndSearchTime = (
  state: IFlightsShopState,
  action: PayloadAction<number | null>
) => {
  state.endSearchTime = action.payload;
};

export const setFirstResultRenderedTime = (
  state: IFlightsShopState,
  action: PayloadAction<number | null>
) => {
  state.firstResultRenderedTime = action.payload;
};
