import { useEffect } from "react";

/**
 *
 * @param time - length of timeout
 * @param callback - callback to fire on timeout completion
 * @param deps - dependencies to watch to restart timeout
 */
export const useTimeout = (time: number, callback, deps) => {
  useEffect(() => {
    const timer = setTimeout(callback, time);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);
};
