import styled, { keyframes } from "styled-components";

import Button from "@hotel-engine/common/Button";
import { Modal } from "@hotel-engine/common/Modal";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { colors, zIndex } from "@hotel-engine/styles";
import { flexbox, interactiveStateColors } from "@hotel-engine/styles/helpers/mixins";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";

const SlideUp = keyframes`
  from {
    transform: translateY(150%);
  }
  to {
    transform: translateY(0);
  }
`;

export const CookieBannerContainer = styled.div`
  animation: ${SlideUp} 1s ease-in-out;
  width: 100vw;
  z-index: ${zIndex.modal};
  bottom: 0;
  position: fixed;
  box-shadow: 0 -8px 16px 0 rgba(0, 0, 0, 0.08);
  background-color: ${colors.white};
  opacity: 0.85;
  ${mediaQuery(
    "xl",
    "max"
  )(`
    opacity: 0.95;
  `)}
`;

export const CookieBannerContent = styled.div<{ $isMobile: boolean }>`
  ${flexbox({ justifyContent: "space-between" })}
  height: ${({ $isMobile }) => ($isMobile ? "auto" : "15vh")};
  padding: 0 2vh;

  ${mediaQuery(
    "xl",
    "max"
  )(`
    flex-direction: column;
    padding: 24px;
    `)}
`;

export const CookieText = styled.span`
  display: inline-block;
  ${typographyMixin("body-medium", false)}
  margin: auto 16px auto auto;
  padding: 2.5vw 5vh;
  ${mediaQuery(
    "xl",
    "max"
  )(`
    margin: 0;
    padding: 0
    `)}
`;

export const ButtonContainer = styled.div`
  ${flexbox({
    direction: "column",
    justifyContent: "space-around",
  })};
  width: 18vw;
  padding: 0 auto;
  margin: auto auto auto 12px;
  ${mediaQuery(
    "xl",
    "max"
  )(`
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width:100%;
    padding: 0;
    margin: 16px 0 0;
    `)}
`;

export const Approve = styled(Button)`
  background-color: ${colors.retroBlue};
  color: ${colors.white};
  margin-bottom: 1.6rem;

  ${mediaQuery(
    "xl",
    "max"
  )(`
    margin-bottom: 0;
    min-width: 84px;
    `)}
`;

export const CookieModal = styled(Modal)`
  .ant-modal-content {
    overflow: auto;
    .ant-modal-body {
      padding: 0 24px;
      padding: 12px;
      a {
        ${interactiveStateColors({
          initialFg: colors.retroBlue,
          hoverFg: colors.denim,
        })}
      }
    }
  }
`;
