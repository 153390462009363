import type { IUser } from "@hotel-engine/types/user";

import type { AuthValidatorFn } from "../helpers";

/** Blocks route access if user account does not have travel credits enabled */
export const hasTravelCreditsAllowedEnabled: AuthValidatorFn = (user: IUser) => {
  return (
    user.business.travelCreditsAllowed &&
    (!user.business.hasPooledTravelCredits || user.role !== "user")
  );
};
