/* eslint-disable
 @typescript-eslint/naming-convention,
 @typescript-eslint/no-namespace,
 */
export {
  capitalize,
  head,
  tail,
}

type head<type extends string> = never |
  type extends `${infer head}${string}` ? head : never

function head<type extends string>(string: type): head<type>
function head(string: string): string { return string.charAt(0) }

type tail<type extends string> = never |
  type extends `${string}${infer tail}` ? tail : never

function tail<type extends string>(string: type): tail<type>
function tail(string: string): string { return string.slice(1) }

function capitalize<type extends string>(string: type): Capitalize<type>
function capitalize(string: string): string {
  return string.charAt(0).toUpperCase().concat(string.slice(1))
}
