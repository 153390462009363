import styled, { keyframes } from "styled-components";

const spinAnimation = keyframes`
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg)
  }
`;

const FullScreenSpinnerWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  & > svg {
    animation: 2s linear infinite ${spinAnimation};
    max-width: 100px;
  }

  & > svg > circle {
    display: block;
    fill: transparent;
    stroke: #000000;
    stroke-linecap: round;
    stroke-dasharray: 120;
    stroke-dashoffset: 120;
    stroke-width: 5px;
    transform-origin: 50% 50%;
  }
`;

/**
 * Takes up the fullscreen and shows a spinning circle
 * in the middle of the screen.
 */
export const RouteLoadingUI = () => (
  <FullScreenSpinnerWrapper>
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50" cy="50" r="40" />
    </svg>
  </FullScreenSpinnerWrapper>
);
