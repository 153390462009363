import type { ReactNode } from "react";

import * as Styled from "./styles";

export interface IBadgeProps {
  /** Indicates whether or not it has a colored, filled background with white text or a white background with colored text and a colored border */
  variant?: "filled" | "outlined";
  /** Indicates the type of badge to render, corresponding to color */
  status?: "success" | "warning" | "error" | "loading" | "default" | "idle";
  /** Font size of the content, matching Typography sizes */
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
  /** Best expressed as a short string */
  children: ReactNode;
}

/**
 * The `Badge` component normally appears in proximity to notifications or user avatars with eye-catching appeal, typically displaying unread messages count.
 *
 * @see {@link https://www.figma.com/file/GVLYN60OBX188CID3YvWpSo6/Components---WEB?node-id=910%3A2 Design Specs}
 */
export const Badge = Styled.BadgeWrapper;
