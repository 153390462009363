import { useEffect, useState } from "react";

import * as Sentry from "@sentry/browser";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";

import { tokenKey } from "@hotel-engine/constants";
import { queryClient } from "@hotel-engine/contexts";
import { User } from "@hotel-engine/services";
import storage from "@hotel-engine/storage";
import type { AccessTokenStored } from "@hotel-engine/utilities/auth/helpers";
import { ampli } from "ampli";
import { AuthActions } from "store/Auth/AuthRedux";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { getWidthXHeightFormattedString } from "@hotel-engine/utilities/helpers/getWidthXHeightFormattedString";

export type ITokenMode = "personal" | "business" | undefined;

interface IUseAccountModeProps {
  /** Function to handle the switch failure */
  onFailure?: (reason?: string) => void;
  /** Decides where to route to after toggling between accounts (defaults to dashboard) */
  forwardingRoute?: string;
}

/**
 * Hook used to toggle between business & personal Account.
 * The toggle flow is mostly simulating the sign in flow after the
 * /sessions endpoint return the access token
 *
 * @param onFailure is an optional function to handle the switch fail.
 *
 */
export const useAccountMode = ({
  onFailure,
  forwardingRoute = "/",
}: IUseAccountModeProps): [
  mode: ITokenMode,
  toggleAccountMode: (onSuccess?: () => void) => Promise<void>,
] => {
  const user = useAppSelector((state) => state.Auth?.user);
  const [mode, _setMode] = useState<ITokenMode>(user?.accountType);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { accountType } = user || {};

  useEffect(() => {
    _setMode(accountType);
  }, [accountType]);

  const personalTravelEnabled = !!user?.business.personalTravelEnabled;
  const canSwitchToPersonal =
    accountType === "business" &&
    !user?.business.isPerkAccount &&
    !!user?.personalTravelUserId &&
    personalTravelEnabled;
  const canSwitchToBusiness =
    user?.accountType === "personal" && !!user?.businessTravelUserId && personalTravelEnabled;

  const handleException = (e: unknown, tokens: string | null) => {
    Sentry.captureMessage("Personal Account token switch error", "error" as Sentry.SeverityLevel);

    console.error(e);
    storage.setSecureItem(tokenKey, tokens || "");
    onFailure?.();
  };

  const toggleAccountMode = async (onSuccess?: () => void) => {
    if (canSwitchToBusiness || canSwitchToPersonal) {
      const tokens = storage.getSecureItem(tokenKey);
      try {
        const parsedTokens = JSON.parse(tokens as string) as AccessTokenStored;

        const newAccountType =
          parsedTokens?.latestAccountType === "business" ? "personal" : "business";

        // make sure we have a token for the account type we're switching for first
        // otherwise the user will be logged out unceremoniously
        // if any other part of the application asks for user information
        if (typeof parsedTokens[newAccountType] === "undefined") {
          onFailure?.();
          return;
        }

        const updatedParsedTokens = {
          ...parsedTokens,
          latestAccountType: newAccountType,
        };

        storage.setSecureItem(tokenKey, JSON.stringify(updatedParsedTokens));

        /**
         * The method getSelf from the User service will use the current stored access token
         * to pull the user data. Because the token was updated in the lines above to use the
         * opposite account type token then getSelf will recover personal/business account
         * depending on current account type. The second parameter pass to getSelf is being
         * used to verify the retrieved account is actually linked to the current one.
         */
        const newUser = await User.getSelf(true, user);

        if (newUser && !newUser.error) {
          onSuccess?.();
          ampli.identify(String(user.id), {
            viewportSize: getWidthXHeightFormattedString(),
          });
          /**
           * If an user was successfully retrieved then the user in redux store is updated with the new info,
           * the queryClient cleared and user redirected to dashboard. Even when getSelf method has this.current(user)
           * that doesn't update the redux store and that's why AuthActions.setUser is called here.
           *  */
          dispatch(AuthActions.setUser(newUser));
          queryClient.clear();

          navigate(forwardingRoute, { replace: true });
        } else {
          /**
           * If an user was not retrieved cookies are restored to its original state and onFailure is called if exists
           */
          storage.setSecureItem(
            tokenKey,
            JSON.stringify({
              ...parsedTokens,
              personal: accountType === "business" ? null : parsedTokens.personal,
              business: accountType === "business" ? parsedTokens.business : null,
              latestAccountType: accountType === "business" ? "business" : "personal",
            })
          );
          onFailure?.(newUser?.error);
        }
      } catch (e) {
        handleException(e, tokens);
      }
    }
  };

  return [mode, toggleAccountMode];
};
