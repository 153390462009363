import storage from "@hotel-engine/storage";

export const openNewTab = (
  url: string,
  /**
   * Technically target can be any string specifying the context, but until we have
   * need for such a specific case, let's restrict to the special `target=` keywords.
   */
  target: "_self" | "_blank" | "_parent" | "_top",
  navigateFn: (url: string) => void
) => {
  storage.canPersist ? globalThis.open(url, target) : navigateFn(url);
};

export const internalLinkNewTabTarget = (): string => (storage.canPersist ? "_blank" : "_self");
