import {
  Box,
  BottomSheet,
  BottomSheetHeader,
  BottomSheetContent,
  BottomSheetClose,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogClose,
} from "@hotelengine/atlas-web";

import { useBreakpoint } from "@hotel-engine/hooks";

import * as Styled from "./styles";

interface ISignInModalProps {
  /** Indicates that the modal is visible */
  visible: boolean;
  /** Function that closes the modal */
  onCancel: () => void;
  /** Component to go back from the forgot password state */
  header?: JSX.Element | null;
  children: JSX.Element;
}

export const SignInModalWrapper = ({ visible, onCancel, header, children }: ISignInModalProps) => {
  const isMobile = useBreakpoint("md", "max");

  if (isMobile) {
    return (
      <BottomSheet
        header={
          <BottomSheetHeader>
            <Box display="flex" justifyContent="flex-end" style={{ width: "100%" }}>
              <BottomSheetClose onClick={onCancel} />
            </Box>
            {header}
          </BottomSheetHeader>
        }
        isOpen={visible}
      >
        <BottomSheetContent>{children}</BottomSheetContent>
      </BottomSheet>
    );
  }

  return (
    <Dialog isOpen={visible} onOpenChange={(open) => !open && onCancel()}>
      <DialogContent>
        <DialogClose onClick={onCancel} />
        <DialogTitle>{header}</DialogTitle>
        <Box marginTop={!!header ? 0 : 48}>
          <Styled.ModalContent>{children}</Styled.ModalContent>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
