import type { UseQueryOptions } from "react-query";

import type { IAdminOnboardingResponse } from "@hotel-engine/types/onboardingEvents";
import type { IError } from "@hotel-engine/types/errors";
import { endpoints } from "../constants";
import { useApi } from "../useApi";
import { useExtendedQuery } from "../useExtendedQuery";

/** Query to fetch the admin onboarding information */
export const useAdminOnboardingEventQuery = (
  options?: UseQueryOptions<IAdminOnboardingResponse, IError>
) => {
  const get = useApi("get");

  return useExtendedQuery<IAdminOnboardingResponse, IError>(
    [endpoints.onboardingEvents],
    () => get<IAdminOnboardingResponse>(`${endpoints.onboardingEvents}`),
    // cacheTime set to 0 so that it always refetches on mount and stays up-to-date between concurrently-active business accounts //
    { ...options, cacheTime: 0 }
  );
};
