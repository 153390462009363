import styled from "styled-components";

import { Modal } from "@hotel-engine/common/Modal";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";

export const PostCheckoutActionsModal = styled(Modal)`
  ${mediaQuery("lg")`
    max-width: 500px; 
  `}

  .ant-modal-content {
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xl};
    .ant-modal-body {
      padding: 0px;
      ${mediaQuery("md", "max")`
        padding: 8px;
      `}
    }
  }
`;
