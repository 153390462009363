export const getAdminOnboardingContents = (COMPANY_NAME: string) => [
  {
    title: "Welcome to Direct Bill",
    description: (
      <>
        <p>
          Thanks for signing up for Direct Bill! This new way to pay for your business travel allows
          you to:
        </p>
        <ul style={{ padding: "0 24px" }}>
          <li>Remove the need to pay when you book.</li>
          <li>Get one weekly, bi-weekly or monthly bill for all transactions..</li>
          <li>Do away with company credit cards to pay for lodging.</li>
        </ul>
      </>
    ),
    videoSrc:
      "https://player.vimeo.com/video/720707180?h=9d9ad6c467&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    title: "Add your payment method",
    description: (
      <p>
        Whether you are new to Direct Bill or already enrolled, we can set up a direct payment for
        you from your company's bank account via ACH.
      </p>
    ),
    heroBackground: "/assets/direct-bill-promotion/hero-background.png",
    heroSrc: "/assets/direct-bill-promotion/add-bank-account.png",
    heroAlt: "A button to add your bank account for direct bill",
  },
  {
    title: "Sign up for Autopay",
    description: (
      <p>
        Autopay makes it easy to pay for your lodging statement from {COMPANY_NAME}. We will
        automatically pull your payments from your company's bank account so you don't have to worry
        about due dates or late fees.
      </p>
    ),
    heroBackground: "/assets/direct-bill-promotion/hero-background.png",
    heroSrc: "/assets/direct-bill-promotion/set-up-autopay.png",
  },
  {
    title: "Enable Direct Bill for Travel Policies",
    description: (
      <p>
        Want to make sure your travelers can engage Direct Bill when they book rooms? Set your
        Travel Policies so Direct Bill is turned on, and your travelers won't have to worry about
        pulling out their own credit cards to pay for their rooms and you won't have to deal with
        the headache of reimbursements.
      </p>
    ),
    heroBackground: "/assets/direct-bill-promotion/hero-background.png",
    heroSrc: "/assets/direct-bill-promotion/direct-bill-access.png",
  },
];

export const travelerOnboardingContents = [
  {
    title: "Welcome to Direct Bill",
    description: (
      <p>
        Your company recently changed the way you can pay for your stay. When booking a room and
        checking out, you can now choose, "Pay with Direct Bill" before you submit. No need to pay
        with a credit card up front!
      </p>
    ),
    heroBackground: "/assets/direct-bill-promotion/hero-background.png",
    heroSrc: "/assets/direct-bill-promotion/direct_bill_general.png",
    heroAlt: "A button to add your bank account for direct bill",
  },
];
