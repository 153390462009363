import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 24px 16px 24px;
  row-gap: 24px;
`;

const BulletContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const BulletItem = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;
`;

const HeroImage = styled.img`
  width: 132px;
  height: 80px;
`;

export { Container, BulletContainer, BulletItem, HeroImage };
