import { useSearchParams } from "@hotel-engine/hooks/useSearchParams";
import type { ILinkedAuthStrategy } from "@hotel-engine/types/authStrategy";
import config from "config";

import { Button, Typography } from "@hotelengine/atlas-web";

interface ISignInWithSSOButtonProps {
  selectedAuthStrategy?: ILinkedAuthStrategy;
  onlyOneStrategy: boolean;
}

const SSOButton = ({ selectedAuthStrategy, onlyOneStrategy }: ISignInWithSSOButtonProps) => {
  const { return: returnUrl } = useSearchParams<{
    return: string;
  }>();
  const returnPath = returnUrl ? new URL(returnUrl).pathname : "/";
  const ssoParams = `target=members&path=${encodeURIComponent(returnPath)}`;
  const url = `${config.ssoHost}${selectedAuthStrategy?.url}?${ssoParams}`;
  const buttonText = onlyOneStrategy ? "Continue with" : "Sign in with";

  return (
    <Button
      variant="outlined"
      disabled={selectedAuthStrategy ? false : true}
      size="lg"
      id="auth-url"
      style={{ width: "100%" }}
      asChild
    >
      <Typography
        variant="body/md"
        as="a"
        href={selectedAuthStrategy ? url : undefined}
        target="_self"
      >
        {selectedAuthStrategy
          ? `${buttonText} ${selectedAuthStrategy?.displayName}`
          : "Sign in with SSO"}
      </Typography>
    </Button>
  );
};

export default SSOButton;
