import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";

import { useURLParams } from "@hotel-engine/hooks";
import { ampli } from "ampli";

import * as Styled from "./styles";
import { Has } from "@hotel-engine/guards";

export interface IPolicyItemProps {
  content?: string;
  title: string;
  visible?: boolean;
  testId?: string;
}

export const PolicyItem: FunctionComponent<IPolicyItemProps> = ({
  content,
  title,
  visible,
  testId,
}) => {
  const [isOpen, setIsOpen] = useState(visible || false);
  const { search, params } = useURLParams();
  const sid = Has("s")(search) ? search.s : undefined;
  const rid = Has("roomRateId")(search) ? search.roomRateId : undefined;
  const pid = Has("propertyId")(params) ? params.propertyId : undefined;

  useEffect(() => {
    // This opens the panel if the 'Please review here' link is click from ReviewSection
    if (visible !== undefined) {
      setIsOpen(visible);
    }
  }, [visible]);

  if (!content) {
    return null;
  }

  const handleToggle = () => {
    const submissionObj = {
      searchId: Number(sid),
      roomRateId: Number(rid),
      propertyId: Number(pid),
    };

    if (!isOpen) {
      switch (title) {
        case "Flexible Cancellation Notice":
          ampli.clickCancellationPolicy(submissionObj);
          break;
        case "Hotel Loyalty Program Notice":
          ampli.clickHotelLoyaltyProgramNotice(submissionObj);
          break;
        default:
          break;
      }
    }

    setIsOpen(!isOpen);
  };

  return (
    <Styled.PolicyItem data-testid={testId}>
      <button aria-expanded={isOpen} onClick={handleToggle}>
        <Styled.PolicyItemIcon icon={["far", "chevron-right"]} $isOpen={isOpen} />
        <span>{title}</span>
      </button>
      {renderContent(content ?? "", isOpen)}
    </Styled.PolicyItem>
  );
};

const renderContent = (content: string, isOpen: boolean) => {
  return (
    <Styled.PolicyItemContent role="region" aria-hidden={!isOpen}>
      {content}
    </Styled.PolicyItemContent>
  );
};
