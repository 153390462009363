import type { Ref, RefObject } from "react";

import { Button } from "antd";
import type { ButtonProps } from "antd/lib/button";
import styled from "styled-components";

import { buttonReset, colors } from "@hotel-engine/styles";
import { globalTheme } from "@hotel-engine/styles/themes/global.theme";

interface IButtonProps extends ButtonProps {
  ref?: RefObject<typeof Button> | Ref<HTMLButtonElement>;
}

export const AntButton = styled(Button)<IButtonProps>`
  ${buttonReset};
  text-shadow: none;
  background-color: ${colors.white};
  color: rgba(0, 0, 0, 0.65);
  border: 1px solid ${colors.grey[200]};
  font-size: ${globalTheme.legacy.fontSize.sm};
  line-height: ${globalTheme.legacy.lineHeight.md};
  border-radius: ${globalTheme.legacy.shape.borderRadius.sm};
  padding: 0 ${globalTheme.legacy.space(2)};
  &:hover:enabled,
  &:focus,
  &:focus:disabled {
    color: ${colors.denim};
    border-color: ${colors.denim};
  }
  &.ant-btn-lg {
    font-size: ${globalTheme.legacy.fontSize.md};
  }
  &.ant-btn-primary {
    background-color: ${colors.retroBlue};
    border-color: ${colors.retroBlue};
    color: ${colors.white};
    &:hover:enabled,
    &:focus,
    &:focus:disabled {
      background-color: ${colors.denim};
      border-color: ${colors.denim};
      color: ${colors.white};
    }
    &:active:enabled {
      background-color: ${colors.bavarianBlue};
      border-color: ${colors.bavarianBlue};
      color: ${colors.white};
    }
    &:disabled {
      background-color: ${colors.softBlue};
      border-color: ${colors.softBlue};
    }
  }
  &.ant-btn-danger {
    background-color: ${colors.teaberry};
    border-color: ${colors.teaberry};
    color: ${colors.white};
    &:hover:enabled,
    &:focus,
    &:focus:disabled {
      background-color: ${colors.meat};
      border-color: ${colors.meat};
      color: ${colors.white};
    }
    &:disabled {
      background-color: ${colors.meat};
      border-color: ${colors.meat};
    }
  }
  &.ant-btn-icon-only {
    padding: 0;
  }
  &.ant-btn-link {
    ${buttonReset};
    border-radius: 0;
    color: ${colors.retroBlue};
    &.ant-btn-lg {
      font-size: ${globalTheme.legacy.fontSize.md};
    }
    &:hover,
    &:focus {
      border-color: transparent;
      color: ${colors.fadedRetroBlue};
    }
  }
  &.ant-btn-background-ghost {
    color: ${colors.white};
    border-color: ${colors.white};
  }
  &.ant-btn-round {
    border-radius: 32px /* disable theming (do not replicate) */;
  }
`;
