import * as Styled from "./styles";

import NotificationAdminActions from "./NotificationAdminActions";
import {
  Box,
  Icon,
  Typography,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@hotelengine/atlas-web";

interface INotificationInboxHeader {
  isMobile: boolean;
  hasMessages: boolean;
  markAllAsRead: () => void;
}

export const NotificationInboxHeader = ({
  isMobile,
  hasMessages,
  markAllAsRead,
}: INotificationInboxHeader) => {
  return (
    <Styled.InboxHeader>
      <Typography as="h4" variant={isMobile ? "heading/sm" : "heading/md"} margin={0}>
        Notifications
      </Typography>
      <Popover>
        <PopoverTrigger>
          <Box>
            <Icon name="ellipsis" style={{ cursor: "pointer" }} />
          </Box>
        </PopoverTrigger>
        <PopoverContent align="end" style={{ maxWidth: "unset" }}>
          <NotificationAdminActions hasMessages={hasMessages} markAllAsRead={markAllAsRead} />
        </PopoverContent>
      </Popover>
    </Styled.InboxHeader>
  );
};
