import { useTranslation } from "react-i18next";

export const useAircraftInfo = () => {
  const { t } = useTranslation("iata_aircraft");

  return {
    getAircraftNameByIataAircraftKey: (iataAircraftKey: string) =>
      t(`iata_aircraft.name.${iataAircraftKey}`, { defaultValue: "Unknown aircraft" }),
  };
};
