import type { IBaseHEIconProps } from "./index";

const Airplane = ({ height = "15", width = "15" }: IBaseHEIconProps) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 15 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="1.-LANDING" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          fill="currentColor"
          id="HE_Component_SearchResults_@2x"
          transform="translate(-480.000000, -118.000000)"
        >
          <g id="Group-4" transform="translate(480.000000, 87.000000)">
            <g id="Airport" transform="translate(0.000000, 31.000000)">
              <path
                d="M15,7.5 C15,6.86842105 14.475,6.31578947 13.875,6.31578947 L9.75,6.31578947 L6,0 L4.5,0 L6.375,6.31578947 L2.25,6.31578947 L1.125,4.73684211 L0,4.73684211 L0.75,7.5 L0,10.2631579 L1.125,10.2631579 L2.25,8.68421053 L6.375,8.68421053 L4.5,15 L6,15 L9.75,8.68421053 L13.875,8.68421053 C14.475,8.68421053 15,8.13157895 15,7.5 Z"
                id="Airport_Icon"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Airplane;
