import { Box, Button, Dialog, DialogContent, Icon, Typography } from "@hotelengine/atlas-web";

export enum NuclearErrorRedirectOption {
  DEFAULT,
  HOMEPAGE,
}
interface INuclearErrorModal {
  /** should show modal */
  hasPageError: boolean;
  redirectOption?: NuclearErrorRedirectOption;
}

const handleRedirect = (option) => {
  if (!option) {
    globalThis.location.reload();
    return;
  }

  // Don't let users redirect outside of flight seatmap, ever
  if (globalThis.location.pathname === "/flights/seatmap-portal") {
    globalThis.location.reload();
    return;
  }

  if (option === NuclearErrorRedirectOption.HOMEPAGE) {
    globalThis.location.assign("/");
  } else {
    globalThis.location.reload();
  }
};

const NuclearErrorModal = ({ hasPageError, redirectOption }: INuclearErrorModal) => {
  return (
    <Dialog isOpen={hasPageError}>
      <DialogContent>
        <Box display="flex" flexDirection="row" alignItems="center" marginBottom={24}>
          <Icon name="circle-exclamation" size="lg" color="accentOrange" />
          <Typography variant="heading/md" paddingLeft={16}>
            Sorry, we are unable to retrieve your information.
          </Typography>
        </Box>
        <Box paddingLeft={40}>
          <Typography variant="body/md">
            Something went wrong. Please reload this page to try again.
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" marginTop={12} style={{ width: "100%" }}>
          <Button onClick={() => handleRedirect(redirectOption)}>Reload</Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default NuclearErrorModal;
