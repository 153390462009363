import type { IPaymentProfile } from "@hotel-engine/types/paymentProfile";
import config from "config";

import Resource from "./Resource";

/**
 * @deprecated - switch to the associated react-query hooks if possible
 */
export default class PaymentProfile extends Resource<IPaymentProfile> {
  public static _basePath = `${config.apiHostV2}/payment_profiles`;

  // Duplicate of the constructor from ReadOnlyResource;
  // The context of "this" got broken in the upgrade to CRA,
  // so calling it explicitly here as a hack
  constructor(params?) {
    super(params);
    Object.assign(this, params);
  }

  // this is used to indicate to the parent classes that request should include the camel case key-inflection header

  // declaring these so they can be accessed in the isValid instance method
  public isExpired!: boolean;
  public billingPostalCode!: string;
}
