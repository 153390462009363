import styled, { createGlobalStyle } from "styled-components";

import { Modal } from "@hotel-engine/common/Modal";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { colors } from "@hotel-engine/styles";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";

import type { IPersonalAccountCreationScreen } from "./PersonalAccountCreationModal";

export const GlobalBottomSheetStyles = createGlobalStyle`
  :root {  
    --rsbs-overlay-rounded: 14px;
  }
`;

export const BottomSheetContent = styled.div`
  padding: 24px;
  margin-top: 4px;
`;

export const PersonalAccountCreationModal = styled(Modal)<{
  $screen: IPersonalAccountCreationScreen;
}>`
  max-width: 500px;
  width: auto !important;
  ${mediaQuery("lg", "max")(`margin: ${({ theme }) => theme.legacy.space(2)} !important`)};

  .ant-modal-content {
    padding: ${({ theme }) => theme.legacy.space(3)};

    div.ant-modal-body {
      padding: 0;
    }
  }

  .ant-modal-header {
    padding: 0;
    margin-bottom: ${({ theme }) => theme.legacy.space(2)};
  }

  .ant-modal-title {
    ${typographyMixin("xlarge", false)};
    color: ${colors.black};
    text-align: center;
  }

  .ant-modal-footer {
    border: none;
    display: flex;
    padding: 0;

    > button {
      margin: 0;
      flex: 1;

      &:first-of-type {
        color: ${colors.black};
        margin-right: 16px;
      }
    }
  }
`;

export const TermsText = styled.p`
  ${typographyMixin("xsmall")};
  font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
  color: ${colors.grey[400]};
  margin-top: ${({ theme }) => theme.legacy.space(1)};
`;

export const SmallLink = styled.a`
  ${typographyMixin("body-small")};
  color: ${colors.retroBlue};
`;
