export interface IFlightSearchControlProps {
  isMobileScreen: boolean;
}

export interface IFlightLocationControlProps extends IFlightSearchControlProps {
  sliceIndex: number;
  forcedUpdate: boolean;
  setForcedUpdate: (newState: boolean) => void;
  fromSearchBar?: boolean;
  visible?: boolean;
}

export type InputFocused = "departure" | "arrival" | null;

export enum FareListAlertType {
  limited = "limited",
  sameFare = "sameFare",
}
export interface IFareListAlert {
  title: string;
  text: string;
}
