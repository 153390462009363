/* eslint-disable
 @typescript-eslint/no-namespace,
 @typescript-eslint/naming-convention,
 @typescript-eslint/ban-types,
 @typescript-eslint/no-shadow,
 */
import type { Any } from "../any/exports"
import { Fn } from "../function/exports"
import { isUnusedParameter } from "../function/function"
import type { Replace } from "../replace/exports"

export {
  emptyOf,
  entries,
  every,
  findAndReplace,
  is,
  keys,
  make,
  map,
  mapWithIndex,
  singleton,
  some,
  values,
}

declare namespace Struct {
  type NonStructs = [Any.Primitive, Any.Array]
  type isStruct<type> = Extract<Exclude<type, NonStructs[number]>, Any.Struct>
  type ground<type> = 0 extends 1 & type ? never : type

  export interface is {
    <const type extends {}>(u: ground<type>): u is Struct.isStruct<ground<type>>
    (u: unknown): u is Any.Struct
  }
  export interface findAndReplace<target, replaceWith> {
    <const struct extends Any.Struct>(u: struct): Replace.in<struct, target, replaceWith>
  }
}

const is
  : Struct.is
  = (u: unknown): u is never => u !== null && typeof u === "object" && !Array.isArray(u)

type Entries<T>
  = Any.Array<keyof T extends infer K ? K extends keyof T ? readonly [K, T[K]] : never : never>

const entries
  : <T extends Any.Struct>(t: T) => Entries<T>
  = Object.entries

type Keys<T>
  = Any.Array<keyof T>

const keys
  : <T extends Any.Struct>(t: T) => Keys<T>
  = Object.keys

type Values<T>
  = Any.Array<T[keyof T]>

const values
  : <T extends Any.Struct>(t: T) => Values<T>
  = Object.values

/**
 * Constructs an empty object that has a particular shape. Useful when you're initializing
 * an initial value that will be mutated (or passed as an initial value to
 * {@link Array.prototype.reduce}, for example).
 */
const emptyOf
  : <T extends Any.Struct = never>() => T
  = () => Object.create(Object.getPrototypeOf({}))

const make
  : <const T extends Any.Object>(struct: T) => T
  = Fn.absorb(Fn.identity)

const every
  : <T>(predicate: Fn.Predicate<T>) => Fn.Predicate<Any.Dict<T>>
  = (predicate) => (struct) => {
    const ks = Object.keys(struct)
    for (const k of ks) {
      if (k in struct)
        if (!predicate(struct[k])) return false
    }
    return true
  }

const some
  : <T>(predicate: Fn.Predicate<T>) => Fn.Predicate<Any.Dict<T>>
  = (predicate) => (struct) => {
    const keys = Object.keys(struct)
    for (const k of keys) {
      if (k in struct)
        if (predicate(struct[k])) return true
    }
    return false
  }

function mapWithIndex<Ix extends Any.Key, V1, V2>(f: (ix: Ix, v: V1) => V2): (fa: { [K in Ix]: V1 }) => { [K in Ix]: V2 }
function mapWithIndex<Ix extends Any.Key, V1, V2>(struct: { [K in Ix]: V1 }, fn: (ix: Ix, v: V1) => V2): { [K in Ix]: V2 }
function mapWithIndex<V1, V2>(
  ...args:
    | [fn: (k: Any.Key, v: V1) => V2]
    | [struct: Any.Dict<V1>, fn: (k: Any.Key, v: V1) => V2]
): unknown {
  if (Fn.isUnary(args)) {
    const [fn] = args
    return <T extends Any.Dict<V1>>(struct: T) => mapWithIndex(struct, fn)
  }
  else {
    const [struct, fn] = args
    const out = emptyOf<Any.Dict<V2>>()
    for (const k in struct) {
      if (Object.prototype.hasOwnProperty.call(struct, k)) {
        out[k] = fn(k, struct[k])
      }
    }

    return out
  }
}

function map<V1, V2>(fn: (v: V1) => V2): <Ix extends Any.Key>(struct: { [K in Ix]: V1 }) => { [K in Ix]: V2 }
function map<V1, V2, Ix extends Any.Key>(struct: { [K in Ix]: V1 }, fn: (v: V1) => V2): { [K in Ix]: V2 }
function map<V1, V2, Ix extends Any.Key>(
  ...fullArgs:
    | readonly [fn: (v: V1) => V2]
    | readonly [struct: { [K in Ix]: V1 }, fn: (v: V1) => V2]
): unknown {
  if (Fn.isUnary(fullArgs)) {
    const [fn] = fullArgs
    return mapWithIndex<Ix, V1, V2>((_, a) => fn(a))
  }
  else {
    const [struct, fn] = fullArgs
    return mapWithIndex<Ix, V1, V2>(struct, (_, a) => fn(a))
  }
}

/**
 * Define a record with a single property.
 *
 * Note that {@link singleton} is distributive, meaning that if the provided key is a union type,
 * `singleton` will return a union of possible records.
 *
 * Distributing the union like this avoids the problem described in
 * {@link https://github.com/gcanti/fp-ts/issues/1413 this issue}.
 */
function singleton<K extends Any.Key>(k: K): <V>(v: V) => K extends K ? { [P in K]: V } : never
function singleton<K extends Any.Key, V>(k: K, v: V): K extends K ? { [P in K]: V } : never
function singleton(k: Any.Key, v: unknown = Fn.UnusedParameter): unknown {
  return isUnusedParameter(v)
    ? (next: unknown) => singleton(k, next)
    : { [k]: v }
}

const isNonNullable
  : <const type>(u: type) => u is type & {}
  = (u): u is never => u !== null && typeof u !== "undefined"

export function hasOwn<prop extends Any.Key>(prop: prop): Any.TypeGuard<unknown, Any.Row<prop, Any.NonNullable>>
export function hasOwn<prop extends Any.Key, target>(prop: prop, guard?: Any.TypeGuard<unknown, target>): Any.TypeGuard<unknown, Any.Row<prop, target>>
export function hasOwn<prop extends Any.Key, target extends Any.NonNullable>(prop: prop, guard = isNonNullable): Any.TypeGuard<unknown, Any.Row<prop, target>> {
  return (u): u is never => guard((u as never)[prop])
}

/**
 * A _"find & replace"_ utility for objects.
 *
 * {@link findAndReplace Struct.findAndReplace} takes 2 arguments: a user-defined
 * type guard (`rejectMatching`) and a substitution value (`replaceWith`), and
 * returns a _"find & replace"_ function that accepts any JavaScript object, and
 * returns a new object with the substitutions applied.
 *
 * **Note** that values for which the `rejectGuard` returns `true` are _removed_, not _kept_.
 *
 * Also **note** that the final output of `Struct.findAndReplace` will be an object whose type
 * _depends_ on the type guard you gave it: any properties that satisfy the type guard will
 * be updated to reflect the `replaceWith` argument it received.
 *
 * @example
*  import { Struct } from "@hotel-engine/data"
*  import { is } from "@hotel-engine/guards"
*
*  const patch = findAndReplace(is.nullable, "")
*
*  const myAddress = {
*    street1: null,
*    street2: null,
*    city: "Denver",
*    state: "CO",
*    zipCode: undefined,
*  } as const
*
*  const out = patch(addr)
*  //    ^? type out: { street1: ""; street2: ""; city: "Denver"; state: "CO"; zipCode: ""; }
*
*  console.log(out)
*  // > { street1: "", street2: "", city: "Denver", state: "CO", zipCode: "", [[Prototype]]: Object }
*/
function findAndReplace<prev, const next>(rejectMatching: Any.TypeGuard<prev>, replaceWith: next): Struct.findAndReplace<prev, next>
function findAndReplace<prev, const next>(rejectMatching: Any.TypeGuard<prev>, replaceWith: next) {
  return (struct: Any.Struct) => {
    const out = { ...struct }
    for (const key in struct) {
      out[key] = rejectMatching(struct[key])
        ? replaceWith
        : struct[key]
    }
    return out
  }
}
