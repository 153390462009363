import { Field, Form, useFormikContext } from "formik";
import InputField from "@hotel-engine/common/FormikFields/InputField";
import { Typography } from "@hotelengine/atlas-web";

const ChangePasswordForm = () => {
  const { errors } = useFormikContext();
  return (
    <Form>
      <Field
        component={InputField}
        label="Current Password"
        name="currentPassword"
        type="password"
        placeholder="Enter your current password"
        data-testid="current-password-field"
      />
      <Field
        component={InputField}
        label="New Password"
        name="newPassword"
        type="password"
        placeholder="Choose a strong, unique password"
        data-testid="new-password-field"
      />
      <Field
        component={InputField}
        label="Confirm New Password"
        name="passwordConfirmation"
        type="password"
        placeholder="Match your new password"
        data-testid="confirm-password-field"
      />
      {Object.keys(errors)
        .filter((i) => i === "general")
        .map((e, i) => {
          return (
            <Typography key={i} color="accentRed" variant="body/sm">
              {errors[e]}
            </Typography>
          );
        })}
    </Form>
  );
};

export default ChangePasswordForm;
