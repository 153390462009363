import type { UseMutationOptions } from "react-query";
import { useMutation, useQueryClient } from "react-query";

import { notification } from "@hotel-engine/common/Notifications";
import { endpoints } from "@hotel-engine/react-query/constants";
import { useApi } from "@hotel-engine/react-query/useApi";
import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { IGuest, IGuestUpdateParams } from "@hotel-engine/types/guest";
import { convertEmptyStrValsToNull } from "@hotel-engine/utilities";

export const useUpdateGuest = (
  options?: UseMutationOptions<IGuest, IErrorResponse, IGuestUpdateParams>
) => {
  const put = useApi("put");
  const queryClient = useQueryClient();

  return useMutation(
    (params) => put(`${endpoints.guests}/${params.guest.id}`, convertEmptyStrValsToNull(params)),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [endpoints.guests] });
        notification.success({
          message: "Guest Updated",
          duration: 10,
        });
      },
      ...options,
    }
  );
};
