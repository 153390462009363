import type { NavigateProps, Params } from "@hotel-engine/lib/react-router-dom";
import { Navigate, useParams } from "@hotel-engine/lib/react-router-dom";

export const replaceRouteParams = (to: string, params: Readonly<Params<string>>) => {
  const routeParamsFromUrl = Object.entries(params);

  // The route template string (specified in code), which will initially contain ":id" or whatever
  // Example: "/newpath/:id"
  let newToPath = to;

  // Find ":id" and replace ":id" with the actual id value from the provided
  // route params pulled from the url
  // Example: "/newpath/:id" => "/newpath/123"
  routeParamsFromUrl.forEach(([routeParamKey, valueFromUrl]) => {
    newToPath = newToPath.replace(`:${routeParamKey}`, `${valueFromUrl}`);
  });

  return newToPath;
};

/**
 * In react router 6, you can no longer <Redirect to="/path/:id" /> with route params.
 * So if you wanted to redirect like:
 *  From: /path/:id
 *  To: /new-path/:id
 *
 * You would want to use this component like:
 * ```
 * {
 *  path: "/oldpath/:token",
 *  element: (
 *    <RedirectWithRouteParams to="/newpath/:token" />
 *  ),
 * }
 * ```
 *
 * It'll look in the route params for `token`, which better be there or this will break.
 * I found this to be the approach taken by an existing npm package:
 *  https://github.com/nirmaoz/react-router6-redirect/blob/main/src/Redirect.tsx
 */
export function RedirectWithRouteParams({ to, ...rest }: NavigateProps & { to: string }) {
  const routeParams = useParams();

  return <Navigate to={replaceRouteParams(to, routeParams)} {...rest} replace />;
}
