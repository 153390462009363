import type { IUser } from "@hotel-engine/types/user";

/**
 * Returns a boolean after determining if a user is eligible to enroll in rewards
 *
 *
 * @param user - auth user
 * @returns true if the user has a role of "admin", and the business is eligible but doesn't yet pool rewards,
 */
export const isUserEligibleToEnrollInRewards = (user: IUser | null) => {
  if (!user) {
    return false;
  }

  const { business } = user;

  // Does the business have rewards disabled set to true?
  const isBusinessRewardsDisabled = business?.disableRewards;

  // Returns true if business IS NOT disabled for rewards
  return !isBusinessRewardsDisabled;
};

/**
 * Returns a boolean after determining if a user is already enrolled in rewards
 *
 * @remarks
 * To change the routing and/or CTA's to enroll of view rewards
 *
 * @param user - auth user
 * @returns true if the user has a role of "admin", and the business is eligible for pooling rewards and is or isn't enrolled already,
 */
export const isUserEnrolledInRewards = (user?: IUser) => {
  if (!user) {
    return false;
  }
  const { rewardsMember, rewardsProfile } = user;
  // Is the user enrolled in rewards and do the have a rewards profile?
  const isUserEnrolled = Boolean(rewardsMember && rewardsProfile);

  // Returns a boolean after determining if a user is eligible to enroll in rewards via the current condition of checking the business.disableRewards boolean (the intent is that this may have more restrictions in the future)
  const isUserEligible = isUserEligibleToEnrollInRewards(user);

  // Returns true if business isn't disabled for rewards and the user is a "admin" and if the user is already enrolled
  return isUserEligible && isUserEnrolled;
};

/**
 * Returns a boolean after determining if a business is pooling rewards
 * and whether the user should have access
 *
 * @remarks
 * Main use case is to hide UI elements based on the returned value
 *
 * @param user - auth user
 * @returns true if the user has a role of "admin", the business pools rewards, disableRewards is false for the business (mainly just for texas), if the user is enrolled in rewards and has a profile
 */
export const isUserEligibleToViewAndUseRewards = (user: IUser | null, userRole?: string) => {
  if (!user) {
    return false;
  }

  const { business, role } = user;

  // Does the business have pooled reward points?
  const isBusinessPoolingRewards = business?.hasPooledRewardPoints;

  // Checks if user is a rewards member and has a rewards profile, if a business rewards feature is disabled and if a user is a "admin"
  const isUserEligibleAndEnrolledInRewards = isUserEnrolledInRewards(user);

  // Is the user a admin or match the optional string passed through?
  const isUserRoleValid = role === userRole || role === "admin";

  // Returns true if the user is currently enrolled in rewards and the business is pooling rewards and the user is a "admin" OR is currently enrolled in rewards and the business IS NOT pooling rewards
  return (
    isUserEligibleAndEnrolledInRewards &&
    (!isBusinessPoolingRewards || (isBusinessPoolingRewards && isUserRoleValid))
  );
};
