import { createEntityAdapter } from "@reduxjs/toolkit";
import type { IFlightSlice, IFlightsShopState } from "./flights.shop.types";

export const entityAdapter = createEntityAdapter({
  selectId: (result: IFlightSlice) => result.sliceIndex,
});

export const initialState: IFlightsShopState = {
  ...entityAdapter.getInitialState(),
  isLoadingFlights: false,
  startSearchTime: null,
  endSearchTime: null,
  firstResultRenderedTime: null,
};
