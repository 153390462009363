// SEARCH
export enum EInclusion {
  freeCancellation = "freeCancellation",
  flexibleCancellation = "flexibleCancellation",
  unlimitedMileage = "mileage",
}

export enum EFilterId {
  maximumPrice = "maximumPrice",
  filterPolicy = "filterPolicy",
  vehicleType = "vehicleType",
  vendors = "vendors",
  pickup = "pickup",
  paymentType = "paymentType",

  freeCancellation = EInclusion.freeCancellation,
  flexibleCancellation = EInclusion.flexibleCancellation,
  unlimitedMileage = EInclusion.unlimitedMileage,
}

export enum ECarBottomFeatures {
  passengers = "passengers",
  transmissionType = "transmissionType",
  numberBags = "numberBags",
}

export enum ECarListFeatures {
  doorCount = "doorCount",
  milesIncluded = "milesIncluded",
  unlimitedMileage = "unlimitedMileage",
  freeCancellation = "freeCancellation",
  flexibleCancellation = "flexibleCancellation",
  payLater = "payLater",
  directBill = "directBill",
}

export type TCarFeatureNames =
  | ECarBottomFeatures
  | ECarListFeatures
  | Exclude<
      CarRentalVendorAirportAccessType,
      | CarRentalVendorAirportAccessType.CAR_RENTAL_VENDOR_LOCATION_TYPE_UNKNOWN
      | CarRentalVendorAirportAccessType.CAR_RENTAL_VENDOR_LOCATION_TYPE_CITY
      | CarRentalVendorAirportAccessType.UNRECOGNIZED
    >;

// CHECKOUT

export interface IPostalAddress {
  recipients: string[];
  organization: string;
  addressLine: string[];
  administrativeArea?: string;
  locality?: string;
  postalCode?: string;
  countryCode: string;
}

export interface ICarRentalVendorAirportAccessibility {
  iataAirportCode: string;
  locationType: CarRentalVendorAirportAccessType;
}

export enum ACRISSCategory {
  MINI = "M",
  MINI_ELITE = "N",
  ECONOMY = "E",
  ECONOMY_ELITE = "H",
  COMPACT = "C",
  COMPACT_ELITE = "D",
  INTERMEDIATE = "I",
  INTERMEDIATE_ELITE = "J",
  STANDARD = "S",
  STANDARD_ELITE = "R",
  FULLSIZE = "F",
  FULLSIZE_ELITE = "G",
  PREMIMUM = "P",
  PREMIMUM_ELITE = "U",
  LUXURY = "L",
  LUXURY_ELITE = "W",
  OVERSIZE = "O",
  SPECIAL = "X",
  WILDCARD = "-",
}

export enum ACRISSType {
  VAN = "V",
  SUV = "F",
  TRUCK = "K",
  PICKUP_SINGLE = "P",
  PICKUP_DOUBLE = "Q",
  WILDCARD = "-",
}

export enum CarRentalVendorAirportAccessType {
  CAR_RENTAL_VENDOR_LOCATION_TYPE_UNKNOWN = "CAR_RENTAL_VENDOR_LOCATION_TYPE_UNKNOWN",
  CAR_RENTAL_VENDOR_LOCATION_TYPE_IN_AIRPORT = "CAR_RENTAL_VENDOR_LOCATION_TYPE_IN_AIRPORT",
  CAR_RENTAL_VENDOR_LOCATION_TYPE_SHUTTLE_FROM_AIRPORT = "CAR_RENTAL_VENDOR_LOCATION_TYPE_SHUTTLE_FROM_AIRPORT",
  CAR_RENTAL_VENDOR_LOCATION_TYPE_CITY = "CAR_RENTAL_VENDOR_LOCATION_TYPE_CITY",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum CarNoticeType {
  NOTICE_TYPE_UNKNOWN = "NOTICE_TYPE_UNKNOWN",
  NOTICE_TYPE_IMPORTANT_INFORMATION = "NOTICE_TYPE_IMPORTANT_INFORMATION",
  NOTICE_TYPE_RENTAL_POLICY = "NOTICE_TYPE_RENTAL_POLICY",
  NOTICE_TYPE_FUEL_POLICY = "NOTICE_TYPE_FUEL_POLICY",
  NOTICE_TYPE_CANCELLATION_POLICY = "NOTICE_TYPE_CANCELLATION_POLICY",
}

export interface ITextNotice {
  title: string;
  text: string;
  key?: string;
}

export interface ICarNotice {
  type: CarNoticeType;
  textNotice: ITextNotice;
}
export interface ICarNotices {
  notices: ICarNotice[];
}

export interface ICarRentalVendor {
  id: string;
  vendorCode: string;
  vendorName: string;
  address: IPostalAddress;
  airportAccessibility: ICarRentalVendorAirportAccessibility;
  vendorLogoUrl: string;
}

// General

export enum ECancellationType {
  Free = "FREE",
  Flexible = "FLEXIBLE",
  Unavailable = "UNAVAILABLE",
}

export enum EPaymentType {
  Prepaid = "PAYMENT_TYPE_PREPAID",
  Postpaid = "PAYMENT_TYPE_POSTPAID",
  DirectBill = "PAYMENT_TYPE_DIRECT_BILL",
  Unknown = "PAYMENT_TYPE_UNKNOWN",
}

// Itinerary + trips

export interface ICarCar {
  image: string;
  type: ACRISSType;
  category: ACRISSCategory;
  variant: string;
  transmission: string;
  unlimitedKm: boolean;
  luggageCapacity: number;
  passengerCapacity: number;
}

export interface ICarRentalCompany {
  name: string;
  companyCode: string;
  logo: string;
}

export interface ICarLocation {
  name: string;
  city: string;
  country: string;
  id: string;
  state: string;
  street: string;
  pickUpType: CarRentalVendorAirportAccessType | undefined;
  contactInfo?: {
    phoneNumber: string;
    street: string;
    city: string;
    state: string;
    country: string;
  };
}

export interface ICarDetails {
  carConfirmation: string;
  car: ICarCar;
  rentalCompany: ICarRentalCompany;
  location: {
    pickUp: ICarLocation;
    dropOff: ICarLocation;
    hours?: {
      pickUp: string;
      dropOff: string;
    };
  };
  startTime: string;
  endTime: string;
}
