import { useAuth0 } from "@auth0/auth0-react";
import { useAppSelector } from "store/hooks";
import { signUpWithGoogle } from "@hotel-engine/utilities/auth/helpers";

import config from "config";

import * as Styled from "./styles";
import { Box } from "@hotelengine/atlas-web";

const SocialSignUp = () => {
  const userId = useAppSelector((store) => store.Auth?.user?.id);
  const { loginWithRedirect } = useAuth0();

  return (
    <Box marginTop={24} marginBottom={24}>
      <Styled.GoogleButton
        type="button"
        onClick={() => signUpWithGoogle(loginWithRedirect, undefined, userId)}
        data-testid="google-button"
      >
        <Styled.GoogleButtonIcon
          src={`${config.cdnHost}/assets/personal-travel/images/google-icon.svg`}
          alt=""
          width="16px"
          height="16px"
        />
        <Styled.GoogleButtonText className="buttonText">
          Sign in with Google
        </Styled.GoogleButtonText>
      </Styled.GoogleButton>
    </Box>
  );
};

export default SocialSignUp;
