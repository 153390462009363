import LogRocket from "logrocket";
import { useEffect, useRef } from "react";
import { useAppSelector } from "store/hooks";
import { useAmplitudeContext } from "@hotel-engine/hooks";
import { ampli } from "ampli";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments/FeatureFlag";

/**
 * This hook will fire an amplitude event that contains the user's LogRocket
 * session URL (which is a link to go watch the user's session recording).
 */
export function useSendLogrocketToAmplitude() {
  const userId = useAppSelector((store) => store.Auth?.user?.id);
  const { initialized: isAmplitudeInitialized } = useAmplitudeContext().amplitudeSdk;
  const isFFEnabled = useIsFeatureFlagOn("send_logrocket_to_ampli");

  // Only submit the session url to amplitude once for the logged in user, per tab.
  // When the user switches between personal and business accounts, we want to
  // submit the session url again so that it appears on that user's event stream in amplitude.
  // However, there is a brief moment where the userId has changed, but the feature flags
  // have not been reset yet. So this would cause the event to be fired twice, if not for the ref.
  const refUserIdWhenEventFiredMostRecently = useRef<number | null>(null);

  useEffect(() => {
    if (
      userId &&
      userId !== refUserIdWhenEventFiredMostRecently.current &&
      isAmplitudeInitialized &&
      isFFEnabled
    ) {
      refUserIdWhenEventFiredMostRecently.current = userId;
      /**
       * This doesn't seem to actually fetch the sessionUrl. It seems to queue up
       * the callback until Logrocket is finished starting up. So, if this were
       * called after Logrocket is already running, then it would actually be pretty instant.
       * (For instance when they switch between personal and business accounts).
       * But Logrocket initially takes 3-5 seconds to start, so this callback
       * can feel like it's taking a long time if we call it on page load.
       */
      LogRocket.getSessionURL((sessionUrl) => {
        ampli.requestLogRocketUrl({ sessionUrl });
      });
    }
  }, [userId, isAmplitudeInitialized, isFFEnabled]);

  useEffect(() => {
    if (!userId && refUserIdWhenEventFiredMostRecently.current) {
      refUserIdWhenEventFiredMostRecently.current = null;
    }
  }, [userId]);

  return null;
}
