import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments/FeatureFlag";
import { useAppSelector } from "store/hooks";
import moment from "moment";
import { useMemo } from "react";

const DEFAULT_FLEX_CUTOFF_TIME = 12;

export const useFlexCutoffTime = () => {
  const isCustomFlexCutoffTimeEnabled = useIsFeatureFlagOn("custom_flex_cutoff_time");
  const user = useAppSelector((store) => store.Auth.user);

  const memoizedFlexCutoffTime = useMemo(() => {
    const flexCutoffTime = user?.business.flexCutoffTime;
    const customFlexCutoffTime =
      isCustomFlexCutoffTimeEnabled && flexCutoffTime !== undefined
        ? flexCutoffTime
        : DEFAULT_FLEX_CUTOFF_TIME;

    let formatedFlexCutOffTime: string;

    try {
      formatedFlexCutOffTime = moment(customFlexCutoffTime, ["H"]).format("ha");
    } catch (e) {
      console.warn("Error formatting flex cutoff time", e);
      formatedFlexCutOffTime = moment(DEFAULT_FLEX_CUTOFF_TIME, ["H"]).format("ha");
    }

    return {
      flexCutoffTime,
      customFlexCutoffTime,
      formatedFlexCutOffTime,
    };
  }, [isCustomFlexCutoffTimeEnabled, user?.business.flexCutoffTime]);

  return {
    isCustomFlexCutoffTimeEnabled,
    flexCutoffTime: memoizedFlexCutoffTime.flexCutoffTime,
    customFlexCutoffTime: memoizedFlexCutoffTime.customFlexCutoffTime,
    formatedFlexCutOffTime: memoizedFlexCutoffTime.formatedFlexCutOffTime,
  };
};
