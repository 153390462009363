import { pxToRem } from "@hotel-engine/styles/helpers/mixins";
import styled from "styled-components";
import { faArrowRight, faCalendar } from "@fortawesome/pro-regular-svg-icons";

import { ExpressBookDateSelectorInput } from "./ExpressBookDatePickerInput";
import { Button } from "@hotelengine/atlas-web";
import { Icon } from "@hotel-engine/common/Icon";

export const ExpressBookDatePicker = styled(ExpressBookDateSelectorInput)`
  width: 100%;
  display: block;

  border: 1px solid #c9d2d6;
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xl};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: auto 1fr 16px 1fr auto;
    justify-items: center;
    margin-right: 0;
    align-items: center;
  }


  // DATE PICKER LIBRARY OVERRIDES
  .DateRangePicker {
    width: 100%;
  }

  .DateRangePickerInput {
    align-items: center;
    width: 100%;
    justify-items: center;
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xl};

    display: grid;
    grid-template-columns: auto 1fr auto 1fr auto;

    .DateInput {
      background: transparent;

      .DateInput_input {
        background: transparent;
      }j
    }
  }

  .DateRangePicker_picker {
    top: 20px !important;
    left: -44px !important;
    transform: scale(0.85);
  }

  .DateInput_fang {
    display: none !important;
  }
`;

export const ExpressBookDatePickerContainer = styled.div`
  height: 44px;
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xl};
  border: solid 1px #c9d2d6;
  margin: 0 ${pxToRem(24)} ${pxToRem(16)};
  padding: 0 ${pxToRem(16)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin: 0 ${pxToRem(16)} ${pxToRem(16)};
  }
`;

export const ExpressBookInteractiveDatePickerContainer = styled.div`
  height: 44px;

  margin: 0 ${pxToRem(24)} ${pxToRem(16)};

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 0 ${pxToRem(16)} ${pxToRem(16)};
  }
`;

export const UpdateSearchBtn = styled(Button).attrs(() => ({
  size: "md",
  variant: "filled",
}))`
  && {
    min-width: 147px !important;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      display: none !important;
    }
  }
`;

export const CalendarIcon = styled(Icon).attrs(() => ({
  icon: faCalendar,
  size: "lg",
}))`
  margin-right: 8px;
`;
export const RightArrowIcon = styled(Icon).attrs(() => ({
  icon: faArrowRight,
}))``;
