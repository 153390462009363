import styled from "styled-components";

import { colors, ellipsisText } from "@hotel-engine/styles";
import Image from "@hotel-engine/common/Image";

interface IIconTextBlock {
  type: string;
}
export const IconTextBlock = styled.span<IIconTextBlock>`
  color: ${({ type }) => (type === "loyalty" ? colors.purple[300] : colors.green[600])};
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 0 1rem;
  max-width: 100%;
  flex-wrap: wrap;
`;

export const LoyaltyText = styled.span`
  ${ellipsisText};
  margin-left: 5px;
`;

export const RefundableText = styled.span`
  ${ellipsisText};
  margin-left: 6px;
`;

export const FlexProLogoContainer = styled.div``;

export const FlexProLogo = styled(Image)`
  width: 83px;
`;
