import Airplane from "./Airplane";
import Hotel from "./Hotel";
import MapMarker from "./MapMarker";
import Star from "./Star";

export interface IHEIconProps {
  type: "airplane" | "hotel" | "map-marker" | "star";
}

export interface IBaseHEIconProps {
  height?: string;
  width?: string;
  stroke?: string;
  fill?: string;
  fill2?: string;
  fill3?: string;
  style?: Record<string, unknown>;
  ariaHidden?: boolean;
}

export const HEIcon = ({ type }: IHEIconProps) => {
  const iconMap = {
    airplane: <Airplane />,
    hotel: <Hotel />,
    "map-marker": <MapMarker />,
    star: () => <Star />,
  };

  return <>{iconMap[type] || null}</>;
};

export default HEIcon;
