import { lazy } from "react";
import { Navigate, Route, Routes } from "@hotel-engine/lib/react-router-dom";

import { routes } from "@hotel-engine/constants";

const ConfirmationPage = lazy(
  () => import(/* webpackChunkName: "Other" */ "./components/ConfirmationPage")
);
const ExistingRateFormPage = lazy(
  () => import(/* webpackChunkName: "Other" */ "./pages/ExistingRateForm")
);
const MarketingPage = lazy(
  () => import(/* webpackChunkName: "Other" */ "./components/MarketingPage")
);
const NewRateForm = lazy(() => import(/* webpackChunkName: "Other" */ "./pages/NewRateForm"));
const RateTypePage = lazy(() => import(/* webpackChunkName: "Other" */ "./pages/RateType"));

export const RatePartnering = () => {
  return (
    <Routes>
      <Route index element={<MarketingPage />} />
      <Route path="form/*" element={<RateTypePage />} />
      <Route path="new-rate/*" element={<NewRateForm />} />
      <Route path="existing-rate/*" element={<ExistingRateFormPage />} />
      <Route path="confirmation/*" element={<ConfirmationPage />} />

      <Route path="*" element={<Navigate replace to={routes.ratePartnering.base} />} />
    </Routes>
  );
};
