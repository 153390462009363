import { useEffect } from "react";

import { Field, FieldArray, useFormikContext } from "formik";
import { Typography } from "@hotelengine/atlas-web";
import { CustomFieldInput } from "@hotel-engine/app/CustomFieldsForm/components/CustomFieldInput";
import InputField from "@hotel-engine/common/FormikFields/InputField";
import CheckboxField from "@hotel-engine/common/FormikFields/CheckboxField";
import { useAppSelector } from "store/hooks";

import { DirectBillVerification } from "pages/Checkout/LegacyLodging/DirectBillVerification";
import type { ICheckoutFormData } from "pages/Checkout/validation";
import type { ICustomField } from "@hotel-engine/types/customField";

import * as Styled from "./styles";

type ExpressBookTripInformationProps = {
  customFields: ICustomField[];
};

export const ExpressBookTripInformation = ({ customFields }: ExpressBookTripInformationProps) => {
  const user = useAppSelector((store) => store.Auth.user);
  const { values, errors, setFieldValue } = useFormikContext<ICheckoutFormData>();

  const isDirectBillVerificationRequired = Boolean(
    values.selectedPaymentId === 0 && user?.business?.directBillVerificationRequired
  );

  const showIsBillable = Boolean(user?.business.showIsBillable);
  const hasCustomFields = values.customFields?.length > 0;

  const handleCustomValue = (index: number, val: string) => {
    setFieldValue(`customFields.${index}.value`, val, false);
  };

  useEffect(() => {
    if (customFields?.length > 0) {
      setFieldValue(
        "customFields",
        customFields.map((field, index) => {
          let defaultValue: string | undefined = "";

          if (field.value) {
            defaultValue = field.value;
          } else if (field.options) {
            defaultValue = undefined;
          }

          const existingValue = values?.customFields?.[index]?.value;

          return {
            helpText: field.helpText,
            id: field.id,
            name: field.name,
            options: field.options,
            required: field.required,
            value: existingValue ?? defaultValue,
            error: null,
            testId: `customFields-${index}`,
            minTextLength: field.minTextLength,
            maxTextLength: field.maxTextLength,
          };
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFields]);

  useEffect(() => {
    if (user && isDirectBillVerificationRequired) {
      setFieldValue("directBillVerificationRequired", true);

      if (values.directBillCode) {
        setFieldValue("directBillCode", values.directBillCode);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirectBillVerificationRequired, setFieldValue, user]);

  if (!hasCustomFields && !isDirectBillVerificationRequired && !showIsBillable) {
    return null;
  }

  return (
    <Styled.Card>
      <Typography variant="heading/md">Trip Information</Typography>

      {!!hasCustomFields && (
        <FieldArray name="customFields" data-testid="customFields">
          {() => (
            <>
              {values.customFields.map((customField, index) => {
                const { id, name, options, helpText } = customField;
                const isSelectDropdown = Boolean(customField.options?.length);

                if (isSelectDropdown) {
                  return (
                    <Field
                      key={id}
                      label={name}
                      component={CustomFieldInput}
                      name={`customFields.${index}.value`}
                      options={options?.sort((a, b) => a.localeCompare(b))}
                      onChange={(val) => handleCustomValue(index, val)}
                      placeholder={helpText || `Select ${name}`}
                      required={customField.required}
                      data-testid={customField.testId}
                    />
                  );
                } else {
                  return (
                    <Field
                      key={id}
                      label={name}
                      component={InputField}
                      name={`customFields.${index}.value`}
                      placeholder={helpText || `Enter ${name}`}
                      required={customField.required}
                      data-testid={customField.testId}
                    />
                  );
                }
              })}
            </>
          )}
        </FieldArray>
      )}

      {!!isDirectBillVerificationRequired && (
        <DirectBillVerification
          directBillCode={values.directBillCode}
          onChange={(value) => setFieldValue("directBillCode", value)}
          submitAttempts={errors?.directBillCode?.length}
          labelText="Direct Bill code"
          placeholderText="Enter Direct Bill code"
        />
      )}

      {!!showIsBillable && (
        <Field
          label="Is this trip billable?"
          name="isBillable"
          component={(props) => (
            <Styled.CheckboxWrapper>
              <CheckboxField {...props} />
            </Styled.CheckboxWrapper>
          )}
        />
      )}
    </Styled.Card>
  );
};
