import styled from "styled-components";

import type { TypographyVariant } from "./styles";
import { getTypeStylesForVariant } from "./styles";

export type ITypographyProps = {
  variant: TypographyVariant;
  isResponsive?: boolean;
  margin?: string;
  mT?: string;
  mR?: string;
  mB?: string;
  mL?: string;
};

/**
 * @deprecated Please utilize the new `Typography` component found in the `@hotelengine/atlas-web` package.
 * @see {@link [Design Guide for more details](https://atlas-design-system.supernova-docs.io/latest/guides/migration-guides/typography-migration-guide-Amp7Gns2)}
 * @example
 * OLD: xxxlarge | NEW: heading/3xl
 * OLD: xxlarge | NEW: heading/2xl
 * OLD: xlarge | NEW: heading/xl
 * OLD: large | NEW: heading/lg
 * OLD: medium | NEW: heading/md
 * OLD: small | NEW: heading/sm
 * OLD: xsmall | NEW: heading/xs
 * OLD: body-large | NEW: body/md
 * OLD: body-medium | NEW: body/sm
 * OLD: body-small | NEW: body/xs
 *

 * The `Typography` is built on the Box component and can be utilized to render one of our standard type styles.
 * Responsiveness is baked into (some) of the type styles by default, but those can be overriden
 *
 *
 * @param variant - the type styles that will be applied inside of the component. {@link TypographyVariant TypographyVariant documentation}
 * @param isResponsive - if true the type will have mobile & desktop styles if applicable. defaults to true
 * @param as - can change the typography element tag to the given tag name. defaults to span
 *
 * @remarks You can use the styled component `as` prop to change the html element
 * @see {@link https://www.figma.com/file/te9lrwlyjuSXOEqn6C6Yki/Foundation-Update?node-id=2522%3A2156 Design Specs}
 */
const Typography = styled.span.withConfig({
  shouldForwardProp: (prop) => {
    return !["variant", "isResponsive", "margin", "mT", "mR", "mB", "mL"].includes(prop as string);
  },
})<ITypographyProps>`
  ${getTypeStylesForVariant};
  margin: ${({ margin }) => margin};
  margin-bottom: ${({ mB }) => mB};
  margin-left: ${({ mL }) => mL};
  margin-right: ${({ mR }) => mR};
  margin-top: ${({ mT }) => mT};
`;

/** @component */
export default Typography;
