import styled from "styled-components";

import { Icon } from "@hotel-engine/common/Icon";
import { buttonReset, colors } from "@hotel-engine/styles";

export const PolicyItem = styled.div`
  background: ${colors.grey[50]};
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  button {
    ${buttonReset}
    display: block;
    text-align: left;
    width: 100%;
    padding: 14px 20px;
    transition: 0.3s ease all;
    &[aria-expanded="false"] {
    }
    &:not(:hover):focus,
    &:not(:focus):hover {
      background: ${colors.grey[100]};
    }
    span {
      margin-left: 20px;
    }
  }
`;

export const PolicyItemContent = styled.div`
  transition: all 0.25s ease-in-out;
  overflow: hidden;
  padding: 0 20px;
  &[aria-hidden="true"] {
    max-height: 0;
  }
  &[aria-hidden="false"] {
    max-height: 100%;
    padding: 7px 20px 14px;
  }
`;

export const PolicyItemIcon = styled(Icon)<{ $isOpen: boolean }>`
  transform: ${({ $isOpen }) => ($isOpen ? `rotate(90deg)` : `rotate(0deg)`)};
  transition: transform 0.15s ease-in;
`;
