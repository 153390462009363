// This component should replace InputField from '@hotel-engine/common'.
// Will be done in a different PR since that component is being used in multiple places.
import type { HTMLAttributes } from "react";

import { useField } from "formik";

import { FormItem } from "@hotel-engine/common/Form";
import { Input } from "@hotel-engine/common/Inputs";

interface IInputFieldProps {
  /** form placeholder name */
  label: string;
  /** determines if form label has colon */
  colon?: boolean;
  /** field placeholder name */
  placeholder: string;
  /** formik field name */
  name: string;
  /** boolean if field is required or not */
  required?: boolean;
  /** boolean if form has been submitted or not */
  submitted?: boolean;
  /** input size */
  size?: "small" | "default" | "large" | undefined;
  /** html input type */
  inputMode?: HTMLAttributes<HTMLLIElement>["inputMode"];
  /** customized styling */
  className?: string;
  /** triggers an amplitude event on blur via an anonymous function passed from the parent component */
  triggerAmplitudeEvent?: () => void;
}

const InputField = ({
  label,
  colon,
  placeholder,
  required,
  submitted = false,
  size = "large",
  className,
  inputMode = "text",
  triggerAmplitudeEvent,
  ...props
}: IInputFieldProps) => {
  const [field, meta] = useField<string | number | undefined>(props);

  const validated = submitted || meta.touched;
  const errorMessage = meta.error && validated ? meta.error : "";
  const validateStatus = errorMessage.length > 0 ? "error" : undefined;

  return (
    <FormItem
      className={className || ""}
      label={label}
      colon={colon}
      help={errorMessage}
      validateStatus={validateStatus}
      required={required}
      htmlFor={field.name}
    >
      <Input
        {...field}
        style={{ fontSize: "16px" }}
        id={field.name}
        value={field.value || ""}
        placeholder={placeholder}
        size={size}
        inputMode={inputMode}
        onBlur={triggerAmplitudeEvent}
      />
    </FormItem>
  );
};

export default InputField;
