import { useCheckSessionExpirationInterval } from "./useCheckSessionExpirationInterval";
import { useAuth0 } from "@auth0/auth0-react";

export function CheckSessionInterval() {
  const { isAuthenticated, isLoading } = useAuth0();
  // if the user is authenticated via auth0 or the app is still loading, we don't need to check the session using the legacy method
  if (isLoading || isAuthenticated) return null;
  return <CheckSession />;
}
function CheckSession() {
  useCheckSessionExpirationInterval();
  return null;
}
