/*
 eslint-disable
 @typescript-eslint/ban-types,
 @typescript-eslint/naming-convention,
 @typescript-eslint/no-empty-interface,
 @typescript-eslint/no-explicit-any,
 @typescript-eslint/no-namespace,
 @typescript-eslint/no-shadow,
*/
export { empty }

namespace empty {
  export const string = "" as const
  export const array = [] as const
}
declare namespace empty {
  export { String as string }
  type String<type = ""> = type & typeof empty.string
  export type array<type extends typeof empty.array = typeof empty.array> = type
}
