import styled, { css } from "styled-components";
import { Button as AtlasButton, Icon } from "@hotelengine/atlas-web";

export const IconAdornment = styled(Icon)`
  color: ${({ theme }) => theme.colors.foregroundInverse};
`;

export const Pill = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundPrimary};
    border-radius: ${theme.borderRadius.pill};
    padding: 0px ${theme.spacing[6]};
    font-weight: 600;
  `};
`;

export const Button = styled(AtlasButton)`
  padding: 2px 8px;
  max-width: 60px;
  color: ${({ theme }) => theme.colors.foregroundInverse};

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing[8]};
  }
`;
