import { parse, stringify } from "qs";
import { useCallback, useMemo } from "react";
import { useNavigate, useLocation } from "@hotel-engine/lib/react-router-dom";
import type { ISearchQueryParams } from "@hotel-engine/types/search";
import { routes } from "../../constants";
import { searchResultsActions } from "store/Search/SearchResults";

export const useSearchQueryParams = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const queryParams = useMemo(() => {
    const parsedQueryParams = parse(search, {
      ignoreQueryPrefix: true,
      decoder: (
        str: string,
        defaultDecoder: qs.defaultDecoder,
        charset: string,
        type: "key" | "value"
      ) => {
        const keywords = {
          true: true,
          false: false,
          null: null,
          undefined: undefined,
        };
        if (type === "value" && str in keywords) {
          return keywords[str];
        }

        return defaultDecoder(str, defaultDecoder, charset);
      },
    });
    ["baseSearchId", "guestCount", "radius", "roomCount"].forEach((numberKey) => {
      const numberValue = parsedQueryParams[numberKey];
      if (numberKey in parsedQueryParams && typeof numberValue === "string") {
        parsedQueryParams[numberKey] = parseFloat(numberValue);
      }
    });
    return parsedQueryParams;
  }, [search]) as unknown as ISearchQueryParams;

  const setQueryParams = useCallback(
    (newQueryParams: ISearchQueryParams) => {
      if (!newQueryParams.baseSearchId) {
        searchResultsActions.toggleIsMapSearch(false);
      }
      navigate({
        pathname: `${routes.search}`,
        search: stringify(newQueryParams),
      });
    },
    [navigate]
  );

  return {
    queryParams,
    setQueryParams,
  };
};
