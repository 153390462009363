import { useFormikContext } from "formik";

import Avatar from "@hotel-engine/common/Avatar";
import { Icon } from "@hotel-engine/common/Icon";
import { colors } from "@hotel-engine/styles";
import type { ICheckoutFormData } from "pages/Checkout/validation";

export const Prefix = ({
  guestType,
  index,
}: {
  guestType: "primary" | "secondary";
  index: number;
}) => {
  const form = useFormikContext<ICheckoutFormData>();

  let prefix = <Icon icon={["fas", "user-plus"]} />;
  const selectedUser = form.values.guestList[index][guestType].user;
  if (selectedUser) {
    const { firstName, lastName, email, businessId } = selectedUser;
    const name = !!firstName && !!lastName ? `${firstName} ${lastName}` : email;
    prefix = businessId ? (
      <Avatar backgroundColor={colors.black} fontColor={colors.white} size="small" name={name} />
    ) : (
      <Avatar
        backgroundColor={colors.white}
        fontColor={colors.black}
        size="small"
        name={name}
        border={`1px dashed ${colors.black}`}
      />
    );
  }
  return prefix;
};
