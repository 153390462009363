import type { ICurrency } from "@hotel-engine/constants";
import type {
  IExpressBookCheckoutForm,
  IExpressBookContractRate,
} from "@hotel-engine/types/expressBook";
import type { IUser } from "@hotel-engine/types/user";
import { formatCurrency } from "@hotel-engine/utilities";

export const generatePricingCalculation = (
  currencyCode: ICurrency,
  formValues: IExpressBookCheckoutForm,
  contractRate: IExpressBookContractRate,
  user: IUser | null
) => {
  const isFlexProEnrolled = user?.business.flexProEnrolled || false;

  const totalCost = +(
    (formValues.isFlexEnabled ? contractRate.flexCancellationCost : 0) +
    (formValues.isFlexEnabled && isFlexProEnrolled ? -contractRate.flexCancellationCost : 0) +
    (formValues.includeIncidentalCharges ? contractRate.totalWithIncidentals : contractRate.total)
  );

  const totalDue = +totalCost.toFixed(2);

  const formattedTotal = formatCurrency(totalDue, true, currencyCode);

  return formattedTotal;
};
