import styled, { createGlobalStyle } from "styled-components";

import { zIndex } from "@hotel-engine/styles";

// required to display bottom sheet on top of the Complete Booking button
export const GlobalBottomSheetStyles = createGlobalStyle`
  [data-rsbs-overlay] {
    ${zIndex.modal}
  }
`;

export const BottomSheetContentContainer = styled.div`
  padding: 16px;
  margin-bottom: 16px;
`;
