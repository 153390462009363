import styled from "styled-components";
import { pxToRem } from "@hotel-engine/styles/helpers/mixins";

export const ExpressBookBaseStateFooter = styled.div`
  border-top: ${({ theme }) => `1px solid ${theme.legacy.colors.grey[300]}`};

  background: ${({ theme }) => theme.colors.backgroundPrimary};

  padding: ${pxToRem(16)} ${pxToRem(24)};

  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    font-family: ${({ theme }) => theme.legacy.fontFamily.primary};
    font-size: ${({ theme }) => theme.legacy.fontSize.xs};
    line-height: 150%;
    color: ${({ theme }) => theme.legacy.colors.grey[500]};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    position: fixed;
    bottom: 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    position: fixed;
    bottom: 0;
    padding: ${pxToRem(16)};
  }
`;

export const ExpressBookFooterBaseStateButtonContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: space-between;

  button.ant-btn-default {
    min-width: 170px;
  }

  button.ant-btn-primary {
    flex: 1;
  }

  button {
    height: 40px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    button.ant-btn-default {
      display: none;
    }

    button.ant-btn-primary {
      height: 50px;
    }
  }
`;
