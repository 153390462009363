import config from "config";

import Api from "./Api";
import Resource from "./Resource";

/**
 * @deprecated - switch to the associated react-query hooks if possible
 */
export default class Guest extends Resource {
  public static _basePath = `${config.apiHostV2}/guests`;

  // Duplicate of the constructor from ReadOnlyResource;
  // The context of "this" got broken in the upgrade to CRA,
  // so calling it explicitly here as a hack
  constructor(params?) {
    super(params);
    Object.assign(this, params);
  }

  get displayName() {
    this._displayName = `${this.firstName} ${this.lastName}`;

    return this._displayName;
  }

  public static async query(params: { [k: string]: unknown } | null = null) {
    const response = await Api.get(this._basePath, params);

    return {
      ...response,
      results: response.results.map((i) => this._makeInstance(i)),
    };
  }

  public async save() {
    if (this.id) {
      const response = await Api.put(`${Guest._basePath}/${this.id}`, {
        guest: { ...this },
      });
      return Object.assign(this, response);
    }

    const response = await Api.post(Guest._basePath, { guest: { ...this } });
    return new Guest(response);
  }

  public email!: string;
  public firstName!: string;
  public lastName!: string;
  public phone!: string;

  private _displayName!: string;
}
