import type { EInclusion } from "@hotel-engine/types/cars/cars.shared";
import { ECancellationType, EPaymentType } from "@hotel-engine/types/cars/cars.shared";

export const CancellationPolicyText = {
  [ECancellationType.Free]: "Good news! Cancellation is free of charge any time until pick-up",
  [ECancellationType.Unavailable]:
    "This reservation is non-refundable, non-transferable and non-changeable even if it is not used.",
  [ECancellationType.Flexible]: "",
};

export const InclusionLabels: { [x in EInclusion]: string } = {
  freeCancellation: "Free cancellation",
  flexibleCancellation: "Flexible cancellation",
  mileage: "Unlimited mileage",
};

export const PaymentTypeLabel = {
  [EPaymentType.Postpaid]: "Pay at pick-up",
  [EPaymentType.Prepaid]: "Pay now",
  [EPaymentType.DirectBill]: "Direct bill",
};

export const CancellationTypeLabel = {
  [ECancellationType.Free]: "Free cancellation",
  [ECancellationType.Flexible]: "Flexible Cancellation",
  [ECancellationType.Unavailable]: "Non-refundable",
};
