import type { FormikProps } from "formik";

import { colors } from "@hotel-engine/styles";
import type { IconProps } from "@hotelengine/atlas-web";

export type IFeedbackForm = FormikProps<{
  satisfaction: RatingValues;
  multiline: string;
}>;

export type RatingValues =
  | "veryDissatisfied"
  | "dissatisfied"
  | "neutral"
  | "satisfied"
  | "verySatisfied"
  | null;

export type RatingOptions =
  | "Very Dissatisfied"
  | "Dissatisfied"
  | "Neutral"
  | "Satisfied"
  | "Very Satisfied"
  | null;

export type RatingCallouts = "Wow!" | "Yay!" | "Hmm." | "Oh no!" | "Eek!" | "";

export interface IRating {
  value: RatingValues;
  rating: RatingOptions;
  icon: IconProps["name"];
  color: string;
}

export const ratingOptions: IRating[] = [
  {
    value: "veryDissatisfied",
    rating: "Very Dissatisfied",
    icon: "face-tired",
    color: colors.red[400],
  },
  {
    value: "dissatisfied",
    rating: "Dissatisfied",
    icon: "face-frown-slight",
    color: colors.orange[400],
  },
  {
    value: "neutral",
    rating: "Neutral",
    icon: "face-meh",
    color: colors.gold[400],
  },
  {
    value: "satisfied",
    rating: "Satisfied",
    icon: "face-smile",
    color: colors.green[400],
  },
  {
    value: "verySatisfied",
    rating: "Very Satisfied",
    icon: "face-grin-stars",
    color: colors.aqua[500],
  },
];

export const calloutsMap = {
  veryDissatisfied: "Eek!",
  dissatisfied: "Oh no!",
  neutral: "Hmm.",
  satisfied: "Yay!",
  verySatisfied: "Wow!",
};

export const getNumericRating = (rating: RatingValues | undefined) => {
  const ratingMap = {
    veryDissatisfied: 1,
    dissatisfied: 2,
    neutral: 3,
    satisfied: 4,
    verySatisfied: 5,
  };

  return rating ? ratingMap[rating] : undefined;
};
