import type { ISupportSpecialRequest } from "@hotel-engine/types/supportCase";
import * as Styled from "../../styles";
import { Typography } from "@hotelengine/atlas-web";

export interface ISupportSpecialRequestProps {
  details: ISupportSpecialRequest;
}

function SpecialRequest({ details }: ISupportSpecialRequestProps) {
  const {
    adjacentRooms,
    connectingRooms,
    earlyCheckIn,
    floorRequest,
    lateCheckIn,
    lateCheckOut,
    other,
    petFriendly,
    rollawayBed,
    upgradeRequest,
  } = details;

  const isFloorRequestString = typeof floorRequest === "string";

  return (
    <>
      {!!adjacentRooms && (
        <>
          <Styled.FieldDivider />
          <Styled.Row>
            <Typography variant="body/sm">Adjacent Rooms</Typography>
          </Styled.Row>
        </>
      )}
      {!!connectingRooms && (
        <>
          <Styled.FieldDivider />
          <Styled.Row>
            <Typography variant="body/sm">Connecting Rooms</Typography>
          </Styled.Row>
        </>
      )}
      {!!earlyCheckIn && (
        <>
          <Styled.FieldDivider />
          <Styled.Row>
            <Typography variant="body/sm">Early Check-In</Typography>
          </Styled.Row>
        </>
      )}
      {!!floorRequest && (
        <>
          <Styled.FieldDivider />
          <Styled.Row>
            <Typography variant="body/sm">Floor Request</Typography>
          </Styled.Row>
          {!!isFloorRequestString && (
            <Styled.Row>
              <Typography variant="body/md">Floor Number:</Typography>
              <Typography variant="body/md">{floorRequest}</Typography>
            </Styled.Row>
          )}
        </>
      )}
      {!!lateCheckIn && (
        <>
          <Styled.FieldDivider />
          <Styled.Row>
            <Typography variant="body/sm">Late Check-In</Typography>
          </Styled.Row>
        </>
      )}
      {!!lateCheckOut && (
        <>
          <Styled.FieldDivider />
          <Styled.Row>
            <Typography variant="body/sm">Late Check-Out</Typography>
          </Styled.Row>
        </>
      )}
      {!!other && (
        <>
          <Styled.FieldDivider />
          <Styled.Row>
            <Typography variant="body/md">Request Details:</Typography>
          </Styled.Row>
          <Styled.Row>
            <Typography variant="body/md">"{other}"</Typography>
          </Styled.Row>
        </>
      )}
      {!!petFriendly && (
        <>
          <Styled.FieldDivider />
          <Styled.Row>
            <Typography variant="body/sm">Pet Friendly</Typography>
          </Styled.Row>
        </>
      )}
      {!!rollawayBed && (
        <>
          <Styled.FieldDivider />
          <Styled.Row>
            <Typography variant="body/sm">Rollaway Bed</Typography>
          </Styled.Row>
        </>
      )}
      {!!upgradeRequest && (
        <>
          <Styled.FieldDivider />
          <Styled.Row>
            <Typography variant="body/sm">Upgrade Request</Typography>
          </Styled.Row>
        </>
      )}
    </>
  );
}

export default SpecialRequest;
