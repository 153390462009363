import styled from "styled-components";

import Button from "@hotel-engine/common/Button";
import { Icon } from "@hotel-engine/common/Icon";
import Typography from "@hotel-engine/common/Typography";
import { buttonReset, colors } from "@hotel-engine/styles";

export const RemoveGuest = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0 8px;
`;

export const AdditionalGuestButton = styled(Button)`
  ${buttonReset};
  padding: 0 10px;
  color: ${colors.retroBlue};
`;
export const AdditionalGuestButtonIcon = styled(Icon)`
  margin-right: 10px;
  color: ${colors.retroBlue};
`;

export const RoomTypography = styled(Typography)<{
  $color?: string;
  $margin?: string;
}>`
  ${({ $color }) => $color && `color: ${$color};`}
  ${({ $margin }) => $margin && `margin: ${$margin};`}
`;
