import { Modal as antdModal } from "antd";
import styled, { createGlobalStyle, css } from "styled-components";

const modalStyles = css``;

export const Modal = styled(antdModal)`
  ${modalStyles};
`;

export const GlobalModalStyles = createGlobalStyle`
  ${modalStyles}
`;
