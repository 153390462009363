import type { UseMutationOptions } from "react-query";
import { useMutation } from "react-query";

import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { ISurveyAnswer } from "@hotel-engine/types/survey";

import { endpoints } from "../constants";
import { useApi } from "../useApi";

export const useCreateSurvey = (
  options?: UseMutationOptions<ISurveyAnswer, IErrorResponse, ISurveyAnswer>
) => {
  const post = useApi("post");

  return useMutation((payload) => post(endpoints.surveySubmissions, payload), options);
};
