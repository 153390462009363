import styled from "styled-components";

import Button from "@hotel-engine/common/Button";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { colors, sizes } from "@hotel-engine/styles";

export const VerificationContentsWrapper = styled.main`
  padding: 24px;
  h1 {
    ${typographyMixin("large")};
    color: ${colors.errieBlack};
  }
  p {
    ${typographyMixin("body-medium")};
    color: ${colors.errieBlack};
  }
`;

export const VerificationInstructions = styled.section`
  padding: 12px 0 6px;
  @media screen and (min-width: ${sizes.breakpoints.md}) {
    padding: 24px 0 12px;
  }
`;

export const VerificationForm = styled.section`
  padding: 6px 0 0;
  @media screen and (min-width: ${sizes.breakpoints.md}) {
    padding: 12px 0 0;
  }
`;
export const SubmitButton = styled(Button)`
  width: 100%;
  margin: 48px 0 0;
`;
