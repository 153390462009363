import type { IUser } from "@hotel-engine/types/user";
import config from "config";

export const texasOrg = config.stage === "production" ? 273 : 260;

const isStateOfTexasUser = (user?: IUser | null, orgId?: number) => {
  if (user) return user.business?.organizationId === texasOrg;
  return orgId === texasOrg;
};

export default isStateOfTexasUser;
