import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogIcon,
  DialogTrigger,
} from "@hotelengine/atlas-web";
import { captureMessage } from "@hotel-engine/utilities/logger";
import config from "config";
import { MFA_AUDIENCE, MFA_REDIRECT_URI, MFA_SCOPES } from ".";
import { type AuthenticatorKind } from "./authenticator";
import { isEmail } from "./AuthenticatorCard";

const AuthenticatorEditButton = ({
  kind,
  id,
  hasValue,
  setHasErrors,
}: {
  kind: AuthenticatorKind;
  id?: string;
  hasValue: boolean;
  setHasErrors: (boolean) => void;
}) => {
  const { loginWithRedirect, getAccessTokenSilently } = useAuth0();

  const deleteMfaMode = async () => {
    const url = new URL(`https://${config.auth0Tenant}.us.auth0.com`);
    url.pathname = `/mfa/authenticators/${id}`;
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: MFA_AUDIENCE,
        scopes: MFA_SCOPES,
      },
    });
    let response;
    try {
      response = await fetch(url, {
        method: "DELETE",
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      captureMessage(`Error deleting an authenticator in the Account Security tab`, {
        error,
      });
      setHasErrors(true);
    }
    return response;
  };

  const editMfa = async () => {
    if (id) {
      const response = await deleteMfaMode();
      if (!response?.ok) {
        const error = await response.text();
        captureMessage(`Error deleting an authenticator in the Account Security tab`, {
          error,
        });
        setHasErrors(true);
      }
    }
    await loginWithRedirect({
      authorizationParams: {
        redirect_uri: MFA_REDIRECT_URI,
        audience: MFA_AUDIENCE,
        editMfaFactor: kind,
      },
    });
  };
  return (
    <>
      {isEmail(kind) ? null : (
        <Dialog>
          <DialogTrigger>
            <Button color="secondary" variant="outlined" size="md">
              {hasValue ? "Edit" : "Add"}
            </Button>
          </DialogTrigger>
          <DialogContent data-testid="popover-content">
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <DialogIcon sentiment="cautionary" />
              <DialogDescription
                style={{ marginBottom: "8px", marginTop: "16px", textAlign: "center" }}
              >
                To edit this phone number, your current saved number will be removed from your
                account with no way to undo. You will be asked to enter a new phone number to
                protect your account.
              </DialogDescription>
            </Box>
            <Box
              as="footer"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              marginTop={16}
            >
              <DialogClose>
                <Button color="secondary" variant="outlined" style={{ flex: 1 }}>
                  Cancel
                </Button>
              </DialogClose>
              <DialogClose>
                <Button onClick={editMfa} style={{ flex: 1, marginLeft: 16 }}>
                  Continue to edit
                </Button>
              </DialogClose>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export { AuthenticatorEditButton };
