import moment from "moment";
import { useSearchQueryParams } from "./search/useSearchQueryParams";

/**
 * Checks if current query is for a Single Day Same Day Booking
 */
export const useIsSingleDaySameDayBooking = () => {
  const { checkIn, checkOut } = useSearchQueryParams().queryParams;

  const numberOfNights = moment(checkOut, "MM-DD-YYYY").diff(moment(checkIn, "MM-DD-YYYY"), "days");

  const isSameDayAndSingleNightBooking =
    moment(checkIn, "MM-DD-YYYY").isSame(moment(), "day") && numberOfNights === 1;

  return Boolean(isSameDayAndSingleNightBooking);
};
