import type { UseQueryOptions } from "react-query";

import type { IError } from "@hotel-engine/types/errors";
import type { IPropertyReviewInfo } from "@hotel-engine/types/property";

import { useApi } from "../useApi";
import { useExtendedQuery } from "../useExtendedQuery";
import { endpoints } from "../constants";

/**
 * property review information query
 *
 * @params 'id' - the id of the property review
 *
 * @returns {@link IPropertyReviewInfo Property Review Information}
 */
export const usePropertyReviewInfoQuery = (
  id: string,
  options?: UseQueryOptions<IPropertyReviewInfo, IError, IPropertyReviewInfo>
) => {
  const get = useApi("get");

  return useExtendedQuery<IPropertyReviewInfo, IError>(
    [endpoints.invoices, id],
    () => get<IPropertyReviewInfo>(`${endpoints.propertyReviewInfo}/${id}`),
    options
  );
};
