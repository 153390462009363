import type { ISupportBillingInquiry } from "@hotel-engine/types/supportCase";
import * as Styled from "../../styles";
import { Typography } from "@hotelengine/atlas-web";
import { formatCurrency } from "@hotel-engine/utilities";
import { useAppSelector } from "store/hooks";
import type { ISupportAlertBannerStatus } from "../../types";

export interface ISupportBillingInquiryProps {
  details: ISupportBillingInquiry;
  bannerStatus: ISupportAlertBannerStatus;
}

function BillingInquiry({ details, bannerStatus }: ISupportBillingInquiryProps) {
  const user = useAppSelector((state) => state.Auth.user);
  const { billingInquiryAmount, refundedAmount, refundedTo } = details;
  const currencyCode = user?.business?.currencyCode || "USD";
  const billingInquiryAmountNumber = Number(billingInquiryAmount);
  return (
    <>
      {!!billingInquiryAmount && billingInquiryAmountNumber !== 0 && (
        <>
          <Styled.Row>
            <Typography variant="body/md">Billing Inquiry Amount:</Typography>
            <Typography variant="body/md">
              {formatCurrency(billingInquiryAmount || 0, true, currencyCode)}
            </Typography>
          </Styled.Row>
        </>
      )}
      {bannerStatus === "positive" && !!refundedTo && (
        <>
          <Styled.Row>
            <Typography variant="body/md">Refunded:</Typography>
            <Typography variant="body/md">
              -{formatCurrency(refundedAmount || 0, true, currencyCode)}
            </Typography>
          </Styled.Row>
          <Styled.Row>
            <Typography variant="body/md">Refunded to:</Typography>
            <Typography variant="body/md">{refundedTo}</Typography>
          </Styled.Row>
        </>
      )}
    </>
  );
}

export default BillingInquiry;
