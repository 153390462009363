import { useEffect, useMemo } from "react";

export interface ILiveRegionOptions {
  /**
   * If true, the role of the live region will be an alert. Otherwise, the role will (by default) be status.
   *
   * Assertive live regions should be used sparingly due to their intrusive nature.
   */
  isAssertive?: boolean;
}

const createElement = ({ isAssertive }: ILiveRegionOptions) => {
  const region = document.createElement("span");
  region.setAttribute("role", isAssertive ? "alert" : "status");

  //ensure the element cannot be seen
  Object.assign(region.style, {
    border: "0px",
    clip: "rect(0px, 0px, 0px, 0px)",
    height: "1px",
    width: "1px",
    margin: "-1px",
    padding: "0px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    position: "absolute",
  });

  return region;
};

/**
 * Used to communicate information to screen readers dynamically via `speak`
 *
 * @param options if needed, the live region can be set to be assertive
 *
 * @example
 * ```
 * import { useLiveRegion } from "@hotel-engine/hooks"
 *
 * const TestComponent = () => {
 *   const liveRegion = useLiveRegion();
 *
 *   return (
 *     <button onClick={() => liveRegion.speak("Data saved")}>
 *       Save Data
 *     </button>
 *   );
 * };
 * ```
 */
export function useLiveRegion(options?: ILiveRegionOptions) {
  const region = useMemo(
    () => createElement({ isAssertive: options?.isAssertive }),
    [options?.isAssertive]
  );

  useEffect(() => {
    document.body.appendChild(region);
    return () => {
      region?.parentNode?.removeChild(region);
    };
  }, [region]);

  return {
    speak: (message: string) => {
      if (region) {
        region.innerText = "";
        region.innerText = message;
      }
    },
  };
}
