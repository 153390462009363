import { useEffect, useState } from "react";

export interface IDimensions {
  desktop: boolean;
  height: number;
  isMobileDevice: boolean;
  mobile: boolean;
  mobileSmall: boolean;
  retina: boolean;
  tablet: boolean;
  width: number;
}

/**
 * @deprecated Please use the new useBreakpoint hook for breakpoints.
 * You may still need useWindowSize if you need width as number for any numeric calculations.
 */
export function useWindowSize(): IDimensions {
  function getSize() {
    const width = globalThis.innerWidth;

    const mobile = width < 768;
    const tablet = width >= 768 && width < 980;

    return {
      mobileSmall: width < 480,
      mobile,
      tablet,
      isMobileDevice: mobile || tablet,
      desktop: width >= 980,
      retina: width >= 1250,
      height: globalThis.innerHeight,
      width: width,
    };
  }

  const [windowSize, setWindowSize] = useState<IDimensions>(getSize);

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize());
    }

    globalThis.addEventListener("resize", handleResize);
    return () => globalThis.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}
