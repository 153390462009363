import { DateTime } from "luxon";
import type { IFlightResult, ISegment, IShelfSummary } from "@hotelengine/core-booking-web";
import {
  StopFilterOptions,
  durationRegex,
  FlightsSortOptions,
  AllAirlinesFilter,
  flightsStoreFilterKeys,
} from "store/Flights/FlightsFilters/flights.filters.constants";
import type {
  FlightsSelectedFilter,
  IFlightDurationFilter,
  IFlightsSearchBasicFilter,
  IFlightsSearchFilters,
  IFlightSliderFilter,
  INewDurationRangeFilter,
  INewPriceRangeFilter,
  SortByFunction,
} from "store/Flights/FlightsFilters/flights.filters.types";

const getAllCarriers = (allSegments: ISegment[]) => {
  return allSegments.reduce((acc, segment) => {
    if (segment.owner.iataCode) {
      acc.push(segment.owner.iataCode);
    }
    if (segment.operator.iataCode) {
      acc.push(segment.operator.iataCode);
    }
    return acc;
  }, [] as string[]);
};

export const getTechnicalStopsCount = (allSegments: ISegment[]) => {
  const numberOfTechnicalStops = allSegments.reduce<number>((accumulator, segment) => {
    accumulator += segment?.stops.length || 0;
    return accumulator;
  }, 0);

  return numberOfTechnicalStops;
};

export const getAvailableArlinesFilter = (allCarriers: string[]) => {
  const uniqueCarriersArray = Array.from(new Set(allCarriers));
  const sortedCarriers = uniqueCarriersArray.sort();

  const airlineFilters = sortedCarriers.reduce(
    (acc, airlineIataCode) => {
      acc[airlineIataCode] = { selected: false };
      return acc;
    },
    { [AllAirlinesFilter]: { selected: false } }
  );

  return airlineFilters;
};

/**
 * This function updates the ceil and floor values based on the totalValue of the fareSummaries.
 * The ceil value will be updated if the totalValue is greater than the current ceil value.
 * The floor value will be updated if the totalValue is less than the current floor value.
 *
 * Before updating the ceil and floor values, the totalValue will be rounded (Math.round) to the
 * nearest integer because flight card component displays the price using the Intl.NumberFormat
 * function which rounds the number to the nearest integer too.
 *
 * @param newPriceRange
 * @param result
 */
const updatePriceRange = (newPriceRange: INewPriceRangeFilter, result: IFlightResult) => {
  Object.values(result.fareSummaries).forEach(({ price: { totalValue: totalPrice = 0 } }) => {
    const canUpdateCeil = newPriceRange.ceil === null || newPriceRange.ceil < totalPrice;
    const canUpdateFloor = newPriceRange.floor === null || newPriceRange.floor > totalPrice;

    if (canUpdateCeil) {
      /** upper bound should be rounded up:
       * if the value is, for instance, 1.1, doing `Math.round` would return 1
       * and the 1.1 value would be considered invalid for the upper range.
       * Doing `Math.ceil` would return 2, thus guaranteeing that the 1.1 value
       * is within range.
       */
      newPriceRange.ceil = Math.ceil(totalPrice);
    }

    if (canUpdateFloor) {
      /**
       * Conversely, the lower bound should be rounded down:
       * For a 1.8 value, doing `Math.round` would return 2
       * Thus making the 1.8 value invalid for the range.
       * Doing `Math.floor` will guarantee a floor bound lower
       * than the value.
       */
      newPriceRange.floor = Math.floor(totalPrice);
    }
  });
};

export const splitDuration = (duration: string | null) => {
  if (!duration) {
    return false;
  }

  // Match P1DT2H30M or PT2H30M
  const durationMatch = duration.match(durationRegex);

  if (durationMatch) {
    const days = Number.parseInt(durationMatch[1]) || 0;
    const hours = Number.parseInt(durationMatch[2]) || 0;
    const minutes = Number.parseInt(durationMatch[3]) || 0;
    return { days, hours, minutes };
  }

  return false;
};

export const getDurationInMinutes = (duration: string) => {
  const splittedDuration = splitDuration(duration);

  if (splittedDuration) {
    const { days, hours, minutes } = splittedDuration;
    const totalMinutes = days * 24 * 60 + hours * 60 + minutes;

    return totalMinutes;
  }

  return 0;
};

const updateDurationRange = (newDuration: INewDurationRangeFilter, resultDuration: string) => {
  const durationInMinutes = getDurationInMinutes(resultDuration);

  const canUpdateCeil = newDuration.ceil === null || newDuration.ceil < durationInMinutes;

  const canUpdateFloor = newDuration.floor === null || newDuration.floor > durationInMinutes;

  if (canUpdateCeil) {
    newDuration.userMax = durationInMinutes;
    newDuration.ceil = durationInMinutes;
    newDuration.iso8601Ceil = resultDuration;
  }

  if (canUpdateFloor) {
    newDuration.userMin = durationInMinutes;
    newDuration.floor = durationInMinutes;
    newDuration.iso8601Floor = resultDuration;
  }
};

interface IGetAvailableFiltersFromResultsArgs {
  departureDate: string;
  passengersCount: number;
  results: IFlightResult[];
  state: IFlightsSearchFilters;
  stops: StopFilterOptions;
}

export const getAvailableFiltersFromResults = ({
  departureDate,
  stops,
  passengersCount,
  results,
  state,
}: IGetAvailableFiltersFromResultsArgs): IFlightsSearchFilters => {
  const newPriceRange: INewPriceRangeFilter = {
    floor: null,
    ceil: null,
  };

  const newDuration: INewDurationRangeFilter = {
    userMax: null,
    userMin: null,
    floor: null,
    ceil: null,
    iso8601Floor: null,
    iso8601Ceil: null,
  };

  const allCarriers: string[] = [];

  if (results.length === 0) {
    return state;
  }

  for (const result of results as IFlightResult[]) {
    updatePriceRange(newPriceRange, result);

    allCarriers.push(...getAllCarriers(result.segments));

    updateDurationRange(newDuration, result.totalDuration);
  }

  const priceRange = {
    userMin: Math.floor((newPriceRange.floor ?? 0) / passengersCount),
    userMax: Math.ceil((newPriceRange.ceil ?? 0) / passengersCount),
    floor: Math.floor((newPriceRange.floor ?? 0) / passengersCount),
    ceil: Math.ceil((newPriceRange.ceil ?? 0) / passengersCount),
  };

  const { floor: takeOffTimeFloor, ceil: takeOffTimeCeil } = getTakeOffTimesBounds(results);

  const { floor: landingTimeFloor, ceil: landingTimeCeil } = getLandingTimesBounds(results);

  const takeoffTimeFloorInMinutes = takeOffTimeFloor
    ? getMinutesFromTime(departureDate, takeOffTimeFloor)
    : state.takeoffTime.floor;

  const takeOffTimeCeilInMinutes = takeOffTimeCeil
    ? getMinutesFromTime(departureDate, takeOffTimeCeil)
    : state.takeoffTime.ceil;

  const landingTimeFloorInMinutes = landingTimeFloor
    ? getMinutesFromTime(departureDate, landingTimeFloor)
    : state.landingTime.floor;

  const landingTimeCeilInMinutes = landingTimeCeil
    ? getMinutesFromTime(departureDate, landingTimeCeil)
    : state.landingTime.ceil;

  return {
    ...state,
    priceRange,
    stops: {
      selected: stops ?? StopFilterOptions.any,
    },
    airlines: getAvailableArlinesFilter(allCarriers),
    duration: newDuration as IFlightDurationFilter,
    takeoffTime: {
      ceil: takeOffTimeCeilInMinutes,
      floor: takeoffTimeFloorInMinutes,
      userMin: takeoffTimeFloorInMinutes,
      userMax: takeOffTimeCeilInMinutes,
    },
    landingTime: {
      ceil: landingTimeCeilInMinutes,
      floor: landingTimeFloorInMinutes,
      userMin: landingTimeFloorInMinutes,
      userMax: landingTimeCeilInMinutes,
    },
  };
};

export const buildSelectedFilters = (filters: IFlightsSearchFilters) => {
  const selectedFilters: FlightsSelectedFilter[] = [];
  let hasAppliedFilters = false;

  Object.entries(filters).forEach(([filterKey, filterValue]) => {
    const selectedvalue = (filterValue as { selected: StopFilterOptions })?.selected;

    if (filterKey === flightsStoreFilterKeys.stops) {
      if (selectedvalue !== StopFilterOptions.any) {
        hasAppliedFilters = true;
      }

      selectedFilters.push({
        type: filterKey,
        value: selectedvalue,
      });
    }

    if (filterKey === flightsStoreFilterKeys.inPolicy) {
      const hasSelectedInPolicy = !selectedvalue;
      if (hasSelectedInPolicy) {
        hasAppliedFilters = true;
      }

      selectedFilters.push({
        type: filterKey,
        value: filterValue.selected,
      });
    }

    if (filterKey === flightsStoreFilterKeys.airlines) {
      const selectedAirlines: Array<string> = [];

      Object.entries(filterValue as IFlightsSearchFilters["airlines"]).forEach(
        ([airlineCode, airlineFilter]) => {
          const hasSelectedAirline = airlineCode !== AllAirlinesFilter && airlineFilter.selected;
          if (hasSelectedAirline) {
            hasAppliedFilters = true;
          }

          if ((airlineFilter as IFlightsSearchBasicFilter).selected) {
            selectedAirlines.push(airlineCode);
          }
        }
      );

      // Only add airlines if there are selected values
      if (selectedAirlines.length) {
        selectedFilters.push({
          type: filterKey,
          value: selectedAirlines,
        });
      }
    }

    if (filterKey === flightsStoreFilterKeys.shelf) {
      const shelfFilters = filterValue as IFlightsSearchFilters["shelf"];
      const selectedShelfBlock = Object.values(shelfFilters).find(
        (shelfBlock) => shelfBlock.selected
      );

      // Only add shelf if it was found (not undefined or null)
      if (selectedShelfBlock) {
        selectedFilters.push({
          type: filterKey,
          value: selectedShelfBlock?.ordinal,
        });
      }
    }

    if (filterKey === flightsStoreFilterKeys.priceRange) {
      const priceRange = filterValue as IFlightsSearchFilters["priceRange"];
      const priceRangeTouched = getSliderTouched(priceRange);
      if (priceRangeTouched) {
        hasAppliedFilters = true;

        selectedFilters.push({
          type: filterKey,
          value: {
            userMin: priceRange.userMin,
            userMax: priceRange.userMax,
          },
        });
      }
    }

    if (filterKey === flightsStoreFilterKeys.duration) {
      const duration = filterValue as IFlightSliderFilter;
      const durationTouched = getSliderTouched(duration);
      if (durationTouched) {
        hasAppliedFilters = true;

        selectedFilters.push({
          type: filterKey,
          value: {
            userMin: duration.userMin,
            userMax: duration.userMax,
          },
        });
      }
    }

    if (filterKey === flightsStoreFilterKeys.takeoffTime) {
      const takeoffTime = filterValue as IFlightSliderFilter;
      const takeoffTimeTouched = getSliderTouched(takeoffTime);
      if (takeoffTimeTouched) {
        hasAppliedFilters = true;

        selectedFilters.push({
          type: filterKey,
          value: {
            userMin: takeoffTime.userMin,
            userMax: takeoffTime.userMax,
          },
        });
      }
    }

    if (filterKey === flightsStoreFilterKeys.landingTime) {
      const landingTime = filterValue as IFlightSliderFilter;
      const landingTimeTouched = getSliderTouched(landingTime);
      if (landingTimeTouched) {
        hasAppliedFilters = true;

        selectedFilters.push({
          type: filterKey,
          value: {
            userMin: landingTime.userMin,
            userMax: landingTime.userMax,
          },
        });
      }
    }
  });

  return {
    hasAppliedFilters,
    selectedFilters,
  };
};

/**
 * Extract the take off min and max time from the results
 * @param results array of IFlightsResult
 * @param originIataCode string representing the origin iata code
 * @returns an object with the floor and ceil times, object values are null if no results
 */
export const getTakeOffTimesBounds = (results: IFlightResult[]) => {
  if (!results.length) return { floor: null, ceil: null };
  return results
    .map((result) => result.segments[0])
    .reduce<{
      floor: string | null;
      ceil: string | null;
    }>(
      (acc, segment) => {
        const takeOffTime = DateTime.fromISO(segment.origin.timestamp);
        const shouldUpdateFloor = !acc.floor || takeOffTime < DateTime.fromISO(acc.floor);
        const shouldUpdateCeil = !acc.ceil || takeOffTime > DateTime.fromISO(acc.ceil);

        if (shouldUpdateFloor) acc.floor = segment.origin.timestamp;
        if (shouldUpdateCeil) acc.ceil = segment.origin.timestamp;

        return acc;
      },
      { floor: null, ceil: null }
    );
};

/**
 * Extract the landing min and max time from the results
 * @param results array of IFlightsResult
 * @param destinationIataCode string representing the destination iata code
 * @returns an object with the floor and ceil times, object values are null if no results
 */
export const getLandingTimesBounds = (results: IFlightResult[]) => {
  if (!results.length) return { floor: null, ceil: null };
  return results
    .map((result) => result.segments[result.segments.length - 1])
    .reduce<{
      floor: string | null;
      ceil: string | null;
    }>(
      (acc, segment) => {
        const landingTime = DateTime.fromISO(segment.destination.timestamp);

        const shouldUpdateFloor = !acc.floor || landingTime < DateTime.fromISO(acc.floor);
        const shouldUpdateCeil = !acc.ceil || landingTime > DateTime.fromISO(acc.ceil);

        if (shouldUpdateFloor) acc.floor = segment.destination.timestamp;
        if (shouldUpdateCeil) acc.ceil = segment.destination.timestamp;
        return acc;
      },
      { floor: null, ceil: null }
    );
};

/**
 *
 * @param baseDate the initial date to calculate the minutes,
 * just the date part is considered the time will be assumed to be 00:00
 * @param time  the date time string to calculate the minutes difference from the base date
 * @returns a number representing the minutes difference between the base date and the time
 */
export const getMinutesFromTime = (baseDate: string, time: string) => {
  const { year, month, day } = DateTime.fromISO(baseDate);

  const dateTime = DateTime.fromISO(time, { setZone: true });
  const baseDateAtMidnight = DateTime.fromObject(
    {
      year,
      month,
      day,
    },
    { zone: dateTime.zone }
  );
  const { minutes } = dateTime.diff(baseDateAtMidnight, "minutes").toObject();
  return minutes ?? null;
};

/**
 *
 * @param baseDate the initial date to add the minutes and get the corresponding date time
 * @param minutes the amount of minutes to add to the base date
 * @returns a string representing the time in the format "h:mm a"
 * or "ccc h:mm a" if the hours calculated from minutes are greater than 24
 */
export const getTimeFromMinutes = (baseDate: string | null, minutes: number | null) => {
  if (!baseDate || minutes === null) return "";
  const hours = Math.floor(minutes / 60);
  const { year, month, day } = DateTime.fromISO(baseDate);
  const baseDateAtMidnight = DateTime.fromObject({
    year,
    month,
    day,
  });
  const datePlusMinutes = baseDateAtMidnight.plus({ minutes });
  const format = hours > 24 ? "ccc h:mm a" : "h:mm a";
  return datePlusMinutes.toFormat(format);
};

export const getChepeastShelf = (flight: IFlightResult): IShelfSummary => {
  const cheapestShelf = Object.keys(flight.fareSummaries).reduce((prev, curr) => {
    if (flight.fareSummaries[prev].price.totalValue < flight.fareSummaries[curr].price.totalValue) {
      return prev;
    }
    return curr;
  });
  return flight.fareSummaries[cheapestShelf];
};

/**
 * Sort by cheapest price. It will use the cheapest shelf summary price.
 *
 * @param a
 * @param b
 * @param selectedShelfSummaryKey
 * @returns 0 if the prices are equal, a negative number if a is cheaper than b, a positive number if b is cheaper than a
 */
export const sortByCheapest = (a: IFlightResult, b: IFlightResult) => {
  const aShelfSummary = getChepeastShelf(a);
  const bShelfSummary = getChepeastShelf(b);

  if (aShelfSummary && bShelfSummary) {
    return aShelfSummary.price.totalValue - bShelfSummary.price.totalValue;
  }

  return 0;
};

/**
 * Sort by fastest duration. The duration is calculated in minutes.
 * If the duration is not in the format P1DT2H30M or PT2H30M it will be considered 0 minutes.
 * @param a
 * @param b
 * @returns 0 if the durations are equal, a negative number if a is faster than b, a positive number if b is faster than a
 */
export const sortByFastest = (a: IFlightResult, b: IFlightResult) => {
  const aDuration = getDurationInMinutes(a.totalDuration);
  const bDuration = getDurationInMinutes(b.totalDuration);
  return aDuration - bDuration;
};

/**
 * Sort by earliest departure. The departure is calculated in milliseconds.
 * It will use the first segment origin timestamp.
 *
 * @param a
 * @param b
 * @returns 0 if the departures are equal, a negative number if a departs earlier than b, a positive number if b departs earlier than a
 */
export const sortByEarliestDeparture = (a: IFlightResult, b: IFlightResult) => {
  const aDeparture = DateTime.fromISO(a.segments[0].origin.timestamp, { setZone: true });
  const bDeparture = DateTime.fromISO(b.segments[0].origin.timestamp, { setZone: true });
  return aDeparture.valueOf() - bDeparture.valueOf();
};

/**
 * Sort by latest departure. The departure is calculated in milliseconds.
 * It will use the first segment origin timestamp.
 *
 * @param a
 * @param b
 * @returns 0 if the departures are equal, a negative number if a departs later than b, a positive number if b departs later than a
 */
export const sortByLatestDeparture = (a: IFlightResult, b: IFlightResult) => {
  const aDeparture = DateTime.fromISO(a.segments[0].origin.timestamp, { setZone: true });
  const bDeparture = DateTime.fromISO(b.segments[0].origin.timestamp, { setZone: true });
  return bDeparture.valueOf() - aDeparture.valueOf();
};

/**
 * Sort by earliest arrival. The arrival is calculated in milliseconds.
 * It will use the last segment destination timestamp.
 *
 * @param a
 * @param b
 * @returns 0 if the arrivals are equal, a negative number if a arrives earlier than b, a positive number if b arrives earlier than a
 */
export const sortByEarliestArrival = (a: IFlightResult, b: IFlightResult) => {
  const aArrival = DateTime.fromISO(a.segments[a.segments.length - 1].destination.timestamp, {
    setZone: true,
  });
  const bArrival = DateTime.fromISO(b.segments[b.segments.length - 1].destination.timestamp, {
    setZone: true,
  });
  return aArrival.valueOf() - bArrival.valueOf();
};

/**
 * Sort by latest arrival. The arrival is calculated in milliseconds.
 * It will use the last segment destination timestamp.
 *
 * @param a
 * @param b
 * @returns 0 if the arrivals are equal, a negative number if a arrives later than b, a positive number if b arrives later than a
 */
export const sortByLatestArrival = (a: IFlightResult, b: IFlightResult) => {
  const aArrival = DateTime.fromISO(a.segments[a.segments.length - 1].destination.timestamp, {
    setZone: true,
  });
  const bArrival = DateTime.fromISO(b.segments[b.segments.length - 1].destination.timestamp, {
    setZone: true,
  });
  return bArrival.valueOf() - aArrival.valueOf();
};

export const sortFunctionsMap: Record<FlightsSortOptions, SortByFunction> = {
  [FlightsSortOptions.Cheapest]: sortByCheapest,
  [FlightsSortOptions.Fastest]: sortByFastest,
  [FlightsSortOptions.EarliestDeparture]: sortByEarliestDeparture,
  [FlightsSortOptions.LatestDeparture]: sortByLatestDeparture,
  [FlightsSortOptions.EarliestArrival]: sortByEarliestArrival,
  [FlightsSortOptions.LatestArrival]: sortByLatestArrival,
};

export const getSliderTouched = (time: IFlightSliderFilter) => {
  const takeoffTimeInitialized = time.userMin !== null && time.userMax !== null;
  return takeoffTimeInitialized && (time.userMin !== time.floor || time.userMax !== time.ceil);
};
