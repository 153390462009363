import styled from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import type { TypographyProps, ButtonProps } from "@hotelengine/atlas-web";
import { Button, Typography } from "@hotelengine/atlas-web";
import type React from "react";

export const ThankYouContainer = styled.div`
  text-align: center;
  width: 100%;
  ${flexbox({ direction: "column", justifyContent: "center" })};
`;

export const ThankYouHeadline = styled<React.FC<TypographyProps>>(Typography)<{ variant: string }>`
  margin: 0 auto 0.5em;
`;

export const ThankYouCopy = styled<React.FC<TypographyProps>>(Typography)<{ variant: string }>`
  margin: 0 4em;
`;

export const ThankYouImage = styled.img`
  max-width: 100px;
  margin: 40px auto 1em;
`;

export const ModalFooter = styled.footer`
  margin: 4em 0 0;
  text-align: right;
`;

export const CloseButton = styled<React.FC<ButtonProps>>(Button)`
  padding: 8px 16px;
`;
