import styled from "styled-components";

import Button from "@hotel-engine/common/Button";
import { Modal } from "@hotel-engine/common/Modal";
import { pxToRem } from "@hotel-engine/styles/helpers/mixins";

export const ExpressBookModal = styled(Modal)<{
  $hidden?: boolean;
  $isOutOfPolicy?: boolean;
}>`
  div.ant-modal-content {
    transition: 0.25s all ease-in-out;

    opacity: ${({ $hidden }) => ($hidden ? 0 : 1)};
    transform: ${({ $hidden }) => ($hidden ? "translateY(-10px)" : "translateY(0)")};

    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xl};
    overflow: hidden;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;

    .ant-modal-close {
      .ant-modal-close-x {
        width: 64px;
        height: 56px;
        line-height: 56px;
        svg {
          fill: ${({ theme }) => theme.legacy.colors.black};
        }
      }
    }
    .ant-modal-header {
      height: 56px;
      padding: 1rem;
      border-bottom: 1px solid #dddee0;
      border-radius: ${({ theme }) => {
        const brValue = theme.legacy.shape.borderRadius.xl;
        return `${brValue} ${brValue} 0 0`;
      }};
    }

    .ant-modal-title {
      display: flex;
      gap: 10px;
      position: relative;
      top: 3px;
    }

    > div.ant-modal-body {
      color: ${({ theme }) => theme.legacy.colors.grey[800]};

      padding: 0;
      .ant-form-item {
        margin: 16px 0 0;
      }
      p {
        margin-bottom: 0;
      }

      .express-book-modal {
        height: 100%;
      }
    }
    .ant-modal-footer {
      border-top: 1px solid #dddee0;
      padding: 16px;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    /* OVERRIDE TO ALLOW DESKTOP DATEPICKER TO */
    /* BREAK OUT WHEN MODAL IS SHORT DUE TO isOutOfPolicy */
    ${({ $isOutOfPolicy }) => {
      if ($isOutOfPolicy) {
        return `
        div.ant-modal-content {
          overflow: visible !important;
        }

        div.ant-modal-body: {
          overflow: visible !important;
        }

        div.express-book-modal {
          overflow: visible !important;
        }
      `;
      }

      return ``;
    }}
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100vw;
    max-width: 100vw !important;
    height: 100dvh;
    position: fixed; // TODO: explore this style later when we start implementing datepicker
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;

    div.ant-modal-content {
      height: 100dvh;
      overflow-y: auto;
      border-radius: 0;
    }

    .ant-modal-title {
      text-align: center;
      position: relative;
      top: 3px;
    }

    .ant-modal-body {
      max-width: 100vw;
    }

    .ant-modal-close {
      right: 0;
    }
  }
`;

export const EditButton = styled(Button)`
  display: flex;
  height: auto;
  font-size: ${pxToRem(14)} !important;
`;
