import type { ISupportDateChange } from "@hotel-engine/types/supportCase";
import * as Styled from "../../styles";
import { Typography } from "@hotelengine/atlas-web";
import { useFormatDate } from "@hotel-engine/hooks/useFormatDate";

export interface ISupportDateChangeProps {
  details: ISupportDateChange;
}

function DateChange({ details }: ISupportDateChangeProps) {
  const { currentCheckIn, currentCheckOut, requestedCheckIn, requestedCheckOut } = details;

  const formattedCurrentCheckIn = useFormatDate(currentCheckIn, "MM/DD/YYYY", true);
  const formattedCurrentCheckOut = useFormatDate(currentCheckOut, "MM/DD/YYYY", true);
  const formattedrequestedCheckIn = useFormatDate(requestedCheckIn, "MM/DD/YYYY", true);
  const formattedrequestedCheckOut = useFormatDate(requestedCheckOut, "MM/DD/YYYY", true);

  return (
    <>
      {!!requestedCheckIn && (
        <Styled.Row>
          <Typography variant="body/md">Check-In:</Typography>

          <Typography variant="body/md">
            {formattedrequestedCheckIn !== formattedCurrentCheckIn && (
              <Styled.StrikethroughText variant="body/md-linethrough">
                {formattedCurrentCheckIn}
              </Styled.StrikethroughText>
            )}
            {formattedrequestedCheckIn}
          </Typography>
        </Styled.Row>
      )}
      {!!requestedCheckOut && (
        <Styled.Row>
          <Typography variant="body/md">Checkout:</Typography>
          <Typography variant="body/md">
            {formattedrequestedCheckOut !== formattedCurrentCheckOut && (
              <Styled.StrikethroughText variant="body/md-linethrough">
                {formattedCurrentCheckOut}
              </Styled.StrikethroughText>
            )}
            {formattedrequestedCheckOut}
          </Typography>
        </Styled.Row>
      )}
    </>
  );
}

export default DateChange;
