import { useAppSelector } from "store/hooks";
import { Icon } from "@hotel-engine/common/Icon";
import { colors } from "@hotel-engine/styles";
import type { IOverlappingContract } from "@hotel-engine/types/contractRate";
import { formatDate } from "@hotel-engine/utilities";

import * as Styled from "./styles";

export interface IOverlappingData {
  data: IOverlappingContract[] | undefined;
}

const OverlappingResMessage = (props: IOverlappingData) => {
  const preferredDateFormat =
    useAppSelector((state) => state.Auth.user?.business.preferredDateFormat) || "mdy";

  const overlappingContract = props.data?.[0];

  if (!overlappingContract) return null;

  const { propertyName, checkIn } = overlappingContract;

  const dateFormat = "ddd, MMM Do, YYYY";
  const formattedCheckIn = formatDate(checkIn, dateFormat, preferredDateFormat);

  return (
    <Styled.OverlappingToast>
      <Icon icon={["fas", "exclamation-circle"]} color={colors.orange[400]} size="lg" />
      <div>
        {`This guest has an overlapping reservation at ${propertyName} on ${formattedCheckIn} which is against your travel policy. To proceed with booking, please contact your administrator.`}
      </div>
    </Styled.OverlappingToast>
  );
};

export default OverlappingResMessage;
