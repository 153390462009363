import config from "config";

import Api from "./Api";
import Resource from "./Resource";

/**
 * @deprecated - switch to the associated react-query hooks if possible
 */
export default class AuthStrategy extends Resource<AuthStrategy> {
  // Duplicate of the constructor from ReadOnlyResource;
  // The context of "this" got broken in the upgrade to CRA,
  // so calling it explicitly here as a hack
  constructor(params?) {
    super(params);
    Object.assign(this, params);
  }

  public static _basePath = `${config.apiHostV2}/auth_strategies`;
  public displayName!: string;
  public isRequired?: boolean;
  public url!: string;
  public callbackUrl?: string;
  public endpoint?: string;
  public protocol?: string;
  public cert?: string;
  public attributeMap?: {
    firstName: string;
    lastName: string;
    email: string;
  };
  public static async lookup(params: { [k: string]: unknown } | null = null) {
    const response = await Api.get(`${this._basePath}/lookup`, params);
    return {
      ...response,
      results: response.data.map((i) => this._makeInstance(i)),
    };
  }
}
