import type { Dispatch, SetStateAction } from "react";
import { useEffect } from "react";
import _ from "lodash";
import { useFormikContext } from "formik";

import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import type { IExpressBookCheckoutForm } from "@hotel-engine/types/expressBook";

import { ExpressBookSearchGuest } from "../ExpressBookSearchGuest/ExpressBookSearchGuest";
import { ExpressBookSelectedGuest } from "../ExpressBookSelectedGuest/ExpressBookSelectedGuest";
import { ExpressBookAdditionalGuest } from "../ExpressBookAdditionalGuest/ExpressBookAdditionalGuest";
import type { IPropertyLoyaltyRewards } from "@hotel-engine/types/booking";
import * as helpers from "pages/Checkout/LegacyLodging/GuestSelection/Room/helpers";
import { useAppSelector } from "store/hooks";
import { useExpressBook } from "pages/Checkout/LegacyLodging/ExpressBookModal/hooks/useExpressBook";
import { useOverlappingContractsQuery } from "@hotel-engine/react-query/overlappingContracts/useOverlappingContractsQuery";
import moment from "moment";
import type { IOverlappingContract } from "@hotel-engine/types/contractRate";

interface IExpressBookGuestRoomParams {
  index: number;
  propertyLoyaltyRewards: IPropertyLoyaltyRewards;
  isLoyaltyEligible: boolean;
  setOverlappingContractData: Dispatch<SetStateAction<IOverlappingContract[]>>;
}

export const ExpressBookGuestRoom = ({
  index,
  propertyLoyaltyRewards,
  isLoyaltyEligible,
  setOverlappingContractData,
}: IExpressBookGuestRoomParams) => {
  const allowPreFillAllGuests = useIsFeatureFlagOn("pre_fill_all_guests");

  const user = useAppSelector((store) => store.Auth.user);
  const form = useFormikContext<IExpressBookCheckoutForm>();
  const thisRoom = form.values.guestList[index];
  const mode = thisRoom?.primary.mode;
  const selectedUser = thisRoom?.primary.user;
  const shouldCheckOverlappingContracts = helpers.shouldCheckOverlappingContracts(user);

  const { expressBookCheckIn, expressBookCheckOut } = useExpressBook();

  const { data: overlappingContractFromQuery } = useOverlappingContractsQuery(
    {
      bookableId: `${selectedUser?.id}`,
      bookableType: selectedUser && Object.keys(selectedUser).includes("role") ? "user" : "guest",
      checkIn: moment(expressBookCheckIn).format("YYYY-MM-DD"),
      checkOut: moment(expressBookCheckOut).format("YYYY-MM-DD"),
    },
    {
      enabled: Boolean(shouldCheckOverlappingContracts && selectedUser?.id),
      onSuccess: (data) => {
        setOverlappingContractData(data || []);
      },
    }
  );

  useEffect(() => {
    setOverlappingContractData(overlappingContractFromQuery || []);

    form.setFieldValue(`guestList.${index}`, {
      ...thisRoom,
      overlappingContracts: overlappingContractFromQuery,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overlappingContractFromQuery]);

  useEffect(() => {
    form.setFieldValue(`guestList.${index}`, {
      ...thisRoom,
      loyaltyInfo: !allowPreFillAllGuests ? thisRoom.loyaltyInfo : null,
    });
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let primaryGuest: JSX.Element | undefined = undefined;

  if (mode === "search") {
    primaryGuest = <ExpressBookSearchGuest guestType="primary" index={index} />;
  }

  if (mode === "selected" && selectedUser) {
    primaryGuest = (
      <ExpressBookSelectedGuest
        roomIsLoyaltyEligible={isLoyaltyEligible}
        guestType="primary"
        index={index}
        propertyLoyaltyRewards={propertyLoyaltyRewards}
      />
    );
  }

  return (
    <>
      {primaryGuest}
      <ExpressBookAdditionalGuest index={index} />
    </>
  );
};
