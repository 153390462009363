export const dateRangePickerFormats = {
  mdy: "ddd, MMM D",
  dmy: "ddd, D MMM",
  ymd: "ddd, MMM D",
};

export const dateRangePickerYearFormats = {
  mdy: "MM/DD/YYYY",
  dmy: "DD/MM/YYYY",
  ymd: "YYYY/MM/DD",
};

export const dateRangePickerShortFormats = {
  mdy: "MM/DD/YY",
  dmy: "DD/MM/YY",
  ymd: "YY/MM/DD",
  md: "MMM D",
};

export const dateTimeRangePickerShortFormats = {
  mdy: "MM/DD/YY hh:mm A",
  dmy: "DD/MM/YY hh:mm A",
  ymd: "YY/MM/DD hh:mm A",
  md: "MMM D HH:mm A",
};
