import styled from "styled-components";
import { colors } from "../../../styles";

interface IFieldDescription {
  blurred?: boolean;
}

export const FieldDescription = styled.span<IFieldDescription>`
  color: ${({ blurred }) => (blurred ? colors.fadedBlack : colors.blackPanther)};
`;
