import { parse } from "qs";

export const getProductVertical = () => {
  try {
    if (globalThis.location.href.includes("/cars")) {
      return "cars";
    }
    if (globalThis.location.href.includes("/flights")) {
      return "flights";
    }
    return "lodging";
  } catch (e) {
    return "";
  }
};

export const getCartId = () => {
  try {
    const queryParams = parse(globalThis.location.search, { ignoreQueryPrefix: true });
    const cartId = (queryParams.cartId as string) || "";

    return cartId;
  } catch (e) {
    return "";
  }
};
