import { notification as antDNotification } from "antd";
import type { ArgsProps } from "antd/lib/notification";

import { NotificationStyles } from "./styles";

/**
 * The `Notification` helper leverages antD to provide feedback based on user interaction.
 * It may also be used to give details on upcoming actions the user will take
 *
 * @see {@link https://www.figma.com/file/S9dcIwiGPXxXIT1Iw1n33D/1.-Basic-Components-(Web)?node-id=1%3A8539 Design Specs}
 */

/** Returns the notification message with the styles injected */
const returnNotificationStyles = (obj: ArgsProps) => {
  return {
    ...obj,
    message: (
      <>
        {obj.message}
        <NotificationStyles />
      </>
    ),
  };
};

export const notification = {
  error: (obj: ArgsProps) => antDNotification.error(returnNotificationStyles(obj)),
  info: (obj: ArgsProps) => antDNotification.info(returnNotificationStyles(obj)),
  open: (obj: ArgsProps) => antDNotification.open(returnNotificationStyles(obj)),
  success: (obj: ArgsProps) => antDNotification.success(returnNotificationStyles(obj)),
  warn: (obj: ArgsProps) => antDNotification.warn(returnNotificationStyles(obj)),
  warning: (obj: ArgsProps) => antDNotification.warning(returnNotificationStyles(obj)),
  close: (key: string) => antDNotification.close(key),
  destroy: () => antDNotification.destroy(),
};
