import type {
  IExpressBookContractDataResponse,
  IExpressBookPropertyRef,
} from "@hotel-engine/types/expressBook";
import { ExpressBookModalStep } from "@hotel-engine/types/expressBook";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { IExpressBookState } from "./types";

export const initialState: IExpressBookState = {
  checkoutProperty: null,
  modalStep: ExpressBookModalStep.BASE_STATE,
  checkIn: null,
  checkOut: null,
  guestCount: null,
  contractData: {},
  contractRateRefreshedAt: undefined,
  analytics: {},
  successContext: {},
  hideForStackingModals: false,
};

export const expressBookSlice = createSlice({
  name: "ExpressBook",
  initialState,
  reducers: {
    //TODO: Rework these stub methods for handling modal visibility during dev
    openModal: (
      state,
      action: PayloadAction<{
        checkoutPropRef: IExpressBookPropertyRef;
        checkIn: string;
        checkOut: string;
        guestCount: number;
      }>
    ) => ({
      ...state,
      checkoutProperty: action.payload.checkoutPropRef,
      checkIn: action.payload.checkIn,
      checkOut: action.payload.checkOut,
      guestCount: action.payload.guestCount,
      analytics: {
        ...state.analytics,
        searchId: action.payload.checkoutPropRef.searchId,
        propertyId: action.payload.checkoutPropRef.propertyId,
      },
    }),
    updateAnalyticsValues: (
      state,
      action: PayloadAction<Partial<IExpressBookState["analytics"]>>
    ) => ({
      ...state,
      analytics: {
        ...state.analytics,
        ...action.payload,
      },
    }),
    closeModal: () => ({
      ...initialState,
    }),
    setModalStep: (state, action: PayloadAction<ExpressBookModalStep>) => ({
      ...state,
      modalStep: action.payload,
    }),
    advanceToSuccessState: (
      state,
      action: PayloadAction<Required<IExpressBookState["successContext"]>>
    ) => ({
      ...state,
      modalStep: ExpressBookModalStep.SUCCESS_STATE,
      successContext: {
        ...action.payload,
      },
    }),
    setHideForStackingModals: (
      state,
      action: PayloadAction<IExpressBookState["hideForStackingModals"]>
    ) => ({
      ...state,
      hideForStackingModals: action.payload,
    }),
    updateTripDates: (state, action: PayloadAction<{ checkIn: string; checkOut: string }>) => ({
      ...state,
      analytics: {
        ...state.analytics,
        searchId: undefined,
      },
      checkIn: action.payload.checkIn,
      checkOut: action.payload.checkOut,
    }),
    setContractData: (
      state,
      action: PayloadAction<Required<IExpressBookContractDataResponse>>
    ) => ({
      ...state,
      contractData: action.payload,
      contractRateRefreshedAt: Date.now(),
    }),
  },
});

export const ExpressBookActions = expressBookSlice.actions;

export default expressBookSlice.reducer;
