import { lazy, Suspense } from "react";

import config from "config";
const DevTools = lazy(() => import(/* webpackChunkName: "DevTools" */ "./DevTools"));

export const DevToolsIfProductionEnv = () => {
  if (config.stage === "dev" || config.stage === "preview") {
    return (
      // Don't want to trigger full screen loading spinner fallback for DevTools
      <Suspense fallback={null}>
        <DevTools />
      </Suspense>
    );
  }

  return null;
};

export default DevToolsIfProductionEnv;
