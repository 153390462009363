import { routes } from "@hotel-engine/constants";

/**
 *
 * @returns redirectPath: the path to redirect to after login
 */
export const getRedirectLocation = () => {
  if (globalThis.location.pathname !== "/login") {
    const redirectPath = `${routes.login}${
      globalThis.location.pathname == "/"
        ? ""
        : `?return=${encodeURIComponent(globalThis.location.href)}`
    }`;
    return redirectPath;
  }
  return null;
};
