export enum SortOptions {
  Recommended = "RECOMMENDED",
  BestValue = "BEST_VALUE",
  Distance = "DISTANCE",
  PriceLowToHigh = "PRICE_ASC",
  PriceHighToLow = "PRICE_DESC",
  StarsHighest = "STARS_DESC",
  StarsLowest = "STARS_ASC",
  GuestRatingHighest = "GUEST_RATING_DESC",
}
