/** utility for casting alphanumeric strings to numbers */
export const stringToNumber = (n: string) => parseFloat(n.replace(/[^.\d]/g, ""));

/** Enables language-sensitive number formatting. */
export const formatNumber = (value: number, locale?: string) =>
  new Intl.NumberFormat(locale).format(value);

/**
 * A "wrapper" around Intl.NumberFormat for formatting numbers/strings as USD strings.
 * @param value the value to be formatted
 * @param displayDecimals do you want to display decimals?
 * @param currency - in the future when we need to support other currencies,
 * @param notation - {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#scientific_engineering_or_compact_notations | notation options }
 * @returns a string in the format of either $1,234.56 by default or "truncated" with a shorten notation like $1.24K, $3.69M or $1.10B or without decimals as $1,234
 */

export const formatCurrency = (
  value: string | number,
  displayDecimals = true,
  currency?: string,
  notation?: "standard" | "scientific" | "engineering" | "compact"
) => {
  const options: Parameters<typeof Intl.NumberFormat>[1] = {
    // in the future when we need to support other currencies,
    // we'll need to make sure the api sends back the type in the payload
    currency: currency || "USD",
    maximumFractionDigits: displayDecimals ? 2 : 0,
    minimumFractionDigits: displayDecimals ? 2 : 0,
    notation: notation || "standard",
    style: "currency",
  };

  return new Intl.NumberFormat(navigator.language, options).format(Number(value));
};

/**
 *
 * @param value what is being formatted
 * @param includeCountryCode defaults to true
 * @returns Formats phone number as either international: +1 (123)456-7890, domestic: (123)456-7890, or local: 456-7890
 */
export const formatPhoneNumber = (value?: string | number, includeCountryCode = true) => {
  // If a falsy value is entered or a empty string/spaces just return a empty string
  if (!value || value == "") {
    return "";
  }

  // Make sure there are no strings included by converting the input value to a string initially, replacing the string with a space and making sure its converted back to a number
  const numericalValueAsString = value.toString().replace(/\D/g, "");

  // Create an array of phone number components[+1 or undefined, area code, first three, last four]
  const rawPhone = ("" + numericalValueAsString).match(/^(1|)?(\d{3})?(\d{3})(\d{4})$/);

  // If there are enough matches to create a phone number with 7 or 10 digits and/or 10 with a 1 in the beginning continue to formate the number
  if (rawPhone) {
    // format phone number (parentheses, space between +1 and rest, hyphen)
    return [
      // If includeCountryCode is false at implementation it will omit the +1 in the beginning
      includeCountryCode && rawPhone[1] ? "+1 " : "",
      rawPhone[2] ? `(${rawPhone[2]}) ` : "",
      rawPhone[3],
      "-",
      rawPhone[4],
    ].join("");
  }

  // Until the conditions are met to format a full phone number, just return the value as is
  return numericalValueAsString;
};
