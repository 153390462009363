import styled from "styled-components";
import { pxToRem } from "@hotel-engine/styles/helpers/mixins";

export const ExpressBookLoadingBlock = styled.div`
  margin-top: 0.6rem;
  margin-bottom: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 0.85rem;
  padding: ${pxToRem(16)} ${pxToRem(24)};

  h4 {
    color: ${({ theme }) => theme.legacy.colors.grey[800]};

    font-size: ${({ theme }) => theme.legacy.fontSize.md};
    font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
    line-height: 150%;

    text-align: center;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: ${pxToRem(16)};
  }
`;

export const ExpressBookBaseStateContainer = styled.div<{
  $isLoaded: boolean;
  $isOutOfPolicy: boolean;
}>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
  height: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    overflow-y: ${({ $isOutOfPolicy }) => ($isOutOfPolicy ? "visible" : "auto")};
    position: relative;
    padding-bottom: ${({ $isLoaded, $isOutOfPolicy }) =>
      $isLoaded && !$isOutOfPolicy ? "119px" : 0};
    max-height: calc(86vh - 175px);
    height: ${({ $isOutOfPolicy }) => ($isOutOfPolicy ? "260px" : "auto")};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-height: calc(100dvh - 56px);
    overflow-y: ${({ $isOutOfPolicy }) => ($isOutOfPolicy ? "hidden" : "auto")};
    display: ${({ $isOutOfPolicy }) => ($isOutOfPolicy ? "block" : "grid")};
  }
`;

export const ExpressBookHotelPreviewCardContainer = styled.div`
  padding: ${pxToRem(5)} ${pxToRem(8)} 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 0;
  }
`;

export const ExpressBookBaseStateFormContainer = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: 1fr auto;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: flex;
    flex-direction: column;
    padding-bottom: 147px;
  }
`;
