import type { MutableRefObject } from "react";
import { useEffect, useRef, useState } from "react";

import { captureMessage } from "@sentry/browser";
import moment from "moment";
import { BottomSheet } from "react-spring-bottom-sheet";

import { useBreakpoint } from "@hotel-engine/hooks";
import { useCreateSurvey } from "@hotel-engine/react-query/survey/useCreateSurvey";
import { useSurveyQuery } from "@hotel-engine/react-query/survey/useSurveyQuery";
import { ampli } from "ampli";
import { useAppSelector } from "store/hooks";

import FeedbackForm from "./components/FeedbackForm";
import ThankYou from "./components/ThankYou";
import type { IFeedbackForm, RatingValues } from "./constants";
import { getNumericRating } from "./constants";
import * as Styled from "./styles";
import type { ISurveyQuestion } from "@hotel-engine/types/survey";
import { Unsafe } from "@hotel-engine/data";
import { Dialog, DialogClose, DialogContent, Typography } from "@hotelengine/atlas-web";

export interface IFeedbackModalProps {
  /** method used to open the modal - button or condition */
  accessMethod: "active" | "passive";
  /** Used to close the feedback survey */
  onClose?: () => void;
}

export interface IFeedbackSurveyState {
  /** Indicates if the survey has an error */
  showError?: boolean;
  /** Indicates if the survey has been submitted */
  showThankYou: boolean;
  /** Uniquely identifies the survey */
  surveyId: number | undefined;
  /** The title of the survey */
  surveyTitle: string;
  /** Array of survey questions */
  surveyQuestions: (ISurveyQuestion & { id: number })[];
}

export const FeedbackModal = ({ accessMethod, onClose }: IFeedbackModalProps) => {
  const user = useAppSelector((state) => state.Auth.user);
  const isMobile = useBreakpoint("md", "max");
  const formRef = useRef() as MutableRefObject<IFeedbackForm>;
  const [visible, setVisible] = useState(false);
  const [survey, setSurvey] = useState<IFeedbackSurveyState>({
    showThankYou: false,
    surveyId: undefined,
    surveyTitle: "",
    surveyQuestions: [],
    showError: false,
  });

  useEffect(() => {
    refetch().then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate: createSurvey } = useCreateSurvey();

  const { data, isFetched, refetch } = useSurveyQuery(
    { name: "customer_insights" },
    {
      onError: (error) => {
        captureMessage("Survey query failed", error["message"]);
      },
      onSuccess: (surveyResp) => {
        const {
          lastDeclinedAt,
          lastCompletedAt,
          id,
          surveyQuestions,
          title,
          firstBookingCreatedAt,
        } = surveyResp;

        if (accessMethod === "active") {
          // Check dates to make sure user qualifies for survey
          const dateQualifiesForSurvey = (() => {
            if (!lastDeclinedAt && !lastCompletedAt) {
              // if lastDeclinedAt or lastCompletedAt are null, user has not completed modal
              return true;
            } else if (moment().diff(moment(lastDeclinedAt), "days") > 30) {
              // if user hasn't declined in last 30 days, show modal
              return true;
            } else if (moment().diff(moment(lastCompletedAt), "days") > 90) {
              // if user hasn't submitted in last 90 days, show modal
              return true;
            } else {
              // if user has responded within 90 days, hide modal
              return false;
            }
          })();

          const userHasCompletedFirstBooking =
            !!firstBookingCreatedAt && moment().diff(moment(firstBookingCreatedAt), "days") > 30;

          setVisible(userHasCompletedFirstBooking && dateQualifiesForSurvey);
        } else {
          setVisible(true);
        }

        setSurvey((prev) => ({
          ...prev,
          surveyQuestions,
          surveyId: id,
          surveyTitle: title,
        }));
      },
    }
  );

  const getAmplitudeData = () => ({
    accessMethod,
    rating: getNumericRating(formRef.current?.values?.satisfaction),
    typedText: formRef.current?.values?.multiline,
    userId: user?.id,
  });

  const handleExit = () => {
    onClose?.();
    setVisible(false);
    ampli.clickFeedbackModal({
      ...getAmplitudeData(),
      closeMethod: "close",
    });

    const shouldUpdateSurveyDeclinedDate =
      !survey.showThankYou && !!survey.surveyId && accessMethod === "active";
    if (shouldUpdateSurveyDeclinedDate) {
      createSurvey({
        // @ts-expect-error surveyId will always be truthy
        surveyId: survey.surveyId,
        declined: true,
        userAgent: navigator.userAgent,
        pageName: location.pathname,
        surveyAnswersAttributes: [],
      });
    }
  };

  const handleSubmit = (
    answers: { satisfaction: RatingValues | undefined; multiline: string },
    { setSubmitting }: { setSubmitting: (val: boolean) => void }
  ) => {
    // Amplitude event will go here to log successful submission
    // Hit endpoint here to send feedback to BE, then handle next modal step
    if (survey.surveyId && survey.surveyQuestions) {
      const amplitudeObj = getAmplitudeData();
      createSurvey(
        {
          surveyId: survey.surveyId,
          declined: false,
          userAgent: navigator.userAgent,
          pageName: location.pathname,
          surveyAnswersAttributes: survey.surveyQuestions.map((question) => {
            const answer = answers[question.questionType];
            const isSatisfaction = question.questionType === "satisfaction";
            let satisfactionAnswer;
            if (isSatisfaction) {
              satisfactionAnswer = getNumericRating(answers.satisfaction);
            }
            return {
              ...question,
              surveyQuestionId: question.id,
              response: isSatisfaction ? satisfactionAnswer : answer || "unanswered",
            };
          }),
        },
        {
          onError: (error) => {
            // @ts-expect-error I hate our type handling around errors.
            captureMessage("Failed to submit survey decline", error);
            setSurvey((prev) => ({
              ...prev,
              showError: true,
            }));
            setSubmitting(false);
          },
          onSuccess: () => {
            setSurvey((prev) => ({
              ...prev,
              showThankYou: true,
            }));

            ampli.clickFeedbackModal({
              ...amplitudeObj,
              closeMethod: "submit",
            });
          },
        }
      );
    }
  };

  const content = survey.showThankYou ? (
    <ThankYou close={handleExit} />
  ) : (
    isFetched &&
    !!data?.surveyQuestions.length && (
      <>
        <header>
          <Typography variant="heading/lg" data-testid="feedback-modal">
            We love your feedback!
          </Typography>
        </header>
        <FeedbackForm
          close={handleExit}
          isError={survey.showError}
          isMobile={isMobile}
          ref={formRef}
          submit={handleSubmit}
        />
      </>
    )
  );

  if (isMobile) {
    return (
      <>
        <Styled.GlobalBottomSheetStyles />
        <BottomSheet
          data-testid="feedback-modal"
          onDismiss={handleExit}
          open={visible}
          blocking={false}
        >
          <Styled.BottomSheetContentContainer>{content}</Styled.BottomSheetContentContainer>
        </BottomSheet>
      </>
    );
  }

  return visible ? (
    <>
      <Dialog isOpen={visible} data-testid="feedback-modal">
        <DialogContent>
          {!survey.showThankYou && <DialogClose onClick={handleExit} data-testid="close-modal" />}
          {content}
        </DialogContent>
      </Dialog>
    </>
  ) : null;
};
