/**
 * Type representation of a cookie and its properties required for compliance.
 */
export type ICookie = {
  name: string;
  expiresDisplay?: string;
  type: "Strictly Necessary" | "Preferences" | "Statistics" | "Marketing";
  description: string;
  essential: boolean;
  firstParty: boolean;
  /** These set the values passed to {@link createCookie} */
  options?: {
    session?: boolean;
    expires?: number | Date;
    maxAge?: number;
    secure?: boolean;
    httpOnly?: boolean;
    sameSite?: "None" | "Strict" | "Lax";
    domain?: string;
  };
};

/**
 *
 * Definitions used for the {@link ICookie} type property.
 *
 * For more information see:
 *
 * CCPA - https://www.oag.ca.gov/privacy/ccpa
 *
 * GDPR - {@link https://www.gdpr.eu/cookies/}
 *
 * Our privacy policy - {@link [https://engine.com/privacy-policy/]}
 *
 * Our research - {@link [https://www.hotelengine.atlassian.net/wiki/spaces/DEVELOPMEN/pages/1275658402/Octo-5+FE+Ability+for+users+to+opt+out+of+3rd+party+cookies]
 *
 */
export const CookieTypeDefinitions = {
  "Strictly Necessary":
    "These cookies are essential for you to browse the website and use its features, such as accessing secure areas of the site.",
  // Preferences:
  //   "Also known as “functionality cookies,” these cookies allow a website to remember choices you have made in the past.",
  Statistics:
    "Also known as “performance cookies”, these collect information about how you use a website, such as the which pages you visit and the links you click.",
  Marketing:
    "These cookies track your online activity to help advertisers deliver more relevant advertising or to limit how many times you see an ad.",
} as const;

/**
 * Our cookie definitions. Keep this up to date with our first party cookies
 *
 * These are of type {@link cookie}
 */
export const Cookies = {
  cookiePerms: {
    name: "cookie_accepted",
    type: "Strictly Necessary",
    description: `manages cookie settings`,
    expiresDisplay: "6 months",
    essential: true,
    firstParty: true,
    options: {
      expires: new Date().setMonth(new Date().getMonth() + 6),
      session: false,
      secure: true,
      sameSite: "none",
    },
  },
  rememberEmail: {
    name: "remember_email",
    type: "Preferences",
    description: "Remember user email on login",
    expiresDisplay: "6 months",
    essential: true,
    firstParty: true,
    options: {
      expires: new Date().setMonth(new Date().getMonth() + 6),
      session: false,
      secure: true,
      sameSite: "none",
    },
  },
  notificationPopup: {
    name: "notification_popup",
    type: "Strictly Necessary",
    description: "Notification tracker",
    expiresDisplay: "4 weeks",
    essential: true,
    firstParty: true,
    options: {
      expires: new Date().setDate(new Date().getDate() + 28),
      session: false,
      secure: true,
      sameSite: "none",
    },
  },
  resend2fa: {
    name: "resend_2fa",
    type: "Preferences",
    description: "Resend code countdown 2fa",
    essential: true,
    firstParty: true,
    expiresDisplay: "10 mins",
    options: {
      expires: new Date().setMonth(new Date().getMonth() + 6),
      session: false,
      secure: true,
      sameSite: "none",
    },
  },
  token: {
    name: "token",
    type: "Strictly Necessary",
    description: "authentication",
    expiresDisplay: "10 years",
    essential: true,
    firstParty: true,
    options: {
      expires: new Date().setFullYear(new Date().getFullYear() + 10),
      httpOnly: true,
      secure: true,
      sameSite: "None",
    },
  },
  datadog: {
    name: "_dd_s",
    type: "Statistics",
    description: "Datadog provides vital information for fixing bugs.",
    essential: false,
    firstParty: false,
  },
  googleAnalytics: {
    name: "_ga",
    type: "Marketing",
    description: "Google Analytics is used to track and report website traffic.",
    essential: false,
    firstParty: false,
  },
  googleAnalyticsId: {
    name: "_gid",
    type: "Marketing",
    description: "This links Google Analytics to our application.",
    expiresDisplay: "1 day",
    essential: false,
    firstParty: false,
  },
  googleTagManager: {
    name: "_gat_UA-.*",
    type: "Marketing",
    description: "This links a user to Google Analytics and our application.",
    essential: false,
    firstParty: false,
  },
  amplitude: {
    name: "amp_*",
    type: "Statistics",
    description: "We use Amplitude to get an overview of how customers use our website.",
    essential: false,
    firstParty: false,
  },
} as const;
