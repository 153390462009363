import type { BoxProps } from "@hotelengine/atlas-web";
import { Box } from "@hotelengine/atlas-web";
import styled from "styled-components";
import { pxToRem } from "@hotel-engine/styles/helpers/mixins";
import { colors } from "@hotel-engine/styles";

export const Card = styled<React.FC<BoxProps>>(Box)`
  padding: ${({ theme }) => theme.spacing[16]}
    ${({ theme }) => theme.spacing[24]};
  border-top: 4px solid ${({ theme }) => theme.legacy.colors.grey[50]};

  .ant-input,
  .ant-select-selection {
    border-radius: ${({ theme }) => theme.borderRadius.lg};
  }

  .ant-form-explain {
    font-size: ${pxToRem(12)};
  }

  .ant-form-item-label {
    padding-bottom: 0;
  }

  .has-error {
    .ant-input {
      border-color: ${colors.red[400]};
    }

    .ant-form-explain {
      color: ${colors.red[400]};
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: ${({ theme }) => theme.spacing[16]};
  }
`;

export const CheckboxWrapper = styled.div`
  padding-top: 4px;

  .ant-form-item-control {
    line-height: 14px;
  }
`;
