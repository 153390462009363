import styled from "styled-components";

export const Stars = styled.div`
  margin: 0px;
  padding: 0px;
  flex-shrink: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
`;

export const SingleStar = styled.button`
  font-size: 32px;
  line-height: 22px;
  padding: 10px;
  border: 0;
  background: transparent;
`;
